import dayjs from "dayjs";
import {exists} from "../../utils/helpers.js";

/**
 * @param {object} builder
 * @param {string} fieldId
 * @returns {array}
 */
export const fieldsOnLevel = (builder, fieldId) => {
  const {byId, allIds} = builder;

  const field = byId[fieldId];

  if (!field) return [];

  const fieldsOnSameLevel =
    field.parentName && field.parentName !== "" && byId[field.parentName]
      ? byId[field.parentName].children
      : allIds;

  return fieldsOnSameLevel.filter(
    id => id in byId && byId[id].element === "field" && id !== fieldId
  );
};

/**
 * @param {object} builder
 * @param {string} fieldId
 * @returns {array}
 */
export const validNumberFieldOptions = (builder, fieldId) => {
  const {byId} = builder;
  const fieldsOnSameLevel = fieldsOnLevel(builder, fieldId);

  return fieldsOnSameLevel
    .filter(name => byId[name].type === "number")
    .map(name => ({
      name: name,
      label: byId[name].label
    }));
};

/**
 * @param {object} builder
 * @param {string} fieldId
 * @returns {array}
 */
export const validFieldOptions = (builder, fieldId) => {
  const {byId} = builder;
  const result = fieldsOnLevel(builder, fieldId);

  return result.map(name => ({
    name: name,
    label: byId[name].label
  }));
};

/**
 * @param {Array.<string>} ids
 * @param {object} builder
 * @param {object} result
 * @returns {object}
 */
export const getIndexedBuilderFields = (ids, builder, result = {}, count = 0) => {
  ids.map(id => {
    const item = builder.byId[id];
    result[id] = count++;

    if (item && item.children && item.children.length > 0) {
      count = getIndexedBuilderFields(item.children, builder, result, count);
    }
  });

  return count;
};

/**
 * @param {HTMLElement} selector
 * @param {object} yOffset
 * @param {HTMLElement} parent
 * @returns
 */
export const scrollTo = (selector, yOffset = -150, parent = window, behavior = "smooth") => {
  if (selector) {
    const y = selector.parentElement.getBoundingClientRect().top + yOffset;
    parent.scrollBy({behavior, top: y});
  }
};

export const scrollToScrollTop = (scrollTop, parent, behavior = "smooth") => {
  const y = parent.scrollTop - scrollTop;
  parent.scrollBy({behavior, top: y});
};

export const evaluateTemporalConditional = (value, depends, compare) => {
  if (depends === "weekday") {
    const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const weekday = value.get("day");
    return compare.includes(weekdays[weekday]);
  }

  // otherwise, absolute date
  const year = value.get("year");
  const month = value.get("month") + 1; // increment by 1 because dayjs is 0-based
  const date = value.get("date");
  for (let i = 0; i < compare.length; i++) {
    const frequencyParts = compare[i].split("_");
    const frequency = frequencyParts[1] || null;
    const end = frequencyParts[2] || null;

    const parts = frequencyParts[0].split("-");
    const targetYear = parseInt(parts[0], 10);
    const targetMonth = parseInt(parts[1], 10);
    const targetDate = parseInt(parts[2], 10);

    if (!frequency && targetYear === year && targetMonth === month && targetDate === date)
      return true;

    if (frequency === "weekly") {
      const diff = dayjs(value).diff(dayjs(parts), "weeks");
      const target = dayjs(parts).add(diff, "weeks");
      return target.get("date") === date;
    }

    if (frequency === "monthly") {
      let target = dayjs(parts);
      if (end) target = dayjs(value).endOf("month");
      return target.get("date") === date;
    }

    if (frequency === "annually" && targetMonth === month && targetDate === date) return true;
  }
  return false;
};

/**
 * Parse condtion object and evaluate
 * @param {string} value
 * @param {string} check
 * @param {string} compare
 * @param {object} depends
 * @returns {bool}
 */
export const evaluateConditional = (value, check, compare, depends = null, operator = null) => {
  let checkArray = check;
  let compareArray = compare;

  if (!Array.isArray(checkArray)) checkArray = [checkArray];
  if (!Array.isArray(compareArray)) compareArray = [compareArray];

  if (depends === "weekday" || depends === "date")
    return evaluateTemporalConditional(value, depends, compareArray);

  // Multiselect all unchecked
  if (check && compare && operator === "or" && value === null)
    for (let i = 0; i < compare.length; i++) {
      if (compare[i] === "unchecked") return false;
    }

  if (
    (value === undefined ||
      value === null ||
      value === "" ||
      check === undefined ||
      compare === undefined) &&
    depends?.type !== "upload"
  )
    return false;

  // start result at true only for 'and', since a single false result means return false
  let result = operator === "and";

  for (let i = 0; i < checkArray.length; i++) {
    let parsedCompare = compareArray[i];
    const currentCheck = checkArray[i];
    let parsedValue = value;

    parsedCompare = parsedCompare.includes("Other:")
      ? parsedCompare.split("Other: ")[1]
      : parsedCompare;
    parsedCompare = parsedCompare === "true" ? true : parsedCompare;
    parsedCompare = parsedCompare === "false" ? false : parsedCompare;

    if (depends?.type === "confirm") parsedCompare = parsedCompare ? "confirmed" : false;

    if (
      (depends?.type === "number" || depends?.type === "generated" || depends?.type === "range") &&
      !Number.isNaN(Number.parseFloat(parsedCompare))
    )
      parsedCompare = Number.parseFloat(parsedCompare);

    if (
      (depends?.type === "number" || depends?.type === "generated" || depends?.type === "range") &&
      parsedValue &&
      !Number.isNaN(Number.parseFloat(parsedValue))
    )
      parsedValue = Number.parseFloat(parsedValue);

    if (depends?.type === "switch") parsedValue = parsedValue ? depends.on : depends.off;

    if (depends?.type === "time") {
      const compareTime = parsedCompare.split(":");
      const compareHr = compareTime[0];
      const compareMn = compareTime[1];
      const compareDate = new Date();
      compareDate.setHours(compareHr);
      compareDate.setMinutes(compareMn);
      parsedCompare = compareDate;

      const valueTime = parsedValue.split(":");
      const valueHr = valueTime[0];
      const valueMn = valueTime[1];
      const valueDate = new Date();
      valueDate.setHours(valueHr);
      valueDate.setMinutes(valueMn);
      parsedValue = valueDate;
    }

    if (depends?.type === "date") {
      parsedCompare = new Date(`${parsedCompare}T00:00:00`);
      parsedValue = new Date(`${parsedValue}T00:00:00`);

      parsedCompare = parsedCompare.getTime();
      parsedValue = parsedValue.getTime();

      if (Number.isNaN(parsedCompare) || Number.isNaN(parsedValue)) parsedValue = null;
    }

    if (currentCheck === "contains" && exists(parsedValue))
      // Default Comparisons
      result = parsedValue.includes(parsedCompare);
    if (currentCheck === "===" && exists(parsedValue)) result = parsedValue === parsedCompare;
    if (currentCheck === "!==" && exists(parsedValue)) result = parsedValue !== parsedCompare;
    if (currentCheck === ">" && exists(parsedValue)) result = parsedValue > parsedCompare;
    if (currentCheck === "<" && exists(parsedValue)) result = parsedValue < parsedCompare;
    if (currentCheck === ">=" && exists(parsedValue)) result = parsedValue >= parsedCompare;
    if (currentCheck === "<=" && exists(parsedValue)) result = parsedValue <= parsedCompare;

    // Multiselect overrides
    if (depends?.type === "multiselect" && parsedValue) {
      if (parsedCompare === "checked") result = parsedValue.length === depends.options.length;
      if (parsedCompare === "unchecked") result = parsedValue.length === 0;
      if (parsedCompare === "one") result = parsedValue.length === 1;
      if (parsedCompare === "more") result = parsedValue.length > 0;
    }

    if (depends && depends.type === "upload") {
      if (currentCheck === "===") result = !!value;
      if (currentCheck === "!==") result = !value;
    }

    if (result && (!operator || operator === "or")) return result;
    if (!result && operator === "and") return result;
  }

  return operator === "and";
};

export const evaluateRange = (
  value,
  min,
  max,
  strictMin = false,
  strictMax = false,
  qualifier = null,
  infinite = false
) => {
  const valueParsed = Number.parseFloat(value);
  const aMinParsed = Number.parseFloat(min);
  const aMaxParsed = Number.parseFloat(max);

  let overMax = false;
  let belowMin = false;

  if (!exists(valueParsed) && !infinite) return false;

  if (exists(aMinParsed)) {
    if (["<", "ND", "≤"].includes(qualifier)) belowMin = true;
    else if (strictMin)
      belowMin = valueParsed < aMinParsed || (valueParsed === aMinParsed && qualifier !== ">");
    else belowMin = valueParsed < aMinParsed;
  }

  if (exists(aMaxParsed)) {
    if ([">", "≥"].includes(qualifier) || infinite) overMax = true;
    else if (strictMax)
      overMax =
        valueParsed > aMaxParsed ||
        (valueParsed === aMaxParsed && !["<", "ND"].includes(qualifier));
    else overMax = valueParsed > aMaxParsed;
  }

  return overMax || belowMin;
};

export const evaluateInvalid = (value, invalid) => {
  if (invalid && Array.isArray(invalid)) return invalid.includes(value);
  return invalid === value;
};

export const recurseToggle = (ids, builder, setTo) => {
  if (!builder) return null;

  const {byId} = builder;

  for (let i = 0; i < ids.length; i++) {
    const id = ids[i];
    if (byId[id] !== undefined) {
      byId[id].toggle = setTo;
      if (!byId[id].toggle) byId[id].preview = false;

      if (byId[id].children && byId[id].children.length > 0) {
        builder = recurseToggle(byId[id].children, builder, setTo);
      }
    }
  }

  return builder;
};

export const getToggleState = (ids, builder) => {
  if (!builder) return true;
  const {byId} = builder;
  let hasToggledOff = false;

  for (let i = 0; i < ids.length; i++) {
    const id = ids[i];
    if (byId[id] !== undefined) {
      if (!byId[id].toggle) return true;

      if (byId[id].children.length > 0) {
        hasToggledOff = getToggleState(byId[id].children, builder);
        if (hasToggledOff) return true;
      }
    }
  }
  return hasToggledOff;
};

/**
 * Get full name with ancestors
 * @param {object} element
 * @param {object} builder
 * @returns {string}
 */
export const getAncestryName = (element, builder) => {
  let curr = element;
  let currLabel = curr ? curr.label : "";
  while (curr && curr.parentName) {
    curr = builder.byId[curr.parentName];
    if (curr) currLabel = `${curr.label} ${currLabel}`;
  }
  return currLabel;
};

/* Transform formula through recursion to get base level formula (no generated fields as arguments)
 * @param {array} currentFormula
 * @param {object} builder
 * @param {object} targetElement
 * @returns {array}
 */
export const transformFormula = (currentFormula, builder) => {
  let lim = currentFormula.length;
  const temp = [...currentFormula];
  for (let i = 0; i < lim; i++) {
    // Case of primary field dependency (first item in depends) being a generated field
    if (
      temp[i].value in builder.byId &&
      builder.byId[temp[i].value].type === "generated" &&
      !temp[i].previous
    ) {
      const element = builder.byId[temp[i].value];
      // recurse until hitting base level (number field)
      const transformed = transformFormula(element.formula, builder);
      const toSplice = [
        {label: "(", value: "(", type: "grouping", start: true, formulaName: element.name},
        ...transformed,
        {label: ")", value: ")", type: "grouping", end: true, formulaName: element.name}
      ];
      temp.splice(i, 1, ...toSplice);
      lim = temp.length;
    }
  }
  return temp;
};

/**
 * Build formula string for evaluation
 * @param {array} currentFormula
 * @param {bool} absoluteValue
 * @returns {string}
 */
export const buildFormulaString = (currentFormula, builder, absoluteValue) => {
  let formulaString = "result=";
  if (absoluteValue) formulaString = `${formulaString}abs(`;
  if (currentFormula) {
    for (let i = 0; i < currentFormula.length; i++) {
      const f = currentFormula[i];
      let fieldName = f.value;

      const hasAbsoluteValue =
        f?.formulaName &&
        (builder.byId[f.formulaName]?.absoluteValue ||
          builder.byId[f.formulaName]?.trueDepends?.filter(
            key => builder.byId[key]?.type === "time"
          )?.length > 0);

      if (f.start && hasAbsoluteValue) formulaString = `${formulaString}abs(`;
      if (f.previous) fieldName = `${fieldName}_previous`;
      if (fieldName.includes("Other: "))
        formulaString = `${formulaString}Decimal(${fieldName.replace("Other: ", "")})`;
      else formulaString = `${formulaString}${fieldName}`;

      if (f.end && hasAbsoluteValue) formulaString = `${formulaString})`;
    }
    if (absoluteValue) formulaString = `${formulaString})`;
  }
  return formulaString;
};

/**
 * Returns formatted formula field object to be saved into builder
 * @param {object} values
 * @param {object} builder
 * @param {string} mode
 * @param {object} targetElement
 * @returns {object}
 */
export const formatFormulaToSave = (builder, mode, targetElement, values = null) => {
  const coalescedValues = values || targetElement || {};
  const {formula, absoluteValue} = coalescedValues;

  // add any linked fields to the depends []    const depends =
  let depends;
  let trueDepends;
  let valuesWithLinkedFields;

  const {
    linkedComponent: newParentComponent,
    linkedGroup: newParentGroup,
    divideByZeroDefault,
    hasARange,
    hasPrecision,
    precision
  } = coalescedValues;

  const hasDivideByZeroDefault =
    coalescedValues.hasDivideByZeroDefault || exists(coalescedValues.divideByZeroDefault);

  if (mode === "edit") {
    const nestedExpanded = transformFormula(formula, builder) ?? formula;
    depends = [];
    trueDepends = [];
    nestedExpanded
      .filter(({previous}) => !previous)
      .forEach(part => {
        if (
          part.type === "variable" &&
          part.value in builder.byId &&
          !trueDepends.includes(part.value)
        )
          trueDepends.push(part.value);
      });
    formula
      .filter(({previous}) => !previous)
      .forEach(part => {
        if (part.type === "variable" && part.value in builder.byId && !depends.includes(part.value))
          depends.push(part.value);
      });

    let elementParent;
    if (newParentComponent && newParentComponent !== "") elementParent = newParentComponent;
    else if (newParentGroup && newParentGroup !== "") elementParent = newParentGroup;
    else elementParent = targetElement.parentName;

    valuesWithLinkedFields = {
      ...targetElement,
      ...coalescedValues,
      formula,
      formulaString: buildFormulaString(nestedExpanded, builder, absoluteValue),
      depends,
      trueDepends,
      trueFormula: nestedExpanded,
      parentName: elementParent,
      precision: hasPrecision ? precision : null,
      divideByZeroDefault: hasDivideByZeroDefault ? divideByZeroDefault : null,
      aMin: hasARange ? coalescedValues.aMin : null,
      aMax: hasARange ? coalescedValues.aMax : null
    };
  } else {
    depends = [];
    trueDepends = [];
    const temp = transformFormula(formula, builder) ?? formula;
    temp
      .filter(({previous}) => !previous)
      .forEach(part => {
        if (
          part.type === "variable" &&
          part.value in builder.byId &&
          !trueDepends.includes(part.value)
        )
          trueDepends.push(part.value);
      });
    formula
      .filter(({previous}) => !previous)
      .forEach(part => {
        if (part.type === "variable" && part.value in builder.byId && !depends.includes(part.value))
          depends.push(part.value);
      });
    valuesWithLinkedFields = {
      ...coalescedValues,
      formula,
      formulaString: buildFormulaString(temp, builder, absoluteValue),
      depends,
      trueDepends,
      parentName: targetElement.parentName,
      trueFormula: temp,
      precision: hasPrecision ? precision : null,
      divideByZeroDefault: hasDivideByZeroDefault ? divideByZeroDefault : null,
      aMin: hasARange ? coalescedValues.aMin : null,
      aMax: hasARange ? coalescedValues.aMax : null
    };
  }
  delete valuesWithLinkedFields.other;
  delete valuesWithLinkedFields.fieldToAdd;
  delete valuesWithLinkedFields.addMode;
  delete valuesWithLinkedFields.linkedComponent;
  delete valuesWithLinkedFields.linkedGroup;
  if (valuesWithLinkedFields.units && valuesWithLinkedFields.units.includes("Other: ")) {
    valuesWithLinkedFields.units = valuesWithLinkedFields.units.replace("Other: ", "");
  }
  return valuesWithLinkedFields;
};

/**
 * @param {object} updated
 * @param {object} nameMap
 */
export const resolveDependencyMismatches = (updated, nameMap) => {
  const {byId} = updated;
  const formulasToReset = [];
  Object.entries(nameMap).forEach(([oldName, newName]) => {
    // updating names in depends from field{idx} -> generated key
    if (byId[newName].depends)
      byId[newName].depends = byId[newName].depends.map(dependency => nameMap[dependency]);

    // updating names in trueDepends from field{idx} -> generated key
    if (byId[newName].trueDepends)
      byId[newName].trueDepends = byId[newName].trueDepends.map(dependency => nameMap[dependency]);

    if (byId[newName].formula) {
      formulasToReset.push(newName);
      const newFormula = [];
      // updating names in formula from field{idx} -> generated key
      byId[newName].formula.forEach(part => {
        newFormula.push({
          ...part,
          value: part.value in nameMap ? nameMap[part.value] : part.value
        });
      });
      byId[newName].formula = newFormula;
    }

    if (byId[newName].trueFormula) {
      const newFormula = [];
      // updating names in trueFormula from field{idx} -> generated key
      byId[newName].trueFormula.forEach(part => {
        newFormula.push({
          ...part,
          value: part.value in nameMap ? nameMap[part.value] : part.value
        });
      });
      byId[newName].trueFormula = newFormula;
    }
    if (byId[newName].condition && byId[newName].condition.list) {
      // updating names in condition from field{idx} -> generated key
      const newList = [];
      byId[newName].condition.list.forEach(cond => {
        if (cond.depends in nameMap) {
          newList.push({
            ...cond,
            depends: cond.depends in nameMap ? nameMap[cond.depends] : cond.depends
          });
        } else {
          newList.push({...cond});
        }
      });
      byId[newName].condition = {
        ...byId[newName].condition,
        list: [...newList.map(cond => ({...cond}))]
      };
    }

    if (byId[newName].parentName in byId && byId[byId[newName].parentName].help) {
      byId[byId[newName].parentName].help = byId[byId[newName].parentName].help.map(h => {
        if (h.id === oldName) return {key: byId[newName].label, value: h.value, id: newName};
        return h;
      });
    }

    if (byId[newName].formulaString)
      // updating names in formulaString from field{idx} -> generated key
      Object.entries(nameMap).forEach(([o, n]) => {
        if (byId[newName].formulaString.includes(o))
          byId[newName].formulaString = byId[newName].formulaString.replaceAll(o, n);
      });
  });
};

/**
 * Determine whether formula makes circular references
 * @param {array} formula
 * @param {object} builder
 * @param {object} targetElement
 * @param {number} level
 * @returns {bool}
 */
export const isCircularFormula = (formula, builder, targetElement, level = 0) => {
  for (let i = 0; i < formula.length; i++) {
    if (
      formula[i].value in builder.byId &&
      builder.byId[formula[i].value].type === "generated" &&
      !formula[i].previous
    ) {
      if (level && formula[i].value === targetElement.name) return true;

      const element = builder.byId[formula[i].value];
      // recurse until hitting base level (number field)
      const result = isCircularFormula(element.formula, builder, targetElement, level + 1);
      if (result) return true;
    }
  }
  return false;
};

/**
 * Validate formula array, returning true if valid, false otherwise
 * @param {array} formula
 * @param {object} builder
 * @param {object} targetElement
 * @returns {bool}
 */
export const validateFormula = (formula, builder, targetElement) => {
  if (!formula || formula.length < 3) return false;
  let prevSymbol;
  let currSymbol;
  let nextSymbol;
  let grouping = 0;

  for (let i = 0; i < formula.length; i++) {
    if (i !== 0) prevSymbol = formula[i - 1];
    currSymbol = formula[i];
    if (i !== formula.length - 1) nextSymbol = formula[i + 1];
    else nextSymbol = null;

    if (currSymbol.value === "(") grouping += 1;
    if (currSymbol.value === ")") {
      if (grouping === 0) return false;
      grouping -= 1;
    }

    if (
      (!prevSymbol || prevSymbol.value === "(") &&
      !(currSymbol.type === "variable" || currSymbol.value === "(")
    )
      return false;

    if (
      (!nextSymbol || nextSymbol.value === ")") &&
      !(currSymbol.type === "variable" || currSymbol.value === ")")
    )
      return false;

    if (prevSymbol && prevSymbol.value === ")" && currSymbol.type !== "operation") return false;

    if (
      prevSymbol &&
      prevSymbol.type === "operation" &&
      !(currSymbol.type === "variable" || currSymbol.value === "(")
    )
      return false;

    if (
      prevSymbol &&
      prevSymbol.type === "variable" &&
      !(currSymbol.type === "operation" || currSymbol.value === ")")
    )
      return false;
  }

  if (grouping > 0) return false;

  const isCircular = isCircularFormula(formula, builder, targetElement);

  if (isCircular) return false;

  return true;
};

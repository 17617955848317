import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {useFormContext} from "react-hook-form";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faClose,
  faGreaterThan,
  faGreaterThanEqual,
  faLessThan,
  faLessThanEqual,
  faTrash
} from "@fortawesome/free-solid-svg-icons";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Utils
import {getAncestryName} from "./helpers.js";
import {COMPONENT, FIELD, GROUP} from "../../utils/builder.js";
import {exists} from "../../utils/helpers.js";

// Components
import Formula from "../../components/Formula.js";
import SearchSelect from "../../components/SearchSelect.js";
import Dropdown from "../../components/Dropdown.js";
import Help from "../../components/Help.js";
import {
  InputSelect,
  InputText,
  InputCheck,
  InputNumber,
  InputTextArea,
  InputRadioGroup,
  InputError
} from "../../components/form/FormInputs.js";

// Style
import {flex} from "../../style/components/mixins.js";
import {cross} from "../../style/components/shapes.js";
import {voice} from "../../style/components/typography.js";
import {bp} from "../../style/components/breakpoints.js";
import {border, pad, radius} from "../../style/components/variables.js";
import {
  Button,
  FormGroup,
  FormField,
  Inline,
  Line,
  Section,
  Error,
  Label,
  Input,
  Pill,
  Text,
  Abbr
} from "../../style/components/general.js";

const FormulaEditor = ({targetElement, builder, units, mode, hasPrimaryAddress}) => {
  const isMounted = useMountedState();

  const {api: apiFieldTags} = useApi("field-tags");

  const [error, setError] = useState(null);
  const [currentCount, setCurrentCount] = useState(1);
  const [results, setResults] = useState([]);
  const [allOptions, setAllOptions] = useState(null);
  const [variableError, setVariableError] = useState(null);
  const [dependsDropdownVal, setDependsDropdownVal] = useState(null);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [canDivideByZero, setCanDivideByZero] = useState(false);
  const [unitResults, setUnitResults] = useState([]);
  const [fieldTags, setFieldTags] = useState();
  const [tagResults, setTagResults] = useState();

  const form = useFormContext();
  const {
    watch,
    setValue,
    register,
    formState: {errors, submitCount},
    clearErrors
  } = form;

  const formula = watch("formula");
  const addMode = watch("addMode");
  const other = watch("other");
  const hasSetPoint = watch("hasSetPoint");
  const hasARange = watch("hasARange");
  const strictMin = watch("strictMin");
  const strictMax = watch("strictMax");
  const hasDivideByZeroDefault = watch("hasDivideByZeroDefault");
  const linkedGroup = watch("linkedGroup");
  const linkedComponent = watch("linkedComponent");
  const unit = watch("units");
  const watchFieldTag = watch("tag");
  const rMin = watch("min");
  const rMax = watch("max");

  const defaultRestrictedRangeException = useMemo(() => {
    if (exists(rMin) && exists(rMax))
      return `Leaving this blank defaults to: 'Value must be > ${rMin}' or 'Value must be < ${rMax}'`;
    if (exists(rMin)) return `Leaving this blank defaults to: 'Value must be > ${rMin}'`;
    if (exists(rMax)) return `Leaving this blank defaults to: 'Value must be < ${rMax}'`;
    return "Override default violation message";
  }, [rMin, rMax]);

  useEffect(() => {
    setValue("other");
  }, [addMode, setValue]);

  useEffect(() => {
    if (!fieldTags)
      apiFieldTags.callGet().then(({status, data}) => {
        if (status && data) {
          setFieldTags(data);
          setTagResults(data);
        }
      });
  }, [fieldTags, apiFieldTags]);

  useEffect(() => {
    if (isMounted() && !allOptions) {
      const external = Object.values(builder.byId)
        .filter(
          el =>
            el &&
            el.parentName !== targetElement.parentName &&
            el.element === FIELD &&
            (["number", "range"].includes(el.type) || el.type === "generated")
        )
        .flatMap(el =>
          el?.trueDepends?.filter(key => builder.byId[key]?.type === "time")?.length === 0
            ? [
                {
                  label: `${getAncestryName(el, builder)} - Current`,
                  value: el.name,
                  fieldLabel: el.label
                },
                {
                  label: `${getAncestryName(el, builder)} - Previous`,
                  value: `${el.name}_previous`,
                  fieldLabel: el.label
                }
              ]
            : [
                {
                  label: getAncestryName(el, builder),
                  value: el.name,
                  fieldLabel: el.label
                }
              ]
        );

      const tempSiblings = Object.values(builder.byId)
        .filter(
          el =>
            el &&
            el.element === FIELD &&
            (["number", "range"].includes(el.type) || el.type === "generated") &&
            (mode !== "edit" || el.name !== targetElement.name) &&
            el.parentName === targetElement.parentName
        )
        .flatMap(el =>
          (el?.trueDepends?.filter(key => builder.byId[key]?.type === "time")?.length || 0) === 0
            ? [
                {
                  label: `${getAncestryName(el, builder)} - Current`,
                  value: el.name,
                  fieldLabel: el.label
                },
                {
                  label: `${getAncestryName(el, builder)} - Previous`,
                  value: `${el.name}_previous`,
                  fieldLabel: el.label
                }
              ]
            : [
                {
                  label: getAncestryName(el, builder),
                  value: el.name,
                  fieldLabel: el.label
                }
              ]
        );

      const weatherOptions = [
        {
          label: "Rainfall",
          value: "rainfall",
          fieldLabel: "rainfall"
        },
        {
          label: "Cumulative Rainfall",
          value: "cumulative",
          fieldLabel: "cumulative"
        }
      ];

      if (hasPrimaryAddress) setAllOptions([...weatherOptions, ...tempSiblings, ...external]);
      else setAllOptions([...tempSiblings, ...external]);

      if (external.length)
        setDropdownOptions([
          ...weatherOptions,
          ...tempSiblings,
          {label: "Other field", name: "external"}
        ]);
      // otherwise, if siblings exist provide them as options
      else setDropdownOptions([...weatherOptions, ...tempSiblings]);
    }
  }, [allOptions, builder, hasPrimaryAddress, isMounted, mode, targetElement]);

  useEffect(() => {
    setError(null);
    clearErrors("formula");

    let divideByZero = false;
    let prevDivision = false;
    let groupingLevel = 0;
    for (let i = 0; i < formula.length; i++) {
      const part = formula[i];
      if (prevDivision) {
        if (part.value in builder.byId || ["rainfall", "cumulative"].includes(part.value)) {
          divideByZero = true;
          break;
        } else if (part.value === "(") groupingLevel++;
        else if (part.value === ")") groupingLevel--;
        if (groupingLevel < 0) groupingLevel = 0;
        if (!groupingLevel && part.value !== "(") prevDivision = false;
      }
      if (part.value === "/") prevDivision = true;
    }

    if (divideByZero) setCanDivideByZero(true);
    else setCanDivideByZero(false);
  }, [builder, clearErrors, formula]);

  useEffect(() => {
    setVariableError(null);
  }, [other]);

  useEffect(() => {
    if (linkedComponent) {
      const grandparent =
        linkedComponent && linkedComponent in builder.byId
          ? builder.byId[linkedComponent].parentName
          : null;
      if (
        linkedGroup &&
        linkedGroup !== "" &&
        linkedGroup !== targetElement.parentName &&
        (!grandparent || linkedGroup !== grandparent)
      )
        setValue("linkedComponent", null);
    }
  }, [linkedGroup, setValue, targetElement, builder, linkedComponent]);

  const addToFormula = item => {
    if (mode !== "edit" && item.value === targetElement.name && !item.previous)
      setCurrentCount(currentCount + 1);
    setValue("formula", [...formula, item]);
  };

  const addVariable = item => {
    let fieldName = item.value;
    let fieldLabel = item.value;

    if (fieldName === "rainfall") fieldLabel = "Rainfall";
    if (fieldName === "cumulative") fieldLabel = "Cumulative";

    const previous = !!fieldName.match(/.*_previous$/);
    fieldName = fieldName.replace("_previous", "");

    const formatted = {
      value: fieldName,
      type: "variable",
      previous
    };

    if (!(fieldName in builder.byId)) formatted.label = fieldLabel;

    addToFormula(formatted);
  };

  const componentInSetGroup = (parentName, currentGroup) => {
    if (!currentGroup) currentGroup = linkedGroup;
    const targetParent = builder.byId[targetElement.parentName];
    if (targetParent && targetParent.element === COMPONENT) {
      if (!currentGroup || currentGroup === "") return true;
      return currentGroup === parentName;
    }
    if (targetParent && targetParent.element === GROUP) {
      if (!currentGroup || currentGroup === "") return targetElement.parentName === parentName;
      return currentGroup === parentName;
    }
    if (!currentGroup || currentGroup === "") return true;
    return currentGroup === parentName;
  };

  const getLinkedGroup = (grandparent, linkedGroups) => {
    const groups = linkedGroups.map(item => item.name);
    if (groups.includes(targetElement.parentName)) return targetElement.parentName;
    if (grandparent && groups.includes(grandparent)) return grandparent;
    return null;
  };

  const renderFieldOptions = () => {
    const linkedGroups = Object.values(builder.byId).filter(
      ({element: el, toggle}) => el === GROUP && toggle
    );
    const grandparent =
      targetElement.parentName in builder.byId
        ? builder.byId[targetElement.parentName].parentName
        : null;

    const currentGroup = getLinkedGroup(grandparent, linkedGroups);
    const linkedComponents = Object.values(builder.byId).filter(
      ({element: el, parentName, toggle}) =>
        el === COMPONENT && componentInSetGroup(parentName, linkedGroup || currentGroup) && toggle
    );
    return (
      <>
        {linkedGroups.length > 0 && (
          <FormField>
            <InputSelect
              name="linkedGroup"
              options={linkedGroups}
              label="Linked Group"
              placeholder="Link to Group..."
              defaultValue={currentGroup}
              testId="edit.linkedGroup"
            />
          </FormField>
        )}
        {linkedComponents.length > 0 && (
          <FormField>
            <InputSelect
              name="linkedComponent"
              options={linkedComponents}
              placeholder="Link to Component..."
              label="Linked Component"
              defaultValue={
                linkedComponents.map(item => item.name).includes(targetElement.parentName)
                  ? targetElement.parentName
                  : null
              }
              testId="edit.linkedComponent"
            />
          </FormField>
        )}
      </>
    );
  };

  const renderFormulaOptions = () => {
    const aMaxExists = exists(watch("aMax"));
    const aMinExists = exists(watch("aMin"));

    return (
      <StyledSection>
        <Label bold>Optional Configuration</Label>
        <InputCheck minWidth="90%" name="absoluteValue">
          Return absolute value of calculated value?
        </InputCheck>

        <InputCheck name="hasPrecision">
          Custom rounding precision? System default will round to 2 places.
        </InputCheck>
        {watch("hasPrecision") && (
          <FormField>
            <InputSelect
              name="precision"
              placeholder="Precision"
              options={[0, 1, 2, 3, 4, 5, 6, 7, 8]}
            />
          </FormField>
        )}

        <InputCheck minWidth="90%" name="hasQualifier" testId="addField.hasQualifier">
          Allow qualifier
          <Help>Atach qualifier to response (including: &gt;,&lt;,&gt;=,&lt;=,ND,DNQ,EST)</Help>
        </InputCheck>

        <InputCheck minWidth="90%" name="hasSetPoint">
          Provide set point
          <Help>
            Show these bounds on charts that are created. Does not directly affect submission.
          </Help>
        </InputCheck>
        {hasSetPoint && (
          <FormGroup inline>
            <Inline>
              <InputNumber name="setLow" placeholder="Min" />
              <Line width={15} />
              <InputNumber name="setHigh" placeholder="Max" />
            </Inline>
          </FormGroup>
        )}

        <>
          <FormField>
            <InputCheck name="hasRange" testId="addField.hasRange">
              Provide restricted min-max range
              <Help>Any values outside this range will not be allowed for submission.</Help>
            </InputCheck>
          </FormField>
          {watch("hasRange") && (
            <FormGroup>
              <FormField>
                <InputNumber name="min" placeholder="Min" testId="addField.restrictedMin" />
              </FormField>
              <FormField>
                <InputNumber name="max" placeholder="Max" testId="addField.restrictedMax" />
              </FormField>

              <FormField>
                <InputTextArea
                  name="restrictedRangeException"
                  placeholder={defaultRestrictedRangeException}
                  required={false}
                  testId="addField.restrictedRangeException"
                />
              </FormField>
            </FormGroup>
          )}
        </>

        <InputCheck minWidth="90%" name="hasARange">
          <Text inline>
            Enable unacceptable parameter alert
            <Help>
              When entry is out of acceptable range a note will be required and an email
              notification will be sent.
            </Help>
          </Text>
        </InputCheck>
        {hasARange && (
          <FormGroup>
            <FormField>
              <Inline>
                <StrictToggle
                  type="button"
                  onClick={() => setValue("strictMin", !strictMin)}
                  isStrict={strictMin ? 1 : 0}
                  disabled={!aMinExists}>
                  <Abbr title={`Toggle ${strictMin ? "Greater Than or Equal" : "Greater Than"}`}>
                    <FontAwesomeIcon icon={strictMin ? faGreaterThan : faGreaterThanEqual} />
                  </Abbr>
                </StrictToggle>
                <InputNumber name="aMin" placeholder="Lower Bound" />
              </Inline>
            </FormField>
            <FormField>
              <Inline>
                <StrictToggle
                  type="button"
                  onClick={() => setValue("strictMax", !strictMax)}
                  isStrict={strictMax ? 1 : 0}
                  disabled={!aMaxExists}>
                  <Abbr title={`Toggle ${strictMax ? "Less Than or Equal" : "Less Than"}`}>
                    <FontAwesomeIcon icon={strictMax ? faLessThan : faLessThanEqual} />
                  </Abbr>
                </StrictToggle>
                <InputNumber name="aMax" placeholder="Upper Bound" />
              </Inline>
            </FormField>
            <StyledLabel>Provide prompt for explanation of unacceptable parameter?</StyledLabel>
            <InputTextArea
              name="rangeException"
              placeholder={targetElement["rangeException"]}
              required={false}
            />
          </FormGroup>
        )}

        <InputCheck name="hidden">Hide field?</InputCheck>
      </StyledSection>
    );
  };

  // re-ordering list
  const reorderFormula = result => {
    const {destination, source} = result;
    const tempFormula = [...formula];
    if (destination && destination.droppableId === "trash") {
      if (
        tempFormula[source.index].value === targetElement.name &&
        !tempFormula[source.index].previous
      ) {
        if (mode === "edit" && currentCount === 1) {
          setError("Error: Cannot delete primary formula field");
          return;
        }
        setCurrentCount(currentCount - 1);
      }
      tempFormula.splice(source.index, 1);
      setValue("formula", tempFormula);
    } else if (source && destination && source.index !== destination.index) {
      const movingPart = {...formula[source.index]};
      let sourceOffset = 0;
      let destinationOffset = 1;
      if (destination.index < source.index) {
        sourceOffset = 1;
        destinationOffset = 0;
      }
      tempFormula.splice(destination.index + destinationOffset, 0, {...movingPart});
      tempFormula.splice(source.index + sourceOffset, 1);
      setValue("formula", tempFormula);
    }
  };

  const search = query => {
    if (query) {
      const lower = query.toLowerCase();
      const tempResults = allOptions.filter(option =>
        option.fieldLabel.toLowerCase().includes(lower)
      );
      tempResults.sort(
        (a, b) =>
          a.fieldLabel.toLowerCase().indexOf(lower) - b.fieldLabel.toLowerCase().indexOf(lower)
      );
      setResults(tempResults);
    } else {
      setResults(allOptions);
    }
  };

  const searchUnits = query => {
    if (!query) setUnitResults(units);
    setUnitResults(units.filter(u => u.toLowerCase().includes(query.toLowerCase())));
  };

  return (
    <>
      <FormField data-testid="formula.labelContainer">
        <InputText
          name="label"
          label="Field Name"
          placeholder="Please provide a name to represent the value collected."
          testId="formula.label"
          required
        />
      </FormField>

      <FormField>
        <div>
          <Label bold>FIELD TYPE *</Label>
          <Text>GENERATED</Text>
        </div>
      </FormField>
      <FormField>
        <FormWrapper>
          <FormulaParts>
            <MenuWrapper>
              <MenuBar>
                <Operation
                  type="button"
                  onClick={() => addToFormula({label: "+", value: "+", type: "operation"})}
                  data-testid="formula.plusOp">
                  +
                </Operation>
                <Operation
                  type="button"
                  onClick={() => addToFormula({label: "-", value: "-", type: "operation"})}
                  data-testid="formula.subOp">
                  -
                </Operation>
                <Operation
                  type="button"
                  onClick={() => addToFormula({label: "×", value: "*", type: "operation"})}
                  data-testid="formula.multOp">
                  ×
                </Operation>
                <Operation
                  type="button"
                  onClick={() => addToFormula({label: "÷", value: "/", type: "operation"})}
                  data-testid="formula.divOp">
                  ÷
                </Operation>
                <Operation
                  type="button"
                  onClick={() => addToFormula({label: "(", value: "(", type: "grouping"})}
                  data-testid="formula.leftParen">
                  (
                </Operation>
                <Operation
                  type="button"
                  onClick={() => addToFormula({label: ")", value: ")", type: "grouping"})}
                  data-testid="formula.rightParen">
                  )
                </Operation>
              </MenuBar>
            </MenuWrapper>
            <InputRadioGroup
              name="addMode"
              options={["Select Field", "Enter Constant"]}
              testId="formula.modeSelect"
            />
            <div style={{marginBottom: `${pad}px`}}>
              {addMode === "Select Field" ? (
                <div>
                  {dependsDropdownVal !== "external" ? (
                    <FormField>
                      <Inline>
                        <Dropdown
                          testId="formula.fieldSelect"
                          options={dropdownOptions}
                          placeholder="Select..."
                          selection={dependsDropdownVal}
                          setSelection={val => setDependsDropdownVal(val)}
                          showPlaceholder
                        />
                        {error && <Error>{error}</Error>}
                        <AddVariable
                          type="button"
                          onClick={() => {
                            if (dependsDropdownVal) {
                              addVariable({
                                value: dependsDropdownVal
                              });
                            } else setVariableError("Please select a variable to add.");
                          }}
                          data-testid="formula.addVariable">
                          Insert
                        </AddVariable>
                      </Inline>
                    </FormField>
                  ) : (
                    <FieldDropdown>
                      <Inline>
                        <SearchSelect
                          results={results}
                          setResults={setResults}
                          search={search}
                          add={addVariable}
                        />
                        <DeleteButton onClick={() => setDependsDropdownVal("")}>
                          <IconWrapper>
                            <CloseIcon data-testid="formula.cancelSearch" />
                          </IconWrapper>
                        </DeleteButton>
                      </Inline>
                    </FieldDropdown>
                  )}
                </div>
              ) : (
                <>
                  <Inline>
                    <Other
                      type="text"
                      placeholder="Provide value..."
                      name="other"
                      required
                      {...register("other")}
                    />
                    <AddVariable
                      type="button"
                      onClick={() => {
                        if (other) {
                          addToFormula({
                            label: other,
                            value: `Other: ${other}`,
                            type: "variable",
                            previous: false
                          });
                        } else setVariableError("Please select a variable to add.");
                      }}
                      data-testid="formula.addVariable">
                      Insert
                    </AddVariable>
                  </Inline>
                  {variableError && <StyledErrorText>{variableError}</StyledErrorText>}
                </>
              )}
            </div>
            <Label bold>FORMULA *</Label>
            <DragDropContext onDragEnd={reorderFormula}>
              <FormulaWrapper id="formula">
                <Droppable droppableId="formula" direction="horizontal">
                  {provided => (
                    <Inner {...provided.droppableProps} ref={provided.innerRef}>
                      <Formula outerProvided={provided} byId={builder.byId} />
                    </Inner>
                  )}
                </Droppable>
              </FormulaWrapper>
              {formula && formula.length > 0 && (
                <Droppable droppableId="trash" direction="horizontal" on>
                  {trashProvided => (
                    <>
                      <Trash {...trashProvided.droppableProps} ref={trashProvided.innerRef}>
                        <FontAwesomeIcon icon={faTrash} />
                      </Trash>

                      <span style={{display: "none"}}>{trashProvided.placeholder}</span>
                    </>
                  )}
                </Droppable>
              )}
            </DragDropContext>
          </FormulaParts>
          <div>
            <ErrorWrapper>
              {error ? (
                <InputError errors={{formula: error}} name="formula" atBottom={false} />
              ) : (
                <InputError errors={errors || {}} name="formula" atBottom={false} />
              )}
            </ErrorWrapper>
            {formula && formula.length > 0 && (
              <Clear
                type="button"
                onClick={() =>
                  setValue("formula", [
                    {
                      value: targetElement.name,
                      type: "variable",
                      previous: false
                    }
                  ])
                }
                data-testid="formula.clear">
                Clear
              </Clear>
            )}
            {canDivideByZero && (
              <InputCheck minWidth="90%" name="hasDivideByZeroDefault">
                Division by zero is possible given the current formula. Provide a default value for
                when this occurs?
              </InputCheck>
            )}
            {canDivideByZero && hasDivideByZeroDefault && (
              <InputNumber name="divideByZeroDefault" placeholder="Default" />
            )}
          </div>
        </FormWrapper>
      </FormField>

      {fieldTags?.length > 0 && (
        <FormField>
          {!watchFieldTag ? (
            <SearchWrapper>
              <SearchSelect
                testId="addField.tags"
                label="Tag"
                placeholder="Search tags..."
                results={tagResults}
                setResults={setTagResults}
                search={query => {
                  if (!query) setTagResults(fieldTags);
                  else
                    setTagResults(prev =>
                      prev.filter(({name: tagName}) =>
                        tagName.toLowerCase().includes(query.toLowerCase())
                      )
                    );
                }}
                add={selected =>
                  setValue("tag", typeof selected === "string" ? selected : selected.name)
                }
                showAll
                allowNew
              />
            </SearchWrapper>
          ) : (
            <SelectedContainer data-testid="addField.tag-selected">
              <Abbr>{watchFieldTag}</Abbr>
              &nbsp;
              <IconButton onClick={() => setValue("tag", null, {shouldValidate: !!submitCount})}>
                <FontAwesomeIcon icon={faClose} />
              </IconButton>
            </SelectedContainer>
          )}
          <InputError name="tag" errors={errors} />
        </FormField>
      )}
      <FormField id="units">
        <Label bold>UNITS *</Label>
        {!unit ? (
          <SearchWrapper>
            <SearchSelect
              placeholder="Search units..."
              results={unitResults}
              setResults={setUnitResults}
              search={searchUnits}
              add={val => {
                setValue("units", val, {shouldValidate: !!submitCount});
              }}
              showAll
            />
          </SearchWrapper>
        ) : (
          <SelectedContainer>
            <SelectedName>
              <Abbr>{unit}</Abbr>
            </SelectedName>
            &nbsp;
            <IconButton
              onClick={() => {
                setValue("units", null, {shouldValidate: !!submitCount});
              }}>
              <FontAwesomeIcon icon={faClose} />
            </IconButton>
          </SelectedContainer>
        )}
        <InputError name="units" errors={errors} />
      </FormField>
      {mode === "edit" && <FormField>{renderFieldOptions()}</FormField>}

      {renderFormulaOptions()}
    </>
  );
};

FormulaEditor.propTypes = {
  targetElement: PropTypes.objectOf(PropTypes.any).isRequired,
  builder: PropTypes.objectOf(PropTypes.any).isRequired,
  units: PropTypes.arrayOf(PropTypes.string),
  tags: PropTypes.arrayOf(PropTypes.string),
  mode: PropTypes.string,
  hasPrimaryAddress: PropTypes.bool
};

FormulaEditor.defaultProps = {
  units: [],
  tags: [],
  mode: "create",
  hasPrimaryAddress: false
};

// Style Overrides
const StyledErrorText = styled(Error)`
  margin: 0 0 ${pad}px 0;
  color: ${({theme}) => theme.error};
  position: relative;
  display: block;
`;

const FormWrapper = styled.div`
  ${flex("row", "wrap", "start", "start")};
  width: 100%;
`;

const FormulaParts = styled(FormGroup)`
  width: 100%;
  margin: ${pad}px 0 -${pad / 2}px 0;
  padding: 0;
`;

const Operation = styled(Button)`
  margin-left: ${pad}px;

  :first-child {
    margin-left: 0;
  }
`;

const Clear = styled(Button)`
  margin-bottom: ${pad * 1.5}px;
`;

const MenuBar = styled(Inline)``;

const FieldDropdown = styled.div`
  margin: 0;
  width: 180px;
  height: min-content;
  border-radius: ${radius};
  background-color: ${({theme}) => theme.primary};
  ${bp(3)} {
    margin-bottom: ${pad}px;
  }
`;

const MenuWrapper = styled(FormGroup)`
  width: min-content;
  margin-bottom: -${pad}px;
  border: ${border} solid ${({theme}) => theme.primary};
  border-radius: ${radius};
  color: ${({theme}) => theme.primary};
  padding: ${pad}px;
  height: min-content;
`;

const FormulaWrapper = styled.div`
  width: 100%;
  border-radius: ${radius};
  border: ${border} solid ${({theme}) => theme.secondary};
  margin-bottom: ${pad}px;
  height: 64px;
`;

const Inner = styled.div`
  padding: 20px;
  overflow-x: auto;
  box-sizing: border-box;
`;

const Trash = styled.div`
  position: absolute;
  right: ${pad * 2}px;
  bottom: 33px;
`;

const Other = styled(Input)`
  max-width: 300px;
`;

const AddVariable = styled(Button)`
  margin-left: 0;
  margin-right: 0;
  margin-top: ${pad}px;
  white-space: nowrap;
  overflow: visible;
  width: 100%;
  max-width: none;

  ${bp(3)} {
    width: min-content;
    margin-top: 0;
    margin-left: ${pad}px;
  }
`;

const ErrorWrapper = styled.div`
  margin-bottom: ${pad / 2}px;
`;

const DeleteButton = styled(Button)`
  background-color: transparent;
  padding: ${pad / 2}px ${pad / 4}px;
`;

const CloseIcon = styled.div`
  ${({theme}) => cross("100%", theme.secondary)};
`;

const IconWrapper = styled.div`
  position: relative;
  width: 10px;
  height: 10px;
`;

const StyledSection = styled(Section)`
  margin-bottom: ${pad * 3}px;
`;

const StyledLabel = styled(Label)`
  margin-top: ${pad * 1.5}px;
`;

const SelectedContainer = styled(Pill)`
  width: min-content;
  height: min-content;
  max-width: 100%;
  margin: 0 ${pad}px ${pad}px 0;
  color: ${({theme}) => theme.tertiary};
`;

const SelectedName = styled(Text)`
  ${voice.normal};
  width: min-content;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  margin-right: ${pad / 2}px;
`;

const IconButton = styled(Button)`
  ${voice.normal};
  background-color: transparent;
  width: min-content;
  padding: 0;

  svg {
    fill: ${({theme}) => theme.secondary};
  }
`;

const SearchWrapper = styled.div`
  max-width: 200px;
`;

const StrictToggle = styled(Button)`
  margin-top: ${pad}px;

  ${({isStrict}) =>
    isStrict
      ? css`
          padding-right: 11px;
          padding-left: 11px;
        `
      : ""}

  ${({disabled}) =>
    disabled
      ? css`
          opacity: 0.5;
        `
      : ""}
`;

export default FormulaEditor;

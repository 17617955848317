import React from "react";
import {Draggable} from "react-beautiful-dnd";
import styled from "styled-components";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHistory} from "@fortawesome/free-solid-svg-icons";
import {useFormContext, Controller} from "react-hook-form";

// Utils
import {getAncestryName} from "../pages/checksheet-builder/helpers.js";

// Style
import {border, radius} from "../style/components/variables.js";
import {Inline, Text} from "../style/components/general.js";

const Formula = ({outerProvided, byId}) => {
  const form = useFormContext();
  const {watch, control} = form;

  const formula = watch("formula");

  const abbreviate = str => {
    if (str.length > 8) return `${str.slice(0, 8)}...`;
    return str;
  };

  const getVarTitle = part => {
    if (byId && part.value in byId) return getAncestryName(byId[part.value], {byId});
    return part.label;
  };

  const getVarLabel = part => {
    if (byId && part.value in byId) return abbreviate(byId[part.value].label);
    return abbreviate(part.label);
  };

  if (!formula || formula.length === 0)
    return <NoFormula>Please insert variables and operations to build a formula.</NoFormula>;

  return (
    <Controller
      render={({field: {ref}}) => (
        <Inline id="formula" ref={ref}>
          {formula.map((part, idx) => {
            if (part.type !== "variable")
              return (
                <Draggable
                  draggableId={`${part.type}.${idx}`}
                  index={idx}
                  isDragDisabled={false}
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${part.type}.${idx}`}>
                  {innerProvided => (
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={`part-${idx}`}
                      {...innerProvided.draggableProps}
                      {...innerProvided.dragHandleProps}
                      ref={innerProvided.innerRef}
                      data-testid="formula.item">
                      {part.label}
                    </div>
                  )}
                </Draggable>
              );
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Draggable
                draggableId={`${part.type}.${idx}`}
                index={idx}
                // eslint-disable-next-line react/no-array-index-key
                key={`${part.type}.${idx}`}>
                {innerProvided => (
                  <Inline
                    // eslint-disable-next-line react/no-array-index-key
                    key={`part-${idx}`}
                    {...innerProvided.draggableProps}
                    {...innerProvided.dragHandleProps}
                    ref={innerProvided.innerRef}
                    data-testid="formula.item">
                    <VariableAbbr title={getVarTitle(part)}>
                      <Variable>
                        {getVarLabel(part)}
                        {part.previous && (
                          <>
                            &nbsp;
                            <FontAwesomeIcon icon={faHistory} />
                          </>
                        )}
                      </Variable>
                    </VariableAbbr>
                    {innerProvided.placeholder}
                  </Inline>
                )}
              </Draggable>
            );
          })}
          {outerProvided ? outerProvided.placeholder : null}
        </Inline>
      )}
      name="formula"
      control={control}
    />
  );
};

Formula.propTypes = {
  outerProvided: PropTypes.objectOf(PropTypes.any).isRequired,
  byId: PropTypes.objectOf(PropTypes.any).isRequired
};

// Style Overrides
const NoFormula = styled(Text)`
  margin-left: auto;
  margin-right: auto;
  width: max-content;
  word-break: break-all;
  white-space: normal;
`;

const Variable = styled.div`
  border-radius: ${radius};
  border: ${border} solid ${({theme}) => theme.primary};
  background-color: ${({theme}) => theme.primary};
  color: ${({highlight}) => (highlight ? "white" : "black")};

  svg {
    font-size: 10pt;
  }
`;

const VariableAbbr = styled.abbr`
  text-decoration: none;
`;

export default Formula;

import React, {useEffect, useMemo} from "react";
import styled, {css} from "styled-components";
import {Link, useLocation} from "react-router-dom";

// Utils
import useApi from "../hooks/useApi.js";
import useMountedState from "../hooks/useMountedState.js";

// Style
import {flex, z} from "../style/components/mixins.js";
import {voice} from "../style/components/typography.js";
import {border, heroTheme, pad, radius} from "../style/components/variables.js";
import {Heading, Title} from "../style/components/general.js";

const NotFound = () => {
  const {state, pathname} = useLocation();

  const isMounted = useMountedState();

  const {api, data} = useApi("facilities");

  useEffect(() => {
    const slugs = pathname.split("/");
    if (isMounted() && slugs?.length > 1 && slugs[1] === "facilities")
      api.callGet(slugs[2], null, {suppress: {error: true}});
  }, [isMounted, api, pathname]);

  const url = useMemo(() => (data ? `/facilities/${data.slug}` : "/facilities"), [data]);

  return (
    <NotFoundPage>
      <Nav>
        <Logo src={`${process.env.PUBLIC_URL}/hero-services.png`} alt="Hero Services Logo" />
      </Nav>
      <Header>404</Header>
      <Heading>{state?.message || "Page Not Found"}</Heading>
      <HomeLink to={url}>Go back to home</HomeLink>
    </NotFoundPage>
  );
};

export default NotFound;

// Style Overrides
const NotFoundPage = styled.section`
  ${flex("column", "nowrap", "start", "center")};
  position: relative;
  width: 100vw;
  height: 100vh;
`;

const Nav = styled.nav`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  align-self: start;
  display: flex;
  background: ${heroTheme.secondary};
  z-index: ${z("top")};

  ${props =>
    props.theme.name === "dark" &&
    css`
      border-bottom: ${border} solid ${heroTheme.tertiary};
    `}
`;

const Logo = styled.img`
  height: 75px;
  margin: ${pad}px auto;
`;

const Header = styled(Title)`
  font-size: 100px;
`;

const HomeLink = styled(Link)`
  position: relative;
  padding: ${pad / 2}px ${pad}px;
  margin-top: ${pad * 2}px;
  border-radius: ${radius};
  background-color: ${props => props.theme.secondary};
  color: ${props => props.theme.tertiary};
  ${voice.normal};
`;

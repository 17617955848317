import React, {useEffect, useMemo, useRef, useState} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faCheck,
  faClose,
  faPencil
} from "@fortawesome/free-solid-svg-icons";
import {yupResolver} from "@hookform/resolvers/yup";
import {useParams} from "react-router-dom";
import * as yup from "yup";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

// Hooks
import useApi from "../../hooks/useApi.js";

// Utils
import {getReadableKey} from "../../utils/helpers.js";
import {getFields} from "../../utils/builder.js";
import {
  STATIC,
  TABLE,
  CHART,
  STAT,
  HEADER,
  TEXT,
  INPUT,
  CHECKSHEET,
  EVENT
} from "../../utils/report.js";

// Components
import SearchSelect from "../../components/SearchSelect.js";
import Modal from "../../components/Modal.js";
import MultiSelect from "../../components/MultiSelect.js";
import ToggleButton from "../user-roles/ToggleButton.js";
import {
  InputText,
  InputSelect,
  InputTable,
  InputCheck,
  InputTextArea
} from "../../components/form/FormInputs.js";

// Style
import {flex} from "../../style/components/mixins.js";
import {pad, radius} from "../../style/components/variables.js";
import {
  Abbr,
  Button,
  ButtonFull,
  Error,
  Form,
  FormField,
  FormFieldWrapper,
  FormGroup,
  Heading,
  Inline
} from "../../style/components/general.js";

// Type Groups
const SECTION_TYPES = [STATIC, TABLE, CHART, STAT];
const STATIC_OPTIONS = [HEADER, TEXT, INPUT];
const SOURCE_OPTIONS = [CHECKSHEET, EVENT];
const TABLE_OPTIONS = [CHECKSHEET, EVENT, STATIC];

const defaultValues = {
  title: "",
  source: null,
  sourceType: null,
  fields: [],
  columns: null,
  cells: null,
  table: {},
  highlight: false,
  hasSecondInput: false,
  secondInput: null
};

const schema = yup.object().shape({
  sectionType: yup.string().required("Please provide a type."),
  sourceType: yup
    .mixed()
    .nullable()
    .when("sectionType", {
      is: val => val !== "Chart" && val !== "Statistic",
      then: () => yup.string().required("Please provide a source.")
    }),
  title: yup
    .mixed()
    .nullable()
    .when("sectionType", {
      is: val => val !== "Chart" && val !== "Statistic",
      then: () => yup.string().required("Please provide a title.")
    }),
  source: yup.string().nullable(),
  fields: yup
    .mixed()
    .nullable()
    .when("sourceType", {
      is: val => val === "Checksheet" || val === "Event",
      then: () =>
        yup
          .array()
          .of(yup.object())
          .test({
            test: val => Array.isArray(val) && val.length > 0,
            message: "Please provide at least one field"
          })
    }),
  columns: yup
    .mixed()
    .nullable()
    .when(["sectionType", "sourceType"], {
      is: (sectionType, sourceType) => sectionType === TABLE && sourceType === STATIC,
      then: () => yup.string().required("Column count is required.")
    }),
  cells: yup
    .mixed()
    .nullable()
    .when(["sectionType", "sourceType"], {
      is: (sectionType, sourceType) => sectionType === TABLE && sourceType === STATIC,
      then: () => yup.string().required("Cell count is required.")
    }),
  table: yup
    .mixed()
    .nullable()
    .when(["sectionType", "sourceType"], {
      is: (sectionType, sourceType) => sectionType === TABLE && sourceType === STATIC,
      then: () =>
        yup.object().test({
          message: "Table cells must be filled out.",
          test: arr => {
            let valid = true;
            Object.values(arr).map(row => {
              Object.values(row).map(cell => {
                if (!cell || cell === "") valid = false;
              });
            });
            return valid;
          }
        })
    }),
  highlight: yup
    .mixed()
    .nullable()
    .when("sourceType", {
      is: sourceType => sourceType === TEXT,
      then: () => yup.bool()
    }),
  hasSecondInput: yup
    .mixed()
    .nullable()
    .when("sourceType", {
      is: sourceType => sourceType === INPUT,
      then: () => yup.bool()
    }),
  secondInput: yup
    .mixed()
    .nullable()
    .when("hasSecondInput", {
      is: hasSecondInput => !!hasSecondInput,
      then: () => yup.string().required("Please provide a title.")
    })
});

const ModalAddSection = ({visible, setVisible, handleInsertRegion, start, end, base, interval}) => {
  const {slug} = useParams();

  const [sourceTypeOptions, setSourceTypeOptions] = useState(null);
  const [sourceOptions, setSourceOptions] = useState(null);
  const [fieldOptions, setFieldOptions] = useState(null);
  const [results, setResults] = useState([]);

  const prevSectionType = useRef(null);
  const defaultColumns = useRef({});

  const {api: apiAnalytics, data: analyticsData} = useApi("facility-analytics");
  const {api: apiChecksheets, data: checksheets} = useApi("checksheets");
  const {api: apiEvents, data: events} = useApi("events");
  const {api: apiReport} = useApi("reports");

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues
  });

  const {watch, control, reset, handleSubmit, setValue} = form;
  const {fields, append, remove, replace, insert} = useFieldArray({control, name: "fields"});

  const sectionType = watch("sectionType");
  const sourceType = watch("sourceType");
  const title = watch("title");
  const source = watch("source");
  const columns = watch("columns");
  const cells = watch("cells");
  const hasSecondInput = watch("hasSecondInput");
  const stage = watch("stage");
  const hasStats = watch("hasStats");
  const hasMin = watch("hasMin");
  const hasMedian = watch("hasMedian");
  const hasAvg = watch("hasAvg");
  const hasMax = watch("hasMax");
  const hasTotal = watch("hasTotal");

  const chart = useMemo(() => {
    if (sectionType !== CHART || !source || !analyticsData?.analytics) return null;
    const selected = analyticsData.analytics.find(a => `${a.id}` === source);
    return selected || null;
  }, [source, sectionType, analyticsData]);

  const event = useMemo(() => {
    if (sourceType !== EVENT || !source || !events) return null;
    const selected = events.find(a => `${a.id}` === source);
    return selected || null;
  }, [source, sourceType, events]);

  const stageOptions = useMemo(() => {
    if (!event?.stages?.allIds) return null;
    const {byId, allIds} = event.stages;
    return allIds
      .filter(stageId => byId[stageId])
      .map(stageId => {
        const {name} = byId[stageId];
        return {name: stageId, label: name};
      });
  }, [event]);

  const selectedStage = useMemo(() => {
    if (!stage || !event?.stages?.byId || !event?.stages?.byId[stage]) return null;
    const {byId} = event.stages;
    return byId[stage];
  }, [event, stage]);

  useEffect(() => {
    if (hasSecondInput) setValue("fullWidth", true);
    else setValue("secondInput", null);
  }, [hasSecondInput, setValue]);

  useEffect(() => {
    if (hasStats) {
      setValue("hasMin", true);
      setValue("hasMedian", true);
      setValue("hasAvg", true);
      setValue("hasMax", true);
      setValue("hasTotal", true);
    } else {
      setValue("hasMin", false);
      setValue("hasMedian", false);
      setValue("hasAvg", false);
      setValue("hasMax", false);
      setValue("hasTotal", false);
    }
  }, [hasStats, setValue]);

  useEffect(() => {
    if (
      sourceType &&
      source &&
      sourceType === CHECKSHEET &&
      checksheets &&
      checksheets.length > 0
    ) {
      const checksheet = checksheets.filter(({id}) => `${id}` === source)[0];
      if (checksheet) {
        const numberFields = getFields(checksheet.builder.allIds, checksheet.builder.byId)
          .filter(
            field => field.type === "number" || field.type === "generated" || field.type === "range"
          )
          .map(({name, ancestry, units}) => ({name, label: ancestry, units}));
        setFieldOptions(numberFields);
      }
      replace([]);
      defaultColumns.current = {};
    } else if (sourceType && source && sourceType === EVENT && events && events.length > 0) {
      if (selectedStage?.builder) {
        const {allIds, byId} = selectedStage.builder;
        setFieldOptions(
          getFields(allIds, byId)
            .filter(field => field.type === "number" || field.type === "generated")
            .map(({name, label, units}) => ({name, label, units}))
        );
      }
      replace([]);
      defaultColumns.current = {};
    } else {
      setFieldOptions(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checksheets, source, events, stage, replace]);

  // Update source options
  useEffect(() => {
    if (sectionType && prevSectionType.current !== sectionType) {
      // section type changed
      reset({...defaultValues, sectionType});

      if (slug && (sectionType === CHART || sectionType === STAT)) {
        setSourceOptions(null);
        apiAnalytics
          .callGet(null, {
            facilitySlug: slug,
            type: sectionType === CHART ? "plot" : sectionType.toLowerCase()
          })
          .then(response => {
            if (response.status === 200) {
              setFieldOptions(null);
              setSourceTypeOptions(null);
              const filtered = response.data?.analytics;
              if (filtered) {
                const options = filtered.map(({id, ...rest}) => ({
                  name: id,
                  ...rest
                }));
                setSourceOptions(options);
              }
            }
          });
      }

      if (sectionType === TABLE) {
        apiChecksheets.callGet(null, {facilitySlug: slug});
        apiEvents.callGet(null, {facilitySlug: slug});
        setSourceTypeOptions(TABLE_OPTIONS);
      }

      if (sectionType === STATIC) {
        setFieldOptions(null);
        setSourceTypeOptions(STATIC_OPTIONS);
        setSourceOptions(null);
      }
    } else if (sourceType) {
      // source type changed
      reset({...defaultValues, sectionType, sourceType});

      if (checksheets && sourceType === CHECKSHEET) {
        setFieldOptions(null);
        setSourceOptions(
          checksheets.map(({id, name, frequency, ...rest}) => ({
            name: id,
            label: frequency?.name ? `${frequency.name.toUpperCase()} ${name}` : name,
            ...rest
          }))
        );
      }

      if (events && sourceType === EVENT) {
        setFieldOptions(null);
        setSourceOptions(
          // only allow events with existing records
          events
            .filter(({records}) => Object.keys(records).length > 0)
            .map(({id, name}) => ({
              name: id,
              label: name
            }))
        );
      }

      if (sourceType === STATIC) {
        setSourceOptions(null);
        setFieldOptions(null);
      }
    } else if (sectionType !== CHART && sectionType !== STAT) {
      // source changed to null

      reset({...defaultValues, sectionType});
      setFieldOptions(null);
      setSourceOptions(null);
    }
    defaultColumns.current = {};
    prevSectionType.current = sectionType;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionType, sourceType, analyticsData, checksheets, events, setValue, replace]);

  // Create regions on the Report Builder
  const generateRegion = values => {
    const {
      table,
      fullWidth,
      highlight,
      secondInput,
      hasBandedRows,
      hasHiddenGridlines,
      chartSources
    } = values;

    const payload = {
      start,
      end,
      base,
      interval,
      regions: []
    };

    const watchedFields = watch("fields");

    let headers;

    if (sectionType && sectionType === CHART && source)
      payload.regions.push({
        rowId: "temp",
        type: sectionType,
        sourceId: source,
        fullWidth,
        chartSources
      });

    if (sectionType && sectionType === STAT && source)
      payload.regions.push({
        rowId: "temp",
        type: sectionType,
        sourceId: source,
        fullWidth
      });

    if (sourceType && (sourceType === CHECKSHEET || sourceType === EVENT) && title && source) {
      headers = {date: {label: "Date", index: 0}};

      watchedFields.map(({name, label, defaultLabel, units}, i) => {
        const newLabel = `${label} ${units && `(${units})`}`;
        headers[name] = {
          label: newLabel,
          enteredLabel: label,
          defaultLabel: defaultLabel,
          units,
          index: i + 1
        };
      });

      payload.regions.push({
        rowId: "temp",
        type: sourceType,
        sourceId: source,
        title,
        fields: fields.map(({name}) => name),
        headers,
        fullWidth,
        hasAvg,
        hasMax,
        hasMin,
        hasTotal,
        hasMedian,
        stageId: stage,
        hasBandedRows,
        hasHiddenGridlines
      });
    }

    // Static Sections
    if (sectionType && sectionType === STATIC && title)
      payload.regions.push({
        rowId: "temp",
        type: sourceType,
        title,
        fullWidth,
        highlight,
        hasSecondInput,
        secondInput
      });

    if (
      sectionType &&
      sectionType === TABLE &&
      sourceType === STATIC &&
      title &&
      table &&
      Object.values(table) &&
      Object.values(table).length > 0
    ) {
      const formatted = Object.values(table).map(cell => Object.values(cell));
      payload.regions.push({
        rowId: "temp",
        type: sourceType,
        title,
        table: formatted,
        fullWidth,
        hasBandedRows,
        hasHiddenGridlines
      });
    }

    if (payload.regions.length > 0)
      apiReport.callPost(payload).then(({status, data}) => {
        if (status === 200 && data?.temp) {
          handleInsertRegion(data.temp);
          reset(defaultValues);
          setVisible(false);
        }
      });
  };

  const search = query => {
    const selected = fields ? fields.map(({name}) => name) : [];
    if (query && fieldOptions) {
      const lower = query.toLowerCase();
      const tempResults = fieldOptions.filter(
        option => !selected.includes(option.name) && option.label.toLowerCase().includes(lower)
      );
      tempResults.sort(
        (a, b) => a.label.toLowerCase().indexOf(lower) - b.label.toLowerCase().indexOf(lower)
      );
      setResults(tempResults);
    } else setResults(fieldOptions.filter(option => !selected.includes(option.name)));
  };

  const onDragEnd = result => {
    const {destination, source: dragSource} = result;
    if (!destination || dragSource?.index === destination.index) {
      return;
    }
    const movingField = watch(`fields.${dragSource.index}`);
    let sourceOffset = 0;
    let destinationOffset = 1;
    if (destination.index < dragSource.index) {
      sourceOffset = 1;
      destinationOffset = 0;
    }
    insert(destination.index + destinationOffset, {...movingField});
    remove(dragSource.index + sourceOffset);
  };

  const noNumberFields = useMemo(() => fieldOptions && fieldOptions.length === 0, [fieldOptions]);
  const noneExistOfType = useMemo(
    () =>
      ((sourceType && SOURCE_OPTIONS.includes(sourceType)) ||
        sectionType === CHART ||
        sectionType === STAT) &&
      sourceOptions &&
      sourceOptions.length === 0,
    [sectionType, sourceOptions, sourceType]
  );

  const hasError = useMemo(
    () => noNumberFields || noneExistOfType,
    [noNumberFields, noneExistOfType]
  );

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <Heading>Section Builder</Heading>
      <FormProvider {...form}>
        <Form noValidate onSubmit={handleSubmit(generateRegion)}>
          <FormField>
            <InputSelect
              name="sectionType"
              label="Choose which type of section you are building."
              placeholder="Section Type..."
              options={SECTION_TYPES}
            />
          </FormField>
          {sectionType && sourceTypeOptions && (
            <FormField>
              <InputSelect
                name="sourceType"
                placeholder="Source Type..."
                options={sourceTypeOptions}
              />
            </FormField>
          )}
          {sourceOptions && sourceOptions.length > 0 && (
            <FormField>
              <InputSelect name="source" placeholder="Source..." options={sourceOptions} />
            </FormField>
          )}

          {noneExistOfType ? (
            <StyledError>
              There are no {sourceType ? sourceType.toLowerCase() : sectionType.toLowerCase()}s on
              this facility
            </StyledError>
          ) : (
            <>
              {/* Checksheet & Event Field Options */}
              {sourceType === EVENT && stageOptions && (
                <FormField>
                  <InputSelect name="stage" placeholder="Stage..." options={stageOptions} />
                </FormField>
              )}
              {fieldOptions && fieldOptions.length > 0 && (
                <StyledFormFieldWrapper>
                  <Inline>
                    <SearchSelect
                      results={results}
                      setResults={setResults}
                      search={search}
                      add={field => {
                        append(field);
                        defaultColumns.current[field.name] = field.label;
                      }}
                      showAll
                      large
                      keepOpen
                    />
                    {fields?.length !== fieldOptions?.length && (
                      <Button
                        type="button"
                        onClick={() => {
                          defaultColumns.current = {};
                          fieldOptions.map(field => {
                            defaultColumns.current[field.name] = field.label;
                          });

                          replace(fieldOptions);
                        }}>
                        Select All
                      </Button>
                    )}
                  </Inline>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="fields">
                      {outerProvided => (
                        <div {...outerProvided.droppableProps} ref={outerProvided.innerRef}>
                          {fields?.length > 0 &&
                            fields.map(({name, label}, i) => {
                              const override = watch(`fields.${i}.override`);
                              const newLabel = watch(`fields.${i}.label`);
                              const currentLabel = newLabel || label;
                              return (
                                <Draggable
                                  draggableId={`${name}.${i}`}
                                  index={i}
                                  key={name}
                                  isDragDisabled={fields.length <= 1}>
                                  {innerProvided => (
                                    <>
                                      <ColumnInfo
                                        ref={innerProvided.innerRef}
                                        {...innerProvided.draggableProps}
                                        {...innerProvided.dragHandleProps}>
                                        <Inline key={name}>
                                          <FontAwesomeIcon icon={faArrowsUpDownLeftRight} />
                                          {override ? (
                                            <InputText
                                              name={`fields.${i}.label`}
                                              callbackBlur={() => {
                                                setValue(`fields.${i}.override`, false);
                                                // set original name
                                                setValue(`fields.${i}.defaultLabel`, label);
                                              }}
                                            />
                                          ) : (
                                            <Abbr title={currentLabel}>
                                              {currentLabel?.toUpperCase()}
                                            </Abbr>
                                          )}
                                          &nbsp;
                                          {newLabel?.toUpperCase() !==
                                            defaultColumns.current[name].toUpperCase() && (
                                            <Abbr title={`(${defaultColumns.current[name]})`}>
                                              ({defaultColumns.current[name].toUpperCase()})
                                            </Abbr>
                                          )}
                                        </Inline>
                                        <Inline>
                                          <Button
                                            type="button"
                                            title="Add Alias"
                                            onClick={() =>
                                              setValue(`fields.${i}.override`, !override)
                                            }>
                                            <FontAwesomeIcon icon={override ? faCheck : faPencil} />
                                          </Button>
                                          <Button
                                            type="button"
                                            title="Remove"
                                            onClick={() => remove(i)}>
                                            <FontAwesomeIcon icon={faClose} />
                                          </Button>
                                        </Inline>
                                      </ColumnInfo>
                                      {innerProvided.placeholder}
                                    </>
                                  )}
                                </Draggable>
                              );
                            })}
                          {outerProvided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                  <ClearAll>
                    {!!fields?.length && fields.length > 1 && (
                      <Button
                        type="button"
                        onClick={() => {
                          defaultColumns.current = {};

                          replace([]);
                        }}>
                        Clear
                      </Button>
                    )}
                  </ClearAll>
                </StyledFormFieldWrapper>
              )}

              {sectionType === CHART && chart && (
                <FormGroup>
                  <MultiSelect
                    name="chartSources"
                    label="FIELDS INCLUDED"
                    defaultSelection={chart.data?.sources}
                    options={chart.data?.sources.map(s => ({
                      label: getReadableKey(s),
                      name: s
                    }))}
                    setSelection={selection => setValue("chartSources", selection)}
                  />
                </FormGroup>
              )}

              {noNumberFields && (
                <StyledError>
                  The selected {sourceType ? sourceType.toLowerCase() : "checksheet"} does not
                  contain any number fields
                </StyledError>
              )}

              {!hasError && (!!stage || sourceType !== EVENT) && (
                <>
                  {/* Static Table */}
                  {sectionType && sectionType === TABLE && sourceType && sourceType === STATIC && (
                    <FormGroup>
                      <InputSelect
                        name="columns"
                        placeholder="Columns..."
                        label="# of Columns"
                        options={[1, 2, 3, 4]}
                      />
                      <InputSelect
                        name="cells"
                        label="Cells per Column"
                        placeholder="Cells..."
                        options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
                      />
                    </FormGroup>
                  )}

                  {sectionType &&
                    sourceType &&
                    sectionType !== CHART &&
                    sectionType !== STAT &&
                    sourceType !== TEXT && (
                      <FormGroup>
                        <InputText
                          name="title"
                          placeholder={`${sectionType} Title...`}
                          maxLength={75}
                        />
                      </FormGroup>
                    )}

                  {sectionType && sourceType && sourceType === TEXT && (
                    <FormGroup>
                      <InputTextArea name="title" placeholder={`${sectionType} Text...`} />
                    </FormGroup>
                  )}

                  {columns && cells && (
                    <FormGroup>
                      <InputTable
                        name="table"
                        label="Provide rows"
                        colCount={Number.parseInt(columns, 10)}
                        cellCount={Number.parseInt(cells, 10)}
                      />
                    </FormGroup>
                  )}
                  <FormGroup>
                    {source && (sourceType !== EVENT || !!stage) && (
                      <InputCheck name="fullWidth" disabled={!!hasSecondInput}>
                        Force section to occupy full width of page?
                      </InputCheck>
                    )}
                    {sourceType === INPUT && (
                      <InputCheck name="hasSecondInput">Add additional input to line?</InputCheck>
                    )}
                    {hasSecondInput && (
                      <InputText
                        name="secondInput"
                        placeholder="Additional Input Title..."
                        maxLength={75}
                      />
                    )}
                    {sourceType === TEXT && (
                      <InputCheck name="highlight">Highlight in text box?</InputCheck>
                    )}
                    {sectionType === TABLE && (
                      <>
                        <InputCheck name="hasBandedRows">Banded rows?</InputCheck>
                        <InputCheck name="hasHiddenGridlines">Hide grid lines?</InputCheck>
                      </>
                    )}
                    {sectionType === TABLE && sourceType && sourceType !== STATIC && source && (
                      <>
                        <InputCheck name="hasStats">Include Stats?</InputCheck>
                        {hasStats && (
                          <ButtonGroup>
                            <ToggleButton
                              type="button"
                              label="Min"
                              state={hasMin ? "enabled" : "disabled"}
                              callback={newStatus => setValue("hasMin", newStatus === "enabled")}
                            />
                            <ToggleButton
                              type="button"
                              label="Median"
                              state={hasMedian ? "enabled" : "disabled"}
                              callback={newStatus => setValue("hasMedian", newStatus === "enabled")}
                            />
                            <ToggleButton
                              type="button"
                              label="Mean"
                              state={hasAvg ? "enabled" : "disabled"}
                              callback={newStatus => setValue("hasAvg", newStatus === "enabled")}
                            />
                            <ToggleButton
                              type="button"
                              label="Max"
                              state={hasMax ? "enabled" : "disabled"}
                              callback={newStatus => setValue("hasMax", newStatus === "enabled")}
                            />
                            <ToggleButton
                              type="button"
                              label="Total"
                              state={hasTotal ? "enabled" : "disabled"}
                              callback={newStatus => setValue("hasTotal", newStatus === "enabled")}
                            />
                          </ButtonGroup>
                        )}
                      </>
                    )}
                  </FormGroup>
                </>
              )}
            </>
          )}

          <FormFieldWrapper>
            <StyledButtonFull type="submit">Create Section</StyledButtonFull>
          </FormFieldWrapper>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalAddSection.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func.isRequired,
  handleInsertRegion: PropTypes.func.isRequired,
  start: PropTypes.string,
  end: PropTypes.string,
  base: PropTypes.string,
  interval: PropTypes.string,
  target: PropTypes.objectOf(PropTypes.any)
};

ModalAddSection.defaultProps = {
  visible: false,
  start: null,
  end: null,
  target: null,
  base: null,
  interval: null
};

const StyledButtonFull = styled(ButtonFull)`
  padding: ${pad / 2}px;
`;

const StyledFormFieldWrapper = styled(FormFieldWrapper)`
  margin-bottom: ${pad}px;
`;

const ColumnInfo = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${pad}px;
  color: ${props => props.theme.tertiary};
  background: ${props => props.theme.secondary};
  border-radius: ${radius};
  margin-top: ${pad}px;

  ${FormFieldWrapper} {
    margin: 0;
  }

  ${Inline}:first-child {
    width: 90%;
  }

  ${Inline}:last-child {
    width: 10%;
    justify-content: end;
    gap: ${pad}px;
  }

  svg {
    fill: ${props => props.theme.tertiary};
  }

  ${Abbr} {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  input {
    background: ${props => props.theme.tertiary};
    max-width: 150px;
  }

  ${Button} {
    padding: 0 ${pad}px;
    background: transparent;
  }
`;

const StyledError = styled(Error)`
  margin-bottom: ${pad / 2}px;
`;

const ClearAll = styled.div`
  margin-top: ${pad}px;
`;

const ButtonGroup = styled.div`
  ${flex("row", "nowrap", "space-between", "center")};
  gap: ${pad}px;
  padding-left: ${pad}px;
  margin-top: ${pad / 2}px;
`;

export default ModalAddSection;

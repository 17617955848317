import React from "react";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faBoxOpen,
  faBroadcastTower,
  faCalendarDay,
  faCaretSquareDown,
  faCheckDouble,
  faCheckSquare,
  faICursor,
  faListAlt,
  faSortNumericDown,
  faToggleOn,
  faUpload,
  faClock
} from "@fortawesome/free-solid-svg-icons";

// Components
import InputRadio from "./InputRadio.js";

// Style
import {flex} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {border, pad, radius} from "../../style/components/variables.js";
import {FormField, Small, Label, Hidden} from "../../style/components/general.js";

export const options = [
  {
    label: "Number",
    value: "number",
    icon: faSortNumericDown
  },
  {
    label: "Text",
    value: "text",
    icon: faICursor
  },
  {
    label: "Text Box",
    value: "textarea",
    icon: faBoxOpen
  },
  {
    label: "Check Box",
    value: "checkbox",
    icon: faCheckSquare
  },
  {
    label: "Switch",
    value: "switch",
    icon: faToggleOn
  },
  {
    label: "Confirm",
    value: "confirm",
    icon: faCheckDouble
  },
  {
    label: "Drop Down",
    value: "dropdown",
    icon: faCaretSquareDown
  },
  {
    label: "Radio",
    value: "radio",
    icon: faBroadcastTower
  },
  {
    label: "Multi Select",
    value: "multiselect",
    icon: faListAlt
  },
  {
    label: "Date",
    value: "date",
    icon: faCalendarDay
  },
  {
    label: "Time",
    value: "time",
    icon: faClock
  },
  {
    label: "Upload",
    value: "upload",
    icon: faUpload
  }
];

export const iconLookup = () => {
  const icons = {};
  options.map(option => {
    icons[option.value] = option.icon;
  });
  return icons;
};

const InputFieldType = ({name}) => {
  const {watch} = useFormContext();

  return (
    <FieldOptions>
      {options.map(option => {
        const {label, value, icon} = option;
        return (
          <FieldOption key={value} type="button">
            <FieldType data-testid={`field-type-${value}`} selected={watch(name) === value}>
              <FontAwesomeIcon icon={icon} />
              <Small>{label}</Small>
              <Hidden>
                <InputRadio name={name} label={value} />
              </Hidden>
            </FieldType>
          </FieldOption>
        );
      })}
    </FieldOptions>
  );
};

InputFieldType.propTypes = {
  name: PropTypes.string.isRequired
};

// Style Overrides
const FieldOptions = styled(FormField)`
  ${flex("row", "wrap", "start", "center")};
  ${voice.normal};
  margin-bottom: ${pad}px;
`;

const FieldOption = styled.button`
  width: 33.33%;
  padding-right: ${pad}px;
  padding-top: ${pad}px;

  &:nth-child(3n) {
    padding-right: 0;
  }
`;

const FieldType = styled(Label)`
  ${flex("column", "wrap", "center", "center")};
  ${voice.medium};
  min-width: 100%;
  text-transform: uppercase;
  background: transparent;
  border: ${border} solid ${props => props.theme.secondary};
  border-radius: ${radius};
  padding: ${pad}px;

  ${Small} {
    color: ${props => props.theme.secondary};
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
  }

  svg {
    fill: ${props => props.theme.secondary};
    margin-bottom: ${pad / 2}px;
  }

  &:hover {
    background: ${props => props.theme.secondary};

    ${Small} {
      color: ${props => props.theme.tertiary};
    }

    svg {
      fill: ${props => props.theme.tertiary};
    }
  }

  ${props =>
    props.selected &&
    css`
      background: ${props.theme.secondary};

      ${Small} {
        color: ${props.theme.tertiary};
      }

      svg {
        fill: ${props.theme.tertiary};
      }
    `}
`;

export default InputFieldType;

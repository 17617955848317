import React, {useCallback, useRef, useState} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";

// Utils
import {CHART, STAT} from "../../utils/report.js";
import {exists} from "../../utils/helpers.js";

// Components
import Modal from "../../components/Modal.js";

// Style
import {flex} from "../../style/components/mixins.js";
import {border, pad, radius} from "../../style/components/variables.js";
import {Abbr, Heading} from "../../style/components/general.js";

const LEFT_COLUMN = 0;
const RIGHT_COLUMN = 1;

const RenderReportBuilder = ({report, visible, setVisible}) => {
  const ratio = useRef(null);

  const [activeHoverId, setActiveHoverId] = useState(null);
  const [activeDraggableId, setActiveDraggableId] = useState(null);
  const [activeDraggableType, setActiveDraggableType] = useState(null);

  const onDragStart = provided => {
    const {draggableId} = provided;
    setActiveDraggableId(draggableId);
    setActiveDraggableType(draggableId.includes("c") ? "within-column" : "moving-group");
  };

  const onDragEnd = provided => {
    const {destination, draggableId} = provided;
    if (destination) {
      const {droppableId, index} = destination;

      const [dragGroup, dragColumn, dragRow] = draggableId
        .replace(/[^0-9-]/g, "")
        .split("-")
        .map(val => parseInt(val, 10));
      if (droppableId !== "root") {
        const [destGroup, destColumn] = droppableId
          .replace(/[^0-9-]/g, "")
          .split("-")
          .map(val => parseInt(val, 10));

        if (destGroup !== dragGroup || destColumn !== dragColumn || index !== dragRow) {
          report.reorder(
            {group: dragGroup, column: dragColumn, row: dragRow},
            {group: destGroup, column: destColumn, row: index}
          );
        }
      } else if (index !== draggableId) {
        report.swapGroups(dragGroup, index);
      }
    }
    setActiveDraggableId(null);
    setActiveHoverId(null);
  };

  const droppableWrapper = useCallback(
    (element, group, column) => {
      let id = "root";

      const isRoot = !exists(group);

      if (exists(column)) id = `g${group}-c${column}`;
      else if (exists(group)) id = `g${group}`;

      return (
        <Droppable
          droppableId={id}
          direction="vertical"
          key={id}
          isDropDisabled={
            (isRoot && activeDraggableType === "within-column") ||
            (!isRoot && activeDraggableType === "moving-group")
          }>
          {provided => (
            <DroppableFlex
              {...provided.droppableProps}
              ref={provided.innerRef}
              isHalfWidth={!isRoot && exists(column)}>
              {!exists(column) && group === 0 ? provided.placeholder : null}
              {element}
              {!(!exists(column) && group === 0) ? provided.placeholder : null}
            </DroppableFlex>
          )}
        </Droppable>
      );
    },
    [activeDraggableType]
  );

  const draggableWrapper = useCallback(
    (element, group, column, rowIdx, dragDisabled = false) => {
      let id = `g${group}`;
      if (exists(column)) id = `g${group}-c${column}-r${rowIdx}`;
      return (
        <Draggable
          draggableId={id}
          index={rowIdx ?? column ?? group}
          direction={exists(rowIdx) || !exists(column) ? "vertical" : "horizontal"}
          isDragDisabled={dragDisabled}
          key={id}>
          {provided => (
            <DraggableDiv
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
              padded={exists(rowIdx) ? 1 : 0}
              isDragDisabled={dragDisabled ? 1 : 0}
              hasShadow={(activeHoverId === id && !activeDraggableId) || activeDraggableId === id}
              onMouseOver={e => {
                if (!dragDisabled) {
                  e.stopPropagation();
                  setActiveHoverId(id);
                }
              }}
              onMouseMove={e => {
                if (!dragDisabled && !activeHoverId) {
                  e.stopPropagation();
                  setActiveHoverId(id);
                }
              }}
              onMouseLeave={e => {
                if (!dragDisabled) {
                  e.stopPropagation();
                  setActiveHoverId(null);
                }
              }}>
              <DraggableDivInside>{element}</DraggableDivInside>
            </DraggableDiv>
          )}
        </Draggable>
      );
    },
    [activeDraggableId, activeHoverId]
  );

  const pushColumns = useCallback(
    (allRegions, leftColumn, rightColumn, groupIdx, groupKey, leftKey, rightKey) => {
      allRegions.push(
        draggableWrapper(
          <GroupWrapper key={groupKey}>
            {droppableWrapper(leftColumn, groupIdx, LEFT_COLUMN, leftKey)}
            {droppableWrapper(rightColumn, groupIdx, RIGHT_COLUMN, rightKey)}
          </GroupWrapper>,
          groupIdx,
          null,
          null,
          true
        )
      );
    },
    [droppableWrapper, draggableWrapper]
  );

  const getTitle = region => {
    if (region.type === CHART || region.type === STAT) return region?.data?.label || region.type;
    return region.title && region.title.length > 60
      ? `${region.title.slice(0, 60)}...`
      : region.title || region.type;
  };

  const arrangeRegions = () => {
    const allRegions = [];
    if (!ratio.current) {
      const maxRegionHeight = report.getMaxHeight();
      const totalHeight = report.getTotalHeight();
      const availableHeight = window.innerHeight * 0.75;

      if (totalHeight <= availableHeight) ratio.current = 1;
      // get ratio of modal available height to maximum region height
      else ratio.current = Math.min(availableHeight / maxRegionHeight, 1);
    }

    report.groups.map((group, groupIdx) => {
      if (group?.columns?.length === 1) {
        const row = group.columns[0].rows[0];
        if (!row) return;
        const {region, height} = row;
        const title = getTitle(region);
        if (groupIdx === 0 || groupIdx === report.groups.length - 1) {
          allRegions.push(
            draggableWrapper(
              droppableWrapper(
                <DraggableRegion height={height} ratio={ratio.current}>
                  <LabelWrapper>
                    <RegionLabel>
                      <Abbr title={title}>{title}</Abbr>
                    </RegionLabel>
                  </LabelWrapper>
                </DraggableRegion>,
                groupIdx,
                null
              ),
              groupIdx,
              null,
              null
            )
          );
        } else
          allRegions.push(
            draggableWrapper(
              <DraggableRegion height={height} ratio={ratio.current}>
                <LabelWrapper>
                  <RegionLabel>
                    <Abbr title={region.title}>{title}</Abbr>
                  </RegionLabel>
                </LabelWrapper>
              </DraggableRegion>,
              groupIdx,
              null,
              null
            )
          );
      } else if (group?.columns?.length === 2) {
        const leftColumn = group.columns[LEFT_COLUMN].rows
          .filter(row => !!row)
          .map((row, i) => {
            const {region, height} = row;
            const title = getTitle(region);

            return draggableWrapper(
              <DraggableRegion height={height} ratio={ratio.current}>
                <LabelWrapper>
                  <RegionLabel>
                    <Abbr title={region.title}>{title}</Abbr>
                  </RegionLabel>
                </LabelWrapper>
              </DraggableRegion>,
              groupIdx,
              LEFT_COLUMN,
              i
            );
          });

        const rightColumn = group.columns[RIGHT_COLUMN].rows
          .filter(row => !!row)
          .map((row, i) => {
            const {region, height} = row;
            const title = getTitle(region);
            return draggableWrapper(
              <DraggableRegion height={height} ratio={ratio.current}>
                <LabelWrapper>
                  <RegionLabel>
                    <Abbr title={region.title}>{title}</Abbr>
                  </RegionLabel>
                </LabelWrapper>
              </DraggableRegion>,
              groupIdx,
              RIGHT_COLUMN,
              i
            );
          });

        if (leftColumn.length || rightColumn.length)
          pushColumns(
            allRegions,
            leftColumn,
            rightColumn,
            groupIdx,
            group.id,
            group.columns[LEFT_COLUMN].id,
            group.columns[RIGHT_COLUMN].id
          );
      }
    });

    return droppableWrapper(allRegions, null, null);
  };

  const tableRender = arrangeRegions();

  return (
    <Modal visible={visible} setVisible={setVisible} width="50vw" fixedHeight>
      <Heading>Reorder Regions</Heading>
      <br />
      {ratio.current && (
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <Center>
            <VariableWidth ratio={ratio.current}>{tableRender}</VariableWidth>
          </Center>
        </DragDropContext>
      )}
    </Modal>
  );
};

RenderReportBuilder.propTypes = {
  report: PropTypes.objectOf(PropTypes.any),
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

RenderReportBuilder.defaultProps = {
  report: null
};

// Style Overrides

const DraggableRegion = styled.div`
  ${flex("column", "nowrap", "center", "center")};
  width: 100%;
  height: ${props => props.height * props.ratio * 0.5}px;
  border: ${border} solid ${props => props.theme.secondary};
  border-radius: ${radius};
  background: ${props => props.theme.tertiary};
  padding: ${pad}px;

  font-size: 14px;
`;

const DroppableFlex = styled.div`
  ${flex("column", "nowrap", "flex-start", "start")};
  align-self: stretch;
  gap: ${pad}px;
  height: auto;

  ${props =>
    props.isHalfWidth &&
    css`
      width: 50%;
    `}
`;

const DraggableDivInside = styled.div`
  ${flex("column", "nowrap", "flex-start", "start")};
  height: 100%;
  width: 100%;
`;

const DraggableDiv = styled.div`
  height: auto;
  top: auto;
  left: auto;
  display: block;
  position: relative;

  ${props =>
    props.padded
      ? css`
          margin-bottom: ${pad}px;
          width: calc(100% - (${`${pad}px`}));
        `
      : css`
          width: 100%;
        `}

  ${props =>
    props.hasShadow
      ? css`
          box-shadow: 0 0 12px ${props.theme.primary};
        `
      : ""}
`;

const GroupWrapper = styled.div`
  width: 100%;
  ${flex("row", "nowrap", "flex-start", "start")}
`;

const VariableWidth = styled.div`
  height: min-content;
  align-self: center;
  width: 100%;
  padding-bottom: ${pad}px;
`;

const Center = styled.div`
  ${flex("column", "nowrap", "flex-start", "center")};
  width: 100%;
  height: calc(100% - 100px);
`;

const RegionLabel = styled.strong`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow-x: hidden;
  max-width: 100%;
  padding-bottom: ${pad * 2}px;
  margin-top: ${pad}px;
`;

const LabelWrapper = styled.span`
  ${flex("column", "nowrap", "center", "center")};
  width: 100%;
  height: 100%;
`;

export default RenderReportBuilder;

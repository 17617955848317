import React, {useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";

// Style
import {flex} from "../../style/components/mixins.js";
import {pad} from "../../style/components/variables.js";
import {voice} from "../../style/components/typography.js";
import {bp} from "../../style/components/breakpoints.js";
import {Text, Button, Label, Inline} from "../../style/components/general.js";

export const AccountRow = ({label, help, children, indent}) => {
  const [showHelp, setShowHelp] = useState(false);
  return (
    <InfoItem>
      <InfoLabel>
        <HelpButton type="button" onClick={() => setShowHelp(prev => !prev)} hidden />
        {label}&nbsp;{help && <HelpIcon icon={faQuestionCircle} />}
      </InfoLabel>

      <InfoCell>
        {showHelp && <InfoHelp>{help}</InfoHelp>}
        <Inline>
          {indent && <>&nbsp;&nbsp;</>}
          {children}
        </Inline>
      </InfoCell>
    </InfoItem>
  );
};

AccountRow.propTypes = {
  label: PropTypes.string.isRequired,
  help: PropTypes.string,
  children: PropTypes.node.isRequired,
  indent: PropTypes.bool
};

AccountRow.defaultProps = {help: null, indent: false};

export const InfoItem = styled.div`
  position: relative;
  padding: ${pad}px;
  width: 100%;
  min-width: min-content;
  gap: ${pad}px;

  ${bp(2)} {
    ${flex("row", "wrap", "start", "center")}
    padding-left: 20px;
  }
`;

export const InfoLabel = styled(Label)`
  width: 35%;
  align-self: start;
  margin-bottom: 0;
  font-weight: bold;
`;

const InfoHelp = styled.span`
  ${voice.quiet};
`;

const HelpButton = styled.button`
  opacity: 0;
  width: 100%;
  height: 100%;
`;

const HelpIcon = styled(FontAwesomeIcon)`
  ${voice.quiet};
  padding: ${pad / 2}px ${pad / 2}px ${pad / 2}px 0;
  fill: ${props => props.theme.secondary};
`;

export const InfoCell = styled.div`
  width: 65%;
`;

export const InfoText = styled(Text)`
  color: ${props => props.theme.secondary};
`;

export const Save = styled(Button)`
  position: absolute;
  bottom: 0;
  right: 0;
  margin: ${pad}px;
`;

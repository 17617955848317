import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect,
  useRef,
  useMemo
} from "react";
import PropTypes from "prop-types";

// Utils
import useMountedState from "../hooks/useMountedState.js";

// Components
import ToastList from "../components/ToastList.js";

const ToastContext = createContext();

const ToastProvider = ({children}) => {
  const isMounted = useMountedState();

  const [toasts, setToasts] = useState([]);
  const [paused, setPaused] = useState(false);
  const [pauseTime, setPauseTime] = useState(null);

  const queued = useRef(0);
  const existing = useRef(0);

  /**
   * @param {number} providedTimeout Trigger pause for x seconds
   */
  const pauseToastOutput = (providedTimeout = null) => {
    setPauseTime(providedTimeout);
    setPaused(true);
  };

  /**
   * @param {string} message
   * @param {string} type
   * @returns {void}
   */
  const addToast = useCallback(
    (message, type = "info") => {
      if (isMounted() && existing.current < 5) {
        queued.current += 1;
        if (!paused) {
          setToasts(toastList => [
            ...toastList,
            {
              id: ++queued.current,
              type,
              content: message
            }
          ]);
        }
      }
    },
    [isMounted, paused]
  );

  /**
   * @param {number} toastId id of toast to remove
   */
  const removeToast = useCallback(
    toastId => setToasts(toastList => toastList.filter(t => t.id !== toastId)),
    []
  );

  // Remove toast after 2 seconds
  useEffect(() => {
    let schedule = null;

    if (isMounted() && toasts && toasts.length > 0 && !paused)
      schedule = setTimeout(() => removeToast(toasts[0].id), 2000);

    if (paused) clearTimeout(schedule);

    return () => clearTimeout(schedule);
  }, [isMounted, toasts, paused, removeToast]);

  // Handle Pause
  useEffect(() => {
    if (paused && pauseTime) setTimeout(() => setPaused(false), pauseTime * 1000);
  }, [paused, pauseTime]);

  // Absolute toast limit
  useEffect(() => {
    existing.current = toasts.length;
  }, [toasts]);

  // Make the provider update only when it should.
  // We only want to force re-renders on dependencies
  const memoedValue = useMemo(
    () => ({
      addToast,
      removeToast,
      pauseToastOutput
    }),
    [addToast, removeToast]
  );

  return (
    <ToastContext.Provider value={memoedValue}>
      <ToastList toasts={toasts} pauseToastOutput={pauseToastOutput} removeToast={removeToast} />
      {children}
    </ToastContext.Provider>
  );
};

ToastProvider.propTypes = {
  children: PropTypes.node.isRequired
};

const useToast = () => useContext(ToastContext);

export {ToastContext, useToast};

export default ToastProvider;

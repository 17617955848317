import React, {useContext, useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";

// Contexts
import {SettingsContext} from "../../contexts/settings.js";

// Utils
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";
import {startExport} from "../../utils/export.js";
import {useToast} from "../../contexts/toast.js";
import {prettyDateInUserTimezone} from "../../utils/helpers.js";

// Components
import Modal from "../../components/Modal.js";
import SearchSelect from "../../components/SearchSelect.js";
import ModalExportPreview from "../../components/ModalExportPreview.js";
import {
  InputText,
  InputDate,
  InputCheck,
  InputCheckGroup
} from "../../components/form/FormInputs.js";

// Style
import {pad} from "../../style/components/variables.js";
import {voice} from "../../style/components/typography.js";
import {
  HeadingCenter,
  Button,
  Form,
  FormGroup,
  FormField,
  Label,
  Inline,
  ButtonLoader,
  SearchWrapper,
  Pill
} from "../../style/components/general.js";

const ModalExport = ({visible, setVisible, start, end, eventTypes, events, filters, facility}) => {
  const isMounted = useMountedState();

  const {addToast} = useToast();

  const {api: apiRecords} = useApi("events", {suppress: {success: true}});
  const {api: apiFacilities} = useApi("facilities");

  const {settings} = useContext(SettingsContext);

  const showFacilities = useMemo(() => !facility, [facility]);

  const options = useMemo(() => {
    const names = [];
    const groupKeys = [];
    const groups = [];
    const frequencies = [];
    events.map(({name, facility: eventFacility, group, frequency}) => {
      if (!names.includes(name)) names.push(name);
      if (group && !groupKeys.includes(group)) {
        groupKeys.push(group);
        groups.push({
          name: group,
          label: eventFacility.builder.byId[group].label
        });
      }
      if (frequency) frequencies.push({name: frequency.name, label: frequency.label});
    });
    return {
      names,
      groups,
      frequencies
    };
  }, [events]);

  const [loading, setLoading] = useState(false);
  const [facilities, setFacilities] = useState([]);
  const [results, setResults] = useState([]);
  const [nameResults, setNameResults] = useState(options.names);
  const [groupResults, setGroupResults] = useState(options.groups);
  const [frequencyResults, setFrequencyResults] = useState(options.frequencies);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [showModalPreview, setShowModalPreview] = useState(false);
  const [tableHeadings, setTableHeadings] = useState(null);
  const [tableData, setTableData] = useState(null);

  const initialValues = {
    exportName: "",
    range: start?.length > 0 || end?.length > 0,
    start,
    end,
    eventTypes: filters?.types,
    facilities: filters?.facilities,
    stages:
      filters?.stages && typeof filters?.stages === "string" ? [filters?.stages] : filters?.stages,
    groups: filters?.groups,
    frequencies: filters?.frequencies,
    names: filters?.names
  };

  const schema = yup.object().shape({
    exportName: yup.string().required("Name is required."),
    start: yup
      .string()
      .nullable()
      .when("range", {
        is: val => !!val,
        then: () => yup.string().required()
      }),
    end: yup
      .string()
      .nullable()
      .when("range", {
        is: val => !!val,
        then: () => yup.string().required()
      }),
    range: yup.boolean()
  });

  const form = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema)
  });
  const {watch, setValue, handleSubmit, reset} = form;

  const watchExportName = watch("exportName");
  const watchRange = watch("range");
  const watchTypes = watch("eventTypes");
  const watchFacilities = watch("facilities");
  const watchNames = watch("names");
  const watchGroups = watch("groups");
  const watchFrequencies = watch("frequencies");

  // Initial Load
  useEffect(() => {
    if (isMounted() && showFacilities)
      apiFacilities.callGet().then(({status, data}) => {
        if (status === 200) setFacilities(data.map(({id, name}) => ({label: name, value: id})));
      });
  }, [isMounted, showFacilities, apiFacilities]);

  useEffect(() => {
    if (!visible) reset({});
  }, [reset, visible]);

  useEffect(() => {
    if (watchTypes?.length !== 1) setValue("stages", null);
  }, [watchTypes, setValue]);

  const handleSearch = q => {
    if (q) {
      const lower = q.toLowerCase();
      const filtered = facilities.filter(({label}) => label.toLowerCase().includes(lower));
      setResults(filtered);
    } else setResults(facilities);
  };

  const generateExportData = async (values, stripQuotes = false) => {
    const {start: s, end: e, eventTypes: ets, stages, groups, names, frequencies} = values;

    const filter = {};
    if (watchFacilities?.length > 0) filter.facilities = watchFacilities;
    else if (facility) filter.facilities = [facility.id];
    if (ets?.length > 0) filter.eventTypes = ets;
    if (groups?.length > 0) filter.groups = groups.map(group => group.name);
    if (names?.length > 0) filter.names = names;
    if (frequencies?.length > 0) filter.frequencies = frequencies.map(frequency => frequency.name);

    if (s) filter.start = s;
    if (e) filter.end = e;

    const params = {};
    if (Object.keys(filter)?.length > 0) params.filter = JSON.stringify(filter);
    if (stages?.length > 0)
      params.stages = JSON.stringify(typeof stages === "string" ? [stages] : stages);

    const {status, data} = await apiRecords.callPatch(null, params);

    let rows = [];
    if (status === 200 && data?.rows?.length === 0) addToast("No rows found.");
    if (status === 200 && data?.rows?.length > 0)
      data.rows.map(row => {
        const temp = {};
        row.map(({key, value}) => {
          const stripped =
            stripQuotes && value?.match && value.match(/^".*"$/)
              ? value.slice(1, value.length - 1)
              : value;
          let formattedValue = stripped;
          // Format dates
          if (
            formattedValue?.match(
              /\d{4}[-](0?[1-9]|1[012])[-](0?[1-9]|[12][0-9]|3[01])T([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9](.\d{6})?/
            )
          )
            formattedValue = prettyDateInUserTimezone(
              formattedValue,
              settings?.timezone,
              "YYYY-MM-DD hh:mm A"
            );
          temp[key] = formattedValue;
        });
        rows.push(temp);
      });
    else rows = data;

    return rows;
  };

  const handlePreview = async () => {
    setPreviewLoading(true);
    const values = watch();
    const data = await generateExportData(values, true);
    const headers = {};
    Object.keys(data[0]).map(key => {
      headers[key] = {header: key, disabled: true};
    });
    setTableHeadings(headers);
    setTableData(data);
    setShowModalPreview(true);
    setPreviewLoading(false);
  };

  const createExport = async values => {
    setLoading(true);
    const data = await generateExportData(values);
    startExport("csv", watchExportName, data);
    setVisible(false);
    reset({...initialValues});
    setLoading(false);
  };

  const searchGroups = query => {
    if (query)
      setGroupResults(
        options?.groups
          ? options.groups.filter(f => f.label.toLowerCase().includes(query.toLowerCase()))
          : []
      );
    else setGroupResults(options.groups);
  };

  const searchNames = query => {
    if (query)
      setNameResults(
        options?.names
          ? options.names.filter(f => f.toLowerCase().includes(query.toLowerCase()))
          : []
      );
    else setNameResults(options.names);
  };

  const searchFrequencies = query => {
    if (query)
      setFrequencyResults(
        options?.frequencies
          ? options.frequencies.filter(f => f.toLowerCase().includes(query.toLowerCase()))
          : []
      );
    else setFrequencyResults(options.frequencies);
  };

  if (showModalPreview)
    return (
      <ModalExportPreview
        visible={visible}
        setVisible={setVisible}
        goBack={() => setShowModalPreview(false)}
        exportName={watchExportName}
        tableHeadings={tableHeadings}
        tableData={tableData}
        handleExport={() => {
          startExport("csv", watch("exportName"), tableData);
          setVisible(false);
          reset({...initialValues});
        }}
      />
    );

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <HeadingCenter>Export CSV</HeadingCenter>

      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(createExport)} noValidate>
          <FormGroup>
            <FormField>
              <InputText name="exportName" label="name" required />
            </FormField>
            <FormField>
              <InputCheck name="range">Specify a date range?</InputCheck>
            </FormField>
            {watchRange && (
              <FormField>
                <Label bold>DATE RANGE</Label>
                <DateRange>
                  from&nbsp;
                  <InputDate name="start" />
                  &nbsp;to&nbsp;
                  <InputDate name="end" />
                </DateRange>
              </FormField>
            )}
            {showFacilities && (
              <FormField>
                <SearchWrapper>
                  <SearchSelect
                    label="Facility Filter"
                    placeholder="Search facilities..."
                    results={results}
                    setResults={setResults}
                    search={handleSearch}
                    add={({id}) => {
                      if (!watchFacilities) setValue("facilities", [id]);
                      else if (!watchFacilities.includes(id))
                        setValue("facilities", [...watchFacilities, id]);
                    }}
                    showAll
                  />
                </SearchWrapper>
                <Results>
                  {watchFacilities?.map(name => (
                    <Pill key={name}>
                      <IconButton
                        onClick={() =>
                          setValue(
                            "facilities",
                            watchFacilities.filter(curr => curr !== name)
                          )
                        }>
                        {name}&nbsp;
                        <FontAwesomeIcon icon={faClose} />
                      </IconButton>
                    </Pill>
                  ))}
                </Results>
              </FormField>
            )}
            <FormField>
              <InputCheckGroup
                name="eventTypes"
                label="Event Type Filter"
                options={eventTypes.map(type => type.name.toUpperCase())}
                all
              />
            </FormField>
            {watchTypes?.length === 1 &&
              eventTypes.filter(({name}) => name === watchTypes[0].toLowerCase())[0]?.stages && (
                <FormField>
                  <InputCheckGroup
                    name="stages"
                    label="Stages"
                    options={
                      eventTypes.filter(({name}) => name === watchTypes[0].toLowerCase())[0].stages
                    }
                    all
                  />
                </FormField>
              )}

            <FormField>
              <Label bold>FREQUENCIES</Label>
              <SearchWrapper>
                <SearchSelect
                  placeholder="Filter..."
                  results={frequencyResults}
                  setResults={setFrequencyResults}
                  search={searchFrequencies}
                  add={value => {
                    if (!watchFrequencies) setValue("frequencies", [value]);
                    else if (!watchFrequencies.some(frequency => frequency.name === value.name))
                      setValue("frequencies", [...watchFrequencies, value]);
                  }}
                  showAll
                />
              </SearchWrapper>
              {watchFrequencies?.length > 0 && (
                <Selected>
                  {watchFrequencies?.map(frequency => (
                    <Button
                      key={frequency.name}
                      type="button"
                      title="Remove Frequency"
                      onClick={() =>
                        setValue(
                          "frequencies",
                          watchFrequencies.filter(curr => curr.name !== frequency.name)
                        )
                      }>
                      <span>{frequency.label}</span>
                      &nbsp;
                      <FontAwesomeIcon icon={faClose} />
                    </Button>
                  ))}
                </Selected>
              )}
            </FormField>
            <FormField>
              <Label bold>EVENT NAMES</Label>
              <SearchWrapper>
                <SearchSelect
                  placeholder="Filter..."
                  results={nameResults}
                  setResults={setNameResults}
                  search={searchNames}
                  add={value => {
                    if (!watchNames) setValue("names", [value]);
                    else if (!watchNames.includes(value)) setValue("names", [...watchNames, value]);
                  }}
                  showAll
                />
              </SearchWrapper>
              {watchNames?.length > 0 && (
                <Selected>
                  {watchNames?.map(name => (
                    <Button
                      key={name}
                      type="button"
                      title="Remove Name"
                      onClick={() =>
                        setValue(
                          "names",
                          watchNames.filter(curr => curr !== name)
                        )
                      }>
                      <span>{name}</span>
                      &nbsp;
                      <FontAwesomeIcon icon={faClose} />
                    </Button>
                  ))}
                </Selected>
              )}
            </FormField>

            {(facility || watchFacilities?.length === 1) && options?.groups?.length > 0 && (
              <FormField>
                <Label bold>LOCATION (GROUP)</Label>
                <SearchWrapper>
                  <SearchSelect
                    placeholder="Filter..."
                    results={groupResults}
                    setResults={setGroupResults}
                    search={searchGroups}
                    add={value => {
                      if (!watchGroups) setValue("groups", [value]);
                      else if (!watchGroups.some(group => group.name === value.name))
                        setValue("groups", [...watchGroups, value]);
                    }}
                    showAll
                  />
                </SearchWrapper>
                {watchGroups?.length > 0 && (
                  <Selected>
                    {watchGroups?.map(group => (
                      <Button
                        key={group.name}
                        type="button"
                        title="Remove Name"
                        onClick={() =>
                          setValue(
                            "groups",
                            watchGroups.filter(curr => curr.name !== group.name)
                          )
                        }>
                        <span>{group.label}</span>
                        &nbsp;
                        <FontAwesomeIcon icon={faClose} />
                      </Button>
                    ))}
                  </Selected>
                )}
              </FormField>
            )}
          </FormGroup>
          <Inline>
            <Button type="submit" loading={loading ? 1 : 0}>
              Export {loading && <ButtonLoader />}
            </Button>
            <Button type="button" onClick={handlePreview} loading={previewLoading ? 1 : 0}>
              Preview {previewLoading && <ButtonLoader />}
            </Button>
          </Inline>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalExport.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  start: PropTypes.string,
  end: PropTypes.string,
  eventTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  filters: PropTypes.objectOf(PropTypes.any),
  events: PropTypes.arrayOf(PropTypes.any),
  facility: PropTypes.objectOf(PropTypes.any)
};

ModalExport.defaultProps = {
  start: "",
  end: "",
  filters: [],
  facility: null,
  events: []
};

// Style Overrides
const DateRange = styled(Inline)`
  display: flex;

  div {
    max-width: 150px;
  }
`;

const Results = styled(Inline)`
  margin-top: ${pad}px;
`;

const IconButton = styled(Button)`
  ${voice.small};
  background-color: transparent;
  width: min-content;
  padding: 0;
  margin-left: ${pad / 2}px;

  svg {
    fill: ${props => props.theme.tertiary};
  }
`;

const Selected = styled(Inline)`
  margin-top: ${pad}px;

  ${Button} {
    ${voice.quiet};
  }
`;

export default ModalExport;

import React, {useState, useRef, useLayoutEffect} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Style
import {slideLeft} from "../../style/components/animations";
import {Text} from "../../style/components/general";

const Marquee = ({text}) => {
  const textRef = useRef(null);

  const [overflow, setOverflow] = useState(false);

  useLayoutEffect(() => {
    const {current} = textRef;
    if (current) setOverflow(current.scrollWidth > current.clientWidth);
  }, []);

  return (
    <Wrapper shouldAnimate={overflow}>
      <MarqueeAnimation>
        {text}&nbsp;
        {text}
      </MarqueeAnimation>
      <Original ref={textRef}>{text}</Original>
    </Wrapper>
  );
};

Marquee.propTypes = {
  text: PropTypes.node.isRequired
};

// Style Overrides
const MarqueeAnimation = styled(Text)`
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  white-space: nowrap;
  font-weight: bold;
`;

const Original = styled(Text)`
  display: block;
  word-wrap: normal;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: bold;
`;

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;

  &:hover {
    ${MarqueeAnimation} {
      opacity: ${({shouldAnimate}) => (shouldAnimate ? 1 : 0)};
      animation: ${slideLeft} linear 10s infinite;
    }

    ${Original} {
      opacity: ${({shouldAnimate}) => (shouldAnimate ? 0 : 1)};
    }
  }
`;

export default Marquee;

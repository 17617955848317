import React, {useRef, useState, useEffect} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";

// Utils
import useMountedState from "../hooks/useMountedState.js";

// Style
import {border, pad} from "../style/components/variables.js";
import {Abbr, Text} from "../style/components/general.js";

const Switch = ({testId, state, disabled, on, off, showValue, callback}) => {
  const isMounted = useMountedState();

  const element = useRef(null);

  const [enabled, setEnabled] = useState(null);

  const setCheck = () => {
    if (!disabled) {
      const {checked} = element.current;
      element.current.checked = !checked;
      setEnabled(!state);
      callback(!state);
    }
  };

  // set intial state
  useEffect(() => {
    if (isMounted() && element.current) {
      element.current.checked = state;
      setEnabled(state);
    }
  }, [isMounted, state]);

  return (
    <CheckLabel data-testid={testId}>
      <SwitchWrapper onClick={setCheck} disabled={disabled}>
        <Abbr title={!showValue && enabled ? on : off}>
          <SwitchOutline checked={enabled}>
            <CheckInput
              type="checkbox"
              ref={element}
              data-testid={`${testId}-checkbox`}
              disabled={disabled}
            />
            <SwitchIcon checked={enabled} />
          </SwitchOutline>
        </Abbr>
      </SwitchWrapper>
      {showValue && <Text>{enabled ? on : off}</Text>}
    </CheckLabel>
  );
};

Switch.propTypes = {
  testId: PropTypes.string,
  state: PropTypes.bool,
  disabled: PropTypes.bool,
  on: PropTypes.string,
  off: PropTypes.string,
  showValue: PropTypes.bool,
  callback: PropTypes.func.isRequired
};

Switch.defaultProps = {
  testId: "switch",
  state: false,
  disabled: false,
  on: "ON",
  off: "OFF",
  showValue: true
};

// Style Overrides
const CheckLabel = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
`;

const SwitchWrapper = styled.button`
  display: flex;
`;

const SwitchOutline = styled.div`
  position: relative;
  width: 30px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${pad}px;
  border: ${border} solid ${props => props.theme.secondary};
  border-radius: 10px;
  background: ${props => (!props.checked ? props.theme.secondary : "transparent")};
  transition: all ease 0.1s;

  &:hover {
    cursor: pointer;
  }

  ${props =>
    props.disabled &&
    css`
      &:hover {
        cursor: auto;
      }
    `}
`;

const SwitchIcon = styled.span`
  width: 100%;
  height: 100%;
  cursor: inherit;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    background: ${props => (!props.checked ? props.theme.tertiary : props.theme.secondary)};
    border-radius: 50%;
    margin: 2px;
    transition: all ease 0.1s;
  }
`;

const CheckInput = styled.input`
  display: none;

  &:checked + span:after {
    left: 12px;
  }
`;

export default Switch;

export const STATIC = "Static";
export const TABLE = "Table";
export const CHART = "Chart";
export const STAT = "Statistic";
export const HEADER = "Header";
export const TEXT = "Text";
export const INPUT = "Input";
export const CHECKSHEET = "Checksheet";
export const EVENT = "Event";

export const MIN_FONT_SIZE = 9;
export const MAX_FONT_SIZE = 18;
export const FONT_SIZE_NUM_OPTIONS = 6;
export const DEFAULT_FONT_SIZE =
  MIN_FONT_SIZE + ((MAX_FONT_SIZE - MIN_FONT_SIZE) / FONT_SIZE_NUM_OPTIONS) * 3;

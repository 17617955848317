import React, {createContext, useState, useCallback, useContext, useRef, useMemo} from "react";
import PropTypes from "prop-types";

// Utils
import {AuthContext} from "./auth.js";
import useApi from "../hooks/useApi.js";

export const NotificationContext = createContext();

const NotifyProvider = ({children}) => {
  const {currentUser} = useContext(AuthContext);

  const [notifications, setNotifications] = useState(null);

  const {api, loading} = useApi("alerts");

  const call = useRef(false);

  const getNotifications = useCallback(
    (slug = null) => {
      if (!slug) setNotifications(null);
      else if (!call.current) {
        call.current = true;
        api
          .callGet("", {slug, userId: currentUser.publicId})
          .then(({status, data}) => {
            if (status === 200 && data) setNotifications(data);
          })
          .finally(() => {
            call.current = false;
          });
      }
    },
    [api, currentUser]
  );

  // Make the provider update only when it should.
  // We only want to force re-renders on dependencies
  const memoedValue = useMemo(
    () => ({
      notifications,
      setNotifications,
      getNotifications,
      isLoading: loading
    }),
    [getNotifications, notifications, loading]
  );

  return (
    <NotificationContext.Provider value={memoedValue}>{children}</NotificationContext.Provider>
  );
};

NotifyProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default NotifyProvider;

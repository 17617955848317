import React, {useContext, useMemo} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faExclamation} from "@fortawesome/free-solid-svg-icons";

// Utils
import {AuthContext} from "../../contexts/auth";
import {SettingsContext} from "../../contexts/settings";

// Style
import {voice} from "../../style/components/typography";
import {border, colors, pad, radius} from "../../style/components/variables";
import {Abbr, Label, List, ListItem, Small, Text} from "../../style/components/general";

const Notifications = ({showEditModal, existing, notifications}) => {
  const {allIds, byId} = notifications;

  const {roleCanAccessResource, roles} = useContext(AuthContext);
  const {settings} = useContext(SettingsContext);

  const hasRoleRecipientsEnabled = useMemo(() => {
    if (roles?.length > 0 && settings?.roleRecipients === "true") return true;
    return false;
  }, [roles, settings]);

  const renderNotifications = list =>
    list?.length > 0 ? (
      list.map(id => {
        const {template, recipients, type, name} = byId[id];

        const recipientsAreRoles = type === "roles";
        let settingAlert = "";

        if (!recipientsAreRoles && hasRoleRecipientsEnabled)
          settingAlert = (
            <StyledAbbr title="Needs to be reconfigured for role recipients">
              <FontAwesomeIcon icon={faExclamation} />
            </StyledAbbr>
          );

        if (recipientsAreRoles && !hasRoleRecipientsEnabled)
          settingAlert = (
            <StyledAbbr title="Needs to be reconfigured for user recipients">
              <FontAwesomeIcon icon={faExclamation} />
            </StyledAbbr>
          );

        const title = template?.subject?.toUpperCase() || name?.toUpperCase();

        return (
          <Notification key={id}>
            {title && (
              <StyledText bold>
                {title} {settingAlert}
              </StyledText>
            )}
            {recipients?.length ? (
              <Text quiet>
                Notify:&nbsp;
                {recipients.map((recipient, index) => (
                  <span key={recipient}>
                    {recipient}
                    {index !== recipients.length - 1 && ", "}
                  </span>
                ))}
              </Text>
            ) : (
              ""
            )}
          </Notification>
        );
      })
    ) : (
      <Description quiet>No notifications of this type configured.</Description>
    );

  return (
    <Menu>
      {roleCanAccessResource("facility_checksheet_notifications", "create") && (
        <Edit type="button" onClick={() => showEditModal(true)}>
          <Abbr title="Edit">
            <FontAwesomeIcon icon={faEdit} />
          </Abbr>
        </Edit>
      )}

      {allIds?.length > 0 ? (
        <>
          <NotificationList>
            <Label bold>Completed Notifications</Label>
            <br />
            <List column>
              {renderNotifications(
                allIds.filter(
                  id =>
                    (!byId[id].depends || byId[id].depends === null) &&
                    byId[id].trigger !== "Unacceptable Parameter"
                )
              )}
            </List>
          </NotificationList>
          <NotificationList>
            <Label bold>Field Notifications</Label>
            <br />
            <List column>
              {renderNotifications(
                allIds.filter(id => byId[id].depends && byId[id].depends !== null)
              )}
            </List>
          </NotificationList>
          <NotificationList>
            <Label bold>Unacceptable Parameters</Label>
            <br />
            <List column>
              {renderNotifications(
                allIds.filter(id => byId[id].trigger === "Unacceptable Parameter")
              )}
            </List>
          </NotificationList>
          {!existing && (
            <Text>
              <Small>Notifications will save on publish</Small>
            </Text>
          )}
        </>
      ) : (
        <NotificationList>
          <Label bold>Notifications</Label>
          <Text>Checksheet specific notifications have not been configured.</Text>
        </NotificationList>
      )}
    </Menu>
  );
};

Notifications.propTypes = {
  showEditModal: PropTypes.func.isRequired,
  existing: PropTypes.bool,
  notifications: PropTypes.objectOf(PropTypes.any).isRequired
};

Notifications.defaultProps = {
  existing: false
};

// Style Overrides
const Menu = styled.div`
  position: relative;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  width: 100%;
  padding: ${pad}px;
  margin-bottom: ${pad}px;

  ${Text} {
    max-width: 95%;
  }
`;

const Edit = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: ${pad / 2}px ${pad}px;
  width: min-content;

  svg {
    fill: ${({theme}) => theme.secondary};
  }
`;

const NotificationList = styled.div`
  margin-bottom: ${pad}px;

  ${Label} {
    ${voice.quiet};
  }
`;

const Notification = styled(ListItem)`
  margin-top: ${pad / 2}px;
  width: 100%;
`;

const Description = styled(Text)`
  margin-bottom: ${pad}px;
  ${voice.weak};
`;

const StyledAbbr = styled(Abbr)`
  background-color: ${colors.red};
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-left: ${pad}px;
  padding-left: 1px;

  svg {
    fill: ${({theme}) => theme.tertiary};
    height: 16px;
    width: 16px;
  }
`;

const StyledText = styled(Text)`
  display: flex;
  ${voice.small}
`;

export default Notifications;

export const voice = {
  quiet: {
    "font-size": "12px",
    "line-height": "1.2em"
  },
  weak: {
    "font-size": "13px",
    "line-height": "1.2em"
  },
  small: {
    "font-size": "14px",
    "line-height": "1.2em"
  },
  normal: {
    "font-size": "16px",
    "line-height": "1.5em"
  },
  medium: {
    "font-size": "22px",
    "line-height": "1.5em"
  },
  strong: {
    "font-size": "30px",
    "line-height": "1.5em"
  },
  loud: {
    "font-size": "50px",
    "line-height": "unset"
  }
};

export default voice;

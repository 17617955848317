const CHART_RATIO = 0.38;
const STAT_HEIGHT = 103;
const GAP_HEIGHT = 10;

const getChartHeight = () => window.innerHeight * CHART_RATIO;

class AnalyticsColumn {
  constructor(existing) {
    this.members = existing || [];
    let height = 0;
    if (existing) {
      existing.map(el => {
        if (el.type === "plot") height += getChartHeight();
        else height += STAT_HEIGHT;
        height += GAP_HEIGHT;
      });
    }
    this.height = height;
  }

  insert(item) {
    if (item.type === "plot") this.height += getChartHeight();
    else this.height += STAT_HEIGHT;
    this.height += GAP_HEIGHT;
    this.members.push(item);
  }
}

export default AnalyticsColumn;

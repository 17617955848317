/**
 * @function isMobileDevice
 * check for mobile device based on window orientation
 *
 * @returns boolean for mobile device
 */
export const isMobileDevice = () =>
  !!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator?.userAgent || window?.navigator?.userAgent || ""
  );

/**
 * @function isMobileSize
 * check if current screen width indicates mobile device
 *
 * @returns boolean mobile screens
 */
export const isMobileSize = () => window.matchMedia("(max-width: 766px)").matches;

/**
 * @function isMobile
 * check if current screen width and device indicates mobile device
 *
 * @returns boolean mobile screens
 */
export const isMobile = () => isMobileDevice() || isMobileSize();

/**
 * @function isMobile
 * check if current screen width and device type is a mobile device below 767
 *
 * @returns boolean mobile screens
 */
export const isSmallMobile = () => isMobileDevice() && isMobileSize();

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Style
import {voice} from "../style/components/typography.js";
import {pad} from "../style/components/variables.js";
import {bp} from "../style/components/breakpoints.js";
import {z} from "../style/components/mixins.js";

const Badge = ({count, children, offset, offsetX, offsetY, color, diameter}) =>
  children || (count && count > 0) ? (
    <BadgeWrapper>
      <BadgeIcon
        offset={offset}
        offsetX={offsetX}
        offsetY={offsetY}
        color={color}
        diameter={diameter}>
        {count || children}
      </BadgeIcon>
    </BadgeWrapper>
  ) : (
    ""
  );

Badge.propTypes = {
  count: PropTypes.number,
  children: PropTypes.node,
  offset: PropTypes.string,
  offsetX: PropTypes.string,
  offsetY: PropTypes.string,
  color: PropTypes.string,
  diameter: PropTypes.string
};

Badge.defaultProps = {
  count: null,
  children: null,
  offset: "8px",
  offsetX: null,
  offsetY: null,
  color: null,
  diameter: "16px"
};

// Style Overrides
const BadgeWrapper = styled.div`
  position: relative;
  display: flex;
`;

const BadgeIcon = styled.div`
  position: absolute;
  top: -${({offsetY, offset}) => offsetY || offset};
  left: ${({offsetX, offset}) => offsetX || offset};
  color: ${({theme}) => theme.tertiary};
  background: ${({color, theme}) => color || theme.error};
  opacity: 0.95;
  border-radius: 50%;
  padding: ${pad / 2}px;
  text-align: center;
  width: ${({diameter}) => diameter || "16px"};
  height: ${({diameter}) => diameter || "16px"};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: ${z("top")};
  font-weight: bold;
  ${voice.quiet};

  svg {
    fill: ${({theme}) => theme.tertiary};
  }

  ${bp(3)} {
    width: ${({diameter}) => diameter || "20px"};
    height: ${({diameter}) => diameter || "20px"};
  }
`;

export default Badge;

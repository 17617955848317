import React, {Fragment} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Components
import Modal from "../../components/Modal.js";

// Style
import {pad} from "../../style/components/variables.js";
import {flex} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {breakpoint} from "../../style/components/breakpoints.js";
import {Button, Small} from "../../style/components/general.js";

const ModalRedirect = ({
  visible,
  setVisible,
  buttonOptions,
  orientation,
  goBack,
  hasBackButton
}) => (
  <Modal
    visible={visible}
    setVisible={setVisible}
    maxWidth={breakpoint.width[1]}
    hasBackButton={hasBackButton}
    goBack={goBack}>
    <Options orientation={orientation}>
      {buttonOptions.map(({action, description, elaboration, icon}) => (
        <Fragment key={description}>
          {elaboration && (
            <Small center secondary>
              {elaboration}
            </Small>
          )}
          <Option
            orientation={orientation}
            onClick={() => {
              action();
              if (goBack) goBack();
              else setVisible(false);
            }}>
            <OptionLabel>
              {icon && <FontAwesomeIcon icon={icon} />}
              <Small>{description}</Small>
            </OptionLabel>
          </Option>
        </Fragment>
      ))}
    </Options>
  </Modal>
);

ModalRedirect.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  buttonOptions: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func.isRequired,
      description: PropTypes.string.isRequired,
      icon: PropTypes.any
    })
  ).isRequired,
  orientation: PropTypes.string,
  goBack: PropTypes.func,
  hasBackButton: PropTypes.bool
};

ModalRedirect.defaultProps = {
  orientation: "row",
  goBack: null,
  hasBackButton: false
};

// Style Overrides
const Options = styled.div`
  display: flex;
  gap: ${pad}px;

  ${({orientation}) =>
    orientation === "column"
      ? css`
          flex-direction: column;
        `
      : css`
          flex-direction: row;
        `}
`;

const Option = styled.div`
  align-self: stretch;

  ${({orientation}) =>
    orientation === "column"
      ? css`
          width: 100%;
        `
      : css`
          width: 50%;
        `}
`;

const OptionLabel = styled(Button)`
  ${flex("column", "wrap", "center", "center")};
  ${voice.medium};
  width: 100%;
  height: 100%;
  padding: ${pad}px;
  text-align: center;
  text-transform: uppercase;
  color: ${props => props.theme.tertiary};
  background-color: ${props => props.theme.secondary};

  svg {
    fill: ${props => props.theme.tertiary};
    margin-bottom: ${pad}px;
  }
`;

export default ModalRedirect;

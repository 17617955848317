import React, {useState, useEffect, useRef} from "react";
import {useFormContext} from "react-hook-form";
import PropTypes from "prop-types";
import styled from "styled-components";

// Utils
import STATES from "../pages/facilities/helpers.js";
import MapProvider, {formatAddressComponents} from "../utils/google/maps.js";

// Components
import {InputText, InputSelect} from "./form/FormInputs.js";

// Style
import {flex} from "../style/components/mixins.js";
import {border, pad, radius} from "../style/components/variables.js";
import {ListItem, Button, FormField, Abbr, scrollbar} from "../style/components/general.js";

const maps = new MapProvider();

const Address = ({hidden}) => {
  const {watch, reset} = useFormContext();

  const requestCancel = useRef(false);

  const [options, setOptions] = useState(null);
  const [selected, setSelected] = useState(watch("address.line1"));

  const values = watch();
  const line1 = watch("address.line1");

  useEffect(() => {
    if (line1 && line1.length > 0 && line1 !== selected) {
      requestCancel.current = false;
      maps.textSearch({query: line1}, result => {
        if (result && result.length > 0 && !requestCancel.current)
          setOptions(
            result.map(place => ({
              id: place.place_id,
              address: place.formatted_address
            }))
          );
        else setOptions(null);
      });
    } else if (line1 && line1 === selected) {
      setOptions(null);
    }
  }, [line1, selected]);

  const handleSelect = target => {
    maps.getDetails({placeId: target}, details => {
      if (details) {
        const formatted = formatAddressComponents(details.address_components);
        if (formatted.line1) setSelected(formatted.line1);
        reset({
          ...values,
          address: formatted,
          hasAddress: true
        });
        setOptions(null);
        requestCancel.current = true;
      }
    });
  };

  return (
    <>
      <FormField hidden={hidden}>
        <InputText testId="line1" name="address.line1" placeholder="Line 1" label="Address" />
        {options?.length > 0 && (
          <OptionList>
            {options.map(({id, address: addr}) => (
              <ListItem key={id}>
                <Option type="button" onClick={() => handleSelect(id)}>
                  <Abbr title={addr}>{addr}</Abbr>
                </Option>
              </ListItem>
            ))}
          </OptionList>
        )}
      </FormField>
      <FormField hidden={hidden}>
        <InputText testId="line2" name="address.line2" placeholder="Line 2" />
      </FormField>
      <FormField hidden={hidden}>
        <InputText testId="city" name="address.city" placeholder="City" />
      </FormField>
      <FormField hidden={hidden}>
        <InputSelect testId="state" name="address.state" placeholder="State" options={STATES} />
      </FormField>
      <FormField hidden={hidden}>
        <InputText testId="zip" name="address.zipCode" placeholder="Zip code" />
      </FormField>
    </>
  );
};

Address.propTypes = {
  hidden: PropTypes.bool
};

Address.defaultProps = {
  hidden: false
};

const OptionList = styled.ul`
  ${flex("column", "nowrap", "start", "start")};
  position: relative;
  border: ${border} solid ${props => props.theme.secondary};
  border-radius: ${radius};
  width: 100%;
  margin-top: ${pad}px;
  max-height: 100px;
  max-width: 500px;
  overflow-y: scroll;

  ${scrollbar};

  li {
    width: 100%;
    padding: 0;

    background: ${props => props.theme.tertiary};

    &:hover {
      background: ${props => props.theme.secondary};
    }
  }
`;

const Option = styled(Button)`
  width: 100%;
  border-radius: 0;
  background: none;
  color: ${props => props.theme.secondary};
  text-align: left;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  &:hover {
    color: ${props => props.theme.tertiary};
  }
`;

export default Address;

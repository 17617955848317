import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {get} from "react-hook-form";

// Style
import {voice} from "../../style/components/typography.js";

const InputError = ({errors, name, testId, atBottom}) => {
  const {message} = errors ? get(errors, name) ?? {} : {};
  return (
    message && (
      <ErrorText data-testid={testId} atBottom={atBottom}>
        {message}
      </ErrorText>
    )
  );
};

InputError.propTypes = {
  testId: PropTypes.string,
  errors: PropTypes.objectOf(PropTypes.any).isRequired,
  name: PropTypes.string.isRequired,
  atBottom: PropTypes.bool
};

InputError.defaultProps = {
  testId: "input-error",
  atBottom: true
};

// Style Overrides
const ErrorText = styled.span`
  ${voice.quiet};
  display: flex;
  justify-content: ${({atBottom}) => (atBottom ? "flex-end" : "flex-start")};
  position: ${({atBottom}) => (atBottom ? "absolute" : "relative")};
  top: ${({atBottom}) => (atBottom ? "100%" : "unset")};
  left: 0;
  line-height: 1.5;
  color: ${({theme}) => theme.error};
`;

export default InputError;

import React, {useState, useContext, useEffect, useCallback, useRef, useMemo} from "react";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faExclamation, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {useParams} from "react-router-dom";

// Contexts
import {FacilityNavContext} from "../contexts/facilitynav.js";
import {AuthContext} from "../contexts/auth.js";
import {SettingsContext} from "../contexts/settings.js";
import {NotificationContext} from "../contexts/notify.js";

// Hooks
import useApi from "../hooks/useApi.js";
import useMountedState from "../hooks/useMountedState.js";

// Utils
import {exists} from "../utils/helpers.js";

// Components
import Room from "./general/Room.js";
import AccordionWrapped from "../components/AccordionWrapped.js";
import FacilityPageHeader from "./general/FacilityPageHeader.js";
import ModalFacilityStates from "./facility/ModalFacilityStates.js";
import ModalNotification, {extractRecipients} from "./facility/ModalNotification.js";

// Styles
import {flex} from "../style/components/mixins.js";
import {bp} from "../style/components/breakpoints.js";
import {border, colors, navWidth, pad, radius} from "../style/components/variables.js";
import {
  Heading,
  Inline,
  Button,
  Label,
  Text,
  Page,
  Loader,
  NotLoaded,
  Abbr,
  Pill
} from "../style/components/general.js";
import {NavContext} from "../contexts/nav.js";

const WEEKDAYS = {
  Mon: 0,
  Tue: 1,
  Wed: 2,
  Thu: 3,
  Fri: 4,
  Sat: 5,
  Sun: 6
};

const WEEKDAYS_IDX = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];

const MONTH_IDX = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

const NOTIFICATION_TYPES = {
  State: "State Change Alert",
  StateRemind: "State Change Reminder",
  Completed: "Task Completion Alert",
  OutOfRange: "Unacceptable Parameter Alert",
  Remind: "Outstanding Tasks Reminder",
  Overdue: "Overdue Tasks Alert"
};

const getTimeParts = (hour, min) => {
  let hourFormatted = hour;
  let minuteFormatted = min;
  let meridian = "AM";

  if (hour > 12) {
    hourFormatted -= 12;
    meridian = "PM";
  } else if (hour === 12) {
    meridian = "PM";
  } else if (hour === 0) hourFormatted = 12;

  if (minuteFormatted < 10) minuteFormatted = `0${minuteFormatted}`;

  return {hour: `${hourFormatted}`, min: `${minuteFormatted}`, meridian};
};

const getSuffix = date => {
  if (date === 1 || date === 21 || date === 31) return "st";
  if (date === 2 || date === 22) return "nd";
  if (date === 3 || date === 23) return "rd";
  return "th";
};

const formatTime = (times, freq) => {
  let formatted = "";
  let prevDateStr = "";

  for (let i = 0; i < times.length; i++) {
    const time = times[i];
    const {hour, min, dayOfWeek, dayOfMonth, month, dateStr} = time;

    if (!prevDateStr || prevDateStr !== dateStr) {
      if (i !== 0 && freq !== "Daily") formatted = `${formatted}), `;
      else if (i !== 0) formatted = `${formatted}, `;

      if (month) formatted = `${formatted}${MONTH_IDX[month - 1]} ${dayOfMonth} (`;
      else if (dayOfMonth) {
        const formattedDayOfMonth = `the ${dayOfMonth}${getSuffix(dayOfMonth)}`;
        formatted = `${formatted}${formattedDayOfMonth} (`;
      } else if (exists(dayOfWeek)) formatted = `${formatted}${WEEKDAYS_IDX[dayOfWeek]} (`;
    } else if (i !== 0) formatted = `${formatted}, `;

    prevDateStr = dateStr;

    const {hour: formattedHour, min: formattedMin, meridian} = getTimeParts(hour, min);

    if (exists(hour)) formatted = `${formatted}${formattedHour}:${formattedMin} ${meridian}`;
  }

  if (freq !== "Daily") formatted = `${formatted})`;

  return formatted;
};

const FREQUENCY_ORDER = ["None", "Daily", "Weekly", "Monthly", "Annual"];

const FacilityNotifications = () => {
  const isMounted = useMountedState();

  const {states, getStates} = useContext(NavContext);
  const {facility, setFacility} = useContext(FacilityNavContext);
  const {roles} = useContext(AuthContext);
  const {settings} = useContext(SettingsContext);
  const {getNotifications: getAlerts} = useContext(NotificationContext);

  const {slug} = useParams();

  const {api: apiNotification, loading} = useApi("notifications");
  const {api: apiFacilities} = useApi("facilities");

  const [notifications, setNotifications] = useState({});
  const [target, setTarget] = useState();
  const [targetInfo, setTargetInfo] = useState();
  // Modals
  const [showStateModal, setShowStateModal] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);

  const hasRoleRecipientsEnabled = useMemo(() => {
    if (roles?.length > 0 && settings?.roleRecipients === "true") return true;
    return false;
  }, [roles, settings]);

  const isInitialized = useRef(false);

  const getFacility = useCallback(() => {
    apiFacilities.callGet(slug).then(({status, data}) => {
      if (status === 200 && data) setFacility(data);
    });
  }, [apiFacilities, setFacility, slug]);

  const getNotifications = useCallback(
    () =>
      apiNotification.callGet(null, {facilityId: facility.id}).then(({status, data}) => {
        if (status === 200 && data) {
          setNotifications(data);
          if (!isInitialized.current) isInitialized.current = true;
        }
      }),
    [apiNotification, facility]
  );

  // Initial Load
  useEffect(() => {
    if (isMounted() && facility === null) getFacility();
  }, [apiFacilities, isMounted, facility, getFacility]);

  useEffect(() => {
    if (isMounted() && facility?.id) {
      getAlerts(slug);
      getNotifications();
      getStates();
    }
  }, [isMounted, facility, getAlerts, slug, getNotifications, getStates]);

  const addNotification = ({type, tags, state, occurrences, recipients}) => {
    const isRole = hasRoleRecipientsEnabled;

    const formatted = {
      type,
      recipients: extractRecipients(recipients)
    };

    if (type === "Completed" && tags?.length > 0) formatted.tags = tags.map(({id}) => id);
    if ((type === "State" || type === "StateRemind") && state) formatted.state = state;

    if (occurrences?.length > 0)
      formatted.occurrences = occurrences?.map(occurrence => ({
        ...occurrence,
        dayOfWeek: occurrence.dayOfWeek?.length ? WEEKDAYS[occurrence.dayOfWeek[0]] : null
      }));

    apiNotification
      .callPost({
        notification: formatted,
        facilityId: facility?.id,
        isRole
      })
      .then(({status}) => {
        if (status === 201) {
          setShowNotificationModal(false);
          getFacility();
        }
      });
  };

  const updateNotification = notification => {
    const {type, freq, ids} = targetInfo;
    const old = notifications[type][freq][ids];
    const {recipients, times} = old;

    const isRole = hasRoleRecipientsEnabled;
    const wasRole = recipients[Object.keys(recipients)[0]]?.isRole;

    const updated = {
      type,
      recipients: extractRecipients(notification.recipients),
      occurrences: notification.occurrences?.map(occurrence => ({
        ...occurrence,
        dayOfWeek: occurrence.dayOfWeek?.length ? WEEKDAYS[occurrence.dayOfWeek[0]] : null
      }))
    };

    if (type === "Completed" && notification.tags?.length > 0)
      updated.tags = notification.tags.map(({id}) => id);
    if ((type === "State" || type === "StateRemind") && notification.state)
      updated.state = notification.state;

    apiNotification
      .callPut(null, {
        notification: updated,
        facilityId: facility?.id,
        old: {
          type,
          recipients: extractRecipients(recipients),
          // map to utc time data which is directly from database entries
          times: times ? times.map(({utc}) => utc) : null
        },
        isRole,
        wasRole
      })
      .then(({status}) => {
        if (status === 200) {
          setShowNotificationModal(false);
          setTarget(null);
          setTargetInfo(null);
          getFacility();
        }
      });
  };

  const removeNotification = (type, freq, ids) => {
    const old = notifications[type][freq][ids];
    const {recipients, times} = old;

    const wasRole = recipients[Object.keys(recipients)[0]]?.isRole;

    apiNotification
      .callPut(null, {
        facilityId: facility?.id,
        old: {
          type,
          recipients: extractRecipients(recipients),
          times: times ? times.map(({utc}) => utc) : null
        },
        wasRole
      })
      .then(({status}) => {
        if (status === 200) {
          setShowNotificationModal(false);
          getFacility();
        }
      });
  };

  const handleSave = ({notification}) =>
    targetInfo ? updateNotification(notification) : addNotification(notification);

  const editNotification = (type, freq, ids) => {
    setShowNotificationModal(true);
    setTargetInfo({type, freq, ids});
    setTarget(notifications[type][freq][ids]);
  };

  return (
    <>
      <Room name="notifications" />

      <NotificationPage>
        <HeaderWrapper>
          {facility?.name && (
            <FacilityPageHeader
              facility={facility}
              path="/notifications"
              reloadData={() => setFacility(null)}
            />
          )}
        </HeaderWrapper>

        <StatesWrapper>
          <Edit type="button" onClick={() => setShowStateModal(true)}>
            <FontAwesomeIcon icon={states && Object.keys(states)?.length > 0 ? faEdit : faPlus} />
          </Edit>
          <Label bold>States: (On/Off)</Label>
          <States>
            {states ? (
              Object.keys(states)?.map(key => (
                <Pill key={states[key].id} color={`#${states[key].color}`}>
                  {key}
                </Pill>
              ))
            ) : (
              <Loader />
            )}
          </States>
          {states && Object.keys(states)?.length === 0 && <Text>No states have been created.</Text>}
        </StatesWrapper>

        {isInitialized.current &&
          notifications &&
          facility?.hasNotifications &&
          Object.keys(notifications)
            .filter(
              key =>
                notifications[key] &&
                Object.values(notifications[key]).some(
                  freqNotifcation => !!Object.keys(freqNotifcation).length
                )
            )
            .map(key => {
              const notification = notifications[key];

              let heading = NOTIFICATION_TYPES[key];
              if (key.includes("StateRemind"))
                heading = `${NOTIFICATION_TYPES["StateRemind"]} (On ${key.replace(
                  "StateRemind",
                  ""
                )})`;
              else if (key.includes("State"))
                heading = `${NOTIFICATION_TYPES["State"]} (On ${key.replace("State", "")})`;

              return (
                <NotificationTypeWrapper key={`notification.${key}`}>
                  <Heading smaller>{heading}</Heading>
                  {FREQUENCY_ORDER.filter(
                    freq => notification[freq] && Object.keys(notification[freq]).length
                  ).map(freq => {
                    const notificationTimes = notification[freq];
                    return (
                      <NotificationList key={`${key}-${freq}`}>
                        {freq !== "None" && <Label bold>{freq}</Label>}
                        {Object.entries(notificationTimes).map(
                          ([idsStr, {tags, recipients, times}]) => {
                            const formatted = times ? formatTime(times, freq) : null;
                            const prefix = freq === "Daily" ? "At " : "On ";

                            const isRole = recipients[Object.keys(recipients)[0]]?.isRole;

                            const userList = Object.values(recipients).flatMap(({users}) => users);

                            let roleStr = " send email";

                            if (isRole) {
                              const roleList = Object.values(recipients)
                                .map(({label}) => label)
                                .join(", ");
                              roleStr = ` to ${roleList}`;
                            }

                            let settingAlert = "";

                            if (!isRole && hasRoleRecipientsEnabled)
                              settingAlert = (
                                <StyledAbbr title="Needs to be reconfigured for role recipients">
                                  <FontAwesomeIcon icon={faExclamation} />
                                </StyledAbbr>
                              );

                            if (isRole && !hasRoleRecipientsEnabled)
                              settingAlert = (
                                <StyledAbbr title="Needs to be reconfigured for user recipients">
                                  <FontAwesomeIcon icon={faExclamation} />
                                </StyledAbbr>
                              );

                            return (
                              <NotificationWrapper
                                key={`${key}-${freq}-${formatted}-${isRole ? "role" : "user"}`}>
                                <AccordionWrapped
                                  labelNode={
                                    <AccordionLabel>
                                      {formatted
                                        ? `${prefix}${formatted}${roleStr}`.toUpperCase()
                                        : `On Action${roleStr}`.toUpperCase()}
                                      {settingAlert}
                                    </AccordionLabel>
                                  }
                                  menu={
                                    <Options>
                                      <Button
                                        type="button"
                                        onClick={e => {
                                          editNotification(key, freq, idsStr);
                                          e.stopPropagation();
                                        }}
                                        data-testid="textGroup.edit">
                                        <FontAwesomeIcon icon={faEdit} />
                                      </Button>
                                      <Button
                                        type="button"
                                        onClick={e => {
                                          removeNotification(key, freq, idsStr);
                                          e.stopPropagation();
                                        }}
                                        data-testid="textGroup.remove">
                                        <FontAwesomeIcon icon={faTrash} />
                                      </Button>
                                    </Options>
                                  }
                                  invertColors>
                                  <DetailWrapper>
                                    <Detail>
                                      {freq === "None" && !key.includes("State") && (
                                        <p>
                                          <strong>Included Tagged Fields:</strong>&nbsp;
                                          {tags?.length > 0 ? "Enabled" : "Disabled"}
                                        </p>
                                      )}
                                      <strong>Send To:</strong> <p>{userList.join(", ")}</p>
                                    </Detail>
                                  </DetailWrapper>
                                </AccordionWrapped>
                              </NotificationWrapper>
                            );
                          }
                        )}
                      </NotificationList>
                    );
                  })}
                </NotificationTypeWrapper>
              );
            })}

        {!facility?.hasNotifications && isInitialized.current && !loading && (
          <NotificationTypeWrapper>
            <None>
              <Circle>
                <FontAwesomeIcon icon={faExclamation} />
              </Circle>
              &nbsp;&nbsp;<Text>Notifications have not been configured for this facility.</Text>
            </None>
          </NotificationTypeWrapper>
        )}

        {!isInitialized.current && loading && (
          <NotLoaded>
            <Loader />
          </NotLoaded>
        )}

        <AddNotification onClick={() => setShowNotificationModal(true)}>
          <FontAwesomeIcon icon={faPlus} />
          &nbsp; Notification
        </AddNotification>
      </NotificationPage>

      {showStateModal && (
        <ModalFacilityStates visible={showStateModal} setVisible={setShowStateModal} />
      )}

      {showNotificationModal && (
        <ModalNotification
          visible={showNotificationModal}
          setVisible={state => {
            if (!state) {
              setTarget(null);
              setTargetInfo(null);
            }
            setShowNotificationModal(state);
          }}
          handleSave={handleSave}
          setManage={state => {
            if (!state) {
              setTarget(null);
              setTargetInfo(null);
            }
            setShowNotificationModal(state);
          }}
          target={target}
          freq={targetInfo?.freq}
          type={targetInfo?.type}
        />
      )}
    </>
  );
};

// Style Overrides
const NotificationPage = styled(Page)`
  margin-bottom: ${pad * 2}px;
  margin-left: ${pad * 2}px;

  ${bp(3)} {
    margin-left: ${navWidth}px;
    width: calc(100% - 70px);
  }
`;

const Edit = styled(Button)`
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 ${pad / 2}px;
  margin: ${pad}px;

  svg {
    fill: ${({theme}) => theme.tertiary};
  }
`;

const StatesWrapper = styled.div`
  position: relative;
  min-width: 200px;
  width: fit-content;
  padding: ${pad}px;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
`;

const States = styled(Inline)`
  flex-wrap: wrap;
  gap: ${pad}px;
  min-height: 35px;
  margin-top: ${pad / 2}px;

  div {
    margin: 0;
  }
`;

const Options = styled(Inline)`
  gap: ${pad}px;
  padding-right: ${pad * 2}px;
`;

const Detail = styled.span`
  ${flex("column", "nowrap")};
  gap: ${pad}px;
  height: min-content;
  margin-left: ${pad}px;
  padding: ${pad}px;
`;

const DetailWrapper = styled(Inline)`
  width: 100%;
  align-items: baseline;
  border-radius: 0 0 ${radius} ${radius};
  background-color: ${({theme}) => theme.tertiary};
`;

const NotificationWrapper = styled.div`
  width: 100%;
`;

const NotificationList = styled(Inline)`
  flex-direction: column;
  align-items: start;
  width: 100%;
`;

const HeaderWrapper = styled.div`
  ${flex("row", "nowrap", "space-between", "center")};
`;

const NotificationTypeWrapper = styled.div`
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  padding: ${pad}px;
  margin-top: ${pad}px;
`;

const None = styled.div`
  ${flex("row", "nowrap", "center", "center")};
  padding: ${pad}px;
`;

const Circle = styled.span`
  background: ${({theme}) => theme.error};
  border-radius: 50%;
  padding: ${pad / 2}px;
  padding-left: 6px;
  text-align: center;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;

  svg {
    fill: ${({theme}) => theme.tertiary};
  }
`;

const StyledAbbr = styled(Abbr)`
  background-color: ${colors.red};
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: ${pad}px;
  padding-left: 1px;

  svg {
    fill: ${({theme}) => theme.tertiary};
  }
`;

const AccordionLabel = styled.span`
  ${flex("row", "nowrap", "start", "center")};
  color: ${({theme}) => theme.tertiary};
  font-weight: bold;
  padding-left: ${pad}px;
`;

const AddNotification = styled(Button)`
  margin-top: ${pad}px;
`;

export default FacilityNotifications;

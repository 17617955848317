import React, {useContext, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

// Contexts
import {AuthContext} from "../contexts/auth.js";

// Hooks
import useMountedState from "../hooks/useMountedState.js";

// Components
import CustomTables from "./general/CustomTables.js";
import GlobalTable from "./global-tables/GlobalTable.js";
import Room from "./general/Room.js";

// Styles
import {pad} from "../style/components/variables.js";
import {flex} from "../style/components/mixins.js";
import {Button, Page, Search, SearchIcon} from "../style/components/general.js";

const GlobalTables = () => {
  const isMounted = useMountedState();

  const {atLeast, isRole} = useContext(AuthContext);

  const [active, setActive] = useState(null);
  const [toggle, setToggle] = useState(false);
  const [query, setQuery] = useState(null);

  const [hasNoTables, setHasNoTables] = useState(true);

  const typing = useRef(false);

  const handleInputRateLimit = input => {
    if (typing.current) clearTimeout(typing.current);
    const timer = setTimeout(() => {
      setQuery(input);
      typing.current = null;
    }, 200);
    typing.current = timer;
  };

  // Initial Load - Handle View
  useEffect(() => {
    if (isMounted()) setToggle(localStorage.getItem("globalTableView") === "single");
  }, [isMounted]);

  return (
    <Page>
      <Room name="global-tables" active={active} setActive={setActive} />

      <Menu>
        {atLeast("admin") && !isRole("Lab") && (
          <Button
            type="button"
            onClick={() => {
              localStorage.setItem("globalTableView", toggle ? "all" : "single");
              setToggle(prev => !prev);
            }}>
            {toggle ? "View Facility Tables" : "View as Single Table"}
          </Button>
        )}
        {!toggle && (hasNoTables === false || !!query) && (
          <Option>
            <SearchIcon>
              <FontAwesomeIcon icon={faSearch} />
            </SearchIcon>
            <Search
              name="search"
              type="text"
              placeholder="Tables..."
              onChange={({target}) => handleInputRateLimit(target.value)}
            />
          </Option>
        )}
      </Menu>

      {!toggle ? (
        <CustomTables inGlobalTables setHasNoTables={setHasNoTables} query={query} />
      ) : (
        <GlobalTable setHasNoTables={setHasNoTables} />
      )}
    </Page>
  );
};

// Style Overrides
const Menu = styled.div`
  ${flex("row", "wrap")};
  gap: ${pad}px;
  padding: ${pad}px 0;
  margin-bottom: ${pad / 2}px;
  width: 100%;
`;

const Option = styled.div`
  ${flex("row", "nowrap", "start", "center")};
  position: relative;
  height: 100%;
  margin-right: ${pad / 2}px;

  &:last-child {
    margin-right: 0;
  }
`;

export default GlobalTables;

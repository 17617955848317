import React, {useContext, Fragment, useState} from "react";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleQuestion, faPlus} from "@fortawesome/free-solid-svg-icons";

// Utils
import {AuthContext} from "../contexts/auth.js";

// Components
import Accordion from "../components/Accordion.js";
import ModalRoleAdd from "./user-roles/ModalRoleAdd.js";
import UserRole from "./user-roles/UserRole.js";
import Modal from "../components/Modal.js";

// Style
import {voice} from "../style/components/typography.js";
import {border, pad, radius} from "../style/components/variables.js";
import {
  Button,
  Heading,
  List,
  ListItem,
  Loader,
  Page,
  Text,
  Title
} from "../style/components/general.js";

const UserRoles = () => {
  const {currentUser, atLeast, types, roles, getUserRoles} = useContext(AuthContext);

  const [visible, setVisible] = useState(false);
  const [help, setHelp] = useState(null);

  return (
    currentUser && (
      <Page>
        <PageHeader>
          <PageTitle>Manage Roles</PageTitle>
          <Button type="button" onClick={() => setVisible(true)}>
            New&nbsp;
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </PageHeader>
        {roles && types ? (
          <Roles>
            <List column>
              {types.map(type => {
                const hasRoles = [];
                return (
                  atLeast(type.name) &&
                  type.name !== "super" && (
                    <UserType key={type.id}>
                      <TypeLabel>
                        {type.name.toUpperCase()}
                        <Help type="button" onClick={() => setHelp(type)}>
                          <FontAwesomeIcon icon={faCircleQuestion} />
                        </Help>
                      </TypeLabel>
                      <List column>
                        {roles.length > 0 ? (
                          roles.map((role, index) => {
                            const {id, type: userType, label} = role;
                            const match = type.name === userType.name;
                            if (match) hasRoles.push(role);
                            if (index === roles.length - 1 && hasRoles.length === 0)
                              return (
                                <Text key={id}>Roles have not been defined for this type.</Text>
                              );
                            return (
                              match && (
                                <Fragment key={id}>
                                  <Accordion label={label.toUpperCase()}>
                                    <UserRole role={role} update={getUserRoles} />
                                  </Accordion>
                                </Fragment>
                              )
                            );
                          })
                        ) : (
                          <Text>Roles have not been defined.</Text>
                        )}
                      </List>
                    </UserType>
                  )
                );
              })}
            </List>
          </Roles>
        ) : (
          <Loader />
        )}

        {visible && (
          <ModalRoleAdd visible={visible} setVisible={setVisible} update={getUserRoles} />
        )}

        {help && (
          <Modal visible={help !== null} setVisible={() => setHelp(null)}>
            <Heading>{help.name.toUpperCase()}</Heading>
            <Text>{help.description}</Text>
          </Modal>
        )}
      </Page>
    )
  );
};

// Style Overrides
const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PageTitle = styled(Title)`
  margin-bottom: ${pad}px;
`;

const Roles = styled.div`
  padding: ${pad}px;
  border: ${border} solid ${props => props.theme.secondary};
  border-radius: ${radius};
`;

const UserType = styled(ListItem)`
  width: 100%;
  margin-bottom: ${pad}px;
`;

const TypeLabel = styled.p`
  display: flex;
  font-style: italic;
  font-weight: bold;
  color: ${props => props.theme.primary};
  margin-bottom: ${pad}px;
`;

const Help = styled.button`
  ${voice.quiet};
  margin-left: ${pad / 2}px;
  width: min-content;

  svg {
    fill: ${props => props.theme.primary};
  }
`;

export default UserRoles;

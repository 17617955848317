import {useState, createContext, useMemo, useEffect, useCallback} from "react";
import PropTypes from "prop-types";

// Hooks
import useMountedState from "../hooks/useMountedState";
import useApi from "../hooks/useApi";

export const NavContext = createContext();

const NavProvider = ({children}) => {
  const isMounted = useMountedState();

  const [online, setOnline] = useState(!!navigator.onLine);
  const [showManageSessions, setShowManageSessions] = useState(false);
  const [idleVisible, setIdleVisible] = useState(false);
  const [states, setStates] = useState();
  const [showModalState, setShowModalState] = useState(false);

  const {api: apiFacilityState} = useApi("facility-states", {suppress: {error: true}});

  const getStates = useCallback(
    () =>
      apiFacilityState.callGet().then(({status, data}) => {
        if (status === 200 && data) {
          const stateMap = {};
          data.map(({name, ...rest}) => {
            stateMap[name] = rest;
          });
          setStates(stateMap);
        }
      }),
    [apiFacilityState]
  );

  useEffect(() => {
    if (isMounted() && !states) getStates();
  }, [isMounted, states, getStates]);

  // Make the provider update only when it should.
  // We only want to force re-renders on dependencies
  const memoedValue = useMemo(
    () => ({
      online,
      setOnline,
      showManageSessions,
      setShowManageSessions,
      idleVisible,
      setIdleVisible,
      states,
      getStates,
      showModalState,
      setShowModalState
    }),
    [idleVisible, online, showManageSessions, states, getStates, showModalState]
  );

  return <NavContext.Provider value={memoedValue}>{children}</NavContext.Provider>;
};

NavProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default NavProvider;

import React, {createContext, useState, useCallback, useEffect, useMemo, useContext} from "react";
import PropTypes from "prop-types";

// Utils
import useApi from "../hooks/useApi.js";
import {getHeaderSiteTitle, setHeaderSiteTitle} from "../utils/header.js";
import {ThemeStateContext} from "./theme.js";

// Style
import {heroTheme} from "../style/components/variables.js";

export const SettingsContext = createContext();

const SettingsProvider = ({children}) => {
  const {theme, setTheme} = useContext(ThemeStateContext);

  const [settings, setSettings] = useState({
    siteTitle: "",
    logo: "",
    timezone: "US/Pacific",
    maxFileSize: 8,
    ...heroTheme
  });

  useEffect(() => {
    if (settings?.siteTitle !== "" && settings?.siteTitle !== getHeaderSiteTitle())
      setHeaderSiteTitle(settings.siteTitle);
  }, [settings]);

  const {api} = useApi("settings");

  const getSettings = useCallback(
    () =>
      api.callGet().then(({status, data}) => {
        if (status === 200) setSettings(data);
      }),
    [api]
  );

  useEffect(() => {
    const {pathname: path} = window.location;
    if (
      !path.includes("/signin") &&
      !path.includes("forgot-password") &&
      !path.includes("reset-password")
    )
      getSettings();
  }, [getSettings]);

  useEffect(() => {
    // Currently in Light Mode
    const temp = {};

    if (settings.primary && settings.primary !== theme?.primary && theme?.name === "light")
      temp.primary = settings.primary;

    if (settings.secondary && settings.secondary !== theme?.secondary && theme?.name === "light")
      temp.secondary = settings.secondary;

    if (settings.tertiary && settings.tertiary !== theme?.tertiary && theme?.name === "light")
      temp.tertiary = settings.tertiary;

    // Currently in Dark Mode
    if (settings.primaryDark && settings.primaryDark !== theme?.primary && theme?.name === "dark")
      temp.primary = settings.primaryDark;

    if (
      settings.secondaryDark &&
      settings.secondaryDark !== theme?.secondary &&
      theme?.name === "dark"
    )
      temp.secondary = settings.secondaryDark;

    if (
      settings.tertiaryDark &&
      settings.tertiaryDark !== theme?.tertiary &&
      theme?.name === "dark"
    )
      temp.tertiary = settings.tertiaryDark;

    if (Object.keys(temp).length > 0) setTheme({...theme, ...temp});
  }, [settings, theme, setTheme]);

  const saveSettings = useCallback(
    updated =>
      api
        .callPost({
          settings: {...updated}
        })
        .then(({status, data}) => {
          if (status === 201) setSettings(data.settings);
        }),
    [api]
  );

  // Make the provider update only when it should.
  // We only want to force re-renders on dependencies
  const memoedValue = useMemo(
    () => ({
      settings,
      getSettings,
      saveSettings
    }),
    [getSettings, saveSettings, settings]
  );

  return <SettingsContext.Provider value={memoedValue}>{children}</SettingsContext.Provider>;
};

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default SettingsProvider;

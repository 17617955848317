import React, {useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLock} from "@fortawesome/free-solid-svg-icons";
import * as yup from "yup";
import {yupResolver} from "@hookform/resolvers/yup";

// Context
import {AuthContext} from "../../contexts/auth.js";
import {NavContext} from "../../contexts/nav.js";
import {useSocket} from "../../contexts/socket.js";
import {SettingsContext} from "../../contexts/settings.js";

// Hooks
import useMountedState from "../../hooks/useMountedState.js";
import useApi from "../../hooks/useApi.js";
import usePrevious from "../../hooks/usePrevious.js";

// Utils
import {
  convertToUserTimezone,
  exists,
  prettyDateInUserTimezone,
  toTitleCase
} from "../../utils/helpers.js";
import {
  BASE_OPTIONS,
  BASE_OPTIONS_PLURAL,
  DAY_IDX,
  DAY_NAMES_DAYJS,
  DAY_TO_IDX_DAYJS,
  getNumberSuffix,
  IDX_TO_DAY,
  IDX_TO_DAY_DAYJS
} from "../schedule/helpers.js";

// Components
import Modal from "../../components/Modal.js";
import {
  InputCalendar,
  InputDay,
  InputError,
  InputNumber,
  InputRadioGroup,
  InputSelect
} from "../../components/form/FormInputs.js";

// Style
import {bp} from "../../style/components/breakpoints.js";
import {border, pad, radius} from "../../style/components/variables.js";
import {
  Abbr,
  Button,
  Form,
  FormField,
  FormFieldWrapper,
  Heading,
  HeadingMedium,
  Inline,
  Input,
  Label,
  Loader,
  RelativeWrapper,
  Text,
  Warning
} from "../../style/components/general.js";

// Socket Constants
import {
  CHECK_STATE,
  STATE_ON,
  SET_STATE_ON,
  SET_STATE_OFF,
  REQUEST_LOCKS,
  getFacilityRooms,
  LIST_LOCKS
} from "./Room.js";

const CUSTOM_OPTION = {
  name: "custom",
  label: "Custom..."
};

const NO_LAST_DAY_OPTION = ["daily", "weekly", "bi-weekly", "work-week", "semi-monthly"];

const TargetChecksheet = ({checksheet, frequencies, form}) => {
  const {id, name} = checksheet;

  const isMounted = useMountedState();

  const socket = useSocket();

  const {settings} = useContext(SettingsContext);

  const {api: apiUser} = useApi("users");
  const {api: apiFrequency} = useApi("frequencies");

  const [locks, setLocks] = useState(null);
  const [keyHolder, setKeyHolder] = useState(null);
  const [startPreview, setStartPreview] = useState("");
  const [skipDayCorrection, setSkipDayCorrection] = useState(true);
  const [skipDateCorrection, setSkipDateCorrection] = useState(true);

  const {
    watch,
    setValue,
    formState: {errors}
  } = form;
  const watchFrequency = watch(`checksheets.checksheet_${id}.frequency`);
  const watchBase = watch(`checksheets.checksheet_${id}.recurrence.base`);
  const watchInterval = watch(`checksheets.checksheet_${id}.recurrence.interval`);
  const watchAvailability = watch(`checksheets.checksheet_${id}.recurrence.availability`);
  const watchDueDays = watch(`checksheets.checksheet_${id}.recurrence.days`);
  const watchLastDay = watch(`checksheets.checksheet_${id}.start.lastDay`);
  const watchStartDate = watch(`checksheets.checksheet_${id}.start.date`);

  const prevFreq = usePrevious(watchFrequency);

  const listLocks = useCallback(({lockList}) => setLocks(lockList), []);

  const defaultDate = useMemo(() => {
    let date = dayjs();
    if (["weekly", "bi-weekly"].includes(watchFrequency)) date = date.endOf("week").add(1, "day");
    if (["monthly", "bi-monthly"].includes(watchFrequency)) date = date.endOf("month");
    if (["annually", "biennially", "triennially"].includes(watchFrequency))
      date = date.endOf("year");

    if (watchFrequency === "semi-monthly") {
      const midCurrentMonth = Math.floor(date.daysInMonth() / 2);

      if (date.date() > midCurrentMonth) {
        date = date.date(date.daysInMonth());
      } else {
        date = date.date(midCurrentMonth);
      }
    }

    if (watchFrequency === "quarterly") {
      const currentMonth = date.month();
      let nextMonth = currentMonth;

      if (nextMonth >= 0 && nextMonth < 3) nextMonth = 2;
      if (nextMonth >= 3 && nextMonth < 6) nextMonth = 5;
      if (nextMonth >= 6 && nextMonth < 9) nextMonth = 8;
      else nextMonth = 11;

      date = date.date(1);
      date = date.month(nextMonth);

      const quarterEnd = date.daysInMonth();
      date = date.date(quarterEnd);
    }

    if (watchFrequency === "quadrimesterly") {
      const currentMonth = date.month();
      let nextMonth = currentMonth;

      if (nextMonth >= 0 && nextMonth < 4) nextMonth = 3;
      if (nextMonth >= 4 && nextMonth < 8) nextMonth = 7;
      else nextMonth = 11;

      date = date.date(1);
      date = date.month(nextMonth);

      const quarterEnd = date.daysInMonth();
      date = date.date(quarterEnd);
    }

    if (watchFrequency === "semi-annually") {
      const currentMonth = date.month();
      let nextMonth = currentMonth;

      if (nextMonth >= 0 && nextMonth < 6) nextMonth = 5;
      else nextMonth = 11;

      date = date.date(1);
      date = date.month(nextMonth);

      const quarterEnd = date.daysInMonth();
      date = date.date(quarterEnd);
    }

    return {
      date: date.format("YYYY-MM-DD"),
      visibleDate: date.format("MM/DD/YYYY"),
      lastDay: false
    };
  }, [watchFrequency]);

  useLayoutEffect(() => {
    if (prevFreq !== watchFrequency || !watchBase) {
      setValue(`checksheets.checksheet_${id}.recurrence.interval`, 1);
      setValue(`checksheets.checksheet_${id}.recurrence.base`, "days");
      if (prevFreq !== watchFrequency) {
        if (["weekly", "bi-weekly"].includes(watchFrequency))
          setValue(`checksheets.checksheet_${id}.recurrence.days`, ["Sun"]);
        setValue(`checksheets.checksheet_${id}.start`, defaultDate);
      }
    }
  }, [watchFrequency, watchBase, prevFreq, setValue, id, defaultDate]);

  // Custom Frequency (allows multiple day selection) -> update dueDay depending on startDate
  useEffect(() => {
    const startDateWeekday = dayjs(watchStartDate).day();
    if (
      (watchBase === "weeks" || watchFrequency === "weekly" || watchFrequency === "bi-weekly") &&
      startDateWeekday &&
      DAY_NAMES_DAYJS[startDateWeekday] &&
      !skipDayCorrection
    ) {
      const targetWeekday = toTitleCase(DAY_NAMES_DAYJS[startDateWeekday]);
      setSkipDateCorrection(true);

      if (
        (!watchDueDays || watchFrequency === "weekly" || watchFrequency === "bi-weekly") &&
        exists(startDateWeekday)
      )
        setValue(`checksheets.checksheet_${id}.recurrence.days`, [targetWeekday]);
      else if (targetWeekday && watchDueDays && !watchDueDays.includes(targetWeekday)) {
        setValue(
          `checksheets.checksheet_${id}.recurrence.days`,
          [...watchDueDays, targetWeekday].sort(day => DAY_TO_IDX_DAYJS[day.toLowerCase()])
        );
      }
    } else setSkipDayCorrection(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchBase, watchStartDate, skipDayCorrection, setValue]);

  // Custom Frequency (allows multiple day selection) -> updates startDate depending on dueDays
  useEffect(() => {
    if (
      (watchBase === "weeks" || watchFrequency === "weekly" || watchFrequency === "bi-weekly") &&
      watchDueDays?.length > 0 &&
      !skipDateCorrection
    ) {
      const startDateWeekday = dayjs(watchStartDate).day();
      if (!watchDueDays.includes(IDX_TO_DAY_DAYJS[startDateWeekday])) {
        let i = dayjs().day();
        let hasResetToSunday = false;
        while (!watchDueDays.includes(IDX_TO_DAY_DAYJS[i])) {
          if (i === 6) {
            // prevents infinite loop if for whatever reason watchDueDays does not contain any valid days
            if (hasResetToSunday) break;
            hasResetToSunday = true;
            i = 0;
          } else i++;
        }
        let start = dayjs(watchStartDate).day(i);

        if (start.isBefore(dayjs(), "day")) start = start.add(1, "week");

        setSkipDayCorrection(true);
        setValue(`checksheets.checksheet_${id}.start.date`, start.format("YYYY-MM-DD"));
        setValue(`checksheets.checksheet_${id}.start.visibleDate`, start.format("MM/DD/YYYY"));
      }
    } else setSkipDateCorrection(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchBase, watchDueDays, skipDateCorrection, setValue]);

  // Socket Management - resource locking
  useEffect(() => {
    if (isMounted() && checksheet?.slug && !locks) {
      socket.emit(REQUEST_LOCKS, {
        rooms: getFacilityRooms(checksheet.slug, "checksheet"),
        type: "checksheet",
        to_sender: true
      });
    }

    socket.on(LIST_LOCKS, listLocks);

    return () => {
      // unbind all event handlers used in this component
      socket.off(LIST_LOCKS, listLocks);
    };
  }, [socket, isMounted, checksheet, locks, listLocks]);

  const checksheetLocked = useMemo(() => {
    if (locks && locks[`checksheet_${checksheet.id}`]) {
      apiUser.callGet(locks[`checksheet_${checksheet.id}`].user).then(({status, data}) => {
        if (status === 200 && data) {
          const {firstName, lastName} = data;
          setKeyHolder(
            <Abbr title={`${firstName} ${lastName}`}>
              by {firstName}&nbsp;{lastName}
            </Abbr>
          );
        }
      });
      return true;
    }

    return false;
  }, [apiUser, checksheet.id, locks]);

  const frequencyWarning = useMemo(() => {
    if (
      (watchFrequency === "monthly" || watchFrequency === "bi-monthly" || watchBase === "months") &&
      dayjs(watchStartDate).date() > 28
    )
      return `For months with less or greater than 
          ${dayjs(watchStartDate).date()} days, this checksheet will be due on the last day of the
          month`;

    if (watchFrequency === "semi-monthly")
      return "Due date defaults to the middle or last day of the month for semi-monthly tasks.";

    if (watchFrequency === "quarterly")
      return "Due date defaults to the last day of the quarter for quarterly tasks.";

    if (watchFrequency === "quadrimesterly")
      return "Start date defaults to the first day of the year and is due every four months.";

    if (watchFrequency === "semi-annually")
      return "Start date defaults to the first day of the year and is due every six months.";

    if (watchFrequency === CUSTOM_OPTION.name && watchBase?.includes("month"))
      if (watchInterval <= 1)
        return "Availability window not supported for intervals of less than 2 months.";
      else if (watchInterval >= 24)
        return "Availability window not supported for intervals of 24 months or longer.";

    if (watchFrequency === CUSTOM_OPTION.name && watchBase?.includes("year"))
      if (watchInterval > 10)
        return "Availability window not supported for intervals of longer than 10 years.";

    return "";
  }, [watchFrequency, watchBase, watchStartDate, watchInterval]);

  // Set preview based on selected frequency
  useEffect(() => {
    if (watchFrequency !== "none" && watchStartDate && watchInterval) {
      const interval = Number.parseInt(watchInterval, 10);

      if (watchFrequency !== "custom" || (!Number.isNaN(interval) && interval > 0)) {
        let params;

        if (watchFrequency !== "custom") params = {start: watchStartDate, name: watchFrequency};

        if (watchFrequency === "custom" && interval && watchBase) {
          params = {
            start: watchStartDate,
            interval,
            base: watchBase,
            exclude:
              watchDueDays && watchDueDays.length > 1
                ? DAY_IDX.filter(idx => !watchDueDays.includes(IDX_TO_DAY[idx]))
                : null
          };
          if (watchAvailability) {
            params.availableBase =
              watchBase.includes("month") || interval === 1 ? "months" : "years";
            params.availableIndex = watchAvailability;
          }
        }
        if (watchLastDay) params.lastDay = watchLastDay;

        if (params)
          apiFrequency.callGet(null, params).then(({status, data}) => {
            if (status === 200 && data) setStartPreview(data);
          });
      } else {
        setStartPreview("");
      }
    } else {
      setStartPreview("");
    }
  }, [
    apiFrequency,
    watchStartDate,
    watchLastDay,
    watchFrequency,
    watchInterval,
    watchBase,
    watchDueDays,
    watchAvailability
  ]);

  const hasLastDayOption = useMemo(() => {
    if (!watchFrequency) return false;
    if (watchFrequency === "custom")
      return watchBase?.includes("month") || watchBase?.includes("year");
    return !NO_LAST_DAY_OPTION.includes(watchFrequency);
  }, [watchBase, watchFrequency]);

  const availableOptions = useMemo(() => {
    if (
      watchFrequency !== CUSTOM_OPTION.name ||
      !["month", "months", "year", "years"].includes(watchBase)
    )
      return [];

    const options = [];

    const parsedInterval = parseInt(watchInterval, 10);

    if (exists(parsedInterval) && !Number.isNaN(parsedInterval)) {
      if (watchBase?.includes("month") && parsedInterval > 1) {
        for (let i = 1; i < parsedInterval + 1; i++) {
          options.push({name: i, label: `${i}${getNumberSuffix(i)} Month`});
        }
      }

      if (watchBase?.includes("year")) {
        if (parsedInterval === 1) {
          for (let i = 1; i < 13; i++) {
            options.push({name: i, label: `${i}${getNumberSuffix(i)} Month`});
          }
        } else {
          for (let i = 1; i < parsedInterval + 1; i++) {
            options.push({name: i, label: `${i}${getNumberSuffix(i)} Year`});
          }
        }
      }
    }

    return options;
  }, [watchBase, watchFrequency, watchInterval]);

  const showAvailable = useMemo(() => {
    if (watchFrequency !== CUSTOM_OPTION.name) return false;
    if (watchBase?.includes("month"))
      return watchInterval && watchInterval > 1 && watchInterval < 24;
    if (watchBase?.includes("year")) return watchInterval && watchInterval < 10;
    return false;
  }, [watchBase, watchFrequency, watchInterval]);

  useEffect(() => {
    if (!showAvailable) setValue(`checksheets.checksheet_${id}.recurrence.availability`, "");
  }, [showAvailable, setValue, id]);

  const firstDueDate = useMemo(() => {
    if (startPreview && watchAvailability && watchFrequency === CUSTOM_OPTION.name) {
      let due = convertToUserTimezone(startPreview, settings.timezone)
        .add(1, watchBase.includes("month") || watchInterval === 1 ? "month" : "year")
        .subtract(1, "minute");

      if (watchLastDay) due = due.date(due.daysInMonth());

      return due.format("MM/DD/YYYY, h:mm A");
    }
    return null;
  }, [
    startPreview,
    watchBase,
    watchInterval,
    watchFrequency,
    watchLastDay,
    watchAvailability,
    settings
  ]);

  const minDateFromAvailabilityWindow = useMemo(() => {
    if (watchInterval && watchAvailability && watchBase) {
      let due = dayjs();
      if (watchInterval === 1 && watchBase.includes("year"))
        due = due.add(
          12 - watchAvailability,
          watchBase.includes("month") || watchInterval === 1 ? "month" : "year"
        );
      else
        due = due.add(
          watchInterval - watchAvailability,
          watchBase.includes("month") || watchInterval === 1 ? "month" : "year"
        );

      if (watchLastDay) due = due.date(due.daysInMonth());

      return due.format("YYYY-MM-DD");
    }
    return null;
  }, [watchBase, watchInterval, watchLastDay, watchAvailability]);

  const minDateComputed = useMemo(() => {
    if (minDateFromAvailabilityWindow) return minDateFromAvailabilityWindow;
    return dayjs().format("YYYY-MM-DD");
  }, [minDateFromAvailabilityWindow]);

  const minDate = useMemo(() => {
    const minDateComputedParsed = dayjs(minDateComputed);
    if (checksheet.lastCompleted) {
      const lastCompleted = convertToUserTimezone(
        checksheet.lastCompleted,
        settings?.timezone
      )?.add(1, "day");
      if (lastCompleted?.isAfter(minDateComputedParsed, "day"))
        return lastCompleted.format("YYYY-MM-DD");
    }
    return minDateComputed;
  }, [settings, checksheet, minDateComputed]);

  useEffect(() => {
    if (watchStartDate && dayjs(watchStartDate).isBefore(dayjs(minDate))) {
      setValue(`checksheets.checksheet_${id}.start.date`, minDate);
      setValue(
        `checksheets.checksheet_${id}.start.visibleDate`,
        dayjs(minDate).format("MM/DD/YYYY")
      );
      setValue(`checksheets.checksheet_${id}.start.lastDay`, false);
    }
  }, [watchStartDate, minDate, setValue, id]);

  return (
    <Checksheet key={id} disabled={checksheetLocked}>
      {checksheetLocked && (
        <Lock>
          <FontAwesomeIcon icon={faLock} />
          &nbsp; Locked by {keyHolder}
        </Lock>
      )}
      <HeadingMedium>{name.toUpperCase()}</HeadingMedium>
      <InputSelect
        name={`checksheets.checksheet_${id}.frequency`}
        options={[
          {name: "none", label: "None"},
          ...frequencies,
          {name: "custom", label: "Custom..."}
        ]}
      />
      {watchFrequency === CUSTOM_OPTION.name && (
        <CustomGroup fitcontent>
          <FormField>
            <Label bold>REPEAT EVERY</Label>
            <CustomGroup>
              <InputNumber
                name={`checksheets.checksheet_${id}.recurrence.interval`}
                min={1}
                max={10}
                maxWidth
                required={false}
                disableInverse
                hideError
              />
              <BaseSelectWrapper>
                <InputSelect
                  name={`checksheets.checksheet_${id}.recurrence.base`}
                  options={
                    watch(`checksheets.checksheet_${id}.recurrence.interval`) > 1
                      ? BASE_OPTIONS_PLURAL
                      : BASE_OPTIONS
                  }
                  fullWidth
                />
              </BaseSelectWrapper>
            </CustomGroup>
          </FormField>
          {showAvailable && (
            <InputSelect
              name={`checksheets.checksheet_${id}.recurrence.availability`}
              label="AVAILABLE"
              options={availableOptions}
              placeholder="Always"
            />
          )}
          <InputError errors={errors} name={`checksheets.checksheet_${id}.recurrence.interval`} />
        </CustomGroup>
      )}

      {(watchFrequency === "weekly" || watchFrequency === "bi-weekly" || watchBase === "weeks") && (
        <InputDay
          name={`checksheets.checksheet_${id}.recurrence.days`}
          restrictOne={watchFrequency !== CUSTOM_OPTION.name}
          restrictWarning={
            watchFrequency === CUSTOM_OPTION.name
              ? "Multiple day selection not currently supported for intervals longer than 1 week"
              : null
          }
        />
      )}

      {watchFrequency && watchFrequency !== "none" && (
        <DatesWrapper>
          <Inline>
            <DateWrapper selfInline>
              <Label bold>AVAILABLE DATE</Label>
              <Input
                type="text"
                value={
                  startPreview && prettyDateInUserTimezone(startPreview, null, "MM/DD/YYYY, h:mm A")
                }
                disabled
              />
            </DateWrapper>
            {watchAvailability && (
              <DateWrapper selfInline>
                <Label bold>NEXT DUE DATE</Label>
                <Input type="text" value={firstDueDate} disabled />
              </DateWrapper>
            )}
          </Inline>
          <DateWrapper selfInline>
            <InputCalendar
              name={`checksheets.checksheet_${id}.start`}
              label={watchAvailability ? "INTERVAL END DATE" : "NEXT DUE DATE"}
              showLastDay={hasLastDayOption}
              min={minDate}
              disabled={
                watchFrequency === "semi-monthly" ||
                watchFrequency === "quarterly" ||
                watchFrequency === "quadrimesterly" ||
                watchFrequency === "semi-annually"
              }
            />
          </DateWrapper>
        </DatesWrapper>
      )}

      {frequencyWarning && (
        <WarningWrapper>
          <WarningHeader>Warning!</WarningHeader>&nbsp;{frequencyWarning}
        </WarningWrapper>
      )}
    </Checksheet>
  );
};

TargetChecksheet.propTypes = {
  checksheet: PropTypes.objectOf(PropTypes.any).isRequired,
  frequencies: PropTypes.arrayOf(PropTypes.any).isRequired,
  form: PropTypes.objectOf(PropTypes.any).isRequired
};

const ModalFacilityState = ({visible, setVisible, facility}) => {
  const isMounted = useMountedState();

  const socket = useSocket();

  const {currentUser, roleCanAccessResource} = useContext(AuthContext);
  const {states} = useContext(NavContext);
  const {settings} = useContext(SettingsContext);

  const {api: apiNotification} = useApi("notifications");
  const {api: apiFrequency} = useApi("frequencies");
  const {api: apiChecksheets} = useApi("checksheets");

  const [notifications, setNotifications] = useState();
  const [targetChecksheets, setTargetChecksheets] = useState();
  const [frequencies, setFrequencies] = useState();
  const [activeStates, setActiveStates] = useState();
  const [stateChanged, setStateChanged] = useState(false);

  const schema = yup.object().shape({
    checksheets: yup.lazy(obj =>
      yup.object(
        Object.fromEntries(
          Object.keys(obj).map(key => [
            key,
            yup.object().shape({
              frequency: yup.string().required("Please provide a frequency."),
              recurrence: yup
                .object()
                .nullable()
                .when("frequency", ([freq], s) => {
                  if (freq === CUSTOM_OPTION.name)
                    return yup.object().shape({
                      interval: yup
                        .number()
                        .transform((v, o) => (o === "" ? null : v))
                        .required("Please provide interval."),
                      days: yup
                        .array()
                        .of(yup.string())
                        .when("base", ([base]) => {
                          if (base !== "weeks") return yup.mixed().nullable();
                          return yup
                            .array()
                            .of(yup.string())
                            .test({
                              test: v => Array.isArray(v) && v.length > 0,
                              message: "Please provide weekday."
                            });
                        })
                    });

                  return s;
                })
            })
          ])
        )
      )
    )
  });

  const form = useForm({resolver: yupResolver(schema)});
  const {
    watch,
    setValue,
    formState: {dirtyFields},
    handleSubmit
  } = form;
  const watchState = watch("state");

  // Initial Load
  useEffect(() => {
    if (isMounted()) {
      if (!frequencies)
        apiFrequency.callGet().then(({status, data}) => {
          if (status === 200 && data) setFrequencies(data);
        });
    }
  }, [isMounted, apiNotification, facility, frequencies, apiFrequency]);

  const listenForStateChange = useCallback(
    ({facility: current, setBy, state}) => {
      if (setBy && current && current === facility.slug)
        setActiveStates(prev => ({...prev, [state]: setBy}));
    },
    [facility.slug]
  );

  // Socket Management
  useEffect(() => {
    if (isMounted() && currentUser && currentUser.publicId) socket.emit(CHECK_STATE, facility.slug);

    socket.on(STATE_ON, listenForStateChange);

    return () => {
      // unbind all event handlers used in this component
      socket.off(STATE_ON, listenForStateChange);
    };
  }, [socket, isMounted, currentUser, facility, listenForStateChange]);

  // Get notifications if state is defined
  useEffect(() => {
    if (isMounted() && facility.id && watchState)
      apiNotification
        .callGet(null, {
          facilityId: facility.id,
          notificationName: `State${toTitleCase(watchState)}`
        })
        .then(({status, data}) => {
          if (status === 200 && data?.length > 0) setNotifications(data);
        });
  }, [isMounted, facility.id, watchState, apiNotification]);

  // Get Checksheets when frequency change possible
  useEffect(() => {
    if (states && watchState && states[watchState]?.settings?.hasFrequencyChanges)
      apiChecksheets.callGet(null, {facilityId: facility.id}).then(({status, data}) => {
        if (status === 200 && data) {
          setTargetChecksheets(data);
          data.map(({id, dateDue, frequency, lastDay, availableBase, availableIndex}) => {
            if ((frequency && frequency?.order === null) || (availableBase && availableIndex)) {
              setValue(`checksheets.checksheet_${id}.frequency`, "custom");
              setValue(`checksheets.checksheet_${id}.recurrence.interval`, frequency.interval);
              setValue(`checksheets.checksheet_${id}.recurrence.base`, frequency.base);
              setValue(`checksheets.checksheet_${id}.recurrence.availability`, availableIndex);
              setValue(
                `checksheets.checksheet_${id}.recurrence.days`,
                frequency?.exclude
                  ? DAY_IDX.filter(idx => !frequency.exclude.includes(idx)).map(
                      idx => IDX_TO_DAY[idx]
                    )
                  : null
              );
            } else if (frequency)
              setValue(`checksheets.checksheet_${id}.frequency`, frequency.name);
            else setValue(`checksheets.checksheet_${id}.frequency`, "none");

            if (dateDue) {
              const dateFormatted =
                prettyDateInUserTimezone(dateDue, settings.timezone, "YYYY-MM-DD") ||
                dayjs().format("YYYY-MM-DD");

              setValue(`checksheets.checksheet_${id}.start.date`, dateFormatted);

              setValue(
                `checksheets.checksheet_${id}.start.visibleDate`,
                prettyDateInUserTimezone(dateDue, settings.timezone, "MM/DD/YYYY") ||
                  dayjs().format("MM/DD/YYYY")
              );

              setValue(`checksheets.checksheet_${id}.start.lastDay`, lastDay);

              if (frequency.name === "weekly") {
                const weekdayIdx = dayjs(dateFormatted).day();
                setValue(`checksheets.checksheet_${id}.recurrence.days`, [
                  IDX_TO_DAY_DAYJS[weekdayIdx]
                ]);
              }
            }
          });
        }
      });
  }, [states, watchState, apiChecksheets, facility, setValue, watch, settings]);

  const toggle = state => {
    if (!targetChecksheets?.length > 0) {
      socket.emit(
        activeStates && activeStates[state] ? SET_STATE_OFF : SET_STATE_ON,
        facility.slug,
        currentUser.publicId,
        states[state].id
      );
      setVisible(false);
    }

    setStateChanged(true);
  };

  const handleUpdate = ({checksheets}) => {
    const {checksheets: fieldsChanged} = dirtyFields;
    const params = {};

    Object.keys(checksheets).map(key => {
      const {frequency, recurrence, start} = checksheets[key] || {};
      const {interval, base, days, availability} = recurrence || {};
      let exclude = null;

      if (fieldsChanged && key in fieldsChanged) {
        if (interval && base && frequency === CUSTOM_OPTION.name) {
          let availableParams = {};
          if (availability)
            availableParams = {
              availableIndex: Number.parseInt(availability, 10),
              availableBase: base.includes("month") || interval === 1 ? "months" : "years"
            };

          if (base === "weeks")
            exclude =
              days?.length > 0 ? DAY_IDX.filter(idx => !days.includes(IDX_TO_DAY[idx])) : [];

          params[key] = {
            interval,
            base,
            exclude,
            start: start?.date,
            lastDay: start?.lastDay,
            ...availableParams
          };
        } else if (frequency && frequency !== "none")
          params[key] = {name: frequency, start: start?.date, lastDay: start?.lastDay};
        else params[key] = {name: null};
      }
    });

    apiChecksheets.callPatch(null, {checksheets: params}).then(({status}) => {
      if (status === 200) {
        setVisible(false);
        socket.emit(
          activeStates && activeStates[watchState] ? SET_STATE_OFF : SET_STATE_ON,
          facility.slug,
          currentUser.publicId,
          states[watchState].id
        );
      }
    });
  };

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <ModalHeading>Manage State</ModalHeading>

      {states && (
        <FormProvider {...form}>
          <Form onSubmit={handleSubmit(handleUpdate)} noValidate>
            {!stateChanged ? (
              <>
                <FormField>
                  <InputRadioGroup
                    name="state"
                    label="Target State"
                    options={Object.keys(states).filter(
                      stateName =>
                        !states[stateName]?.settings?.hasFrequencyChanges ||
                        roleCanAccessResource("facility_checksheet", "update")
                    )}
                  />
                </FormField>
                {!states[watchState]?.settings?.hasFrequencyChanges || !!targetChecksheets ? (
                  <>
                    {states[watchState]?.description && (
                      <Description>{states[watchState].description}</Description>
                    )}
                    {notifications && (
                      <RelativeWrapper>
                        <Notify>
                          <Text bold>Configured to Notify:</Text>
                          <Email>
                            {notifications.map(email => (
                              <Abbr title={email} key={email}>
                                {email}
                              </Abbr>
                            ))}
                          </Email>
                        </Notify>
                      </RelativeWrapper>
                    )}
                    {watchState && (
                      <Button type="button" onClick={() => toggle(watchState)}>
                        Toggle {activeStates && activeStates[watchState] ? "OFF" : "ON"}
                      </Button>
                    )}
                  </>
                ) : (
                  <LoaderWrapper>
                    <Loader />
                  </LoaderWrapper>
                )}
              </>
            ) : (
              targetChecksheets &&
              frequencies?.length > 0 && (
                <>
                  <FormField>
                    <Label bold>State may require updates to checksheet frequencies.</Label>
                    {targetChecksheets.map(checksheet => (
                      <TargetChecksheet
                        key={checksheet.id}
                        checksheet={checksheet}
                        frequencies={frequencies}
                        form={form}
                      />
                    ))}
                  </FormField>
                  <Inline>
                    {targetChecksheets.length > 0 && <Save type="submit">Save</Save>}
                    <Button
                      type="button"
                      onClick={() => {
                        setVisible(false);
                        socket.emit(
                          activeStates && activeStates[watchState] ? SET_STATE_OFF : SET_STATE_ON,
                          facility.slug,
                          currentUser.publicId,
                          states[watchState].id
                        );
                      }}>
                      Skip
                    </Button>
                  </Inline>
                </>
              )
            )}
          </Form>
        </FormProvider>
      )}
    </Modal>
  );
};

ModalFacilityState.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  facility: PropTypes.objectOf(PropTypes.any).isRequired
};

// Style Overrides
const ModalHeading = styled(Heading)`
  margin-bottom: ${pad}px;
`;

const Description = styled(Text)`
  margin-bottom: ${pad}px;
`;

const Notify = styled.div`
  margin-bottom: ${pad}px;
`;

const Email = styled.span`
  line-height: ${pad * 2.5}px;
  margin-left: ${pad}px;
  display: block;
  height: min-content;

  abbr {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const Checksheet = styled(Inline)`
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  padding: ${pad}px ${pad}px ${pad * 1.5}px;
  margin-bottom: ${pad}px;
  border-radius: ${radius};
  border: ${border} solid ${({theme}) => theme.secondary};

  p {
    white-space: nowrap;
  }

  div {
    margin: 0;

    ${({disabled}) =>
      disabled &&
      css`
        opacity: 0.8;
        pointer-events: none;
      `};
  }

  input,
  select {
    ${({disabled}) =>
      disabled &&
      css`
        opacity: 0.8;
        pointer-events: none;
      `};
  }

  &:hover {
    cursor: ${({disabled}) => (disabled ? "not-allowed" : "auto")};
  }
`;

const Lock = styled(Text)`
  position: absolute;
  top: 0;
  right: 0;
  padding: ${pad}px;
`;

const CustomGroup = styled(Inline)`
  margin-top: ${pad / 2}px;
  align-items: end;
  width: 100%;

  div {
    margin: 0;

    ${({fitcontent}) =>
      fitcontent &&
      css`
        width: max-content;
      `}
  }

  input {
    min-width: 35px;
    width: 35px;
  }

  select {
    width: 105px;
  }

  ${bp(2)} {
    margin-right: 0;
  }
`;

const DateWrapper = styled(FormFieldWrapper)`
  max-width: 180px;
  > div {
    width: 100%;
    max-width: 180px;
  }
`;

const DatesWrapper = styled(Inline)`
  flex-wrap: wrap;
`;

const BaseSelectWrapper = styled.div`
  select {
    max-width: 88px;
  }
`;

const WarningWrapper = styled(Warning)`
  margin-top: ${pad / 2}px;
  color: ${({theme}) => theme.secondary};
`;

const WarningHeader = styled.strong`
  color: ${({theme}) => theme.warning};
`;

const Save = styled(Button)`
  background: ${({theme}) => theme.primary};
`;

const LoaderWrapper = styled.div`
  width: 25px;
  height: 25px;
  position: relative;
`;

export default ModalFacilityState;

import React, {useCallback, useMemo} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {faCog} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Utils
import {STATIC, EVENT, CHART, STAT, HEADER, TEXT, INPUT, CHECKSHEET} from "../../utils/report.js";

// Components
import ReportTable from "./ReportTable.js";
import Chart from "../../components/Chart.js";
import Statistic from "../../components/Statistic.js";

// Style
import {z} from "../../style/components/mixins.js";
import {border, pad, radius, transition} from "../../style/components/variables.js";
import {HeadingSmall, Inline, Text} from "../../style/components/general.js";

const Region = ({
  id,
  type,
  title,
  headers,
  data,
  requiredBreak,
  printScale,
  handleShowOptions,
  hasMin,
  hasMax,
  hasAvg,
  hasMedian,
  hasTotal,
  hasBandedRows,
  hasHiddenGridlines,
  highlight,
  secondInput,
  printable,
  footerRowCount,
  fontSize,
  alignment
}) => {
  const getFooterRowCount = useCallback(() => {
    let count = 0;
    if (hasMin) count++;
    if (hasMax) count++;
    if (hasTotal) count++;
    if (hasAvg) count++;
    if (hasMedian) count++;
    return count;
  }, [hasAvg, hasMax, hasMin, hasTotal, hasMedian]);

  const getRegion = useCallback(() => {
    if ((type === CHECKSHEET || type === EVENT || type === STATIC) && title)
      return (
        <ReportTable
          title={title}
          headers={headers}
          data={data}
          continued={requiredBreak}
          footerRowCount={footerRowCount ?? getFooterRowCount()}
          id={id}
          printable={printable}
          fontSize={fontSize}
          alignment={alignment}
          commas={type !== STATIC}
          bandedRows={hasBandedRows}
          hideGridlines={hasHiddenGridlines}
        />
      );

    if (type === CHART)
      return (
        <Chart
          view="analytics"
          chart={data}
          allSources={data.sources}
          label={data.label}
          printScale={printScale}
          chartType={data.chartType}
        />
      );

    if (type === STAT)
      return <Statistic stat={data} precision={0} fontSize={fontSize} alignment={alignment} />;

    if (type === HEADER)
      return (
        <StaticHeading id={`${id}-region`} fontSize={fontSize + 3} alignment={alignment}>
          {title}
        </StaticHeading>
      );

    if (type === TEXT && highlight)
      return (
        <StaticHighlight id={`${id}-region`} fontSize={fontSize + 2} alignment={alignment}>
          {data?.split("\n").map((line, i, arr) =>
            i < arr.length - 1 ? (
              // eslint-disable-next-line react/no-array-index-key
              <span key={`${id}-region-${i}`}>
                {line}
                <br />
              </span>
            ) : (
              line
            )
          )}
        </StaticHighlight>
      );

    if (type === TEXT && !highlight)
      return (
        <StaticText id={`${id}-region`} fontSize={fontSize + 2} alignment={alignment}>
          {data?.split("\n").map((line, i, arr) =>
            i < arr.length - 1 ? (
              <>
                {line}
                <br />
              </>
            ) : (
              line
            )
          )}
        </StaticText>
      );

    if (type === INPUT) {
      return (
        <Inline id={`${id}-region`} fullWidth>
          <StaticField bold fontSize={fontSize + 2} hasSecondInput={!!secondInput}>
            {title?.toUpperCase()}:
          </StaticField>
          {secondInput && (
            <StaticField bold fontSize={fontSize + 2} hasSecondInput={!!secondInput}>
              {secondInput?.toUpperCase()}:
            </StaticField>
          )}
        </Inline>
      );
    }

    return null;
  }, [
    type,
    title,
    headers,
    data,
    requiredBreak,
    getFooterRowCount,
    id,
    printable,
    printScale,
    footerRowCount,
    highlight,
    fontSize,
    alignment,
    secondInput,
    hasHiddenGridlines,
    hasBandedRows
  ]);

  const region = useMemo(() => getRegion(), [getRegion]);

  return (
    <Wrapper allowEdit={handleShowOptions !== null}>
      {handleShowOptions !== null && (
        <Option>
          <FontAwesomeIcon icon={faCog} onClick={() => handleShowOptions(id)} />
        </Option>
      )}
      {region}
    </Wrapper>
  );
};

Region.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  headers: PropTypes.objectOf(PropTypes.any),
  data: PropTypes.any,
  requiredBreak: PropTypes.bool,
  printScale: PropTypes.objectOf(PropTypes.any),
  handleShowOptions: PropTypes.func,
  hasMin: PropTypes.bool,
  hasMax: PropTypes.bool,
  hasAvg: PropTypes.bool,
  hasMedian: PropTypes.bool,
  hasTotal: PropTypes.bool,
  highlight: PropTypes.bool,
  secondInput: PropTypes.string,
  printable: PropTypes.bool,
  footerRowCount: PropTypes.number,
  fontSize: PropTypes.number,
  alignment: PropTypes.string,
  hasBandedRows: PropTypes.bool,
  hasHiddenGridlines: PropTypes.bool
};

Region.defaultProps = {
  id: null,
  title: null,
  headers: null,
  data: null,
  requiredBreak: false,
  printScale: null,
  handleShowOptions: null,
  hasMin: false,
  hasMax: false,
  hasAvg: false,
  hasMedian: false,
  hasTotal: false,
  highlight: false,
  secondInput: null,
  printable: false,
  footerRowCount: null,
  fontSize: 14,
  alignment: "left",
  hasBandedRows: false,
  hasHiddenGridlines: false
};

// Style Overrides
const StaticHeading = styled(HeadingSmall)`
  text-transform: uppercase;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding: ${pad}px;
  font-size: ${props => props.fontSize}px;
  text-align: ${props => props.alignment};

  @media print {
    font-size: ${props => props.fontSize / 1.3333}pt;
  }
`;

const StaticText = styled(Text)`
  margin-bottom: ${pad}px;
  word-wrap: break-word;
  font-size: ${props => props.fontSize}px;
  text-align: ${props => props.alignment};

  @media print {
    font-size: ${props => props.fontSize / 1.3333}pt;
  }
`;

const StaticHighlight = styled(StaticText)`
  padding: ${pad * 2}px;
  border: ${border} solid ${props => props.theme.secondary};
  border-radius: ${radius};
  background-color: #dddddd;
`;

const StaticField = styled(Text)`
  margin: 0 ${pad}px;
  padding-top: ${pad * 2}px;
  border-bottom: ${border} solid ${props => props.theme.secondary};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: ${props => props.fontSize}px;
  text-align: left;
  width: 100%;

  ${props =>
    props.hasSecondInput &&
    css`
      width: 50%;
    `}

  @media print {
    font-size: ${props => props.fontSize / 1.3333}pt;
  }
`;

const Option = styled.div`
  visibility: hidden;
  opacity: 0;
  z-index: ${z("above")};
  position: absolute;
  transition: ${transition};

  top: 9px;
  right: 5px;

  svg {
    fill: ${props => props.theme.primary};
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  position: relative;
  z-index: ${z("base")};
  border-radius: ${radius};
  border: 2px dashed transparent;
  transition: ${transition};
  width: 100%;

  ${props =>
    props.allowEdit &&
    css`
      &:hover {
        border-color: ${props.theme.primary};
        ${Option} {
          visibility: visible;
          opacity: 1;
        }
      }
    `};
`;

export default Region;

import React from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Components
import InputError from "./InputError.js";
import Help from "../Help.js";

// Style
import {voice} from "../../style/components/typography.js";
import {flex} from "../../style/components/mixins.js";
import {border, pad, radius} from "../../style/components/variables.js";
import {Button, FormFieldWrapper, Inline, Label, Text} from "../../style/components/general.js";

const InputIcon = ({label, name, options, required, defaultValue, color}) => {
  const {
    setValue,
    watch,
    formState: {errors}
  } = useFormContext();

  const selected = watch(name);

  return (
    <FormFieldWrapper>
      {label && (
        <Label htmlFor={name} bold>
          <Text inline>
            {label.toUpperCase()}
            {required && <span>&nbsp;*</span>}&nbsp;
            <Help>
              Events of this type will be included in the status counts next to the selected icon on
              each facility card. To request an icon that is not currently an option, visit the
              following link and submit the name of the icon in a Support Ticket:
              <br />
              <br />
              <a href="https://fontawesome.com/search?o=r&m=free" target="_blank" rel="noreferrer">
                www.FontAwesome.com&nbsp;&nbsp;
              </a>
            </Help>
          </Text>
        </Label>
      )}
      <IconWrapper>
        {options.map(({icon, value}) => (
          <IconButton
            type="button"
            key={`icon-${value}`}
            onClick={() => setValue(name, value)}
            selected={selected ? selected === value : defaultValue === value}
            color={`#${color}`}>
            <FontAwesomeIcon icon={icon} />
          </IconButton>
        ))}
      </IconWrapper>
      <InputError errors={errors} name={name} />
    </FormFieldWrapper>
  );
};

InputIcon.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
  color: PropTypes.string
};

InputIcon.defaultProps = {
  label: null,
  required: false,
  defaultValue: null,
  color: null
};

// Style Overrides
const IconButton = styled(Button)`
  ${flex("column", "wrap", "center", "center")};
  ${voice.medium};
  text-transform: uppercase;
  background: transparent;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  padding: ${pad}px;
  height: 50px;
  width: 50px;
  margin-top: ${pad / 2}px;

  svg {
    fill: ${({theme}) => theme.secondary};
    margin-bottom: ${pad / 2}px;
  }

  &:hover {
    background: ${({color, theme}) => color || theme.secondary};

    svg {
      fill: ${({theme}) => theme.tertiary};
    }
  }

  ${({selected, color, theme}) =>
    selected &&
    css`
      background: ${color || theme.secondary};

      svg {
        fill: ${theme.tertiary};
      }
    `}
`;

const IconWrapper = styled(Inline)`
  gap: ${pad}px;
`;

export default InputIcon;

import React, {useEffect, useState, useCallback, useContext} from "react";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSearch, faEdit, faPlus} from "@fortawesome/free-solid-svg-icons";
import {DragDropContext, Droppable} from "react-beautiful-dnd";

// Utils
import useMountedState from "../hooks/useMountedState.js";
import useApi from "../hooks/useApi.js";
import {AuthContext} from "../contexts/auth.js";
import {resolveDependencyMismatches} from "./checksheet-builder/helpers.js";
import {generateUniqueKey, objectGuard} from "../utils/helpers.js";
import {
  addField,
  initialFieldValues,
  removeElement,
  sortHelp,
  updateElement
} from "../utils/builder.js";

// Components
import RenderChecksheet from "./checksheet-builder/RenderChecksheet.js";
import RenderChecksheetBuilder from "./checksheet-builder/RenderChecksheetBuilder.js";
import ModalTemplateInstances from "./checksheet-builder/ModalTemplateInstances.js";
import ModalAddField from "./checksheet-builder/ModalAddField.js";
import ModalDelete from "../components/ModalDelete.js";
import ModalPreviewTemplate from "./checksheet-builder/ModalPreviewTemplate.js";
import ModalHelp from "./checksheet-builder/ModalHelp.js";
import ModalEditElement from "./checksheet-builder/ModalEditElement.js";
import ModalFormula from "./checksheet-builder/ModalFormula.js";
import ModalConditionals from "./checksheet-builder/ModalConditionals.js";
import ModalDeleteElement from "./checksheet-builder/ModalDeleteElement.js";
import ModalRedirect from "./general/ModalRedirect.js";
import ModalElapsed from "./checksheet-builder/ModalElapsed.js";

// Style
import {voice} from "../style/components/typography.js";
import {breakpoint} from "../style/components/breakpoints.js";
import {pad, transition, border, radius} from "../style/components/variables.js";
import {flex} from "../style/components/mixins.js";
import {
  Page,
  Section,
  Title,
  ButtonFull,
  FormGroup,
  Button,
  Inline,
  Text,
  Heading,
  StickyWrapper,
  Loader,
  NotLoaded,
  Error,
  ButtonLoader,
  RelativeWrapper,
  SearchWrapper,
  Search,
  SearchIcon
} from "../style/components/general.js";

const ComponentTemplates = () => {
  const isMounted = useMountedState();

  const {roleCanAccessResource} = useContext(AuthContext);

  const [query, setQuery] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [currentResults, setCurrentResults] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [units, setUnits] = useState([]);
  const [showPreview, setShowPreview] = useState(false);
  const [edit, setEdit] = useState(false);
  const [viewInstances, setViewInstances] = useState(false);
  const [builder, setBuilder] = useState({byId: {}, allIds: []});
  const [target, setTarget] = useState(null);
  const [error, setError] = useState("");
  const [unitsToAdd, setUnitsToAdd] = useState([]);
  // Modals
  const [showModalHelp, setShowModalHelp] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showModalRedirectFormula, setShowModalRedirectFormula] = useState(false);
  const [formulaMode, setFormulaMode] = useState("create");
  const [showModalFormula, setShowModalFormula] = useState(false);
  const [elapsedMode, setElapsedMode] = useState("create");
  const [showModalElapsed, setShowModalElapsed] = useState(false);
  const [showConditionalModal, setShowConditionalModal] = useState(false);
  const [loadingTemplatesCreate, setLoadingTemplatesCreate] = useState(false);
  const [loadingTemplatesUpdate, setLoadingTemplatesUpdate] = useState(false);
  const [activeDroppableId, setActiveDroppableId] = useState("");

  const {api, loading: loadingTemplates} = useApi("base-components");
  const {api: apiUnits} = useApi("units");

  const loadTemplates = useCallback(() => {
    api.callGet("", {query: ""}).then(response => {
      setCurrentResults(response.data);
      setTemplateList(response.data);
    });
  }, [api]);

  useEffect(() => {
    if (isMounted() && api) {
      loadTemplates();
      apiUnits.callGet().then(response => {
        if (response.status === 200) setUnits(response.data);
      });
    }
  }, [api, apiUnits, isMounted, loadTemplates]);

  useEffect(() => {
    if (refresh) {
      setQuery("");
      setEdit(false);
      setRefresh(false);
    }
  }, [refresh]);

  useEffect(() => {
    setCurrentResults(
      templateList.filter(template => template.label.toLowerCase().includes(query.toLowerCase()))
    );
  }, [query, templateList]);

  const [selected, setSelected] = useState({});

  const formatOptions = useCallback(
    targetFields =>
      targetFields.map(field => {
        if (field.options && field.options.length > 0)
          return {
            ...field,
            options: field.options.map(option => ({
              option: option.option || option.value || objectGuard(option)
            })),
            toggle: true
          };

        return {...field, toggle: true};
      }),
    []
  );

  const formatSelected = useCallback(
    template => {
      const templateFields =
        template.fields && template.fields.length > 0
          ? formatOptions(template.fields)
          : [initialFieldValues];

      return {
        id: template.id,
        base: false,
        label: template.label || "",
        grid: template.grid || false,
        hasHelp: template.hasHelp || false,
        help: template.help || [{}],
        fields: templateFields,
        instances: template.instances
      };
    },
    [formatOptions]
  );

  const saveBuilder = (updated, newUnits) => {
    if (newUnits && newUnits.length > 0) {
      const temp = newUnits.filter(unit => !unitsToAdd.includes(unit));
      setUnitsToAdd(prev => [...prev, ...temp]);
      setUnits(prev => [...prev, ...temp]);
    }
    setBuilder(updated);
  };

  const initializeBuilder = useCallback(() => {
    const formatted = formatSelected(selected);
    const fields = [...formatted.fields];
    delete formatted.fields;
    const children = [];
    const nameMap = {};
    const fieldObjs = Object.fromEntries(
      fields.map(field => {
        const name = generateUniqueKey(field.label);
        nameMap[field.name] = name;
        children.push(name);
        return [
          name,
          {
            ...field,
            parentName: "component",
            name,
            element: "field"
          }
        ];
      })
    );
    const updated = {
      byId: {
        ...fieldObjs,
        component: {
          ...formatted,
          element: "component",
          children,
          label: formatted.label,
          name: "component",
          toggle: true
        }
      },
      allIds: ["component"]
    };
    resolveDependencyMismatches(updated, nameMap);
    setBuilder(updated);
  }, [formatSelected, selected]);

  useEffect(() => {
    if (isMounted() && Object.keys(selected).length > 0) initializeBuilder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, formatSelected, selected]);

  const buildTemplate = () => {
    const newUnits = [];
    const component = builder?.byId?.component;
    const children = builder?.byId?.component?.children;
    const template = {
      label: component.label,
      options: {
        grid: component.grid,
        hasHelp: component.hasHelp,
        help: component.help
      },
      units: newUnits
    };

    if (!builder || !builder.byId) return {};

    const byId = Object.fromEntries(
      Object.entries(builder.byId).map(([name, value]) => {
        const temp = {...value};
        delete temp.toggle;
        return [name, temp];
      })
    );

    return {
      ...template,
      fields: children.map(field => ({
        ...byId[field],
        units: byId[field].units ? byId[field].units.replace("Other: ", "") : null,
        element: "field"
      }))
    };
  };

  const handleEdit = base => {
    const children = builder?.byId?.component?.children;
    if (!children || children.length === 0) {
      setError("Template must contain at least one field");
      return;
    }
    setError("");

    if (base) setLoadingTemplatesCreate(true);
    else setLoadingTemplatesUpdate(false);

    const template = buildTemplate();

    if (base)
      api.callPost(template).then(() => {
        setEdit(false);
        setRefresh(true);
        setLoadingTemplatesCreate(false);
        loadTemplates();
      });
    else
      api.callPut(selected.id, template).then(() => {
        setEdit(false);
        setRefresh(true);
        setLoadingTemplatesUpdate(false);
        loadTemplates();
      });
  };

  const handleDelete = () => {
    api.callDelete(selected.id).then(() => {
      setSelected({});
      setRefresh(true);
      setQuery("");
      setEdit(false);
      setShowDelete(false);
      loadTemplates();
    });
  };

  const handleEditField = update => {
    const newUnits = [];
    if (update.units && !units.includes(update.units)) {
      setUnits(prev => [...prev, update.units].sort());
      newUnits.push(update.units);
    }
    const {builder: updated} = updateElement(builder, update, false);
    saveBuilder(updated, newUnits);
    setShowEditModal(false);
  };

  const handleDeleteField = update => {
    const updated = removeElement(builder, update);
    saveBuilder(updated);
    setShowDeleteModal(false);
  };

  const handleAddField = field => {
    setError("");
    const newUnits = [];
    if (field.units && !units.includes(field.units)) {
      setUnits(prev => [...prev, field.units].sort());
      newUnits.push(field.units);
    }
    const temp = {
      ...field,
      toggle: true
    };
    const updated = addField(builder, temp);
    saveBuilder(updated, newUnits);
  };

  const handleAddMultipleFields = fields => {
    setError("");
    const newUnits = [];
    let updated = {...builder};
    fields.map(field => {
      if (field.units && !units.includes(field.units)) {
        setUnits(prev => [...prev, field.units].sort());
        newUnits.push(field.units);
      }
      const temp = {
        ...field,
        toggle: true
      };
      updated = addField(updated, temp);
    });
    saveBuilder(updated, newUnits);
  };

  const handleFormula = field => {
    if (field.type === "generated") handleEditField(field);
    else {
      const newField = {
        ...field,
        type: "generated",
        disabled: true
      };
      handleAddField(newField);
    }
  };

  const handleConditional = values => {
    const element = {
      ...target,
      condition: values.condition
    };
    handleEditField(element);
  };

  const cloneElement = targetElement => {
    const {element, label, name} = targetElement;
    const clone = {
      ...builder.byId[name],
      label: `${label} copy`,
      generates: [],
      toggle: true,
      condition: null,
      name: null
    };
    const nameMap = {};

    let updated;
    if (element === "field") updated = addField(builder, clone);

    resolveDependencyMismatches(updated, nameMap);
    saveBuilder(updated);
  };

  const onDragStart = provided => {
    const {droppableId} = provided.source;
    setActiveDroppableId(droppableId);
  };

  // re-ordering list
  const onDragEnd = result => {
    const {destination, source, type} = result;
    if (!destination) {
      return;
    }

    let updatedBuilder = {...builder};

    if (type === source.droppableId) {
      const byId = {...builder.byId};

      const parent = byId[type];
      const children = [...parent.children];

      const [removed] = children.splice(source.index, 1);
      children.splice(destination.index, 0, removed);
      const help = sortHelp(parent.help, children, byId);

      updatedBuilder = {...updatedBuilder, byId: {...byId, [type]: {...parent, children, help}}};

      saveBuilder(updatedBuilder);
    }
  };

  const handleShowFormulaModal = element => {
    if (element.type === "generated") setShowModalRedirectFormula(true);
    else setShowModalFormula(true);
  };

  const handleShowElapsedModal = element => {
    if (element.type === "generated") {
      setElapsedMode("edit");
      setShowModalElapsed(true);
    } else {
      setElapsedMode("create");
      setShowModalElapsed(true);
    }
  };

  return (
    <TemplatePage>
      <Menu fullWidth>
        <Title>Manage Templates</Title>
        <Button type="button" onClick={() => window.history.back()}>
          <FontAwesomeIcon icon={faArrowLeft} />
          &nbsp;&nbsp;Go Back
        </Button>
      </Menu>
      <TemplateInline>
        <TemplateListContent>
          <TemplateList>
            <SectionHeader>Component Templates</SectionHeader>
            <SearchWrapper>
              <SearchIcon>
                <FontAwesomeIcon icon={faSearch} />
              </SearchIcon>
              <Search
                type="text"
                placeholder="Search"
                value={query}
                onChange={e => setQuery(e.target.value)}
              />
            </SearchWrapper>
            {!loadingTemplates ? (
              <TemplateTable>
                {currentResults && currentResults.length > 0 ? (
                  currentResults.map(template => (
                    <ComponentTemplate
                      data-testid="templates.option"
                      key={template.id}
                      onClick={() => {
                        setSelected({
                          id: template.id,
                          label: template.label || "",
                          grid: template.grid || false,
                          hasHelp: template.hasHelp || false,
                          help: template.help || [{}],
                          fields: template.fields || [initialFieldValues],
                          instances: template.instances
                        });
                        setQuery(template.label);
                        window.scrollTo(0, 0);
                      }}
                      active={selected && selected.label === template.label}>
                      {template.label}
                    </ComponentTemplate>
                  ))
                ) : (
                  <Text>No templates found.</Text>
                )}
              </TemplateTable>
            ) : (
              <NotLoaded>
                <Loader />
              </NotLoaded>
            )}
          </TemplateList>
        </TemplateListContent>
        <Preview>
          <Options>
            <SectionHeader>Selected Component</SectionHeader>
            {selected.label && (
              <Inline>
                {roleCanAccessResource("component", "update") && (
                  <Option
                    type="button"
                    onClick={() => {
                      if (!edit) setEdit(true);
                      else {
                        setEdit(false);
                        initializeBuilder();
                        setError("");
                      }
                    }}>
                    {!edit ? "Edit" : "Cancel"}
                  </Option>
                )}
                {roleCanAccessResource("component", "delete") && (
                  <Delete type="button" onClick={() => setShowDelete(!showDelete)}>
                    Delete
                  </Delete>
                )}
              </Inline>
            )}
          </Options>
          {selected.label ? (
            <RelativeWrapper>
              {edit ? (
                <EditForm>
                  <Builder>
                    <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                      <Droppable isDropDisabled droppableId="disabled">
                        {provided => (
                          <div {...provided.droppableProps} ref={provided.innerRef}>
                            <RenderChecksheetBuilder
                              builder={builder}
                              setBuilder={saveBuilder}
                              setTarget={setTarget}
                              setShowHelpModal={setShowModalHelp}
                              setShowEditModal={setShowEditModal}
                              setShowAddModal={setShowAddModal}
                              setShowDeleteModal={setShowDeleteModal}
                              setShowFormulaModal={handleShowFormulaModal}
                              setShowElapsedModal={handleShowElapsedModal}
                              setShowConditionalModal={setShowConditionalModal}
                              cloneElement={cloneElement}
                              activeDroppableId={activeDroppableId}
                              inTemplateBuilder>
                              {provided.placeholder}
                            </RenderChecksheetBuilder>
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                    {error && <Error>{error}</Error>}
                  </Builder>

                  <ButtonWrapper>
                    <ButtonFull
                      type="submit"
                      onClick={() => handleEdit(true)}
                      data-testid="templates.submit"
                      loading={loadingTemplatesCreate ? 1 : 0}>
                      Save as New Template
                      {loadingTemplatesCreate && <ButtonLoader />}
                    </ButtonFull>
                    <ButtonFull
                      type="button"
                      onClick={() => handleEdit(false)}
                      data-testid="templates.submit"
                      loading={loadingTemplatesUpdate ? 1 : 0}>
                      Update Template
                      {loadingTemplatesUpdate && <ButtonLoader />}
                    </ButtonFull>
                  </ButtonWrapper>
                </EditForm>
              ) : (
                <>
                  <FormGroup>
                    <RenderChecksheet task={{builder}} readOnly hideMeta />
                  </FormGroup>

                  {selected.instances &&
                    Object.keys(selected.instances).length > 0 &&
                    selected.instances[Object.keys(selected.instances)[0]] &&
                    selected.instances[Object.keys(selected.instances)[0]].length > 0 && (
                      <FormGroup>
                        <Button type="button" onClick={() => setViewInstances(true)}>
                          View Associated Components
                        </Button>
                      </FormGroup>
                    )}
                </>
              )}
            </RelativeWrapper>
          ) : (
            <Text>Please select a template to view.</Text>
          )}
        </Preview>
      </TemplateInline>

      {showDelete && (
        <ModalDelete
          visible={showDelete}
          setVisible={setShowDelete}
          confirmDelete={() => handleDelete()}
          title="Delete Component Template"
          loading={loadingTemplates}
        />
      )}

      {showPreview && (
        <ModalPreviewTemplate
          visible={showPreview}
          setVisible={setShowPreview}
          template={buildTemplate()}
        />
      )}

      {viewInstances && (
        <ModalTemplateInstances
          visible={viewInstances}
          setVisible={setViewInstances}
          template={selected}
        />
      )}

      {showModalHelp && target && (
        <ModalHelp visible={showModalHelp} setVisible={setShowModalHelp} target={target} />
      )}

      {showEditModal && target && (
        <ModalEditElement
          visible={showEditModal}
          setVisible={setShowEditModal}
          targetElement={target}
          persistEdit={handleEditField}
          builder={builder}
          editing={false}
          units={units}
        />
      )}

      {showAddModal && target && (
        <ModalAddField
          visible={showAddModal}
          setVisible={setShowAddModal}
          addFields={handleAddMultipleFields}
          parentName={target.name}
          units={units}
        />
      )}

      {showModalRedirectFormula && (
        <ModalRedirect
          visible={showModalRedirectFormula}
          setVisible={setShowModalRedirectFormula}
          buttonOptions={[
            {
              action: () => {
                setFormulaMode("create");
                setShowModalFormula(true);
                setShowModalRedirectFormula(false);
              },
              description: "Create formula based on this field",
              icon: faPlus
            },
            {
              action: () => {
                setFormulaMode("edit");
                setShowModalFormula(true);
                setShowModalRedirectFormula(false);
              },
              description: "Edit existing formula field",
              icon: faEdit
            }
          ]}
        />
      )}

      {showModalFormula && target && (
        <ModalFormula
          visible={showModalFormula}
          setVisible={state => {
            if (!state) setFormulaMode("create");
            setShowModalFormula(state);
          }}
          targetElement={target}
          save={handleFormula}
          builder={builder}
          units={units}
          mode={formulaMode}
        />
      )}

      {showModalElapsed && target && (
        <ModalElapsed
          visible={showModalElapsed}
          setVisible={state => {
            if (!state) setElapsedMode("create");
            setShowModalElapsed(state);
          }}
          mode={elapsedMode}
          targetElement={target}
          builder={builder}
          save={handleFormula}
        />
      )}

      {showConditionalModal && target && (
        <ModalConditionals
          visible={showConditionalModal && !!target}
          setVisible={state => {
            if (!state) setTarget(null);
            setShowConditionalModal(state);
          }}
          targetElement={target}
          save={handleConditional}
          builder={builder}
        />
      )}

      {showDeleteModal && target && (
        <ModalDeleteElement
          builder={builder}
          visible={showDeleteModal}
          setVisible={setShowDeleteModal}
          persistDelete={handleDeleteField}
          targetElement={target}
        />
      )}
    </TemplatePage>
  );
};

// Style Overrides
const Menu = styled(Inline)`
  justify-content: space-between;
  margin-bottom: ${pad}px;
`;

const TemplateTable = styled.div`
  max-height: 75vh;
  overflow: auto;
  margin-top: ${pad}px;
`;

const TemplatePage = styled(Page)`
  margin-bottom: ${pad * 2}px;
`;

const TemplateInline = styled.div`
  ${flex("row", "nowrap", "start", "start")};
  width: 100%;
  gap: ${pad}px;
  max-width: ${breakpoint.width[5]};
`;

const SectionHeader = styled(Heading)`
  margin-bottom: ${pad}px;
`;

const TemplateList = styled(Section)`
  width: 100%;
  padding: ${pad}px;
  border: ${border} solid ${props => props.theme.secondary};
  border-radius: ${radius};
`;

const TemplateListContent = styled.div`
  width: 35%;
  min-width: 160px;
`;

const Preview = styled(StickyWrapper)`
  width: 65%;
  padding: ${pad}px;
  border: ${border} solid ${props => props.theme.secondary};
  border-radius: ${radius};
  margin: 0;
`;

const ComponentTemplate = styled(ButtonFull)`
  width: 100%;
  text-transform: uppercase;
  background: transparent;
  margin-bottom: ${pad}px;
  color: ${props => props.theme.component};
  border: ${border} solid ${props => props.theme.component};
  transition: ${transition};
  font-weight: bold;
  ${voice.normal};

  svg {
    fill: ${props => props.theme.component};
  }

  &:hover {
    color: ${props => props.theme.tertiary};
    background: ${props => props.theme.component};

    svg {
      fill: ${props => props.theme.tertiary};
    }
  }

  ${props =>
    props.active &&
    css`
      color: ${props.theme.tertiary};
      background: ${props.theme.component};
    `}
`;

const Options = styled(Inline)`
  justify-content: space-between;
  padding: ${pad}px;
`;

const Option = styled(Button)`
  margin-left: ${pad}px;
  min-width: 80px;
`;

const Delete = styled(Option)`
  background: ${props => props.theme.error};
`;

const EditForm = styled.div`
  padding: ${pad}px;
`;

const Builder = styled.div`
  position: relative;
  padding: ${pad}px;
  width: 100%;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pad}px;
`;

export default ComponentTemplates;

import React, {useEffect, useContext, useMemo, useRef} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {get, useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble, faHistory, faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

// Contexts
import {SettingsContext} from "../../contexts/settings.js";

// Utils
import {addCommas, exists, prettyDateInUserTimezone} from "../../utils/helpers.js";
import {defaultUnacceptableParameterPrompt} from "../../utils/builder.js";
import {evaluateRange} from "../../pages/checksheet-builder/helpers.js";

// Components
import InputTextArea from "./InputTextArea.js";
import InputSelect from "./InputSelect.js";
import InputCheck from "./InputCheck.js";
import InputError from "./InputError.js";
import Help from "../Help.js";

// Style
import {pad, radius} from "../../style/components/variables.js";
import {voice} from "../../style/components/typography.js";
import {bp} from "../../style/components/breakpoints.js";
import {
  Abbr,
  Button,
  CloseButton,
  Error,
  FormFieldWrapper,
  Inline,
  Input,
  Label,
  Pill,
  Text
} from "../../style/components/general.js";

const InputNumber = ({
  name,
  label,
  testId,
  degree,
  prompt,
  placeholder,
  required,
  disabled,
  precision,
  units,
  globalUnacceptable,
  globalUnacceptableTitles,
  aMin,
  aMax,
  strictMin,
  strictMax,
  hasARange,
  min,
  max,
  rangeException,
  restrictedRangeException,
  hasSetPoint,
  setLow,
  setHigh,
  overrideWidth,
  innerRef,
  previousRead,
  qualifier,
  allowInfinite,
  disableInverse,
  hideError,
  condition,
  tag
}) => {
  const {settings} = useContext(SettingsContext);

  const {
    watch,
    register,
    setValue,
    formState: {errors, submitCount}
  } = useFormContext();

  const value = watch(name);
  const qualifierEnabled = watch(`${name}_qualifierEnabled`);
  const qualifierSelected = watch(`${name}_qualifier`);
  const infinityEnabled = watch(`${name}_infinite`);

  useEffect(() => {
    if (allowInfinite && infinityEnabled) setValue(name, "∞");
  }, [allowInfinite, infinityEnabled, name, setValue]);

  const hasYupErrors = useMemo(() => {
    const {message} = get(errors, name) || {};
    return !!message;
  }, [errors, name]);

  const refUnits = useRef();

  return (
    <>
      <FormFieldWrapper data-testid={testId}>
        {label && (
          <LabelWrapper htmlFor={name} bold inline>
            {globalUnacceptable}
            {label.toUpperCase()}
            {required && <span>*</span>}
            {tag && (
              <Pill quiet>
                <Abbr title={tag}>{tag}</Abbr>
              </Pill>
            )}
            {condition && <Help icon={<FontAwesomeIcon icon={faCheckDouble} />}>{condition}</Help>}
            {previousRead && exists(previousRead.value) && (
              <Help icon={<FontAwesomeIcon icon={faHistory} />}>
                {previousRead?.value !== "_NO_PREVIOUS" ? (
                  <>
                    Previous&nbsp;Read:&nbsp;
                    {addCommas(
                      previousRead.value,
                      degree === "Decimal" ? precision ?? 2 : undefined
                    )}
                    &nbsp;{units}
                    &nbsp;on&nbsp;
                    {prettyDateInUserTimezone(previousRead.date, settings.timezone, "MMM D YYYY")}
                  </>
                ) : (
                  "No previous record, data will be available on next submission."
                )}
              </Help>
            )}
          </LabelWrapper>
        )}

        {prompt && <Prompt quiet>{prompt}</Prompt>}

        <Inline>
          {qualifier && !qualifierEnabled && (
            <Option type="button" onClick={() => setValue(`${name}_qualifierEnabled`, true)}>
              Qualifier
            </Option>
          )}
          {qualifier && qualifierEnabled && (
            <>
              <CloseQualifier
                data-testid="close-button"
                type="button"
                onClick={() => {
                  setValue(`${name}_qualifier`, null);
                  setValue(`${name}_qualifierEnabled`, false);
                }}
              />
              <SelectWrapper>
                <InputSelect
                  name={`${name}_qualifier`}
                  placeholder="Select..."
                  options={["<", ">", "≤", "≥", "ND", "DNQ", "EST"]}
                />
              </SelectWrapper>
            </>
          )}
          <InputWrapper>
            {units && <Units ref={refUnits}>{units}</Units>}
            <NumberInput
              id={name}
              data-testid={`${testId}-input`}
              ref={innerRef}
              type={infinityEnabled ? "text" : "number"}
              placeholder={placeholder}
              disabled={disabled || infinityEnabled}
              min={min}
              max={max}
              step="any"
              size="10"
              overrideWidth={overrideWidth}
              unitPad={refUnits.current?.clientWidth}
              onWheel={e => e.target.blur()} // disable scroll increment
              pattern={degree === "Number" ? "[0-9]*" : ""} // show number keyboard on mobile
              inputMode={degree === "Decimal" ? "decimal" : "numeric"}
              {...register(name, {required: required})}
            />
          </InputWrapper>
          {hasSetPoint && (
            <Info>
              Set&nbsp;Point:&nbsp;{setLow}&nbsp;-&nbsp;{setHigh}
            </Info>
          )}
        </Inline>

        {!disableInverse && value !== null && value !== "" && value !== "∞" && value !== "0" && (
          <Inverse
            type="button"
            onClick={() =>
              setValue(name, value > 0 ? `-${Math.abs(value)}` : `${Math.abs(value)}`)
            }>
            Invert&nbsp;
            <FontAwesomeIcon icon={value > 0 ? faMinus : faPlus} />
          </Inverse>
        )}

        {allowInfinite && <InputCheck name={`${name}_infinite`}>Value is Infinite</InputCheck>}

        {!hideError && errors && (
          <>
            <InputError errors={errors} name={name} />
            {!hasYupErrors && globalUnacceptableTitles?.length && (
              <Error>
                Parameter violation{globalUnacceptableTitles.length > 1 ? "s" : ""}:{" "}
                {globalUnacceptableTitles.join(", ")}
              </Error>
            )}
          </>
        )}
      </FormFieldWrapper>

      {hasARange &&
        evaluateRange(
          value,
          aMin,
          aMax,
          strictMin,
          strictMax,
          qualifierSelected,
          infinityEnabled
        ) && (
          <InputTextArea
            name={`${name}_comment`}
            label={rangeException || defaultUnacceptableParameterPrompt}
            required
            maxLength={1000}
          />
        )}

      {restrictedRangeException &&
        exists(value) &&
        (exists(min) || exists(max)) &&
        submitCount === 0 &&
        evaluateRange(value, min, max, false, false, qualifierSelected, infinityEnabled) && (
          <Error>{restrictedRangeException}</Error>
        )}
    </>
  );
};

InputNumber.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  prompt: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  degree: PropTypes.string,
  precision: PropTypes.number,
  disabled: PropTypes.bool,
  units: PropTypes.string,
  globalUnacceptable: PropTypes.node,
  globalUnacceptableTitles: PropTypes.arrayOf(PropTypes.string),
  aMin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  aMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  strictMin: PropTypes.bool,
  strictMax: PropTypes.bool,
  hasARange: PropTypes.bool,
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rangeException: PropTypes.string,
  restrictedRangeException: PropTypes.string,
  hasSetPoint: PropTypes.bool,
  setLow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setHigh: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.instanceOf(Element)})
  ]),
  overrideWidth: PropTypes.string,
  previousRead: PropTypes.objectOf(PropTypes.any),
  qualifier: PropTypes.bool,
  allowInfinite: PropTypes.bool,
  disableInverse: PropTypes.bool,
  hideError: PropTypes.bool,
  condition: PropTypes.string,
  tag: PropTypes.string
};

InputNumber.defaultProps = {
  testId: "input-number",
  label: null,
  prompt: null,
  degree: "Decimal",
  precision: 2,
  placeholder: null,
  required: true,
  disabled: false,
  innerRef: null,
  units: "",
  globalUnacceptable: null,
  globalUnacceptableTitles: null,
  aMin: null,
  aMax: null,
  strictMin: false,
  strictMax: false,
  hasARange: false,
  min: null,
  max: null,
  rangeException: "",
  restrictedRangeException: "",
  hasSetPoint: false,
  setLow: null,
  setHigh: null,
  overrideWidth: null,
  previousRead: null,
  qualifier: false,
  allowInfinite: false,
  disableInverse: false,
  hideError: false,
  condition: undefined,
  tag: undefined
};

// Style Overrides
const Prompt = styled(Text)`
  margin-bottom: ${pad / 2}px;
`;

const Info = styled.span`
  color: ${({theme}) => theme.secondary};
  margin-left: ${pad}px;
  ${voice.quiet};

  ${bp(3)} {
    ${voice.normal};
  }
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Units = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: ${pad / 2}px;
  line-height: initial;
  color: ${({theme}) => theme.tertiary};
  background: ${({theme}) => theme.secondary};
  border-top-right-radius: ${radius};
  border-bottom-right-radius: ${radius};
`;

const SelectWrapper = styled.div`
  width: 100px;
  margin-right: ${pad}px;
  margin-top: -${pad}px;
`;

const CloseQualifier = styled(CloseButton)`
  margin-right: ${pad}px;
  margin-left: 0;
`;

const Option = styled(Button)`
  padding: ${pad}px;
  ${voice.quiet};
`;

const Inverse = styled(Option)`
  margin-top: ${pad / 2}px;

  ${bp(2)} {
    display: none;
  }
`;

const NumberInput = styled(Input)`
  width: ${({unitPad}) => (unitPad ? `${150 + unitPad / 2}px` : "150px")};
  max-width: 200px;

  ${({overrideWidth}) =>
    overrideWidth &&
    css`
      min-width: ${overrideWidth};
      width: ${overrideWidth};
    `}

  ${({unitPad}) =>
    unitPad &&
    css`
      padding-right: ${unitPad}px;
    `}
`;

const LabelWrapper = styled(Label)`
  gap: ${pad / 2}px;
  margin-bottom: 2px;
`;

export default InputNumber;

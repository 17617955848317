import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
// Components
import Modal from "../../components/Modal.js";

// Style
import {pad} from "../../style/components/variables.js";
import {HeadingCenter} from "../../style/components/general.js";
import RenderChecksheet from "./RenderChecksheet.js";

const ModalPreviewTemplate = ({visible, setVisible, template}) => {
  const [current, setCurrent] = useState({});

  useEffect(() => {
    if (visible && template) {
      setCurrent({
        ...template,
        fields: Object.fromEntries(
          template.fields.map(field => [field.name, {...field, parentName: "component"}])
        )
      });
    }
  }, [visible, template]);

  return (
    <Modal testId="facility.addComponent" visible={visible} setVisible={setVisible}>
      <ModalTitle>Template Preview</ModalTitle>
      <RenderChecksheet
        task={{
          builder: {
            byId: {
              ...current.fields,
              component: {
                ...current,
                element: "component",
                children: template.fields.map(field => field.name),
                name: "component",
                fields: null
              }
            },
            allIds: ["component"]
          }
        }}
        readOnly
        hideMeta
      />
    </Modal>
  );
};

ModalPreviewTemplate.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  template: PropTypes.objectOf(PropTypes.any)
};

ModalPreviewTemplate.defaultProps = {
  template: null
};

// Style Overrides
const ModalTitle = styled(HeadingCenter)`
  margin: ${pad}px 0;
  color: ${props => props.theme.component};
`;

export default ModalPreviewTemplate;

import React, {useEffect, useState, useContext, useRef, useMemo} from "react";
import PropTypes from "prop-types";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import styled from "styled-components";

// Contexts
import {AuthContext} from "../../contexts/auth.js";

// Hooks
import useApi from "../../hooks/useApi.js";

// Utils
import {toTitleCase, exists} from "../../utils/helpers.js";
import {FIELD, COMPONENT, GROUP, fieldValidationSchema} from "../../utils/builder.js";

// Components
import Modal from "../../components/Modal.js";
import FieldBuilder from "../../components/FieldBuilder.js";
import SearchSelect from "../../components/SearchSelect.js";
import ElementFieldHelp from "../general/ElementFieldHelp.js";
import ElementHelp from "../general/ElementHelp.js";
import Help from "../../components/Help.js";
import {
  InputText,
  InputCheck,
  InputTextArea,
  InputFileGroup,
  InputSelect
} from "../../components/form/FormInputs.js";

// Style
import {pad} from "../../style/components/variables.js";
import {
  HeadingCenter,
  Button,
  FormGroup,
  FormField,
  Form,
  ButtonFull,
  Label,
  Inline,
  Text
} from "../../style/components/general.js";

const ModalEditElement = ({
  visible,
  setVisible,
  hasBackButton,
  goBack,
  targetElement,
  persistEdit,
  builder,
  units,
  templateLabel,
  templates,
  editing
}) => {
  const {currentUser} = useContext(AuthContext);

  const [triggerAppend, setTriggerAppend] = useState(null);
  const [restrictedError, setRestrictedError] = useState(null);
  const [acceptableError, setAcceptableError] = useState(null);
  const [rangeError, setRangeError] = useState(null);
  const [conditionError, setConditionError] = useState(null);
  const [unlinkTemplate, setUnlinkTemplate] = useState(!targetElement.templateId);
  const [linkToTemplate, setLinkToTemplate] = useState(null);
  const [templateResults, setTemplateResults] = useState(templates);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const stored = useRef();

  const {api: uploadFile} = useApi("files");

  const hasChildFields = useMemo(() => {
    const {children} = targetElement;
    const fieldHelp = children?.filter(child => builder.byId[child]?.element === FIELD);
    return fieldHelp?.length > 0;
  }, [builder, targetElement]);

  const defaultElement = useMemo(() => {
    const {help: allHelp, children, element} = targetElement;

    let generalHelp = null;
    let fieldHelp = null;

    if (element === FIELD && targetElement.parentName && targetElement.parentName in builder.byId) {
      const parentElement = builder.byId[targetElement.parentName];
      const help =
        parentElement.help && parentElement.help.filter(h => h.id === targetElement.name);
      if (help && help.length > 0) generalHelp = help[0].value;
    } else {
      const fieldHelpItems = allHelp?.filter(({id}) => !!id);
      generalHelp = allHelp?.filter(({id}) => !id);

      fieldHelp = children
        ?.filter(child => builder.byId[child]?.element === FIELD)
        .map(child => {
          const found = fieldHelpItems?.filter(({id}) => id === child);
          if (found && found.length > 0) return {...found[0], enabled: true};
          return {key: builder.byId[child].label, value: null, id: child, enabled: false};
        });
    }

    const fallbackHelp = element === FIELD ? "" : [{key: null, value: null}];

    if (generalHelp && generalHelp.length === 0) generalHelp = fallbackHelp;

    return {
      ...targetElement,
      label: targetElement.label || "",
      hasGlobalPrompt: targetElement.hasGlobalPrompt || false,
      globalPrompt: targetElement.globalPrompt || null,
      base: targetElement.base || false,
      displayTable: targetElement.displayTable || false,
      hasHelp: targetElement.hasHelp || false,
      hasLimits: targetElement.hasLimits || false,
      limits: targetElement.limits || [{}],
      help: generalHelp || fallbackHelp,
      fieldHelp
    };
  }, [builder, targetElement]);

  delete targetElement.linkedGroup;
  delete targetElement.linkedComponent;
  delete defaultElement.linkedGroup;
  delete defaultElement.linkedComponent;

  const schema =
    targetElement.element === FIELD
      ? fieldValidationSchema(targetElement.parentName, builder)
      : yup.object().shape({
          label: yup
            .string()
            .required("Please provide an Element Name")
            .test({
              test: val => !val.match(/.*[.,'"].*/),
              message: "Element name may not contain periods, commas or quotation marks."
            }),
          hasGlobalPrompt: yup.boolean(),
          globalPrompt: yup.string().nullable(),
          base: yup.bool(),
          displayTable: yup.bool(),
          hasHelp: yup.bool(),
          help: yup
            .mixed()
            .nullable()
            .when("hasHelp", {
              is: val => !!val,
              then: () =>
                yup
                  .array()
                  .of(
                    yup.object().shape({
                      key: yup
                        .string()
                        .nullable()
                        .test({
                          test: (val, ctx) =>
                            !!val || ctx.from[1]?.value?.fieldHelp?.some(item => !!item.value),
                          message: "Please provide a key"
                        }),
                      value: yup
                        .string()
                        .nullable()
                        .test({
                          test: (val, ctx) =>
                            !!val || ctx.from[1]?.value?.fieldHelp?.some(item => !!item.value),
                          message: "Please provide a value."
                        })
                    })
                  )
                  .test({
                    test: (val, ctx) =>
                      (Array.isArray(val) && val.length > 0) ||
                      ctx.parent.fieldHelp?.some(item => !!item.value),
                    message: "Please provide help item."
                  })
            }),
          fieldHelp: yup
            .mixed()
            .nullable()
            .when("hasHelp", {
              is: val => !!val,
              then: () =>
                yup
                  .array()
                  .of(yup.object())
                  .test({
                    test: val =>
                      !hasChildFields ||
                      (Array.isArray(val) && val.every(({enabled, value}) => !enabled || !!value)),
                    message: "Please provide field help or disable unused help items."
                  })
            })
        });

  const form = useForm({
    defaultValues: defaultElement,
    resolver: yupResolver(schema)
  });
  const {watch, handleSubmit, reset, setValue} = form;

  const element = watch();
  const linkedGroup = watch("linkedGroup");
  const linkedComponent = watch("linkedComponent");

  const hasARange = watch("hasARange");
  const aMin = watch("aMin");
  const aMax = watch("aMax");
  const hasRange = watch("hasRange");
  const min = watch("min");
  const max = watch("max");
  const help = watch("help");

  useEffect(() => {
    setRestrictedError(null);
  }, [hasRange, min, max]);

  useEffect(() => {
    setAcceptableError(null);
  }, [hasARange, aMin, aMax]);

  useEffect(() => {
    const grandparent =
      linkedComponent && linkedComponent in builder.byId
        ? builder.byId[linkedComponent].parentName
        : null;
    if (
      linkedGroup &&
      linkedGroup !== "" &&
      linkedGroup !== targetElement.parentName &&
      (!grandparent || linkedGroup !== grandparent)
    )
      setValue("linkedComponent", null);
  }, [linkedGroup, setValue, targetElement, builder, linkedComponent]);

  const addFile = file => {
    const formData = new FormData();
    formData.append("file", file);
    uploadFile
      .callPost(formData, {
        params: {
          userId: currentUser.publicId
        }
      })
      .then(({status, data: body}) => {
        if (status === 201) {
          let wasSet = false;
          if (help && help.length > 0) {
            for (let i = 0; i < help.length; i++) {
              const {key, value} = help[i] || {};
              if (!key && !value) {
                setValue(`help.${i}`, {
                  key: body.data.label.split(".")[0],
                  value: body.data.label,
                  fileId: body.data.id,
                  export: body.data.export || false
                });
                wasSet = true;
                break;
              }
            }
          }

          if (!wasSet) {
            setTriggerAppend(prev => ({
              ...prev,
              key: body.data.label.split(".")[0],
              value: body.data.label,
              fileId: body.data.id,
              export: body.data.export || false
            }));
          }
        }
      });
  };

  const renderGroupOptions = ({hasGlobalPrompt}) => (
    <>
      <FormField>
        <InputCheck name="hasGlobalPrompt">
          <Text inline>
            Provide global prompt
            <Help>
              Provide a general description that will be shown at the top of {targetElement.label}
            </Help>
          </Text>
        </InputCheck>
      </FormField>
      {hasGlobalPrompt && (
        <FormField>
          <InputTextArea name="globalPrompt" placeholder="Prompt for all group fields." />
        </FormField>
      )}
    </>
  );

  const componentInSetGroup = (parentName, currentGroup) => {
    if (!currentGroup) currentGroup = linkedGroup;
    const targetParent = builder.byId[targetElement.parentName];
    if (targetParent && targetParent.element === COMPONENT) {
      if (!currentGroup || currentGroup === "") return true;
      return currentGroup === parentName;
    }
    if (targetParent && targetParent.element === GROUP) {
      if (!currentGroup || currentGroup === "") return targetElement.parentName === parentName;
      return currentGroup === parentName;
    }
    if (!currentGroup || currentGroup === "") return true;
    return currentGroup === parentName;
  };

  const getLinkedGroup = (grandparent, linkedGroups) => {
    const groups = linkedGroups.map(item => item.name);
    if (groups.includes(targetElement.parentName)) return targetElement.parentName;
    if (grandparent && groups.includes(grandparent)) return grandparent;
    return null;
  };

  const renderFieldOptions = () => {
    const linkedGroups = Object.values(builder.byId).filter(
      ({element: el, toggle}) => el === GROUP && toggle
    );
    const grandparent =
      targetElement.parentName in builder.byId
        ? builder.byId[targetElement.parentName].parentName
        : null;

    const currentGroup = getLinkedGroup(grandparent, linkedGroups);
    const linkedComponents = Object.values(builder.byId).filter(
      ({element: el, parentName, toggle}) =>
        el === COMPONENT && componentInSetGroup(parentName, linkedGroup || currentGroup) && toggle
    );
    return (
      <>
        {linkedGroups.length > 0 && (
          <FormField>
            <InputSelect
              name="linkedGroup"
              options={linkedGroups}
              label="Linked Group"
              placeholder="Link to Group..."
              defaultValue={currentGroup}
              testId="edit.linkedGroup"
            />
          </FormField>
        )}
        {linkedComponents.length > 0 && (
          <FormField>
            <InputSelect
              name="linkedComponent"
              options={linkedComponents}
              placeholder="Link to Component..."
              label="Linked Component"
              defaultValue={
                linkedComponents.map(item => item.name).includes(targetElement.parentName)
                  ? targetElement.parentName
                  : null
              }
              testId="edit.linkedComponent"
            />
          </FormField>
        )}
      </>
    );
  };

  const renderComponentOptions = () => {
    const linkedGroups = Object.values(builder.byId).filter(
      ({element: el, toggle}) => el === GROUP && toggle
    );

    return (
      <>
        {linkedGroups.length > 0 && (
          <FormField>
            <InputSelect
              name="linkedGroup"
              options={linkedGroups}
              placeholder="Link to Group..."
              label="Linked Group"
              defaultValue={
                linkedGroups.map(item => item.name).includes(targetElement.parentName)
                  ? targetElement.parentName
                  : null
              }
              testId="edit.linkedGroup"
            />
          </FormField>
        )}
        {(templateLabel || linkToTemplate) && !unlinkTemplate && (
          <FormField>
            <Inline>
              <Label bold>Linked Template: </Label>
              <Component>{linkToTemplate ? linkToTemplate.label : templateLabel}</Component>
              <StyledButton
                type="button"
                onClick={() => {
                  setLinkToTemplate(null);
                  setUnlinkTemplate(true);
                }}>
                Unlink
              </StyledButton>
            </Inline>
          </FormField>
        )}
        {unlinkTemplate && templates && (
          <FormField>
            <Inline>
              <SearchSelect
                name="linkedTemplate"
                results={templateResults}
                setResults={setTemplateResults}
                search={query => {
                  if (!query) setTemplateResults(templates);
                  setTemplateResults(
                    templates.filter(template =>
                      template.label.toLowerCase().includes(query.toLowerCase())
                    )
                  );
                }}
                label="Linked Template"
                add={selection => {
                  setUnlinkTemplate(false);
                  setLinkToTemplate(selection);
                }}
                placeholder="Find Template..."
                testId="edit.linkedTemplate"
              />
            </Inline>
          </FormField>
        )}
      </>
    );
  };

  useEffect(() => {
    if (!visible) reset();
  }, [reset, targetElement, visible]);

  const handleEdit = values => {
    let shouldReturn = false;
    if (values.hasARange && !exists(values.aMin) && !exists(values.aMax)) {
      setAcceptableError("Either a min or max must be provided.");
      shouldReturn = true;
    } else setAcceptableError(null);

    if (values.hasRange && !exists(values.min) && !exists(values.max)) {
      setRestrictedError("Either a min or max must be provided.");
      shouldReturn = true;
    } else setRestrictedError(null);

    if (values.type === "range" && !(exists(values.rangeMin) && exists(values.rangeMax))) {
      setRangeError("Min and max must be provided.");
      shouldReturn = true;
    } else setRangeError(null);

    if (values.hasAlert && !exists(values.alertCondition)) {
      setConditionError("Condition must be provided.");
      shouldReturn = true;
    }

    if (shouldReturn) return;

    if (values.units && values.units.includes("Other: "))
      values.units = values.units.replace("Other: ", "");

    let elementParent;
    const {linkedComponent: newParentComponent, linkedGroup: newParentGroup} = values;
    if (newParentComponent && newParentComponent !== "") elementParent = newParentComponent;
    else if (newParentGroup && newParentGroup !== "") elementParent = newParentGroup;
    else elementParent = targetElement.parentName;

    let newHelp = values.help;
    if (targetElement.element !== FIELD && values.hasHelp) {
      const {fieldHelp, help: elementHelp} = values;
      if (elementHelp) newHelp = [...elementHelp.filter(({key, value}) => !!key && !!value)];
      if (fieldHelp)
        newHelp = [
          ...newHelp,
          ...fieldHelp
            .filter(({key, value}) => !!key && !!value)
            .map(item => {
              delete item.enabled;
              return item;
            })
        ];
    }

    const updated = {
      ...targetElement,
      ...values,
      parentName: elementParent,
      help: newHelp
    };

    if (updated.linkedComponent !== undefined) delete updated.linkedComponent;
    if (updated.linkedGroup !== undefined) delete updated.linkedGroup;
    if (updated.phone && updated.phone.number === "") delete updated.phone;
    if (unlinkTemplate && updated.templateId !== undefined) delete updated.templateId;
    delete updated.fieldHelp;

    if (editing && values.type !== targetElement.type) {
      stored.current = {updated, unlinkTemplate, linkToTemplate};
      setShowConfirmModal(true);
    } else persistEdit(updated, unlinkTemplate, linkToTemplate);
  };

  if (showConfirmModal)
    return (
      <Modal visible={showConfirmModal} setVisible={setShowConfirmModal} allowClose={false}>
        <Warning>Warning!</Warning>
        <Text>
          Changing a field&apos;s type will detach previous responses and this field will be treated
          as a new field. Are you sure you want to proceed?
        </Text>
        <br />
        <Inline>
          <Button
            type="button"
            onClick={() => {
              const {updated, unlinkTemplate: unlink, linkToTemplate: link} = stored.current;
              persistEdit(updated, unlink, link);
            }}>
            Confirm
          </Button>
          <Button type="button" onClick={() => setShowConfirmModal(false)}>
            Cancel
          </Button>
        </Inline>
      </Modal>
    );

  return (
    <Modal visible={visible} setVisible={setVisible} hasBackButton={hasBackButton} goBack={goBack}>
      <HeadingCenter>Edit {toTitleCase(targetElement.element)}</HeadingCenter>

      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(handleEdit)} noValidate>
          <FormGroup>
            {targetElement.element === COMPONENT && renderComponentOptions()}
            {targetElement.element === FIELD ? (
              <>
                {renderFieldOptions()}
                <FieldBuilder
                  field={element}
                  form={form}
                  editElement
                  restrictedError={restrictedError}
                  acceptableError={acceptableError}
                  rangeError={rangeError}
                  conditionError={conditionError}
                  units={units}
                />
              </>
            ) : (
              <FormField>
                <InputText
                  testId="edit.label"
                  name="label"
                  label="NAME"
                  placeholder={targetElement.label}
                  required
                />
              </FormField>
            )}
          </FormGroup>
          <FormGroup>
            {targetElement.element === GROUP && renderGroupOptions(element)}

            {targetElement.element === COMPONENT && (
              <FormField>
                <InputCheck name="grid" testId="edit.grid">
                  <Text inline>
                    Display {targetElement.label} as grid in form&nbsp;
                    <Help>Show fields within this component horizontally.</Help>
                  </Text>
                </InputCheck>
              </FormField>
            )}
            {targetElement.element !== FIELD && (
              <FormField>
                <InputCheck name="hasHelp" testId="edit.hasHelp">
                  <Text inline>
                    Provide additional help&nbsp;
                    <Help>
                      These descriptions will be available when filling out {targetElement.label}.
                    </Help>
                  </Text>
                </InputCheck>
              </FormField>
            )}
            {element.hasHelp && (
              <>
                <InputFileGroup name="files" callback={addFile} />

                <ElementHelp
                  name="help"
                  label={`${targetElement.element.toUpperCase()} HELP`}
                  triggerAppend={triggerAppend}
                  testId="edit.help"
                />

                {hasChildFields && <ElementFieldHelp name="fieldHelp" label="FIELD HELP" />}
              </>
            )}
          </FormGroup>
          <ButtonFull type="submit" data-testid="edit.save">
            Save {toTitleCase(targetElement.element)}
          </ButtonFull>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalEditElement.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func,
  targetElement: PropTypes.objectOf(PropTypes.any).isRequired,
  persistEdit: PropTypes.func.isRequired,
  builder: PropTypes.objectOf(PropTypes.any).isRequired,
  units: PropTypes.arrayOf(PropTypes.string),
  templateLabel: PropTypes.string,
  templates: PropTypes.arrayOf(PropTypes.any),
  editing: PropTypes.bool
};

ModalEditElement.defaultProps = {
  hasBackButton: false,
  goBack: () => {},
  units: [],
  templateLabel: null,
  templates: null,
  editing: false
};

// Style Overrides
const Component = styled.span`
  margin-top: -${pad / 2}px;
  color: ${({theme}) => theme.component};
`;

const StyledButton = styled(Button)`
  margin-top: -${pad / 2}px;
`;

const Warning = styled(HeadingCenter)`
  color: ${({theme}) => theme.error};
`;

export default ModalEditElement;

import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";

// Utils
import useApi from "../../hooks/useApi.js";

// Components
import ModalDelete from "../../components/ModalDelete.js";

const ModalFacilityUserDelete = ({
  visible,
  setVisible,
  selected,
  updateTable,
  setRefreshFacility,
  hasBackButton,
  goBack
}) => {
  const {api, loading: loadingDelete} = useApi("users");
  const [message, setMessage] = useState(null);

  useEffect(() => {
    let names = "";
    selected.map((user, index) => {
      const temp = user.firstName.props.children;
      const name = `${temp[0]} ${temp[2]}`;
      names += name;
      if (selected.length === 1) names += "'s";
      if (index !== selected.length - 1) names += ", ";
      if (index === selected.length - 2) names += " and ";
    });

    setMessage(
      `Are your sure you want to delete the following account? ${names} will no longer have access to Hero Builder™.`
    );
  }, [selected]);

  const handleSubmit = () => {
    const ids = selected.map(current => current.publicId);
    api.callDelete(null, {data: {ids: ids}}).then(async response => {
      if (response) {
        updateTable();
        setRefreshFacility(true);
        if (goBack) goBack();
        else setVisible(false);
      }
    });
  };

  return (
    message && (
      <ModalDelete
        visible={visible}
        setVisible={setVisible}
        title="Delete User"
        prompt={message}
        confirmDelete={handleSubmit}
        loading={loadingDelete}
        hasBackButton={hasBackButton}
        goBack={goBack}
      />
    )
  );
};

ModalFacilityUserDelete.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  updateTable: PropTypes.func.isRequired,
  setRefreshFacility: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.any).isRequired,
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func
};

ModalFacilityUserDelete.defaultProps = {
  hasBackButton: false,
  goBack: null
};

export default ModalFacilityUserDelete;

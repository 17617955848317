import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";

// Components
import InputError from "./InputError.js";
import Help from "../Help.js";

// Style
import {Abbr, FormFieldWrapper, Input, Label, Pill, Text} from "../../style/components/general.js";
import {pad} from "../../style/components/variables.js";

const InputTime = ({...props}) => {
  const {
    testId,
    label,
    name,
    prompt,
    placeholder,
    disabled,
    capitalize,
    defaultValue,
    hidden,
    condition,
    tag,
    required
  } = props;

  const {
    setValue,
    watch,
    register,
    formState: {errors}
  } = useFormContext();

  const [focused, setFocused] = useState(false);
  const inputRef = useRef();
  const value = watch(name);

  useEffect(() => {
    if (value && value.length > 0) setFocused(true);
    else setFocused(false);
  }, [value]);

  useEffect(() => {
    if (value) {
      const transformed = capitalize ? value.toUpperCase() : value;
      setValue(name, transformed);
    }
  }, [capitalize, name, setValue, value, inputRef]);

  return (
    <FormFieldWrapper data-testid={testId}>
      {label && (
        <LabelWrapper htmlFor={name} bold inline>
          {label.toUpperCase()}
          {required && <span>*</span>}
          {tag && (
            <Pill quiet>
              <Abbr title={tag}>{tag}</Abbr>
            </Pill>
          )}
          {condition && <Help icon={<FontAwesomeIcon icon={faCheckDouble} />}>{condition}</Help>}
        </LabelWrapper>
      )}
      {prompt && <Prompt quiet>{prompt}</Prompt>}
      <TimeInput
        id={name}
        data-testid={`${testId}-input`}
        type="time"
        name={name}
        placeholder={!focused && prompt ? prompt : placeholder}
        disabled={disabled}
        defaultValue={defaultValue}
        hidden={hidden}
        ref={inputRef}
        {...register(name, {required: required})}
      />
      <InputError errors={errors} name={name} />
    </FormFieldWrapper>
  );
};

InputTime.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  prompt: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  capitalize: PropTypes.bool,
  defaultValue: PropTypes.string,
  hidden: PropTypes.bool,
  condition: PropTypes.string,
  tag: PropTypes.string,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.instanceOf(Element)})
  ])
};

InputTime.defaultProps = {
  testId: "input-time",
  label: null,
  prompt: null,
  placeholder: null,
  required: false,
  disabled: false,
  capitalize: false,
  defaultValue: "",
  hidden: false,
  condition: undefined,
  tag: undefined,
  innerRef: null
};

// Style Overrides
const LabelWrapper = styled(Label)`
  gap: ${pad / 2}px;
`;

const Prompt = styled(Text)`
  margin-bottom: ${pad}px;
`;

const TimeInput = styled(Input)`
  max-width: 120px;
`;

export default InputTime;

import React, {useEffect, useRef, useState} from "react";
import {SketchPicker} from "react-color";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import styled, {css} from "styled-components";

// Components
import InputError from "./InputError.js";
import Help from "../Help.js";

// Style
import {voice} from "../../style/components/typography.js";
import {flex, z} from "../../style/components/mixins.js";
import {border, colors, pad, radius, shadow} from "../../style/components/variables.js";
import {FormFieldWrapper, Label, Text} from "../../style/components/general.js";

const InputColor = ({label, name, required, triggerHide, hideAllExcept, help, hidden}) => {
  const [showHelp, setShowHelp] = useState(false);
  const [visible, setVisible] = useState(false);

  const {
    formState: {errors},
    setValue,
    watch
  } = useFormContext();
  const watchColor = watch(name);

  const handleChangeComplete = color => setValue(name, color?.hex?.slice(1));

  // for Enterprise settings, we want to hide all other color pickers when a new one is selected,
  // done via triggerHide and hideAllExcept
  useEffect(() => {
    if (triggerHide) setVisible(false);
  }, [triggerHide]);

  const colorButton = useRef(null);
  const colorPicker = useRef(null);

  const helpIcon = useRef(null);
  const helpDetail = useRef(null);

  // Outside click on Help Icon
  useEffect(() => {
    const handleOutsideClick = ({target}) =>
      showHelp &&
      helpIcon &&
      !helpIcon.current.contains(target) &&
      helpDetail &&
      helpDetail.current !== null &&
      !helpDetail.current.contains(target) &&
      !helpDetail.current.contains(target.nextSibling) &&
      setShowHelp(false);

    if (helpDetail && help !== null) document.addEventListener("mousedown", handleOutsideClick);

    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [help, showHelp]);

  // Outside click on Color Picker
  useEffect(() => {
    const handleOutsideClick = ({target}) =>
      colorButton &&
      !colorButton.current.contains(target) &&
      colorPicker &&
      colorPicker.current !== null &&
      !colorPicker.current.contains(target) &&
      !colorPicker.current.contains(target.nextSibling) &&
      setVisible(false);

    document.addEventListener("mousedown", handleOutsideClick);

    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [help]);

  return (
    <FormFieldWrapper hidden={hidden}>
      {label && (
        <Label htmlFor={name} bold>
          <Text inline>
            {label.toUpperCase()}
            {required && <span>&nbsp;*</span>}&nbsp;
            {help !== null && (
              <Help>Events of this type will appear on the calendar in the selected color.</Help>
            )}
          </Text>
        </Label>
      )}
      {help !== null && (
        <Detail ref={helpDetail} visible={showHelp}>
          {help}
        </Detail>
      )}

      <ColorSetting>
        <Preview
          ref={colorButton}
          color={watchColor}
          onClick={() => {
            setVisible(prev => !prev);
            if (hideAllExcept) hideAllExcept(name);
          }}
        />
        <ColorPickerWrapper ref={colorPicker} visible={visible}>
          <SketchPicker
            onChangeComplete={handleChangeComplete}
            color={watchColor}
            presetColors={[]}
            disableAlpha
          />
        </ColorPickerWrapper>
      </ColorSetting>

      <InputError errors={errors} name={name} />
    </FormFieldWrapper>
  );
};

InputColor.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  triggerHide: PropTypes.bool,
  hideAllExcept: PropTypes.func,
  help: PropTypes.string,
  hidden: PropTypes.bool
};

InputColor.defaultProps = {
  label: null,
  required: false,
  triggerHide: null,
  hideAllExcept: null,
  help: null,
  hidden: false
};

// Style Overrides
const ColorSetting = styled.div`
  position: relative;
  width: min-content;
  cursor: pointer;
  margin-top: ${pad}px;
`;

const Preview = styled.div`
  width: 50px;
  height: 25px;
  margin-top: -${pad / 2}px;
  background: ${props => `#${props.color}`};
  border-radius: ${radius};
  border: ${border} solid ${props => props.theme.secondary};
`;

const ColorPickerWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: ${pad}px;
  visibility: hidden;
  box-shadow: ${shadow};
  z-index: ${z("peak")};

  opacity: 0;

  ${props =>
    props.visible
      ? css`
          visibility: visible;
          opacity: 1;
        `
      : ""}
`;

const Detail = styled.span`
  ${flex("column")};
  ${voice.small};
  position: absolute;
  top: 0;
  left: 80%;
  color: ${colors.heroBlack};
  background: ${colors.heroWhite};
  width: 360px;
  border-radius: 5px;
  padding: ${pad / 4}px ${pad}px;
  transition: all ease 0.2s;
  box-shadow: 0 5px 15px rgb(0 0 0 / 30%);
  z-index: ${z("above")};

  opacity: 0;
  visibility: hidden;

  ${props =>
    props.visible &&
    css`
      opacity: 1;
      visibility: visible;
    `}

  a {
    display: block;
    font-weight: bold;
    align-self: center;
  }
`;

export default InputColor;

// Utils
import {EMPTY_TABLE_HEIGHT} from "../../utils/builder.js";
import {generateUniqueKey} from "../../utils/helpers.js";

// Classes
import Column from "./Column.js";

const LEFT_COLUMN = 0;
const RIGHT_COLUMN = 1;

export default class Group {
  columns;
  id;

  constructor({group, region} = {}) {
    if (group) {
      this.columns = group.columns.map(column => new Column({column}));
      this.id = group.id;
    } else {
      if ((region?.headers && Object.keys(region.headers).length > 4) || region?.fullWidth) {
        this.columns = [new Column({region})];
      } else if (region) this.columns = [new Column({region}), new Column()];
      else this.columns = [new Column(), new Column()];
      this.id = generateUniqueKey("group");
    }
  }

  getHeight() {
    if (this.columns.length === 1) return this.columns[0].getHeight();
    const leftColumnMaxHeight = this.columns[LEFT_COLUMN].getHeight();
    const rightColumnMaxHeight = this.columns[RIGHT_COLUMN].getHeight();
    return Math.max(leftColumnMaxHeight, rightColumnMaxHeight);
  }

  getMaxHeight() {
    if (this.columns.length === 1) return this.columns[0].getMaxHeight();
    const leftColumnMaxHeight = this.columns[LEFT_COLUMN].getMaxHeight();
    const rightColumnMaxHeight = this.columns[RIGHT_COLUMN].getMaxHeight();
    return Math.max(leftColumnMaxHeight, rightColumnMaxHeight);
  }

  setColumn(idx, column) {
    if (this.columns && this.columns.length > idx) this.columns[idx] = column;
  }

  setColumns(columns) {
    this.columns = columns;
  }

  updateGroup(columnIdx, rowIdx, update) {
    const column = this.columns[columnIdx];

    if (column) {
      column.updateColumn(update, rowIdx);
    }
  }

  hasEmptyFit(height) {
    if (!height) height = EMPTY_TABLE_HEIGHT;
    if (this.columns.length === 1) {
      return this.columns[0].getHeight() === 0 ? this.columns[0] : false;
    }
    const leftColumnHeight = this.columns[LEFT_COLUMN].getHeight();
    const rightColumnHeight = this.columns[RIGHT_COLUMN].getHeight();

    if (!leftColumnHeight && !rightColumnHeight) return this.columns[LEFT_COLUMN];

    if (Math.abs(leftColumnHeight - rightColumnHeight) < height) return false;

    return leftColumnHeight > rightColumnHeight
      ? this.columns[RIGHT_COLUMN]
      : this.columns[LEFT_COLUMN];
  }

  getEmptyColumn() {
    if (this.columns.length === 1 && this.columns[0].rows.length === 0) return [this.columns[0], 0];
    if (this.columns[LEFT_COLUMN].rows.length === 0)
      return [this.columns[LEFT_COLUMN], LEFT_COLUMN];
    if (this.columns[RIGHT_COLUMN].rows.length === 0)
      return [this.columns[RIGHT_COLUMN], RIGHT_COLUMN];
    return [];
  }
}

import React, {useContext, useCallback, useRef, useState} from "react";
import {useReactToPrint} from "react-to-print";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint, faTrash} from "@fortawesome/free-solid-svg-icons";

// Utils
import {SettingsContext} from "../../contexts/settings.js";
import {prettyDateInUserTimezone} from "../../utils/helpers.js";
import {DEFAULT_FONT_SIZE} from "../../utils/report.js";
import {useToast} from "../../contexts/toast.js";

// Components
import Modal from "../../components/Modal.js";
import Report from "../report-builder/Report.js";
import RenderReportBuilder from "../report-builder/RenderReportBuilder.js";
import RenderReportBuilderPrintable from "../report-builder/RenderReportBuilderPrintable.js";

// Style
import {flex} from "../../style/components/mixins.js";
import {border, pad, radius} from "../../style/components/variables.js";
import {
  ModalButton,
  Inline,
  Heading,
  HeadingMedium,
  Small,
  Text,
  RelativeWrapper,
  AnchorInline
} from "../../style/components/general.js";
import useApi from "../../hooks/useApi.js";
import ModalDelete from "../../components/ModalDelete.js";

const REPORT_WIDTH = 900;

const ModalViewGenerated = ({visible, setVisible, report, record, createdAt, updateData}) => {
  const {settings} = useContext(SettingsContext);
  const {addToast} = useToast();

  const {name, base, interval} = report;
  const {header} = record;

  const generatedReport = new Report(record.generated);

  const reportRef = useRef();
  const containerRef = useRef();

  const {api} = useApi("report-records");

  const [canPrint, setCanPrint] = useState(true);
  const [showDeleted, setShowDeleted] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => {
      const printBody = document.createElement("div");
      printBody.style.pageBreakInside = "avoid";
      const reportClone = reportRef.current.cloneNode(true);
      printBody.appendChild(reportClone);
      return printBody;
    }
  });

  const handleResize = useCallback(() => {
    if (containerRef.current)
      document.documentElement.style.setProperty(
        "--vw-scale",
        Math.max(Math.min(containerRef.current.clientWidth / REPORT_WIDTH, 1), 0.5)
      );
  }, []);

  const containerCallback = useCallback(
    node => {
      if (node !== null) {
        window.addEventListener("resize", handleResize);

        containerRef.current = node;
        document.documentElement.style.setProperty(
          "--vw-scale",
          Math.max(Math.min(node.clientWidth / REPORT_WIDTH, 1), 0.5)
        );
      }
    },
    [handleResize]
  );

  const wrapperCallback = useCallback(node => {
    if (node !== null) {
      document.documentElement.style.setProperty("--target-height", `${node.clientHeight}px`);
    }
  }, []);

  const handleDelete = () => {
    const ids = [record.id];
    api.callDelete(null, {data: {ids}}).then(response => {
      if (response.status === 200) {
        if (updateData) updateData();
        setVisible(false);
      }
    });
  };

  const printError = () => {
    addToast(
      <>
        We are sorry, something went wrong with your report. Please submit a{" "}
        <AnchorInline href="/support" target="_blank" rel="noreferrer">
          Support Ticket
        </AnchorInline>
        .
      </>,
      "error"
    );
  };

  const controls = (
    <Controls>
      <PrintButton onClick={() => setShowDeleted(true)} title="Delete">
        <FontAwesomeIcon icon={faTrash} />
      </PrintButton>
      <PrintButton
        onClick={canPrint ? handlePrint : printError}
        title="Print"
        clickableDisabled={!canPrint}>
        <FontAwesomeIcon icon={faPrint} />
      </PrintButton>
    </Controls>
  );

  if (showDeleted)
    return (
      <ModalDelete
        visible={showDeleted && visible}
        setVisible={setVisible}
        hasBackButton
        goBack={() => setShowDeleted(false)}
        confirmDelete={handleDelete}
      />
    );

  return (
    <Modal visible={visible} setVisible={setVisible} renderModalControls={controls}>
      <ModalContent>
        <RelativeWrapper ref={wrapperCallback}>
          <Menu ref={containerCallback}>
            <Heading>{name}</Heading>
            <Small>
              Generated{" "}
              {createdAt
                ? `${prettyDateInUserTimezone(createdAt, settings.timezone, "MMM DD, YYYY")} `
                : ""}
              with data from last{" "}
              {interval === "1" ? base.slice(0, base.length - 1) : `${interval} ${base}`}
            </Small>
          </Menu>

          <ReportPreview>
            {header && (
              <HeaderWrapper>
                <Col align={report.settings?.alignment || "left"}>
                  <Text>{header.topLeft}</Text>
                  <Small>{header.bottomLeft}</Small>
                </Col>
                <Col align="center">
                  <HeadingMedium>{header.name}</HeadingMedium>
                  {header.address && (
                    <Text>
                      <span>{header.line1}</span>
                      <br />
                      {header.line2 !== "" && (
                        <>
                          <span>{header.line2}</span>
                          <br />
                        </>
                      )}
                      <span>
                        {header.city} {header.state}
                        {header.zipCode && `, ${header.zipCode}`}
                      </span>
                    </Text>
                  )}
                </Col>
                <Col
                  align={
                    !report.settings?.alignment || report.settings?.alignment === "left"
                      ? "end"
                      : report.settings?.alignment
                  }>
                  <Text>{header.topRight}</Text>
                  <Small>{header.bottomRight}</Small>
                </Col>
              </HeaderWrapper>
            )}
            <RenderReportBuilder
              report={generatedReport}
              showPlaceholders={false}
              fontSize={report.settings?.fontSize || DEFAULT_FONT_SIZE}
              alignment={report.settings?.alignment || "left"}
            />
          </ReportPreview>
        </RelativeWrapper>

        {/* Printable Report */}
        <ReportPreview ref={reportRef} hide>
          {header && (
            <HeaderWrapper>
              <Col align={report.settings?.alignment || "left"}>
                <Text>{header.topLeft}</Text>
                <Small>{header.bottomLeft}</Small>
              </Col>
              <Col align="center">
                <HeadingMedium>{header.name}</HeadingMedium>
                {header.address && (
                  <Text>
                    <span>{header.line1}</span>
                    <br />
                    {header.line2 !== "" && (
                      <>
                        <span>{header.line2}</span>
                        <br />
                      </>
                    )}
                    <span>
                      {header.city} {header.state}
                      {header.zipCode && `, ${header.zipCode}`}
                    </span>
                  </Text>
                )}
              </Col>
              <Col
                align={
                  !report.settings?.alignment || report.settings?.alignment === "left"
                    ? "end"
                    : report.settings?.alignment
                }>
                <Text>{header.topRight}</Text>
                <Small>{header.bottomRight}</Small>
              </Col>
            </HeaderWrapper>
          )}
          <RenderReportBuilderPrintable
            report={generatedReport}
            update={0}
            fontSize={report.settings?.fontSize || DEFAULT_FONT_SIZE}
            alignment={report.settings?.alignment || "left"}
            setCanPrint={setCanPrint}
          />
        </ReportPreview>
      </ModalContent>
    </Modal>
  );
};

ModalViewGenerated.propTypes = {
  visible: PropTypes.bool,
  setVisible: PropTypes.func.isRequired,
  report: PropTypes.objectOf(PropTypes.any).isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  createdAt: PropTypes.string,
  updateData: PropTypes.func
};

ModalViewGenerated.defaultProps = {
  visible: false,
  createdAt: null,
  updateData: null
};

// Style Overrides
const Controls = styled.div`
  display: flex;
  margin-right: ${pad}px;
`;

const ModalContent = styled.div`
  height: calc(var(--target-height) * var(--vw-scale));

  margin-bottom: ${pad}px;
`;

const Menu = styled(Inline)`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${pad}px;

  @media print {
    margin-bottom: ${pad}px;
    page-break-after: avoid;
    height: 16mm;
  }
`;

const ReportPreview = styled.div`
  border: ${border} solid ${props => props.theme.secondary};
  border-radius: ${radius};
  padding: ${pad}px;
  width: ${REPORT_WIDTH}px;
  min-width: 445px;
  height: min-content;

  /* Print Scale A4 */
  transform: scale(var(--vw-scale));
  transform-origin: top left;

  @media print {
    margin-bottom: 9mm;
    display: block;
    border: 0;
    padding: 0;
  }

  ${props =>
    props.hide
      ? css`
          display: none;
        `
      : ""}
`;

const HeaderWrapper = styled.div`
  ${flex("row", "nowrap")};
  gap: ${pad * 5}px;
  margin: ${pad}px 0 ${pad * 2}px;
  padding: ${pad}px 0;
  border-top: ${border} solid ${props => props.theme.secondary};
  border-bottom: ${border} solid ${props => props.theme.secondary};

  @media print {
    page-break-after: avoid;
  }
`;

const Col = styled.div`
  ${flex("column", "wrap", "space-between", "start")};
  width: 33.33%;

  ${HeadingMedium}, ${Text}, ${Small} {
    color: ${props => props.theme.secondary};
    width: 100%;
    text-align: ${props => props.align || "start"};
  }
`;

const PrintButton = styled(ModalButton)`
  ${props =>
    props.clickableDisabled &&
    css`
      cursor: not-allowed;
    `}
`;

export default ModalViewGenerated;

import React, {useContext, useEffect, useState} from "react";
import {useFieldArray, useFormContext} from "react-hook-form";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faMapMarker, faPaperclip} from "@fortawesome/free-solid-svg-icons";

// Utils
import useApi from "../../hooks/useApi.js";
import {AuthContext} from "../../contexts/auth.js";
import {useToast} from "../../contexts/toast.js";
import {isMobileDevice} from "../../utils/responsive.js";
import {exists, getLocation, getMapFromCoords} from "../../utils/helpers.js";

// Components
import {ModalControls} from "../../components/Modal.js";
import {InputTextArea} from "../../components/form/FormInputs.js";

// Style
import {voice} from "../../style/components/typography.js";
import {heroTheme, pad, radius} from "../../style/components/variables.js";
import {
  ButtonFull,
  ButtonLoader,
  Inline,
  CalloutContent,
  Button,
  Label,
  Error,
  CloseButton,
  Input,
  Pill,
  Abbr
} from "../../style/components/general.js";

const AddNoteCalloutContent = ({
  setVisible,
  visible,
  setRefreshNotes,
  current,
  setCurrent,
  task,
  taskRecord,
  group,
  label,
  taskType
}) => {
  const {addToast} = useToast();

  const {currentUser} = useContext(AuthContext);

  const {api: apiNotes, loading: noteLoading} = useApi(
    taskType === "event" ? "event-record-notes" : "record-notes"
  );

  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({});

  const form = useFormContext();

  const {control, watch, setValue} = form;
  const {append, remove} = useFieldArray({control, name: "note.files"});

  const message = watch("note.message");
  const uploaded = watch("note.files");
  const lat = watch("note.coords.lat");
  const lon = watch("note.coords.lon");
  const hasCoords = lat?.length > 0 && lon?.length > 0;

  const submit = () => {
    const params = {
      userId: currentUser.publicId,
      group,
      groupLabel: label,
      message,
      status: "pending"
    };

    if (taskType === "event") {
      params.stage = group;
      params.stageLabel = label;
    } else {
      params.group = group;
      params.groupLabel = label;
    }

    if (!taskRecord) {
      params.referenceDate = task.dateDue;
      params.taskId = task.id;
    } else {
      params.taskRecordId = taskRecord.id;
    }

    if (lat !== "" && lon !== "") {
      params.lat = lat;
      params.lon = lon;
    }

    const fileData = new FormData();

    if (files.length > 0)
      for (let i = 0; i < files.length; i++) {
        fileData.append(`file-${i}`, new File([files[i]], files[i].name, {type: files[i].type}));
      }

    if (current?.id) {
      // Find Deleted Files
      const deletedFiles = current.files.filter(
        oldFile => !uploaded.some(newFile => oldFile.label === newFile.filename)
      );
      params.delete = deletedFiles.map(file => file.id);

      apiNotes
        .callPut(current.id, fileData, {params, headers: {"Content-Type": "multipart/form-data"}})
        .then(({status}) => {
          if (status === 200) {
            setVisible(false);
            setRefreshNotes(true);
            setCurrent(null);
          }
        });
    } else
      apiNotes
        .callPost(fileData, {params, headers: {"Content-Type": "multipart/form-data"}})
        .then(({status}) => {
          if (status === 201) {
            setVisible(false);
            setRefreshNotes(true);
            setFiles([]);
          }
        });
  };

  const validateSubmit = () => {
    const tempErrors = {};
    if (!message) tempErrors.message = "Please provide a message";
    if (hasCoords && (!exists(lat) || !exists(lon)))
      tempErrors.coords = "Please provide valid coordinates";
    if (!Object.keys(tempErrors).length) {
      setErrors({});
      return true;
    }
    setErrors(tempErrors);
    return false;
  };

  useEffect(() => {
    if (!visible || !current) setValue("note", {});
  }, [visible, current, setValue]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) validateSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [message, lat, lon]);

  useEffect(() => {
    if (current) {
      setValue("note.message", current.message);
      setValue("note.name", current.name);
      setValue(
        "note.files",
        current.files.map(file => ({...file, filename: file.label}))
      );
    }
  }, [append, current, setValue]);

  return (
    <CalloutContent
      mobile={isMobileDevice() ? 1 : 0}
      className="calloutContent"
      taskType={taskType}>
      {isMobileDevice() && (
        <ModalControls>
          <CloseButton data-testid="closeButton" onClick={() => setVisible(false)} />
        </ModalControls>
      )}

      <Menu>
        <Label color={heroTheme.group} bold>
          {label}
        </Label>
        <Inline>
          <Button
            type="button"
            title="Attach Location"
            onClick={async () => {
              const {error, lat: currLat, lon: currLon} = await getLocation();
              if (error) addToast(error, "error");
              else {
                setValue("note.coords.lat", currLat);
                setValue("note.coords.lon", currLon);
              }
            }}>
            <FontAwesomeIcon icon={faMapMarker} />
          </Button>
          <FileUpload htmlFor="file_upload">
            <Abbr title="Attach File(s)">
              <FontAwesomeIcon icon={faPaperclip} />
              <Input
                id="file_upload"
                name="file_upload"
                type="file"
                onChange={({target}) => {
                  const targetFile = target.files[0];
                  append({filename: targetFile.name});
                  setFiles(prev => [...prev, targetFile]);
                }}
                hidden
              />
            </Abbr>
          </FileUpload>
        </Inline>
      </Menu>

      <InputTextArea
        name="note.message"
        label="Message"
        testId="addNote.message"
        maxLength={1000}
        required
      />
      {errors && errors.message && <Error>{errors.message}</Error>}

      {(hasCoords || uploaded?.length > 0) && (
        <Attachments>
          {hasCoords && (
            <Pill>
              <a href={getMapFromCoords(lat, lon)}>Attached Location</a>
              &nbsp;
              <IconButton
                onClick={() => {
                  setValue("note.coords.lat", null);
                  setValue("note.coords.lon", null);
                }}>
                <FontAwesomeIcon icon={faClose} />
              </IconButton>
            </Pill>
          )}
          {uploaded?.map(({filename}) => (
            <Pill key={filename}>
              <Abbr title={filename}>{filename}</Abbr>
              &nbsp;
              <IconButton
                onClick={e => {
                  e.preventDefault();
                  const {textContent} = e.target.parentElement;
                  remove(uploaded.findIndex(({filename: name}) => name === textContent.trim()));
                  setFiles(prev => prev.filter(({name}) => name !== textContent.trim()));
                }}>
                <FontAwesomeIcon icon={faClose} />
              </IconButton>
            </Pill>
          ))}
        </Attachments>
      )}

      <Save
        type="button"
        onClick={() => {
          const result = validateSubmit();
          if (result) submit();
        }}
        data-testid="addNote.submit"
        loading={noteLoading ? 1 : 0}>
        Save
        {noteLoading && <ButtonLoader />}
      </Save>
    </CalloutContent>
  );
};

AddNoteCalloutContent.propTypes = {
  setVisible: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  setRefreshNotes: PropTypes.func.isRequired,
  current: PropTypes.objectOf(PropTypes.any),
  setCurrent: PropTypes.func,
  task: PropTypes.objectOf(PropTypes.any).isRequired,
  taskRecord: PropTypes.objectOf(PropTypes.any),
  group: PropTypes.string,
  label: PropTypes.string,
  taskType: PropTypes.string
};

AddNoteCalloutContent.defaultProps = {
  current: null,
  setCurrent: null,
  taskRecord: null,
  group: "",
  label: "",
  taskType: "checksheet"
};

// Style Overrides
const Menu = styled(Inline)`
  width: 100%;
  justify-content: space-between;
`;

const Attachments = styled(Inline)`
  width: 100%;
  flex-wrap: wrap;
  margin-top: ${pad / 2}px;

  ${Pill} {
    ${voice.quiet};

    a {
      line-height: 1;
    }
  }
`;

const FileUpload = styled(Label)`
  background-color: ${props => props.theme.secondary};
  color: ${props => props.theme.tertiary};
  padding: ${pad / 2}px ${pad}px;
  border-radius: ${radius};
  cursor: pointer;

  svg {
    fill: ${props => props.theme.tertiary};
  }
`;

const IconButton = styled(Button)`
  ${voice.quiet};
  background-color: transparent;
  width: min-content;
  padding: 0;

  svg {
    fill: ${props => props.theme.tertiary};
  }
`;

const Save = styled(ButtonFull)`
  margin-top: ${pad}px;
`;

export default AddNoteCalloutContent;

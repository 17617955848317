import React, {useMemo, useState} from "react";
import {FormProvider, useFieldArray, useForm} from "react-hook-form";
import PropTypes from "prop-types";
import styled from "styled-components";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";

// Hooks
import useApi from "../../hooks/useApi.js";

// Utils
import {getFields} from "../../utils/builder.js";
import {convertToUserTimezone} from "../../utils/helpers.js";

// Components
import ModalReview from "./ModalReview.js";
import Modal from "../../components/Modal.js";
import InputText from "../../components/form/InputText.js";
import InputTextArea from "../../components/form/InputTextArea.js";
import InputNumber from "../../components/form/InputNumber.js";
import SearchSelect from "../../components/SearchSelect.js";
import InputDate from "../../components/form/InputDate.js";
import InputCheck from "../../components/form/InputCheck.js";
import InputError from "../../components/form/InputError.js";

// Style
import {flex} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {pad} from "../../style/components/variables.js";
import {Button, Form, Heading, Inline} from "../../style/components/general.js";

const defaultValues = {
  description: "",
  limit: 1
};

const schema = yup.object().shape({
  description: yup.string().required("Please provide a description for review."),
  hasFields: yup.boolean(),
  fields: yup
    .mixed()
    .nullable()
    .when("hasFields", {
      is: true,
      then: () =>
        yup.array().test({
          test: val => Array.isArray(val) && val.length > 0,
          message: "Please select at least one field."
        })
    }),
  limit: yup
    .number()
    .nullable()
    .when("hasFields", {
      is: true,
      then: () => yup.number().typeError("Please provide a limit.")
    }),
  hasEnd: yup.boolean(),
  end: yup
    .mixed()
    .nullable()
    .when("hasEnd", {
      is: true,
      then: () => yup.date().typeError("Please provide a date when the review will expire.")
    })
});

const ModalReviewManage = ({visible, setVisible, goBack, checksheet, review, reload}) => {
  const {api: apiReviews} = useApi("checksheet-reviews");

  const {builder} = checksheet;
  const {allIds, byId} = builder;

  const [showModalReview, setShowModalReview] = useState(false);
  const [results, setResults] = useState([]);

  const form = useForm({
    defaultValues: review?.data
      ? {
          name: review.name,
          hasFields: review.data.fields.length > 0,
          ...review.data,
          hasEnd: review?.endAfter !== null,
          end: review?.endAfter
            ? dayjs(convertToUserTimezone(review.endAfter)).format("YYYY-MM-DD")
            : null
        }
      : defaultValues,
    resolver: yupResolver(schema)
  });
  const {
    control,
    watch,
    handleSubmit,
    formState: {errors},
    reset
  } = form;
  const values = watch();
  const watchHasEnd = watch("hasEnd");
  const watchHasFields = watch("hasFields");

  const {fields, append, remove} = useFieldArray({control, name: "fields"});

  const allFields = useMemo(() => getFields(allIds, byId), [allIds, byId]);

  const search = query => {
    if (query) {
      const lower = query.toLowerCase();
      const tempResults = allFields.filter(field => field.ancestry.toLowerCase().includes(lower));
      tempResults.sort(
        (a, b) => a.label.toLowerCase().indexOf(lower) - b.ancestry.toLowerCase().indexOf(lower)
      );
      setResults(tempResults.map(field => ({name: field.ancestry, value: field.name})));
    } else setResults(allFields.map(field => ({name: field.ancestry, value: field.name})));
  };

  const handleSave = ({name, description, limit, fields: targetFields, end, hasEnd}) => {
    const params = {
      facilityChecksheetId: checksheet.id,
      name,
      data: {description, limit, fields: targetFields}
    };

    if (hasEnd) params.endAfter = end;

    const success = !review ? 201 : 200;
    const request = !review ? apiReviews.callPost(params) : apiReviews.callPut(review.id, params);

    request.then(({status}) => {
      if (status === success) {
        goBack();
        reset(defaultValues);
        reload();
      }
    });
  };

  if (showModalReview)
    return (
      <ModalReview
        visible={visible}
        setVisible={setVisible}
        goBack={() => setShowModalReview(false)}
        checksheet={checksheet}
        preview={values}
      />
    );

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      hasBackButton
      goBack={() => {
        goBack();
        reset(defaultValues);
      }}>
      <Heading>{review ? "Edit" : "Add"} Required Review</Heading>

      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(handleSave)} noValidate>
          <InputText name="name" label="Title" placeholder="Title..." required />

          <InputTextArea
            name="description"
            label="Description"
            placeholder="Please review and acknowledge..."
            required
          />

          <InputCheck name="hasFields">Add fields to review?</InputCheck>
          {watchHasFields && (
            <>
              <SearchWrapper>
                <SearchSelect
                  label="Fields *"
                  results={results}
                  setResults={setResults}
                  search={search}
                  add={field => {
                    if (fields?.filter(({value}) => value === field.value).length === 0)
                      append(field);
                  }}
                  showAll
                />
                <Selections>
                  {fields?.map((field, idx) => (
                    <Selection key={field.value} type="button" onClick={() => remove(idx)}>
                      {field.name}&nbsp;
                      <FontAwesomeIcon icon={faClose} />
                    </Selection>
                  ))}
                </Selections>
                <InputError name="fields" errors={errors} />
              </SearchWrapper>
              <InputNumber name="limit" label="Data from last" units="Records" required />
            </>
          )}

          <InputCheck name="hasEnd">Expire on date?</InputCheck>
          {watchHasEnd && (
            <InputDate
              name="end"
              label="Expires"
              required={watchHasEnd}
              min={dayjs().format("YYYY-MM-DD")}
            />
          )}

          <Submit>
            <Button type="submit">Save</Button>
            <Button type="button" onClick={() => setShowModalReview(true)}>
              Preview
            </Button>
          </Submit>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalReviewManage.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  checksheet: PropTypes.objectOf(PropTypes.any).isRequired,
  review: PropTypes.objectOf(PropTypes.any),
  reload: PropTypes.func.isRequired
};

ModalReviewManage.defaultProps = {
  review: undefined
};

// Style Overrides
const SearchWrapper = styled.div`
  position: relative;
  margin: ${pad}px 0;
  width: 100%;
`;

const Selections = styled.div`
  position: relative;
  ${flex("row", "wrap", "start", "start")};
`;

const Selection = styled(Button)`
  padding: ${pad / 2}px;
  width: fit-content;
  margin: ${pad}px ${pad / 2}px 0 0;
  ${voice.quiet};
`;

const Submit = styled(Inline)`
  margin-top: ${pad * 2}px;
`;

export default ModalReviewManage;

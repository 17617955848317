import React, {useMemo} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";

// Utils
import {getFields} from "../../utils/builder.js";

// Components
import Modal from "../../components/Modal.js";
import Help from "../../components/Help.js";

// Style
import {voice} from "../../style/components/typography.js";
import {pad} from "../../style/components/variables.js";
import {Heading, Inline, Label, Text} from "../../style/components/general.js";

const ModalFieldRestrictions = ({visible, setVisible, checksheet}) => {
  const {
    name,
    builder: {allIds, byId}
  } = checksheet;

  // Open single file preview
  const fields = useMemo(() => getFields(allIds, byId, ["number", "generated"]), [allIds, byId]);

  const formatRange = (min, max, strictMin = true, strictMax = true) => {
    if (min !== null && max !== null)
      return `value ${strictMin ? ">" : ">="} ${min} and value ${strictMax ? "<" : "<="}  ${max}`;
    if (min !== null) return `value ${strictMin ? ">" : ">="} ${min}`;
    return `value ${strictMax ? "<" : "<="} ${max}`;
  };

  const formatCondition = condition => {
    let formattedCondition;
    if (condition) {
      formattedCondition = `${condition.action} when `;
      condition.list.map(({check, compare, depends, operator}, idx) => {
        if (condition.list.length > 1 && idx === condition.list.length - 1)
          formattedCondition += `, ${condition.operator} `;
        else if (condition.list.length > 1 && idx > 0) formattedCondition += ", ";

        const checkArray = Array.isArray(check) ? check : [check];
        const compareArray = Array.isArray(compare) ? compare : [compare];

        const dependsType = byId[depends]?.type || depends;
        let dependsUnits = byId[depends]?.units ? ` ${byId[depends]?.units}` : "";
        let dependsLabel = byId[depends]?.label || depends?.toUpperCase();

        if (depends === "cumulative") dependsLabel = "CUMULATIVE RAINFALL";
        if (depends === "cumulative" || depends === "rainfall") dependsUnits = " inches";

        formattedCondition += `${dependsLabel} `;

        if (depends === "weekday") {
          formattedCondition += "is ";
          compareArray.map((compareVal, compareIdx) => {
            formattedCondition += compareVal;
            if (compareIdx < compareArray.length - 2) formattedCondition += ", ";
            if (compareIdx === compareArray.length - 2) formattedCondition += ` ${operator} `;
          });
        } else
          checkArray.map((checkVal, checkIdx) => {
            let comp = compareArray[checkIdx];

            let ch = checkVal || "is";

            if (ch === "===") ch = "is";
            if (ch === "!==") ch = "is not";
            if (ch === ">=") ch = "≥";
            if (ch === "<=") ch = "≤";
            if (ch === ">" && dependsType === "time") ch = "is after";
            if (ch === "<" && dependsType === "time") ch = "is before";
            if (dependsType === "multiselect") {
              ch = "has";
              if (comp === "checked") comp = "all checked";
              if (comp === "unchecked") comp = "all unchecked";
              if (comp === "one") comp = "one checked";
              if (comp === "more") comp = "at least one checked";
            }

            if (comp.match(/^Other: /)) comp = comp.replace(/^Other: /, "");

            formattedCondition += `${ch} ${comp}${dependsUnits}`;
            if (checkIdx < checkArray.length - 1) formattedCondition += ` ${operator} `;
          });
      });
    }

    return formattedCondition;
  };

  const readableFormulaString = formula => {
    let f = "";
    formula.map(part => {
      if (byId && part.value in byId)
        f += ` ${byId[part.value].label} ${part?.previous ? " PREVIOUS" : ""}`;
      else f += ` ${part.label}`;
    });
    return f;
  };

  const targetFields = useMemo(
    () =>
      fields?.filter(
        ({hasSetPoint, hasRange, hasARange, limits}) =>
          hasSetPoint || hasRange || hasARange || (limits?.length > 0 && limits[0]?.label)
      ),
    [fields]
  );

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <Heading>{name} Field Restrictions</Heading>
      {targetFields?.length > 0 ? (
        targetFields.map(
          ({
            name: fieldName,
            ancestry,
            condition,
            formula,
            hasSetPoint,
            setHigh,
            setLow,
            hasRange,
            min,
            max,
            hasARange,
            aMin,
            aMax,
            strictMin,
            strictMax,
            limits
          }) => (
            <Field key={fieldName}>
              <Inline>
                <Label bold>{ancestry}</Label>
                {condition && (
                  <Help icon={<FontAwesomeIcon icon={faCheckDouble} />}>
                    {formatCondition(condition)}
                  </Help>
                )}
                {formula?.length > 0 && (
                  <Help icon={<Formula>f(x)</Formula>}>{readableFormulaString(formula)}</Help>
                )}
              </Inline>
              {hasSetPoint && (
                <Tab>
                  Set Points: {formatRange(setLow, setHigh)}&nbsp;
                  <Help>Informational range, does not effect values.</Help>
                </Tab>
              )}
              {hasRange && (
                <Tab>
                  Restricted Range: {formatRange(min, max)}&nbsp;
                  <Help>Will not allow values outside this range to be saved.</Help>
                </Tab>
              )}
              {hasARange && (
                <Tab>
                  Acceptable Range: {formatRange(aMin, aMax, strictMin, strictMax)}&nbsp;
                  <Help>
                    Values will be saved, but an Unacceptable Parameter Notification will be sent
                  </Help>
                </Tab>
              )}
              {limits?.length > 0 &&
                limits[0]?.label &&
                limits.map(limit => (
                  <Tab key={`${limit.label}:${limit.value}`}>
                    {limit.label} Limit: {limit.value}
                  </Tab>
                ))}
            </Field>
          )
        )
      ) : (
        <Text>
          This checksheet does not include conditional fields, generated formula fields or fields
          with any restrictions (ranges, limits or set points).
        </Text>
      )}
    </Modal>
  );
};

ModalFieldRestrictions.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  checksheet: PropTypes.objectOf(PropTypes.any).isRequired
};

// Style Overrides
const Field = styled.div`
  padding: ${pad}px;
`;

const Tab = styled(Text)`
  margin-left: ${pad}px;
`;

const Formula = styled.span`
  ${voice.quiet};
  font-weight: bold;
  padding: ${pad / 2}px;
  color: ${({theme}) => theme.secondary};
`;

export default ModalFieldRestrictions;

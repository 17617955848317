import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Contexts
import {NotificationContext} from "../../contexts/notify.js";
import {AuthContext} from "../../contexts/auth.js";
import {useSocket} from "../../contexts/socket.js";

// Utils
import {exists, toTitleCase} from "../../utils/helpers.js";
import {getIcon} from "../schedule/helpers.js";

// Components
import EventList from "./EventList.js";

// Style
import {flex} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {pad} from "../../style/components/variables.js";
import {HeadingMedium, Span} from "../../style/components/general.js";

// Socket Constants
import {REQUEST_LOCKS, LIST_LOCKS, getFacilityRooms} from "../general/Room.js";

const EventsDue = ({room, canRequestLocks, setHasUpdates}) => {
  const socket = useSocket();

  const isMounted = useMountedState();

  const {slug} = useParams();

  const {currentUser} = useContext(AuthContext);

  const {notifications} = useContext(NotificationContext);

  const {api: apiEventTypes} = useApi("event-types");
  const {api: apiAddress, data: markers} = useApi("addresses");

  const [eventTypes, setEventTypes] = useState(null);
  const [hide, setHide] = useState(null);
  const [locks, setLocks] = useState(null);

  const requestingLocks = useRef(false);
  const timers = useRef({});

  const getEventTypes = useCallback(
    () =>
      apiEventTypes.callGet().then(({status, data}) => {
        if (status === 200) setEventTypes(data);
      }),
    [apiEventTypes]
  );

  useEffect(() => {
    if (locks) {
      Object.keys(locks).map(key => {
        const lockInfo = locks[key];
        const {ttl} = lockInfo;
        if (exists(ttl) && ttl > 0 && ttl <= 3 * 60 * 60) {
          if (timers.current[key]) clearTimeout(timers.current[key]);
          const timer = setTimeout(() => {
            socket.emit(REQUEST_LOCKS, {
              rooms: getFacilityRooms(slug, "event"),
              user: currentUser.publicId,
              type: "event"
            });
            delete timers.current[key];
          }, ttl * 1000);
          timers.current[key] = timer;
        }
      });
    }
  }, [locks, socket, currentUser, slug]);

  useEffect(
    () => () => {
      Object.values(timers.current).map(timer => clearTimeout(timer));
    },
    []
  );

  // Initial Load
  useEffect(() => {
    if (isMounted() && eventTypes === null) {
      getEventTypes();
      apiAddress.callGet(null, {markers: true});
    }
  }, [isMounted, eventTypes, getEventTypes, apiAddress]);

  const listLocks = useCallback(
    ({lockList, userId, fromPercentComplete, type}) => {
      // if type is null, this event should be accepted by both Events and Checksheets
      // if userId is null, then it was another event action that triggered listLocks
      // if userId is defined, it is either from request_locks or percent_complete and should be ignored if:
      //        userId is current user, and request was not from percent complete
      //        userId is not the current user, and request was from percent complete
      if (
        (!type || type === "event") &&
        (!userId ||
          (userId === currentUser.publicId && !fromPercentComplete) ||
          (userId !== currentUser.publicId && fromPercentComplete))
      ) {
        setLocks(lockList);
        requestingLocks.current = false;
      }
    },
    [currentUser]
  );

  // Socket Management
  useEffect(() => {
    if (isMounted() && currentUser && !locks && canRequestLocks && !requestingLocks.current) {
      requestingLocks.current = true;
      socket.emit(REQUEST_LOCKS, {
        rooms: getFacilityRooms(slug, "event"),
        user: currentUser.publicId,
        type: "event"
      });
    }
  }, [isMounted, currentUser, locks, canRequestLocks, slug, socket]);

  useEffect(() => {
    if (isMounted()) socket.on(LIST_LOCKS, listLocks);

    return () => {
      // unbind all event handlers used in this component
      socket.off(LIST_LOCKS, listLocks);
    };
  }, [isMounted, socket, listLocks]);

  return (
    locks &&
    notifications?.eventsDue && (
      <Events>
        {eventTypes?.map(({name, color, icon}) => {
          const retrievedIcon = getIcon(icon);

          return (
            <Glance key={name} hide={!hide || hide?.includes(name)}>
              <HeadingMedium>
                {notifications.eventsDue[name]?.length}
                &nbsp;
                <Span color={`#${color}`}>
                  {toTitleCase(name)}
                  {notifications.eventsDue[name]?.length > 1 ? "s" : ""}
                </Span>
                &nbsp;
                {retrievedIcon && <Icon color={`#${color}`} icon={retrievedIcon} />}
                &nbsp;Upcoming
              </HeadingMedium>
              <EventList
                room={room}
                type={name}
                markers={markers}
                locks={locks}
                events={notifications.eventsDue[name]}
                setHide={setHide}
                setHasUpdates={setHasUpdates}
              />
            </Glance>
          );
        })}
      </Events>
    )
  );
};

EventsDue.propTypes = {
  room: PropTypes.string.isRequired,
  canRequestLocks: PropTypes.bool.isRequired,
  setHasUpdates: PropTypes.func.isRequired
};

// Style Overrides
const Events = styled.div`
  ${flex("row", "wrap", "start", "start")};
`;

const Glance = styled.div`
  margin-bottom: ${pad}px;
  width: 100%;

  ${({hide}) =>
    hide &&
    css`
      display: none;
    `}
`;

const Icon = styled(FontAwesomeIcon)`
  ${voice.medium};
  margin-bottom: 3px;
  fill: ${({color, theme}) => color || theme.tertiary};
`;

export default EventsDue;

import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useParams} from "react-router";
import styled from "styled-components";
import {DragDropContext, Droppable} from "react-beautiful-dnd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faFilter, faLock, faPlus} from "@fortawesome/free-solid-svg-icons";

// Contexts
import {NotificationContext} from "../contexts/notify.js";
import {useSocket} from "../contexts/socket.js";
import {AuthContext} from "../contexts/auth.js";
import {FacilityNavContext} from "../contexts/facilitynav.js";
import {useToast} from "../contexts/toast.js";

// Hooks
import useApi from "../hooks/useApi.js";
import useMountedState from "../hooks/useMountedState.js";

// Utils
import {
  addComponent,
  addField,
  removeElement,
  updateElement,
  newFromBuilder,
  addGroup,
  whichElementInvalid,
  sortHelp,
  FIELD,
  labelAlreadyExists
} from "../utils/builder.js";
import {generateUniqueKey, getSnakeCase, getWithExpiry, setWithExpiry} from "../utils/helpers.js";
import {recurseToggle, resolveDependencyMismatches} from "./checksheet-builder/helpers.js";

// Components
import Badge from "../components/Badge.js";
import MissingElements from "./checksheet-builder/MissingElements.js";
import RenderChecksheetBuilder from "./checksheet-builder/RenderChecksheetBuilder.js";
import RoleRestrict from "./checksheet-builder/RoleRestrict.js";
import ComponentTemplates from "./checksheet-builder/ComponentTemplates.js";
import FacilityPageHeader from "./general/FacilityPageHeader.js";
import Notifications from "./checksheet-builder/Notifications.js";
import ModalFilter from "./checksheet-builder/ModalFilter.js";
import ModalNotifications from "./checksheet-builder/ModalNotifications.js";
import ModalEditElement from "./checksheet-builder/ModalEditElement.js";
import ModalAddField from "./checksheet-builder/ModalAddField.js";
import ModalDeleteElement from "./checksheet-builder/ModalDeleteElement.js";
import ModalRedirect from "./general/ModalRedirect.js";
import ModalFormula from "./checksheet-builder/ModalFormula.js";
import ModalElapsed from "./checksheet-builder/ModalElapsed.js";
import ModalHelp from "./checksheet-builder/ModalHelp.js";
import ModalEditChecksheetMeta from "./checksheet-builder/ModalEditChecksheetMeta.js";
import ModalAddToBuilder from "./checksheet-builder/ModalAddToBuilder.js";
import ModalChecksheetPreview from "./checksheet-builder/ModalChecksheetPreview.js";
import ModalApplyTemplate from "./checksheet-builder/ModalApplyTemplate.js";
import ModalSaveTemplate from "./checksheet-builder/ModalSaveTemplate.js";
import ModalConditionals from "./checksheet-builder/ModalConditionals.js";

// Style
import {pad, border, radius, colors} from "../style/components/variables.js";
import {voice} from "../style/components/typography.js";
import {flex, z} from "../style/components/mixins.js";
import {bp} from "../style/components/breakpoints.js";
import {
  Page,
  Heading,
  Inline,
  Button,
  Error,
  NotLoaded,
  Loader,
  StickyWrapper,
  Abbr,
  Pill,
  HeadingMedium,
  Text
} from "../style/components/general.js";

// Socket Constants
import Room, {LOCK, RELEASE, getFacilityRooms} from "./general/Room.js";

const UPDATE_CHECKSHEET_BUILDER = "notify_update_checksheet_builder";

const ChecksheetEditor = () => {
  const isMounted = useMountedState();

  const socket = useSocket();

  const {addToast} = useToast();

  const {slug, edit} = useParams();

  const {currentUser, roleCanAccessResource, roles} = useContext(AuthContext);

  const {getNotifications} = useContext(NotificationContext);

  const {setFacility} = useContext(FacilityNavContext);

  const [checksheetName, setChecksheetName] = useState("");
  const [checksheetFrequency, setChecksheetFrequency] = useState("");
  const [external, setExternal] = useState(false);
  const [builder, setBuilder] = useState({allIds: [], byId: {}});
  const [activeDroppableId, setActiveDroppableId] = useState("");
  const [isDraft, setIsDraft] = useState(null);
  const [fieldNotifications, setFieldNotifications] = useState({allIds: [], byId: {}});
  const [restrictTo, setRestrictTo] = useState(null);
  const [initialLoad, setInitialLoad] = useState(false);
  const [globalError, setGlobalError] = useState("");
  const [elementError, setElementError] = useState(null);
  const [elementToInsert, setElementToInsert] = useState(null);
  const [refreshTemplates, setRefreshTemplates] = useState(false);
  const [hasToggledOn, setHasToggledOn] = useState(null);
  const [units, setUnits] = useState([]);
  const [template, setTemplate] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [preview, setPreview] = useState(null);
  const [templateList, setTemplateList] = useState([]);
  // Filters
  const [filters, setFilters] = useState(null);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [filterResult, setFilterResult] = useState(null);
  // Modals
  const [target, setTarget] = useState(null);
  const [refreshMissing, setRefreshMissing] = useState(true);
  const [showModalHelp, setShowModalHelp] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showModalRedirectFormula, setShowModalRedirectFormula] = useState(false);
  const [showModalRedirectElapsed, setShowModalRedirectElapsed] = useState(false);
  const [formulaMode, setFormulaMode] = useState("create");
  const [showModalFormula, setShowModalFormula] = useState(false);
  const [elapsedMode, setElapsedMode] = useState("create");
  const [showModalElapsed, setShowModalElapsed] = useState(false);
  const [showConditionalModal, setShowConditionalModal] = useState(false);
  const [showModalEditChecksheetMeta, setShowModalEditChecksheetMeta] = useState(false);
  const [showModalAddFBElement, setShowModalAddFBElement] = useState(false);
  const [showSaveTemplateModal, setShowSaveTemplateModal] = useState(false);
  const [showTemplateModal, setShowTemplateModal] = useState(false);
  const [showNotificationModal, setShowNotificationModal] = useState(false);

  const {api: apiFacility, data: facility, loading: loadingFacility} = useApi(`facilities/${slug}`);
  const {api: apiChecksheet} = useApi(`checksheets/${edit}`);
  const {api: apiBase} = useApi("base-components");
  const {api: apiUnits} = useApi("units");

  const prevRestrictTo = useRef(null);
  const prevExternal = useRef(false);

  const filterCount = useMemo(() => {
    let count = 0;
    if (filters?.types?.length) count += filters.types.length;
    if (filters?.tags?.length) count += filters.tags.length;
    return count;
  }, [filters]);

  const scrollToTable = useCallback(() => {
    const {hash} = window.location;
    if (hash) {
      const componentId = hash.replace("#", "");
      setTimeout(() => {
        const component = document.getElementById(componentId);
        if (component) {
          const y = component.getBoundingClientRect().top - 200;
          window.scrollBy({behavior: "smooth", top: y});
        }
      }, 500);
    }
  }, []);

  // Socket Management - resource locking
  useEffect(() => {
    if (isMounted() && edit)
      socket.emit(LOCK, getFacilityRooms(slug, "checksheet"), `checksheet_${edit}`);

    // Component unmount
    return () => {
      socket.emit(RELEASE, getFacilityRooms(slug, "checksheet"), `checksheet_${edit}`);
    };
  }, [isMounted, socket, slug, currentUser, edit]);

  // Initial Load
  useEffect(() => {
    if (isMounted()) {
      apiFacility.callGet();
      apiUnits.callGet().then(({status, data}) => {
        if (status === 200 && data) setUnits(data);
      });
    }
  }, [isMounted, apiFacility, apiUnits]);

  const errorMessages = (invalid, result) => {
    // if invalid is nonempty, invalid contains ids of invalid elements
    // if invalid is empty and result is false, globally invalid (no elements)
    // if invalid is empty and result is true, checksheet is valid

    if (invalid.length > 0) {
      setGlobalError("Cannot save checksheet - see below for invalid elements.");
      setElementError(invalid);
    }
    // elementError contains ids of invalid element
    else if (!result) setGlobalError("Cannot save checksheet - must contain at least one field.");
    else {
      setElementError(null);
      setGlobalError("");
    }
  };

  // Handle filtering
  useEffect(() => {
    const {byId} = builder;

    if (filters?.types || filters?.tags)
      setFilterResult(
        Object.fromEntries(
          Object.entries(byId).filter(
            ([, {tag, type}]) =>
              filters.types?.includes(type) || (tag && filters.tags?.includes(tag))
          )
        )
      );
    else {
      setFilters(null);
      setFilterResult(null);
    }
  }, [filters, builder]);

  useEffect(() => {
    if (isMounted() && !loadingFacility && facility) {
      if (setFacility) setFacility(facility);
      apiChecksheet
        .callGet()
        .then(({status, data}) => {
          if (status === 200 && isMounted()) {
            const {
              name,
              frequency,
              builder: latest,
              notifications,
              restrictTo: res,
              external: emailOnly,
              draft
            } = data;

            // Check/Store local builder
            const key = `${getSnakeCase(facility.name)}${getSnakeCase(
              frequency?.name
            )}.${getSnakeCase(name)}.builder`;
            const storedBuilder = JSON.parse(getWithExpiry(key));
            let builderToStore = latest;
            if (storedBuilder) builderToStore = storedBuilder;

            // Update states based on Facility Builder
            const updated = recurseToggle(builderToStore.allIds, builderToStore, true);
            const invalid = [];
            const result = whichElementInvalid(updated.allIds, updated, invalid);

            if (res?.length > 0) {
              prevRestrictTo.current = [...res];
              setRestrictTo(res);
              prevExternal.current = emailOnly;
              setExternal(emailOnly);
            }

            errorMessages(invalid, result);
            setChecksheetName(name);
            setChecksheetFrequency(frequency ? frequency.name : null);
            setBuilder({...updated});
            setFieldNotifications(notifications);
            setHasToggledOn(true);
            setInitialLoad(true);
            setRefreshMissing(true);
            setIsDraft(draft || false);
          }
        })
        .finally(() => scrollToTable());
    }
  }, [isMounted, apiChecksheet, facility, loadingFacility, scrollToTable, setFacility]);

  const handleClear = () => {
    setFilters(null);
    setFilterResult(null);
    setShowFilterModal(false);
  };

  const getTemplateLabel = id => {
    const filtered = templateList.filter(t => t.id === id);
    if (filtered.length) return filtered[0].label;
    return "Template";
  };

  const saveChecksheet = useCallback(
    (updated, newUnits, appliedTemplates, notifications) => {
      const {allIds} = updated;

      // validate builder structure
      setBuilder(updated);

      const checksheetBuilder = newFromBuilder(allIds, {...updated}, {allIds: [], byId: {}});

      const key = `${getSnakeCase(facility.name)}.${getSnakeCase(
        checksheetFrequency
      )}.${getSnakeCase(checksheetName)}.builder`;

      setWithExpiry(
        key,
        JSON.stringify(updated),
        (process.env.REACT_APP_EXPIRATION_IN_MINUTES ?? 10) * 60 // 10 minute default
      );

      const invalid = [];
      const result = whichElementInvalid(checksheetBuilder.allIds, checksheetBuilder, invalid);
      errorMessages(invalid, result);

      if (facility && invalid.length === 0 && result) {
        const checksheetToSave = {
          facilityId: facility.id,
          name: checksheetName,
          frequency: checksheetFrequency,
          builder: checksheetBuilder,
          notifications: notifications || fieldNotifications,
          templates: appliedTemplates || {},
          restrictTo: restrictTo?.map(({id}) => id),
          external: external
        };

        if (newUnits && newUnits.length > 0) checksheetToSave.units = newUnits;
        apiChecksheet.callPut("", checksheetToSave).then(({status}) => {
          if (status === 200) {
            setWithExpiry(
              key,
              null,
              (process.env.REACT_APP_EXPIRATION_IN_MINUTES ?? 10) * 60 // 10 minute default
            );
            getNotifications(slug);
            socket.emit(UPDATE_CHECKSHEET_BUILDER, [
              ...getFacilityRooms(slug, "checksheet"),
              `${slug}-checksheet-preview`
            ]);
          }
        });
      }
      setRefreshMissing(true);
    },
    [
      apiChecksheet,
      checksheetName,
      checksheetFrequency,
      facility,
      fieldNotifications,
      getNotifications,
      slug,
      restrictTo,
      external,
      socket
    ]
  );

  const toggledElements = updated => {
    for (let i = 0; i < updated.allIds.length; i++)
      if (updated.byId[updated.allIds[i]]?.toggle) return true;

    return false;
  };

  const saveBuilderAndNotifications = useCallback(
    (updated, notifications, newUnits, appliedTemplates) => {
      setHasToggledOn(toggledElements(updated));
      saveChecksheet(updated, newUnits, appliedTemplates, notifications);
      setFieldNotifications(notifications);
    },
    [saveChecksheet]
  );

  const saveBuilder = useCallback(
    (updated, newUnits, appliedTemplates) => {
      setHasToggledOn(toggledElements(updated));
      saveChecksheet(updated, newUnits, appliedTemplates);
    },
    [saveChecksheet]
  );

  const saveNotifications = useCallback(
    notifications => {
      saveChecksheet(builder, null, null, notifications);
      setFieldNotifications(notifications);
    },
    [saveChecksheet, builder]
  );

  // Handle Checksheet Role Restriction(s)
  useEffect(() => {
    if (
      isMounted() &&
      (prevRestrictTo?.current?.length !== restrictTo?.length || prevExternal.current !== external)
    ) {
      saveChecksheet(builder, null, null, null);
      prevExternal.current = external;
    }
  }, [isMounted, restrictTo, prevRestrictTo, external, prevExternal, builder, saveChecksheet]);

  const onDragStart = provided => {
    const {droppableId} = provided.source;
    setActiveDroppableId(droppableId);
  };

  // re-ordering list
  const onDragEnd = result => {
    const {destination, source, type} = result;
    if (!destination) return;

    let updatedBuilder;
    const builderCopy = {...builder};

    if (type === "DEFAULT") {
      const allIds = [...builder.allIds];

      const [removed] = allIds.splice(source.index, 1);
      allIds.splice(destination.index, 0, removed);

      updatedBuilder = {...builderCopy, allIds};

      saveBuilder(updatedBuilder);
    }

    if (type === source.droppableId) {
      const byId = {...builder.byId};

      const parent = byId[type];
      const {children} = parent;

      const [removed] = children.splice(source.index, 1);
      children.splice(destination.index, 0, removed);

      const help = sortHelp(parent.help, children, byId);

      updatedBuilder = {...builderCopy, byId: {...byId, [type]: {...parent, children, help}}};

      saveBuilder(updatedBuilder);
    }
  };

  const recursePreview = (elementName, updatedById) => {
    if (
      updatedById[elementName] === undefined ||
      (!updatedById[elementName].toggle && updatedById[elementName].element !== FIELD)
    )
      return updatedById;

    updatedById[elementName].preview = !updatedById[elementName].preview;

    const {children} = updatedById[elementName];
    if (children && children.length > 0)
      children.forEach(child => {
        updatedById = recursePreview(child, updatedById);
      });

    return updatedById;
  };

  const togglePreview = elementName => {
    const updatedById = recursePreview(elementName, {...builder.byId});
    setBuilder({...builder, byId: {...updatedById}});
  };

  const handleEdit = (update, unlinkTemplate, linkToTemplate, updatedBuilder) => {
    const currentBuilder = updatedBuilder ?? builder;
    const newUnits = [];
    if (update.units && !units.includes(update.units)) {
      setUnits(prev => [...prev, update.units].sort());
      newUnits.push(update.units);
    }

    if (!unlinkTemplate && linkToTemplate) update.templateId = linkToTemplate.id;

    const forceNewKey = update.type
      ? update.type !== currentBuilder.byId[update.name]?.type
      : false;
    if (forceNewKey) handleClear();

    const {builder: updated, notifications: updatedNotifications} = updateElement(
      currentBuilder,
      update,
      false,
      null,
      forceNewKey,
      fieldNotifications
    );

    let appliedTemplate = null;
    if (unlinkTemplate) appliedTemplate = {unlink: [update.name]};
    if (!unlinkTemplate && linkToTemplate) appliedTemplate = {[linkToTemplate.id]: [update.name]};

    saveBuilderAndNotifications(updated, updatedNotifications, newUnits, appliedTemplate);
    setShowEditModal(false);
  };

  const handleDelete = (element, notificationsToDelete) => {
    // Handle builder element removal
    const updatedBuilder = removeElement(builder, element);
    saveBuilder(updatedBuilder);

    // Handle notification delete
    if (notificationsToDelete && notificationsToDelete.length > 0) {
      const updatedNotifications = {...fieldNotifications};
      notificationsToDelete.map(id => {
        if (Object.keys(updatedNotifications.byId).includes(id) && updatedNotifications.byId[id])
          // Delete field notification object
          delete updatedNotifications.byId[id];

        const temp = updatedNotifications.allIds.indexOf(id);
        updatedNotifications.allIds.splice(temp, 1);
      });
      saveNotifications(updatedNotifications);
    }

    setShowDeleteModal(false);
  };

  const handleAddField = (field, updatedBuilder) => {
    const currentBuilder = updatedBuilder ?? builder;
    handleClear();

    const newUnits = [];
    if (field.units && !units.includes(field.units)) {
      setUnits(prev => [...prev, field.units].sort());
      newUnits.push(field.units);
    }

    const temp = {...field, toggle: true};
    const updated = addField(currentBuilder, temp);
    saveBuilder(updated, newUnits);
  };

  const handleAddMultipleFields = fields => {
    handleClear();
    const newUnits = [];
    let updated = {...builder};
    fields.map(field => {
      if (field.units && !units.includes(field.units)) {
        setUnits(prev => [...prev, field.units].sort());
        newUnits.push(field.units);
      }
      const temp = {...field, toggle: true};
      updated = addField(updated, temp);
    });
    saveBuilder(updated, newUnits);
  };

  const handleFormula = (field, updatedBuilder) => {
    if (field.type === "generated") handleEdit(field, false, null, updatedBuilder);
    else {
      const newField = {
        ...field,
        type: "generated",
        disabled: true
      };
      handleAddField(newField, updatedBuilder);
    }
  };

  const handleConditional = values => {
    const {parentName} = target;
    const update = {
      ...target,
      condition: values.condition
    };
    if (parentName) {
      const parentElement = builder.byId[parentName];
      const parentHelp = parentElement.help;
      const helpItem = parentHelp?.find(({id}) => id === target.name);
      if (helpItem?.value) update.help = helpItem.value;
    }
    handleEdit(update);
  };

  const cloneElement = targetElement => {
    const {element, label, name, parentName} = targetElement;

    const newLabel = `${label} COPY`;
    if (labelAlreadyExists(newLabel, name, parentName, builder)) {
      const parentLabel = parentName ? builder.byId[parentName]?.label : null;
      addToast(
        parentLabel
          ? `${newLabel} already exists in ${parentLabel}`
          : `${newLabel} already exists on top level`,
        "error"
      );
    } else {
      const clone = {
        ...builder.byId[name],
        label: `${label} COPY`,
        name: null,
        toggle: true,
        condition: null
      };
      const nameMap = {};

      let updated;
      if (element === "field") updated = addField(builder, clone);
      if (element === "component") updated = addComponent(builder, clone, nameMap);
      if (element === "group") {
        const cloneIdx = builder.allIds.indexOf(name) + 1;
        updated = addGroup(builder, {...clone, cloneIdx}, nameMap);
      }

      resolveDependencyMismatches(updated, nameMap);
      saveBuilder(updated);
    }
  };

  const addTemplate = (componentName, templateLabel) => {
    const component = builder.byId[componentName];
    const fields = builder.byId[componentName].children.map(child => ({...builder.byId[child]}));
    const nameMap = {};
    const tempBuilder = {byId: {}};
    const extern = {};
    let extIdx = 0;

    // iterate over each field to add it to the temporary builder.
    // this is for resolving mismatched dependencies due to the template
    // being removed from the context of the current builder
    fields.forEach(field => {
      delete field.preview;
      delete field.toggle;
      if ("linkedGroup" in field) delete field.linkedGroup;
      if ("linkedComponent" in field) delete field.linkedComponent;

      const name = generateUniqueKey(field.label);
      nameMap[field.name] = name;
      field.name = name;

      // if a field has a formula, iterate over each part to set up
      // nameMap and the temporary builder (for resolving dependencies)
      if (field.formula)
        field.formula
          .filter(({value}) => value in builder.byId)
          .map(({value}) => {
            const item = builder.byId[value];
            // any items in formula that fall outside component
            // are reassigned to name ext_ where _ represents a unique integer
            if (item.parentName !== componentName) {
              let key = nameMap[value];
              // if field name already exists in nameMap, use that mapping instead of
              // generating a new ext_ key
              if (!key) {
                nameMap[value] = `ext${extIdx}`;
                key = `ext${extIdx}`;
                extIdx++;
              }
              if (!(key in extern)) {
                tempBuilder.byId[key] = {
                  ...item,
                  name: key,
                  formula: undefined,
                  condition: undefined
                };
                extern[key] = {
                  type: item.type,
                  label: item.label
                };
              }
            }
          });

      // if a field has conditions, iterate over each condition in list to set up
      // nameMap and the temporary builder (for resolving dependencies)
      if (field.condition && field.condition.list)
        field.condition.list
          .filter(({depends}) => depends in builder.byId)
          .map(({depends}) => {
            const item = builder.byId[depends];

            // any items in condition that fall outside component
            // are reassigned to name ext_ where _ represents a unique integer
            if (item.parentName !== componentName) {
              let key = nameMap[depends];
              // if field name already exists in nameMap, use that mapping instead of
              // generating a new ext_ key
              if (!key) {
                nameMap[depends] = `ext${extIdx}`;
                key = `ext${extIdx}`;
                extIdx++;
              }

              if (!(key in extern)) {
                tempBuilder.byId[key] = {
                  ...item,
                  name: key,
                  formula: undefined,
                  condition: undefined
                };
                extern[key] = {
                  type: item.type,
                  label: item.label
                };
              }
            }
          });

      tempBuilder.byId[field.name] = {
        ...field,
        formula: field.formula ? [...field.formula.map(part => ({...part}))] : null,
        condition: field.condition
          ? {...field.condition, list: [...field.condition.list.map(item => ({...item}))]}
          : null
      };
    });

    resolveDependencyMismatches(tempBuilder, nameMap);

    const templateToAdd = {
      label: templateLabel,
      options: {
        grid: component.grid,
        hasHelp: component.hasHelp,
        help: component.help
          ? component.help.map(item => ({
              ...item,
              id: item.id in nameMap ? nameMap[item.id] : ""
            }))
          : [],
        external: extern
      },
      fields: Object.values(tempBuilder.byId).filter(({name}) => !name.match("^ext[0-9]*$"))
    };

    apiBase.callPost({...templateToAdd}).then(() => {
      setRefreshTemplates(true);
      setTarget(null);
    });
  };

  const buildPreview = () => {
    const checksheetBuilder = newFromBuilder(builder.allIds, {...builder}, {allIds: [], byId: {}});
    const checksheetId = parseInt(edit, 10);
    setPreview({
      id: checksheetId || edit,
      name: checksheetName,
      frequency: checksheetFrequency,
      builder: checksheetBuilder,
      notifications: fieldNotifications
    });
    setShowPreview(true);
  };

  const handleShowFormulaModal = element => {
    if (element.type === "generated") setShowModalRedirectFormula(true);
    else setShowModalFormula(true);
  };

  const handleShowElapsedModal = element => {
    if (element.type === "generated") setShowModalRedirectElapsed(true);
    else {
      setElapsedMode("create");
      setShowModalElapsed(true);
    }
  };

  return (
    <Page hasMenu>
      <Room name="checksheet-edit" />

      {facility?.name && <FacilityPageHeader facility={facility} path="/schedule" disabled />}

      <HeadingWrapper>
        <HeadingMedium>Checksheet Editor</HeadingMedium>
        <Text>
          <FontAwesomeIcon icon={faLock} />
          &nbsp;Checksheet Locked while editing
        </Text>
      </HeadingWrapper>

      <Wrapper>
        <PageLeft>
          <StyledSticky>
            {facility && (
              <>
                <RoleRestrict
                  restrictTo={restrictTo}
                  setRestrictTo={setRestrictTo}
                  external={external}
                  setExternal={
                    checksheetFrequency && checksheetFrequency !== "None" ? setExternal : null
                  }
                />

                {roleCanAccessResource("facility_checksheet_notifications", "view") && (
                  <Notifications
                    showEditModal={setShowNotificationModal}
                    facility={facility}
                    notifications={fieldNotifications}
                    existing
                  />
                )}
                <MissingElements
                  insertElement={setElementToInsert}
                  setShowMissingElementsModal={setShowModalAddFBElement}
                  facility={facility}
                  currentBuilder={builder}
                  refresh={refreshMissing}
                  setRefresh={setRefreshMissing}
                  initialLoad={initialLoad}
                />
                {roleCanAccessResource("component", "view") && (
                  <ComponentTemplates
                    setTemplate={setTemplate}
                    setShowTemplateModal={setShowTemplateModal}
                    setRefresh={setRefreshTemplates}
                    refresh={refreshTemplates}
                    maxHeight="35vh"
                    setTemplateList={setTemplateList}
                    templateList={templateList}
                    hasPrimaryAddress={!!facility?.primaryAddress}
                  />
                )}
              </>
            )}
          </StyledSticky>
        </PageLeft>
        <PageRight>
          {globalError && globalError !== "" && <StyledErrorText>{globalError}</StyledErrorText>}
          <Builder data-testid="checksheet.preview">
            {!initialLoad ? (
              <NotLoaded>
                <Loader data-testid="checksheet.loader" />
              </NotLoaded>
            ) : (
              <>
                <Menu>
                  <MenuHeading>
                    <Heading data-testid="checksheet.name">
                      <Abbr title={checksheetName.toUpperCase()}>
                        {checksheetName.toUpperCase()}
                      </Abbr>
                    </Heading>
                    {checksheetFrequency?.length > 0 && (
                      <Frequency data-testid="checksheet.frequency">
                        <Abbr title={checksheetFrequency}>{checksheetFrequency}</Abbr>
                      </Frequency>
                    )}
                    {external && roles && (
                      <Pill quiet>
                        <Abbr
                          title={`Email to ${restrictTo
                            ?.filter(
                              ({id}) => roles.filter(({id: roleId}) => id === roleId)[0]?.label
                            )
                            .map(({id}) => roles.filter(({id: roleId}) => id === roleId)[0].label)
                            .join(", ")}`}>
                          EXTERNAL
                        </Abbr>
                      </Pill>
                    )}
                    {isDraft && <Draft>DRAFT</Draft>}
                    {initialLoad && (
                      <EditChecksheetMetaButton
                        type="button"
                        onClick={() => setShowModalEditChecksheetMeta(true)}
                        data-testid="checksheet.editMeta">
                        <FontAwesomeIcon icon={faEdit} />
                      </EditChecksheetMetaButton>
                    )}
                  </MenuHeading>
                  <MenuButtons>
                    <Filters>
                      <Button type="button" onClick={() => setShowFilterModal(true)}>
                        <Badge count={filterCount} offset="14px" color={colors.heroGreen} />
                        <FontAwesomeIcon icon={faFilter} />
                      </Button>
                    </Filters>
                    {hasToggledOn && <PreviewAll onClick={buildPreview}>Preview All</PreviewAll>}
                  </MenuButtons>
                </Menu>

                <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
                  <Droppable
                    droppableId="checksheet.editor"
                    isDropDisabled={activeDroppableId !== "checksheet.editor"}>
                    {provided => (
                      <div {...provided.droppableProps} ref={provided.innerRef}>
                        <RenderChecksheetBuilder
                          builder={builder}
                          filterResult={filterResult}
                          setBuilder={saveBuilder}
                          activeDroppableId={activeDroppableId}
                          setTarget={setTarget}
                          setShowHelpModal={setShowModalHelp}
                          setShowEditModal={setShowEditModal}
                          setShowAddModal={setShowAddModal}
                          setShowDeleteModal={setShowDeleteModal}
                          setShowFormulaModal={handleShowFormulaModal}
                          setShowConditionalModal={setShowConditionalModal}
                          setShowElapsedModal={handleShowElapsedModal}
                          setShowSaveTemplateModal={setShowSaveTemplateModal}
                          cloneElement={cloneElement}
                          togglePreview={togglePreview}
                          error={elementError}
                          editing
                          templates={templateList}>
                          {provided.placeholder}
                        </RenderChecksheetBuilder>
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </>
            )}
          </Builder>
        </PageRight>
      </Wrapper>

      {/* Modals */}
      {showModalHelp && target && (
        <ModalHelp visible={showModalHelp} setVisible={setShowModalHelp} target={target} />
      )}

      {showEditModal && target && (
        <ModalEditElement
          visible={showEditModal}
          setVisible={setShowEditModal}
          targetElement={target}
          persistEdit={handleEdit}
          builder={builder}
          units={units}
          templateLabel={
            target.templateId && templateList ? getTemplateLabel(target.templateId) : null
          }
          templates={templateList}
          editing
        />
      )}

      {showAddModal && target && (
        <ModalAddField
          visible={showAddModal}
          setVisible={setShowAddModal}
          addFields={handleAddMultipleFields}
          builder={builder}
          parent={target}
          units={units}
        />
      )}

      {showModalRedirectFormula && (
        <ModalRedirect
          visible={showModalRedirectFormula}
          setVisible={setShowModalRedirectFormula}
          buttonOptions={[
            {
              action: () => {
                setFormulaMode("create");
                setShowModalFormula(true);
                setShowModalRedirectFormula(false);
              },
              description: "Create formula based on this field",
              icon: faPlus
            },
            {
              action: () => {
                setFormulaMode("edit");
                setShowModalFormula(true);
                setShowModalRedirectFormula(false);
              },
              description: "Edit existing formula field",
              icon: faEdit
            }
          ]}
        />
      )}

      {showModalRedirectElapsed && (
        <ModalRedirect
          visible={showModalRedirectElapsed}
          setVisible={setShowModalRedirectElapsed}
          buttonOptions={[
            {
              action: () => {
                setFormulaMode("create");
                setShowModalFormula(true);
                setShowModalRedirectElapsed(false);
              },
              description: "Create formula based on this field",
              icon: faPlus
            },
            {
              action: () => {
                setElapsedMode("edit");
                setShowModalElapsed(true);
                setShowModalRedirectElapsed(false);
              },
              description: "Edit existing elapsed time field",
              icon: faEdit
            }
          ]}
        />
      )}

      {showModalFormula && target && (
        <ModalFormula
          visible={showModalFormula}
          setVisible={state => {
            if (!state) setFormulaMode("create");
            setShowModalFormula(state);
          }}
          targetElement={target}
          save={handleFormula}
          builder={builder}
          units={units}
          mode={formulaMode}
          page="editor"
          hasPrimaryAddress={!!facility?.primaryAddress}
        />
      )}

      {showModalElapsed && target && (
        <ModalElapsed
          visible={showModalElapsed}
          setVisible={state => {
            if (!state) setElapsedMode("create");
            setShowModalElapsed(state);
          }}
          mode={elapsedMode}
          targetElement={target}
          builder={builder}
          save={handleFormula}
        />
      )}

      {showConditionalModal && target && (
        <ModalConditionals
          visible={showConditionalModal && !!target}
          setVisible={state => {
            if (!state) setTarget(null);
            setShowConditionalModal(state);
          }}
          targetElement={target}
          save={handleConditional}
          builder={builder}
          hasPrimaryAddress={!!facility?.primaryAddress}
        />
      )}

      {showDeleteModal && target && (
        <ModalDeleteElement
          visible={showDeleteModal}
          setVisible={setShowDeleteModal}
          facilityId={facility.id}
          builder={builder}
          notifications={fieldNotifications}
          persistDelete={handleDelete}
          targetElement={target}
        />
      )}

      {showModalEditChecksheetMeta && (
        <ModalEditChecksheetMeta
          checksheet={{name: checksheetName, frequency: checksheetFrequency, builder}}
          visible={showModalEditChecksheetMeta}
          setVisible={setShowModalEditChecksheetMeta}
          edit={apiChecksheet.callPut}
          setName={setChecksheetName}
        />
      )}

      {showModalAddFBElement && (
        <ModalAddToBuilder
          visible={showModalAddFBElement}
          setShowModal={setShowModalAddFBElement}
          builder={builder}
          facility={facility}
          saveBuilder={saveBuilder}
          element={elementToInsert}
          setRefreshMissing={setRefreshMissing}
        />
      )}

      {showTemplateModal && (
        <ModalApplyTemplate
          builder={builder}
          visible={showTemplateModal}
          setShowModal={setShowTemplateModal}
          template={template}
          saveBuilder={saveBuilder}
          recursePreview={recursePreview}
          units={units}
          setUnits={setUnits}
        />
      )}

      {showSaveTemplateModal && (
        <ModalSaveTemplate
          visible={showSaveTemplateModal}
          setShowModal={setShowSaveTemplateModal}
          targetName={target ? target.name : null}
          targetLabel={target ? target.label : null}
          saveTemplate={addTemplate}
        />
      )}

      {showPreview && (
        <ModalChecksheetPreview
          checksheet={preview}
          visible={showPreview}
          setVisible={setShowPreview}
        />
      )}

      {showNotificationModal && (
        <ModalNotifications
          visible={showNotificationModal}
          setVisible={setShowNotificationModal}
          checksheetBuilder={builder}
          notifications={fieldNotifications}
          setNotifications={setFieldNotifications}
          handleSave={saveNotifications}
        />
      )}

      {showFilterModal && (
        <ModalFilter
          visible={showFilterModal}
          setVisible={setShowFilterModal}
          filters={filters}
          setFilters={setFilters}
        />
      )}
    </Page>
  );
};

// Style Overrides
const Wrapper = styled.article`
  ${flex("row", "wrap", "start", "stretch")};
`;

const HeadingWrapper = styled(Inline)`
  display: block;
  width: 100%;

  ${bp(1)} {
    display: flex;
    justify-content: space-between;
  }

  ${bp(3)} {
    width: calc(100% - 10px);
  }
`;

const Builder = styled.div`
  position: relative;
  min-height: 300px;
  width: 100%;
  padding: ${pad}px;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
`;

const Frequency = styled(Pill)`
  ${voice.quiet};
  white-space: nowrap;
`;

const Draft = styled(Pill)`
  ${voice.quiet};
  white-space: nowrap;
  background: ${({theme}) => theme.warning};
`;

const EditChecksheetMetaButton = styled(Button)`
  position: relative;
  border: 0;
  background: 0;
  z-index: ${z("above")};
  svg {
    fill: ${({theme}) => theme.secondary};
  }
`;

const StyledErrorText = styled(Error)`
  margin: -${pad * 2}px auto ${pad}px auto;
  position: absolute;
  min-width: 350px;
  color: ${({theme}) => theme.error};
`;

const PageLeft = styled.div`
  position: relative;
  padding: ${pad}px 0;
  margin-top: -${pad}px;
  width: 100%;
  flex: 1;
  ${bp(3)} {
    width: 35%;
  }
`;

const PageRight = styled.div`
  padding: ${pad}px;
  width: 100%;
  ${bp(3)} {
    position: relative;
    width: 65%;
  }
`;

const Menu = styled.div`
  ${flex("row", "wrap", "space-between", "center")};
  margin-bottom: ${pad * 2}px;

  ${Heading} {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 75%;
  }
`;

const MenuHeading = styled(Inline)`
  width: calc(100% - 170px);
  justify-content: start;
`;

const MenuButtons = styled(Inline)`
  width: min-content;
  justify-content: end;
`;

const StyledSticky = styled(StickyWrapper)`
  top: ${pad * 10}px;
`;

const Filters = styled.div`
  position: relative;
`;

const PreviewAll = styled(Button)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export default ChecksheetEditor;

import React from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";

// Utils
import {
  exists,
  formatAddressAsString,
  getMapFromAddress,
  getMapFromCoords
} from "../../utils/helpers.js";

// Style
import {z} from "../../style/components/mixins";
import {voice} from "../../style/components/typography.js";
import {border, pad, radius} from "../../style/components/variables";
import {Abbr, Anchor, AnchorInline, HeadingSmall, Text} from "../../style/components/general.js";
import {bp} from "../../style/components/breakpoints.js";

const PrimaryAddress = ({details, fullWidth, children}) => {
  const {address} = details;
  const {id, lat, lon, line1, line2, city, state, zipCode, details: addressDetails} = address;

  const getNoteValue = value => {
    if (value && value.includes("http"))
      return (
        <AnchorInline href={value} target="_blank" rel="noreferrer">
          View
        </AnchorInline>
      );
    return value;
  };

  return (
    address && (
      <Wrapper>
        <CardHeading>Primary Location</CardHeading>

        <MapLink
          href={
            exists(lat) && exists(lon)
              ? getMapFromCoords(lat, lon)
              : getMapFromAddress(formatAddressAsString(address))
          }
          target="_blank"
          rel="noreferrer">
          <NoteText key={id} bold>
            <span>{line1}</span>
            <br />
            {line2 && line2 !== "" && (
              <>
                <span>{line2}</span>
                <br />
              </>
            )}
            <span>
              {city} {state}
              {zipCode && `, ${zipCode}`}
            </span>
          </NoteText>
        </MapLink>

        {addressDetails?.map(
          ({key, value}) =>
            key &&
            value && (
              <Detail key={key} fullWidth={fullWidth}>
                <DetailLabel fullWidth={fullWidth}>{key}</DetailLabel>
                <Notes fullWidth={fullWidth}>
                  <NoteText bold>
                    <Abbr title={value}>{getNoteValue(value)}</Abbr>
                  </NoteText>
                </Notes>
              </Detail>
            )
        )}
        {children}
      </Wrapper>
    )
  );
};

PrimaryAddress.propTypes = {
  details: PropTypes.objectOf(PropTypes.any).isRequired,
  fullWidth: PropTypes.bool,
  children: PropTypes.node
};

PrimaryAddress.defaultProps = {
  fullWidth: false,
  children: null
};

// Style Overrides
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-width: 330px;
  padding: ${pad}px;
  border: ${border} solid ${props => props.theme.secondary};
  border-radius: ${radius};
  margin: 0 0 ${pad}px 0;

  ${bp(3)} {
    min-width: 100%;
  }
`;

const CardHeading = styled(HeadingSmall)`
  ${voice.weak};
`;

const Detail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: ${pad}px 0;

  &:last-child {
    margin-bottom: 0;
  }

  p {
    color: ${props => props.theme.secondary};
  }

  ${props =>
    props.fullWidth &&
    css`
      flex-direction: column;
    `}
`;

const MapLink = styled(Anchor)`
  z-index: ${z("above")};
  position: relative;
  background: none;
  padding: ${pad}px 0 0;
`;

const DetailLabel = styled(Text)`
  width: 40%;
  padding-right: ${pad}px;
  text-overflow: ellipsis;
  overflow: hidden;

  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
      padding: 0;
      overflow: inherit;
    `}
`;

const Notes = styled.div`
  display: block;
  height: 100%;

  ${props =>
    props.fullWidth &&
    css`
      width: 100%;
    `}
`;

const NoteText = styled(Text)`
  display: block;
  color: ${props => props.theme.secondary};
  word-wrap: ${props => (props.wrap ? "break-word" : "normal")};
  text-overflow: ${props => (props.wrap ? "unset" : "ellipsis")};
  white-space: ${props => (props.wrap ? "unset" : "nowrap")};
  overflow: hidden;
`;

export default PrimaryAddress;

// Layout
export const pad = 10;
export const navHeight = 75;
export const navWidth = 70;
export const border = "2px";
export const radius = "5px";
export const transition = "all ease 0.2s";
export const shadow = "0 5px 10px rgb(0 0 0 / 20%)";
export const thickShadow = "0 10px 20px rgb(0 0 0 / 40%)";
export const shadowTop = "0 -5px 10px rgb(0 0 0 / 20%)";
export const shadowRight = "0 5px 10px 5px rgb(0 0 0 / 20%)";
export const thickShadowTop = "0 -10px 20px rgb(0 0 0 / 40%)";

// Colors
export const colors = {
  // hero
  heroWhite: "#fafafa",
  heroBlack: "#2D2E2E",
  heroGreen: "#AAD03E",
  // pallet
  grayLight: "#e3e3e3",
  gray: "#818181",
  grayDark: "#C4C4C4",
  grayDarker: "#636363",
  blue: "#003855",
  blueLight: "#326185",
  blueDarker: "#24445D",
  green: "#90a959",
  oceanGreen: "#59C9A5",
  mossGreen: "#3F562A",
  yellow: "#eed202",
  orange: "#FF7900",
  red: "#da2c38",
  coral: "#EF6F6C",
  indBlue: "#465775",
  aqua: "#56E39F",
  feldgrau: "#5B6C5D",
  coffee: "#775334",
  brownSugar: "#B86F52",
  steelTeal: "#548687",
  rhythmn: "#777DA7",
  indigoDye: "#003f5c",
  purpleNavy: "#6D63AC",
  mulberry: "#bc5090",
  copperRed: "#D1603D"
};

export const labels = [
  colors.coral,
  colors.indBlue,
  colors.aqua,
  colors.oceanGreen,
  colors.feldgrau
];

export const lines = [
  colors.indigoDye,
  colors.purpleNavy,
  colors.mulberry,
  colors.copperRed,
  colors.steelTeal
];

export const builder = {
  field: colors.rhythmn,
  component: colors.steelTeal,
  group: colors.brownSugar
};

export const status = {
  success: colors.green,
  error: colors.red,
  warning: colors.yellow,
  alert: colors.orange
};

// Themes
export const heroTheme = {
  primary: colors.heroGreen,
  primaryDark: colors.heroGreen,
  secondary: colors.heroBlack,
  secondaryDark: colors.heroWhite,
  tertiary: colors.heroWhite,
  tertiaryDark: colors.heroBlack,
  info: colors.heroBlack,
  ...builder,
  ...status
};

export const light = {
  name: "light",
  primary: heroTheme.primary,
  secondary: heroTheme.secondary,
  tertiary: heroTheme.tertiary,
  info: colors.heroBlack,
  highlight: heroTheme.primary,
  ...builder,
  ...status
};

export const dark = {
  name: "dark",
  primary: heroTheme.primaryDark,
  secondary: heroTheme.secondaryDark,
  tertiary: heroTheme.tertiaryDark,
  info: colors.heroBlack,
  ...builder,
  ...status
};

import React, {useContext} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
// eslint-disable-next-line import/extensions
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

// Utils
import {AuthContext} from "../../contexts/auth.js";
import useApi from "../../hooks/useApi.js";

// Components
import {Form, SettingsRowWrapper} from "../../style/components/general.js";
import {AccountRow, InfoText, Save} from "./components.js";
import InputPassword from "../../components/form/InputPassword.js";
import {bp} from "../../style/components/breakpoints.js";

const Security = ({edit, setEdit}) => {
  const {currentUser} = useContext(AuthContext);

  const {api: apiResetPassword} = useApi("reset-password");

  const securitySchema = yup.object().shape({
    currentPassword: yup.string().required("Please provide your current password."),
    newPassword: yup
      .string()
      .required("Please provide your new password")
      .min(6, "Must be longer than 6 characters")
  });

  const securityForm = useForm({
    resolver: yupResolver(securitySchema)
  });

  const resetPassword = ({currentPassword, newPassword}) => {
    apiResetPassword
      .callPut("", {
        publicId: currentUser.publicId,
        password: currentPassword,
        new: newPassword
      })
      .then(() => setEdit(false));
  };

  return (
    <FormProvider {...securityForm}>
      <Form onSubmit={securityForm.handleSubmit(resetPassword)} noValidate>
        <SettingsRowWrapper>
          <AccountRow label="User Type">
            {currentUser.type && <InfoText>{currentUser.type.name}</InfoText>}
          </AccountRow>
          <AccountRow label="Password">
            {edit ? (
              <FieldWrapper>
                <InputPassword name="currentPassword" placeholder="Current Password" />
              </FieldWrapper>
            ) : (
              <InfoText>**********</InfoText>
            )}
          </AccountRow>
          {edit && (
            <AccountRow label="New Password">
              <FieldWrapper>
                <InputPassword name="newPassword" placeholder="New Password" />
              </FieldWrapper>
            </AccountRow>
          )}
        </SettingsRowWrapper>
        {edit && <Save type="submit">Update</Save>}
      </Form>
    </FormProvider>
  );
};

Security.propTypes = {
  edit: PropTypes.bool.isRequired,
  setEdit: PropTypes.func.isRequired
};

// Style Overrides

const FieldWrapper = styled.div`
  width: 90%;
  ${bp(2)} {
    width: 100%;
  }
`;

export default Security;

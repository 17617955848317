import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble, faMailBulk, faPhone} from "@fortawesome/free-solid-svg-icons";

// Components
import InputError from "./InputError.js";
import Help from "../Help.js";

// Style
import {pad} from "../../style/components/variables.js";
import {voice} from "../../style/components/typography.js";
import {
  FormFieldWrapper,
  Label,
  Text,
  Button,
  Input,
  Anchor,
  Abbr,
  Pill
} from "../../style/components/general.js";

const InputConfirm = ({
  testId,
  name,
  label,
  prompt,
  disabled,
  required,
  phone,
  email,
  condition,
  tag
}) => {
  const {
    setValue,
    trigger,
    watch,
    register,
    formState: {errors}
  } = useFormContext();

  const checked = watch(name);

  const [confirmed, setConfirmed] = useState(disabled);

  useEffect(() => {
    if (checked) setConfirmed(true);
  }, [checked]);

  return (
    <FormFieldWrapper data-testid={testId}>
      {label && (
        <LabelWrapper htmlFor={name} bold inline>
          {label.toUpperCase()}*
          {tag && (
            <Pill quiet>
              <Abbr title={tag}>{tag}</Abbr>
            </Pill>
          )}
          {condition && <Help icon={<FontAwesomeIcon icon={faCheckDouble} />}>{condition}</Help>}
        </LabelWrapper>
      )}

      {prompt && (
        <Prompt data-testid={`${testId}-prompt`} quiet>
          {prompt}
        </Prompt>
      )}

      <ConfirmWrapper>
        <ConfirmButton
          id={name}
          type="button"
          disabled={disabled}
          onClick={() => {
            setConfirmed(prev => !prev);
            setValue(name, confirmed ? "" : "confirmed");
            if (!confirmed) trigger(name);
          }}
          success={confirmed}>
          {confirmed ? "Confirmed" : "Confirm"}
        </ConfirmButton>

        <Input
          type="text"
          id={name}
          disabled={disabled || confirmed}
          hidden
          {...register(name, {required: required})}
        />

        {phone?.number && (
          <ConfirmLink href={`tel:${phone.number}`} data-testid={`${testId}-phone`}>
            <Abbr title={phone.number}>
              <FontAwesomeIcon icon={faPhone} />
              &nbsp;{phone.number}&nbsp;{phone.extension && `ext.${phone.extension}`}
            </Abbr>
          </ConfirmLink>
        )}

        {email && (
          <ConfirmLink href={`mailto:${email}`} data-testid={`${testId}-email`}>
            <Abbr title={email}>
              <FontAwesomeIcon icon={faMailBulk} />
              &nbsp;{email}
            </Abbr>
          </ConfirmLink>
        )}

        <InputError errors={errors} name={name} />
      </ConfirmWrapper>
    </FormFieldWrapper>
  );
};

InputConfirm.propTypes = {
  testId: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  prompt: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  phone: PropTypes.objectOf(PropTypes.any),
  email: PropTypes.string,
  condition: PropTypes.string,
  tag: PropTypes.string
};

InputConfirm.defaultProps = {
  testId: "input-confirm",
  type: "text",
  label: null,
  prompt: "",
  required: false,
  disabled: false,
  phone: null,
  email: null,
  condition: undefined,
  tag: undefined
};

// Style Overrides
const Prompt = styled(Text)`
  margin-bottom: ${pad / 2}px;
`;

const ConfirmWrapper = styled.div`
  display: flex;
`;

const ConfirmButton = styled(Button)`
  padding: 0 ${pad / 2}px;
  height: fit-content;

  ${({success, theme}) =>
    success &&
    css`
      color: ${theme.success};
      background: 0;
    `}
`;

const ConfirmLink = styled(Anchor)`
  ${voice.quiet};
  padding: ${pad / 2}px;
  margin-left: ${pad}px;
  height: min-content;
  color: ${({theme}) => theme.secondary};
`;

const LabelWrapper = styled(Label)`
  gap: ${pad / 2}px;
  margin-bottom: 2px;
`;

export default InputConfirm;

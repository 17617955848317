// Utils
import {EMPTY_TABLE_HEIGHT} from "../../utils/builder.js";

// Classes
import Column from "./Column.js";
import Group from "./Group.js";

const LEFT_COLUMN = 0;
const RIGHT_COLUMN = 1;

export default class Report {
  groups;
  updater;

  constructor(groups, updater) {
    if (groups) this.groups = groups.map(group => new Group({group}));
    else this.groups = [new Group()];

    if (updater) this.updater = updater;
  }

  setUpdater(updater) {
    this.updater = updater;
  }

  getMaxHeight() {
    let maxHeight = 0;
    for (let i = 0; i < this.groups.length; i++) {
      const group = this.groups[i];
      const groupMaxHeight = group.getMaxHeight();
      if (groupMaxHeight && groupMaxHeight > maxHeight) maxHeight = groupMaxHeight;
    }
    return maxHeight;
  }

  getTotalHeight() {
    let height = 0;
    for (let i = 0; i < this.groups.length; i++) {
      const group = this.groups[i];
      height += group.getHeight();
    }
    return height;
  }

  insertRegion(region) {
    const {height, headers, fullWidth, hasSecondInput} = region;
    let tableAdded = false;
    let lastGroupFull = false;
    let groupIdx;
    const isFullWidth = (headers && Object.keys(headers).length > 4) || fullWidth || hasSecondInput;
    if (!isFullWidth)
      for (groupIdx = 0; groupIdx < this.groups.length; groupIdx++) {
        const group = this.groups[groupIdx];
        if (group.columns.length === 1) lastGroupFull = true;
        else lastGroupFull = false;
        const column = group.hasEmptyFit(height || EMPTY_TABLE_HEIGHT);
        if (column) {
          tableAdded = true;
          column.addToColumn(region);
          break;
        }
      }
    if (!tableAdded) {
      if (lastGroupFull || isFullWidth) this.groups.push(new Group({region}));
      else {
        const group = this.groups[this.groups.length - 1];
        const leftColumn = group.columns[LEFT_COLUMN];
        const rightColumn = group.columns[RIGHT_COLUMN];
        if (leftColumn.getHeight() > rightColumn.getHeight()) rightColumn.addToColumn(region);
        else leftColumn.addToColumn(region);
      }
    }
    if (this.updater) this.updater();
  }

  getEmpty() {
    const group = this.groups
      .map((g, i) => [g, i])
      .filter(([g]) => g.hasEmptyFit(EMPTY_TABLE_HEIGHT, null))[0];
    return group || [];
  }

  isEmpty() {
    const empty = [];
    this.groups.map(({columns}) =>
      columns.map(({rows}) => {
        const nonEmptyRegions = rows.filter(row => row && row.region);
        if (nonEmptyRegions.length > 0) empty.push(nonEmptyRegions);
      })
    );
    return empty.length === 0;
  }

  filterEmptyGroups() {
    this.groups = this.groups.filter(({columns}) => {
      const nonEmptyColumns = columns.filter(({rows}) => {
        const nonEmptyRegions = rows.filter(row => row && row.region);
        return nonEmptyRegions.length > 0;
      });
      return nonEmptyColumns.length > 0;
    });
    if (this.groups.length === 0) this.groups = [new Group()];
  }

  getColumn(groupIdx, columnIdx) {
    const group = this.groups[groupIdx];
    if (group && group.columns.length === 2) {
      const column = group.columns[columnIdx];
      if (column) return column;
    } else {
      const newColumn = new Column();
      const newGroup = new Group();
      if (columnIdx === LEFT_COLUMN || !columnIdx) newGroup.setColumns([newColumn, new Column()]);
      else if (columnIdx === RIGHT_COLUMN) newGroup.setColumns([new Column(), newColumn]);

      if (groupIdx === 0) this.groups = [newGroup, ...this.groups];
      else this.groups.push(newGroup);
      return newColumn;
    }
    return null;
  }

  extractRegion(groupIdx, columnIdx, rowIdx) {
    const group = this.groups[groupIdx];
    if (group) {
      const column = group.columns[columnIdx];
      if (column) {
        const [row] = column.rows.splice(rowIdx, 1);
        if (row) return row;
      }
    }
    return null;
  }

  moveRegion(srcGroup, srcColumn, srcIdx, destGroup, destColumn, destIdx) {
    const movedItem = this.extractRegion(srcGroup, srcColumn, srcIdx);
    const column = this.getColumn(destGroup, destColumn);
    if (column) column.insertToColumn(movedItem, destIdx);
  }

  reorder(src, dest) {
    this.moveRegion(src.group, src.column, src.row, dest.group, dest.column, dest.row);
    if (this.updater) this.updater();
  }

  swapGroups(src, dest) {
    const movedItem = this.groups[src];
    this.groups[src] = this.groups[dest];
    this.groups[dest] = movedItem;
    if (this.updater) this.updater();
  }

  convertToFull(group, groupIdx, columnIdx, rowIdx) {
    const columnToRemoveFrom = group.columns[columnIdx];
    const otherColumn = group.columns[columnIdx === LEFT_COLUMN ? RIGHT_COLUMN : LEFT_COLUMN];

    const regionTop = columnToRemoveFrom.getRegionTop(rowIdx);
    const regionToMakeFull = columnToRemoveFrom.getFromColumn(rowIdx);
    const [aboveSplitRemoveFrom, belowSplitRemoveFrom] = columnToRemoveFrom.splitColumn(rowIdx);
    const [aboveSplitOther, belowSplitOther] = otherColumn.splitColumnAtHeight(regionTop);

    let topGroup = null;
    if (aboveSplitOther || aboveSplitRemoveFrom) {
      topGroup = new Group();
      const leftColumn = new Column();
      const rightColumn = new Column();
      if (columnIdx === LEFT_COLUMN) {
        leftColumn.setRows(aboveSplitRemoveFrom);
        rightColumn.setRows(aboveSplitOther);
      } else {
        leftColumn.setRows(aboveSplitOther);
        rightColumn.setRows(aboveSplitRemoveFrom);
      }
      topGroup.setColumns([leftColumn, rightColumn]);
    }

    let bottomGroup = null;
    if (belowSplitOther || belowSplitRemoveFrom) {
      bottomGroup = new Group();
      const leftColumn = new Column();
      const rightColumn = new Column();
      if (columnIdx === LEFT_COLUMN) {
        leftColumn.setRows(belowSplitRemoveFrom);
        rightColumn.setRows(belowSplitOther);
      } else {
        leftColumn.setRows(belowSplitOther);
        rightColumn.setRows(belowSplitRemoveFrom);
      }
      bottomGroup.setColumns([leftColumn, rightColumn]);
    }

    const fullGroup = new Group({region: regionToMakeFull.region});

    let offset = 0;
    if (topGroup) {
      this.groups.splice(groupIdx, 1, topGroup);
      offset++;
    }
    this.groups.splice(groupIdx + offset, !offset ? 1 : 0, fullGroup);
    offset++;
    if (bottomGroup) this.groups.splice(groupIdx + offset, 0, bottomGroup);
  }

  convertToHalf(group, groupIdx) {
    const column = group.columns[LEFT_COLUMN];
    const nextGroup = this.groups[groupIdx + 1];
    const prevGroup = this.groups[groupIdx - 1];
    let converted = false;

    if (nextGroup && nextGroup.columns.length === 2) {
      const [emptyColumn, emptyColumnIdx] = nextGroup.getEmptyColumn();
      if (emptyColumn) {
        const otherColumn =
          nextGroup.columns[emptyColumnIdx === LEFT_COLUMN ? RIGHT_COLUMN : LEFT_COLUMN];
        group.setColumns([column, otherColumn]);
        this.groups.splice(groupIdx + 1, 1);
        converted = true;
      }
    }

    if (!converted && prevGroup && prevGroup.columns.length === 2) {
      const [emptyColumn, emptyColumnIdx] = prevGroup.getEmptyColumn();
      if (emptyColumn) {
        const otherColumn =
          nextGroup.columns[emptyColumnIdx === LEFT_COLUMN ? RIGHT_COLUMN : LEFT_COLUMN];
        group.setColumns([otherColumn, column]);
        this.groups.splice(groupIdx - 1, 1);
        converted = true;
      }
    }

    if (!converted) group.setColumns([column, new Column()]);
  }

  retrieveRow(id) {
    for (let i = 0; i < this.groups.length; i++) {
      const group = this.groups[i];
      if (group)
        for (let j = 0; j < group.columns.length; j++) {
          const column = group.columns[j];
          if (column)
            for (let k = 0; k < column.rows.length; k++) {
              const row = column.rows[k];
              if (row?.id === id) return {row, groupIdx: i, columnIdx: j, rowIdx: k};
            }
        }
    }
    return null;
  }

  retrieveRegion(id) {
    const {row} = this.retrieveRow(id);
    return row?.region;
  }

  deleteRegion(id) {
    const {groupIdx, columnIdx, rowIdx} = this.retrieveRow(id);
    const group = this.groups[groupIdx];
    const column = group.columns[columnIdx];
    column.rows.splice(rowIdx, 1);
    if (this.updater) this.updater();
  }

  updateRegion(id, update) {
    const {row, groupIdx, columnIdx, rowIdx} = this.retrieveRow(id);

    const group = this.groups[groupIdx];
    row.updateRegion(update);
    if (
      group.columns?.length > 1 &&
      ((update?.headers && Object.keys(update.headers).length > 4) ||
        update.fullWidth ||
        update.hasSecondInput)
    )
      this.convertToFull(group, groupIdx, columnIdx, rowIdx);
    if (
      group.columns?.length === 1 &&
      (!update?.headers || Object.keys(update.headers).length <= 3) &&
      !update.fullWidth &&
      !update.hasSecondInput
    )
      this.convertToHalf(group, columnIdx, rowIdx);

    if (this.updater) this.updater();
  }

  extractRegions() {
    const regions = [];
    this.groups.map(group => {
      if (group)
        group.columns.map(column => {
          if (column)
            column.rows.map(row => {
              if (row) {
                const region = {...row.region};
                delete region.data;
                delete region.height;
                region.rowId = row.id;
                regions.push(region);
              }
            });
        });
    });
    return regions;
  }

  /**
   * Remove data from Report Elements
   * @returns {object} report "template" groups without data
   */
  getGroupTemplates() {
    // deep copy
    const template = JSON.parse(JSON.stringify(this.groups));
    template.map(group => {
      if (group)
        group.columns.map(column => {
          if (column)
            column.rows.map(row => {
              if (row) {
                delete row.region.data;
                delete row.region.height;
                row.region.rowId = row.id;
              }
            });
        });
    });
    return template;
  }

  replaceRegions(regionMap) {
    this.groups.map(group => {
      if (group)
        group.columns.map(column => {
          if (column)
            column.rows.map(row => {
              if (row && row.id in regionMap) {
                row.updateRegion(regionMap[row.id]);
              }
            });
        });
    });
    if (this.updater) this.updater();
  }

  reset() {
    this.groups = [new Group()];
  }
}

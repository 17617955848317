import React, {useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Utils
import {defaultUnacceptableParameterPrompt, FIELD} from "../../utils/builder.js";
import {formatFormulaToSave, getAncestryName, validateFormula} from "./helpers.js";
import {exists} from "../../utils/helpers.js";

// Components
import Modal from "../../components/Modal.js";
import SearchSelect from "../../components/SearchSelect.js";
import Help from "../../components/Help.js";
import {
  InputCheck,
  InputError,
  InputNumber,
  InputText,
  InputTextArea
} from "../../components/form/FormInputs.js";

// Style
import {flex} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {pad, radius} from "../../style/components/variables.js";
import {
  Abbr,
  Button,
  Error,
  Form,
  FormField,
  FormGroup,
  HeadingCenter,
  Inline,
  Label,
  Line,
  Pill,
  Text
} from "../../style/components/general.js";

const ModalElapsed = ({
  visible,
  setVisible,
  targetElement,
  save,
  builder,
  mode,
  hasBackButton,
  goBack
}) => {
  const isMounted = useMountedState();

  const {api: apiFieldTags} = useApi("field-tags");

  const [allOptions, setAllOptions] = useState(null);
  const [results, setResults] = useState([]);
  const [target, setTarget] = useState(mode === "edit" ? targetElement : null);
  const [fieldTags, setFieldTags] = useState();
  const [tagResults, setTagResults] = useState();

  const defaultValues =
    mode === "edit"
      ? {
          ...targetElement,
          addMode: "Select Field"
        }
      : {
          label: "",
          units: "hours",
          hasARange: false,
          aMin: null,
          aMax: null,
          rangeException: defaultUnacceptableParameterPrompt,

          hasRange: false,
          min: null,
          max: null,
          restrictedRangeException: "",
          hasSetPoint: false,
          setLow: null,
          setHigh: null,
          absoluteValue: false,
          hasQualifier: false,
          hidden: false,
          hasDivideByZeroDefault: false,
          divideByZeroDefault: null,
          formula: [
            {
              value: targetElement.name,
              type: "variable",
              previous: false
            }
          ],
          addMode: "Select Field"
        };

  const schema = yup.object().shape({
    label: yup.string().required("Required"),
    tag: yup.string().nullable(),
    units: yup.string().required("Required"),
    formula: yup
      .array()
      .of(
        yup.object().shape({
          value: yup.string().required(),
          type: yup.string().required(),
          previous: yup.bool().nullable()
        })
      )
      .test({
        message: "Cannot submit: invalid formula",
        test: value => validateFormula(value, builder, targetElement)
      }),
    hasARange: yup.boolean(),
    aMin: yup
      .mixed()
      .nullable()
      .when("hasARange", {
        is: val => !!val,
        then: () =>
          yup
            .number()
            .transform((v, o) => (o === "" ? null : v))
            .nullable()
            .typeError("Min must be a number.")
            .test({
              message: "Either min or max must be provided.",
              test: (aMin, ctx) => exists(aMin) || exists(ctx.parent.aMax)
            })
      }),
    aMax: yup
      .mixed()
      .nullable()
      .when("hasARange", {
        is: val => !!val,
        then: () =>
          yup
            .number()
            .transform((v, o) => (o === "" ? null : v))
            .nullable()
            .typeError("Max must be a number.")
            .test({
              message: "Invalid range, please make sure that max value is greater than the min.",
              test: (aMax, ctx2) =>
                exists(aMax) && exists(ctx2.parent.aMin) ? aMax > ctx2.parent.aMin : true
            })
      }),
    rangeException: yup.string(),
    hasRange: yup.boolean(),
    min: yup
      .mixed()
      .nullable()
      .when("hasRange", {
        is: val => !!val,
        then: () =>
          yup
            .number()
            .transform((v, o) => (o === "" ? null : v))
            .nullable()
            .typeError("Min must be a number.")
            .test({
              message: "Either min or max must be provided.",
              test: (min, ctx) => exists(min) || exists(ctx.parent.max)
            })
      }),
    max: yup
      .mixed()
      .nullable()
      .when("hasRange", {
        is: val => !!val,
        then: () =>
          yup
            .number()
            .transform((v, o) => (o === "" ? null : v))
            .nullable()
            .typeError("Max must be a number.")
            .test({
              message: "Invalid range, please make sure that max value is greater than the min.",
              test: (max, ctx2) =>
                exists(max) && exists(ctx2.parent.min) ? max > ctx2.parent.min : true
            })
      }),
    restrictedRangeException: yup.string(),
    hasSetPoint: yup.boolean(),
    setLow: yup
      .string()
      .nullable()
      .when("hasSetPoint", {
        is: val => !!val,
        then: () => yup.number().typeError("Set point min must be a number.").required()
      }),
    setHigh: yup
      .string()
      .nullable()
      .when(["hasSetPoint", "setLow"], {
        is: (hasRange, setLow) => hasRange && !Number.isNaN(setLow),
        then: () =>
          yup
            .number()
            .typeError("Set point max must be a number.")
            .test({
              message:
                "Invalid range, please make sure that max value is greater than the set point min.",
              test: (setHigh, ctx2) => setHigh > ctx2.parent.setLow
            })
      }),
    absoluteValue: yup.boolean(),
    hasQualifier: yup.boolean(),
    hidden: yup.boolean(),
    hasDivideByZeroDefault: yup.boolean(),
    divideByZeroDefault: yup
      .string()
      .nullable()
      .when("hasDivideByZeroDefault", {
        is: val => !!val,
        then: () =>
          yup.number().typeError("Default must be a number.").required("Default is required.")
      })
  });

  const form = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  });
  const {
    watch,
    setValue,
    handleSubmit,
    reset,
    formState: {errors, submitCount}
  } = form;

  const watchHasAcceptable = watch("hasARange");
  const watchRMin = watch("min");
  const watchRMax = watch("max");
  const watchFieldTag = watch("tag");

  const defaultRestrictedRangeException = useMemo(() => {
    if (exists(watchRMin) && exists(watchRMax))
      return `Leaving this blank defaults to: 'Value must be > ${watchRMin}' or 'Value must be < ${watchRMax}'`;
    if (exists(watchRMin)) return `Leaving this blank defaults to: 'Value must be > ${watchRMin}'`;
    if (exists(watchRMax)) return `Leaving this blank defaults to: 'Value must be < ${watchRMax}'`;
    return "Override default violation message";
  }, [watchRMin, watchRMax]);

  // Initial Load
  useEffect(() => {
    if (isMounted() && allOptions === null) {
      const timeFields = Object.values(builder.byId)
        .filter(el => el.element === FIELD && el.type === "time" && el.name !== targetElement.name)
        .flatMap(el => [
          {
            label: getAncestryName(el, builder),
            value: el.name,
            fieldLabel: el.label
          }
        ]);
      setAllOptions(timeFields);
    }
  }, [isMounted, allOptions, builder, targetElement]);

  useEffect(() => {
    if (!fieldTags)
      apiFieldTags.callGet().then(({status, data}) => {
        if (status && data) {
          setFieldTags(data);
          setTagResults(data);
        }
      });
  }, [fieldTags, apiFieldTags]);

  const search = query => {
    if (query) {
      const lower = query.toLowerCase();
      const tempResults = allOptions.filter(option =>
        option.fieldLabel.toLowerCase().includes(lower)
      );
      tempResults.sort(
        (a, b) =>
          a.fieldLabel.toLowerCase().indexOf(lower) - b.fieldLabel.toLowerCase().indexOf(lower)
      );
      setResults(tempResults);
    } else setResults(allOptions);
  };

  const addToFormula = field => {
    setTarget(field);
    const {value} = field;
    setValue("formula", [
      {
        value,
        type: "variable",
        previous: false
      },
      {label: "-", value: "-", type: "operation"},
      {
        value: targetElement.name,
        type: "variable",
        previous: false
      }
    ]);
  };

  const submission = values => {
    const formatted = formatFormulaToSave(builder, mode, targetElement, values);
    save(formatted, builder);
    reset({});
    if (hasBackButton && goBack) goBack();
    else setVisible(false);
  };

  return (
    <Modal visible={visible} setVisible={setVisible} hasBackButton={hasBackButton} goBack={goBack}>
      <HeadingCenter>{mode === "edit" ? "Edit" : "Create"} Elapsed Time Field</HeadingCenter>
      <FormProvider {...form}>
        <Form noValidate onSubmit={handleSubmit(submission)}>
          <div>
            <Label bold>FIELD TYPE</Label>
            <Text>GENERATED</Text>
          </div>
          <FormField data-testid="formula.labelContainer">
            <InputText
              name="label"
              label="Field Name"
              placeholder="Please provide a name to represent the value collected."
              testId="formula.label"
              required
            />
          </FormField>
          {mode === "create" && allOptions && (
            <FormField>
              <SearchSelect
                label="Target Field"
                results={results}
                setResults={setResults}
                search={search}
                add={addToFormula}
                showAll
              />
              {errors?.formula && <Error>{errors.formula.message}</Error>}
            </FormField>
          )}
          {targetElement?.type === "generated" && mode === "edit" && (
            <StyledInline bold>
              TIME ELAPSED between {targetElement.formula[0].label} and&nbsp;
              {targetElement.formula[2].label}.
            </StyledInline>
          )}
          {targetElement?.type === "time" && (
            <StyledInline bold>
              TIME ELAPSED between {targetElement.label} and
              {target ? (
                <Selection onClick={() => setTarget(null)}>
                  {target.label}&nbsp;
                  <FontAwesomeIcon icon={faClose} />
                </Selection>
              ) : (
                ".."
              )}
              .
            </StyledInline>
          )}

          {fieldTags?.length > 0 && (
            <FormField>
              {!watchFieldTag ? (
                <SearchWrapper>
                  <SearchSelect
                    testId="addField.tags"
                    label="Tag"
                    placeholder="Search tags..."
                    results={tagResults}
                    setResults={setTagResults}
                    search={query => {
                      if (!query) setTagResults(fieldTags);
                      else
                        setTagResults(prev =>
                          prev.filter(({name: tagName}) =>
                            tagName.toLowerCase().includes(query.toLowerCase())
                          )
                        );
                    }}
                    add={selected =>
                      setValue("tag", typeof selected === "string" ? selected : selected.name)
                    }
                    showAll
                    allowNew
                  />
                </SearchWrapper>
              ) : (
                <SelectedContainer data-testid="addField.tag-selected">
                  <Abbr>{watchFieldTag}</Abbr>
                  &nbsp;
                  <IconButton
                    onClick={() => setValue("tag", null, {shouldValidate: !!submitCount})}>
                    <FontAwesomeIcon icon={faClose} />
                  </IconButton>
                </SelectedContainer>
              )}
              <InputError name="tag" errors={errors} />
            </FormField>
          )}
          <>
            <InputCheck minWidth="90%" name="hasRange" testId="addField.hasRange">
              Provide restricted min-max range?
            </InputCheck>
            {watch("hasRange") && (
              <FormGroup>
                <FormField>
                  <InputNumber name="min" placeholder="Min" testId="addField.restrictedMin" />
                </FormField>
                <FormField>
                  <InputNumber name="max" placeholder="Max" testId="addField.restrictedMax" />
                </FormField>

                <FormField>
                  <InputTextArea
                    name="restrictedRangeException"
                    placeholder={defaultRestrictedRangeException}
                    required={false}
                    testId="addField.restrictedRangeException"
                  />
                </FormField>
              </FormGroup>
            )}
          </>
          <FormGroup>
            <InputCheck minWidth="90%" name="hasARange">
              <Text inline>
                Enable unacceptable parameter alert
                <Help>
                  When entry is out of acceptable range a note will be required and an email
                  notification will be sent.
                </Help>
              </Text>
            </InputCheck>
            {watchHasAcceptable && (
              <FormGroup>
                <Inline>
                  <InputNumber name="aMin" placeholder="Min" />
                  <Line width={15} />
                  <InputNumber name="aMax" placeholder="Max" />
                </Inline>
                <Label>Provide prompt for explanation of unacceptable parameter?</Label>
                <InputTextArea
                  name="rangeException"
                  placeholder={targetElement["rangeException"]}
                  required={false}
                />
              </FormGroup>
            )}
            <InputCheck name="hidden">Hide field?</InputCheck>
          </FormGroup>

          <Button type="submit" data-testid="formula.save">
            Save
          </Button>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalElapsed.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
  targetElement: PropTypes.objectOf(PropTypes.any).isRequired,
  save: PropTypes.func.isRequired,
  builder: PropTypes.objectOf(PropTypes.any).isRequired,
  mode: PropTypes.string,
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func,
  hasPrimaryAddress: PropTypes.bool
};

ModalElapsed.defaultProps = {
  tags: [],
  mode: "create",
  hasBackButton: false,
  goBack: () => {},
  hasPrimaryAddress: false
};

// Style Overrides
const Selection = styled.button`
  color: ${({theme}) => theme.tertiary};
  background: ${({theme}) => theme.secondary};
  border-radius: ${radius};
  padding: ${pad / 2}px;
  width: fit-content;
  margin: 0 ${pad / 3}px 0 ${pad / 2}px;
  ${voice.quiet};

  svg {
    fill: ${({theme}) => theme.tertiary};
  }
`;

const StyledInline = styled(Inline)`
  ${flex("row", "wrap", "start", "center")}

  gap: 0;

  text-align: left;
  color: ${({theme}) => theme.secondary};
  ${voice.normal};
`;

const SelectedContainer = styled(Pill)`
  width: min-content;
  height: min-content;
  max-width: 100%;
  margin: 0 ${pad}px ${pad}px 0;
  color: ${({theme}) => theme.tertiary};
`;

const IconButton = styled(Button)`
  ${voice.normal};
  background-color: transparent;
  width: min-content;
  padding: 0;

  svg {
    fill: ${({theme}) => theme.secondary};
  }
`;

const SearchWrapper = styled.div`
  max-width: 200px;
`;

export default ModalElapsed;

import React, {useContext} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Utils
import {NotificationContext} from "../../contexts/notify.js";
import useApi from "../../hooks/useApi";
import {useSocket} from "../../contexts/socket.js";

// Components
import Modal from "../../components/Modal";

// Style
import {pad} from "../../style/components/variables";
import {Button, Heading, Text} from "../../style/components/general";

import {EVENT_MODIFIED} from "../general/Room.js";
import {formatDate} from "../../utils/helpers.js";

const ModalEventDelete = ({visible, setVisible, goBack, event, getEvents}) => {
  const {facility, id, name, type, frequency, dateDue, addition} = event;

  const socket = useSocket();

  const {getNotifications} = useContext(NotificationContext);

  const {api: apiEvents} = useApi("events");

  const deleteEvent = targetDate => {
    let params = null;
    if (targetDate) params = {data: {addition: targetDate}};
    apiEvents.callDelete(id, params).then(({status}) => {
      if (status === 200) {
        setVisible(false);
        getNotifications(facility.slug);
        getEvents();
        socket.emit(EVENT_MODIFIED, facility.slug, id, "delete", event.type.name);
      }
    });
  };

  return (
    <Modal visible={visible} setVisible={setVisible} goBack={goBack} hasBackButton>
      <Heading center>{name}</Heading>

      <ConfirmDelete>
        {addition?.map(({date}) => date).includes(formatDate(dateDue)) ? (
          <>
            <Text>
              Are you sure you want to delete this addition to the {frequency.name}&nbsp;
              {type.name}? This event will no longer be available and this action cannot be undone.
            </Text>
            <ConfirmDeleteButton type="button" onClick={() => deleteEvent(dateDue)}>
              Confirm Delete
            </ConfirmDeleteButton>
          </>
        ) : (
          <>
            <Text>
              Are you sure you want to delete this{" "}
              {frequency ? "recurring event (all occurrences will be deleted)" : "event"}? This
              action cannot be undone.
            </Text>
            <ConfirmDeleteButton type="button" onClick={() => deleteEvent()}>
              Confirm Delete
            </ConfirmDeleteButton>
          </>
        )}
      </ConfirmDelete>
    </Modal>
  );
};

ModalEventDelete.propTypes = {
  setVisible: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  goBack: PropTypes.func.isRequired,
  event: PropTypes.objectOf(PropTypes.any).isRequired,
  getEvents: PropTypes.func.isRequired
};

// Style Overrides

const ConfirmDelete = styled.div`
  margin-top: ${pad}px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ConfirmDeleteButton = styled(Button)`
  margin-top: ${pad / 2}px;
  background: ${props => props.theme.error};
`;

export default ModalEventDelete;

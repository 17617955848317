import React, {
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
  Fragment,
  useMemo
} from "react";
import {useReactToPrint} from "react-to-print";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faPrint,
  faFileExport,
  faBoxArchive,
  faCodeCompare,
  faFilter
} from "@fortawesome/free-solid-svg-icons";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";

// Utils
import useMountedState from "../../hooks/useMountedState.js";
import useApi from "../../hooks/useApi.js";

// Contexts
import {AuthContext} from "../../contexts/auth.js";
import {SettingsContext} from "../../contexts/settings.js";
import {FacilityNavContext} from "../../contexts/facilitynav.js";
import {useToast} from "../../contexts/toast.js";

// Utils
import {startExport} from "../../utils/export.js";
import {formatSubmissions, getAncestors} from "../../utils/builder.js";
import {isMobileSize} from "../../utils/responsive.js";
import {
  getMapFromCoords,
  openLink,
  prettyDateInUserTimezone,
  prettyDateWithDayInUserTimezone
} from "../../utils/helpers.js";

// Components
import Modal from "../../components/Modal.js";
import RenderSubmission from "./RenderSubmission.js";
import RenderChecksheet from "../checksheet-builder/RenderChecksheet.js";
import InputText from "../../components/form/InputText.js";
import InputCheck from "../../components/form/InputCheck.js";
import InputCheckGroup from "../../components/form/InputCheckGroup.js";
import Accordion from "../../components/Accordion.js";
import Weather from "../../components/Weather.js";
import Badge from "../../components/Badge.js";
import ModalFilter from "../checksheet-builder/ModalFilter.js";

// Style
import {border, colors, pad, radius} from "../../style/components/variables.js";
import {bp, breakpoint} from "../../style/components/breakpoints.js";
import {flex} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {
  Abbr,
  Button,
  ButtonLoader,
  Form,
  FormField,
  FormGroup,
  Heading,
  Inline,
  Label,
  Loader,
  NotLoaded,
  scrollbar,
  Small,
  Text,
  Title
} from "../../style/components/general.js";

const ViewRecord = ({
  visible,
  setVisible,
  record,
  checksheet,
  updateData,
  editing,
  hasBackButton,
  goBack
}) => {
  const {addToast} = useToast();

  const {allIds, byId} = useMemo(() => checksheet?.builder || {}, [checksheet]);

  const isMounted = useMountedState();

  const hasNotes = useRef(
    Object.keys(record.postedNotes || {}).length > 0 ||
      Object.keys(record.archivedNotes || {}).length > 0 ||
      Object.keys(record.resolvedNotes || {}).length > 0
  );

  const headerRef = useRef();
  const weatherRef = useRef();
  const recordRef = useRef();
  const notesRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => {
      const printBody = document.createElement("div");
      printBody.style.pageBreakInside = "avoid";

      if (weatherRef?.current) {
        const weatherClone = weatherRef.current.cloneNode(true);
        printBody.appendChild(weatherClone);
      }

      const headerClone = headerRef.current.cloneNode(true);
      printBody.appendChild(headerClone);

      if (hasNotes.current) {
        const recordNotesWrapper = document.createElement("div");
        recordNotesWrapper.style.display = "flex";
        recordNotesWrapper.style.justifyContent = "start";
        recordNotesWrapper.style.flexWrap = "nowrap";
        recordNotesWrapper.style.gap = "10px";

        const notesClone = notesRef.current.cloneNode(true);
        const recordClone = recordRef.current.cloneNode(true);

        const recordWrapper = document.createElement("div");
        recordWrapper.style.width = "65%";

        recordWrapper.appendChild(recordClone);

        const notesWrapper = document.createElement("div");
        notesWrapper.style.width = "34%";
        notesWrapper.style.marginTop = "55px";

        notesWrapper.appendChild(notesClone);

        recordNotesWrapper.appendChild(recordWrapper);
        recordNotesWrapper.appendChild(notesWrapper);

        printBody.appendChild(recordNotesWrapper);
      } else {
        const recordClone = recordRef.current.cloneNode(true);
        printBody.appendChild(recordClone);
      }

      return printBody;
    }
  });

  const {currentUser, atLeast, roleCanAccessResource} = useContext(AuthContext);
  const {facility} = useContext(FacilityNavContext);
  const {settings} = useContext(SettingsContext);

  const [edit, setEdit] = useState(editing);
  const [exporting, setExporting] = useState(false);
  const [options] = useState(
    record && record.submission && record.submission.responses && byId
      ? Object.keys(record.submission.responses)
          .filter(key => key in byId)
          .map(key => ({
            name: `${getAncestors(byId[key].parentName, byId)} ${byId[key].label}`,
            value: key
          }))
      : []
  );
  const [loading, setLoading] = useState(false);
  const [refreshNotes, setRefreshNotes] = useState(false);
  const [viewMode, setViewMode] = useState("record");
  const [versions, setVersions] = useState(null);
  const [currentVersion, setCurrentVersion] = useState(null);
  const [versionsOpen, setVersionsOpen] = useState({});
  const [viewModification, setViewModification] = useState(false);
  const [isOriginal, setIsOriginal] = useState(false);
  const [isCurrent, setIsCurrent] = useState(true);
  const [fieldTags, setFieldTags] = useState([]);
  // Filters
  const [filters, setFilters] = useState(null);
  const [filterResult, setFilterResult] = useState(null);
  const [showModalFilter, setShowModalFilter] = useState(false);
  // Export
  const [loadingExport, setLoadingExport] = useState(false);
  const [exportError, setExportError] = useState();

  const exportAllowed = useRef();

  const {api: apiChecksheetRecords} = useApi("checksheet-records");
  const {api: apiNotes} = useApi("record-notes");
  const {api: apiFiles} = useApi("files");
  const {api: apiRecords} = useApi("checksheet-records", {suppress: {success: false, error: true}});
  const {api: apiVersions} = useApi("checksheet-record-versions");
  const {api: apiFieldTags} = useApi("field-tags");

  const schema = yup.object().shape({
    exportName: yup.string().required(),
    help: yup.boolean(),
    fields: yup.lazy(() =>
      options && options.length === 1
        ? yup.string().nullable()
        : yup.array().nullable().of(yup.string())
    )
  });

  const initialValues = {
    exportName: "",
    help: false,
    fields: null
  };

  const form = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(schema)
  });
  const {handleSubmit, reset} = form;

  const {submission} = record;

  const filterCount = useMemo(() => {
    let count = 0;
    if (filters?.types?.length) count += filters.types.length;
    if (filters?.tags?.length) count += filters.tags.length;
    return count;
  }, [filters]);

  const handleEdit = (values, outOfRange = null, outOfRangeMultiple = null, draft = false) => {
    setLoading(true);

    const {completedAt} = values;
    const responses = {...values};
    delete responses.completedAt;

    const formatted = formatSubmissions(responses, checksheet.builder);

    const edited = {
      submission: {
        name: submission.name,
        frequency: submission.frequency,
        responses: formatted
      },
      userId: currentUser.publicId,
      draft,
      completedAt: completedAt || null,
      outOfRange: outOfRange ? Object.keys(outOfRange) : null,
      outOfRangeMultiple: outOfRangeMultiple ? Object.keys(outOfRangeMultiple) : null
    };

    apiChecksheetRecords.callPut(record.id, edited).then(({status}) => {
      if (status === 200) {
        updateData();
        setLoading(false);
        setVisible(false);
      } else setLoading(false);
    });
  };

  const handleResolve = (noteId, group) => {
    apiNotes
      .callPut(noteId, null, {
        params: {userId: currentUser.publicId, status: "resolved"},
        headers: {"Content-Type": "multipart/form-data"}
      })
      .then(({status}) => {
        if (status === 200) {
          if (record.postedNotes[group]) {
            const temp = record.postedNotes[group].filter(note => note.id === noteId);
            record.postedNotes[group] = record.postedNotes[group].filter(
              note => note.id !== noteId
            );
            if (record.postedNotes[group].length === 0) delete record.postedNotes[group];
            if (temp && temp.length > 0) {
              if (group in record.resolvedNotes) record.resolvedNotes[group].push(temp[0]);
              else record.resolvedNotes[group] = [temp[0]];
            }
          }

          updateData();
        }
      });
  };

  // pass key of version, builds state object that determines accordion open state
  const buildVersionsOpenObj = useCallback(
    key =>
      Object.fromEntries([
        ...versions.map((_v, i) => [i, i === key]),
        ["createdAt", key === "createdAt"],
        ["archivedAt", key === "archivedAt"],
        ["draftAt", key === "draftAt"]
      ]),
    [versions]
  );

  const buildVersionsClosedObj = () =>
    Object.fromEntries([
      ...versions.map((_v, i) => [i, false]),
      ["createdAt", false],
      ["archivedAt", false],
      ["draftAt", false]
    ]);

  useEffect(() => {
    if (!visible) setEdit(false);
  }, [setVisible, visible]);

  useEffect(() => {
    if (refreshNotes) {
      setRefreshNotes(false);
      updateData();
    }
  }, [refreshNotes, updateData]);

  // Load Initial Versions
  useEffect(() => {
    apiVersions.callGet(null, {recordId: record.id}).then(({status, data}) => {
      if (isMounted() && status === 200 && data?.versions) setVersions(data.versions);
    });
    apiFieldTags.callGet().then(({status, data}) => {
      if (isMounted() && status === 200)
        setFieldTags(data.map(({id, name}) => ({id, name, label: name.toUpperCase()})));
    });
  }, [isMounted, apiVersions, apiFieldTags, record]);

  useEffect(() => {
    if (versions?.length > 0) {
      setVersionsOpen(buildVersionsOpenObj(0));
      setCurrentVersion(versions[0]);
    } else if (versions) setVersionsOpen(buildVersionsOpenObj("createdAt"));
  }, [versions, buildVersionsOpenObj]);

  // Handle filtering
  useEffect(() => {
    if (filters?.types || filters?.tags) {
      setFilterResult(
        Object.fromEntries(
          Object.entries(byId).filter(
            ([, {tag, type}]) =>
              filters.types?.includes(type) || (tag && filters.tags?.includes(tag))
          )
        )
      );
    } else {
      setFilters(null);
      setFilterResult(null);
    }
  }, [filters, allIds, byId]);

  const openFile = fileId =>
    apiFiles.callGet(fileId).then(({status, data}) => status === 200 && openLink(data.link));

  // Check if export is available
  useEffect(() => {
    if (!exportAllowed?.current)
      apiRecords
        .callPatch(null, {
          facilityChecksheetRecordId: record.id,
          exportName: "test",
          help: true
        })
        .then(res => {
          exportAllowed.current = res.status === 200;
          if (res.status !== 200) setExportError(res);
        });
  }, [apiRecords, record.id]);

  const createExport = async values => {
    setLoadingExport(true);
    const {exportName, fields, help} = values;
    const params = {
      facilityChecksheetRecordId: record.id,
      exportName,
      help: help || false
    };

    if (fields?.length > 0) params["fields"] = fields.toString();

    apiRecords
      .callPatch(null, params)
      .then(res => {
        const {status, data} = res;
        if (status === 200 && data) {
          const rows = [];
          const {body, header} = data;
          body.map(row => {
            const temp = {};
            row.map(pair => {
              temp[pair.key] = pair.value;
            });
            rows.push(temp);
          });

          startExport("csv", exportName, rows, header);
          setVisible(false);
          reset({...initialValues});
        } else addToast(res, "error");
      })
      .finally(() => setLoadingExport(false));
  };

  const buildArchivedVersion = () => (
    <Accordion
      label={prettyDateWithDayInUserTimezone(record.archivedAt, settings.timezone)}
      handleOpen={() => {
        if (versionsOpen["archivedAt"]) setVersionsOpen(buildVersionsOpenObj("archivedAt"));
        else setVersionsOpen(buildVersionsClosedObj());

        setCurrentVersion(null);
        setIsCurrent(true);
      }}
      toggleOpen={versionsOpen["archivedAt"]}
      disabled={edit}>
      <AccordionContent>
        {record.archivedBy && (
          <VersionBy>
            Archived by {record.archivedBy.firstName} {record.archivedBy.lastName}
          </VersionBy>
        )}
      </AccordionContent>
    </Accordion>
  );

  const buildDraftVersion = () => (
    <Accordion
      label={prettyDateWithDayInUserTimezone(record.createdAt, settings.timezone)}
      handleOpen={() => {
        if (versionsOpen["draftAt"]) {
          setVersionsOpen(buildVersionsOpenObj("draftAt"));
          setIsCurrent(false);
        } else {
          setVersionsOpen(buildVersionsClosedObj());
          setIsCurrent(true);
        }
        setCurrentVersion(null);
      }}
      toggleOpen={versionsOpen["draftAt"]}
      disabled={edit}>
      <AccordionContent>
        {record.user && (
          <VersionBy>
            Incomplete submission created by {record.user.firstName} {record.user.lastName}
          </VersionBy>
        )}
      </AccordionContent>
    </Accordion>
  );

  const orderDraftAndArchive = () => {
    if (record.draft && record.archived && record.createdAt && record.archivedAt) {
      if (dayjs(record.createdAt).isBefore(dayjs(record.archivedAt)))
        return (
          <>
            {buildArchivedVersion()}
            {buildDraftVersion()}
          </>
        );
      return (
        <>
          {buildDraftVersion()}
          {buildArchivedVersion()}
        </>
      );
    }

    if (record.draft && record.createdAt) return buildDraftVersion();

    if (record.archived && record.archivedAt) return buildArchivedVersion();

    return null;
  };

  const buildNotesDisplay = (notes, canResolve) => {
    const groups = Object.keys(notes).filter(group => checksheet.builder.allIds.includes(group));
    groups.sort(
      (a, b) => checksheet.builder.allIds.indexOf(a) - checksheet.builder.allIds.indexOf(b)
    );

    return groups.map(group => (
      <Fragment key={group}>
        <Label bold>{byId[group].label}</Label>
        {notes[group].map(note => (
          <NoteWrapper
            key={`${byId[group].name}_note_${note.id}`}
            data-testid="preview.noteWrapper">
            <Message>{note.message}</Message>
            {note.coords && (
              <AddressLink
                href={getMapFromCoords(note.coords.lat, note.coords.lon)}
                target="_blank"
                rel="noreferrer">
                View in Maps
              </AddressLink>
            )}
            {note.files && note.files.length > 0 && (
              <Files>
                <hr />
                <p>Attached Files</p>
                {note.files.map(file => (
                  <FileLink onClick={() => openFile(file.id)} key={`file-${file.id}`}>
                    {file.label}
                  </FileLink>
                ))}
              </Files>
            )}
            {note.user && <Author>By: {note.user}</Author>}

            {canResolve && (
              <ResolveButton
                data-testid="resolveButton"
                onClick={() => handleResolve(note.id, group)}>
                Resolve
              </ResolveButton>
            )}
          </NoteWrapper>
        ))}
      </Fragment>
    ));
  };

  if (showModalFilter)
    return (
      <ModalFilter
        visible={showModalFilter}
        setVisible={setShowModalFilter}
        filters={filters}
        setFilters={setFilters}
        tags={fieldTags}
        hasBackButton
        goBack={() => setShowModalFilter(false)}
      />
    );

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      isChecksheetPreview
      clickOutsideToClose={false}
      maxWidth={breakpoint.width[5]}
      hasBackButton={hasBackButton}
      goBack={goBack}>
      <Row>
        <HeadingWrapper>
          <Heading>Checksheet Submission</Heading>

          <Info ref={headerRef}>
            <Status status={record.status} archived={record.archived}>
              {record.archived && (
                <Abbr title="Archived">
                  <FontAwesomeIcon icon={faBoxArchive} />
                  &nbsp;
                </Abbr>
              )}
              {record.status}
            </Status>
            {record.conflict && (
              <Conflict>
                <Abbr title="This record has conflicting versions. Please contact system administrator to resolve.">
                  CONFLICT
                </Abbr>
              </Conflict>
            )}
            <span>
              {record.dateDue && (
                <MobileBlock>
                  <strong>DATE&nbsp;DUE</strong>:&nbsp;{isMobileSize() && <br />}
                  {prettyDateWithDayInUserTimezone(record.dateDue, settings.timezone)}
                </MobileBlock>
              )}
              <br />
              <br />
              {record.completedAt && (
                <MobileBlock>
                  <strong>DATE&nbsp;COMPLETED</strong>:&nbsp;{isMobileSize() && <br />}
                  {prettyDateInUserTimezone(
                    record.completedAt,
                    settings.timezone,
                    "MMM D, YYYY"
                  )}{" "}
                  {record.completedBy && (
                    <>
                      by {record.completedBy.firstName}&nbsp;{record.completedBy.lastName}
                    </>
                  )}
                </MobileBlock>
              )}
            </span>
          </Info>
        </HeadingWrapper>
        <StyledInline>
          {atLeast("admin") && !editing && submission && !record.overdue && (
            <SpacedInline>
              {roleCanAccessResource("facility_checksheet_record_version", "export") &&
                !edit &&
                options.length > 0 && (
                  <Export
                    onClick={() => {
                      if (!exportAllowed?.current && exportError) addToast(exportError, "error");
                      else setExporting(prev => !prev);
                    }}
                    error={!exportAllowed?.current}>
                    {!exporting ? (
                      <abbr title="Export">
                        <FontAwesomeIcon icon={faFileExport} />
                      </abbr>
                    ) : (
                      "Cancel"
                    )}
                  </Export>
                )}

              {roleCanAccessResource("facility_checksheet_record_version", "export") &&
                !edit &&
                !exporting && (
                  <Export type="button" onClick={handlePrint}>
                    <abbr title="Print">
                      <FontAwesomeIcon icon={faPrint} />
                    </abbr>
                  </Export>
                )}

              {!edit && (
                <Filters>
                  <FilterButton type="button" onClick={() => setShowModalFilter(true)}>
                    <Badge count={filterCount} offset="14px" color={colors.heroGreen} />
                    <FontAwesomeIcon icon={faFilter} />
                  </FilterButton>
                </Filters>
              )}

              {updateData &&
                roleCanAccessResource("facility_checksheet_record_version", "create") &&
                !facility?.isDeleted &&
                !checksheet.isArchived &&
                !exporting &&
                !viewModification &&
                (!currentVersion || isCurrent) && (
                  <Button type="button" onClick={() => setEdit(prev => !prev)}>
                    {!edit ? (
                      <abbr title="Edit">
                        <FontAwesomeIcon icon={faEdit} />
                      </abbr>
                    ) : (
                      "Cancel"
                    )}
                  </Button>
                )}
            </SpacedInline>
          )}
          <SpacedInline>
            <ModeToggle
              onClick={() => {
                setViewMode(prev => (prev === "record" ? "notes" : "record"));
              }}>
              View {viewMode === "record" ? "Notes" : "Record"}
            </ModeToggle>
            {roleCanAccessResource("facility_checksheet_record_version", "create") &&
              record.overdue && (
                <Button type="button" onClick={() => setEdit(prev => !prev)}>
                  {edit ? "Cancel" : "Complete"}
                </Button>
              )}
          </SpacedInline>
        </StyledInline>
      </Row>

      <Wrapper>
        <PageLeft data-testid="record.left" mode={viewMode}>
          {!edit && submission && !exporting && (
            <RenderSubmission
              ref={recordRef}
              submission={
                currentVersion && !isCurrent ? currentVersion.submission : record.submission
              }
              builder={
                record.overdue && !record.draft && !edit
                  ? {allIds: [], byId: {}}
                  : checksheet.builder
              }
              filterResult={filterResult}
              dateDue={record.dateDue}
              completedAt={record.completedAt}
              startDate={record.startDate ?? record.dateDue}
              draft={record.draft}
              modifications={currentVersion ? currentVersion.modification : null}
              viewModification={viewModification}
              isOriginal={isOriginal}
              submissionName={checksheet.name}
              notifications={checksheet.notifications}
            />
          )}

          <Hide hide={!edit ? 1 : 0}>
            <Inline>
              <Title>{checksheet.name}</Title>
              {submission.frequency && (
                <Frequency>{submission.frequency && submission.frequency.toUpperCase()}</Frequency>
              )}
              {checksheet.draft && <Draft>INCOMPLETE</Draft>}
            </Inline>
            <RenderChecksheet
              task={checksheet}
              taskRecord={record}
              readOnly={false}
              preview={false}
              persistToLocalStorage={editing}
              responses={submission.responses}
              setSubmission={(responses, outOfRange, outOfRangeMultiple) =>
                handleEdit(responses, outOfRange, outOfRangeMultiple)
              }
              setDraft={
                record.overdue
                  ? (responses, outOfRange, outOfRangeMultiple) =>
                      handleEdit(responses, outOfRange, outOfRangeMultiple, true)
                  : null
              }
              cancelFunction={() => setEdit(false)}
              previousSubmission={record.previousSubmission}
              dateDue={record.dateDue}
              draft={record.draft}
              overdue={record.overdue}
              startDate={record.startDate ?? record.dateDue}
              completedAt={record.completedAt}
              completeLoading={loading}
            />
          </Hide>

          {exporting && (
            <FormProvider {...form}>
              <Form onSubmit={handleSubmit(createExport)}>
                <FormGroup>
                  <FormField>
                    <InputText
                      name="exportName"
                      label="Please provide a name for the export."
                      required
                    />
                  </FormField>
                  <FormField>
                    <InputCheck name="help">
                      Include selected help information in export?
                    </InputCheck>
                  </FormField>
                  <FormField>
                    <InputCheckGroup name="fields" label="Target Fields" options={options} all />
                  </FormField>
                </FormGroup>
                <Button type="submit" loading={loadingExport ? 1 : 0}>
                  Export{loadingExport && <ButtonLoader />}
                </Button>
              </Form>
            </FormProvider>
          )}
        </PageLeft>

        {record && (
          <PageRight mode={viewMode}>
            {record.weather && <Weather ref={weatherRef} weather={record.weather} />}
            <NotesWrapper>
              <StyledInline>
                <NotesHeader>History</NotesHeader>
              </StyledInline>
              <hr />
              <VersionsWrapper>
                {versions?.map((version, idx) => (
                  <Accordion
                    testId="record.historyEntry"
                    key={`version-${version.id}`}
                    label={prettyDateWithDayInUserTimezone(version.createdAt, settings.timezone)}
                    handleOpen={() => {
                      setIsOriginal(false);
                      if (versionsOpen[idx]) {
                        setCurrentVersion(null);
                        setVersionsOpen(buildVersionsClosedObj());

                        setIsCurrent(true);
                      } else {
                        setCurrentVersion(version);
                        setVersionsOpen(buildVersionsOpenObj(idx));

                        if (idx === 0) setIsCurrent(true);
                        else setIsCurrent(false);
                      }
                      setViewModification(false);
                    }}
                    toggleOpen={versionsOpen[idx]}
                    disabled={edit && idx !== 0}>
                    <AccordionContent>
                      <VersionBy>
                        {idx === 0 ? "Last " : ""}Modified by {version.user.firstName}{" "}
                        {version.user.lastName}
                      </VersionBy>
                      <Mod
                        type="button"
                        disabled={edit}
                        onClick={() => setViewModification(prev => !prev)}
                        data-testid="record.viewModification"
                        active={viewModification}>
                        <FontAwesomeIcon icon={faCodeCompare} />
                      </Mod>
                    </AccordionContent>
                  </Accordion>
                ))}
                {versions && record.createdAt && !record.overdue && (
                  <Accordion
                    label={prettyDateWithDayInUserTimezone(record.createdAt, settings.timezone)}
                    handleOpen={() => {
                      if (versionsOpen["createdAt"]) {
                        setCurrentVersion(null);
                        setVersionsOpen(buildVersionsClosedObj());
                        setIsOriginal(false);
                        setIsCurrent(true);
                      } else {
                        setCurrentVersion(versions[versions.length - 1]);
                        setIsOriginal(true);
                        setVersionsOpen(buildVersionsOpenObj("createdAt"));
                        if (versions && versions.length === 0) setIsCurrent(true);
                        else setIsCurrent(false);
                      }
                      // must source viewModification version from submission and modification of first version stored,
                      // since we do not store the viewModification submission as a version
                      setViewModification(false);
                    }}
                    toggleOpen={versionsOpen["createdAt"]}
                    disabled={edit && versions.length > 0}>
                    <AccordionContent>
                      {record.user && (
                        <VersionBy>
                          Submission created by {record.user.firstName} {record.user.lastName}
                        </VersionBy>
                      )}
                    </AccordionContent>
                  </Accordion>
                )}
                {versions && orderDraftAndArchive()}
                {record.overdue &&
                  !record.archived &&
                  !record.draft &&
                  versions &&
                  versions.length === 0 && <NoNotes>No history</NoNotes>}
                {!versions && (
                  <StyledNotLoaded>
                    <StyledLoader data-testid="history.loader" />
                  </StyledNotLoaded>
                )}
              </VersionsWrapper>
            </NotesWrapper>

            <NotesWrapper ref={notesRef}>
              <NoteSection>
                <NotesHeader>Posted Notes</NotesHeader>
                <hr />
                {record.postedNotes && Object.keys(record.postedNotes).length > 0 ? (
                  buildNotesDisplay(record.postedNotes, true)
                ) : (
                  <NoNotes>No Posted Notes</NoNotes>
                )}
              </NoteSection>
              <NoteSection>
                <NotesHeader>Resolved Notes</NotesHeader>
                <hr />
                {record.resolvedNotes && Object.keys(record.resolvedNotes).length > 0 ? (
                  buildNotesDisplay(record.resolvedNotes, false)
                ) : (
                  <NoNotes>No Resolved Notes</NoNotes>
                )}
              </NoteSection>
              {atLeast("admin") && record.archivedNotes && record.archivedNotes.length > 0 && (
                <NoteSection>
                  <NotesHeader>Checksheet Archive Note</NotesHeader>
                  <hr />
                  {record.archivedNotes.map(note => (
                    <NoteWrapper
                      key={`archive_note_${note.id}`}
                      data-testid="preview.resolvedWrapper"
                      resolved>
                      {note.name.includes("error") && <Label bold>{note.name}</Label>}
                      <Message>
                        {note.name === "Hero was not implemented at this time"
                          ? note.name
                          : note.message}
                      </Message>
                      {note.files && note.files.length > 0 && (
                        <Files>
                          <hr />
                          <p>Attached Files</p>
                          {note.files.map(file => (
                            <FileLink onClick={() => openFile(file.id)} key={`file-${file.id}`}>
                              {file.label}
                            </FileLink>
                          ))}
                        </Files>
                      )}
                      {note.user && (
                        <>
                          <hr />
                          <Author>By: {note.user}</Author>
                        </>
                      )}
                    </NoteWrapper>
                  ))}
                </NoteSection>
              )}
            </NotesWrapper>
          </PageRight>
        )}
      </Wrapper>
    </Modal>
  );
};

ViewRecord.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  record: PropTypes.objectOf(PropTypes.any).isRequired,
  checksheet: PropTypes.objectOf(PropTypes.any).isRequired,
  updateData: PropTypes.func,
  editing: PropTypes.bool,
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func
};

ViewRecord.defaultProps = {
  hasBackButton: false,
  goBack: null,
  updateData: null,
  editing: false
};

// Style Overrides
const HeadingWrapper = styled.div`
  margin-right: ${pad}px;
`;

const Row = styled.section`
  width: 100%;
  padding: 0 0 ${pad}px;
  margin-bottom: ${pad}px;
  ${flex("row", "wrap", "space-between", "end")};
`;

const Wrapper = styled.article`
  ${flex("row", "wrap-reverse", "start", "start")};
`;

const PageLeft = styled.div`
  position: relative;
  width: 100%;
  border: 2px solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  padding: ${pad * 1.5}px;

  ${({mode}) =>
    mode === "notes" &&
    css`
      display: none;
    `}

  ${bp(3)} {
    display: block;
    width: 65%;
  }
`;

const PageRight = styled.div`
  width: 100%;

  ${({mode}) =>
    mode === "record" &&
    css`
      display: none;
    `}

  ${bp(3)} {
    position: relative;
    display: block;
    width: 35%;
    padding-left: ${pad}px;

    ${({addingNote}) =>
      addingNote &&
      css`
        position: sticky;
        left: 70%;
        top: 60px;
        z-index: 3;
      `}
  }
`;

const Frequency = styled.span`
  text-transform: uppercase;
  padding: 0 ${pad / 2}px;
  border: ${border} solid ${({theme}) => theme.primary};
  border-radius: ${radius};
  background: ${({theme}) => theme.primary};
  color: ${({theme}) => theme.tertiary};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: ${pad}px;
  min-width: min-content;
`;

const Draft = styled.span`
  ${voice.quiet}
  text-transform: uppercase;
  border: ${border} solid ${({theme}) => theme.warning};
  padding: ${pad / 10}px ${pad / 4}px;
  border-radius: 10px;
  background: ${({theme}) => theme.warning};
  color: ${({theme}) => theme.tertiary};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin-left: ${pad}px;
`;

const Conflict = styled.span`
  width: 100px;
  text-align: center;
  text-transform: uppercase;
  padding: ${pad / 5}px ${pad / 2}px;
  border-radius: ${radius};
  color: ${({theme}) => theme.tertiary};
  display: inline-block;
  white-space: nowrap;
  vertical-align: middle;
  margin-right: ${pad}px;
  background-color: ${({theme}) => theme.error};
  ${voice.quiet};
`;

const NotesWrapper = styled.div`
  border: 2px solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  padding: ${pad}px;
  margin-bottom: ${pad}px;

  @media print {
    border: none;
  }
`;

const NoteSection = styled.div`
  margin-bottom: ${pad * 2}px;

  &:last-child {
    margin: 0;
  }
`;

const NoteWrapper = styled.div`
  position: relative;
  border: ${border} solid ${({theme}) => theme.primary};
  border-radius: ${radius};
  padding: ${pad}px;
  margin-bottom: ${pad}px;
  width: 100%;
  flex: 1 auto;

  @media print {
    display: block;
    page-break-after: auto;
    page-break-inside: avoid;
  }

  &:last-child {
    margin: 0;
  }
`;

const NotesHeader = styled.h1`
  ${voice.normal};
  color: ${({theme}) => theme.secondary};
`;

const Info = styled.p`
  padding-bottom: ${pad}px;
  color: ${({theme}) => theme.secondary};

  &:last-child {
    padding: 0;
  }

  @media print {
    display: flex;
    flex-direction: row-reverse;
    align-items: start;
    justify-content: space-between;
    width: 100%;
  }
`;

const NoNotes = styled(Small)`
  margin-left: ${pad / 2}px;
`;

const VersionBy = styled(Small)`
  color: inherit;
`;

const Message = styled(Text)`
  margin-bottom: ${pad}px;
`;

const Files = styled.div`
  text-align: left;
  color: ${({theme}) => theme.secondary};
  margin-bottom: ${pad / 2}px;

  p {
    font-weight: bold;
  }

  a {
    color: ${({theme}) => theme.primary};

    :hover {
      text-decoration: underline;
    }
  }

  ${voice.quiet}
`;

const FileLink = styled(Text)`
  font-weight: bold;
  color: ${({theme}) => theme.primary};
  cursor: pointer;
  ${voice.quiet};

  :hover {
    text-decoration: underline;
  }
`;

const ResolveButton = styled(Button)`
  position: absolute;
  right: ${pad}px;
  margin: 0;
  bottom: 8px;
  ${voice.quiet};

  @media print {
    display: none;
  }
`;

const Author = styled.p`
  ${voice.quiet}
`;

const Export = styled(Button)`
  ${({error}) =>
    error &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

const AddressLink = styled.a`
  color: ${({theme}) => theme.primary};

  p {
    color: ${({theme}) => theme.primary};
  }

  &:hover {
    text-decoration: underline;
  }
`;

const StyledInline = styled(Inline)`
  justify-content: space-between;
  margin-top: ${pad}px;
  width: 100%;

  ${bp(3)} {
    margin-top: 0;
    width: unset;
  }
`;

const SpacedInline = styled(Inline)`
  gap: ${pad}px;
`;

const ModeToggle = styled(Button)`
  ${bp(3)} {
    display: none;
  }
`;

const Status = styled.span`
  ${voice.quiet};
  width: 100px;
  text-align: center;
  text-transform: uppercase;
  padding: ${pad / 5}px ${pad / 2}px;
  border-radius: ${radius};
  color: ${({theme}) => theme.tertiary};
  display: inline-block;
  white-space: nowrap;
  display: block;
  margin-bottom: ${pad}px;

  background: ${({status, theme}) => {
    if (status === "Incomplete") return theme.warning;
    if (status === "Overdue") return theme.error;
    if (status === "Late") return theme.alert;
    return theme.success;
  }};

  ${({archived}) =>
    archived &&
    css`
      opacity: 0.7;
    `}

  svg {
    fill: ${({theme}) => theme.tertiary};
  }

  @media print {
    margin: 0;
  }
`;

const AccordionContent = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 ${pad / 2}px ${pad * 2}px;
  border: ${border} solid ${({theme}) => theme.tertiary};

  &:last-child {
    margin-bottom: 0;
  }
`;

const Mod = styled(Button)`
  ${voice.quiet};
  padding: ${pad / 4}px ${pad / 2}px;
  margin-left: ${pad / 2}px;
  text-align: left;
  pointer-events: ${({disabled}) => (!disabled ? "pointer" : "none")};
  opacity: ${({active}) => (!active ? 1 : 0.5)};
`;

const VersionsWrapper = styled.div`
  max-height: 350px;
  min-height: min-content;
  margin-bottom: ${pad}px;
  overflow-y: auto;
  padding: ${pad / 2}px;
  ${scrollbar}
`;

const Hide = styled.div`
  ${({hide}) =>
    hide &&
    css`
      display: none;
    `}
`;

const StyledNotLoaded = styled(NotLoaded)`
  height: min-content;
  width: calc(100% - 1px);
  margin: ${pad / 2}px 0 0 0;
`;

const StyledLoader = styled(Loader)`
  height: min-content;
  width: calc(100% - 1px);
  margin: 0;
`;

const MobileBlock = styled.span`
  /* display: block; */

  span {
    /* display: block; */
    /* margin-top: ${pad / 2}px; */
  }

  ${bp(2)} {
    /* ${flex("row", "wrap")}
    gap: ${pad / 2}px; */

    div {
      /* margin-top: 0; */
    }
  }
`;

const Filters = styled.div`
  position: relative;
`;

const FilterButton = styled(Button)`
  overflow: initial;
`;

export default ViewRecord;

import React, {useCallback, useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faTriangleExclamation,
  faEdit,
  faTrash,
  faHistory
} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";

// Contexts
import {SettingsContext} from "../../contexts/settings.js";
import {NotificationContext} from "../../contexts/notify.js";
import {AuthContext} from "../../contexts/auth.js";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Utils
import {prettyDateInUserTimezone, prettyDateWithDayInUserTimezone} from "../../utils/helpers.js";

// Components
import Modal from "../../components/Modal.js";
import ModalReview from "./ModalReview.js";
import ModalReviewManage from "./ModalReviewManage.js";
import BaseTable from "../../components/BaseTable.js";
import Pagination from "../../components/Pagination.js";
import Dropdown from "../../components/Dropdown.js";

// Style
import {heroTheme, pad} from "../../style/components/variables.js";
import {
  Abbr,
  Bullet,
  Button,
  Heading,
  Inline,
  Label,
  List,
  ListItem,
  Small,
  TableFooter,
  TableHeaderWrap,
  TableWrapper,
  Text
} from "../../style/components/general.js";

const ModalReviews = ({visible, setVisible, checksheet}) => {
  const {settings} = useContext(SettingsContext);
  const {getNotifications} = useContext(NotificationContext);
  const {atLeast} = useContext(AuthContext);

  const isMounted = useMountedState();

  const {api: apiChecksheet} = useApi("checksheets");
  const {api: apiReviews} = useApi("checksheet-reviews");
  const {api: apiReviewVersions} = useApi("checksheet-review-versions");

  const [currentChecksheet, setCurrentChecksheet] = useState(checksheet);
  const [loading, setLoading] = useState(false);
  const [reviews, setReviews] = useState();
  const [targetReview, setTargetReview] = useState();
  const [versions, setVersions] = useState();
  // Pagination
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageTotal, setPageTotal] = useState(1);
  const [orderBy, setOrderBy] = useState("desc");
  const [groupBy, setGroupBy] = useState("createdAt");
  const [limit, setLimit] = useState(5);
  const [limits, setLimits] = useState([]);
  // Modals
  const [showModalReview, setShowModalReview] = useState(false);
  const [showModalReviewHistory, setShowModalReviewHistory] = useState(false);
  const [showModalReviewManage, setShowModalReviewManage] = useState(false);

  const getReviews = useCallback(() => {
    setLoading(true);
    apiReviews
      .callGet(null, {
        facilityChecksheetId: currentChecksheet.id,
        page: current,
        orderBy,
        groupBy,
        limit
      })
      .then(({status, data}) => {
        if (status === 200 && data) {
          const {total: dataTotal, pages, reviews: dataReviews} = data;

          let count = 10;
          const temp = [];
          while (count < total + 10 && count <= 50) {
            temp.push(count);
            count += 10;
          }

          setReviews(dataReviews);
          setPageTotal(pages);
          setTotal(dataTotal);
          setLimits(temp);
          setLoading(false);
        }
      });
  }, [apiReviews, currentChecksheet, current, groupBy, limit, orderBy, total]);

  // Initial Load
  useEffect(() => {
    if (!reviews) getReviews();
  }, [reviews, getReviews]);

  // Update reviews on sort or limit
  useEffect(() => {
    if (isMounted() && reviews) getReviews();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, orderBy, groupBy, limit]);

  // On Unmount
  useEffect(
    () => () => {
      if (!isMounted()) getNotifications(currentChecksheet.slug);
    },
    [isMounted, getNotifications, currentChecksheet]
  );

  const handleDelete = id =>
    apiReviews.callDelete(id).then(({status}) => {
      if (status === 200) setReviews(undefined);
    });

  const format = useCallback(
    ({
      name: oTitle,
      data: {description: oDescription, limit: oLimit, fields: oFields},
      end: oEnd
    }) => {
      const {name: nTitle, end: nEnd, data} = targetReview;
      const {description: nDescription, limit: nLimit, fields: nFields} = data;

      const modification = [];

      if (oTitle !== nTitle)
        modification.push(
          <span>
            Title from <del>{oTitle}</del> to {nTitle}
          </span>
        );
      if (oDescription !== nDescription)
        modification.push(
          <span>
            Description from <del>{oDescription}</del> to {nDescription}
          </span>
        );
      if (oLimit !== nLimit)
        modification.push(
          <span>
            Limit from <del>{oLimit}</del> to {nLimit}
          </span>
        );
      if (
        oFields.length !== nFields.length ||
        oFields.every((element, index) => element === nFields[index])
      )
        modification.push(
          <span>
            Fields from <del>{oFields.map(({name}) => name).join(", ")}</del> to{" "}
            {nFields.map(({name}) => name).join(", ")}
          </span>
        );
      if (!dayjs(oEnd).isSame(dayjs(nEnd)))
        modification.push(
          <span>
            Expiration from{" "}
            <del>{prettyDateInUserTimezone(oEnd, settings?.timezone, "MMM DD, YYYY")}</del> to{" "}
            {prettyDateInUserTimezone(nEnd, settings?.timezone, "MMM DD, YYYY")}
          </span>
        );

      return modification;
    },
    [targetReview, settings]
  );

  if (showModalReview)
    return (
      <ModalReview
        visible={visible}
        setVisible={setVisible}
        goBack={() => setShowModalReview(false)}
        checksheet={currentChecksheet}
      />
    );

  if (versions && showModalReviewHistory)
    return (
      <Modal
        visible={visible}
        setVisible={setVisible}
        hasBackButton
        goBack={() => setShowModalReviewHistory(false)}>
        <Heading>History</Heading>
        {versions?.length > 0 ? (
          versions.map(({id, user, modification, createdAt}) => (
            <List key={id} column>
              <ListItem>
                <Label bold>
                  {prettyDateInUserTimezone(createdAt, settings?.timezone, "MMM DD, YYYY")}:
                </Label>
                <List column>
                  <Text>
                    {user.firstName} {user.lastName} made the folling changes:
                  </Text>
                  {format(modification)?.map(line => (
                    <Bullet key={line}>{line}</Bullet>
                  ))}
                </List>
              </ListItem>
            </List>
          ))
        ) : (
          <Text>No history for this review.</Text>
        )}
      </Modal>
    );

  if (showModalReviewManage)
    return (
      <ModalReviewManage
        visible={visible}
        setVisible={setVisible}
        goBack={() => {
          setTargetReview(undefined);
          setShowModalReviewManage(false);
        }}
        checksheet={currentChecksheet}
        review={targetReview}
        reload={() => getReviews()}
      />
    );

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <TableWrapper>
        <TableHeaderWrap>
          <Heading>
            <Icon icon={faTriangleExclamation} />
            &nbsp; Required Reviews
          </Heading>
          <Inline>
            {reviews?.length > 0 && (
              <Button
                type="button"
                onClick={() =>
                  apiChecksheet.callGet(checksheet.id).then(({status, data}) => {
                    if (status === 200 && data) {
                      setCurrentChecksheet(data);
                      setShowModalReview(true);
                    }
                  })
                }
                data-testid="tableRecord.preview">
                View Current
              </Button>
            )}
            <Button type="button" onClick={() => setShowModalReviewManage(true)}>
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </Inline>
        </TableHeaderWrap>
        <BaseTable
          headings={{
            name: {header: "Title", disabled: false},
            createdAt: {header: "Created", disabled: false},
            endAfter: {header: "Expiration", disabled: false},
            history: {header: " ", disabled: true},
            edit: {header: " ", disabled: true},
            delete: {header: " ", disabled: true}
          }}
          data={
            reviews
              ? reviews.map(review => {
                  const {id, name, createdAt, updatedAt, endAfter} = review;
                  return {
                    name,
                    createdAt: prettyDateWithDayInUserTimezone(
                      createdAt || updatedAt,
                      settings.timezone,
                      "ddd, MMM D YYYY"
                    ),
                    endAfter: endAfter ? (
                      <Expiration expired={dayjs(endAfter).isBefore(dayjs(), "day")}>
                        {prettyDateWithDayInUserTimezone(
                          endAfter,
                          settings.timezone,
                          "ddd, MMM D YYYY h:mm A"
                        )}
                      </Expiration>
                    ) : null,
                    history: (
                      <Button
                        type="button"
                        onClick={() =>
                          apiReviewVersions.callGet(null, {reviewId: id}).then(({status, data}) => {
                            if (status === 200 && data) {
                              setVersions(data);
                              setShowModalReviewHistory(true);
                              setTargetReview(review);
                            }
                          })
                        }>
                        <Abbr title="History">
                          <FontAwesomeIcon icon={faHistory} />
                        </Abbr>
                      </Button>
                    ),
                    edit: (
                      <Button
                        type="button"
                        onClick={() => {
                          setTargetReview(review);
                          setShowModalReviewManage(true);
                        }}>
                        <Abbr title="Edit">
                          <FontAwesomeIcon icon={faEdit} />
                        </Abbr>
                      </Button>
                    ),
                    delete: atLeast("creator") && (
                      <Delete type="button" onClick={() => handleDelete(id)}>
                        <Abbr title="Delete">
                          <FontAwesomeIcon icon={faTrash} />
                        </Abbr>
                      </Delete>
                    )
                  };
                })
              : null
          }
          updateData={getReviews}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          groupBy={groupBy}
          setGroupBy={setGroupBy}
          loading={loading}
          dataMissing="No reviews have been configured."
        />
        <TableFooter>
          <Inline>
            {total > 10 && limits.length > 0 && (
              <PageLimit>
                <Dropdown
                  options={limits}
                  selection={limit}
                  setSelection={selection => {
                    setLimit(selection);
                    setCurrent(1);
                  }}
                />
              </PageLimit>
            )}
            <Small>
              {pageTotal > 1 && limits.length > 0 && "per page,"} {total}&nbsp;total
            </Small>
          </Inline>
          {pageTotal > 1 && (
            <Pagination
              current={current}
              setCurrent={setCurrent}
              pageTotal={pageTotal}
              updateData={getReviews}
              loading={loading}
            />
          )}
        </TableFooter>
      </TableWrapper>
    </Modal>
  );
};

ModalReviews.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  checksheet: PropTypes.objectOf(PropTypes.any).isRequired
};

// Style Overrides
const Icon = styled(FontAwesomeIcon)`
  fill: ${heroTheme.warning};
`;

const Expiration = styled.span`
  ${({expired, theme}) =>
    expired &&
    css`
      color: ${theme.error};
    `}
`;

const PageLimit = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${pad}px;

  select {
    padding: ${pad / 4}px;
  }
`;

const Delete = styled(Button)`
  background: ${({theme}) => theme.error};
`;

export default ModalReviews;

import React, {useContext, useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";

// Contexts
import {FacilityNavContext} from "../../contexts/facilitynav.js";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Utils
import {getFields} from "../../utils/builder.js";
import {toTitleCase} from "../../utils/helpers.js";
import {getStatOptions} from "../facility/helpers.js";

// Components
import Modal from "../../components/Modal.js";
import Statistic from "../../components/Statistic.js";
import Dropdown from "../../components/Dropdown.js";
import SearchSelect from "../../components/SearchSelect.js";
import {InputSelect, InputText, InputRadioGroup} from "../../components/form/FormInputs";

// Style
import {voice} from "../../style/components/typography.js";
import {pad} from "../../style/components/variables.js";
import {
  Abbr,
  Button,
  ButtonLoader,
  Form,
  FormField,
  Heading,
  Label,
  Loader,
  NotLoaded,
  Pill,
  Text
} from "../../style/components/general.js";

const dropdownOptions = ["None", "Last 7 Days", "Last 30 Days", "Last 90 Days", "Last Year"];

const schema = {
  type: yup.string().required("Please select a type of statistic."),
  name: yup.string().required("Please provide name."),
  sourceType: yup.string().required("Please provide a source type."),
  source: yup.object().required("Please provide a source."),
  sources: yup.string().required("Please select a source")
};

const defaultValues = {
  type: null,
  name: "",
  sourceType: "Average",
  source: null,
  sources: null
};

const ModalNewStatistic = ({visible, setVisible}) => {
  const isMounted = useMountedState();

  const {analytics, setAnalytics, allOrder, facility} = useContext(FacilityNavContext);

  const [sourceOptions, setSourceOptions] = useState(null);
  const [sourcesOptions, setSourcesOptions] = useState(null);
  const [supported, setSupported] = useState(null);
  const [stat, setStat] = useState(null);
  const [snapshot, setSnapshot] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [refreshSources, setRefreshSources] = useState(null);
  const [sourceResults, setSourceResults] = useState([]);

  const form = useForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape(
        {
          ...schema,
          after:
            analytics && analytics.length > 0
              ? yup
                  .number()
                  .transform((v, o) => (o === "" ? null : v))
                  .nullable()
                  .required("Please choose where to display the new statistic.")
              : yup.number().nullable()
        },
        [["sources", "sources"]]
      )
    )
  });
  const {watch, handleSubmit, reset, setValue} = form;
  const sourceType = watch("sourceType");
  const source = watch("source");
  const sources = watch("sources");
  const watchType = watch("type");
  const stage = watch("stage");

  const {api: apiChecksheets} = useApi("checksheets");
  const {api: apiChecksheetRecords} = useApi("checksheet-records");
  const {api: apiEvents} = useApi("events");
  const {api: apiEventRecords} = useApi("event-records");
  const {api: apiAnalytics} = useApi("facility-analytics", {suppress: {error: true}});

  const stageOptions = useMemo(() => {
    if (!source?.stages?.allIds) return null;
    const {byId, allIds} = source.stages;
    return allIds
      .filter(stageId => byId[stageId])
      .map(stageId => {
        const {name} = byId[stageId];
        return {name: stageId, label: name};
      });
  }, [source]);

  const selectedStage = useMemo(() => {
    if (!stage || !source?.stages?.byId || !source?.stages?.byId[stage]) return null;
    const {byId} = source.stages;
    return byId[stage];
  }, [source, stage]);

  // Initial Load
  useEffect(() => {
    if (isMounted() && facility) {
      setSourceOptions(null);
      setSourcesOptions(null);
      setSupported(null);
      setValue("source", null);
      setValue("sources", null);
      setValue("type", null);
      setValue("stage", null);

      const api = sourceType === "Checksheet" ? apiChecksheets : apiEvents;
      api.callGet(null, {facilityId: facility.id}).then(({status, data}) => {
        if (status === 200 && data) {
          setRefreshSources(sourceType);
          setSourceOptions(
            data.map(checksheet => {
              const {name, frequency, group} = checksheet;
              let fullName = frequency?.name
                ? `${toTitleCase(frequency.name)} ${name}`
                : `${name} (optional)`;

              if (group && facility?.builder?.byId) {
                const groupName = facility.builder.byId[group]?.label;
                fullName = groupName ? `${fullName} at ${groupName}` : fullName;
              }

              return {
                ...checksheet,
                name: fullName
              };
            })
          );
        }
      });
    }
  }, [isMounted, sourceType, apiChecksheets, apiEvents, setValue, facility]);

  // Set source on dropdown select
  useEffect(() => {
    if (isMounted() && source && sourceOptions) {
      setValue("sources", null);
      setValue("type", null);
      setValue("stage", null);
    }
  }, [isMounted, source, sourceOptions, setValue]);

  // Get current Checksheet's records to check if there are enough data values
  useEffect(() => {
    if (isMounted() && source && source.id) {
      const api = refreshSources === "Checksheet" ? apiChecksheetRecords : apiEventRecords;
      const typeId = refreshSources === "Checksheet" ? "facilityChecksheetId" : "eventId";
      const params = {[typeId]: source.id};

      api.callGet(null, params).then(({status}) => {
        if (status === 200) {
          const {builder} = source;
          let fields;

          if (selectedStage?.builder) {
            const {allIds, byId} = selectedStage.builder;
            fields = getFields(allIds, byId, ["number", "generated", "weather"]);
          } else if (builder)
            fields = getFields(builder.allIds, builder.byId, ["number", "generated", "weather"]);

          if (fields) {
            const options = fields.map(({ancestry, name}) => ({
              label: ancestry,
              name
            }));

            setSourcesOptions(options);
          } else setSourcesOptions([]);
        }
      });
    }
  }, [isMounted, source, refreshSources, apiChecksheetRecords, apiEventRecords, selectedStage]);

  // Show Stat Options
  useEffect(() => {
    if (source && sources) {
      const {builder} = source;
      let byId;

      if (selectedStage?.builder) {
        ({byId} = selectedStage.builder);
      } else if (builder) ({byId} = builder);

      if (byId) {
        // Handle Rainfall
        const sourceId = sources.includes("_rainfall") ? sources.split("_rainfall")[0] : sources;

        setSupported(getStatOptions(byId[sourceId]));
      }
    }
  }, [source, sources, selectedStage]);

  useEffect(() => {
    if (isMounted() && watchType && supported && sources && source) {
      const typeId = sourceType === "Checksheet" ? "facilityChecksheetId" : "eventId";
      const params = {
        preview: true, // returns data without saving
        [typeId]: source.id,
        sources: [sources],
        type: watchType,
        snapshot: snapshot ?? "none"
      };

      if (sourceType === "Event") params.stageId = stage;

      apiAnalytics.callPost(params).then(({status, data}) => {
        if (status === 200 && data) setStat(data);
      });
    }
  }, [isMounted, watchType, source, sourceType, sources, snapshot, apiAnalytics, supported, stage]);

  const onSubmit = data => {
    setSubmitLoading(true);
    let updated = 1;
    const order = data.after;
    if (order) updated = order + 1;

    const typeId = sourceType === "Checksheet" ? "facilityChecksheetId" : "eventId";
    const params = {
      type: watchType,
      [typeId]: source.id,
      label: data.name,
      sources: [data.sources],
      order: updated,
      snapshot: snapshot ?? "none"
    };

    if (sourceType === "Event") params.stageId = stage;

    apiAnalytics.callPost(params).then(() => {
      setSubmitLoading(false);
      setVisible(false);
      reset({});
      setAnalytics(null);
    });
  };

  const searchSources = query => {
    if (!query) setSourceResults(sourceOptions);
    else
      setSourceResults(
        sourceOptions.filter(s => s.label.toLowerCase().includes(query.toLowerCase()))
      );
  };

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <ModalTitle>Create Statistic</ModalTitle>

      <StyledText>Create a chart from fields on checksheet submissions.</StyledText>

      <FormProvider {...form}>
        <Form data-testid="sourceForm" onSubmit={handleSubmit(onSubmit)} noValidate>
          <FormField>
            <Label htmlFor="name" bold>
              Provide a Label for Statistic.
            </Label>
            <InputText testId="statLabel" name="name" placeholder="Label" required />
          </FormField>
          {analytics && analytics.length > 0 && (
            <FormField>
              <InputSelect
                name="after"
                label="Display After"
                placeholder="Select..."
                options={[{label: "Top of Page", value: "0"}, ...allOrder]}
                fullWidth
              />
            </FormField>
          )}
          <FormField>
            <InputRadioGroup
              name="sourceType"
              options={["Checksheet", "Event"]}
              defaultValue="Checksheet"
              label="Select a source type for plot."
            />
          </FormField>
          {!sourceOptions ? (
            <NotLoaded>
              <Loader />
            </NotLoaded>
          ) : (
            <FormField>
              {!source ? (
                <SearchSelect
                  label={`Select a${
                    sourceType === "Event" ? "n" : ""
                  } ${sourceType.toLowerCase()} as source for plot.`}
                  placeholder={`Select ${sourceType}...`}
                  results={sourceResults}
                  setResults={setSourceResults}
                  search={searchSources}
                  add={val => setValue("source", val)}
                  showAll
                />
              ) : (
                <>
                  <Label bold>{`SELECT A${
                    sourceType === "event" ? "N" : ""
                  } ${sourceType.toLowerCase()} AS SOURCE FOR PLOT.`}</Label>
                  <SourceContainer data-testid="addField.units-selected">
                    <Abbr title={source.name}>{source.name}</Abbr>
                    &nbsp;
                    <IconButton
                      onClick={() => {
                        setSupported(null);
                        setValue("source", null);
                        setValue("sources", null);
                        setValue("stage", null);
                      }}>
                      <FontAwesomeIcon icon={faClose} />
                    </IconButton>
                  </SourceContainer>
                </>
              )}
            </FormField>
          )}

          {sourceType === "Event" && source && (
            <FormField>
              <InputSelect
                name="stage"
                placeholder="Stage..."
                options={stageOptions}
                label="Select a stage."
                required
              />
            </FormField>
          )}

          {(sourceType !== "Event" || !!stage) && (
            <FieldOptionsWrapper>
              {source && sourceOptions === null ? (
                <NotLoaded>
                  <Loader />
                </NotLoaded>
              ) : (
                <FormField>
                  {sourcesOptions && sourcesOptions.length > 0 && (
                    <InputSelect
                      testId="statValue"
                      name="sources"
                      label="Select a field."
                      placeholder="Select..."
                      options={sourcesOptions}
                      required
                    />
                  )}
                </FormField>
              )}
              {sources === null && supported && supported.length === 0 ? (
                <NotLoaded>
                  <Loader />
                </NotLoaded>
              ) : (
                <FormField>
                  {supported && supported.length > 0 && (
                    <InputSelect
                      testId="statType"
                      name="type"
                      label="Select a statistic to evaluate."
                      placeholder="Select..."
                      options={supported}
                      required
                    />
                  )}
                </FormField>
              )}
              {source &&
                sources &&
                (sourceType !== "Event" || !!stage) &&
                supported &&
                supported.length === 0 && (
                  <None>There are no stat types for selected field type, please try another.</None>
                )}
              {sourcesOptions && sourcesOptions.length === 0 && (
                <None>There are not enough submissions to create a stat for this data source.</None>
              )}
              {watchType && (
                <StatWrapper>
                  <Statistic
                    testId="average"
                    label={watch("name")}
                    stat={stat}
                    precision={watchType !== "completionrate" ? 2 : 0}
                  />
                  <Dropdown
                    id="snapshot"
                    testId="snapshotDropdown"
                    name="snapshot"
                    options={dropdownOptions}
                    placeholder="Optionally, select default date range..."
                    setSelection={setSnapshot}
                    selection={snapshot}
                    fullWidth
                    spaced
                  />
                </StatWrapper>
              )}
            </FieldOptionsWrapper>
          )}
          <Submit type="submit" loading={submitLoading ? 1 : 0}>
            Submit{submitLoading && <ButtonLoader />}
          </Submit>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalNewStatistic.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

// Style Overrides
const ModalTitle = styled(Heading)`
  margin: ${pad}px 0;
  text-align: center;
  font-weight: 700;
`;

const StyledText = styled(Text)`
  margin: ${pad * 2}px 0;
  max-width: 700px;
`;

const None = styled(Text)`
  margin: ${pad}px 0 ${pad}px;
`;

const Submit = styled(Button)`
  margin-top: ${pad}px;
`;

const StatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pad}px;
  width: 100%;
`;

const FieldOptionsWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const SourceContainer = styled(Pill)`
  width: min-content;
  height: min-content;
  max-width: 100%;
  margin: ${pad}px ${pad}px 0 0;
  color: ${({theme}) => theme.tertiary};
`;

const IconButton = styled(Button)`
  ${voice.quiet};
  background-color: transparent;
  width: min-content;
  padding: 0;

  svg {
    fill: ${({theme}) => theme.tertiary};
  }
`;

export default ModalNewStatistic;

import React, {useEffect, useLayoutEffect, useMemo, useRef, useState} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import dayjs from "dayjs";
import {useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar} from "@fortawesome/free-solid-svg-icons";
import * as yup from "yup";

// Hooks
import useScrollListener from "../../hooks/useScrollListener.js";
import useMountedState from "../../hooks/useMountedState.js";

// Utils
import Gregorian, {MONTHS, MONTHS_ABBR} from "../../utils/gregorian.js";

// Components
import InputError from "./InputError.js";

// Style
import {voice} from "../../style/components/typography.js";
import {flex, z} from "../../style/components/mixins.js";
import {pad, colors, shadow, radius, border} from "../../style/components/variables.js";
import {
  Arrow,
  Inline,
  Input,
  Label,
  List,
  ListItem,
  Button
} from "../../style/components/general.js";

const greg = new Gregorian(null, 6);

const now = new Date();
now.setHours(0, 0, 0, 0);
const today = now;

export const calendarValidation = yup.object().shape({
  date: yup.date().typeError("Provide valid date.").required("Please provide date."),
  visibleDate: yup.string().nullable(),
  lastDay: yup.bool()
});

export const formatDefaults = (name, date, lastDay = false) =>
  date
    ? {
        [`${name}.date`]: dayjs(date).format("YYYY-MM-DD"),
        [`${name}.visibleDate`]: dayjs(date).format("MM/DD/YYYY"),
        [`${name}.lastDay`]: lastDay
      }
    : {
        [`${name}.date`]: null,
        [`${name}.visibleDate`]: null,
        [`${name}.lastDay`]: null
      };

const InputCalendar = ({name, label, placeholder, min, max, showLastDay, disabled}) => {
  const isMounted = useMountedState();
  const [calendar, setCalendar] = useState(null);
  const [visible, setVisible] = useState(false);
  const [selectMonth, setSelectMonth] = useState(false);
  const [activeYear, setActiveYear] = useState(greg.getActiveYear());

  const {
    register,
    watch,
    setValue,
    trigger,
    formState: {submitCount, errors}
  } = useFormContext();

  const visibleDate = watch(`${name}.visibleDate`);
  const selected = watch(`${name}.date`);
  const lastDay = watch(`${name}.lastDay`);

  const inputElement = useRef();
  const scrolling = useScrollListener(document.getElementById("modal") || window);

  const inputCoords = inputElement.current?.getBoundingClientRect();

  const selectedDate = useMemo(
    () =>
      selected && selected.length === 10 && dayjs(selected).isValid()
        ? new Date(dayjs(selected).toISOString())
        : null,
    [selected]
  );

  const normalize = value => {
    if (!value) return null;
    let digits = value.replace(/[^0-9]/g, "").substring(0, 8);

    let m = "";
    let d = "";
    let y = "";

    if (digits.length) {
      const firstMonthChar = parseInt(digits[0], 10);
      const secondMonthChar = digits[1] ? parseInt(digits[1], 10) : null;
      let monthParsed = parseInt(digits.substring(0, 2), 10);
      if (firstMonthChar > 1 || (secondMonthChar && monthParsed > 12)) {
        m = `0${digits[0]}`;
        digits = digits.substring(1);
      } else {
        m = digits.substring(0, 2);
        digits = digits.substring(2);
      }

      const firstDayChar = digits[0] ? parseInt(digits[0], 10) : null;
      const secondDayChar = digits[1] ? parseInt(digits[1], 10) : null;
      const dayParsed = digits[0] ? parseInt(digits.substring(0, 2), 10) : null;

      monthParsed = parseInt(m, 10);
      const selectedMonth = dayjs().month(monthParsed - 1);
      let daysInMonth = selectedMonth.daysInMonth();
      if (m === "02") daysInMonth = "29";
      const daysInMonthFirstChar = parseInt(`${daysInMonth}`[0], 10);
      if (
        (firstDayChar && firstDayChar > daysInMonthFirstChar) ||
        (secondDayChar && dayParsed > daysInMonth)
      ) {
        d = `0${digits[0]}`;
        digits = digits.substring(1);
      } else {
        d = digits.substring(0, 2);
        digits = digits.substring(2);
      }

      if (m === "02" && d === "29" && digits.length === 4) {
        const lastTwoDigits = parseInt(digits.substring(digits.length - 2), 10);
        if (lastTwoDigits % 4 === 0) y = digits;
        else y = digits.substring(0, 3);
      } else y = digits;
    }

    if (y) return `${m}/${d}/${y}`;
    if (d) return d.length === 2 ? `${m}/${d}/` : `${m}/${d}`;
    if (m && m.length === 2) return `${m}/`;
    return m;
  };

  useLayoutEffect(() => {
    const temp = normalize(visibleDate);
    if (visibleDate && visibleDate !== temp)
      setValue(`${name}.visibleDate`, temp, {shouldDirty: true, shouldValidate: !!submitCount});
    if (temp?.length === 10 && dayjs(temp).isValid()) {
      setValue(`${name}.date`, dayjs(temp).format("YYYY-MM-DD"), {
        shouldDirty: true,
        shouldValidate: !!submitCount
      });
    } else setValue(`${name}.date`, null, {shouldDirty: true, shouldValidate: !!submitCount});
  }, [name, visibleDate, setValue, trigger, submitCount]);

  let initialMonth = 1;
  MONTHS.map((curr, index) => {
    if (curr === greg.getActiveMonth()) initialMonth = index + 1;
  });

  const update = () => {
    setCalendar(greg.getMatrix());
  };

  useEffect(() => {
    if (isMounted() && calendar === null) {
      greg.setView("month");
      update();
    }
  }, [isMounted, calendar]);

  useEffect(() => {
    if (selectedDate) {
      greg.setMonth(selectedDate);
      update();
    }
  }, [selectedDate]);

  useEffect(() => {
    if (!visible) {
      setSelectMonth(false);
      setActiveYear(greg.getActiveYear());
      greg.setMonth(selectedDate || today);
      update();
    }
  }, [visible, selectedDate]);

  useEffect(() => {
    if (selectMonth) setActiveYear(greg.getActiveYear());
  }, [selectMonth]);

  useLayoutEffect(() => {
    setVisible(false);
    inputElement.current.childNodes[0].blur();
  }, [scrolling]);

  const outside = useRef(null);

  useEffect(() => {
    const handleClickOutside = e =>
      outside &&
      outside.current !== null &&
      !outside.current.contains(e.target) &&
      !outside.current.contains(e.target.nextSibling) &&
      setVisible(false);

    if (outside) document.addEventListener("mousedown", handleClickOutside);

    // Cleanup
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [outside]);

  return (
    <Wrapper>
      {label && <Label bold>{label}</Label>}
      <InputWrapper ref={inputElement}>
        <Input
          type="text"
          disabled={disabled}
          onKeyDown={e => {
            const {selectionStart} = e.target;
            const {key} = e;

            if (key === "Backspace")
              if (selectionStart === 3) {
                e.preventDefault();
                const firstPart = visibleDate.substring(0, 1);
                const secondPart = visibleDate.substring(2);
                setValue(`${name}.visibleDate`, `${firstPart}${secondPart}`, {
                  shouldDirty: true,
                  shouldValidate: !!submitCount
                });
              } else if (selectionStart === 6) {
                e.preventDefault();
                const firstPart = visibleDate.substring(0, 4);
                const secondPart = visibleDate.substring(5);
                setValue(`${name}.visibleDate`, `${firstPart}${secondPart}`, {
                  shouldDirty: true,
                  shouldValidate: !!submitCount
                });
              }

            if (key === "Delete") e.preventDefault();
          }}
          placeholder={placeholder || "Date..."}
          autoComplete="off"
          {...register(`${name}.visibleDate`)}
        />
        <CalendarIcon
          onClick={() => {
            if (!disabled) setVisible(true);
          }}>
          <FontAwesomeIcon icon={faCalendar} />
        </CalendarIcon>
        <InputError name={`${name}.date`} errors={errors} />
      </InputWrapper>
      {visible && (
        <CalendarWrapper top={inputCoords?.y} left={inputCoords?.x} ref={outside}>
          <MenuNav>
            <ArrowsWrapper>
              <NavButton
                type="button"
                onClick={() => {
                  if (selectMonth) {
                    setActiveYear(prev => prev - 1);
                  } else {
                    greg.setPrevMonth();
                    update();
                  }
                }}
                data-testid="calendarHelper.backward">
                <Arrow scale={0.8} />
              </NavButton>
              <NavButton
                type="button"
                onClick={() => {
                  if (selectMonth) {
                    setActiveYear(prev => prev + 1);
                  } else {
                    greg.setNextMonth();
                    update();
                  }
                }}
                data-testid="calendarHelper.forward">
                <Arrow rotate="-180deg" scale={0.8} />
              </NavButton>
            </ArrowsWrapper>
            <ActiveMonth type="button" onClick={() => setSelectMonth(prev => !prev)}>
              {!selectMonth ? `${greg.getActiveMonth()} ` : ""}
              {!selectMonth ? greg.getActiveYear() : activeYear}
            </ActiveMonth>
            <Placeholder />
          </MenuNav>
          {selectMonth ? (
            <MonthPicker>
              <List>
                {MONTHS_ABBR.map((abbr, index) => (
                  <ListItem key={abbr}>
                    <MonthButton
                      type="button"
                      onClick={() => {
                        const date = new Date(activeYear, index);
                        greg.setMonth(date);
                        update();
                        setSelectMonth(false);
                      }}
                      active={index + 1 === initialMonth}>
                      {abbr}
                    </MonthButton>
                  </ListItem>
                ))}
              </List>
            </MonthPicker>
          ) : (
            <>
              {calendar?.map((week, x) => (
                <Week key={week[x].name} notfirst={x}>
                  {week.map((day, y) => (
                    <Day
                      key={day.date}
                      day={y}
                      week={x}
                      month={day.date.getMonth() === dayjs(greg.getActive()).month()}
                      notfirst={x}>
                      {x === 0 && <DayName center>{day.name[0]}</DayName>}
                      <DateNumber
                        type="button"
                        onClick={() => {
                          setValue(`${name}.visibleDate`, dayjs(day.date).format("MM/DD/YYYY"), {
                            shouldDirty: true,
                            shouldValidate: !!submitCount
                          });
                          setValue(`${name}.date`, dayjs(day.date).format("YYYY-MM-DD"), {
                            shouldDirty: true,
                            shouldValidate: !!submitCount
                          });
                          setValue(`${name}.lastDay`, false, {
                            shouldDirty: true,
                            shouldValidate: !!submitCount
                          });
                          setVisible(false);
                        }}
                        isToday={dayjs(day.date).isSame(dayjs(), "day", "month", "year")}
                        active={
                          (selectedDate && day.date.getTime() === selectedDate.getTime()) ||
                          (!selectedDate && day.date.getTime() === today.getTime())
                        }
                        disabled={
                          (min && dayjs(day.date).isBefore(dayjs(min))) ||
                          (max && dayjs(day.date).isAfter(dayjs(max)))
                        }>
                        {day.date.getDate()}
                      </DateNumber>
                    </Day>
                  ))}
                </Week>
              ))}
              <ButtonBar>
                <Button
                  type="button"
                  onClick={() => {
                    const current = dayjs();
                    setValue(`${name}.visibleDate`, current.format("MM/DD/YYYY"), {
                      shouldDirty: true,
                      shouldValidate: !!submitCount
                    });
                    setValue(`${name}.date`, current.format("YYYY-MM-DD"), {
                      shouldDirty: true,
                      shouldValidate: !!submitCount
                    });
                    setValue(`${name}.lastDay`, false, {
                      shouldDirty: true,
                      shouldValidate: !!submitCount
                    });
                    setVisible(false);
                  }}>
                  Today
                </Button>
                {showLastDay ? (
                  <LastDay
                    type="button"
                    onClick={() => {
                      const current = greg.getActive() ? dayjs(greg.getActive()) : dayjs();
                      setValue(
                        `${name}.visibleDate`,
                        current.date(current.daysInMonth()).format("MM/DD/YYYY"),
                        {shouldDirty: true, shouldValidate: !!submitCount}
                      );
                      setValue(
                        `${name}.date`,
                        current.date(current.daysInMonth()).format("YYYY-MM-DD"),
                        {shouldDirty: true, shouldValidate: !!submitCount}
                      );
                      setValue(`${name}.lastDay`, true, {
                        shouldDirty: true,
                        shouldValidate: !!submitCount
                      });
                      setVisible(false);
                    }}
                    isLastDay={
                      lastDay && selectedDate?.getMonth() === greg.getActive().getMonth() ? 1 : 0
                    }>
                    Use Last Day of Month
                  </LastDay>
                ) : (
                  <div />
                )}
              </ButtonBar>
            </>
          )}
        </CalendarWrapper>
      )}
    </Wrapper>
  );
};

InputCalendar.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
  showLastDay: PropTypes.bool,
  disabled: PropTypes.bool
};

InputCalendar.defaultProps = {
  label: undefined,
  placeholder: undefined,
  min: undefined,
  max: undefined,
  showLastDay: false,
  disabled: false
};

// Style Overrides
const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const CalendarWrapper = styled.div`
  position: fixed;
  width: 220px;
  height: 270px;
  background-color: ${({theme}) => theme.tertiary};
  border: ${border} solid ${({theme}) => theme.secondary};
  box-shadow: ${shadow};
  padding: 7px 0;
  border-radius: ${radius};

  ${({top}) =>
    top < window.innerHeight / 2
      ? css`
          top: ${top + 38}px;
        `
      : css`
          bottom: ${window.innerHeight - top + 2}px;
        `}
  left: ${({left}) => left}px;

  z-index: ${z("top")};

  button {
    ${voice.quiet}

    ${Arrow} {
      ${voice.quiet}
    }
  }
`;

const Week = styled.div`
  width: 100%;
  height: ${({notfirst}) => (notfirst ? "30px" : "55px")};
  ${flex("row", "wrap", "center", "start")};
`;

const Day = styled.div`
  ${voice.quiet};
  ${flex("column", "nowrap", "start", "center")};
  padding: ${pad}px;
  width: calc(100% / 7);
  max-width: calc(100% / 7);
  height: ${({notfirst}) => (notfirst ? "30px" : "55px")};
  color: ${({theme}) => theme.secondary};
  overflow: hidden;

  ${({month}) =>
    !month
      ? css`
          color: ${colors.grayDarker};
        `
      : ""}
`;

const DateNumber = styled.button`
  text-align: center;
  width: 20px;
  height: 20px;
  padding: 4px 0;
  border-radius: ${radius};

  ${({isToday}) =>
    isToday &&
    css`
      text-decoration: underline;
    `}

  ${({active}) =>
    active
      ? css`
          background-color: ${({theme}) => theme.primary};
          color: ${colors.heroBlack};
        `
      : ""}

  ${({disabled}) =>
    disabled &&
    css`
      opacity: 0.5;
    `}

  :hover {
    cursor: pointer;
    background-color: ${({theme}) => theme.primary};
    color: ${colors.heroBlack};
  }
`;

const DayName = styled.div`
  ${voice.quiet}
  width: 100%;
  margin-bottom: ${pad}px;
  text-align: center;
`;

const InputWrapper = styled.div`
  max-width: 150px;
  position: relative;
`;

const MenuNav = styled.div`
  ${flex("row", "no-wrap", "space-between", "center")};
  margin: 0;
`;

const NavButton = styled.button`
  width: fit-content;
  min-width: 20px;
`;

const ActiveMonth = styled.button`
  width: max-content;
  text-align: left;
  color: ${({theme}) => theme.secondary};
  font-weight: bold;
  ${voice.medium};
`;

const MonthButton = styled.button`
  padding: ${pad}px 0;

  &:hover {
    color: ${({theme}) => theme.primary};
  }

  ${({theme, active}) =>
    active &&
    css`
      color: ${theme.primary};
    `}
`;

const MonthPicker = styled.div`
  position: relative;

  ${List} {
    ${flex("row", "wrap", "center", "center")};
    background: ${({theme}) => theme.tertiary};
    border-radius: ${radius};
    padding: ${pad}px;
    height: 240px;
  }

  ${ListItem} {
    width: 33.33%;
    text-align: center;
    padding: 0;
  }
`;

const ArrowsWrapper = styled(Inline)`
  gap: 0;
  flex: 1;
`;

const Placeholder = styled.div`
  flex: 1;
`;

const CalendarIcon = styled.span`
  position: absolute;
  top: 9px;
  right: ${pad}px;

  svg {
    fill: ${({theme}) => theme.secondary};
  }
`;

const ButtonBar = styled(Inline)`
  justify-content: space-between;
  margin-top: 7px;
  width: 100%;
  padding: 0 ${pad / 2}px;

  ${Button} {
    padding: ${pad / 2}px;
    margin: 0;
  }
`;

const LastDay = styled(Button)`
  ${({isLastDay}) =>
    isLastDay &&
    css`
      opacity: 0.5;
    `}
`;

export default InputCalendar;

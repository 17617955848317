import React, {useEffect, useContext} from "react";
import PropTypes from "prop-types";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

// Utils
import {AuthContext} from "../../contexts/auth.js";
import useApi from "../../hooks/useApi.js";

// Components
import {AccountRow, InfoText, Save, InfoItem, InfoLabel} from "./components.js";
import {InputText, InputPhoneGroup, InputCheck} from "../../components/form/FormInputs.js";
import PhoneNumbers from "../../components/PhoneNumbers.js";

// Style
import {Form, SettingsRowWrapper} from "../../style/components/general.js";

const defaultValues = {
  firstName: "",
  lastName: "",
  email: "",
  primary: {
    id: "",
    number: "",
    extension: "",
    type: "mobile",
    primary: true
  },
  phones: [
    {
      id: "",
      number: "",
      extension: "",
      type: "mobile",
      primary: false
    }
  ]
};

const UserDetails = ({edit, setEdit}) => {
  const {currentUser, getCurrentUser} = useContext(AuthContext);
  const {api: apiUsers} = useApi("users");

  const accountSchema = yup.object().shape({
    firstName: yup.string().required("Please provide first name"),
    lastName: yup.string().required("Please provide last name"),
    email: yup.string().email("Invalid email address").required("Please provide email"),
    phones: yup
      .array()
      .nullable()
      .when("sms", {
        is: true,
        then: () =>
          yup.array().of(
            yup.object().shape({
              number: yup
                .string()
                .matches(/^\(\d{3}\)\s\d{3}-\d{4}/i, {
                  message: "Please provide valid phone #.",
                  excludeEmptyString: true
                })
                .required("Phone number is required."),
              extension: yup.string().nullable(),
              type: yup.string().required("Type is required."),
              primary: yup.bool()
            })
          )
      }),
    sms: yup.bool()
  });

  const accountForm = useForm({
    defaultValues,
    resolver: yupResolver(accountSchema)
  });
  const {reset} = accountForm;

  // On update
  useEffect(() => {
    if (currentUser) {
      const {firstName, lastName, email, primary, phones, details} = currentUser;
      reset({
        firstName,
        lastName,
        email,
        sms: !!details?.sms,
        primary: primary?.number
          ? primary
          : {
              id: "",
              number: "",
              extension: "",
              type: "mobile",
              primary: true
            },
        phones:
          phones?.length > 0
            ? phones
            : [
                {
                  id: "",
                  number: "",
                  extension: "",
                  type: "mobile",
                  primary: true
                }
              ]
      });
    }
  }, [currentUser, reset]);

  const saveUserDetails = values => {
    const {phones} = values;
    const userParams = {...values, phones: null, userRoleId: currentUser?.role?.id};
    delete userParams.primary;
    if (phones && phones.length > 0 && phones[0].number) userParams["phones"] = phones;
    apiUsers.callPut(currentUser.publicId, userParams).then(() => {
      getCurrentUser();
      setEdit(false);
    });
  };

  return (
    <FormProvider {...accountForm}>
      <Form onSubmit={accountForm.handleSubmit(saveUserDetails)} noValidate>
        <SettingsRowWrapper>
          <AccountRow label="First Name">
            {edit ? (
              <InputText name="firstName" placeholder="First Name" />
            ) : (
              <InfoText>{currentUser.firstName}</InfoText>
            )}
          </AccountRow>
          <AccountRow label="Last Name">
            {edit ? (
              <InputText name="lastName" placeholder="Last Name" />
            ) : (
              <InfoText>{currentUser.lastName}</InfoText>
            )}
          </AccountRow>
          <AccountRow label="Email">
            {edit ? (
              <InputText name="email" placeholder="Email" />
            ) : (
              <InfoText>{currentUser.email}</InfoText>
            )}
          </AccountRow>
          <AccountRow label="Phone(s)">
            {edit ? (
              <InputPhoneGroup name="phones" limit={3} />
            ) : (
              <div>
                {currentUser.primary && (
                  <PhoneNumbers primary={currentUser.primary} phones={currentUser.phones} />
                )}
              </div>
            )}
          </AccountRow>
          <AccountRow label="SMS Notifications">
            {edit ? (
              <InputCheck name="sms">Enable</InputCheck>
            ) : (
              <InfoText>{currentUser.details?.sms ? "Enabled" : "Disabled"}</InfoText>
            )}
          </AccountRow>
          <InfoItem>
            <InfoLabel>Role</InfoLabel>
            {currentUser.role && currentUser.role.label && (
              <InfoText>{currentUser.role.label}</InfoText>
            )}
          </InfoItem>
          <InfoItem>
            <InfoLabel>Department</InfoLabel>
            {currentUser.department && <InfoText>{currentUser.department}</InfoText>}
          </InfoItem>
        </SettingsRowWrapper>
        {edit && <Save type="submit">Save</Save>}
      </Form>
    </FormProvider>
  );
};

UserDetails.propTypes = {
  edit: PropTypes.bool.isRequired,
  setEdit: PropTypes.func.isRequired
};

export default UserDetails;

import React, {useEffect, useMemo, useState} from "react";
import {useForm, FormProvider} from "react-hook-form";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

// Utils
import useMountedState from "../../hooks/useMountedState.js";
import useApi from "../../hooks/useApi.js";
import {setWithExpiry} from "../../utils/helpers.js";

// Components
import Modal from "../../components/Modal.js";
import InputCheckGroup from "../../components/form/InputCheckGroup.js";
import SearchSelect from "../../components/SearchSelect.js";

// Style
import {pad} from "../../style/components/variables.js";
import {voice} from "../../style/components/typography.js";
import {
  HeadingCenter,
  Button,
  Form,
  ButtonLoader,
  SearchWrapper,
  FormField,
  Label,
  Inline
} from "../../style/components/general.js";

const ModalFilter = ({
  visible,
  setVisible,
  facility,
  events,
  eventTypes,
  filters,
  setFilters,
  showArchived,
  setShowArchived
}) => {
  const loading = false;

  const isMounted = useMountedState();

  const options = useMemo(() => {
    const names = [];
    const groupKeys = [];
    const groups = [];
    const frequencies = [];
    events?.inMonth?.map(({name, facility: eventFacility, group, frequency}) => {
      if (!names.includes(name)) names.push(name);
      if (group && !groupKeys.includes(group)) {
        groupKeys.push(group);
        groups.push({
          name: group,
          label: eventFacility.builder.byId[group].label
        });
      }
      if (frequency && frequencies.filter(f => f.name === frequency.name)?.length === 0)
        frequencies.push({name: frequency.name, label: frequency.label});
    });
    return {
      names,
      groups,
      frequencies
    };
  }, [events]);

  const [facilityResults, setFacilityResults] = useState([]);
  const [allFacilities, setAllFacilities] = useState([]);
  const [nameResults, setNameResults] = useState(options.names);
  const [groupResults, setGroupResults] = useState(options.groups);
  const [archived, setArchived] = useState(showArchived);
  const [frequencyResults, setFrequencyResults] = useState(options.frequencies);

  const {api: apiFacilities} = useApi("facilities");

  const form = useForm({
    defaultValues: {...filters, archived: showArchived}
  });
  const {watch, setValue, handleSubmit} = form;
  const watchFacilities = watch("facilities");
  const watchTypes = watch("types");
  const watchNames = watch("names");
  const watchGroups = watch("groups");
  const watchFrequencies = watch("frequencies");

  useEffect(() => {
    if (isMounted())
      apiFacilities.callGet().then(({status, data}) => {
        if (status === 200) {
          const facilities = data.map(({id, name, type}) => ({
            id: id,
            name: `${name.toUpperCase()} ${type}`
          }));
          setAllFacilities(facilities);
          setFacilityResults(facilities);
        }
      });
  }, [isMounted, apiFacilities]);

  const searchFacilities = query => {
    if (query)
      setFacilityResults(
        allFacilities
          ? allFacilities.filter(f => f.name.toLowerCase().includes(query.toLowerCase()))
          : []
      );
    else setFacilityResults(allFacilities);
  };

  const searchNames = query => {
    if (query)
      setNameResults(
        options?.names
          ? options.names.filter(f => f.toLowerCase().includes(query.toLowerCase()))
          : []
      );
    else setNameResults(options.names);
  };

  const searchGroups = query => {
    if (query)
      setGroupResults(
        options?.groups
          ? options.groups.filter(f => f.label.toLowerCase().includes(query.toLowerCase()))
          : []
      );
    else setGroupResults(options.groups);
  };

  const searchFrequencies = query => {
    if (query)
      setFrequencyResults(
        options?.frequencies.filter(f => f.toLowerCase().includes(query.toLowerCase()))
      );
    else setFrequencyResults(options.frequencies);
  };

  const applyFilters = ({facilities, types, groups, names, stages, frequencies}) => {
    const updated = {};

    if (facilities?.length > 0) updated.facilities = facilities;
    if (types?.length > 0) updated.types = types;
    if (groups?.length > 0) updated.groups = groups;
    if (names?.length > 0) updated.names = names;
    if (stages?.length > 0) updated.stages = stages;
    if (frequencies?.length > 0) updated.frequencies = frequencies;

    if (archived !== showArchived) setShowArchived(archived);

    setFilters(updated);
    setWithExpiry("scheduleFilters", JSON.stringify(updated));
    setVisible(false);
  };

  useEffect(() => {
    if (watchTypes?.length !== 1) setValue("stages", null);
  }, [watchTypes, setValue]);

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <HeadingCenter>Event Record Filters</HeadingCenter>
      <Form onSubmit={handleSubmit(applyFilters)}>
        <FormProvider {...form}>
          {!facility && (
            <FormField>
              <Label bold>FACILITIES</Label>
              <SearchWrapper>
                <SearchSelect
                  placeholder="Filter..."
                  results={facilityResults}
                  setResults={setFacilityResults}
                  search={searchFacilities}
                  add={value => {
                    if (!watchFacilities) setValue("facilities", [value.name]);
                    else if (!watchFacilities.includes(value.name))
                      setValue("facilities", [...watchFacilities, value.name]);
                  }}
                  showAll
                />
              </SearchWrapper>
              {watchFacilities?.length > 0 && (
                <Selected>
                  {watchFacilities?.map(name => (
                    <Button
                      key={name}
                      type="button"
                      title="Remove Group"
                      onClick={() =>
                        setValue(
                          "facilities",
                          watchFacilities.filter(curr => curr !== name)
                        )
                      }>
                      <span>{name}</span>
                      &nbsp;
                      <FontAwesomeIcon icon={faClose} />
                    </Button>
                  ))}
                </Selected>
              )}
            </FormField>
          )}

          <FormField>
            <InputCheckGroup
              name="types"
              label="Event Types"
              options={eventTypes.map(type => type.name.toUpperCase())}
            />
          </FormField>
          {watchTypes?.length === 1 &&
            eventTypes.filter(({name}) => name === watchTypes[0].toLowerCase())[0]?.stages && (
              <FormField>
                <InputCheckGroup
                  name="stages"
                  label="Stages"
                  options={
                    eventTypes.filter(({name}) => name === watchTypes[0].toLowerCase())[0].stages
                  }
                  all
                />
              </FormField>
            )}

          <FormField>
            <Label bold>FREQUENCIES</Label>
            <SearchWrapper>
              <SearchSelect
                placeholder="Filter..."
                results={frequencyResults}
                setResults={setFrequencyResults}
                search={searchFrequencies}
                add={value => {
                  if (!watchFrequencies) setValue("frequencies", [value]);
                  else if (!watchFrequencies.some(frequency => frequency.name === value.name))
                    setValue("frequencies", [...watchFrequencies, value]);
                }}
                showAll
              />
            </SearchWrapper>
            {watchFrequencies?.length > 0 && (
              <Selected>
                {watchFrequencies?.map(frequency => (
                  <Button
                    key={frequency.name}
                    type="button"
                    title="Remove Frequency"
                    onClick={() =>
                      setValue(
                        "frequencies",
                        watchFrequencies.filter(curr => curr.name !== frequency.name)
                      )
                    }>
                    <span>{frequency.label}</span>
                    &nbsp;
                    <FontAwesomeIcon icon={faClose} />
                  </Button>
                ))}
              </Selected>
            )}
          </FormField>

          <FormField>
            <Label bold>EVENT NAMES</Label>
            <SearchWrapper>
              <SearchSelect
                placeholder="Filter..."
                results={nameResults}
                setResults={setNameResults}
                search={searchNames}
                add={value => {
                  if (!watchNames) setValue("names", [value]);
                  else if (!watchNames.includes(value)) setValue("names", [...watchNames, value]);
                }}
                showAll
              />
            </SearchWrapper>
            {watchNames?.length > 0 && (
              <Selected>
                {watchNames?.map(name => (
                  <Button
                    key={name}
                    type="button"
                    title="Remove Name"
                    onClick={() =>
                      setValue(
                        "names",
                        watchNames.filter(curr => curr !== name)
                      )
                    }>
                    <span>{name}</span>
                    &nbsp;
                    <FontAwesomeIcon icon={faClose} />
                  </Button>
                ))}
              </Selected>
            )}
          </FormField>

          {(facility || watchFacilities?.length === 1) && options?.groups?.length > 0 && (
            <FormField>
              <Label bold>LOCATION (GROUP)</Label>
              <SearchWrapper>
                <SearchSelect
                  placeholder="Filter..."
                  results={groupResults}
                  setResults={setGroupResults}
                  search={searchGroups}
                  add={value => {
                    if (!watchGroups) setValue("groups", [value]);
                    else if (!watchGroups.some(group => group.name === value.name))
                      setValue("groups", [...watchGroups, value]);
                  }}
                  showAll
                />
              </SearchWrapper>
              {watchGroups?.length > 0 && (
                <Selected>
                  {watchGroups?.map(group => (
                    <Button
                      key={group}
                      type="button"
                      title="Remove Name"
                      onClick={() =>
                        setValue(
                          "groups",
                          watchGroups.filter(curr => curr.name !== group.name)
                        )
                      }>
                      <span>{group.label}</span>
                      &nbsp;
                      <FontAwesomeIcon icon={faClose} />
                    </Button>
                  ))}
                </Selected>
              )}
            </FormField>
          )}

          <Inline>
            <Button type="submit" loading={loading ? 1 : 0}>
              Apply
              {loading && <ButtonLoader />}
            </Button>
            <Button
              type="button"
              onClick={() => {
                applyFilters({});
              }}>
              Clear
            </Button>
            <Button type="button" onClick={() => setArchived(prev => !prev)}>
              <FontAwesomeIcon icon={archived ? faEyeSlash : faEye} />
              &nbsp;Archived
            </Button>
          </Inline>
        </FormProvider>
      </Form>
    </Modal>
  );
};

ModalFilter.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  facility: PropTypes.objectOf(PropTypes.any),
  events: PropTypes.arrayOf(PropTypes.any).isRequired,
  eventTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  filters: PropTypes.objectOf(PropTypes.any),
  setFilters: PropTypes.func.isRequired,
  showArchived: PropTypes.bool.isRequired,
  setShowArchived: PropTypes.func.isRequired
};

ModalFilter.defaultProps = {
  facility: null,
  filters: null
};

// Style Overrides
const Selected = styled(Inline)`
  margin-top: ${pad}px;

  ${Button} {
    ${voice.quiet};
  }
`;

export default ModalFilter;

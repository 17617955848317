import React from "react";
import PropTypes from "prop-types";

// Components
import ModalDelete from "../../components/ModalDelete.js";

const ModalClearLocalStorage = ({visible, setVisible, reset}) => {
  const handleClear = () => {
    reset();
    setVisible(false);
  };

  return (
    <ModalDelete
      visible={visible}
      setVisible={setVisible}
      confirmDelete={handleClear}
      title="Clear Checksheet"
      prompt="Are you sure you want to clear this checksheet? This will not affect any data that was submitted as incomplete, but any additional progress will be deleted."
      action="Clear"
      nested
      width="70%"
    />
  );
};

ModalClearLocalStorage.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  reset: PropTypes.func.isRequired
};

export default ModalClearLocalStorage;

import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";

// Utils
import useMountedState from "../../hooks/useMountedState.js";
import {useToast} from "../../contexts/toast.js";

// Style
import {voice} from "../../style/components/typography.js";
import {colors, pad} from "../../style/components/variables.js";
import {Button} from "../../style/components/general.js";

const ToggleButton = ({state, label, callback}) => {
  const isMounted = useMountedState();

  const {addToast} = useToast();

  const [currentStatus, setCurrentStatus] = useState(state);

  const setCheck = () => {
    if (state !== "unavailable") {
      const updated = currentStatus === "enabled" ? "disabled" : "enabled";
      setCurrentStatus(updated);
      callback(updated);
    } else
      addToast(
        "Permission unavailable for User Type. Escalate Role User Type to make this permission available.",
        "info"
      );
  };

  // set intial state
  useEffect(() => {
    if (isMounted() && state !== "unavailable") setCurrentStatus(state);
  }, [isMounted, state]);

  return (
    <Toggle type="button" onClick={() => setCheck()} status={currentStatus}>
      {label}
    </Toggle>
  );
};

ToggleButton.propTypes = {
  label: PropTypes.string.isRequired,
  callback: PropTypes.func.isRequired,
  state: PropTypes.string
};

ToggleButton.defaultProps = {
  state: null
};

// Style Overrides
const Toggle = styled(Button)`
  ${voice.quiet};
  background: ${props => props.theme.primary};
  padding: ${pad / 4}px ${pad / 2}px;

  ${props =>
    props.status
      ? css`
          opacity: ${props.status === "enabled" ? 1 : 0.5};
          cursor: ${props.status === "unavailable" ? "not-allowed" : "pointer"};
          background: ${props.status === "unavailable" ? colors.gray : props.theme.primary};
        `
      : css`
          opacity: 0;
          pointer-events: none;
        `}
`;

export default ToggleButton;

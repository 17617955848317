import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExport} from "@fortawesome/free-solid-svg-icons";

// Components
import Modal from "./Modal";
import BaseTable from "./BaseTable";

// Style
import {pad} from "../style/components/variables";
import {Button, Heading, Inline, TableWrapper} from "../style/components/general";

const ModalExportPreview = ({
  visible,
  setVisible,
  goBack,
  exportName,
  tableHeadings,
  tableData,
  handleExport
}) => (
  <Modal visible={visible} setVisible={setVisible} goBack={goBack} hasBackButton>
    <TableWrapper>
      <HeaderWrapper>
        <Heading>{exportName}</Heading>
        {handleExport && (
          <Button type="button" onClick={handleExport}>
            <FontAwesomeIcon icon={faFileExport} />
          </Button>
        )}
      </HeaderWrapper>
      <BaseTable
        headings={tableHeadings}
        data={tableData}
        maxHeight="500px"
        alignRows="top"
        cellFullWidth
      />
    </TableWrapper>
  </Modal>
);

ModalExportPreview.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  goBack: PropTypes.func,
  exportName: PropTypes.string.isRequired,
  tableHeadings: PropTypes.objectOf(PropTypes.any).isRequired,
  tableData: PropTypes.arrayOf(PropTypes.any).isRequired,
  handleExport: PropTypes.func
};

ModalExportPreview.defaultProps = {
  goBack: null,
  handleExport: null
};

// Style Overrides
const HeaderWrapper = styled(Inline)`
  padding: ${pad}px;
  justify-content: space-between;
  width: 100%;
`;

export default ModalExportPreview;

import React, {useLayoutEffect} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";

// Components
import InputError from "./InputError.js";

// Style
import {bp} from "../../style/components/breakpoints.js";
import {pad, border, radius} from "../../style/components/variables.js";
import {FormFieldWrapper, Inline, Label} from "../../style/components/general.js";

const InputPhone = ({...props}) => {
  const {name, label, required, defaultValue, testId} = props;

  const {
    watch,
    setValue,
    register,
    formState: {errors}
  } = useFormContext();

  const number = watch(`${name}.number`);

  const normalize = value => {
    if (!value) return null;
    const digits = value.replace(/(?:\D)/g, "").substring(0, 10);
    const zip = digits.substring(0, 3);
    const middle = digits.substring(3, 6);
    const last = digits.substring(6, 10);

    let formatted = "";
    if (digits.length > 6) formatted = `(${zip}) ${middle}-${last}`;
    else if (digits.length > 3) formatted = `(${zip}) ${middle}`;
    else if (digits.length > 0) formatted = `(${zip}`;

    return formatted;
  };

  useLayoutEffect(() => {
    if (number) setValue(`${name}.number`, normalize(number));
  }, [name, number, setValue]);

  return (
    <FormFieldWrapper data-testid={testId}>
      {label && (
        <Label htmlFor={`${name}.number`} bold>
          {label.toUpperCase()}
          {required && <span>&nbsp;*</span>}
        </Label>
      )}
      <Inline>
        <Number
          id={`${name}.number`}
          data-testid={`${testId}-number`}
          type="tel"
          placeholder="(999) 999-9999"
          defaultValue={normalize(defaultValue)}
          {...register(`${name}.number`)}
        />
        <Extension
          id={`${name}.extension`}
          data-testid={`${testId}-extension`}
          type="tel"
          placeholder="Ext."
          {...register(`${name}.extension`)}
        />
      </Inline>
      <InputError errors={errors} name={`${name}.number`} />
    </FormFieldWrapper>
  );
};

InputPhone.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  defaultValue: PropTypes.string
};

InputPhone.defaultProps = {
  testId: "input-phone",
  label: null,
  required: false,
  defaultValue: null
};

// Style Overrides
const Part = styled.input`
  padding: ${pad - 2}px ${pad}px;
  border: ${border} solid ${props => props.theme.secondary};
  border-radius: ${radius};
  background: transparent;
  color: ${props => props.theme.secondary};
`;

const Number = styled(Part)`
  margin-right: ${pad}px;
  min-width: 125px;
  width: 80%;

  ${bp(2)} {
    max-width: 125px;
  }
`;

const Extension = styled(Part)`
  width: 50px;
`;

export default InputPhone;

import React, {useCallback, useEffect, useRef, useState} from "react";
import {useFormContext} from "react-hook-form";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";

// Utils
import useMountedState from "../../hooks/useMountedState.js";

// Components
import InputCheck from "./InputCheck.js";
import InputError from "./InputError.js";

// Style
import {flex} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {border, pad, transition} from "../../style/components/variables.js";
import {FormFieldWrapper, Label, Text, Button} from "../../style/components/general.js";

const DAYS = [
  {label: "S", name: "Sun"},
  {label: "M", name: "Mon"},
  {label: "T", name: "Tue"},
  {label: "W", name: "Wed"},
  {label: "T", name: "Thu"},
  {label: "F", name: "Fri"},
  {label: "S", name: "Sat"}
];

const DAY_IDX = {
  Sun: 0,
  Mon: 1,
  Tue: 2,
  Wed: 3,
  Thu: 4,
  Fri: 5,
  Sat: 6
};

const InputDay = ({
  name,
  label,
  disabled,
  required,
  testId,
  prompt,
  restrictOne,
  restrictWarning,
  align
}) => {
  const isMounted = useMountedState();

  const [weekWidth, setWeekWidth] = useState(true);
  const [restrictErrorOverride, setRestrictErrorOverride] = useState(null);

  const {
    formState: {errors, submitCount},
    setValue,
    watch
  } = useFormContext();

  const selected = watch(name);

  const checkboxes = useRef(false);

  useEffect(() => {
    if (restrictOne && selected?.length > 1) setValue(name, []);
    if (restrictOne && restrictWarning && selected?.length > 1)
      setRestrictErrorOverride({[name]: {message: restrictWarning}});
    else setRestrictErrorOverride(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, restrictOne, restrictWarning, setValue]);

  // Resize Event
  const resizeEvent = useCallback(() => setWeekWidth(checkboxes.current.clientWidth), []);

  useEffect(() => {
    if (isMounted()) resizeEvent();

    window.addEventListener("resize", resizeEvent);

    return () => window.removeEventListener("resize", resizeEvent);
  }, [isMounted, resizeEvent]);

  const toggleOne = index => {
    if (restrictOne && restrictWarning && selected?.length > 1)
      setRestrictErrorOverride({[name]: {message: restrictWarning}});
    else setRestrictErrorOverride(null);
    const fieldValues = [];
    fieldValues.push(DAYS[index].name);
    setValue(name, fieldValues, {shouldValidate: !!submitCount, shouldDirty: true});
  };

  const toggleDay = index => {
    setRestrictErrorOverride(null);
    let fieldValues = selected;
    if (!selected) fieldValues = [];
    else if (!Array.isArray(selected)) fieldValues = [selected];
    if (fieldValues.includes(DAYS[index].name))
      fieldValues = fieldValues.filter(value => value !== DAYS[index].name);
    else fieldValues.push(DAYS[index].name);
    setValue(
      name,
      fieldValues.sort((a, b) => DAY_IDX[a] - DAY_IDX[b]),
      {shouldValidate: !!submitCount, shouldDirty: true}
    );
  };

  return (
    <FormFieldWrapper id="wrapper" ref={checkboxes} data-testid={testId}>
      <Label htmlFor={name} bold onClick={e => e.preventDefault()}>
        {label}
        {required && <span>&nbsp;*</span>}
      </Label>
      <Text>{prompt}</Text>
      <Week align={align || (checkboxes.current.clientWidth > 285 ? "row" : "column")}>
        {DAYS.map((day, index) => (
          <Wrapper key={day.name} data-testid={`${testId}-${day.name}`}>
            <InputCheck id={name} name={name} disabled={disabled} showError={false} hidden />
            <Day
              type="button"
              onClick={() => (restrictOne ? toggleOne(index) : toggleDay(index))}
              selected={selected && selected.includes(day.name)}
              label={weekWidth < 250 || weekWidth > 380 ? day.name : day.label}
            />
          </Wrapper>
        ))}
      </Week>
      <InputError errors={restrictErrorOverride ?? errors} name={name} />
    </FormFieldWrapper>
  );
};

InputDay.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  testId: PropTypes.string,
  prompt: PropTypes.string,
  restrictOne: PropTypes.bool,
  restrictWarning: PropTypes.string,
  align: PropTypes.string
};

InputDay.defaultProps = {
  testId: "input-day",
  label: null,
  disabled: false,
  required: false,
  prompt: "",
  restrictOne: false,
  restrictWarning: null,
  align: null
};

// Style Overrides
const Week = styled.div`
  ${flex(({align}) => align, "nowrap", "center", "center")};
  gap: ${pad}px;
  width: 100%;
`;

const Wrapper = styled.div`
  display: flex;
  width: ${({row}) => (row ? "14.28%" : "100%")};
`;

const Day = styled(Button)`
  ${voice.quiet};
  width: 100%;
  color: ${({theme}) => theme.secondary};
  background: ${({theme}) => theme.tertiary};
  border: ${border} solid ${({theme}) => theme.secondary};

  &:hover {
    background: ${({theme}) => theme.primary};
  }

  ${({selected, theme}) =>
    selected &&
    css`
      background: ${theme.primary};
      border: ${border} solid ${theme.secondary};
      transition: ${transition};
    `}

  &:after {
    content: ${({label}) => `"${label}"`};
    display: block;
    width: 100%;
    height: 100%;
  }
`;

export default InputDay;

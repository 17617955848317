import React, {useRef, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faSearch} from "@fortawesome/free-solid-svg-icons";

// Style
import {border, pad, radius} from "../style/components/variables.js";
import {flex, z} from "../style/components/mixins.js";
import {voice} from "../style/components/typography.js";
import {List, ListItem, Label, Input} from "../style/components/general.js";

const SearchSelectUser = ({
  testId,
  label,
  search,
  results,
  setResults,
  add,
  remove,
  selections
}) => {
  const searchInput = useRef(null);
  const resultElement = useRef(null);

  const [active, setActive] = useState(false);

  const handleSearch = e => {
    const query = e.target.value;
    if (query && query !== "") {
      setActive(true);
      search(query);
    } else setActive(false);
  };

  return (
    <>
      {label && <Label bold>{label.toUpperCase()}</Label>}
      <SearchWrapper>
        <SearchIcon>
          <FontAwesomeIcon icon={faSearch} />
        </SearchIcon>
        <Search
          ref={searchInput}
          name="search"
          key="contacts"
          type="text"
          placeholder="Find user..."
          onChange={e => handleSearch(e)}
          onFocus={() => {
            setActive(document.activeElement === searchInput.current);
            setResults([]);
          }}
          onBlur={() =>
            // wait for add
            setTimeout(() => {
              setActive(document.activeElement === searchInput.current);
              setResults([]);
            }, 1000)
          }
        />
        {active && results.length > 0 && (
          <Results ref={resultElement}>
            {results.map(user => (
              <Result
                key={user.publicId}
                onClick={() => {
                  add(user.email);
                  setResults([]);
                  searchInput.current.value = "";
                }}>
                {user.firstName}&nbsp;{user.lastName}
              </Result>
            ))}
          </Results>
        )}
      </SearchWrapper>
      <Selections>
        {selections?.map((email, index) => (
          <Selection
            key={email}
            data-testid={`${testId}-selection${index + 1}`}
            onClick={() => remove(email)}>
            {email}&nbsp;
            <FontAwesomeIcon icon={faClose} />
          </Selection>
        ))}
      </Selections>
    </>
  );
};

SearchSelectUser.propTypes = {
  testId: PropTypes.string,
  label: PropTypes.string,
  search: PropTypes.func.isRequired,
  results: PropTypes.array,
  setResults: PropTypes.func.isRequired,
  add: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  selections: PropTypes.array.isRequired
};

SearchSelectUser.defaultProps = {
  testId: "search",
  label: null,
  results: []
};

// Style Overrides
const SearchWrapper = styled.div`
  position: relative;
  display: flex;
`;

const Search = styled(Input)`
  position: relative;
  max-width: 160px;
  padding-left: ${pad * 3}px;
`;

const SearchIcon = styled.span`
  position: absolute;
  top: ${pad}px;
  left: ${pad}px;
  z-index: ${z("above")};

  svg {
    fill: ${props => props.theme.secondary};
  }
`;

const Results = styled(List)`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 100%;
  left: 0;
  background: ${props => props.theme.tertiary};
  border: ${border} solid ${props => props.theme.secondary};
  border-radius: ${radius};
  margin-top: ${pad / 2}px;
  z-index: ${z("top")};
`;

const Result = styled(ListItem)`
  width: 100%;
  padding: 0 ${pad}px;

  &:hover {
    color: ${props => props.theme.tertiary};
    background: ${props => props.theme.secondary};
  }
`;

const Selections = styled.div`
  position: relative;
  ${flex("row", "wrap", "start", "start")};
`;

const Selection = styled.button`
  color: ${props => props.theme.tertiary};
  background: ${props => props.theme.primary};
  border-radius: ${radius};
  padding: ${pad / 2}px;
  width: fit-content;
  margin: ${pad}px ${pad / 2}px 0 0;
  ${voice.quiet};
`;

export default SearchSelectUser;

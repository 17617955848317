import React, {useState, useEffect} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Utils
import {isMobileDevice} from "../utils/responsive.js";

// Components
import Callout from "./Callout.js";

// Style
import {pad} from "../style/components/variables.js";
import {z} from "../style/components/mixins.js";
import {bp} from "../style/components/breakpoints.js";

const CalloutMenu = ({calloutList}) => {
  const [visibleFunctions, setVisibleFunctions] = useState(null);

  useEffect(() => {
    if (calloutList) {
      setVisibleFunctions(calloutList.map(calloutData => calloutData.setVisible));
    }
  }, [calloutList]);

  const handleChangeVisible = (state, changed) => {
    if (state) {
      visibleFunctions.map((func, index) => {
        if (index !== changed) func(false);
      });
    }
    visibleFunctions[changed](state);
  };

  return (
    <StickyMenu>
      {calloutList &&
        calloutList.map((calloutData, index) => (
          <Callout
            button={calloutData.button}
            visible={calloutData.visible}
            setVisible={state => handleChangeVisible(state, index)}
            index={index}
            buttonType={calloutData.buttonType}
            // eslint-disable-next-line react/no-array-index-key
            key={`callout-${index}`}
            mobile={isMobileDevice() ? 1 : 0}>
            {calloutData.content}
          </Callout>
        ))}
    </StickyMenu>
  );
};

CalloutMenu.propTypes = {
  calloutList: PropTypes.arrayOf(PropTypes.any).isRequired
};

// style overrides
const StickyMenu = styled.div`
  bottom: ${pad}px;
  margin-top: 0;
  display: flex;
  position: sticky;
  justify-content: flex-end;
  align-self: flex-end;
  align-items: flex-end;
  flex-direction: column-reverse;
  z-index: ${z("above")};
  gap: ${pad / 2}px;
  pointer-events: none;

  ${bp(2)} {
    margin-bottom: 5px;
  }
`;

export default CalloutMenu;

import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {useForm} from "react-hook-form";

// Utils
import useMountedState from "../../hooks/useMountedState.js";
import {hexToRgba} from "../../utils/helpers.js";

// Style
import {z} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {pad, border, radius, transition, navHeight} from "../../style/components/variables.js";
import {Text, ButtonFull, Input, scrollbar, Label} from "../../style/components/general.js";

const MissingElements = ({
  insertElement,
  setShowMissingElementsModal,
  facility,
  currentBuilder,
  refresh,
  setRefresh,
  initialLoad
}) => {
  const isMounted = useMountedState();
  const [elementList, setElementsList] = useState([]);
  const [missingComponents, setMissingComponents] = useState({});
  const [currentResults, setCurrentResults] = useState([]);

  const form = useForm({
    defaultValues: {
      search: ""
    }
  });
  const {watch, register, setValue} = form;
  const query = watch("search");

  useEffect(() => {
    if (isMounted() && facility && facility.builder && currentBuilder && initialLoad && refresh) {
      setValue("search", "");

      const tempElementList = [];
      const tempMissingComponents = {};
      facility.builder.allIds.forEach(id => {
        if (!(id in currentBuilder.byId))
          tempElementList.push({
            ...facility.builder.byId[id],
            children: [
              ...facility.builder.byId[id].children.filter(child => child in facility.builder.byId)
            ]
          });
        else if (
          facility.builder.byId[id].children &&
          facility.builder.byId[id].children.length > 0
        ) {
          let shouldAdd = false;
          facility.builder.byId[id].children
            .filter(child => child in facility.builder.byId)
            .forEach(child => {
              if (!(child in currentBuilder.byId)) {
                shouldAdd = true;
                if (id in tempMissingComponents) tempMissingComponents[id].push(child);
                else tempMissingComponents[id] = [child];
              }
            });
          if (shouldAdd) tempElementList.push(facility.builder.byId[id]);
        }
      });

      setElementsList(tempElementList);
      setMissingComponents(tempMissingComponents);
      setCurrentResults(tempElementList);
      setRefresh(false);
    }
  }, [isMounted, currentBuilder, facility, initialLoad, refresh, setRefresh, setValue]);

  useEffect(() => {
    if (query === "") {
      setCurrentResults(elementList);
    } else {
      setCurrentResults(
        elementList.filter(element => element.label.toLowerCase().includes(query.toLowerCase()))
      );
    }
  }, [query, elementList]);

  const missingElementsTable = () => {
    if (currentResults.length > 0)
      return currentResults.map(element => (
        <MissingElement
          type="button"
          key={element.name}
          element={element.element}
          onClick={() => {
            insertElement({...element, componentsMissing: missingComponents[element.name]});
            setShowMissingElementsModal(true);
          }}
          data-testid="editor.missing">
          {element.label}
          <br />
          {element.name in missingComponents && (
            <StyledText>
              {missingComponents[element.name].length} Component
              {missingComponents[element.name].length > 1 ? "s" : ""} not included
            </StyledText>
          )}
        </MissingElement>
      ));

    if (query && query !== "") return <NotFoundText>No results</NotFoundText>;

    return <NotFoundText>All elements included</NotFoundText>;
  };

  return (
    <Menu>
      <Label bold>Add From Facility Builder</Label>
      <Search
        data-testid="editor.missingSearch"
        type="text"
        placeholder="Search"
        autoComplete="off"
        {...register("search")}
      />
      <ElementsTable data-testid="editor.missingTable">{missingElementsTable()}</ElementsTable>
    </Menu>
  );
};

MissingElements.propTypes = {
  insertElement: PropTypes.func.isRequired,
  setShowMissingElementsModal: PropTypes.func.isRequired,
  facility: PropTypes.any.isRequired,
  currentBuilder: PropTypes.objectOf(PropTypes.any).isRequired,
  refresh: PropTypes.bool,
  setRefresh: PropTypes.func,
  initialLoad: PropTypes.bool
};

MissingElements.defaultProps = {
  refresh: false,
  setRefresh: null,
  initialLoad: false
};

// Style Overrides
const CreateButton = styled(ButtonFull)`
  ${voice.strong};
  font-weight: bold;
  text-transform: uppercase;
  margin-right: ${pad}px;
  margin-bottom: ${pad}px;

  &:last-child {
    margin-right: 0;
  }
`;

const ElementsTable = styled.div`
  position: relative;
  max-height: calc(35vh - (${`${navHeight}px`}));
  overflow-y: auto;
  overflow-x: hidden;

  ${scrollbar};
`;

const MissingElement = styled(CreateButton)`
  background: transparent;
  color: ${({theme, element}) => theme[element]};
  border: ${border} solid ${({theme, element}) => theme[element]};
  margin-top: ${pad}px;
  transition: ${transition};
  ${voice.normal};

  svg {
    fill: ${({theme, element}) => theme[element]};
  }

  &:hover {
    color: ${({theme}) => theme.tertiary};
    background: ${({theme, element}) => theme[element]};

    svg {
      fill: ${({theme}) => theme.tertiary};
    }

    span {
      color: ${({theme}) => theme.tertiary};
    }
  }
`;

const Search = styled(Input)`
  margin-top: ${pad}px;
  margin-bottom: ${pad}px;
`;

const Menu = styled.div`
  position: relative;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  width: 100%;
  padding: ${pad}px;
  margin-bottom: ${pad}px;
  max-height: 39vh;
  overflow: hidden;

  &:before,
  &:after {
    content: "";
    z-index: ${z("above")};
    position: absolute;
    left: 0;
    width: 100%;
    height: ${pad}px;
  }

  &:after {
    top: 0;
    background: ${({theme}) =>
      `linear-gradient(to bottom, ${`${hexToRgba(theme.tertiary)} 0%`}, ${
        theme.name === "light" ? "rgba(255, 255, 255, 0) 100%" : "rgba(0, 0, 0, 0) 100%"
      })`};
  }

  &:before {
    bottom: 0;
    background: ${({theme}) =>
      `linear-gradient(to top, ${`${hexToRgba(theme.tertiary)} 0%`}, ${
        theme.name === "light" ? "rgba(255, 255, 255, 0) 100%" : "rgba(0, 0, 0, 0) 100%"
      })`};
  }

  ${Label} {
    ${voice.quiet};
  }
`;

const StyledText = styled.span`
  margin: ${pad}px;
  color: ${({theme}) => theme.component};
  font-size: 12px;
`;

const NotFoundText = styled(Text)`
  color: ${({theme}) => theme.primary};
`;

export default MissingElements;

import React, {useState, useContext, useEffect, useCallback, useMemo} from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash, faEdit, faPlus, faArchive} from "@fortawesome/free-solid-svg-icons";
import styled, {css} from "styled-components";
import dayjs from "dayjs";

// Contexts
import {useToast} from "../../contexts/toast.js";
import {useSocket} from "../../contexts/socket.js";
import {AuthContext} from "../../contexts/auth.js";
import {CalendarContext} from "../../contexts/calendar.js";

// Hooks
import useMountedState from "../../hooks/useMountedState.js";
import useApi from "../../hooks/useApi.js";

// Components
import ModalEventManage from "./ModalEventManage.js";
import ModalEventDetail from "./ModalEventDetail.js";
import ModalEventAddition from "./ModalEventAddition.js";
import ModalEventDelete from "./ModalEventDelete.js";
import ModalEventArchive from "./ModalEventArchive.js";
import ModalRedirect from "../general/ModalRedirect.js";
import ArchiveRecord from "../general/ArchiveRecord.js";

// Style
import {voice} from "../../style/components/typography.js";
import {pad, radius} from "../../style/components/variables.js";
import {ModalButton, Button} from "../../style/components/general.js";

// Socket Constants
import {getFacilityRooms, LIST_LOCKS, REQUEST_LOCKS} from "../general/Room.js";

const ModalEvent = ({
  visible,
  setVisible,
  isGlobal,
  isEditing,
  date,
  facility,
  event,
  eventTypes,
  editEvent,
  cancelEdit,
  handleBulkComplete,
  restoreEvent,
  showEventForm,
  getEvents,
  frequencies,
  units,
  setUnits,
  markerIconMap
}) => {
  const isMounted = useMountedState();

  const socket = useSocket();

  const {currentUser, roleCanAccessResource} = useContext(AuthContext);
  const {records, getRecords} = useContext(CalendarContext);

  const {addToast} = useToast();

  const [locks, setLocks] = useState(null);
  const [keyHolder, setKeyHolder] = useState(null);
  const [completing, setCompleting] = useState(false);

  // Modals
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalAddition, setShowModalAddition] = useState(false);
  const [showModalArchiveOptions, setShowModalArchiveOptions] = useState(false);
  const [showModalArchiveEvent, setShowModalArchiveEvent] = useState(false);
  const [showModalArchiveRecord, setShowModalArchiveRecord] = useState(false);

  const {api: apiUsers} = useApi("users");

  const canArchiveRecord = useMemo(() => {
    const {id, frequency, availableBase, availableIndex} = event || {};
    if (!event || !roleCanAccessResource("event_record", "archive")) return false;
    if (!records || !records[date] || !records[date][id]) {
      const day = dayjs(date);
      let isPastDue = day.isBefore(dayjs(), "days");

      if (availableBase && availableIndex) {
        let offset = null;

        // offset is the number of months the due date must be shifted
        // backwards to make it so that the due date checked falls on the end
        // of the availability window

        const {base, interval} = frequency || {};

        if (base && interval) {
          if (availableBase === "months") {
            let totalLength;

            if (base === "months") totalLength = interval;
            else totalLength = 12;

            offset = totalLength - availableIndex;
          } else {
            offset = interval - availableIndex;
          }

          const intervalEnd = day.subtract(offset, availableBase);

          isPastDue = intervalEnd.isBefore(dayjs(), "days");
        }
      }

      return isPastDue;
    }
    const record = records[date][event.id];
    return record.status === "Overdue" && !record.isArchived;
  }, [records, date, event, roleCanAccessResource]);

  const canArchiveEvent = useMemo(
    () => roleCanAccessResource("event", "archive"),
    [roleCanAccessResource]
  );

  const listLocks = useCallback(
    ({lockList, userId}) => {
      // if userId is null, then it was another event that triggered listLocks
      // if userId is defined, it is from request_locks and should be ignored unless the current user sent the request
      if (!userId || userId === currentUser.publicId) setLocks(lockList || {});
    },
    [currentUser.publicId]
  );

  // Socket Management - resource locking
  useEffect(() => {
    if (isMounted() && currentUser && locks === null) {
      socket.emit(REQUEST_LOCKS, {
        rooms: ["schedule", ...getFacilityRooms(facility?.slug, "event")],
        user: currentUser.publicId,
        type: "event"
      });
    }

    socket.on(LIST_LOCKS, listLocks);

    return () => {
      // unbind all event handlers used in this component
      socket.off(LIST_LOCKS, listLocks);
    };
  }, [isMounted, socket, currentUser, facility, listLocks, locks]);

  useEffect(() => () => window.history.replaceState(null, null, " "), []);

  // Set "Key Holder" from current locks
  useEffect(() => {
    if (isMounted() && event?.id && locks) {
      const key = `event_${event.id}_${date}`;
      if (locks[key] && locks[key].user !== currentUser.publicId)
        apiUsers.callGet(locks[key].user).then(({status, data}) => {
          if (status === 200) {
            const {firstName, lastName} = data;
            setKeyHolder(`${firstName} ${lastName}`);
          }
        });
      else setKeyHolder("");
    }
  }, [isMounted, apiUsers, event, date, locks, currentUser, setKeyHolder]);

  if (showModalAddition)
    return (
      <ModalEventAddition
        visible={showModalAddition}
        setVisible={setVisible}
        event={event}
        date={date}
        reload={getEvents}
        goBack={() => setShowModalAddition(false)}
        hasBackButton
      />
    );

  if (showModalDelete)
    return (
      <ModalEventDelete
        visible={showModalDelete}
        setVisible={setVisible}
        event={event}
        getEvents={getEvents}
        goBack={() => setShowModalDelete(false)}
        hasBackButton
      />
    );

  if (showModalArchiveEvent)
    return (
      <ModalEventArchive
        visible={showModalArchiveEvent}
        setVisible={setVisible}
        event={event}
        getEvents={getEvents}
        goBack={() => setShowModalArchiveEvent(false)}
        hasBackButton
      />
    );

  if (showModalArchiveOptions)
    return (
      <ModalRedirect
        visible={showModalArchiveOptions}
        setVisible={setVisible}
        hasBackButton
        goBack={() => setShowModalArchiveOptions(false)}
        orientation="column"
        buttonOptions={[
          {
            action: () => {
              setShowModalArchiveEvent(true);
              setShowModalArchiveOptions(false);
            },
            description: "Archive Event",
            elaboration:
              "This will remove event from calendar unless the 'Show Archived' filter is applied. All data is preserved, and this operation can be reversed."
          },
          {
            action: () => {
              setShowModalArchiveRecord(true);
              setShowModalArchiveOptions(false);
            },
            description: "Archive Overdue Record",
            elaboration:
              "This will archive the overdue status of the selected record, removing it from overdue alerts"
          }
        ]}
      />
    );

  if (showModalArchiveRecord)
    return (
      <ArchiveRecord
        visible={showModalArchiveRecord}
        setVisible={setVisible}
        updateData={getRecords}
        date={date}
        task={event}
        record={
          records && records[date] && records[date][event.id] ? records[date][event.id] : null
        }
        taskType="event"
        goBack={() => setShowModalArchiveRecord(false)}
        hasBackButton
      />
    );

  const renderModalControls = () => {
    if (isEditing && showEventForm)
      return (
        <EventControls>
          <ModalButton onClick={cancelEdit} type="button">
            Cancel
          </ModalButton>
        </EventControls>
      );
    if (!event || completing || showEventForm) return null;
    return (
      <EventControls>
        {isGlobal && facility?.slug && (
          <PageLink to={`/facilities/${facility.slug}/schedule`}>VIEW ON FACILITY</PageLink>
        )}
        {handleBulkComplete &&
          roleCanAccessResource("event", "create") &&
          !showEventForm &&
          !facility?.isDeleted &&
          !event.isArchived &&
          !event?.message && (
            <Addition type="button" onClick={() => handleBulkComplete()} quiet>
              BULK COMPLETE
            </Addition>
          )}
        {roleCanAccessResource("event", "create") &&
          !showEventForm &&
          !facility?.isDeleted &&
          !event.isArchived &&
          !event?.message && (
            <Addition type="button" onClick={() => setShowModalAddition(true)} quiet>
              <FontAwesomeIcon icon={faPlus} /> {event.type.name.toUpperCase()} DATE
            </Addition>
          )}
        {roleCanAccessResource("event", "update") &&
          !isGlobal &&
          !facility?.isDeleted &&
          !event.isArchived && (
            <LoadingModalButton
              data-testid="event.editButton"
              loading={!locks ? 1 : 0}
              onClick={() => {
                if (keyHolder) addToast(`Locked by ${keyHolder}`);
                else if (event.addition?.map(({date: d}) => d).includes(date))
                  setShowModalAddition(true);
                else editEvent();
              }}
              title="Edit Event">
              <FontAwesomeIcon icon={faEdit} inverse />
            </LoadingModalButton>
          )}
        {(canArchiveEvent || canArchiveRecord) &&
          !isGlobal &&
          !facility?.isDeleted &&
          !event.addition?.includes(event.dateDue) && (
            <>
              {event.isArchived && canArchiveEvent && (
                <ModalButton
                  loading={!locks ? 1 : 0}
                  onClick={() => restoreEvent()}
                  title="Restore Event">
                  Restore
                </ModalButton>
              )}
              {!event.isArchived && (
                <LoadingModalButton
                  data-testid="event.archiveButton"
                  loading={!locks ? 1 : 0}
                  onClick={() => {
                    if (keyHolder) addToast(`Locked by ${keyHolder}`);
                    else if (canArchiveRecord && canArchiveEvent) setShowModalArchiveOptions(true);
                    else if (canArchiveRecord) setShowModalArchiveRecord(true);
                    else setShowModalArchiveEvent(true);
                  }}
                  title="Archive Event">
                  <FontAwesomeIcon icon={faArchive} inverse />
                </LoadingModalButton>
              )}
            </>
          )}
        {roleCanAccessResource("event", "delete") && !isGlobal && !facility?.isDeleted && (
          <LoadingModalButton
            data-testid="event.deleteButton"
            loading={!locks ? 1 : 0}
            onClick={() => {
              if (keyHolder) addToast(`Locked by ${keyHolder}`);
              else setShowModalDelete(true);
            }}
            title="Delete Event">
            <FontAwesomeIcon icon={faTrash} inverse />
          </LoadingModalButton>
        )}
      </EventControls>
    );
  };

  if (locks !== null)
    return showEventForm ? (
      <ModalEventManage
        visible={visible}
        setVisible={setVisible}
        renderModalControls={renderModalControls}
        isGlobal={isGlobal}
        isEditing={isEditing}
        facility={facility}
        date={date}
        event={event}
        eventTypes={eventTypes}
        getEvents={getEvents}
        frequencies={frequencies}
        units={units}
        setUnits={setUnits}
      />
    ) : (
      <ModalEventDetail
        visible={visible}
        setVisible={setVisible}
        isGlobal={isGlobal}
        event={event}
        eventTypes={eventTypes}
        keyHolder={keyHolder}
        completing={completing}
        setCompleting={setCompleting}
        date={date}
        markerIconMap={markerIconMap}
        renderModalControls={renderModalControls}
      />
    );

  return null;
};

ModalEvent.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  isGlobal: PropTypes.bool.isRequired,
  facility: PropTypes.objectOf(PropTypes.any),
  event: PropTypes.objectOf(PropTypes.any),
  date: PropTypes.string.isRequired,
  frequencies: PropTypes.arrayOf(PropTypes.any).isRequired,
  units: PropTypes.arrayOf(PropTypes.string).isRequired,
  setUnits: PropTypes.func.isRequired,
  markerIconMap: PropTypes.objectOf(PropTypes.any).isRequired,
  eventTypes: PropTypes.arrayOf(PropTypes.any),
  editEvent: PropTypes.func,
  restoreEvent: PropTypes.func,
  cancelEdit: PropTypes.func,
  handleBulkComplete: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  isEditing: PropTypes.bool,
  showEventForm: PropTypes.bool,
  getEvents: PropTypes.func
};

ModalEvent.defaultProps = {
  facility: null,
  event: null,
  eventTypes: [],
  editEvent: null,
  restoreEvent: null,
  cancelEdit: null,
  handleBulkComplete: null,
  getEvents: null,
  isEditing: false,
  showEventForm: false
};

// Style Overrides
const EventControls = styled.div`
  display: flex;
  margin-right: ${pad}px;
`;

const PageLink = styled(Link)`
  ${voice.quiet};
  color: ${({theme}) => theme.tertiary};
  padding: ${pad / 2}px;
  border-radius: ${radius};
  background: ${({theme}) => theme.secondary};
`;

const Addition = styled(Button)`
  ${voice.quiet};
  padding: ${pad / 2}px;
  margin-left: ${pad}px;
`;

const LoadingModalButton = styled(ModalButton)`
  transition: opacity 100ms;
  opacity: 1;
  visibility: visible;

  ${({loading}) =>
    loading
      ? css`
          opacity: 0;
          visibility: hidden;
        `
      : ""}
`;

export default ModalEvent;

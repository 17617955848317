import React, {useState, useEffect, useContext, useRef, useCallback, useMemo} from "react";
import {NavLink, useNavigate, useLocation, useParams} from "react-router-dom";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faUser,
  faSun,
  faMoon,
  faTableCellsLarge,
  faBars,
  faCalendar
} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";

// Hooks
import useMountedState from "../../hooks/useMountedState.js";
import useApi from "../../hooks/useApi.js";

// Contexts
import {SettingsContext} from "../../contexts/settings.js";
import {AuthContext} from "../../contexts/auth.js";
import {NavContext} from "../../contexts/nav.js";
import {useSocket} from "../../contexts/socket.js";
import {useBanner} from "../../contexts/banner.js";

// Utils
import {convertToUserTimezone, toTitleCase} from "../../utils/helpers.js";
import {isMobileDevice} from "../../utils/responsive.js";

// Components
import FacilityNav from "./FacilityNav.js";
import ToggleSwitch from "../../components/ToggleSwitch.js";
import Modal from "../../components/Modal.js";
import ModalIdle from "./ModalIdle.js";
import Dropdown from "../../components/Dropdown.js";

// Style
import {z} from "../../style/components/mixins.js";
import {bp} from "../../style/components/breakpoints.js";
import {voice} from "../../style/components/typography.js";
import {border, colors, navHeight, pad, radius, shadow} from "../../style/components/variables.js";
import {
  List,
  ListItem,
  Text,
  Small,
  Button,
  Heading,
  Inline,
  Abbr,
  AnchorInline
} from "../../style/components/general.js";

// Socket Constants
import {DISCONNECTED, RELEASE_LOCKS} from "./Room.js";

const MANAGE_SESSIONS = "manage_sessions";
const MAINTENANCE = "maintenance";

const Nav = () => {
  const isMounted = useMountedState();
  const navigate = useNavigate();
  const location = useLocation();
  const socket = useSocket();

  const {pathname, hash} = useLocation();
  const {slug} = useParams();

  const {addBanner, removeBanner} = useBanner();

  const {settings} = useContext(SettingsContext);
  const {
    currentUser,
    getCurrentUser,
    signout,
    atLeast,
    roles,
    roleCanAccessPage,
    roleCanAccessResource,
    setMaintenance,
    maintenance
  } = useContext(AuthContext);

  const {api} = useApi("users", {suppress: {success: true, error: true}});

  const timerId = useRef(null);

  const [open, setOpen] = useState(false);
  const [showAccountMenu, setShowAccountMenu] = useState(false);

  const {
    online,
    setOnline,
    showManageSessions,
    setShowManageSessions,
    idleVisible,
    setIdleVisible
  } = useContext(NavContext);

  // Page Change -> close menu
  useEffect(() => setOpen(false), [location]);

  // Handle setting online state and clearing connection timeout timer
  useEffect(() => {
    const offlineHandler = () => {
      setOnline(false);
    };

    const onlineHandler = () => {
      setOnline(true);
      if (timerId.current) clearTimeout(timerId.current);
    };

    if (isMounted()) {
      window.addEventListener("offline", offlineHandler);
      window.addEventListener("online", onlineHandler);
    }

    return () => {
      if (!isMounted()) {
        window.removeEventListener("offline", offlineHandler);
        window.removeEventListener("online", onlineHandler);
      }
    };
  }, [isMounted, setOnline]);

  const handleTimeout = useCallback(() => {
    if (!online) setIdleVisible(true);
  }, [online, setIdleVisible]);

  const handleDisconnect = useCallback(() => {
    if (timerId.current) clearTimeout(timerId.current);
    if (!online) {
      const time = setTimeout(handleTimeout, 300000);
      timerId.current = time;
    }
  }, [handleTimeout, online]);

  const handleMaintenance = useCallback(
    scheduled => {
      if (scheduled?.status) setMaintenance(scheduled);
      else if (scheduled?.start?.includes(":")) {
        const [hour, min] = scheduled.start.split(":");
        const start = dayjs()
          .set("hour", Number.parseInt(hour, 10))
          .set("minute", Number.parseInt(min, 10))
          .set("second", 0);
        const delayPassed = start.isSameOrBefore(dayjs());
        setMaintenance({...scheduled, status: delayPassed ? "on" : "off"});
      } else if (scheduled?.start === "N/A") setMaintenance({...scheduled, status: "on"});
      else setMaintenance({status: "off"});
    },
    [setMaintenance]
  );

  // Socket Events
  useEffect(() => {
    socket.on(DISCONNECTED, handleDisconnect);
    socket.on(MAINTENANCE, handleMaintenance);

    // -> signout on new sign in
    const manageSessions = () => setShowManageSessions(true);
    socket.on(MANAGE_SESSIONS, manageSessions);

    return () => {
      // unbind all event handlers used in this component
      socket.off(MAINTENANCE, handleMaintenance);
      socket.off(MANAGE_SESSIONS, manageSessions);
      socket.off(DISCONNECTED, handleDisconnect);
    };
  }, [isMounted, socket, slug, handleMaintenance, handleDisconnect, setShowManageSessions]);

  // Handle Account Menu Outside Click
  const accountMenu = useRef(null);

  useEffect(() => {
    const handleOutsideClick = e =>
      e.target !== null &&
      e.target.nextSibling !== null &&
      accountMenu &&
      accountMenu.current !== null &&
      !accountMenu.current.contains(e.target) &&
      !accountMenu.current.contains(e.target.nextSibling) &&
      setShowAccountMenu(false);

    if (accountMenu && showAccountMenu) document.addEventListener("mousedown", handleOutsideClick);

    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [accountMenu, showAccountMenu]);

  // Handle Account Menu Outside Click
  const facilityNav = useRef(null);

  useEffect(() => {
    const handleOutsideClick = e =>
      e.target !== null &&
      facilityNav &&
      facilityNav.current !== null &&
      !facilityNav.current.contains(e.target) &&
      setOpen(false);

    if (!isMobileDevice() && window.innerHeight < "708px" && facilityNav)
      document.addEventListener("mousedown", handleOutsideClick);

    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [facilityNav]);

  // Close dropdown on page change
  useEffect(() => {
    if (isMounted()) setShowAccountMenu(false);
  }, [isMounted, location]);

  const signOut = async () => {
    setShowAccountMenu(false);
    await signout();
    navigate("/signin");
  };

  const toggleTheme = () => {
    const {localStorage: stg, location: loc} = window;
    stg.setItem("theme", stg.getItem("theme") === "dark" ? "light" : "dark");
    loc.reload();
  };

  // Super Users only
  const changeType = userRoleId =>
    api.callPut(currentUser.publicId, {userRoleId}).then(({status}) => {
      if (status === 200) window.location.reload();
    });

  const optOutOfSMS = useCallback(() => {
    api
      .callPut(currentUser.publicId, {sms: false, userRoleId: currentUser?.role?.id})
      .then(({status}) => {
        if (status === 200) {
          getCurrentUser();
          removeBanner();
        }
      });
  }, [api, currentUser, removeBanner, getCurrentUser]);

  useEffect(() => {
    if (
      isMounted() &&
      currentUser &&
      currentUser.details?.sms === undefined &&
      !(pathname === "/account" && hash === "#userDetails") &&
      pathname !== "/first-signin" &&
      pathname !== "/maintenance"
    )
      addBanner(
        <BannerContent>
          You have not opted into SMS notifications. Click{" "}
          <AnchorInline
            href="/account#userDetails"
            target="_blank"
            rel="noreferrer"
            onClick={() => removeBanner()}>
            here
          </AnchorInline>{" "}
          to configure setting.
        </BannerContent>,
        "info",
        optOutOfSMS
      );
  }, [isMounted, currentUser, addBanner, removeBanner, pathname, hash, optOutOfSMS]);

  const time = useMemo(() => {
    if (maintenance?.start && maintenance.start !== "N/A") {
      const {start} = maintenance;
      const provided = start.split(":");
      const hours = provided[0];
      const minutes = provided[1];
      const formatted = convertToUserTimezone(
        dayjs().hour(hours).minute(minutes),
        settings.timezone
      ).format("h:mm a");
      return formatted;
    }
    return null;
  }, [maintenance, settings.timezone]);

  if (!currentUser) return null;

  return (
    <>
      <NavBar>
        <LogoWrapper
          onClick={() =>
            roleCanAccessResource("facility", "view") &&
            roleCanAccessResource("facility_on_test", "view")
          }>
          <IconLink to="/facilities" disabled={currentUser && !currentUser.isAccepted}>
            <Abbr title="Facilities Dashboard">
              {settings && (
                <Logo
                  src={settings.logo || "/logo.png"}
                  alt={`${settings.siteTitle}`}
                  onError={e => {
                    e.target.src = "/logo.png";
                    e.onerror = null;
                  }}
                />
              )}
            </Abbr>
          </IconLink>
          <Hero>Hero Builder™</Hero>
        </LogoWrapper>

        {maintenance?.start && (
          <MaintenanceAlert>
            {maintenance?.status === "off" && maintenance?.start !== "N/A" ? (
              <>Scheduled maintenance begins at {time}.</>
            ) : (
              "Site under Scheduled Maintenance"
            )}
          </MaintenanceAlert>
        )}

        <List>
          {roleCanAccessPage("global_custom_tables") &&
            roleCanAccessResource("custom_table", "view") && (
              <NavListItem>
                <IconLink to="/global-tables" disabled={currentUser && !currentUser.isAccepted}>
                  <Abbr title="Global Tables">
                    <FontAwesomeIcon icon={faTableCellsLarge} alt="Global Tables" />
                  </Abbr>
                </IconLink>
              </NavListItem>
            )}

          {roleCanAccessPage("global_schedule") && roleCanAccessResource("event", "view") && (
            <NavListItem>
              <IconLink to="/schedule" hidden={currentUser && !currentUser.isAccepted}>
                <Abbr title="Global Schedule">
                  <FontAwesomeIcon icon={faCalendar} alt="Global Schedule" />
                </Abbr>
              </IconLink>
            </NavListItem>
          )}

          <NavListItem>
            <MenuWrapper>
              <AccountButton
                type="button"
                id="accountButton"
                onClick={() => setShowAccountMenu(prev => !prev)}>
                <Abbr title="Account Settings">
                  <FontAwesomeIcon icon={faUser} />
                </Abbr>
              </AccountButton>
              <Menu ref={accountMenu} showDropdown={showAccountMenu}>
                {currentUser && <Small>{currentUser.firstName}</Small>}
                <Centered>
                  <ToggleSwitch
                    iconLeft={faSun}
                    iconRight={faMoon}
                    textLeft="Light"
                    textRight="Dark"
                    onChange={() => setTimeout(() => toggleTheme(), [500])}
                    options={["light", "dark"]}
                    defaultSelected={localStorage.getItem("theme")}
                    inset
                  />
                </Centered>
                <MenuLink to="/account" disabled={currentUser && !currentUser.isAccepted}>
                  Account
                </MenuLink>
                {currentUser?.isAccepted &&
                  roleCanAccessPage("user_management") &&
                  roleCanAccessResource("user", "view") && (
                    <MenuLink to="/users">User Management</MenuLink>
                  )}
                {roleCanAccessPage("support") && roleCanAccessResource("ticket", "view") && (
                  <MenuLink to="/support">Support</MenuLink>
                )}
                {currentUser && atLeast("super", true) && roles?.length > 0 && (
                  <Dropdown
                    placeholder="Assume Role..."
                    selection={currentUser.role && currentUser.role.id}
                    options={[
                      {label: "Super", value: -1},
                      ...roles.map(({id, label}) => ({label: toTitleCase(label), value: id}))
                    ]}
                    setSelection={selection => changeType(selection)}
                  />
                )}
                <MenuButton onClick={() => signOut()}>Sign Out</MenuButton>
              </Menu>
            </MenuWrapper>
          </NavListItem>

          {pathname !== "/facilities" && pathname.includes("/facilities") && (
            <Toggle onClick={() => setOpen(prev => !prev)} open={open}>
              <FontAwesomeIcon icon={faBars} />
            </Toggle>
          )}
        </List>
      </NavBar>

      {pathname !== "/facilities/" && pathname.includes("/facilities/") && (
        <FacilityNav innerRef={facilityNav} open={open} setOpen={setOpen} />
      )}

      {showManageSessions && (
        <Modal visible={showManageSessions} setVisible={setShowManageSessions} allowClose={false}>
          <Heading>Multiple Active Sessions</Heading>
          <Text>
            You have resources locked in another session. Continuing on this page will release
            locked resources and may override changes made in your other session.
          </Text>
          <br />
          <Inline>
            <Button
              type="button"
              onClick={() => {
                socket.emit(RELEASE_LOCKS, currentUser.publicId);
                setShowManageSessions(false);
              }}>
              Acknowledge
            </Button>
          </Inline>
        </Modal>
      )}

      {idleVisible && (
        <ModalIdle
          idle={idleVisible}
          setIdle={setIdleVisible}
          online={online}
          setOnline={setOnline}
        />
      )}
    </>
  );
};

// Style
const NavBar = styled.nav`
  position: fixed;
  top: 0;
  height: ${navHeight}px;
  width: 100%;
  padding: ${pad}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: ${border} solid ${({theme}) => theme.primary};
  box-shadow: ${shadow};
  z-index: ${z("peak")};

  background: ${colors.heroBlack}; // fallback
  background: ${({theme}) => (theme.name === "light" ? theme.secondary : theme.tertiary)};
`;

const NavListItem = styled(ListItem)`
  padding-right: ${pad * 2}px;

  &:last-child {
    padding-right: ${pad}px;
  }
`;

const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  height: 100%;
  max-width: 50%;
`;

const IconLink = styled(NavLink)`
  ${voice.medium};
  height: 100%;
  display: flex;
  text-decoration: none;
  color: ${({theme}) => theme.tertiary};
  max-width: 30%;

  svg {
    fill: ${({theme}) => theme.primary};
  }

  ${({disabled}) =>
    disabled &&
    css`
      pointer-events: none;
    `};

  ${({hidden}) =>
    hidden &&
    css`
      display: none;
    `};
`;

const Logo = styled.img`
  height: 100%;
`;

const Hero = styled(Text)`
  ${voice.quiet};
  text-transform: uppercase;
  color: ${({theme}) => theme.primary};
  padding-left: ${pad / 2}px;

  ${bp(3)} {
    ${voice.normal};
  }
`;

const MenuWrapper = styled.div`
  position: relative;
`;

const AccountButton = styled.button`
  width: 25px;
  height: 25px;
  background: ${({theme}) => theme.primary};
  border-radius: 50%;

  svg {
    fill: ${({theme}) => (theme.name === "light" ? theme.secondary : theme.tertiary)};
  }

  ${({disabled}) =>
    disabled &&
    css`
      pointer-events: none;
    `};
`;

const Menu = styled.div`
  position: absolute;
  width: 250px;
  text-align: center;
  padding: ${pad}px 0;
  border-radius: ${radius};
  background: ${({theme}) => theme.secondary};
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.5);
  top: calc(100% + ${navHeight / 2}px);
  right: calc(-300px);
  visibility: hidden;
  transition: all ease 0.2s;
  z-index: ${z("top")};

  max-height: 60vh;
  overflow-y: auto;

  ${({showDropdown}) =>
    showDropdown &&
    css`
      right: -${pad}px;
      visibility: visible;
    `}
`;

const MenuButton = styled.button`
  color: ${({theme}) => theme.tertiary};
  padding: ${pad}px 0;
  ${voice.normal};

  ${({disabled}) =>
    disabled &&
    css`
      pointer-events: none;
    `};
`;

const MenuLink = styled(NavLink)`
  color: ${({theme}) => theme.tertiary};
  padding: ${pad}px 0;
  ${voice.normal};

  ${({disabled}) =>
    disabled &&
    css`
      pointer-events: none;
    `};
`;

const Toggle = styled(Button)`
  ${voice.medium};
  display: flex;
  text-decoration: none;
  padding-left: 0;
  color: ${({theme}) => theme.tertiary};
  background: ${({theme}) => theme.secondary || colors.heroBlack};

  svg {
    fill: ${({theme}) => theme.primary};
  }

  ${`${bp(3)} and (min-height: 708px)`} {
    display: none;
  }
`;

const Centered = styled.div`
  display: flex;
  margin-top: ${pad}px;
  justify-content: center;
`;

const MaintenanceAlert = styled.span`
  ${voice.bold};
  color: ${({theme}) => theme.primary};
`;

const BannerContent = styled.span`
  a {
    color: ${({theme}) => theme.tertiary};
  }
`;

export default Nav;

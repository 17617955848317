import React, {useContext} from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";

// Style
import {flex} from "../style/components/mixins.js";
import {pad} from "../style/components/variables.js";
import {voice} from "../style/components/typography.js";
import {breakpoint} from "../style/components/breakpoints.js";
import {Heading, Page, Text} from "../style/components/general.js";
import {AuthContext} from "../contexts/auth.js";

const Unauthorized = () => {
  const {currentUser, getAccessablePages} = useContext(AuthContext);
  const {allIds, byId} = currentUser.role.permissions.page;
  return (
    <UnauthorizedPage>
      <Wrapper>
        <Heading>Not Authorized</Heading>
        {getAccessablePages(allIds, byId).length > 0 ? (
          <Text>
            Your role is not authorized to view the requested page. You can click your back button
            or&nbsp;
            <HomeLink to="/">Go back to home</HomeLink>. We apologize for the inconvenience.
          </Text>
        ) : (
          <Text>Your role is not authorized to access any pages at this time.</Text>
        )}
      </Wrapper>
    </UnauthorizedPage>
  );
};

export default Unauthorized;

// Style Overrides
const UnauthorizedPage = styled(Page)`
  ${flex("column", "wrap", "flex-start", "center")};
  min-height: 100vh;
  padding: ${pad * 4}px;
`;

const Wrapper = styled.div`
  width: ${breakpoint.width[2]};
  margin: 0 auto;

  ${Heading}, ${Text} {
    text-align: center;
  }
`;

const HomeLink = styled(Link)`
  position: relative;
  display: inline;
  color: ${props => props.theme.primary};
  ${voice.normal};
`;

import React, {useContext} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

// Utils
import {NotificationContext} from "../../contexts/notify.js";
import useApi from "../../hooks/useApi";
import {useSocket} from "../../contexts/socket.js";

// Components
import Modal from "../../components/Modal";

// Style
import {pad} from "../../style/components/variables";
import {Button, Heading, Text} from "../../style/components/general";

import {EVENT_MODIFIED} from "../general/Room.js";

const ModalEventArchive = ({visible, setVisible, goBack, event, getEvents}) => {
  const {facility, id, name, frequency} = event;

  const socket = useSocket();

  const {getNotifications} = useContext(NotificationContext);

  const {api: apiEvents} = useApi("events");

  const archiveEvent = targetDate => {
    const params = {archive: true};
    if (targetDate) params.addition = targetDate;
    apiEvents.callPut(id, params).then(() => {
      setVisible(false);
      getNotifications(facility.slug);
      getEvents();
      socket.emit(EVENT_MODIFIED, facility, id, "delete");
    });
  };

  return (
    <Modal visible={visible} setVisible={setVisible} goBack={goBack} hasBackButton>
      <Heading center>{name}</Heading>

      <ConfirmArchive>
        <Text>
          Are you sure you want to archive this{" "}
          {frequency ? "recurring event (all occurrences will be archived)" : "event"}?
        </Text>
        <ConfirmArchiveButton type="button" onClick={() => archiveEvent()}>
          Confirm Archive
        </ConfirmArchiveButton>
      </ConfirmArchive>
    </Modal>
  );
};

ModalEventArchive.propTypes = {
  setVisible: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  goBack: PropTypes.func.isRequired,
  event: PropTypes.objectOf(PropTypes.any).isRequired,
  getEvents: PropTypes.func.isRequired
};

// Style Overrides

const ConfirmArchive = styled.div`
  margin-top: ${pad}px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ConfirmArchiveButton = styled(Button)`
  margin-top: ${pad / 2}px;
  background: ${props => props.theme.error};
`;

export default ModalEventArchive;

import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilter, faSearch} from "@fortawesome/free-solid-svg-icons";

// Utils
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";
import {AuthContext} from "../../contexts/auth.js";
import {isSmallMobile} from "../../utils/responsive.js";
// import {GROUP} from "../../utils/builder.js";
import {DEFAULT_MARKER, FACILITY_MARKER} from "../../utils/google/maps.js";
import {adjustHexBrightness} from "../../utils/helpers.js";

// Components
import Modal from "../../components/Modal.js";
import Map from "../../components/Map.js";
import MultiSelect from "../../components/MultiSelect.js";
import Badge from "../../components/Badge.js";

// Style
import {z} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {bp, breakpoint} from "../../style/components/breakpoints.js";
import {border, colors, heroTheme, pad, radius, shadow} from "../../style/components/variables.js";
import {
  Loader,
  Button,
  Inline,
  Small,
  Search,
  SearchWrapper,
  SearchIcon
} from "../../style/components/general.js";

const ModalMapAll = ({visible, setVisible, goBack, facility}) => {
  const navigate = useNavigate();

  const isMounted = useMountedState();

  const {atLeast} = useContext(AuthContext);

  const {api: apiAddress, data: markers} = useApi("addresses");
  const {api: apiFacility} = useApi("facilities");

  const [query, setQuery] = useState(null);
  const [refreshMarkers, setRefreshMarkers] = useState(false);
  const [locations, setLocations] = useState(null);
  const [activeMarker, setActiveMarker] = useState(null);
  const [filters, setFilters] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  const [showFilters, setShowFilters] = useState(false);

  const markerIconMap = useMemo(() => {
    const map = {};
    markers?.map(({id, ...rest}) => {
      map[id] = {...rest};
    });
    return map;
  }, [markers]);

  /**
   * Refresh Google Map Markers
   * @param {array} a Addresses
   * @param {object} b Facility Builder
   */
  const getLocations = useCallback(
    (f, idx) => {
      const {addresses: a, builder: b} = f;
      let extracted = [
        ...a.map(address => ({
          label: `${f.type} ${address?.name || "PRIMARY LOCATION"}`,
          ...address,
          marker: {
            FACILITY_MARKER,
            border: adjustHexBrightness(heroTheme.secondary, (idx / f.types.length) * 100),
            weight: 2
          }
        })),
        ...Object.values(b.byId || {})
          .filter(({hasAddress}) => hasAddress)
          .map(({id, name, label, address, help, markerId}) => ({
            id: id || name,
            label: `${f.type} ${label}`,
            ...address,
            details: help ? [...help] : [{}],
            draggable: true,
            marker:
              markerId && markerId in markerIconMap
                ? {
                    id: markerId,
                    ...markerIconMap[markerId],
                    border: adjustHexBrightness(heroTheme.secondary, (idx / f.types.length) * 100),
                    weight: 2
                  }
                : {
                    DEFAULT_MARKER,
                    border: adjustHexBrightness(heroTheme.secondary, (idx / f.types.length) * 100),
                    weight: 2
                  }
          }))
      ];

      if (!query && activeFilters?.length > 0)
        extracted = extracted.filter(({marker}) => activeFilters?.includes(marker.id));
      if (query?.length > 0 && (!activeFilters || activeFilters?.length === 0))
        extracted = extracted.filter(({label}) =>
          label.toLowerCase().includes(query.toLowerCase())
        );
      if (query?.length > 0 && activeFilters?.length > 0)
        extracted = extracted.filter(
          ({label, marker}) =>
            activeFilters?.includes(marker.id) && label.toLowerCase().includes(query.toLowerCase())
        );

      return extracted;
    },
    [markerIconMap, activeFilters, query]
  );

  const loadMap = useCallback(extracted => {
    setLocations(extracted);
    setRefreshMarkers(true);
  }, []);

  // Initial Load
  useEffect(() => {
    if (isMounted()) apiAddress.callGet(null, {markers: true});
  }, [isMounted, apiAddress]);

  useEffect(() => {
    if (isMounted()) {
      apiFacility.callGet(facility.id, {withAllTypes: true}).then(({status, data}) => {
        if (status === 200 && data) {
          const extracted = [];
          data.map((f, idx) => {
            const locs = getLocations(f, idx);
            extracted.push(...locs);
          });

          loadMap(extracted);
        }
      });
    }
  }, [isMounted, apiFacility, facility, getLocations, loadMap]);

  // Outside click handler
  const filterMenu = useRef(null);

  useEffect(() => {
    const handleClickOutsideFilterMenu = e =>
      filterMenu?.current !== null &&
      !filterMenu.current.contains(e.target) &&
      !filterMenu.current.contains(e.target.nextSibling) &&
      setShowFilters(false);

    if (filterMenu) document.addEventListener("mousedown", handleClickOutsideFilterMenu);

    // Cleanup
    return () => document.removeEventListener("mousedown", handleClickOutsideFilterMenu);
  }, [filterMenu]);

  const handleApply = () => {
    setShowFilters(false);
    setActiveFilters(filters);
  };

  const handleClear = () => {
    setFilters([]);
    setActiveFilters([]);
    setShowFilters(false);
  };

  const handleSearch = e => {
    e.preventDefault();
    const search = e.target.value;
    setQuery(search);
  };

  const toBuilder = () => {
    navigate(`/facilities/${facility.slug}/builder`);
    setVisible(false);
  };

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      goBack={goBack}
      hasBackButton
      maxWidth={breakpoint.width[4]}
      allowClose
      allowClickOutsideToClose={!showFilters}
      hasToolbar>
      <SpaceBetween>
        {!facility?.isDeleted && (
          <Button type="button" onClick={toBuilder}>
            Go To Facility {atLeast("creator") ? "Builder" : "Map"}
          </Button>
        )}
        <Inline>
          <SearchWrapper>
            <SearchIcon>
              <FontAwesomeIcon icon={faSearch} />
            </SearchIcon>
            <Search
              name="search"
              type="text"
              placeholder="Search..."
              onChange={e => handleSearch(e)}
            />
          </SearchWrapper>
          <Button type="button" onClick={() => setShowFilters(true)}>
            <Badge count={activeFilters?.length} offset="14px" color={colors.heroGreen} />
            <FontAwesomeIcon icon={faFilter} />
          </Button>
        </Inline>
        {showFilters && (
          <OptionSub ref={filterMenu}>
            <Small>Filter(s)</Small>
            <MultiSelect
              options={[
                {...DEFAULT_MARKER, value: DEFAULT_MARKER.id},
                ...markers.map(({id, label, icon, color}) => ({
                  value: `${id}`,
                  label,
                  icon,
                  color
                }))
              ]}
              setSelection={setFilters}
              defaultSelection={filters}
            />
            <Inline>
              <ApplyFilter type="button" onClick={handleApply}>
                Apply
              </ApplyFilter>
              <ApplyFilter type="button" onClick={handleClear}>
                Clear
              </ApplyFilter>
            </Inline>
          </OptionSub>
        )}
      </SpaceBetween>

      <MapWrapper isMobile={isSmallMobile() ? 1 : 0}>
        {locations ? (
          <Map
            locations={locations}
            activeMarker={activeMarker}
            setActiveMarker={setActiveMarker}
            filters={activeFilters}
            querying={!!query && query !== ""}
            refreshMarkers={refreshMarkers}
            setRefreshMarkers={setRefreshMarkers}
            iconOverride={{
              strokeColor: "#000000",
              strokeWeight: 1.3
              // path: ALLOWED_ICONS[marker?.icon || "map-marker-alt"].icon[4],
            }}
          />
        ) : (
          <Loader />
        )}
      </MapWrapper>
    </Modal>
  );
};

ModalMapAll.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  goBack: PropTypes.func.isRequired,
  facility: PropTypes.objectOf(PropTypes.any).isRequired
};

// Style Overrides
const MapWrapper = styled.div`
  display: block;
  padding: ${pad}px 0;
  width: 100%;
  height: ${({isMobile}) => (isMobile ? 70 : 54)}vh;
`;

const SpaceBetween = styled(Inline)`
  display: block;

  ${bp(2)} {
    margin-top: 0;
    justify-content: space-between;
    display: flex;
  }
`;

const OptionSub = styled.div`
  position: absolute;
  width: 220px;
  right: unset;
  top: 65px;
  left: 149px;
  border: ${border} solid ${({theme}) => theme.primary};
  border-radius: ${radius};
  padding: ${pad}px;
  background: ${({theme}) => theme.tertiary};
  margin-top: ${pad}px;
  z-index: ${z("top")};
  box-shadow: ${shadow};

  color: ${({theme}) => theme.secondary};

  ${bp(2)} {
    top: 37px;
    right: 125px;
    left: unset;
  }

  ${bp(3)} {
    right: 0;
  }
`;

const ApplyFilter = styled(Button)`
  ${voice.quiet};
  display: inline-flex;
  width: fit-content;
  padding: ${pad / 2}px;

  svg {
    fill: ${({theme}) => theme.tertiary};
    align-self: center;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export default ModalMapAll;

import React, {useContext, useState, useEffect, useCallback} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserPlus, faFileExport, faSearch, faFilter} from "@fortawesome/free-solid-svg-icons";
import styled, {css} from "styled-components";

// Contexts
import {AuthContext} from "../../contexts/auth.js";
import {useToast} from "../../contexts/toast.js";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Components
import BaseTable from "../../components/BaseTable.js";
import Pagination from "../../components/Pagination.js";
import Dropdown from "../../components/Dropdown.js";
import PhoneNumbers from "../../components/PhoneNumbers.js";
import Badge from "../../components/Badge.js";
import ModalUserFilter from "../general/ModalUserFilter.js";
import ModalExport from "../../components/ModalExport.js";
import ModalUserAdd from "./ModalUserAdd.js";
import ModalUserAssign from "./ModalUserAssign.js";
import ModalUserEdit from "./ModalUserEdit.js";
import ModalUserDelete from "./ModalUserDelete.js";
import ModalViewAllUsers from "../general/ModalViewAllUsers.js";

// Style
import {bp} from "../../style/components/breakpoints.js";
import {colors, pad} from "../../style/components/variables.js";
import {
  Button,
  Heading,
  Text,
  EditMenuLoader,
  Inline,
  TableWrapper,
  TableHeaderWrap,
  TableMenu,
  TableEditMenu,
  TableEditMenuButton,
  TableFooter,
  Search,
  SearchIcon,
  SearchWrapper,
  Small,
  Abbr
} from "../../style/components/general.js";

const formatUsers = formattedUsers =>
  formattedUsers.map(user => {
    const {
      publicId,
      role,
      department,
      firstName,
      lastName,
      isInvited,
      isAccepted,
      isActive,
      isDeleted,
      email,
      primary,
      phones,
      company
    } = user;

    return {
      ...user,
      publicId: publicId,
      userRoleId: role ? role.label : null,
      userDepartmentId: department,
      isDeleted,
      firstName: (
        <span>
          {firstName} {lastName}&nbsp;
          {isDeleted && (
            <ErrorSmall>
              <Abbr title="User is deleted">deleted!</Abbr>
            </ErrorSmall>
          )}
          {isInvited && !isAccepted && !isDeleted && (
            <Small>
              <Abbr title="User has not accepted invitation">invited</Abbr>
            </Small>
          )}
          {isInvited && isAccepted && !isActive && !isDeleted && (
            <Small>
              <Abbr title="User is disabled">disabled!</Abbr>
            </Small>
          )}
          {!isInvited && !isActive && !isDeleted && (
            <Small>
              <Abbr title="User has not been invited">contact</Abbr>
            </Small>
          )}
        </span>
      ),
      email: <a href={`mailto:${email}`}>{email}</a>,
      phone: primary && <PhoneNumbers primary={primary} phones={phones} />,
      userCompanyId: company ? company.name : "",
      disabled: !isActive || !isAccepted
    };
  });

const TableUsers = () => {
  const isMounted = useMountedState();

  const {atLeast, roleCanAccessResource} = useContext(AuthContext);
  const {addToast} = useToast();

  const [loadingUsers, setLoadingUsers] = useState(true);
  const [sendLoading, setSendLoading] = useState(false);
  const [original, setOriginal] = useState([]);
  const [users, setUsers] = useState();
  // Edit Options
  const [edit, setEdit] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  // Search
  const [query, setQuery] = useState("");
  // Filters
  const [activeFilters, setActiveFilters] = useState();
  const [showHidden, setShowHidden] = useState(false);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageTotal, setPageTotal] = useState(1);
  const [orderBy, setOrderBy] = useState("asc");
  const [groupBy, setGroupBy] = useState("firstName");
  const [limits, setLimits] = useState([]);
  const [limit, setLimit] = useState(10);
  // Modals
  const [showModalFilters, setShowModalFilters] = useState(false);
  const [showModalExport, setShowModalExport] = useState(false);
  const [showModalUserAdd, setShowModalUserAdd] = useState(false);
  const [showModalUserAssign, setShowModalUserAssign] = useState(false);
  const [showModalUserEdit, setShowModalUserEdit] = useState(false);
  const [showModalDelete, setShowModalUserDelete] = useState(false);
  const [showModalViewAll, setShowModalViewAll] = useState(false);

  const {api: apiUsers} = useApi("users");
  const {api: apiSuppressed} = useApi("users", {suppress: {success: true, error: true}});
  const {api: apiSend} = useApi("send-invite");

  const getUsers = useCallback(
    p => {
      setLoadingUsers(true);

      const filter = {};
      if (query) filter.Search = query;
      if (activeFilters?.departments) filter.Departments = activeFilters.departments;
      if (activeFilters?.roles) filter.Roles = activeFilters.roles;
      if (showHidden) {
        filter.showHidden = true;
        if (activeFilters?.state) filter.State = activeFilters.state;
      }

      apiUsers
        .callGet("", {
          page: p || 1,
          orderBy,
          groupBy,
          limit,
          userManagement: true,
          filter: JSON.stringify(filter)
        })
        .then(({status, data}) => {
          if (status === 200 && data) {
            setCurrentPage(data.page);
            setPageTotal(data.pages);
            setOriginal(data.users);
            const formatted = formatUsers(data.users);
            setUsers(formatted);

            // Page Limit
            const {total: dataTotal} = data;
            setTotal(dataTotal);
            let count = 10;
            const temp = [];
            while (count < dataTotal + 10 && count <= 30) {
              temp.push(count);
              count += 10;
            }

            setLimits(temp);
            setShowOptions(false);
            setEdit([]);
            setLoadingUsers(false);
          }
        });
    },
    [apiUsers, orderBy, groupBy, limit, query, activeFilters, showHidden]
  );

  // Initial Load
  useEffect(() => {
    if (isMounted() && !users) getUsers();
  }, [isMounted, getUsers, users]);

  // Update users on search, filter, sort or limit
  useEffect(() => {
    if (isMounted()) {
      setUsers(undefined);
      setCurrentPage(1);
    }
  }, [isMounted, query, activeFilters, showHidden, orderBy, groupBy, limit]);

  useEffect(() => {
    if (edit.length === 1)
      original.map(user => {
        if (user.publicId === edit[0].publicId) setSelectedUser(user);
      });
  }, [edit, original, setSelectedUser]);

  const handleSearch = e => {
    setCurrentPage(1);
    setQuery(e.target.value);
  };

  const handleReload = () => {
    setCurrentPage(1);
    getUsers();
  };

  // Reset Edit
  useEffect(() => {
    if (!showOptions) setEdit([]);
  }, [showOptions]);

  const restore = () => {
    const [user] = edit;
    const payload = {restore: true};
    apiSuppressed.callPut(user.publicId, payload).then(({status}) => {
      if (status === 200) {
        addToast("User restored", "success");
        setUsers(null);
      }
      addToast("Failed to restore user", "error");
    });
  };

  return (
    <>
      <TableWrapper>
        <TableHeaderWrap>
          <StyledInline>
            <Heading>Users</Heading>
            <InlineSpaced>
              <SearchWrapper>
                <SearchIcon>
                  <FontAwesomeIcon icon={faSearch} />
                </SearchIcon>
                <Search
                  name="search"
                  type="text"
                  placeholder="Search..."
                  onChange={e => handleSearch(e)}
                />
              </SearchWrapper>
              <TableMenu>
                {atLeast("admin") && users?.length > 0 && (
                  <Option
                    type="button"
                    onClick={() => {
                      if (showOptions) setEdit([]);
                      setShowOptions(!showOptions);
                    }}>
                    {showOptions ? "Cancel" : "Options"}
                  </Option>
                )}
                {atLeast("admin") &&
                  (users?.length > 0 ||
                    (activeFilters && Object.keys(activeFilters)?.length > 0) ||
                    showHidden) && (
                    <Option type="button" onClick={() => setShowModalFilters(!showModalFilters)}>
                      <Badge
                        count={
                          (activeFilters?.departments ? activeFilters.departments.length : 0) +
                          (activeFilters?.roles ? activeFilters.roles.length : 0) +
                          (showHidden && activeFilters?.state?.length
                            ? activeFilters.state.length
                            : 0) +
                          (showHidden && !activeFilters?.state?.length ? 1 : 0)
                        }
                        offset="14px"
                        color={colors.heroGreen}
                      />
                      <FontAwesomeIcon icon={faFilter} />
                    </Option>
                  )}
                {roleCanAccessResource("user", "export") && users && users.length > 0 && (
                  <Option type="button" onClick={() => setShowModalExport(true)}>
                    <Abbr title="Export">
                      <FontAwesomeIcon icon={faFileExport} />
                    </Abbr>
                  </Option>
                )}
                {roleCanAccessResource("user", "create") && (
                  <Option
                    type="button"
                    aria-label="add user"
                    data-testid="openUserModal"
                    onClick={() => setShowModalUserAdd(true)}>
                    <Abbr title="Add User">
                      <FontAwesomeIcon icon={faUserPlus} />
                    </Abbr>
                  </Option>
                )}
              </TableMenu>
            </InlineSpaced>
          </StyledInline>
        </TableHeaderWrap>
        <BaseTable
          headings={{
            publicId: {header: "", disabled: true},
            userRoleId: {header: "Role", disabled: false},
            userDepartmentId: {header: "Department", disabled: false},
            firstName: {header: "Name", disabled: false},
            email: {header: "Email", disabled: false},
            phone: {header: "Phone", disabled: true},
            userCompanyId: {header: "Company", disabled: false}
          }}
          data={users}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          groupBy={groupBy}
          setGroupBy={setGroupBy}
          items={edit}
          setItems={setEdit}
          showOptions={showOptions}
          setShowOptions={setShowOptions}
          loading={loadingUsers}
          editMenu={
            <TableEditMenu>
              {edit.length > 0 && !edit.some(user => user.isDeleted) && (
                <TableEditMenuButton type="button" onClick={() => setShowModalUserAssign(true)}>
                  Assign Users(s)
                </TableEditMenuButton>
              )}
              {edit.length === 1 && !edit[0].isDeleted && (
                <>
                  <TableEditMenuButton
                    type="button"
                    onClick={() => {
                      setSendLoading(true);
                      apiSend.callPatch(null, {publicId: edit[0].publicId}).then(() => {
                        setSendLoading(false);
                        setShowOptions(false);
                      });
                    }}>
                    Send/Resend Invite
                    {sendLoading && <EditMenuLoader />}
                  </TableEditMenuButton>
                  {roleCanAccessResource("user", "update") && (
                    <TableEditMenuButton type="button" onClick={() => setShowModalUserEdit(true)}>
                      Edit
                    </TableEditMenuButton>
                  )}
                </>
              )}
              {edit.length === 1 &&
                edit[0].isDeleted &&
                roleCanAccessResource("user", "delete") && (
                  <TableEditMenuButton type="button" onClick={() => restore()}>
                    Restore
                  </TableEditMenuButton>
                )}
              {edit.length >= 1 &&
                !edit[0].isDeleted &&
                roleCanAccessResource("user", "delete") && (
                  <TableEditMenuButton type="button" onClick={() => setShowModalUserDelete(true)}>
                    Delete
                  </TableEditMenuButton>
                )}
            </TableEditMenu>
          }
        />
        <TableFooter>
          {total > 0 && (
            <>
              <Inline>
                {total > 10 && limits.length > 0 && (
                  <PageLimit>
                    <Dropdown
                      options={limits}
                      selection={limit}
                      setSelection={selection => {
                        setLimit(selection);
                        setCurrentPage(1);
                      }}
                    />
                  </PageLimit>
                )}
                <Text quiet>
                  {limit <= 30 && pageTotal > 1 && limits.length > 0 && "per page, "}
                  <ModalViewLink
                    disabled={!total || total <= 10}
                    onClick={!total || total <= 10 ? undefined : () => setShowModalViewAll(true)}>
                    {total} total
                  </ModalViewLink>
                </Text>
              </Inline>
              {limit <= 30 && pageTotal > 1 && (
                <Pagination
                  current={currentPage}
                  setCurrent={setCurrentPage}
                  pageTotal={pageTotal}
                  updateData={() => getUsers(currentPage)}
                  loading={loadingUsers}
                />
              )}
            </>
          )}
        </TableFooter>
      </TableWrapper>

      {showModalFilters && (
        <ModalUserFilter
          visible={showModalFilters}
          setVisible={setShowModalFilters}
          filters={activeFilters}
          setFilters={setActiveFilters}
          showHidden={showHidden}
          setShowHidden={setShowHidden}
        />
      )}

      {showModalExport && (
        <ModalExport
          visible={showModalExport}
          setVisible={setShowModalExport}
          getExport={exportParams => apiUsers.callGet(null, {...exportParams})}
        />
      )}

      {showModalUserAdd && (
        <ModalUserAdd
          visible={showModalUserAdd}
          setVisible={setShowModalUserAdd}
          updateTable={getUsers}
        />
      )}

      {edit && showModalUserAssign && (
        <ModalUserAssign
          visible={showModalUserAssign}
          setVisible={setShowModalUserAssign}
          users={edit}
          setUsers={setEdit}
          setShowOptions={setShowOptions}
        />
      )}

      {showModalUserEdit && (
        <ModalUserEdit
          visible={showModalUserEdit}
          setVisible={setShowModalUserEdit}
          selected={selectedUser}
          updateTable={getUsers}
        />
      )}

      {showModalDelete && (
        <ModalUserDelete
          visible={showModalDelete}
          setVisible={setShowModalUserDelete}
          selected={edit}
          updateTable={getUsers}
        />
      )}

      {showModalViewAll && (
        <ModalViewAllUsers
          visible={showModalViewAll}
          setVisible={setShowModalViewAll}
          reload={handleReload}
        />
      )}
    </>
  );
};

// Style Overrides
const PageLimit = styled.div`
  margin-right: ${pad}px;

  select {
    padding: ${pad / 2}px;
  }
`;

const StyledInline = styled(Inline)`
  gap: ${pad}px;
  display: block;
  width: 100%;

  ${bp(1)} {
    display: flex;
  }
`;

const InlineSpaced = styled(Inline)`
  justify-content: space-between;
  width: 100%;
`;

const Option = styled(Button)`
  margin-left: ${pad}px;
`;

const ErrorSmall = styled(Small)`
  color: ${({theme}) => theme.error};
`;

const ModalViewLink = styled.span`
  ${({disabled}) =>
    !disabled &&
    css`
      text-decoration: underline;
      cursor: pointer;
    `}
`;

export default TableUsers;

import React, {useState} from "react";
import PropTypes from "prop-types";

// Utils
import useApi from "../../hooks/useApi.js";

// Components
import ModalDelete from "../../components/ModalDelete.js";

const ModalUserDelete = ({visible, setVisible, selected, updateTable, hasBackButton, goBack}) => {
  const {api} = useApi("users");
  const [loadingDelete, setLoadingDelete] = useState(false);

  const handleSubmit = () => {
    setLoadingDelete(true);
    const ids = selected.map(current => current.publicId);
    api.callDelete(null, {data: {ids: ids}}).then(async response => {
      if (response) {
        updateTable();
        if (goBack) goBack();
        else setVisible(false);
      }
      setLoadingDelete(false);
    });
  };

  return (
    <ModalDelete
      visible={visible}
      setVisible={setVisible}
      title="Delete Users"
      prompt={
        selected &&
        `Are your sure you want to delete ${selected.firstName}'s account? This action cannot be undone.`
      }
      confirmDelete={handleSubmit}
      loading={loadingDelete}
      hasBackButton={hasBackButton}
      goBack={goBack}
    />
  );
};

ModalUserDelete.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  updateTable: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.any),
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func
};

ModalUserDelete.defaultProps = {
  selected: null,
  hasBackButton: false,
  goBack: null
};

export default ModalUserDelete;

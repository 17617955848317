import React, {useEffect} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {useFieldArray, useFormContext} from "react-hook-form";
import {Droppable, Draggable, DragDropContext} from "react-beautiful-dnd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsAlt} from "@fortawesome/free-solid-svg-icons";

// Components
import {InputText} from "./FormInputs.js";

// Style
import {pad} from "../../style/components/variables.js";
import {FormFieldWrapper, Label, Button} from "../../style/components/general.js";

const InputTextGroupDraggable = ({
  name,
  label,
  placeholder,
  required,
  canSetKey,
  triggerAppend,
  testId
}) => {
  const {control, watch} = useFormContext();

  const {fields, append, remove, insert} = useFieldArray({control, name});

  // re-ordering list
  const onDragEnd = result => {
    const {destination, source} = result;
    if (!destination || source.index === destination.index) {
      return;
    }
    const movingField = watch(`${name}.${source.index}`);
    let sourceOffset = 0;
    let destinationOffset = 1;
    if (destination.index < source.index) {
      sourceOffset = 1;
      destinationOffset = 0;
    }
    insert(destination.index + destinationOffset, {...movingField});
    remove(source.index + sourceOffset);
  };

  useEffect(() => {
    if (triggerAppend) append(triggerAppend);
  }, [triggerAppend, append]);

  return (
    <FormFieldWrapper standard>
      {label && (
        <Label htmlFor={name} bold>
          {label.toUpperCase()}
          {required && <span>&nbsp;*</span>}
        </Label>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={name}>
          {outerProvided => (
            <div {...outerProvided.droppableProps} ref={outerProvided.innerRef}>
              {fields &&
                fields.length > 0 &&
                fields.map((option, index) => (
                  <Draggable
                    draggableId={`${name}.${index}`}
                    index={index}
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${name}.${index}`}
                    isDragDisabled={fields.length <= 1}>
                    {innerProvided => (
                      <div {...innerProvided.draggableProps} ref={innerProvided.innerRef}>
                        <StyledInlineFormField fieldsLength={fields.length}>
                          {fields.length > 1 && (
                            <div {...innerProvided.dragHandleProps}>
                              <StyledDragHandle type="button">
                                <FontAwesomeIcon style={{fontSize: "20px"}} icon={faArrowsAlt} />
                              </StyledDragHandle>
                            </div>
                          )}

                          {canSetKey && (
                            <InputText
                              name={`${name}.${index}.key`}
                              placeholder={placeholder || "Key"}
                              defaultValue={option.key}
                              testId={`${testId}-key`}
                            />
                          )}

                          <InputText
                            name={`${name}.${index}.${canSetKey ? "value" : "option"}`}
                            placeholder="Value"
                            defaultValue={option.value}
                            testId={`${testId}-value`}
                          />

                          <StyledEdit
                            disable={index === 0 && fields.length === 1}
                            type="button"
                            data-testid={`${testId}.removeInput`}
                            onClick={() => remove(index)}>
                            -
                          </StyledEdit>
                          <StyledEdit
                            type="button"
                            data-testid={`${testId}.addInput`}
                            onClick={() => {
                              insert(index + 1, "");
                            }}>
                            +
                          </StyledEdit>
                          {innerProvided.placeholder}
                        </StyledInlineFormField>
                      </div>
                    )}
                  </Draggable>
                ))}
              {outerProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </FormFieldWrapper>
  );
};

InputTextGroupDraggable.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  canSetKey: PropTypes.bool,
  triggerAppend: PropTypes.objectOf(PropTypes.any),
  hasCheckbox: PropTypes.bool,
  checkboxLabel: PropTypes.string,
  checkboxName: PropTypes.string
};

InputTextGroupDraggable.defaultProps = {
  testId: "input-text-group-draggable",
  label: null,
  placeholder: null,
  required: false,
  canSetKey: false,
  triggerAppend: null,
  hasCheckbox: false,
  checkboxLabel: "",
  checkboxName: ""
};

// Style Overrides

const StyledInlineFormField = styled(FormFieldWrapper)`
  flex-direction: row;
  margin-bottom: ${pad}px;
  margin-top: ${pad / 2}px;
  align-items: space-between;

  div {
    margin: 0;

    ${props =>
      props.fieldsLength > 1
        ? css`
            &:nth-child(2) {
              margin-left: ${pad}px;
            }

            &:nth-child(3) {
              margin-left: ${pad}px;
            }
          `
        : css`
            &:nth-child(2) {
              margin-left: ${pad}px;
            }
          `}
  }
`;

const StyledEdit = styled(Button)`
  padding: ${pad / 2}px ${pad * 1.5}px;
  margin-left: ${pad}px;

  ${props =>
    props.disable &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`;

const StyledDragHandle = styled(Button)`
  padding: ${pad * 0.9}px ${pad}px ${pad * 0.7}px ${pad}px;
  margin-left: 0;
  pointer-events: none;
`;

export default InputTextGroupDraggable;

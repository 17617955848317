import React, {useState, useEffect} from "react";
import {Navigate, Link} from "react-router-dom";
import styled, {css} from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

// Utils
import useApi from "../hooks/useApi.js";

// Components
import {InputPassword} from "../components/form/FormInputs.js";
import Footer from "./general/Footer.js";

// Style
import {flex, z} from "../style/components/mixins.js";
import {voice} from "../style/components/typography.js";
import {bp, breakpoint} from "../style/components/breakpoints.js";
import {border, heroTheme, navHeight, pad, radius} from "../style/components/variables.js";
import {animateBackground} from "../style/components/animations.js";
import {Form, FormGroup} from "../style/components/general.js";

const schema = yup.object().shape({
  password: yup
    .string()
    .required("Please provide password")
    .min(6, "Password must be at least 6 characters")
});

const ResetPassword = () => {
  const [passwordReset, setPasswordReset] = useState(false);
  const [token, setToken] = useState("");

  const form = useForm({
    resolver: yupResolver(schema)
  });
  const {handleSubmit} = form;

  const {api} = useApi("reset-password");

  useEffect(() => {
    const {search} = window.location;
    const params = new URLSearchParams(search);
    const providedToken = params.get("token");
    setToken(providedToken);
  }, []);

  const resetPassword = ({password}) => {
    api
      .callPut(null, {
        token,
        new: password
      })
      .then(() => setPasswordReset(true));
  };

  return !passwordReset ? (
    <ResetPasswordPage>
      <Header>
        <Logo src={`${process.env.PUBLIC_URL}/hero-services.png`} alt="Hero Services Logo" />
      </Header>
      <BackButton to="/signin">
        <FontAwesomeIcon icon={faArrowLeft} />
        &nbsp;Back to Sign In
      </BackButton>
      <PageWrapper>
        <Content>
          <PageHeader>Reset Password</PageHeader>
          <FormProvider {...form}>
            <Form onSubmit={handleSubmit(resetPassword)} noValidate>
              <FormGroup>
                <InputPassword name="password" />
                <Send type="submit">Reset Password</Send>
              </FormGroup>
            </Form>
          </FormProvider>
        </Content>
      </PageWrapper>
      <Footer />
    </ResetPasswordPage>
  ) : (
    <Navigate
      to={{
        pathname: "/signin"
      }}
    />
  );
};

// Style Overrides
const ResetPasswordPage = styled.section`
  position: relative;
  width: 100vw;
  height: 100vh;
  ${flex("column", "nowrap", "start", "center")};
`;

const Header = styled.nav`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  align-self: start;
  display: flex;
  background: ${heroTheme.secondary};
  z-index: ${z("top")};

  ${props =>
    props.theme.name === "dark" &&
    css`
      border-bottom: ${border} solid ${heroTheme.tertiary};
    `}
`;

const Logo = styled.img`
  height: 75px;
  margin: ${pad}px auto;
`;

const BackButton = styled(Link)`
  position: absolute;
  top: ${navHeight}px;
  left: 0;
  width: max-content;
  margin: ${pad * 4}px ${pad * 2}px;
  color: ${heroTheme.primary};
  ${voice.quiet};

  ${bp(3)} {
    ${voice.small};
  }
`;

const PageWrapper = styled.section`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

const Content = styled.div`
  width: 395px;
  max-width: 100%;
  padding: ${pad}px;
  margin: 0 auto;
  ${flex("column", "nowrap", "center", "center")};

  ${bp(3)} {
    max-width: ${breakpoint.width[2]};
  }
`;

const PageHeader = styled.h1`
  color: ${heroTheme.secondary};
  margin-bottom: ${pad}px;
  text-align: center;
  font-weight: bold;
  ${voice.strong};

  ${bp(5)} {
    ${voice.loud};
  }

  ${props =>
    props.theme.name === "dark" &&
    css`
      color: ${heroTheme.tertiary};
    `}
`;

const Send = styled.button`
  position: relative;
  border: 0;
  align-self: center;
  padding: ${pad}px;
  margin-top: ${pad}px;
  color: ${heroTheme.tertiary};
  border-radius: ${radius};
  background-color: ${heroTheme.secondary};

  ${props =>
    props.theme.name === "dark" &&
    css`
      background-color: ${heroTheme.primary};
    `};

  ${props =>
    props.loading &&
    css`
      margin-bottom: ${pad}px;
      position: relative;
      display: inline-block;
      opacity: 100%;
      overflow: hidden;
      animation: ${animateBackground} 0.15s linear 0s 1 forwards;
      animation-delay: 0.3s;
      pointer-events: none;
    `}
`;

export default ResetPassword;

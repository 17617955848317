import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Components
import Modal from "../../components/Modal.js";

// Style
import {HeadingCenter, Form, Text, Button, FormGroup} from "../../style/components/general.js";
import {flex} from "../../style/components/mixins.js";
import {pad} from "../../style/components/variables.js";

const ModalDeleteElement = ({visible, setVisible, targetElement, persistDelete}) => {
  const handleSubmit = e => {
    e.preventDefault();
    persistDelete();
  };

  const renderMessage = () => (
    <Text>
      Are you sure you want to delete this {targetElement.element}? This action cannot be undone.
    </Text>
  );

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <ModalTitle>Delete Element</ModalTitle>

      <Form onSubmit={handleSubmit} noValidate>
        <FormGroup>{renderMessage()}</FormGroup>
        <Options>
          <Option type="button" onClick={() => setVisible(false)}>
            Cancel
          </Option>
          <Delete type="submit" data-testid="delete.confirm">
            Delete
          </Delete>
        </Options>
      </Form>
    </Modal>
  );
};

ModalDeleteElement.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  persistDelete: PropTypes.func.isRequired,
  targetElement: PropTypes.objectOf(PropTypes.any).isRequired
};

// Style Overrides
const ModalTitle = styled(HeadingCenter)`
  margin-bottom: ${pad * 2}px;
`;

const Options = styled.div`
  ${flex("row", "nowrap", "start", "start")};
  width: 100%;
`;

const Option = styled(Button)`
  margin: 0 ${pad / 2}px;
`;

const Delete = styled(Option)`
  background: ${props => props.theme.error};
  color: ${props => props.theme.tertiary};

  &:disabled {
    opacity: 0.6;
  }
`;

export default ModalDeleteElement;

import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faArrowRight} from "@fortawesome/free-solid-svg-icons";

// Utils
import {getMapFromCoords, openLink, prettyDateInUserTimezone} from "../../utils/helpers.js";
import useApi from "../../hooks/useApi.js";
import {isMobileDevice} from "../../utils/responsive.js";
import {SettingsContext} from "../../contexts/settings.js";

// Components
import {ModalControls} from "../../components/Modal.js";

// Style
import {border, pad, radius, shadow} from "../../style/components/variables.js";
import {voice} from "../../style/components/typography.js";
import {
  CalloutContent,
  Text,
  Inline,
  Button,
  NotLoaded,
  Loader,
  Small,
  CloseButton,
  HeadingSmall,
  RelativeWrapper
} from "../../style/components/general.js";

const NotesHistoryCalloutContent = ({
  setVisible,
  setShowHistory,
  groupName,
  groupLabel,
  taskId,
  taskType,
  hasPending
}) => {
  const {settings} = useContext(SettingsContext);
  const [recordIdx, setRecordIdx] = useState(0);
  const [hideControls, setHideControls] = useState(true);

  const {api, data, loading} = useApi(taskType === "event" ? "event-record-notes" : "record-notes");
  const {api: fileApi} = useApi("files");

  const openFile = fileId => {
    fileApi.callGet(fileId).then(res => {
      if (res.status === 200) openLink(res.data.link);
    });
  };

  useEffect(() => {
    const params =
      taskType === "event"
        ? {stage: groupName, page: recordIdx, taskId}
        : {group: groupName, page: recordIdx, taskId};
    api.callGet(null, params).then(() => setHideControls(false));
  }, [api, taskId, groupName, taskType, recordIdx]);

  const buildNotesDisplay = notes =>
    notes.map(({id, name, message, user, coords, files}) => (
      <NoteWrapper key={`${name}-${id}`} data-testid="preview.noteWrapper">
        <Message>{message}</Message>
        {coords && (
          <AddressLink
            href={getMapFromCoords(coords.lat, coords.lon)}
            target="_blank"
            rel="noreferrer">
            View in Maps
          </AddressLink>
        )}
        {files && files.length > 0 && (
          <Files>
            <hr />
            <p>Attached Files</p>
            {files.map(file => (
              <FileLink onClick={() => openFile(file.id)} key={`file-${file.id}`}>
                {file.label}
              </FileLink>
            ))}
          </Files>
        )}
        {user && <User>By: {user}</User>}
      </NoteWrapper>
    ));

  return (
    <SmallCalloutContent
      mobile={isMobileDevice() ? 1 : 0}
      className="calloutContent"
      isEvent={taskType === "event"}>
      <div width="95%">
        {hasPending ? (
          <Back type="button" onClick={() => setShowHistory(false)}>
            <FontAwesomeIcon icon={faArrowLeft} />
            &nbsp;&nbsp;Go Back
          </Back>
        ) : (
          <ModalControls>
            <CloseButton data-testid="closeButton" onClick={() => setVisible(false)} />
          </ModalControls>
        )}
      </div>

      <ModalTitle center>
        Note History for &nbsp;
        <GroupLabel isEvent={taskType === "event"}>{groupLabel}</GroupLabel>
      </ModalTitle>

      <Text>
        {data && data.date
          ? prettyDateInUserTimezone(data.date, settings.timezone, "MM/DD/YY")
          : ""}
      </Text>

      {data && (
        <RelativeWrapper>
          {(data.postedNotes && data.postedNotes.length > 0) ||
          (data.resolvedNotes && data.resolvedNotes.length > 0) ? (
            <>
              <NotesHeader>Pending Notes</NotesHeader>
              <hr />
              {data.postedNotes && data.postedNotes.length > 0 ? (
                buildNotesDisplay(data.postedNotes)
              ) : (
                <NoNotes>No Pending Notes</NoNotes>
              )}

              <NotesHeader>Resolved Notes</NotesHeader>
              <hr />
              {data.resolvedNotes && data.resolvedNotes.length > 0 ? (
                buildNotesDisplay(data.resolvedNotes, false)
              ) : (
                <NoNotes>No Resolved Notes</NoNotes>
              )}
            </>
          ) : (
            <NoNotes>No History Found</NoNotes>
          )}
        </RelativeWrapper>
      )}
      {loading && (
        <StyledNotLoaded>
          <Loader />
        </StyledNotLoaded>
      )}
      {!loading &&
      !hideControls &&
      ((data.postedNotes && data.postedNotes.length > 0) ||
        (data.resolvedNotes && data.resolvedNotes.length > 0)) ? (
        <RecordSelect>
          {recordIdx > 0 && (
            <Button
              type="button"
              onClick={() => {
                setHideControls(true);
                setRecordIdx(prev => prev + 1);
              }}>
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
          )}
          {data?.hasNext && (
            <Button
              type="button"
              onClick={() => {
                setHideControls(true);
                setRecordIdx(prev => prev - 1);
              }}>
              <FontAwesomeIcon icon={faArrowRight} />
            </Button>
          )}
        </RecordSelect>
      ) : (
        <br />
      )}
    </SmallCalloutContent>
  );
};

NotesHistoryCalloutContent.propTypes = {
  setVisible: PropTypes.func.isRequired,
  setShowHistory: PropTypes.func.isRequired,
  groupName: PropTypes.string.isRequired,
  groupLabel: PropTypes.string.isRequired,
  taskId: PropTypes.number.isRequired,
  taskType: PropTypes.string,
  hasPending: PropTypes.bool.isRequired
};

NotesHistoryCalloutContent.defaultProps = {
  taskType: "checksheet"
};

// Style Overrides
const ModalTitle = styled(HeadingSmall)`
  margin: 0 0 ${pad}px 0;
  text-align: left;
  align-self: flex-start;
`;

const Message = styled(Text)`
  margin-bottom: ${pad / 2}px;
  max-width: calc(100% - 40px);

  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
`;

const User = styled(Text)`
  margin-bottom: ${pad / 2}px;
  ${voice.quiet};
`;

const GroupLabel = styled.span`
  color: ${props => (props.isEvent ? props.theme.secondary : props.theme.group)};
`;

const FileLink = styled.button`
  ${voice.quiet}
  font-weight: bold;
  color: ${props => props.theme.primary};
  width: fit-content;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

const NoteWrapper = styled.div`
  position: relative;
  border: ${border} solid ${props => props.theme.primary};
  border-radius: ${radius};
  padding: ${pad}px;
  width: 100%;
  margin-bottom: ${pad}px;
  flex: 1 auto;
`;

const Files = styled.div`
  ${voice.quiet}

  span {
    font-weight: bold;
  }

  a {
    color: ${props => props.theme.primary};

    :hover {
      text-decoration: underline;
    }
  }
`;

const AddressLink = styled.a`
  color: ${props => props.theme.primary};

  p {
    color: ${props => props.theme.primary};
  }

  &:hover {
    text-decoration: underline;
  }
`;

const SmallCalloutContent = styled(CalloutContent)`
  max-height: 50vh;
  box-shadow: ${shadow};
  overflow-x: hidden;
`;

const Back = styled.button`
  width: auto;
  color: ${props => props.theme.secondary};
  padding: ${pad}px 0;
`;

const RecordSelect = styled(Inline)`
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: ${pad}px;

  ${Button}:disabled {
    opacity: 0.5;
  }
`;

const NotesHeader = styled.h1`
  margin-top: ${pad}px;
`;

const NoNotes = styled(Small)`
  margin-left: ${pad / 2}px;
`;

const StyledNotLoaded = styled(NotLoaded)`
  min-height: 15px;
`;

export default NotesHistoryCalloutContent;

import React, {useState, useContext, useEffect, useRef, Fragment, useMemo} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import dayjs from "dayjs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faSync,
  faAlignLeft,
  faLock,
  faArrowRight,
  faMapMarkerAlt,
  faNoteSticky,
  faArchive,
  faArrowsLeftRight
} from "@fortawesome/free-solid-svg-icons";

// Contexts
import {SettingsContext} from "../../contexts/settings.js";
import {AuthContext} from "../../contexts/auth.js";
import {useSocket} from "../../contexts/socket.js";
import {CalendarContext} from "../../contexts/calendar.js";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Utils
import {getIcon, getNumberSuffix} from "./helpers.js";
import {formatSubmissions} from "../../utils/builder.js";
import {
  exists,
  getCompletionRate,
  getReadableKey,
  openLink,
  prettyDateInUserTimezone,
  toTitleCase
} from "../../utils/helpers.js";
import {DEFAULT_MARKER} from "../../utils/google/maps.js";

// Components
import RenderChecksheet from "../checksheet-builder/RenderChecksheet.js";
import Modal from "../../components/Modal.js";
import ViewRecord from "./ViewRecord.js";

// Style
import {z} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {colors, heroTheme, pad} from "../../style/components/variables.js";
import {
  Text,
  Label,
  ListItem,
  List,
  Heading,
  Button,
  Small,
  Inline,
  Pill,
  RelativeWrapper,
  Abbr
} from "../../style/components/general.js";

// Socket Constants
import {EVENT_MODIFIED, LOCK, RELEASE, STAGE_COMPLETED, getFacilityRooms} from "../general/Room.js";

const ModalEventDetail = ({
  setVisible,
  visible,
  isGlobal,
  event,
  date,
  keyHolder,
  completing,
  setCompleting,
  markerIconMap,
  renderModalControls
}) => {
  const isMounted = useMountedState();

  const socket = useSocket();

  const {currentUser, roleCanAccessResource} = useContext(AuthContext);

  const {settings} = useContext(SettingsContext);

  const {records, getRecords} = useContext(CalendarContext);

  const {
    id: eventId,
    facility,
    name,
    type,
    frequency,
    until,
    responses,
    stages,
    group,
    isArchived,
    message,
    availableBase,
    availableIndex
  } = event;

  const {base, interval} = frequency || {};

  const pastDueDate = useMemo(() => {
    const now = dayjs();
    return now.isAfter(date, "day");
  }, [date]);

  const formatted = useMemo(() => dayjs(date).format("MMM D YYYY"), [date]);
  const unformatted = useMemo(() => dayjs(date).format("YYYY-MM-DD"), [date]);
  const record = useMemo(
    () =>
      records && records[unformatted] && eventId in records[unformatted]
        ? records[unformatted][eventId]
        : null,
    [records, unformatted, eventId]
  );

  const availableDate = useMemo(() => {
    const day = dayjs(date);

    if (availableBase && availableIndex) {
      let offset = null;

      // offset is the number of months the due date must be shifted
      // backwards to make it so that the due date checked falls on the end
      // of the availability window

      if (base && interval) {
        if (availableBase === "months") {
          let totalLength;

          if (base === "months") totalLength = interval;
          else totalLength = 12;

          offset = totalLength - availableIndex;
        } else {
          offset = interval - availableIndex;
        }

        return day.subtract(offset, availableBase).format("dddd, MMM D YYYY");
      }
    }

    return null;
  }, [availableBase, availableIndex, date, base, interval]);

  const overdueArchived = useMemo(
    () => record?.isArchived && record.status === "Overdue",
    [record]
  );

  const [createLoading, setCreateLoading] = useState(false);
  const [draftLoading, setDraftLoading] = useState(false);
  const [currentStage, setCurrentStage] = useState(null);
  const [currentStageId, setCurrentStageId] = useState(null);
  const [currentStageIdx, setCurrentStageIdx] = useState(null);
  const [preview, setPreview] = useState(false);

  const currentStageButton = useRef();
  const stagesWrapper = useRef();

  const {api: apiEventRecords} = useApi("event-records");
  const {api: fileApi} = useApi("files");

  const openFile = fileId =>
    fileApi.callGet(fileId).then(({status, data}) => {
      if (status === 200) openLink(data.link);
    });

  const linkedGroup = useMemo(() => {
    if (group && group in facility.builder.byId && facility.builder.byId[group])
      return Object.keys(markerIconMap)?.length > 0 && facility.builder.byId[group]?.markerId
        ? markerIconMap[facility.builder.byId[group].markerId]
        : DEFAULT_MARKER;

    return null;
  }, [group, facility, markerIconMap]);

  // Set builder from current stage
  useEffect(() => {
    if (isMounted() && stages?.allIds) {
      const {allIds, byId} = stages;
      const first = allIds[0];

      // update to account for incomplete records
      let stage = first;

      if (records && Object.keys(records).length > 0 && record?.stage) {
        const target = byId[record.stage];
        if (record.draft) stage = record.stage;
        else if (target.next) stage = target.next;
        else stage = null;
      }

      setCurrentStageId(stage);
      setCurrentStage(byId[stage]);
      setCurrentStageIdx(allIds?.indexOf(stage));
    }
  }, [isMounted, stages, event, records, record]);

  const handleSubmission = (submission, draft, outOfRange) => {
    socket.emit(RELEASE, getFacilityRooms(facility.slug, "event"), `event_${eventId}_${date}`);

    if (draft) setDraftLoading(true);
    else setCreateLoading(true);

    const {completedAt, ...rest} = submission;

    const params = {
      stage: currentStageId,
      draft,
      submission: {
        responses: currentStage?.builder ? formatSubmissions(rest, currentStage.builder) : {}
      },
      completedAt: completedAt || null,
      eventId: event.id
    };

    if (outOfRange) params.outOfRange = Object.keys(outOfRange);

    const request = record
      ? apiEventRecords.callPut(record.id, params)
      : apiEventRecords.callPost({
          ...params,
          eventId: eventId,
          dateDue: unformatted
        });

    const targetStatus = record ? 200 : 201;
    request.then(({status, data: res}) => {
      if (status === targetStatus) {
        getRecords();
        setCompleting(false);
        setVisible(false);
        socket.emit(STAGE_COMPLETED, facility.slug, res.data.id);
      }
      if (draft) setDraftLoading(false);
      else setCreateLoading(false);
    });
  };

  const checkDate = () => {
    const due = dayjs(unformatted);
    const today = dayjs(undefined, undefined, settings.timezone);

    // Target Event date is before today
    if (!event?.frequency || due.isSameOrBefore(today)) return true;

    const {interval: i, base: b} = frequency;
    const start = dayjs(unformatted).subtract(i, b);

    // Target Event is within interval
    if (today.isSameOrAfter(start) && today.isSameOrBefore(due)) return true;

    return false;
  };

  const orderDates = useMemo(() => {
    const archivedUser = record?.archivedBy ? (
      <>
        by {record.archivedBy.firstName}&nbsp;{record.archivedBy.lastName}
      </>
    ) : (
      "automatically"
    );

    const {lastStage} = event;
    const {byId} = event?.stages || {};

    const submissionDate = record?.completedAt || record?.createdAt;

    const stageName = record?.stage && byId ? byId[record.stage]?.name : null;

    const completedMessage =
      record?.stage === lastStage ? "Completed on" : `${stageName || "Stage"} completed on`;

    const draftMessage = record?.draft ? `Draft submitted for ${stageName} on` : null;

    if (record?.archivedAt && submissionDate) {
      if (dayjs(record.archivedAt).isBefore(submissionDate))
        return (
          <>
            <Completed>
              <Small>
                Archived by {record.archivedBy.firstName}&nbsp;{record.archivedBy.lastName} on&nbsp;
                {prettyDateInUserTimezone(record.archivedAt, settings.timezone)}
              </Small>
            </Completed>
            <Completed>
              <Small>
                {draftMessage || completedMessage}&nbsp;
                {prettyDateInUserTimezone(
                  record?.completedAt || record?.createdAt,
                  settings.timezone
                )}
              </Small>
            </Completed>
          </>
        );

      return (
        <>
          <Completed>
            <Small>
              {draftMessage || completedMessage}&nbsp;
              {prettyDateInUserTimezone(
                record?.completedAt || record?.createdAt,
                settings.timezone
              )}
            </Small>
          </Completed>
          <Completed>
            <Small>
              Archived {archivedUser} on&nbsp;
              {prettyDateInUserTimezone(record.archivedAt, settings.timezone)}
            </Small>
          </Completed>
        </>
      );
    }

    if (submissionDate)
      return (
        <Completed>
          <Small>
            {draftMessage || completedMessage}&nbsp;
            {prettyDateInUserTimezone(record?.completedAt || record?.createdAt, settings.timezone)}
          </Small>
        </Completed>
      );

    if (record?.archivedAt)
      return (
        <Completed>
          <Small>
            Archived {archivedUser} on&nbsp;
            {prettyDateInUserTimezone(record?.archivedAt, settings.timezone)}
          </Small>
        </Completed>
      );

    return null;
  }, [record, settings, event]);

  const renderForm = () => {
    if (
      isArchived ||
      (record?.isArchived && !roleCanAccessResource("event_record", "archive")) ||
      !roleCanAccessResource("event_record", "create")
    )
      return null;

    if (!currentStage && !event?.stages)
      return (
        <Complete
          type="button"
          onClick={() =>
            handleSubmission({
              completedAt: prettyDateInUserTimezone(undefined, undefined, "YYYY-MM-DD")
            })
          }>
          Dismiss
        </Complete>
      );

    const roleCanAccessCurrent =
      !!currentStage &&
      ((currentUser.type.name === "super" && !currentUser.role?.id) ||
        !currentStage.restrictTo?.length ||
        currentStage.restrictTo.filter(role => currentUser.role.id === role.id).length !== 0);

    if (!roleCanAccessCurrent) return null;

    return (
      currentStage &&
      keyHolder !== null &&
      roleCanAccessResource("event_record", "create") && (
        <RelativeWrapper>
          {!completing ? (
            <RelativeWrapper>
              {keyHolder ? (
                <Text data-testid="eventsDue.locked">
                  <FontAwesomeIcon icon={faLock} />
                  &nbsp;Locked&nbsp;<Abbr title={keyHolder}>by {keyHolder}</Abbr>
                </Text>
              ) : (
                <Stages ref={stagesWrapper} hasLeftFade={currentStageIdx !== 0}>
                  {stages.allIds.length > 1 && (
                    <Label bold>
                      STAGE {currentStageIdx + 1}/{stages.allIds.length}:
                    </Label>
                  )}
                  <Inline>
                    {stages?.allIds?.map((stageId, idx) => {
                      const stage = stages.byId[stageId];
                      const roleCanAccess =
                        !!stage &&
                        ((currentUser.type.name === "super" && !currentUser.role?.id) ||
                          !stage.restrictTo?.length ||
                          stage.restrictTo.filter(role => currentUser.role.id === role.id)
                            .length !== 0);
                      if (currentStage.name === stage.name)
                        return (
                          <Fragment key={`stageButton-${stage.name}`}>
                            <Complete
                              data-testid="event.complete"
                              ref={currentStageButton}
                              type="button"
                              disable={!roleCanAccess}
                              onClick={
                                roleCanAccess
                                  ? () => {
                                      if (currentStage?.builder) {
                                        socket.emit(
                                          LOCK,
                                          getFacilityRooms(facility.slug, "event"),
                                          `event_${eventId}`
                                        );
                                        setCompleting(true);
                                      } else
                                        handleSubmission({
                                          completedAt: unformatted
                                        });
                                    }
                                  : undefined
                              }>
                              Complete
                              {stages?.allIds?.length > 1 && `: ${stage.name.toUpperCase()}`}
                            </Complete>
                            {idx < stages.allIds.length - 1 && (
                              <Arrow>
                                <FontAwesomeIcon icon={faArrowRight} />
                              </Arrow>
                            )}
                          </Fragment>
                        );

                      if (idx > currentStageIdx)
                        return (
                          <Fragment key={`stageButton-${stage.name}`}>
                            <DisabledButton
                              past={!exists(currentStageIdx) || currentStageIdx > idx}>
                              {stage.name.toUpperCase()}
                            </DisabledButton>
                            {idx < stages.allIds.length - 1 && (
                              <Arrow past={!exists(currentStageIdx) || currentStageIdx > idx}>
                                <FontAwesomeIcon icon={faArrowRight} />
                              </Arrow>
                            )}
                          </Fragment>
                        );

                      return null;
                    })}
                  </Inline>
                </Stages>
              )}
            </RelativeWrapper>
          ) : (
            <RenderChecksheet
              task={{...event, builder: currentStage.builder}}
              taskRecord={record}
              stage={currentStageId}
              dateDue={unformatted}
              responses={record?.draft ? record.submission.responses : null}
              setDraft={(submission, outOfRange) => handleSubmission(submission, true, outOfRange)}
              setSubmission={(submission, outOfRange) =>
                handleSubmission(submission, false, outOfRange)
              }
              completeLoading={createLoading}
              draftLoading={draftLoading}
              cancelFunction={() => {
                socket.emit(
                  RELEASE,
                  getFacilityRooms(facility.slug, "event"),
                  `event_${eventId}_${date}`
                );
                setCompleting(false);
              }}
            />
          )}
        </RelativeWrapper>
      )
    );
  };

  const replaceWeekdays = label => {
    const dayNameMap = {
      Sun: "Sundays",
      Mon: "Mondays",
      Tue: "Tuesdays",
      Wed: "Wednesdays",
      Thu: "Thursdays",
      Fri: "Fridays",
      Sat: "Saturdays"
    };
    let newLabel = label;
    if (!newLabel) return "";
    Object.keys(dayNameMap).map(abbreviated => {
      newLabel = newLabel.replace(abbreviated, dayNameMap[abbreviated]);
    });
    return newLabel;
  };

  const getFreqLabel = () => {
    if (!frequency.name && !frequency.label) return `Every ${interval} ${base}`;

    if (frequency.label && frequency.exclude && frequency.name !== "work-week")
      return replaceWeekdays(frequency.label);

    return toTitleCase(frequency.name);
  };

  const getCompletionRateHelper = () => {
    if (!record.submission.responses) return 0;
    const {completedAt: _ca, ...rest} = {...record.submission.responses};
    return getCompletionRate(rest);
  };

  if (preview && unformatted && record)
    return (
      <ViewRecord
        visible={preview}
        setVisible={setVisible}
        hasBackButton
        goBack={() => setPreview(false)}
        event={event}
        record={record}
        updateData={() => socket.emit(EVENT_MODIFIED, facility.slug, eventId, "put")}
        markerIconMap={markerIconMap}
      />
    );

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      renderModalControls={renderModalControls()}
      hasBackButton={completing}
      clickOutsideToClose={!completing}
      goBack={() => {
        setCompleting(false);
        socket.emit(RELEASE, getFacilityRooms(facility.slug, "event"), `event_${eventId}_${date}`);
      }}>
      <PillsWrapper>
        {type?.name && (
          <Pill color={`#${type.color}`} quiet>
            <Icon icon={getIcon(type.icon)} />
            {type.name.toUpperCase()}
          </Pill>
        )}
        {group && (
          <Pill color={`#${linkedGroup.color}`} quiet>
            <Icon icon={faMapMarkerAlt} />
            {facility.builder.byId[group].label}
          </Pill>
        )}
        {frequency && (
          <Pill quiet>
            <Icon icon={faSync} />
            {frequency.label}
          </Pill>
        )}
        {availableBase && (
          <Pill quiet>
            <Icon icon={faArrowsLeftRight} />
            {availableIndex}
            {getNumberSuffix(availableIndex)} {availableBase === "months" ? "month" : "year"}
          </Pill>
        )}
        {overdueArchived ? (
          <Status status="Overdue" archived quiet>
            Overdue&nbsp;
            <FontAwesomeIcon icon={faArchive} />
          </Status>
        ) : (
          <>
            {record?.status && (
              <Status status={record.status} quiet>
                {record.status.toUpperCase()}
              </Status>
            )}
            {!record && pastDueDate && (
              <Status status="Overdue" quiet>
                Overdue
              </Status>
            )}
          </>
        )}

        {isArchived && (
          <Pill color={colors.yellow} quiet>
            Archived
          </Pill>
        )}
      </PillsWrapper>
      <HeadingWrapper>
        <EventHeading>
          <Abbr title={`${name}${isGlobal && ` - ${facility.name} ${facility.type}`}`}>
            {name}
            {isGlobal && ` - ${facility.name} ${facility.type}`}
          </Abbr>
        </EventHeading>

        {record?.draft && <Draft>INCOMPLETE&nbsp; {getCompletionRateHelper()}%</Draft>}
      </HeadingWrapper>

      {!checkDate() && completing && (
        <Inline>
          <Pill color={heroTheme.warning} quiet>
            Warning!
          </Pill>
          <Text>
            You are completing this event early. Event due{" "}
            {prettyDateInUserTimezone(dayjs(unformatted).format("MMM D YYYY"))}
          </Text>
        </Inline>
      )}

      {!completing && (
        <DetailsWrapper>
          {message && (
            <Detail>
              <DetailIcon icon={faNoteSticky} />
              {message}
            </Detail>
          )}
          <Detail>
            <DetailIcon icon={faCalendar} />
            {dayjs(unformatted).format("dddd")}, {formatted}
          </Detail>
          {availableDate && (
            <Detail>
              <AvailableIcon icon={faArrowsLeftRight} />
              Available until {availableDate}
            </Detail>
          )}
          {frequency && until && (
            <Detail>
              <DetailIcon icon={faSync} />
              {getFreqLabel()}, until ${dayjs(until).format("MMM D, YYYY")}
            </Detail>
          )}
          {responses && Object.keys(responses).filter(key => responses[key] !== "").length > 0 && (
            <DetailList>
              <DetailIcon icon={faAlignLeft} />
              <List>
                {Object.keys(responses)
                  .filter(key => responses[key] !== "")
                  .map(key => (
                    <DetailListItem key={key}>
                      <Label bold>
                        {type.fields.byId && key in type.fields.byId
                          ? type.fields.byId[key].label
                          : getReadableKey(key)}
                      </Label>
                      {type.fields.byId &&
                      key in type.fields.byId &&
                      type.fields.byId[key].type === "upload" ? (
                        <FileLink
                          onClick={() => openFile(responses[key])}
                          key={`file-${responses[key]}`}>
                          Open File
                        </FileLink>
                      ) : (
                        <Text>{responses[key]}</Text>
                      )}
                    </DetailListItem>
                  ))}
              </List>
            </DetailList>
          )}
        </DetailsWrapper>
      )}

      {message && !completing && (
        <>
          <br />

          <Text quiet>
            <Warning color={heroTheme.warning} quiet>
              Notice:{" "}
            </Warning>
            This is an additional {type.name.toUpperCase()}
          </Text>
        </>
      )}

      {!event?.facility?.isDeleted &&
        (roleCanAccessResource("event", "create") || checkDate()) &&
        renderForm()}

      {!completing && record && (
        <CompletedWrapper>
          <Label bold>HISTORY:</Label>
          {orderDates}
          {record.submission?.responses && Object.keys(record.submission.responses).length > 0 && (
            <CompletedWrapper>
              <Button type="button" onClick={() => setPreview(true)}>
                View {record.status !== "In Progress" ? "Record" : "Progress"}
              </Button>
            </CompletedWrapper>
          )}
        </CompletedWrapper>
      )}
    </Modal>
  );
};

ModalEventDetail.propTypes = {
  setVisible: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  isGlobal: PropTypes.bool.isRequired,
  event: PropTypes.objectOf(PropTypes.any),
  date: PropTypes.string.isRequired,
  keyHolder: PropTypes.string,
  completing: PropTypes.bool,
  setCompleting: PropTypes.func,
  markerIconMap: PropTypes.objectOf(PropTypes.any).isRequired,
  renderModalControls: PropTypes.func.isRequired
};

ModalEventDetail.defaultProps = {
  event: {},
  keyHolder: "",
  completing: false,
  setCompleting: () => {}
};

// Style Overrides
const EventHeading = styled(Heading)`
  text-align: start;
  margin: ${pad}px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const HeadingWrapper = styled(Inline)`
  flex-wrap: wrap;
  row-gap: 0;
  width: 100%;
`;

const Draft = styled(Pill)`
  background: ${({theme}) => theme.warning};
`;

const DetailsWrapper = styled.div`
  margin-top: ${pad / 2}px;
`;

const Detail = styled(Text)`
  margin-bottom: ${pad}px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const DetailList = styled.div`
  margin-bottom: ${pad}px;
`;

const DetailListItem = styled(ListItem)`
  padding-left: 0;
  padding-right: ${pad * 2}px;
`;

const DetailIcon = styled(FontAwesomeIcon)`
  margin-right: ${pad * 2}px;
  fill: ${({color, theme}) => color || theme.primary};
`;

const AvailableIcon = styled(DetailIcon)`
  margin-right: ${pad * 1.7}px;
`;

const Icon = styled(FontAwesomeIcon)`
  ${voice.quiet};
  margin-right: ${pad / 2}px;
  font-weight: bold;
  fill: ${({color, theme}) => color || theme.tertiary};
`;

const FileLink = styled(Text)`
  font-weight: bold;
  color: ${({theme}) => theme.primary};
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

const Complete = styled(Button)`
  display: block;
  margin: ${pad}px 0;
  color: ${({theme}) => theme.primary};

  ${({disable}) =>
    disable &&
    css`
      cursor: not-allowed;
    `}
`;

const Completed = styled.div`
  margin-top: ${pad}px;
`;

const CompletedWrapper = styled.div`
  margin-top: ${pad}px;
`;

const Stages = styled.div`
  overflow: hidden;
  margin-top: ${pad * 2}px;

  &:after {
    z-index: ${z("top")};
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    content: "";
    pointer-events: none;
    background: ${({theme}) =>
      `linear-gradient(to right, ${theme.tertiary}00 0%  , ${theme.tertiary}00 90% , ${theme.tertiary}FF 100%)`};
  }
`;

const DisabledButton = styled(Complete)`
  cursor: not-allowed;
  color: ${({theme}) => theme.tertiary};

  ${({past}) =>
    past &&
    css`
      opacity: 0.5;
    `}
`;

const Arrow = styled.div`
  font-size: 30px;
  padding: ${pad}px 0;
  width: min-content;

  ${({past}) =>
    past &&
    css`
      opacity: 0.5;
    `}
`;

const PillsWrapper = styled(Inline)`
  margin-top: ${pad / 2}px;
`;

const Status = styled(Pill)`
  text-align: center;

  background: ${({status, theme}) => {
    if (status === "Incomplete") return theme.warning;
    if (status === "Overdue") return theme.error;
    if (status === "Late") return theme.alert;
    return theme.success;
  }};

  ${({archived}) =>
    archived &&
    css`
      opacity: 0.7;
    `}

  svg {
    fill: ${({theme}) => theme.tertiary};
  }

  ${props =>
    props.quiet &&
    css`
      svg {
        ${voice.quiet};
      }
    `};
`;

const Warning = styled.strong`
  ${voice.quiet}
  color: ${({theme}) => theme.warning};
`;

export default ModalEventDetail;

import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";

// Style
import {pad, border, radius, transition} from "../style/components/variables.js";
import {flex} from "../style/components/mixins.js";
import {triangle} from "../style/components/shapes.js";
import {voice} from "../style/components/typography.js";
import {Abbr, Label} from "../style/components/general.js";

const Accordion = ({children, label, handleOpen, toggleOpen, testId, disabled, isGroup}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(toggleOpen);
  }, [toggleOpen]);

  return (
    <Wrapper disabled={disabled ? 1 : 0}>
      <Menu
        data-testid={testId || "accordion"}
        onClick={e => {
          if (!disabled) {
            setOpen(show => !show);
            handleOpen(e);
          }
        }}>
        <Arrow openDrawer={open} isGroup={isGroup ? 1 : 0} />
        <Label isGroup={isGroup ? 1 : 0} bold>
          <Abbr title={label}>{label}</Abbr>
        </Label>
      </Menu>
      {open && children}
    </Wrapper>
  );
};

Accordion.propTypes = {
  children: PropTypes.node.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  handleOpen: PropTypes.func,
  toggleOpen: PropTypes.bool,
  testId: PropTypes.string,
  disabled: PropTypes.bool,
  isGroup: PropTypes.bool
};

Accordion.defaultProps = {
  toggleOpen: false,
  handleOpen: () => null,
  testId: null,
  disabled: false,
  isGroup: false
};

// Style Overrides
const Wrapper = styled.div`
  width: 100%;

  ${props =>
    props.disabled &&
    css`
      fieldset {
        cursor: not-allowed;
      }
      opacity: 0.2;
    `}
`;

const Menu = styled.fieldset`
  ${flex("row", "nowrap", "flex-start", "center")}
  border: ${border} solid transparent;
  border-radius: ${radius};
  overflow: hidden;
  cursor: pointer;

  ${Label} {
    position: relative;
    display: block;
    width: fit-content;
    padding: ${pad / 2}px;
    margin: ${pad / 2}px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    ${props =>
      props.isGroup &&
      css`
        ${voice.medium};
        color: ${props.theme.group};
        text-transform: uppercase;
        margin-bottom: ${pad / 2}px;
      `}
  }
`;

const Arrow = styled.span`
  width: fit-content;
  display: flex;
  align-items: center;
  padding: ${pad / 2}px;
  padding-right: 0;
  transform: rotate(0deg);
  transition: ${transition};

  ${props => triangle(pad / 2, props.isGroup ? props.theme.group : props.theme.secondary, "right")}

  ${props =>
    props.openDrawer &&
    css`
      transform: rotate(90deg);
    `}
`;

export default Accordion;

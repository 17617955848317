import React, {useState} from "react";
import PropTypes from "prop-types";

// Utils
import useApi from "../../hooks/useApi.js";

// Components
import ModalDelete from "../../components/ModalDelete.js";
import {Text, Warning} from "../../style/components/general.js";

const ModalNoteArchive = ({visible, setVisible, selected, updateTable}) => {
  const {api} = useApi("custom-table-rows");
  const [loadingDelete, setLoadingDelete] = useState(false);

  const handleSubmit = () => {
    setLoadingDelete(true);
    const ids = selected.map(current => current.id);
    api.callDelete(null, {data: {ids: ids}}).then(async response => {
      if (response) {
        setVisible(false);
        updateTable();
      }
      setLoadingDelete(false);
    });
  };

  const hasDeleted = selected.filter(item => item.isDeleted)?.length > 0;

  return (
    <ModalDelete
      visible={visible}
      setVisible={setVisible}
      title="Archive Row(s)"
      prompt={
        <Text>
          Are your sure you want to archive?
          {hasDeleted && (
            <Text>
              <Warning>Warning!</Warning>One or more of your selected items is already
              archived.Those items will be permanently deleted. Do you want to proceed?
            </Text>
          )}
        </Text>
      }
      action={hasDeleted ? "Archive / Delete" : "Archive"}
      confirmDelete={handleSubmit}
      loading={loadingDelete}
    />
  );
};

ModalNoteArchive.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  updateTable: PropTypes.func.isRequired,
  selected: PropTypes.arrayOf(PropTypes.any)
};

ModalNoteArchive.defaultProps = {
  selected: null
};

export default ModalNoteArchive;

import React, {useState, useEffect, useContext, useMemo} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faExclamation, faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import {AuthContext} from "../../contexts/auth.js";
import {SettingsContext} from "../../contexts/settings.js";

// Utils
import useMountedState from "../../hooks/useMountedState.js";
import {getFields, getAncestors} from "../../utils/builder.js";
import {exists} from "../../utils/helpers.js";
import {getAncestryName} from "./helpers.js";

// Components
import Modal from "../../components/Modal.js";
import ModalNotificationsUpdate from "./ModalNotificationsUpdate.js";

// Style
import {border, colors, pad, radius} from "../../style/components/variables.js";
import {
  HeadingCenter,
  Text,
  List,
  ListItem,
  Abbr,
  Button,
  Label,
  Inline
} from "../../style/components/general.js";

const checkMap = {
  "===": "is",
  "!==": "is not",
  true: "True",
  false: "False",
  ">": "is greater than",
  "<": "is less than",
  ">=": "is greater than or equal to",
  "<=": "is less than or equal to",
  contains: "has"
};

const compareMap = {
  0: "0",
  1: "1",
  checked: "all checked",
  unchecked: "all unchecked",
  one: "one checked",
  more: "at least one checked",
  some: "at least one unchecked"
};

const ModalNotifications = ({
  visible,
  setVisible,
  checksheetBuilder,
  notifications,
  setNotifications,
  handleSave
}) => {
  const {allIds: nAllIds, byId: nById} = notifications;
  const {allIds, byId} = checksheetBuilder;
  const fields = getFields(allIds, byId);

  const isMounted = useMountedState();
  const {roles} = useContext(AuthContext);
  const {settings} = useContext(SettingsContext);

  const [openUpdate, setOpenUpdate] = useState(false);
  const [target, setTarget] = useState(null);

  const hasRoleRecipientsEnabled = useMemo(() => {
    if (roles?.length > 0 && settings?.roleRecipients === "true") return true;
    return false;
  }, [roles, settings]);

  const current = useMemo(() => {
    if (target && nById && nById[target]) {
      const {trigger, depends, template, recipients, conditionArray, operator, type, name, prompt} =
        nById[target];

      const notification = {
        trigger,
        type,
        name,
        prompt,
        ...template
      };

      const currentRecipientsAreRoles = type ? type === "roles" : hasRoleRecipientsEnabled;
      if (currentRecipientsAreRoles === hasRoleRecipientsEnabled)
        notification.recipients = recipients;
      else notification.recipients = [];

      if (trigger === "Unacceptable Parameter" || depends)
        notification.condition = {
          depends,
          conditionArray,
          operator
        };

      return notification;
    }

    return null;
  }, [target, nById, hasRoleRecipientsEnabled]);

  useEffect(() => {
    if (isMounted() && target) setOpenUpdate(true);
  }, [isMounted, target]);

  const handleDelete = id => {
    const updated = {...notifications};
    if (Object.keys(updated.byId).includes(id) && updated.byId[id])
      // Delete field notification object
      delete updated.byId[id];

    const temp = updated.allIds.indexOf(id);
    updated.allIds.splice(temp, 1);

    setNotifications(updated);

    handleSave(updated);
  };

  const renderNotifications = list =>
    list && list.length > 0 ? (
      list.map(notificationId => {
        const {depends, conditionArray, operator, template, recipients, type, trigger, name} =
          nById[notificationId];

        const recipientsAreRoles = type === "roles";
        let settingAlert = "";

        if (!recipientsAreRoles && hasRoleRecipientsEnabled)
          settingAlert = (
            <StyledAbbr title="Needs to be reconfigured for role recipients">
              <FontAwesomeIcon icon={faExclamation} />
            </StyledAbbr>
          );

        if (recipientsAreRoles && !hasRoleRecipientsEnabled)
          settingAlert = (
            <StyledAbbr title="Needs to be reconfigured for user recipients">
              <FontAwesomeIcon icon={faExclamation} />
            </StyledAbbr>
          );

        const title = template?.subject?.toUpperCase() || name?.toUpperCase();

        return (
          <Notification key={notificationId}>
            <ListItem key={notificationId}>
              {title && (
                <StyledText bold>
                  {title}
                  {settingAlert}
                </StyledText>
              )}
              {depends && (
                <Text>
                  When&nbsp;{getAncestors(byId[depends].parentName, byId)}: &nbsp;
                  {conditionArray.map(({check, compare}, i) => (
                    <span key={`${check}-${compare}`}>
                      {i > 0 && `${operator} `}
                      {byId[depends].label}&nbsp;
                      {checkMap[check]}&nbsp;
                      {compare in compareMap
                        ? compareMap[compare]
                        : compare?.replace("Other: ", "")}
                      &nbsp;
                    </span>
                  ))}
                </Text>
              )}
              {trigger === "Unacceptable Parameter" && (
                <ParamWrapper>
                  {conditionArray
                    ?.filter(({depends: condDepends}) => byId[condDepends])
                    ?.map(({depends: condDepends, aMin, aMax, alertCondition}, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={`${condDepends}-${i}`}>
                        <Text bold>{getAncestryName(byId[condDepends], checksheetBuilder)}</Text>
                        {exists(aMin) && exists(aMax) && (
                          <Text>
                            Acceptable Range: {aMin} - {aMax} {byId[condDepends].units}
                          </Text>
                        )}
                        {exists(aMin) && !exists(aMax) && (
                          <Text>
                            Minimum Value: {aMin} {byId[condDepends].units}
                          </Text>
                        )}
                        {!exists(aMin) && exists(aMax) && (
                          <Text>
                            Maximum Value: {aMax} {byId[condDepends].units}
                          </Text>
                        )}
                        {Array.isArray(alertCondition) && (
                          <Text>Unacceptable Values: {alertCondition.join(", ")}</Text>
                        )}
                        {typeof alertCondition === "string" && (
                          <Text>Unacceptable Value: {alertCondition}</Text>
                        )}
                      </div>
                    ))}
                </ParamWrapper>
              )}
              <br />
              {recipients?.length ? (
                <Text>
                  Notify:&nbsp;
                  {recipients.map((recipient, index) => (
                    <span key={recipient}>
                      {recipient}
                      {index !== recipients.length - 1 && ", "}
                    </span>
                  ))}
                </Text>
              ) : (
                <Text>Sent to facility notification recipients</Text>
              )}
            </ListItem>
            <Options>
              <IconButton type="button" onClick={() => setTarget(notificationId)}>
                <Abbr title="Edit">
                  <FontAwesomeIcon icon={faEdit} />
                </Abbr>
              </IconButton>
              <IconButton type="button" onClick={() => handleDelete(notificationId)} delete>
                <Abbr title="Delete">
                  <FontAwesomeIcon icon={faTrash} />
                </Abbr>
              </IconButton>
            </Options>
          </Notification>
        );
      })
    ) : (
      <None>Checksheet Notifications have not been configured.</None>
    );

  if (openUpdate && fields && fields.length > 0)
    return (
      <ModalNotificationsUpdate
        visible={visible}
        setVisible={setVisible}
        goBack={setOpenUpdate}
        checksheetBuilder={checksheetBuilder}
        fields={fields}
        edit={target}
        current={current}
        setEdit={setTarget}
        notifications={notifications}
        handleSave={handleSave}
      />
    );

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <ModalTitle>Checksheet Notifications</ModalTitle>

      {nAllIds && nAllIds.length > 0 ? (
        <>
          <div>
            <Label bold>Completed Notifications</Label>
            <br />
            <List column>
              {renderNotifications(
                nAllIds.filter(
                  id =>
                    (!nById[id].depends || nById[id].depends === null) &&
                    nById[id].trigger !== "Unacceptable Parameter"
                )
              )}
            </List>
          </div>

          <div>
            <Label bold>Field Notifications</Label>
            <br />
            <List column>
              {renderNotifications(
                nAllIds.filter(id => nById[id].depends && nById[id].depends !== null)
              )}
            </List>
          </div>

          <div>
            <Label bold>Unacceptable Parameters</Label>
            <br />
            <List column>
              {renderNotifications(
                nAllIds.filter(id => nById[id].trigger === "Unacceptable Parameter")
              )}
            </List>
          </div>
        </>
      ) : (
        <Text>Checksheet specific notifications have not been configured.</Text>
      )}

      {fields && fields.length > 0 ? (
        <Button type="button" onClick={() => setOpenUpdate(true)}>
          <FontAwesomeIcon icon={faPlus} />
          &nbsp;New
        </Button>
      ) : (
        <None>Cannot create a field notification without defined fields.</None>
      )}
    </Modal>
  );
};

ModalNotifications.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  checksheetBuilder: PropTypes.objectOf(PropTypes.any).isRequired,
  notifications: PropTypes.objectOf(PropTypes.any).isRequired,
  setNotifications: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired
};

// Style Overrides
const ModalTitle = styled(HeadingCenter)`
  margin: ${pad}px 0;
  color: ${({theme}) => theme.component};
`;

const Notification = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  padding: ${pad}px;
  margin: ${pad}px 0;
`;

const Options = styled(Inline)`
  align-items: start;
`;

const IconButton = styled(Button)`
  width: auto;

  ${({delete: d, theme}) =>
    d &&
    css`
      background: ${theme.error};
    `}
`;

const None = styled(Text)`
  margin: ${pad}px 0;
`;

const ParamWrapper = styled.div`
  margin-top: ${pad}px;
`;

const StyledAbbr = styled(Abbr)`
  background-color: ${colors.red};
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  align-self: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-left: ${pad}px;
  padding-left: 1px;

  svg {
    fill: ${({theme}) => theme.tertiary};
    height: 16px;
    width: 16px;
  }
`;

const StyledText = styled(Text)`
  display: flex;
`;

export default ModalNotifications;

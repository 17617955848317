// Utils
import {generateUniqueKey} from "../../utils/helpers";

export default class Row {
  height;
  region;
  id;

  constructor({region, row} = {}) {
    if (row) {
      this.height = row.region?.height;
      this.region = row.region;
      this.id = row.id;
    } else {
      this.height = region?.height || 0;
      this.region = region;
      this.id = generateUniqueKey("row");
    }
  }

  updateRegion(update) {
    this.height = update?.height || 0;
    this.region = update;
  }
}

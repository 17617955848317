import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";

// Constants
import {MONTHS, MONTHS_ABBR} from "../utils/gregorian.js";

// Style
import {bp} from "../style/components/breakpoints.js";
import {voice} from "../style/components/typography.js";
import {flex, z} from "../style/components/mixins.js";
import {border, pad, radius, shadow} from "../style/components/variables.js";
import {List, ListItem, Inline, Arrow} from "../style/components/general.js";

// const MONTHS = [
//   "January",
//   "February",
//   "March",
//   "April",
//   "May",
//   "June",
//   "July",
//   "August",
//   "September",
//   "October",
//   "November",
//   "December"
// ];

// const MONTHS_ABBR = [
//   "Jan",
//   "Feb",
//   "Mar",
//   "Apr",
//   "May",
//   "Jun",
//   "Jul",
//   "Aug",
//   "Sep",
//   "Oct",
//   "Nov",
//   "Dec"
// ];

const MonthPicker = ({month, setMonth, year}) => {
  let initialMonth = 1;
  MONTHS.map((curr, index) => {
    if (curr === month) initialMonth = index + 1;
  });

  const outside = useRef(null);

  const [open, setOpen] = useState(false);
  const [activeYear, setActiveYear] = useState(year);

  useEffect(() => {
    const handleOutsideClick = e =>
      outside?.current !== null &&
      !outside.current.contains(e.target) &&
      !outside.current.contains(e.target.nextSibling) &&
      setOpen(false);

    if (outside) document.addEventListener("mousedown", handleOutsideClick);

    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [setOpen]);

  return (
    <Wrapper ref={outside}>
      <ActiveMonth data-testid="active" type="button" onClick={() => setOpen(prev => !prev)}>
        {month} {year}
      </ActiveMonth>
      {open && (
        <List data-testid="menu">
          <Inline>
            <ArrowButton type="button" onClick={() => setActiveYear(prev => prev - 1)}>
              <Arrow />
            </ArrowButton>
            <span>{activeYear}</span>
            <ArrowButton type="button" onClick={() => setActiveYear(prev => prev + 1)}>
              <Arrow rotate="-180deg" />
            </ArrowButton>
          </Inline>
          {MONTHS_ABBR.map((abbr, index) => (
            <ListItem key={abbr}>
              <MonthButton
                type="button"
                onClick={() => {
                  const date = new Date(activeYear, index);
                  setMonth(date);
                  setOpen(false);
                }}
                active={index + 1 === initialMonth}>
                {abbr}
              </MonthButton>
            </ListItem>
          ))}
        </List>
      )}
    </Wrapper>
  );
};

MonthPicker.propTypes = {
  month: PropTypes.string.isRequired,
  year: PropTypes.number.isRequired,
  setMonth: PropTypes.func.isRequired
};

// Style Overrides
const Wrapper = styled.div`
  position: relative;
  margin-left: ${pad}px;
  z-index: ${z("top")};

  ${Inline} {
    width: 100%;
    justify-content: space-between;
    margin: 0 ${pad * 5}px;
  }

  ${List} {
    ${flex("row", "wrap", "center", "center")};
    position: absolute;
    top: 100%;
    right: 0;
    left: unset;
    background: ${props => props.theme.tertiary};
    border: ${border} solid ${props => props.theme.secondary};
    border-radius: ${radius};
    padding: ${pad}px;
    box-shadow: ${shadow};
    margin-top: ${pad}px;

    ${bp(1)} {
      left: 0;
      right: unset;
    }
  }

  ${ListItem} {
    width: 33.33%;
    text-align: center;
    padding: 0;
  }
`;

const ActiveMonth = styled.button`
  text-align: left;
  color: ${props => props.theme.secondary};
  font-weight: bold;
  ${voice.medium};
`;

const ArrowButton = styled.button`
  width: min-content;
  margin: 0 ${pad}px;
`;

const MonthButton = styled.button`
  padding: ${pad}px 0;

  &:hover {
    color: ${props => props.theme.primary};
  }

  ${props =>
    props.active &&
    css`
      color: ${props.theme.primary};
    `}
`;

export default MonthPicker;

import {exists} from "../../utils/helpers";

class AnalyticsContainer {
  constructor(columns) {
    this.columns = columns || [];
  }

  getMinColumn() {
    let minHeight = null;
    let minColumn = null;

    this.columns.map(col => {
      if (!exists(minHeight) || col.height < minHeight) {
        minHeight = col.height;
        minColumn = col;
      }
    });

    return minColumn;
  }

  extract() {
    return this.columns.map(col => col.members);
  }

  insert(item) {
    const minColumn = this.getMinColumn();
    if (minColumn) minColumn.insert(item);
  }
}

export default AnalyticsContainer;

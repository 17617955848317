import dayjs from "dayjs";
import {getReadableKey} from "./helpers.js";

/**
 * @param {string} value
 * @returns {string}
 */
const serializeCsv = value => {
  const dateRegex = /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}/g;
  const dateRegexWithMicrosecond =
    /[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]{2}:[0-9]{2}:[0-9]{2}.[0-9]{6}/g;

  if ((value?.match(dateRegex) || value?.match(dateRegexWithMicrosecond)) && dayjs(value).isValid())
    return dayjs(value).format("MM/DD/YYYY h:mm A");

  const numberRegex = /^".*"$/;

  if (value?.match(numberRegex)) return value;

  const temp = value.replace(/,/g, " ");
  return temp.replace(/_\d*$/, "");
};

/**
 * @param {json} data
 * @returns {string}
 */
const formatForCsv = (data, header) => {
  // Default Encoding to UTF-8 with Universal BOM (Byte Order Mark)
  let csv = "\uFEFF";

  if (header) {
    csv += header;
    csv += ",\n";
  }

  if (data?.length > 0) {
    const tableHeaders = Object.keys(data[0]);
    tableHeaders.map((h, index) => {
      csv += `${getReadableKey(h).toUpperCase()}`;
      if (index < tableHeaders.length - 1) csv += ",";
      else csv += "\n";
    });
    data.map(row => {
      tableHeaders.map((h, index) => {
        csv += row[h] ? `${serializeCsv(`${row[h]}`)}` : `"${""}"`;
        if (index < tableHeaders.length - 1) csv += ",";
      });
      csv += "\n";
    });
  }

  return csv;
};

/**
 * @param {string} filename
 * @param {json} rows
 * @param {string} header
 */
export const exportToCsv = (filename, data, header) => {
  const formatted = formatForCsv(data, header);

  const blob = new Blob([formatted], {type: "text/csv;charset=utf-8;"});
  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement("a");
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", filename);
      link.style = "visibility:hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

const formatFileName = name => name.toLowerCase().replace(" ", "_");

/**
 * @param {string} type File type for export
 * @param {string} name File name
 * @param {json} data File date - format: [{key: value, ...}, {...}, ...] or [[{key: "", value: ""}, {...}, ...], ...]
 * @param {string} header Add an additional row with string header
 */
export const startExport = (type, name, data, header = null) => {
  if (type === "csv")
    exportToCsv(name ? `${formatFileName(name)}.csv` : "export.csv", data, header);
};

export default startExport;

import React from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {useFieldArray, useFormContext} from "react-hook-form";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Components
import InputTime from "./InputTime.js";

// Style
import {pad} from "../../style/components/variables.js";
import {FormFieldWrapper, Label, Button} from "../../style/components/general.js";

const InputTimeGroup = ({testId, name, label, required}) => {
  const {control} = useFormContext();

  const {fields, remove, insert} = useFieldArray({control, name});

  return (
    <FormFieldWrapper data-testid={testId} standard>
      {label && (
        <Label htmlFor={name} bold>
          {label.toUpperCase()}
          {required && <span>&nbsp;*</span>}
        </Label>
      )}
      {fields &&
        fields.length > 0 &&
        fields.map((_o, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <StyledInlineFormField key={`${name}-${index}`}>
            <InputTime name={`${name}.${index}.time`} />

            <StyledEdit
              type="button"
              data-testid={`${testId}.removeInput`}
              disable={index === 0 && fields.length === 1}
              onClick={() => remove(index)}>
              <FontAwesomeIcon icon={faMinus} />
            </StyledEdit>
            <StyledEdit
              type="button"
              data-testid={`${testId}.addInput`}
              onClick={() => insert(index + 1, "")}>
              <FontAwesomeIcon icon={faPlus} />
            </StyledEdit>
          </StyledInlineFormField>
        ))}
    </FormFieldWrapper>
  );
};

InputTimeGroup.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool
};

InputTimeGroup.defaultProps = {
  testId: "input-time-group",
  label: null,
  required: false
};

// Style Overrides
const StyledInlineFormField = styled(FormFieldWrapper)`
  flex-direction: row;
  margin-bottom: ${pad * 1.4}px;
  align-items: space-between;
  width: fit-content;

  div {
    margin: 0;

    &:nth-child(2) {
      margin-left: ${pad}px;
    }
  }
`;

const StyledEdit = styled(Button)`
  padding: 0 ${pad}px;
  margin-left: ${pad}px;
  height: 35.5px;

  ${props =>
    props.disable &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`;

export default InputTimeGroup;

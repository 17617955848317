import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarPlus, faPlus} from "@fortawesome/free-solid-svg-icons";

// Components
import Modal from "../../components/Modal.js";

// Style
import {pad} from "../../style/components/variables.js";
import {flex} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {breakpoint} from "../../style/components/breakpoints.js";
import {Button, Inline, Small, Text} from "../../style/components/general.js";

const ModalEventOptions = ({visible, setVisible, handleSingle, handleBulk}) => (
  <Modal visible={visible} setVisible={setVisible} maxWidth={breakpoint.width[1]}>
    <Options>
      <Option onClick={handleSingle}>
        <OptionLabel>
          <FontAwesomeIcon icon={faPlus} />
          <OptionSmallText>Create Single Event</OptionSmallText>
        </OptionLabel>
      </Option>
      <Option onClick={handleBulk}>
        <OptionLabel>
          <FontAwesomeIcon icon={faCalendarPlus} />
          <OptionSmallText>Bulk Add from Templates</OptionSmallText>
        </OptionLabel>
      </Option>
    </Options>
  </Modal>
);

ModalEventOptions.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  handleSingle: PropTypes.func.isRequired,
  handleBulk: PropTypes.func.isRequired
};

// Style Overrides
const Option = styled(Button)`
  width: 50%;
  border-width: 0;
  height: 100px;
`;

const OptionLabel = styled(Text)`
  ${flex("column", "wrap", "center", "center")};
  ${voice.medium};
  font-weight: bold;
  text-align: center;
  min-width: 100%;
  text-transform: uppercase;
  background-color: ${({theme}) => theme.secondary};
  color: ${({theme}) => theme.tertiary};
  padding: ${pad}px;

  svg {
    fill: ${({theme}) => theme.tertiary};
  }
`;

const OptionSmallText = styled(Small)`
  margin-top: ${pad}px;
`;

const Options = styled(Inline)`
  gap: ${pad}px;
  margin-top: ${pad * 2}px;
  justify-content: stretch;
  width: 100%;
`;

export default ModalEventOptions;

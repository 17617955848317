import React, {useState, useContext, useEffect, useCallback, useRef, useLayoutEffect} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import dayjs from "dayjs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faSearch} from "@fortawesome/free-solid-svg-icons";

// Hooks
import useMountedState from "../../hooks/useMountedState.js";
import useApi from "../../hooks/useApi.js";
import usePrevious from "../../hooks/usePrevious.js";

// Contexts
import {useToast} from "../../contexts/toast.js";
import {AuthContext} from "../../contexts/auth.js";
import {FacilityNavContext} from "../../contexts/facilitynav.js";

// Utils
import {exists} from "../../utils/helpers.js";

// Components
import AnalyticsColumn from "./AnalyticsColumn.js";
import AnalyticsContainer from "./AnalyticsContainer.js";
import Statistic from "../../components/Statistic.js";
import Chart from "../../components/Chart.js";
import Dropdown from "../../components/Dropdown.js";
import ModalPreviewAnalytic from "./ModalPreviewAnalytic.js";
import ModalCompareAnalytic from "./ModalCompareAnalytic.js";

// Style
import {pad, radius, border, shadow} from "../../style/components/variables.js";
import {bp} from "../../style/components/breakpoints.js";
import {flex, z} from "../../style/components/mixins.js";
import {
  Inline,
  Loader,
  NotLoaded,
  Masonry,
  MasonryItem,
  Text,
  MasonryColumn,
  Button,
  SearchWrapper,
  Search,
  Input,
  Label,
  Abbr,
  SearchIcon
} from "../../style/components/general.js";

const dropdownOptions = [
  "All Time",
  "Last 7 Days",
  "Last 30 Days",
  "Last 90 Days",
  "Last Year",
  "Custom Range"
];

const Analytics = ({facility}) => {
  const isMounted = useMountedState();

  const {currentUser} = useContext(AuthContext);

  const {addToast} = useToast();

  const {loading, api} = useApi("facility-analytics");

  const {analytics, setAnalytics, allOrder, setAllOrder} = useContext(FacilityNavContext);

  // Options
  const [search, setSearch] = useState(null);
  const [snapshot, setSnapshot] = useState(null);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  // Modals
  const [currentAnalytic, setCurrentAnalytic] = useState({});
  const [showModalPreview, setShowModalPreview] = useState(false);
  const [showModalCompare, setShowModalCompare] = useState(false);
  const [showCustomRange, setShowCustomRange] = useState(false);

  const getInitialColumnCount = () => {
    if (window.innerWidth >= 767) return 3;
    if (window.innerWidth >= 640) return 2;
    return 1;
  };

  const columnCount = useRef(getInitialColumnCount());
  const visible = useRef();
  const menuWrapper = useRef();
  const allLoaded = useRef(false);
  const scrollPos = useRef(null);
  const refreshing = useRef(false);
  const showLoader = useRef(false);
  const timeout = useRef(false);

  const prevSnapshot = usePrevious(snapshot);
  const prevSearch = usePrevious(search);

  const outside = useRef(null);

  const analyticsCount = analytics?.flatMap(row => row).length || 0;

  useEffect(() => {
    const handleOutsideClick = e =>
      outside &&
      outside.current !== null &&
      !outside.current.contains(e.target) &&
      !outside.current.contains(e.target.nextSibling) &&
      snapshot === "custom range" &&
      setShowCustomRange(false);

    if (outside && snapshot === "custom range")
      document.addEventListener("mousedown", handleOutsideClick);

    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [outside, snapshot]);

  const rearrange = useCallback(
    data => {
      let columns = [];
      if (visible.current && !refreshing.current)
        columns = visible.current.map(col => new AnalyticsColumn(col));
      else
        for (let i = 0; i < columnCount.current; i++) {
          columns.push(new AnalyticsColumn());
        }

      const container = new AnalyticsContainer(columns);

      data.map(analytic => {
        if (!(currentUser.type.name === "general" && !analytic.active)) {
          container.insert(analytic);
        }
      });

      const columnArray = container.extract();

      if (columnArray.every(col => !col?.length)) return [];

      return columnArray;
    },
    [currentUser.type.name]
  );

  useLayoutEffect(() => {
    if (analytics && refreshing.current && exists(scrollPos.current)) {
      document.documentElement.scrollTop = scrollPos.current;
      refreshing.current = false;
      scrollPos.current = null;
    }
  }, [analytics]);

  const loadAnalytics = useCallback(
    (currIdx, searchString) => {
      // reset
      if (!currIdx) {
        visible.current = null;
        allLoaded.current = false;
      }

      const rect = menuWrapper.current?.getBoundingClientRect();

      const params = {
        facilityId: facility.id,
        evaluate: true,
        snapshot: snapshot,
        start,
        end: end && dayjs(end).add(1, "day").format("YYYY-MM-DD"),
        idx: currIdx || 0,
        numColumns: columnCount.current,
        // this height is the height from the top of analytics (bottom of menu) to bottom of viewport
        height: Math.ceil(
          rect ? Math.abs(window.innerHeight - (rect.top + rect.height)) : window.innerHeight
        ),
        viewportHeight: Math.ceil(window.innerHeight),
        // if this is true, we end at idx, otherwise we begin at idx (in terms of analytics retrieved - idx is equivalent to order)
        refreshing: !!currIdx && refreshing.current
      };

      if (searchString) params.filter = JSON.stringify({Search: searchString});

      api.callGet(null, params).then(({status, data}) => {
        if (status === 200 && data) {
          const ordered = rearrange(data.analytics);
          visible.current = ordered;
          setAnalytics(ordered);
          setAllOrder(data.allOrder);
          if (data.containsFinalAnalytic) allLoaded.current = true;
        } else if (status) {
          setAnalytics([]);
          setAllOrder([]);
        }
      });
    },
    [facility, snapshot, start, end, api, rearrange, setAnalytics, setAllOrder]
  );

  const handleInputRateLimit = useCallback(
    (currIdx, searchString) => {
      if (searchString) setSearch(searchString);
      if (timeout.current) clearTimeout(timeout.current);
      const timer = setTimeout(() => {
        loadAnalytics(currIdx, searchString);
      }, 100);
      timeout.current = timer;
    },
    [loadAnalytics]
  );

  // Initial Load
  useEffect(() => {
    if (isMounted() && facility?.id && analytics == null) handleInputRateLimit();

    return () => {
      if (!isMounted()) {
        setAnalytics(null);
        setAllOrder(null);
      }
    };
  }, [isMounted, facility, analytics, handleInputRateLimit, setAnalytics, setAllOrder]);

  useEffect(() => {
    if (snapshot && prevSnapshot !== snapshot) {
      scrollPos.current = document.documentElement.scrollTop;
      refreshing.current = true;
      showLoader.current = true;
      handleInputRateLimit(analyticsCount, search);
    }
  }, [snapshot, prevSnapshot, search, analyticsCount, handleInputRateLimit]);

  useEffect(() => {
    if (search && prevSearch !== search) {
      showLoader.current = true;
      handleInputRateLimit(null, search);
    }
  }, [search, prevSearch, analytics, analyticsCount, handleInputRateLimit]);

  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + Math.ceil(window.scrollY) >= document.documentElement.offsetHeight &&
        !allLoaded.current &&
        !loading
      ) {
        showLoader.current = true;
        handleInputRateLimit(analyticsCount, search);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, [analytics, loading, handleInputRateLimit, search, analyticsCount]);

  // Handle Range Error
  useEffect(() => {
    if (isMounted() && start && end && start > end)
      addToast("Start date must occur before end date. Please adjust range.", "warning");
  }, [isMounted, addToast, start, end]);

  // Handle Search & Range Change
  useEffect(() => {
    if (isMounted() && snapshot && snapshot !== "custom range") {
      // Snapshot takes priority over custom range
      setStart(null);
      setEnd(null);
    }
  }, [isMounted, snapshot]);

  // Handle Screen Size Change
  useEffect(() => {
    const resize = () => {
      const prev = columnCount.current;
      if (window.innerWidth >= 767) columnCount.current = 3;
      else if (window.innerWidth >= 640) columnCount.current = 2;
      else columnCount.current = 1;
      // changed
      if (prev !== columnCount.current) {
        showLoader.current = true;
        visible.current = null;
        setAnalytics(null);

        handleInputRateLimit(null, search);
      }
    };

    window.addEventListener("resize", resize);

    return () => window.removeEventListener("resize", resize);
  }, [handleInputRateLimit, rearrange, setAnalytics, analytics, search]);

  const reload = () => {
    scrollPos.current = document.documentElement.scrollTop;
    refreshing.current = true;
    showLoader.current = true;
    handleInputRateLimit();
  };

  const checkIfExists = analytic => {
    api.callGet(analytic.id, {params: {checkExists: true}}).then(({data, status}) => {
      if (status === 200 && data) {
        setCurrentAnalytic(analytic);
        setShowModalPreview(true);
      }
    });
  };

  return (
    <>
      {(analyticsCount > 0 || !!search) && (
        <Menu ref={menuWrapper}>
          <Inline ref={outside}>
            <SearchWrapper>
              <SearchIcon>
                <FontAwesomeIcon icon={faSearch} />
              </SearchIcon>
              <Search
                type="text"
                placeholder="Search Statistics..."
                autoComplete="off"
                onChange={e => {
                  showLoader.current = true;
                  handleInputRateLimit(null, e.target.value);
                }}
              />
            </SearchWrapper>
            {analyticsCount > 0 && (
              <DropdownWrapper>
                <Dropdown
                  options={dropdownOptions}
                  placeholder="Apply Date Range to All Charts..."
                  selection={snapshot}
                  setSelection={val => {
                    if (val === "custom range") setShowCustomRange(true);
                    setSnapshot(val);
                  }}
                  showPlaceholder
                />
              </DropdownWrapper>
            )}
            {snapshot === "custom range" && (
              <Hide>
                <Button type="button" onClick={() => setShowCustomRange(prev => !prev)}>
                  <Abbr title="Date Select">
                    <FontAwesomeIcon icon={faCalendar} />
                  </Abbr>
                </Button>
              </Hide>
            )}
            {((analytics && analyticsCount > 1) || facility.types?.length > 0) && (
              <Button type="button" onClick={() => setShowModalCompare(true)}>
                Compare
              </Button>
            )}
            {analytics && analyticsCount > 0 && snapshot === "custom range" && (
              <CustomRange inline visible={showCustomRange ? 1 : 0}>
                <Inline>
                  <Label htmlFor="start" bold>
                    From
                  </Label>
                  <Input
                    type="date"
                    name="start"
                    onChange={e => setStart(e.target.value)}
                    placeholder="Select Date ..."
                    hasValue={start}
                  />
                </Inline>
                <Inline>
                  <Label htmlFor="end" bold>
                    To
                  </Label>
                  <Input
                    type="date"
                    name="end"
                    onChange={e => setEnd(e.target.value)}
                    placeholder="Select Date ..."
                    hasValue={start}
                  />
                </Inline>
              </CustomRange>
            )}
          </Inline>
        </Menu>
      )}

      {analytics && (
        <Masonry>
          {analyticsCount > 0 ? (
            analytics?.map((col, i) => (
              // eslint-disable-next-line react/no-array-index-key
              <MasonryColumn key={`analytic-col-${i}`}>
                {col.map(analytic => (
                  <MasonryItem data-testid="analytics" key={`analytic-${analytic.id}`}>
                    <button
                      type="button"
                      onClick={() => {
                        checkIfExists(analytic);
                      }}>
                      {["average", "min", "max", "total", "median"].includes(analytic.type) && (
                        <Statistic stat={analytic} />
                      )}
                      {analytic.type === "completionrate" && (
                        <Statistic stat={analytic} precision={0} />
                      )}
                      {analytic.type === "plot" && (
                        <Chart
                          chart={analytic}
                          allSources={analytic.sources}
                          label={analytic.label}
                          view="analytics"
                          chartType={analytic.chartType}
                        />
                      )}
                    </button>
                  </MasonryItem>
                ))}
              </MasonryColumn>
            ))
          ) : (
            <NotFound>
              {search?.length > 0 ? (
                <Text>No Analytics found.</Text>
              ) : (
                <Text>
                  No Analytics have been created.&nbsp;
                  {!facility?.checksheets?.length > 0 && "Please create a checksheet."}
                </Text>
              )}
            </NotFound>
          )}
        </Masonry>
      )}

      {loading && (
        <NotLoaded>
          <Loader />
        </NotLoaded>
      )}

      {showModalPreview && (
        <ModalPreviewAnalytic
          visible={showModalPreview}
          setVisible={setShowModalPreview}
          analytic={currentAnalytic}
          reload={reload}
          allOrder={allOrder}
        />
      )}

      {showModalCompare && (
        <ModalCompareAnalytic visible={showModalCompare} setVisible={setShowModalCompare} />
      )}
    </>
  );
};

Analytics.propTypes = {
  facility: PropTypes.objectOf(PropTypes.any).isRequired
};

// Style Overrides
const Menu = styled(Inline)`
  margin-bottom: ${pad}px;
  justify-content: start;
  align-items: start;
  gap: ${pad / 3}px;
`;

const CustomRange = styled.div`
  ${flex("column")};
  position: absolute;
  padding: ${pad}px;
  top: 40px;
  right: 0;
  gap: ${pad}px;
  box-shadow: ${shadow};
  background: ${({theme}) => theme.tertiary};
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  z-index: ${z("above")};
  visibility: ${({visible}) => (visible ? "visible" : "hidden")};

  ${bp(5)} {
    ${flex("row", "nowrap", "start")};
    position: relative;
    border: none;
    top: unset;
    padding: 0;
    background: none;
    border-radius: unset;
    margin-left: ${pad}px;
    box-shadow: none;
  }

  ${Inline} {
    justify-content: space-between;

    ${bp(5)} {
      width: 50%;
      margin: 0;
    }
  }
`;

const NotFound = styled(MasonryItem)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 75px;
  padding: ${pad}px;
  border-radius: ${radius};
  border: ${border} solid ${({theme}) => theme.secondary};
  max-width: 100% !important;
  flex: unset;
`;

const DropdownWrapper = styled.div`
  min-width: 70px;

  ${bp(3)} {
    min-width: 130px;
  }
`;

const Hide = styled.div`
  display: block;
  ${bp(5)} {
    display: none;
  }
`;

export default Analytics;

import React from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCloud,
  faSun,
  faSmog,
  faCloudRain,
  faThunderstorm,
  faSnowflake,
  faCloudSun,
  faWind
} from "@fortawesome/free-solid-svg-icons";

// Utils
import {toTitleCase} from "../utils/helpers";

// Style
import {voice} from "../style/components/typography";
import {flex} from "../style/components/mixins";
import {colors, pad, radius} from "../style/components/variables";
import {Text} from "../style/components/general";

const getWeatherIcon = description => {
  if (description === "Partly Cloudy") return faCloudSun;
  if (description === "Cloudy") return faCloud;
  if (description === "Sunny") return faSun;
  if (description === "Fog") return faSmog;
  if (description === "Rain") return faCloudRain;
  if (description === "Thunderstorm") return faThunderstorm;
  if (description === "Snow" || description === "Hail") return faSnowflake;
  if (description === "Windy") return faWind;
  return null;
};

const Weather = React.forwardRef(({weather}, ref) => (
  <WeatherWrapper ref={ref}>
    <WeatherDetails>
      <WeatherDescription>
        <FontAwesomeIcon icon={getWeatherIcon(weather.description)} />
        <Text>{toTitleCase(weather.description)}</Text>
      </WeatherDescription>
      <WeatherAttrContainer>
        <WeatherAttrLabel>Avg</WeatherAttrLabel>
        <Text>{weather.tempAvg} ˚F</Text>
      </WeatherAttrContainer>
      <HighLowContainer>
        <TempContainer high={1}>{weather.tempMax}˚</TempContainer>
        <TempContainer>{weather.tempMin}˚</TempContainer>
      </HighLowContainer>
      {weather?.rainfall > 0 && (
        <WeatherAttrContainer>
          <WeatherAttrLabel>Rainfall</WeatherAttrLabel>
          <Text>{weather.rainfall} in.</Text>
        </WeatherAttrContainer>
      )}
      {weather?.cumulative > 0 && (
        <WeatherAttrContainer>
          <WeatherAttrLabel>Cumulative</WeatherAttrLabel>
          <Text>{weather.cumulative} in.</Text>
        </WeatherAttrContainer>
      )}
    </WeatherDetails>
  </WeatherWrapper>
));

Weather.displayName = "Weather";

Weather.propTypes = {weather: PropTypes.objectOf(PropTypes.any).isRequired};

// Style Overrides
const WeatherWrapper = styled.div`
  border: 2px solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  padding: ${pad}px;
  margin-bottom: ${pad}px;
`;

const WeatherDetails = styled.div`
  ${flex("row", "nowrap", "space-around", "center")}

  ${voice.normal}
  font-weight: bold;
`;

const TempContainer = styled.div`
  font-weight: bold;
  ${({high, theme}) =>
    high
      ? css`
          border-bottom: 1px solid ${theme.secondary};
          padding-bottom: ${pad / 3}px;
          color: ${colors.orange};
        `
      : css`
          padding-top: ${pad / 3}px;
          color: ${colors.blueLight};
        `}
`;

const WeatherAttrLabel = styled.div`
  font-size: 11px;
  font-weight: normal;
  color: ${({theme}) => theme.secondary};
`;

const HighLowContainer = styled.div`
  ${voice.quiet}
  gap: 0;
  display: block;

  div {
    text-align: center;
  }
`;

const WeatherDescription = styled.div`
  margin-top: -1px;
  ${voice.small}
  ${flex("column", "nowrap", "center", "center")}
  gap: ${pad / 2}px;
  color: ${({theme}) => theme.primary};

  svg {
    ${voice.medium}
    fill: ${({theme}) => theme.primary};
  }
`;

const WeatherAttrContainer = styled.div`
  ${voice.small}
  ${flex("column", "nowrap", "start", "center")}
  gap: ${pad / 2}px;
`;

export default Weather;

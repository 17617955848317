import React, {useContext, useState, useEffect, useCallback, useRef, useMemo} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Contexts
import {AuthContext} from "../../contexts/auth.js";
import {useSocket} from "../../contexts/socket.js";

// Hooks
import useMountedState from "../../hooks/useMountedState.js";
import useApi from "../../hooks/useApi.js";
import usePrevious from "../../hooks/usePrevious.js";

// Components
import FacilityCard from "./FacilityCard.js";
import {ACTIVE_STATES, GET_ACTIVE_STATES} from "../general/Room.js";

// Style
import {pad} from "../../style/components/variables.js";
import {bp} from "../../style/components/breakpoints.js";
import {flex} from "../../style/components/mixins.js";
import {Loader, TextCenter} from "../../style/components/general.js";

const FacilityCards = ({
  facilities,
  setFacilities,
  query,
  filters,
  orderBy,
  showArchived,
  setTargetFacility,
  setShowModalSettings,
  setShowModalFavorite
}) => {
  const isMounted = useMountedState();

  const {currentUser, roleCanAccessResource} = useContext(AuthContext);

  const prevQuery = usePrevious(query);
  const prevOrderBy = usePrevious(orderBy);
  const prevFilters = usePrevious(filters);

  const [overflow, setOverflow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageTotal, setPageTotal] = useState(1);
  const [slugs, setSlugs] = useState(null);
  const [activeStates, setActiveStates] = useState();

  const favorites = useMemo(() => currentUser?.favorites || [], [currentUser]);

  const currentPage = useRef(1);
  const limit = useRef(12);

  const {api} = useApi("facilities");

  const controller = useRef();

  const getFacilities = useCallback(
    (p = 1, l = undefined, withSlugs = false) => {
      setLoading(true);

      if (controller.current) controller.current.abort();
      controller.current = new AbortController();

      const filter = {};
      const payload = {
        page: p,
        limit: Math.min(l || limit.current || 12, 12),
        orderBy,
        groupBy: "name", // constant for cards
        withTasksDue: true,
        withAllTypes: !showArchived,
        withDeleted: showArchived,
        withSlugs,
        favorites: favorites || []
      };

      if (query) filter.Search = query;
      if (filters?.types) filter.Type = filters.types;
      if (filters?.checksheets) filter.Checksheet = filters.checksheets;
      if (filters?.states) filter.State = filters.states;

      if (filter && Object.keys(filter).length > 0) payload.filter = JSON.stringify(filter);

      api
        .callGet(null, payload, {signal: controller.current.signal})
        .then(({status, data}) => {
          if (status === 200 && data) {
            const {page, pages, facilities: visible, slugs: allSlugs} = data;
            currentPage.current = page;
            setPageTotal(pages);
            setFacilities(prev => (prev ? [...prev, ...visible] : visible));
            if (allSlugs) setSlugs(allSlugs);
          }
        })
        .finally(() => {
          setOverflow(false);
          setLoading(false);
        });
    },
    [api, filters, setFacilities, showArchived, favorites, orderBy, query]
  );

  // Initial Load
  useEffect(() => {
    if (isMounted() && !loading && !facilities) {
      currentPage.current = 1;
      // estimation for number of cards to overflow
      const approxCards = Math.floor(window.innerHeight / 150) * 4;
      limit.current = approxCards;
      getFacilities(1, approxCards, true);
    }
  }, [isMounted, loading, facilities, getFacilities]);

  // Load more rows to fill the page
  useEffect(() => {
    if (
      !loading &&
      facilities &&
      currentPage.current < pageTotal &&
      (facilities.length < limit.current || overflow)
    )
      getFacilities(currentPage.current + 1);
  }, [facilities, pageTotal, loading, getFacilities, overflow, query, filters]);

  // Load more on scroll
  const handleScroll = useCallback(() => {
    const {scrollHeight, clientHeight, scrollTop} = document.documentElement;
    const maxScrollTop = scrollHeight - clientHeight;

    if (scrollTop + 1 >= maxScrollTop && !loading) setOverflow(true);
  }, [loading]);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  // Update facilities on query, sort and deleted
  useEffect(() => {
    if (
      isMounted() &&
      (prevQuery !== query || (prevOrderBy && prevOrderBy !== orderBy) || prevFilters !== filters)
    ) {
      if (controller?.current) controller.current.abort();
      setFacilities(null);
    }
  }, [
    isMounted,
    query,
    orderBy,
    showArchived,
    facilities,
    prevQuery,
    prevOrderBy,
    setFacilities,
    prevFilters,
    filters
  ]);

  const socket = useSocket();

  // Socket Management
  const handleActiveStates = states => setActiveStates(states);

  useEffect(() => {
    if (isMounted() && currentUser && currentUser.publicId) socket.emit(GET_ACTIVE_STATES);

    socket.on(ACTIVE_STATES, handleActiveStates);

    return () => {
      // unbind all event handlers used in this component
      socket.off(ACTIVE_STATES, handleActiveStates);
    };
  }, [isMounted, currentUser, socket]);

  const getFacilityMessage = () => {
    if (query || (!!filters && Object.values(filters).some(val => val?.length)))
      return <TextCenter>No facilities found</TextCenter>;
    if (roleCanAccessResource("facility", "create"))
      return (
        <TextCenter>
          No Facilities have been created please click `Create New` to add one.
        </TextCenter>
      );
    return (
      <TextCenter>
        You are not assigned to any facilities, please contact your manager to assign you.
      </TextCenter>
    );
  };

  return (
    <Facilities>
      {!loading && facilities?.length === 0 && getFacilityMessage()}
      {facilities?.map(facility => (
        <CardWrapper key={facility.id}>
          <FacilityCard
            facility={facility}
            slugs={slugs}
            showArchived={showArchived}
            setTarget={setTargetFacility}
            setShowModalSettings={setShowModalSettings}
            setShowModalFavorite={setShowModalFavorite}
            favorites={favorites}
            initialStates={activeStates ? activeStates[facility.slug] : []}
          />
        </CardWrapper>
      ))}

      <LoadWrapper show={loading}>
        <Loader />
      </LoadWrapper>
    </Facilities>
  );
};

FacilityCards.propTypes = {
  facilities: PropTypes.arrayOf(PropTypes.any),
  setFacilities: PropTypes.func.isRequired,
  query: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.any),
  orderBy: PropTypes.string,
  showArchived: PropTypes.bool,
  setTargetFacility: PropTypes.func.isRequired,
  setShowModalSettings: PropTypes.func.isRequired,
  setShowModalFavorite: PropTypes.func.isRequired
};

FacilityCards.defaultProps = {
  facilities: null,
  query: null,
  filters: null,
  orderBy: "asc",
  showArchived: false
};

// Style Overrides
const Facilities = styled.section`
  position: relative;
  width: 100%;
  min-height: 100px;
  margin: ${pad * 2}px 0;
  gap: ${pad * 1.5}px;
  ${flex("row", "wrap")};
`;

const CardWrapper = styled.div`
  position: relative;
  width: calc(50% - 7.5px);

  ${bp(2)} {
    max-width: calc(33.33333% - 10px);
  }

  ${bp(5)} {
    max-width: calc(25% - 11.25px);
  }
`;

const LoadWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 20px;
  opacity: ${({show}) => (show ? 1 : 0)};

  div {
    margin-top: 0;
  }
`;

export default FacilityCards;

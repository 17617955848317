import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import {FormProvider, useForm} from "react-hook-form";
import styled from "styled-components";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

// Utils
import {AuthContext} from "../../contexts/auth.js";
import useApi from "../../hooks/useApi.js";

// Components
import {InputFileGroup, InputTextArea, InputSelect} from "../../components/form/FormInputs.js";

// Style
import {pad} from "../../style/components/variables.js";
import {Form, FormField, Inline, Button, ButtonLoader} from "../../style/components/general.js";

const ArchiveRecordNote = ({setVisible, record, task, taskType, date, updateData, showCancel}) => {
  const {currentUser} = useContext(AuthContext);

  const {api: apiNotes, loading} = useApi(
    taskType === "checksheet" ? "record-notes" : "event-record-notes"
  );

  const [files, setFiles] = useState([]);

  const schema = yup.object().shape({
    name: yup
      .string()
      .required("Please select a reason")
      .test({
        test: value => !!value,
        message: "Please select a reason"
      }),
    message: yup
      .string()
      .nullable()
      .test({
        test: (value, ctx) =>
          ctx.parent.name === "Hero was not implemented at this time" || !!value,
        message: "Please provide a description"
      })
  });

  const form = useForm({
    defaultValues: {
      name: "",
      message: ""
    },
    resolver: yupResolver(schema)
  });

  const {handleSubmit, watch} = form;

  const name = watch("name");

  const submit = values => {
    const {name: nameProvided, message, coords} = values;

    const params = {
      name: nameProvided,
      message: message || "Hero was not implemented at this time",
      userId: currentUser.publicId,
      taskId: task.id,
      taskRecordId: record?.id,
      acknowledging: true,
      status: "archived",
      referenceDate: date
    };

    if (coords && coords.lat !== "" && coords.lat !== "") {
      params.lat = coords.lat;
      params.lon = coords.lon;
    }

    const fileData = new FormData();

    if (files.length > 0)
      for (let i = 0; i < files.length; i++) {
        fileData.append(`file-${i}`, new File([files[i]], files[i].name, {type: files[i].type}));
      }

    apiNotes
      .callPost(fileData, {params, headers: {"Content-Type": "multipart/form-data"}})
      .then(({status, data: resData}) => {
        if (status === 201) {
          if (record?.postedNotes && params.status === "posted")
            record.postedNotes.push(resData.data);
          else if (record && params.status === "posted") record.postedNotes = [resData.data];
          updateData();
          setVisible(false);
        }
      });
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={handleSubmit(submit)} noValidate>
        <FormField>
          <InputSelect
            name="name"
            label="Reason"
            placeholder="Select..."
            options={[
              "Hero was not implemented at this time",
              "Manager error",
              "Employee error",
              "Other"
            ]}
          />
        </FormField>
        {name !== "Hero was not implemented at this time" && (
          <FormField>
            <InputTextArea
              name="message"
              label="Description"
              testId="addNote.message"
              maxLength={1000}
            />
          </FormField>
        )}

        <InputFileGroup name="addNote.files" setFiles={setFiles} />

        <ButtonWrapper>
          <Button type="submit" data-testid="addNote.submit" loading={loading ? 1 : 0}>
            Save
            {loading && <ButtonLoader />}
          </Button>
          {showCancel && (
            <Button type="button" data-testid="addNote.cancel" onClick={() => setVisible(false)}>
              Cancel
            </Button>
          )}
        </ButtonWrapper>
      </Form>
    </FormProvider>
  );
};

ArchiveRecordNote.propTypes = {
  setVisible: PropTypes.func.isRequired,
  task: PropTypes.objectOf(PropTypes.any).isRequired,
  record: PropTypes.objectOf(PropTypes.any),
  taskType: PropTypes.string,
  date: PropTypes.string,
  updateData: PropTypes.func.isRequired,
  acknowledging: PropTypes.bool,
  showCancel: PropTypes.bool
};

ArchiveRecordNote.defaultProps = {
  record: null,
  taskType: "checksheet",
  date: null,
  acknowledging: false,
  showCancel: false
};

// Style Overrides
const ButtonWrapper = styled(Inline)`
  gap: ${pad}px;
  margin-top: ${pad}px;
`;

export default ArchiveRecordNote;

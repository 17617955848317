import React, {useContext, useState, useEffect, useCallback} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faStar as faStarFilled} from "@fortawesome/free-solid-svg-icons";
import {faStar} from "@fortawesome/free-regular-svg-icons";

// Contexts
import {AuthContext} from "../../contexts/auth.js";
import {NavContext} from "../../contexts/nav.js";

// Hooks
import useMountedState from "../../hooks/useMountedState.js";

// Utils
import {pulse} from "../../style/components/animations.js";

// Style
import {flex, z} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {pad, shadow} from "../../style/components/variables.js";
import {Inline} from "../../style/components/general.js";

// Socket Constants
import {CHECK_STATE, STATE_OFF, STATE_ON} from "../general/Room.js";

const TableFacilitiesFacilityName = ({
  facility,
  socket,
  setTarget,
  favorites,
  setShowModalFavorite
}) => {
  const {name, slug} = facility;

  const {currentUser} = useContext(AuthContext);
  const {states} = useContext(NavContext);

  const isMounted = useMountedState();

  const [activeStates, setActiveStates] = useState([]);

  const facilityOnTest = useCallback(
    payload => {
      if (payload.facility && payload.facility === slug && !activeStates.includes(payload.state))
        setActiveStates(prev => [...prev, payload.state]);
    },
    [slug, activeStates]
  );

  const facilityOffTest = useCallback(
    payload => {
      if (payload.facility && payload.facility === slug)
        setActiveStates(prev => prev.filter(state => state === payload.state));
    },
    [slug]
  );

  useEffect(() => {
    if (isMounted() && currentUser && currentUser.publicId && socket)
      socket.emit(CHECK_STATE, slug);
  }, [currentUser, isMounted, slug, socket]);

  // Socket Management
  useEffect(() => {
    if (socket) {
      socket.on(STATE_ON, facilityOnTest);
      socket.on(STATE_OFF, facilityOffTest);
    }

    return () => {
      if (socket) {
        // unbind all event handlers used in this component
        socket.off(STATE_ON, facilityOnTest);
        socket.off(STATE_OFF, facilityOffTest);
      }
    };
  }, [socket, facilityOnTest, facilityOffTest]);

  return (
    <NameWrapper>
      <TableLink to={`/facilities/${slug}`} title={name}>
        {name}
      </TableLink>
      {!facility?.isDeleted && (
        <Favorite
          type="button"
          onClick={() => {
            setTarget(facility);
            setShowModalFavorite(true);
          }}>
          <FontAwesomeIcon
            icon={favorites?.includes(facility.id) ? faStarFilled : faStar}
            className="primaryIcon">
            Favorite Facility
          </FontAwesomeIcon>
        </Favorite>
      )}
      {states && activeStates?.length > 0 && (
        <BannerWrapper>
          {activeStates
            ?.filter(state => states[state])
            ?.map(state => (
              <Banner key={state} color={`#${states[state].color}`}>
                ON {state}
              </Banner>
            ))}
        </BannerWrapper>
      )}
    </NameWrapper>
  );
};

TableFacilitiesFacilityName.propTypes = {
  facility: PropTypes.objectOf(PropTypes.any).isRequired,
  socket: PropTypes.object.isRequired,
  setTarget: PropTypes.func,
  setShowModalFavorite: PropTypes.func,
  favorites: PropTypes.arrayOf(PropTypes.number)
};

TableFacilitiesFacilityName.defaultProps = {
  setTarget: null,
  setShowModalFavorite: null,
  favorites: []
};

// Style Overrides
const NameWrapper = styled(Inline)`
  align-items: start;
  gap: ${pad / 2}px;
`;

const BannerWrapper = styled.div`
  ${flex("column", "nowrap")}
  gap: ${pad / 2}px;
  margin: ${pad / 2}px ${pad}px 0;
  z-index: ${z("top")};
`;

const Favorite = styled.button`
  width: inherit;
  display: inherit;
  padding: 0 ${pad}px;
  background: transparent;
  z-index: ${z("top")};

  .primaryIcon {
    ${voice.quiet};
    /* allow a larger click area */
    position: absolute;
    top: 0;
    right: 0;
    padding: ${pad / 2}px;
    fill: ${({theme}) => theme.secondary};
  }
`;

const TableLink = styled(Link)`
  font-weight: bold;
  align-self: flex-start;
  vertical-align: top;

  &:hover {
    color: ${({theme}) => theme.primary};
    transition: all ease 0.5s;
  }
`;

const Banner = styled.div`
  position: relative;
  border-radius: 10px;
  font-size: 10px;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  width: max-content;
  color: ${({theme}) => theme.tertiary};
  padding: ${pad / 4}px ${pad / 2}px;
  background-color: ${({color, theme}) => color || theme.error};
  box-shadow: ${shadow};

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({color, theme}) => color || theme.error};
    border-radius: 10px;
    z-index: -1;
    animation: ${({color, theme}) => pulse(color || theme.error)} 2s infinite;
  }
`;

export default TableFacilitiesFacilityName;

import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHistory} from "@fortawesome/free-solid-svg-icons";

// Utils
import {getAncestryName} from "../pages/checksheet-builder/helpers.js";

// Style
import {Inline, Text} from "../style/components/general.js";
import {border, radius} from "../style/components/variables.js";

const Formula = ({name, byId, readOnlyFormula, highlight, defaultAncestry}) => {
  const formula = readOnlyFormula;

  const abbreviate = str => {
    if (str.length > 8) return `${str.slice(0, 8)}...`;
    return str;
  };

  const getVarTitle = part => {
    if (highlight && highlight.label && part.value === highlight.value)
      return defaultAncestry ? `${defaultAncestry} ${highlight.label}` : highlight.label;
    if (defaultAncestry && highlight && part.value !== highlight.value)
      return `${defaultAncestry} ${part.label}`;
    if (byId && part.value in byId) return getAncestryName(byId[part.value], {byId});
    return part.label;
  };

  const getVarLabel = part => {
    if (highlight && highlight.label && part.value === highlight.value)
      return abbreviate(highlight.label);
    if (byId && part.value in byId) return abbreviate(byId[part.value].label);
    return abbreviate(part.label);
  };

  if (!formula || formula.length === 0)
    return <NoFormula>Please insert variables and operations to build a formula.</NoFormula>;

  return (
    <Inline id={name ? `${name}.formula` : "formula"}>
      {formula.map((part, idx) => {
        if (part.type !== "variable")
          return (
            <div
              // eslint-disable-next-line react/no-array-index-key
              key={`part-${idx}`}
              data-testid="formula.item">
              {part.label}&nbsp;
            </div>
          );

        return (
          <div
            // eslint-disable-next-line react/no-array-index-key
            key={`part-${idx}`}
            data-testid="formula.item">
            <VariableAbbr title={getVarTitle(part)}>
              <Variable
                highlight={highlight && highlight.value === part.value && !highlight.label ? 1 : 0}>
                {getVarLabel(part)}
                {part.previous && (
                  <>
                    &nbsp;
                    <FontAwesomeIcon icon={faHistory} />
                  </>
                )}
              </Variable>
            </VariableAbbr>
            &nbsp;
          </div>
        );
      })}
    </Inline>
  );
};

Formula.propTypes = {
  name: PropTypes.string,
  byId: PropTypes.objectOf(PropTypes.any).isRequired,
  readOnlyFormula: PropTypes.arrayOf(PropTypes.any),
  highlight: PropTypes.objectOf(PropTypes.any),
  defaultAncestry: PropTypes.string
};

Formula.defaultProps = {
  name: null,
  readOnlyFormula: null,
  highlight: null,
  defaultAncestry: null
};

// Style Overrides
const NoFormula = styled(Text)`
  margin-left: auto;
  margin-right: auto;
  width: max-content;
  word-break: break-all;
  white-space: normal;
`;

const Variable = styled.div`
  border-radius: ${radius};
  border: ${border} solid ${({highlight, theme}) => (highlight ? theme.error : theme.primary)};
  background-color: ${({highlight, theme}) => (highlight ? theme.error : theme.primary)};
  color: ${({highlight}) => (highlight ? "white" : "black")};

  svg {
    font-size: 10pt;
  }
`;

const VariableAbbr = styled.abbr`
  text-decoration: none;
`;

export default Formula;

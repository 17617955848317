import React, {useEffect, useRef} from "react";
import {useFormContext} from "react-hook-form";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";

// Components
import InputCheck from "./InputCheck.js";
import InputError from "./InputError.js";
import Help from "../Help.js";

// Style
import {pad} from "../../style/components/variables.js";
import {FormFieldWrapper, Button, Label, Text, Pill, Abbr} from "../../style/components/general.js";

const InputCheckGroup = ({
  name,
  label,
  tag,
  options,
  disabled,
  required,
  testId,
  prompt,
  all,
  condition
}) => {
  const {
    watch,
    setValue,
    formState: {errors}
  } = useFormContext();

  const values = watch(name);
  const checkboxes = useRef(false);

  const handleSelectAll = () => {
    const inputs = checkboxes.current.querySelectorAll("input");
    const fieldValues = [];
    if (!values || (values && values.length < options.length))
      Array.from(inputs).forEach(input => {
        fieldValues.push(input.value);
      });

    setValue(name, fieldValues);
  };

  useEffect(() => {
    if (values && typeof values === "string") setValue(name, [values]);
  }, [values, setValue, name]);

  const sanitizeValue = option => {
    if (option?.value) return option.value;
    if (option?.option) return option.option;
    if (option?.name) return option.name;
    return option;
  };

  const sanitizeLabel = option => {
    if (option?.label) return option.label;
    if (option?.option) return option.option;
    if (option?.name) return option.name;
    if (option?.value) return option.value;
    return option;
  };

  return (
    <FormFieldWrapper id="wrapper" ref={checkboxes}>
      {label && (
        <LabelWrapper htmlFor={name} bold inline>
          {label.toUpperCase()}
          {required && <span>*</span>}
          {tag && (
            <Pill quiet>
              <Abbr title={tag}>{tag}</Abbr>
            </Pill>
          )}
          {condition && <Help icon={<FontAwesomeIcon icon={faCheckDouble} />}>{condition}</Help>}
        </LabelWrapper>
      )}

      {prompt && <Prompt quiet>{prompt}</Prompt>}

      <Options>
        {options?.map((option, index) => (
          <InputCheck
            key={sanitizeValue(option)}
            id={name}
            testId={testId && `${testId}.${index + 1}`}
            name={name}
            value={sanitizeValue(option)}
            disabled={disabled}
            showError={false}>
            {sanitizeLabel(option)}
          </InputCheck>
        ))}
      </Options>

      {all && options.length > 1 && (
        <Button type="button" disabled={disabled} onClick={handleSelectAll}>
          {!values || values.length < options.length ? "Select All" : "Deselect All"}
        </Button>
      )}

      <InputError errors={errors} name={name} />
    </FormFieldWrapper>
  );
};

InputCheckGroup.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  disabled: PropTypes.bool,
  all: PropTypes.bool,
  required: PropTypes.bool,
  prompt: PropTypes.string,
  condition: PropTypes.string,
  tag: PropTypes.string
};

InputCheckGroup.defaultProps = {
  testId: "input-check-group",
  label: null,
  disabled: false,
  all: false,
  required: false,
  prompt: "",
  condition: undefined,
  tag: undefined
};

// Style Overrides
const Prompt = styled(Text)`
  margin-bottom: ${pad}px;
`;

const Options = styled.div`
  div:first-child {
    margin: 0;
  }
`;

const LabelWrapper = styled(Label)`
  gap: ${pad / 2}px;
  margin-bottom: 2px;
`;

export default InputCheckGroup;

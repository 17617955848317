import React, {useEffect, useState} from "react";
import styled, {css} from "styled-components";
import {Link} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons";

// Utils
import useApi from "../hooks/useApi.js";

// Components
import InputText from "../components/form/InputText.js";
import Footer from "./general/Footer.js";

// Style
import {flex, z} from "../style/components/mixins.js";
import {voice} from "../style/components/typography.js";
import {bp} from "../style/components/breakpoints.js";
import {animateBackground} from "../style/components/animations.js";
import {border, heroTheme, navHeight, pad, radius} from "../style/components/variables.js";
import {Text, FormGroup, Form, Success, Inline, ButtonLoader} from "../style/components/general.js";

const schema = yup.object().shape({
  email: yup.string().email("Invalid email address").required("Please provide email")
});

const ForgotPassword = () => {
  const [sent, setSent] = useState(false);
  const [email, setEmail] = useState(null);

  const {api, loading} = useApi("forgot-password");

  const form = useForm({
    resolver: yupResolver(schema)
  });
  const {watch, handleSubmit} = form;

  const emailInput = watch("email");

  useEffect(() => {
    if (emailInput) setEmail(emailInput);
  }, [emailInput]);

  const sendEmail = () =>
    api.callPost({email: email}).then(response => response.status === 200 && setSent(true));

  return (
    <ForgotPasswordPage>
      <Header>
        <Logo src={`${process.env.PUBLIC_URL}/hero-services.png`} alt="Hero Services Logo" />
      </Header>
      <BackButton to="/signin">
        <FontAwesomeIcon icon={faArrowLeft} />
        &nbsp;Back to Sign In
      </BackButton>
      <PageWrapper>
        <Content>
          <PageHeader>Forgot Password</PageHeader>
          {!sent ? (
            <FormProvider {...form}>
              <Form onSubmit={handleSubmit(sendEmail)} noValidate>
                <FormGroup>
                  <Instructions>
                    Please provide your Hero Builder Account email to receive instructions for
                    resetting your password.
                  </Instructions>
                  <InputText
                    type="email"
                    name="email"
                    placeholder="Enter your email"
                    required
                    {...form}
                  />
                  <Send type="submit" loading={loading}>
                    Send{loading && <ButtonLoader />}
                  </Send>
                </FormGroup>
              </Form>
            </FormProvider>
          ) : (
            <>
              <Success>Reset Password email sent!</Success>
              <Inline>
                <Text inline>
                  Didn&apos;t receive an email,&nbsp;
                  <Resend type="button" onClick={sendEmail}>
                    Resend
                  </Resend>
                  ?
                </Text>
              </Inline>
            </>
          )}
        </Content>
      </PageWrapper>
      <Footer />
    </ForgotPasswordPage>
  );
};

// Style Overrides
const ForgotPasswordPage = styled.section`
  position: relative;
  width: 100vw;
  height: 100vh;
  ${flex("column", "nowrap", "start", "center")};
`;

const Header = styled.nav`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  align-self: start;
  display: flex;
  background: ${heroTheme.secondary};
  z-index: ${z("top")};

  ${props =>
    props.theme.name === "dark" &&
    css`
      border-bottom: ${border} solid ${heroTheme.tertiary};
    `}
`;

const Logo = styled.img`
  height: 75px;
  margin: ${pad}px auto;
`;

const BackButton = styled(Link)`
  position: absolute;
  top: ${navHeight}px;
  left: 0;
  width: max-content;
  margin: ${pad * 4}px ${pad * 2}px;
  color: ${heroTheme.primary};
  ${voice.quiet};

  ${bp(3)} {
    ${voice.small};
  }
`;

const Instructions = styled(Text)`
  margin: ${pad}px 0;
  text-align: center;
`;

const PageWrapper = styled.section`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

const Content = styled.div`
  width: 440px;
  max-width: 100%;
  padding: ${pad}px;
  margin: 0 auto;
  ${flex("column", "nowrap", "center", "center")};
`;

const PageHeader = styled.h1`
  color: ${heroTheme.secondary};
  margin-bottom: ${pad}px;
  text-align: center;
  font-weight: bold;
  ${voice.strong};

  ${bp(5)} {
    ${voice.loud};
  }

  ${props =>
    props.theme.name === "dark" &&
    css`
      color: ${heroTheme.tertiary};
    `}
`;

const Send = styled.button`
  position: relative;
  border: 0;
  align-self: center;
  padding: ${pad}px;
  margin-top: ${pad}px;
  color: ${heroTheme.tertiary};
  border-radius: ${radius};
  background-color: ${heroTheme.secondary};

  ${props =>
    props.theme.name === "dark" &&
    css`
      background-color: ${heroTheme.primary};
    `};

  ${props =>
    props.loading &&
    css`
      margin-bottom: ${pad}px;
      position: relative;
      display: inline-block;
      opacity: 100%;
      overflow: hidden;
      animation: ${animateBackground} 0.15s linear 0s 1 forwards;
      animation-delay: 0.3s;
      pointer-events: none;
    `}
`;

const Resend = styled.button`
  display: contents;
  text-decoration: underline;
  width: max-content;
  max-width: max-content;
  color: ${heroTheme.primary};
  ${voice.normal};
`;

export default ForgotPassword;

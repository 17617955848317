import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import dayjs from "dayjs";

// Components
import Modal from "../../components/Modal.js";
import ArchiveRecordNote from "./ArchiveRecordNote.js";

// Style
import {pad} from "../../style/components/variables.js";
import {Heading, Text} from "../../style/components/general.js";
import {breakpoint} from "../../style/components/breakpoints.js";

const ArchiveRecord = ({
  visible,
  setVisible,
  record,
  task,
  taskType,
  date,
  updateData,
  goBack,
  hasBackButton
}) => (
  <Modal
    visible={visible}
    setVisible={setVisible}
    isChecksheetPreview
    goBack={goBack}
    hasBackButton={hasBackButton}>
    <Heading>Archive Overdue Record</Heading>

    <Detail>
      <Text>
        <strong>{taskType === "checksheet" ? "Checksheet:" : "Event:"}</strong> {task?.name}
      </Text>
      <Text>
        <strong>Due:</strong> {dayjs(date).format("MMM D, YYYY")}
      </Text>
    </Detail>

    <ArchiveRecordNote
      setVisible={setVisible}
      task={task}
      record={record}
      taskType={taskType}
      date={date}
      updateData={updateData}
      showCancel
    />
  </Modal>
);

ArchiveRecord.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  record: PropTypes.objectOf(PropTypes.any),
  task: PropTypes.objectOf(PropTypes.any).isRequired,
  taskType: PropTypes.string,
  date: PropTypes.string.isRequired,
  updateData: PropTypes.func.isRequired,
  goBack: PropTypes.func,
  hasBackButton: PropTypes.bool
};

ArchiveRecord.defaultProps = {
  record: null,
  taskType: "checksheet",
  goBack: null,
  hasBackButton: false
};

// Style Overrides
const Detail = styled.div`
  margin-right: ${pad}px;

  @media (min-width: ${breakpoint.width[3]}) {
    margin-bottom: ${pad}px;
  }
`;

export default ArchiveRecord;

import React, {useContext, useEffect, useMemo} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCalendar,
  faFileExport,
  faFilter,
  faList,
  faPlus,
  faCog
} from "@fortawesome/free-solid-svg-icons";

// Utils
import useMountedState from "../../hooks/useMountedState";
import {AuthContext} from "../../contexts/auth";
import {getWithExpiry} from "../../utils/helpers";
import {CalendarContext} from "../../contexts/calendar";

// Components
import MonthPicker from "../../components/MonthPicker";
import Badge from "../../components/Badge";
import Dropdown from "../../components/Dropdown";

// Style
import {bp} from "../../style/components/breakpoints";
import {voice} from "../../style/components/typography";
import {flex} from "../../style/components/mixins";
import {pad, colors} from "../../style/components/variables";
import {Arrow, Button} from "../../style/components/general";
import {shake} from "../../style/components/animations";

const Menu = ({
  view,
  setView,
  filters,
  setFilters,
  addEvent,
  hasChanges,
  setHasChanges,
  setShowModalExport,
  setShowModalFilter,
  setShowModalSettings
}) => {
  const isMounted = useMountedState();

  const {roleCanAccessResource} = useContext(AuthContext);

  const {
    getView,
    setView: setCalendarView,
    increment,
    decrement,
    getActiveMonth,
    getActiveYear,
    setMonth,
    reset
  } = useContext(CalendarContext);

  // Load filters from storage
  useEffect(() => {
    const stored = getWithExpiry("scheduleFilters");
    if (isMounted() && stored) {
      setFilters(JSON.parse(stored));
    }
  }, [isMounted, setFilters]);

  const filterCount = useMemo(() => {
    let count = 0;
    if (filters)
      Object.keys(filters).map(filter => {
        count += filters[filter]?.length || 0;
      });
    return count;
  }, [filters]);

  return (
    <Wrapper>
      <MenuNav>
        <Toggle
          type="button"
          title={view === "list" ? "Switch to Calendar Veiw" : "Switch to List Veiw"}
          onClick={() => setView(prev => (prev === "list" ? "calendar" : "list"))}>
          <FontAwesomeIcon icon={view === "list" ? faCalendar : faList} />
        </Toggle>
        <NavButton onClick={decrement} data-testid="calendarHelper.backward">
          <Arrow />
        </NavButton>
        <NavButton onClick={increment} data-testid="calendarHelper.forward">
          <Arrow rotate="-180deg" />
        </NavButton>
        <MonthPicker
          month={getActiveMonth()}
          year={getActiveYear()}
          setMonth={date => setMonth(date)}
        />
        <Today onClick={() => reset()} data-testid="calendarHelper.today">
          Today
        </Today>
        <Settings
          type="button"
          title="Schedule Settings"
          onClick={() => setShowModalSettings(true)}>
          <FontAwesomeIcon icon={faCog} />
        </Settings>
      </MenuNav>

      {hasChanges && (
        <Changes
          type="button"
          title="Events have changed since your last page load"
          onClick={setHasChanges}>
          Load new changes!
        </Changes>
      )}

      <Actions>
        {roleCanAccessResource("event", "export") && view === "list" && (
          <Action>
            <Button onClick={() => setShowModalExport(true)}>
              <FontAwesomeIcon icon={faFileExport} alt="Export" />
            </Button>
          </Action>
        )}
        <Action>
          <Filters>
            <Button type="button" onClick={() => setShowModalFilter(true)}>
              <Badge count={filterCount} offset="14px" color={colors.heroGreen} />
              <FontAwesomeIcon icon={faFilter} />
            </Button>
          </Filters>
        </Action>
        <Action>
          <Dropdown
            name="view"
            options={[
              {label: "Month", value: "month"},
              {label: "Week", value: "week"}
            ]}
            selection={getView()}
            setSelection={selection => setCalendarView(selection)}
            testId="calendarHelper.viewType"
          />
        </Action>
        {roleCanAccessResource("event", "create") && addEvent && (
          <Action>
            <Button data-testid="calendarHelper.addEvent" onClick={() => addEvent()}>
              <FontAwesomeIcon icon={faPlus} alt="Add Event" />
            </Button>
          </Action>
        )}
      </Actions>
    </Wrapper>
  );
};

Menu.propTypes = {
  view: PropTypes.string.isRequired,
  setView: PropTypes.func.isRequired,
  filters: PropTypes.objectOf(PropTypes.any),
  setFilters: PropTypes.func.isRequired,
  addEvent: PropTypes.func,
  hasChanges: PropTypes.bool,
  setHasChanges: PropTypes.func,
  setShowModalExport: PropTypes.func.isRequired,
  setShowModalFilter: PropTypes.func.isRequired,
  setShowModalSettings: PropTypes.func.isRequired
};

Menu.defaultProps = {
  filters: null,
  addEvent: null,
  hasChanges: false,
  setHasChanges: () => null
};

// Style Overrides
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  padding: ${pad}px;
  ${flex("row", "wrap", "space-between", "center")};

  ${bp(2)} {
    ${flex("row", "no-wrap", "space-between", "center")};
  }
`;

const MenuNav = styled.div`
  ${flex("row", "no-wrap", "space-between", "center")};
  margin-bottom: ${pad}px;

  ${bp(3)} {
    margin: 0;
  }
`;

const Toggle = styled(Button)`
  margin: 0 ${pad}px ${pad}px 0;

  ${bp(3)} {
    margin: 0 ${pad}px 0 0;
  }
`;

const Today = styled(Button)`
  ${voice.quiet};
  margin-left: ${pad}px;
`;

const Settings = styled.button`
  display: inline-block;
  padding-left: ${pad}px;
  width: min-content;
  padding: 0 ${pad}px;

  svg {
    fill: ${({theme}) => theme.secondary};
  }
`;

const Actions = styled.div`
  display: inline-flex;
  align-items: center;
`;

const Action = styled.div`
  margin: 0 ${pad}px ${pad / 2}px 0;

  ${bp(2)} {
    margin: ${pad / 2}px 0 ${pad / 2}px ${pad}px;
  }
`;

const Filters = styled.div`
  position: relative;
`;

const NavButton = styled.button`
  width: fit-content;
  min-width: 20px;
`;

const Changes = styled.button`
  ${voice.quiet};
  padding: ${pad}px;
  position: absolute;
  top: 0;
  bottom: 0;
  animation: ${shake()} 5s linear 2s infinite;

  &:hover {
    text-decoration: underline;
    animation-play-state: paused;
  }
`;

export default Menu;

import React, {useEffect} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {useFieldArray, useFormContext} from "react-hook-form";
import {Droppable, Draggable, DragDropContext} from "react-beautiful-dnd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowsAlt, faFileExport, faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

// Components
import {InputText} from "../../components/form/FormInputs.js";

// Style
import {pad} from "../../style/components/variables.js";
import {FormFieldWrapper, Button, Abbr, Label} from "../../style/components/general.js";

const ElementHelp = ({name, label, placeholder, triggerAppend, testId}) => {
  const {control, watch, setValue} = useFormContext();

  const {fields, append, remove, insert} = useFieldArray({control, name});

  // re-ordering list
  const onDragEnd = result => {
    const {destination, source} = result;
    if (!destination || source.index === destination.index) {
      return;
    }
    const movingField = watch(`${name}.${source.index}`);
    let sourceOffset = 0;
    let destinationOffset = 1;
    if (destination.index < source.index) {
      sourceOffset = 1;
      destinationOffset = 0;
    }
    insert(destination.index + destinationOffset, {...movingField});
    remove(source.index + sourceOffset);
  };

  useEffect(() => {
    if (triggerAppend) append(triggerAppend);
  }, [triggerAppend, append]);

  return (
    <HelpWrapper standard>
      {label && (
        <Label bold htmlFor={name}>
          {label}
        </Label>
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={name}>
          {outerProvided => (
            <div {...outerProvided.droppableProps} ref={outerProvided.innerRef}>
              {fields &&
                fields.length > 0 &&
                fields.map((option, index) => {
                  const exportEnabled = watch(`${name}.${index}.export`);
                  return (
                    <Draggable
                      draggableId={`${name}.${index}`}
                      index={index}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${name}.${index}`}
                      isDragDisabled={fields.length <= 1}>
                      {innerProvided => (
                        <div {...innerProvided.draggableProps} ref={innerProvided.innerRef}>
                          <InlineFormField fieldsLength={fields.length}>
                            {fields.length > 1 && (
                              <div {...innerProvided.dragHandleProps}>
                                <StyledDragHandle type="button">
                                  <FontAwesomeIcon style={{fontSize: "20px"}} icon={faArrowsAlt} />
                                </StyledDragHandle>
                              </div>
                            )}
                            <KeyWrapper>
                              <InputText
                                name={`${name}.${index}.key`}
                                placeholder={placeholder || "Key"}
                                defaultValue={option.key}
                                testId={`${testId}-key`}
                              />
                            </KeyWrapper>

                            <InputText
                              name={`${name}.${index}.value`}
                              placeholder="Value"
                              defaultValue={option.value}
                              testId={`${testId}-value`}
                            />

                            <ExportToggle
                              type="button"
                              onClick={() => setValue(`${name}.${index}.export`, !exportEnabled)}
                              exportEnabled={exportEnabled}>
                              <Abbr
                                title={exportEnabled ? "Exclude from export" : "Include in export"}>
                                <FontAwesomeIcon icon={faFileExport} />
                              </Abbr>
                            </ExportToggle>

                            <Update
                              disable={index === 0 && fields.length === 1}
                              type="button"
                              data-testid={`${testId}.removeInput`}
                              onClick={() => remove(index)}>
                              <FontAwesomeIcon icon={faMinus} />
                            </Update>
                            <Update
                              type="button"
                              data-testid={`${testId}.addInput`}
                              onClick={() =>
                                insert(index + 1, {key: "", value: "", export: false})
                              }>
                              <FontAwesomeIcon icon={faPlus} />
                            </Update>
                            {innerProvided.placeholder}
                          </InlineFormField>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
              {outerProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </HelpWrapper>
  );
};

ElementHelp.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  triggerAppend: PropTypes.objectOf(PropTypes.any)
};

ElementHelp.defaultProps = {
  testId: "input-text-group-draggable",
  label: null,
  placeholder: null,
  triggerAppend: null
};

// Style Overrides
const HelpWrapper = styled(FormFieldWrapper)`
  margin-bottom: 0;
  margin-top: ${pad * 2}px;
`;

const InlineFormField = styled(FormFieldWrapper)`
  flex-direction: row;
  margin-bottom: ${pad}px;
  margin-top: ${pad / 2}px;
  align-items: space-between;

  div {
    margin: 0;

    ${({fieldsLength}) =>
      fieldsLength > 1
        ? css`
            &:nth-child(2) {
              margin-left: ${pad}px;
            }

            &:nth-child(3) {
              margin-left: ${pad}px;
            }
          `
        : css`
            &:nth-child(2) {
              margin-left: ${pad}px;
            }
          `}
  }
`;

const Update = styled(Button)`
  margin-left: ${pad}px;

  ${({disable}) =>
    disable &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`;

const StyledDragHandle = styled(Button)`
  padding: 7px ${pad}px ${pad / 2}px ${pad}px;
  margin-left: 0;
  pointer-events: none;
`;

const ExportToggle = styled(Button)`
  margin-left: ${pad}px;

  ${({exportEnabled}) =>
    !exportEnabled &&
    css`
      opacity: 0.5;
      pointer-events: disabled;
    `};
`;

const KeyWrapper = styled.div`
  width: 40%;
  max-width: 200px;
`;

export default ElementHelp;

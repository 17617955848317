import React from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Components
import InputError from "./InputError.js";

// Style
import {flex} from "../../style/components/mixins.js";
import {border, pad} from "../../style/components/variables.js";
import {FormFieldWrapper, Text} from "../../style/components/general.js";

const InputRadio = ({
  testId,
  defaultChecked,
  name,
  label,
  value,
  disabled,
  icon,
  color,
  innerRef,
  showError
}) => {
  const {
    register,
    formState: {errors}
  } = useFormContext();

  return (
    <RadioFormFieldWrapper data-testid={testId} standard>
      {name && (
        <RadioLabel disabled={disabled}>
          <RadioWrapper>
            <RadioInput
              id={name}
              data-testid={`${testId}-input`}
              ref={innerRef}
              type="radio"
              name={name}
              defaultChecked={defaultChecked}
              disabled={disabled}
              value={value ?? label}
              {...register(name)}
            />
            <RadioSelected />
          </RadioWrapper>
          {label && <Text bold>{label.toUpperCase()}</Text>}
          {icon && (
            <>
              &nbsp;&nbsp;
              <IconWrapper color={color}>
                <FontAwesomeIcon icon={icon} />
              </IconWrapper>
              &nbsp;&nbsp;
            </>
          )}
        </RadioLabel>
      )}
      {showError && <InputError errors={errors} name={name} />}
    </RadioFormFieldWrapper>
  );
};

InputRadio.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  showError: PropTypes.bool,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.shape({current: PropTypes.instanceOf(Element)})
  ]),
  defaultChecked: PropTypes.bool,
  icon: PropTypes.string,
  color: PropTypes.string
};

InputRadio.defaultProps = {
  testId: "input-radio",
  label: null,
  value: null,
  disabled: false,
  showError: false,
  innerRef: null,
  defaultChecked: false,
  icon: null,
  color: null
};

// Style Overrides
const RadioFormFieldWrapper = styled(FormFieldWrapper)`
  width: max-content;
  margin-right: 5px;
`;

const RadioLabel = styled.label`
  display: flex;
  align-items: center;
  margin-right: ${pad}px;

  &:hover {
    cursor: pointer;
  }

  ${props =>
    props.disabled &&
    css`
      &:hover {
        cursor: not-allowed;
      }
    `}
`;

const RadioWrapper = styled.div`
  position: relative;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${pad}px;
  border: ${border} solid ${props => props.theme.primary};
  border-radius: 50%;
`;

const RadioSelected = styled.span`
  position: relative;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: inherit;
  transition: all ease 0.1s;

  ${flex("row", "nowrap", "center", "center")};

  &:after {
    content: "";
    width: 75%;
    height: 75%;
    background: ${props => props.theme.primary};
    border-radius: 50%;

    transition: all ease 0.1s;
    opacity: 0;
  }
`;

const RadioInput = styled.input`
  display: none;

  &:checked + span:after {
    opacity: 1;
  }
`;

const IconWrapper = styled.span`
  ${props =>
    props.color &&
    css`
      svg {
        fill: ${props.color};
        font-size: 22px;

        path {
          stroke: #000000;
          stroke-width: 10;
        }
      }
    `}
`;

export default InputRadio;

import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

// Utils
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Components
import Modal from "../../components/Modal.js";
import {InputSelect, InputText} from "../../components/form/FormInputs.js";

// Style
import {breakpoint} from "../../style/components/breakpoints.js";
import {
  Button,
  ButtonLoader,
  Form,
  FormField,
  FormGroup,
  HeadingCenter
} from "../../style/components/general.js";

const defaultValues = {
  userType: null,
  name: null
};

const schema = yup.object().shape({
  userType: yup.number().typeError("Please provide Type.").required("Please provide Type."),
  name: yup.string().required("Please provide name.")
});

const ModalRoleAdd = ({visible, setVisible, update}) => {
  const isMounted = useMountedState();

  const {api, loading} = useApi("user-roles");
  const {data: types, api: apiTypes} = useApi("user-types");

  const form = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema)
  });
  const {handleSubmit} = form;

  // Load user types
  useEffect(() => {
    if (isMounted() && visible) apiTypes.callGet(null, {orderBy: "desc"});
  }, [isMounted, visible, apiTypes]);

  const addRole = ({name, userType}) =>
    api.callPost({label: name, userType}).then(({status}) => {
      if (status === 201) {
        update();
        setVisible(false);
      }
    });

  return (
    <Modal visible={visible} setVisible={setVisible} width={breakpoint.width[1]}>
      <HeadingCenter>Add Role</HeadingCenter>
      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(addRole)} noValidate>
          <FormGroup>
            <FormField>
              <InputSelect
                name="userType"
                label="Type"
                placeholder="Select..."
                options={
                  types &&
                  types
                    .filter(({name}) => name !== "super")
                    .map(({id, name}) => ({label: name.toUpperCase(), value: id}))
                }
                required
              />
            </FormField>
            <FormField>
              <InputText name="name" label="Role" placeholder="Name..." required />
            </FormField>
          </FormGroup>
          <Button type="submit" loading={loading ? 1 : 0}>
            {loading && <ButtonLoader />}
            Add
          </Button>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalRoleAdd.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired
};

export default ModalRoleAdd;

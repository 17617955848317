import React, {useCallback, useContext, useEffect, useState, useMemo, useRef} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {NavLink} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCalendarDay,
  faFileExport,
  faFilter,
  faKeyboard,
  faSearch
} from "@fortawesome/free-solid-svg-icons";

// Utils
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";
import usePrevious from "../../hooks/usePrevious.js";
import {AuthContext} from "../../contexts/auth.js";
import {SettingsContext} from "../../contexts/settings.js";
import {prettyDateInUserTimezone} from "../../utils/helpers.js";

// Components
import ToggleSwitch from "../../components/ToggleSwitch.js";
import BaseTable from "../../components/BaseTable.js";
import Dropdown from "../../components/Dropdown.js";
import Pagination from "../../components/Pagination.js";
import ModalRowPreview from "../general/ModalRowPreview.js";
import ModalExport from "../../components/ModalExport.js";
import Badge from "../../components/Badge.js";
import MultiSelect from "../../components/MultiSelect.js";
import ModalViewAllRows from "../general/ModalViewAllRows.js";

// Styles
import {bp} from "../../style/components/breakpoints.js";
import {z} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {border, colors, pad, radius, shadow} from "../../style/components/variables.js";
import {
  Inline,
  TableWrapper,
  TableMenu,
  TableFooter,
  Search,
  SearchWrapper,
  SearchIcon,
  Button,
  Text
} from "../../style/components/general.js";

const GlobalTable = ({setHasNoTables}) => {
  const isMounted = useMountedState();

  const {atLeast} = useContext(AuthContext);

  const {settings} = useContext(SettingsContext);
  const {timezone} = settings;

  // Custom Table
  const [tableData, setTableData] = useState(null);
  // Base Table
  const [items, setItems] = useState([]);
  const [searchType, setSearchType] = useState("text");
  const [targetRow, setTargetRow] = useState(null);
  const [showModalPreview, setShowModalPreview] = useState(null);
  const [showModalExport, setShowModalExport] = useState(false);
  const [query, setQuery] = useState(null);
  const [viewAllVisible, setViewAllVisible] = useState(false);
  // Filters
  const [showFilters, setShowFilters] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);
  const [filters, setFilters] = useState([]);
  const [activeFilters, setActiveFilters] = useState([]);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageTotal, setPageTotal] = useState(1);
  const [orderBy, setOrderBy] = useState("desc");
  const [groupBy, setGroupBy] = useState("createdAt");
  const [limits, setLimits] = useState([]);
  const [limit, setLimit] = useState(10);

  // Previous Hooks
  const prevOrderBy = usePrevious(orderBy);
  const prevGroupBy = usePrevious(groupBy);
  const prevLimit = usePrevious(limit);

  const {api, loading} = useApi("custom-table-rows");
  const {api: apiTags, data: tags} = useApi("tags");

  useEffect(() => {
    if (setHasNoTables && tableData) setHasNoTables(tableData.length === 0);
  }, [setHasNoTables, tableData]);

  const headings = useMemo(
    () => ({
      preview: {header: "Preview", disabled: true},
      facilityName: {header: "Facility", disabled: false},
      name: {header: "Label", disabled: false},
      createdBy: {header: "Created By", disabled: false},
      createdAt: {header: "Created At", disabled: false}
    }),
    []
  );

  const formatTableData = useCallback(
    payload =>
      payload.map(item => {
        const newItem = {};
        Object.keys(headings).forEach(heading => {
          if (heading === "preview")
            newItem[heading] = (
              <Button
                onClick={() => {
                  setTargetRow(item);
                  setShowModalPreview(true);
                }}>
                View
              </Button>
            );
          else if (heading === "facilityName")
            newItem[heading] = (
              <NavLink to={`/facilities/${item.facilitySlug}/tables`}>
                <abbr title="View in Facility">{item.facilityName}</abbr>
              </NavLink>
            );
          else if (heading === "createdAt" || heading === "updatedAt")
            newItem[heading] = prettyDateInUserTimezone(item[heading], timezone);
          else if (heading === "date")
            newItem[heading] = prettyDateInUserTimezone(item[heading], timezone, "MMM D YYYY");
          else newItem[heading] = item[heading];
          newItem.message = item.message;
          newItem.files = item.files;
          newItem.id = item.id;
          newItem.tags = item.tags;
        });
        return newItem;
      }),
    [headings, timezone]
  );

  const getTableData = useCallback(
    p => {
      setItems([]);
      setTargetRow(null);

      const payload = {
        page: p || 1,
        orderBy: orderBy,
        groupBy,
        limit
      };

      const filterObj = {};
      const stored = localStorage.getItem("globalTableFilters");
      const parsed = JSON.parse(stored);
      if (parsed && parsed.length > 0) filterObj.tags = parsed;
      if (showDeleted) filterObj.showDeleted = true;
      if (query) filterObj.Search = query;
      if (filterObj) payload.filter = JSON.stringify(filterObj);

      api.callGet(null, payload).then(response => {
        const {status, data} = response;
        if (status === 200 && data) {
          const formatted = formatTableData(data.notes);

          // Page Limit
          const {total: dataTotal} = data;
          let count = 10;
          const temp = [];
          while (count < total + 10 && count <= 30) {
            temp.push(count);
            count += 10;
          }

          setTableData(formatted);
          setTotal(dataTotal);
          setCurrentPage(data.page);
          setPageTotal(data.pages);
          setLimits(temp);
        }
      });
    },
    [api, orderBy, groupBy, limit, total, showDeleted, query, formatTableData]
  );

  // Initial Load
  useEffect(() => {
    if (isMounted() && tableData === null) {
      apiTags.callGet(null, {noteTags: true});
      getTableData();
    }
  }, [isMounted, tableData, apiTags, getTableData]);

  // Update rows on sort or limit
  useEffect(() => {
    if (
      isMounted() &&
      prevOrderBy &&
      prevGroupBy &&
      prevLimit &&
      (prevOrderBy !== orderBy || prevGroupBy !== groupBy || prevLimit !== limit)
    )
      getTableData();
  }, [isMounted, getTableData, prevOrderBy, prevGroupBy, prevLimit, orderBy, groupBy, limit]);

  // Search
  useEffect(() => {
    if (isMounted() && (query !== null || query === "")) getTableData();
  }, [isMounted, query, getTableData]);

  const handleSearch = e => {
    setCurrentPage(1);
    setQuery(e.target.value);
  };

  // Filters
  const filterMenu = useRef(null);

  useEffect(() => {
    if (isMounted() && filters !== null) {
      const stored = localStorage.getItem("globalTableFilters");
      if (stored && stored.length > filters) {
        const parsed = JSON.parse(stored);
        setFilters(parsed);
        setActiveFilters(parsed);
      }
    }
  }, [isMounted, filters]);

  useEffect(() => {
    const handleClickOutsideFilterMenu = e =>
      filterMenu &&
      filterMenu.current !== null &&
      !filterMenu.current.contains(e.target) &&
      !filterMenu.current.contains(e.target.nextSibling) &&
      setShowFilters(false);

    if (filterMenu) document.addEventListener("mousedown", handleClickOutsideFilterMenu);

    // Cleanup
    return () => document.removeEventListener("mousedown", handleClickOutsideFilterMenu);
  }, [filterMenu]);

  const handleApply = () => {
    setShowFilters(false);
    localStorage.setItem("globalTableFilters", JSON.stringify(filters));
    setActiveFilters(filters);
    // trigger reload
    setTableData(null);
  };

  const handleClear = () => {
    setFilters([]);
    setShowFilters(false);
    setShowDeleted(false);
    localStorage.removeItem("globalTableFilters");
    setActiveFilters(null);
    // trigger reload
    setTableData(null);
  };

  return (
    <TableWrapper>
      <TableMenuRow>
        <HeaderMenu>
          <MenuItems>
            <SearchWrapper>
              {searchType === "text" && (
                <SearchIcon>
                  <FontAwesomeIcon icon={faSearch} />
                </SearchIcon>
              )}
              <Search
                name="search"
                type={searchType}
                placeholder="Search..."
                onChange={handleSearch}
              />
              <Spaced>
                <ToggleSwitch
                  iconLeft={faKeyboard}
                  iconRight={faCalendarDay}
                  textLeft="Text"
                  textRight="Date"
                  onChange={() => setSearchType(prev => (prev === "text" ? "date" : "text"))}
                  options={["text", "date"]}
                  defaultSelected="text"
                />
              </Spaced>
            </SearchWrapper>
            <TableMenu>
              {atLeast("admin") &&
                ((tableData && tableData.length > 0) ||
                  (activeFilters && activeFilters.length > 0) ||
                  showDeleted) && (
                  <PositionedDiv>
                    <Option
                      type="button"
                      onClick={() => {
                        setShowFilters(!showFilters);
                      }}>
                      <Badge
                        count={(activeFilters ? activeFilters.length : 0) + (showDeleted ? 1 : 0)}
                        offset="14px"
                        color={colors.heroGreen}
                      />
                      <FontAwesomeIcon icon={faFilter} />
                    </Option>
                    {showFilters && (
                      <OptionSub ref={filterMenu}>
                        <StyledSmall>Filter(s)</StyledSmall>
                        <MultiSelect
                          options={tags.map(tag => tag.label.toUpperCase())}
                          defaultSelection={filters}
                          setSelection={setFilters}
                        />
                        <Inline>
                          <ApplyFilter type="button" onClick={handleApply}>
                            Apply
                          </ApplyFilter>
                          <ApplyFilter type="button" onClick={handleClear}>
                            Clear
                          </ApplyFilter>
                        </Inline>
                      </OptionSub>
                    )}
                  </PositionedDiv>
                )}
              {atLeast("admin") && tableData && tableData.length > 0 && (
                <Option type="button" onClick={() => setShowModalExport(true)}>
                  <abbr title="Export">
                    <FontAwesomeIcon icon={faFileExport} />
                  </abbr>
                </Option>
              )}
            </TableMenu>
          </MenuItems>
        </HeaderMenu>
      </TableMenuRow>
      <BaseTable
        headings={headings}
        data={tableData}
        loading={loading}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        groupBy={groupBy}
        setGroupBy={setGroupBy}
        items={items}
        setItems={setItems}
      />
      <TableFooter>
        {total > 0 && (
          <>
            <Inline>
              {total > 10 && limits.length > 0 && (
                <PageLimit>
                  <Dropdown
                    options={limits}
                    selection={limit}
                    setSelection={selection => {
                      setLimit(selection);
                      setCurrentPage(1);
                    }}
                  />
                </PageLimit>
              )}
              <Text quiet>
                {limit <= 30 && pageTotal > 1 && limits.length > 0 && `${limit} per page, `}
                <ModalViewLink
                  disabled={!total || total <= 10}
                  onClick={!total || total <= 10 ? undefined : () => setViewAllVisible(true)}>
                  {total} total
                </ModalViewLink>
              </Text>
            </Inline>
            {limit <= 30 && pageTotal > 1 && (
              <Pagination
                current={currentPage}
                setCurrent={setCurrentPage}
                pageTotal={pageTotal}
                updateData={() => getTableData(currentPage)}
                loading={loading}
              />
            )}
          </>
        )}
      </TableFooter>

      {showModalPreview && targetRow && (
        <ModalRowPreview
          visible={showModalPreview}
          setVisible={setShowModalPreview}
          selected={targetRow}
          setSelected={setTargetRow}
        />
      )}

      {showModalExport && (
        <ModalExport
          visible={showModalExport}
          setVisible={setShowModalExport}
          getExport={params => {
            const filterObj = {};
            const stored = localStorage.getItem("globalTableFilters");
            const parsed = JSON.parse(stored);
            if (parsed && parsed.length > 0) filterObj.tags = parsed;
            if (showDeleted) filterObj.showDeleted = true;
            if (filterObj) params.filter = JSON.stringify(filterObj);
            return api.callGet(null, params);
          }}
        />
      )}

      {viewAllVisible && (
        <ModalViewAllRows visible={viewAllVisible} setVisible={setViewAllVisible} inGlobalTables />
      )}
    </TableWrapper>
  );
};

GlobalTable.propTypes = {
  setHasNoTables: PropTypes.func
};

GlobalTable.defaultProps = {
  setHasNoTables: null
};

// Style Overrides
const TableMenuRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${pad}px;
`;

const HeaderMenu = styled(Inline)`
  gap: ${pad}px;
  display: block;
  width: 100%;

  ${bp(2)} {
    display: flex;
  }
`;

const MenuItems = styled(Inline)`
  justify-content: space-between;
  width: 100%;
  opacity: ${props => props.active};
`;

const Option = styled(Button)`
  margin-left: ${pad}px;
`;

const Spaced = styled.div`
  margin-left: ${pad}px;
`;

const PageLimit = styled.div`
  margin-right: ${pad}px;

  select {
    padding: ${pad / 2}px;
  }
`;

const OptionSub = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  width: 205px;
  border: ${border} solid ${props => props.theme.primary};
  border-radius: ${radius};
  padding: ${pad}px;
  background: ${props => props.theme.tertiary};
  margin-top: ${pad}px;
  z-index: ${z("top")};
  box-shadow: ${shadow};

  color: ${props => props.theme.secondary};

  svg {
    fill: ${props => props.theme.secondary};
  }
`;

const ApplyFilter = styled(Button)`
  ${voice.quiet};
  width: fit-content;
  height: min-content;
  white-space: nowrap;
  padding: ${pad / 2}px;
  margin: 0 ${pad / 2}px 0 0;

  svg {
    fill: ${props => props.theme.tertiary};
    align-self: center;
  }
`;

const StyledSmall = styled.div`
  ${voice.quiet};
  font-weight: normal;
  color: ${props => props.theme.primary};
`;

const PositionedDiv = styled.div`
  position: relative;
`;

const ModalViewLink = styled.span`
  ${({disabled}) =>
    !disabled &&
    css`
      text-decoration: underline;
      cursor: pointer;
    `}
`;

export default GlobalTable;

import React, {useContext, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";

// Contexts
import {SettingsContext} from "../contexts/settings.js";

// Utils
import {startExport} from "../utils/export.js";
import {prettyDateInUserTimezone} from "../utils/helpers.js";

// Components
import Modal from "./Modal.js";
import InputText from "./form/InputText.js";
import InputCheck from "./form/InputCheck.js";
import InputDate from "./form/InputDate.js";
import ModalExportPreview from "./ModalExportPreview.js";

// Style
import {
  HeadingCenter,
  Button,
  Form,
  FormGroup,
  FormField,
  Label,
  Inline,
  ButtonLoader
} from "../style/components/general.js";

const ModalExport = ({visible, setVisible, getExport, hasBackButton, goBack}) => {
  const [loading, setLoading] = useState(false);
  const [previewLoading, setPreviewLoading] = useState(false);
  const [showModalPreview, setShowModalPreview] = useState(false);
  const [tableHeadings, setTableHeadings] = useState(null);
  const [tableData, setTableData] = useState(null);

  const {settings} = useContext(SettingsContext);

  const initialValues = {
    exportName: "",
    range: false,
    start: "",
    end: dayjs().format("YYYY-MM-DD")
  };

  const schema = yup.object().shape({
    exportName: yup.string().required("Name is required"),
    range: yup.boolean(),
    start: yup
      .string()
      .nullable()
      .when("range", {
        is: val => !!val,
        then: () =>
          yup
            .string()
            .test({
              test: (val, ctx) => {
                const {end} = ctx.parent;
                return !!val || !!end;
              },
              message: "Start or end date required"
            })
            .test({
              test: (val, ctx) => {
                const {end} = ctx.parent;
                if (val && end) {
                  return !dayjs(val).isAfter(dayjs(end));
                }
                return true;
              },
              message: "Start date must be before end date"
            })
      }),
    end: yup
      .string()
      .nullable()
      .when("range", {
        is: val => !!val,
        then: () => yup.string()
      })
  });

  const form = useForm({defaultValues: initialValues, resolver: yupResolver(schema)});
  const {watch, handleSubmit, reset} = form;
  const watchExportName = watch("exportName");
  const watchRange = watch("range");

  const generateExportData = async (values, stripQuotes = false) => {
    const {start, end} = values;
    const {status, data} = await getExport({
      exportName: watchExportName,
      start: watchRange && start ? start : new Date(0),
      end: watchRange && end ? end : new Date()
    });

    let rows = [];
    if (status === 200 && data?.rows?.length > 0)
      data.rows.map(row => {
        const temp = {};
        row.map(({key, value}) => {
          const stripped =
            stripQuotes && value?.match && value.match(/^".*"$/)
              ? value.slice(1, value.length - 1)
              : value;
          let formattedValue = stripped;
          // Format dates
          if (
            formattedValue?.match(
              /\d{4}[-](0?[1-9]|1[012])[-](0?[1-9]|[12][0-9]|3[01])T([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9](.\d{6})?/
            )
          )
            formattedValue = prettyDateInUserTimezone(
              formattedValue,
              settings?.timezone,
              "YYYY-MM-DD hh:mm A"
            );
          temp[key] = formattedValue;
        });
        rows.push(temp);
      });
    else rows = data;

    return rows;
  };

  const handlePreview = async () => {
    setPreviewLoading(true);
    const values = watch();
    const data = await generateExportData(values, true);
    const headers = {};
    if (data?.length)
      Object.keys(data[0]).map(key => {
        headers[key] = {header: key, disabled: true};
      });

    setTableHeadings(headers);
    setTableData(data);
    setShowModalPreview(true);
    setPreviewLoading(false);
  };

  const createExport = async values => {
    setLoading(true);
    const rows = await generateExportData(values);
    startExport("csv", watchExportName, rows);
    reset({...initialValues});
    setLoading(false);
    setVisible(false);
  };

  if (showModalPreview)
    return (
      <ModalExportPreview
        visible={visible}
        setVisible={setVisible}
        goBack={() => setShowModalPreview(false)}
        exportName={watchExportName}
        tableHeadings={tableHeadings}
        tableData={tableData}
        handleExport={() => {
          startExport("csv", watchExportName, tableData);
          setVisible(false);
          reset({...initialValues});
        }}
      />
    );

  return (
    <Modal visible={visible} setVisible={setVisible} hasBackButton={hasBackButton} goBack={goBack}>
      <HeadingCenter>Export CSV</HeadingCenter>
      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(createExport)} noValidate>
          <FormGroup>
            <FormField>
              <InputText name="exportName" label="Please provide a name for the export." required />
            </FormField>
            <FormField>
              <InputCheck name="range">Specify a date range?</InputCheck>
            </FormField>
            {watchRange && (
              <FormField>
                <Label bold>Please provide a range for data in export.</Label>
                <DateRange>
                  from&nbsp;
                  <InputDate name="start" />
                  &nbsp;to&nbsp;
                  <InputDate name="end" />
                </DateRange>
              </FormField>
            )}
          </FormGroup>
          <Inline>
            <Button type="submit" loading={loading ? 1 : 0}>
              Export {loading && <ButtonLoader />}
            </Button>
            <Button type="button" onClick={handlePreview} loading={previewLoading ? 1 : 0}>
              Preview {previewLoading && <ButtonLoader />}
            </Button>
          </Inline>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalExport.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  getExport: PropTypes.func.isRequired,
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func
};

ModalExport.defaultProps = {
  hasBackButton: false,
  goBack: null
};

// Style Overrides
const DateRange = styled(Inline)`
  display: flex;
  color: ${({theme}) => theme.secondary};

  div {
    max-width: 150px;
  }
`;

export default ModalExport;

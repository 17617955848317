import React from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {useFieldArray, useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

// Components
import InputSelect from "./InputSelect.js";

// Style
import {pad} from "../../style/components/variables.js";
import {Button, FormFieldWrapper, Label} from "../../style/components/general.js";

const InputSelectGroup = ({testId, name, options, label, placeholder, required}) => {
  const {control, watch} = useFormContext();

  const {fields, remove, insert} = useFieldArray({control, name});

  const filter = (opts, index) => {
    const vals = watch(name)
      .filter((_field, i) => i !== index)
      .map(({value}) => value);
    return opts && opts.filter(({value}) => !vals.includes(value));
  };

  return (
    <FormFieldWrapper standard>
      {label && (
        <Label htmlFor={name} bold>
          {label.toUpperCase()}
          {required && <span>&nbsp;*</span>}
        </Label>
      )}
      {fields?.map((field, index) => (
        <StyledInlineFormField key={field.id}>
          <SelectWrapper>
            <InputSelect
              testId={testId}
              name={`${name}.${index}.value`}
              placeholder={placeholder || "Select..."}
              options={filter(options, index)}
            />
          </SelectWrapper>

          <StyledEdit
            type="button"
            data-testid={`${testId}.removeInput`}
            disable={fields.length === 1}
            onClick={() => remove(index)}>
            <FontAwesomeIcon icon={faMinus} />
          </StyledEdit>
          {options && options.length > 0 && fields.length < options.length && (
            <StyledEdit
              type="button"
              data-testid={`${testId}.addInput`}
              onClick={() => {
                insert(index + 1, "");
              }}>
              <FontAwesomeIcon icon={faPlus} />
            </StyledEdit>
          )}
        </StyledInlineFormField>
      ))}
    </FormFieldWrapper>
  );
};

InputSelectGroup.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool
};

InputSelectGroup.defaultProps = {
  testId: "input-select-group",
  label: null,
  placeholder: null,
  required: false
};

// Style Overrides
const StyledInlineFormField = styled(FormFieldWrapper)`
  flex-direction: row;
  margin-bottom: ${pad * 1.4}px;
  align-items: space-between;

  div {
    margin: 0;

    &:nth-child(2) {
      margin-left: ${pad}px;
    }
  }
`;

const StyledEdit = styled(Button)`
  padding: 0 ${pad}px;
  margin-left: ${pad}px;

  ${({disable}) =>
    disable &&
    css`
      opacity: 0;
      pointer-events: none;
    `}
`;

const SelectWrapper = styled.div`
  width: 200px;
`;

export default InputSelectGroup;

import React from "react";
import styled, {css} from "styled-components";
import {Link} from "react-router-dom";

// Style
import {flex, z} from "../../style/components/mixins.js";
import {border, heroTheme, pad} from "../../style/components/variables.js";
import {AnchorInline, Button, Inline, Text, Title} from "../../style/components/general.js";

const FallbackComponent = () => (
  <FallbackPage>
    <Header>
      <Logo src={`${process.env.PUBLIC_URL}/hero-services.png`} alt="Hero Services Logo" />
    </Header>
    <Wrapper>
      <Title>Sorry, something went wrong.</Title>
      <Text>
        Our team has been notified, but please submit a{" "}
        <AnchorInline href="/support" target="_blank" rel="noreferrer">
          Support Ticket
        </AnchorInline>{" "}
        if this issue persists.
      </Text>
      <Inline>
        <LinkButton onClick={() => window.location.reload()}>Reload Current Page</LinkButton>
        <Link to="/" reloadDocument>
          <LinkButton>Go back to Home</LinkButton>
        </Link>
      </Inline>
    </Wrapper>
  </FallbackPage>
);

// Style Overrides
const FallbackPage = styled.section`
  ${flex("column", "nowrap", "start", "center")};
  position: relative;
  width: 100vw;
  height: 100vh;
`;

const Header = styled.nav`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  align-self: start;
  display: flex;
  background: ${heroTheme.secondary};
  z-index: ${z("top")};

  ${props =>
    props.theme.name === "dark" &&
    css`
      border-bottom: ${border} solid ${heroTheme.tertiary};
    `}
`;

const Logo = styled.img`
  height: 75px;
  margin: ${pad}px auto;
`;

const Wrapper = styled.div`
  ${flex("column", "nowrap", "center", "center")};
  margin-top: 20%;
`;

const LinkButton = styled(Button)`
  margin-top: ${pad}px;
`;

export default FallbackComponent;

import React, {useContext, useState, useEffect, useCallback, useRef} from "react";
import {useParams} from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";

// Contexts
import {NotificationContext} from "../../contexts/notify.js";
import {AuthContext} from "../../contexts/auth.js";
import {FacilityNavContext} from "../../contexts/facilitynav.js";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Components
import TableRecord from "../checksheets/TableRecord.js";
import ModalExport from "../checksheets/ModalExport.js";
import ModalArchive from "../checksheets/ModalArchive.js";
import ModalViewAllRecords from "../general/ModalViewAllRecords.js";
import ModalDelete from "../../components/ModalDelete.js";

// Style
import {pad, border, radius} from "../../style/components/variables.js";
import {Text, Loader, NotLoaded} from "../../style/components/general.js";
import {flex} from "../../style/components/mixins.js";

const Checksheets = ({facilityId, canRequestLocks}) => {
  const isMounted = useMountedState();

  const {slug} = useParams();

  const {getNotifications} = useContext(NotificationContext);
  const {roleCanAccessResource, currentUser, atLeast} = useContext(AuthContext);
  const {facility} = useContext(FacilityNavContext);

  const {api, loading} = useApi("checksheets");

  const [checksheets, setChecksheets] = useState(undefined);
  const [currentChecksheet, setCurrentChecksheet] = useState(undefined);
  // Modals
  const [showModalExport, setShowModalExport] = useState(false);
  const [showModalArchive, setShowModalArchive] = useState(false);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const [showModalViewAll, setShowModalViewAll] = useState(false);

  const currentChecksheetUpdater = useRef(undefined);

  const scrollToTable = useCallback(() => {
    const {hash} = window.location;
    if (hash) {
      const tableId = hash.replace("#", "");
      const table = document.getElementById(tableId);
      if (table) {
        const y = table.getBoundingClientRect().top - 100;
        setTimeout(() => window.scrollBy({behavior: "smooth", top: y}), 500);
      }
    }
  }, []);

  const getChecksheets = useCallback(() => {
    api
      .callGet(null, {
        facilityId,
        includeDrafts: roleCanAccessResource("facility_checksheet", "update"),
        orderBy: "asc",
        groupBy: "frequency"
      })
      .then(({status, data}) => {
        if (status === 200) {
          const filtered = data.filter(
            ({restrictTo}) =>
              !restrictTo ||
              atLeast("admin") ||
              restrictTo?.map(({id: roleId}) => roleId)?.includes(currentUser?.role.id)
          );
          setChecksheets(filtered);
          scrollToTable();
        }
      });
  }, [api, facilityId, roleCanAccessResource, scrollToTable, atLeast, currentUser?.role.id]);

  useEffect(() => {
    if (isMounted() && !checksheets) getChecksheets();
  }, [isMounted, api, checksheets, getChecksheets]);

  const publishDraft = id => api.callPut(id, {draft: false}).then(() => setChecksheets(undefined));

  const openExport = checksheet => {
    setCurrentChecksheet(checksheet);
    setShowModalExport(true);
  };

  const openArchive = checksheet => {
    setCurrentChecksheet(checksheet);
    setShowModalArchive(true);
  };

  const openDelete = checksheet => {
    setCurrentChecksheet(checksheet);
    setShowModalDelete(true);
  };

  const archiveChecksheet = () =>
    api.callPut(currentChecksheet.id, {archive: true}).then(() => {
      getChecksheets();
      setShowModalArchive(false);
      getNotifications(slug);
    });

  const deleteChecksheet = () =>
    api.callDelete(currentChecksheet.id).then(() => {
      getChecksheets();
      setShowModalDelete(false);
      getNotifications(slug);
    });

  const restoreChecksheet = checksheet =>
    api.callPut(checksheet.id, {archive: false}).then(() => {
      getChecksheets();
      getNotifications(slug);
    });

  const handleViewAll = (checksheet, update) => {
    currentChecksheetUpdater.current = update;
    setCurrentChecksheet(checksheet);
    setShowModalViewAll(true);
  };

  return (
    <>
      {loading ? (
        <NotLoaded>
          <Loader data-testid="checksheets.loader" />
        </NotLoaded>
      ) : (
        <OutermostWrapper>
          {checksheets?.length > 0 ? (
            checksheets.map((checksheet, index) => (
              <div id={`c${checksheet.id}`} key={checksheet.id}>
                <TableRecord
                  index={index + 1}
                  checksheet={checksheet}
                  publishDraft={publishDraft}
                  currentChecksheet={setCurrentChecksheet}
                  showExport={openExport}
                  archiveChecksheet={openArchive}
                  showDelete={openDelete}
                  restoreChecksheet={restoreChecksheet}
                  canRequestLocks={canRequestLocks}
                  handleViewAll={handleViewAll}
                />
              </div>
            ))
          ) : (
            <NotFound>
              <Text>No Checksheets available.</Text>
            </NotFound>
          )}
        </OutermostWrapper>
      )}

      {showModalArchive && (
        <ModalArchive
          visible={showModalArchive}
          setVisible={setShowModalArchive}
          archive={archiveChecksheet}
          loading={loading}
        />
      )}

      {showModalDelete && (
        <ModalDelete
          visible={showModalDelete}
          setVisible={setShowModalDelete}
          confirmDelete={deleteChecksheet}
          title="Delete Checksheet"
          loading={loading}
        />
      )}

      {showModalExport && (
        <ModalExport
          visible={showModalExport}
          setVisible={setShowModalExport}
          checksheet={currentChecksheet}
          hasAddress={!!facility?.primaryAddress}
        />
      )}

      {showModalViewAll && (
        <ModalViewAllRecords
          visible={showModalViewAll}
          setVisible={state => {
            if (!state) {
              currentChecksheetUpdater.current = undefined;
              setCurrentChecksheet(undefined);
            }
            setShowModalViewAll(state);
          }}
          target={currentChecksheet}
          reload={currentChecksheetUpdater.current}
        />
      )}
    </>
  );
};

Checksheets.propTypes = {
  facilityId: PropTypes.number.isRequired,
  canRequestLocks: PropTypes.bool.isRequired
};

// Style Overrides
const OutermostWrapper = styled.div`
  ${flex("column", "nowrap")}

  margin-bottom: ${pad * 2}px;
  gap: ${pad * 0.7}px;
`;

const NotFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 85%;
  min-height: 75px;
  padding: ${pad}px;
  margin-right: ${pad}px;
  border-radius: ${radius};
  border: ${border} solid ${props => props.theme.secondary};
  width: 100%;
`;

export default Checksheets;

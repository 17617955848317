import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faClipboardCheck,
  faCog,
  faExclamation,
  faStar as faStarFilled
} from "@fortawesome/free-solid-svg-icons";
import {faStar} from "@fortawesome/free-regular-svg-icons";

// Contexts
import {AuthContext} from "../../contexts/auth.js";
import {SettingsContext} from "../../contexts/settings.js";
import {useSocket} from "../../contexts/socket.js";
import {NavContext} from "../../contexts/nav.js";

// Hooks
import useMountedState from "../../hooks/useMountedState.js";

// Utils
import {getWithExpiry, prettyDateInUserTimezone, slugify} from "../../utils/helpers.js";
// import {getIcon} from "../schedule/helpers.js";

// Components
import Badge from "../../components/Badge.js";

// Style
import {flex, z} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {pulse, slideLeft} from "../../style/components/animations.js";
import {pad, border, transition, colors, shadow} from "../../style/components/variables.js";
import {Text, Small, Inline, Button, Heading, Abbr} from "../../style/components/general.js";

// Socket Constants
import {STATE_ON, STATE_OFF} from "../general/Room.js";

const FacilityCard = ({
  facility,
  setTarget,
  setShowModalSettings,
  setShowModalFavorite,
  showArchived,
  slugs,
  favorites,
  initialStates
}) => {
  const {
    slug,
    name,
    type,
    types,
    isDeleted,
    updatedBy,
    updatedAt,
    checksheetTotal,
    checksheetsDue,
    checksheetDrafts,
    checksheetsOverdue,
    hasNotifications
    // eventAlerts
  } = facility;

  const isMounted = useMountedState();

  const {settings} = useContext(SettingsContext);
  const {states} = useContext(NavContext);
  const {roleCanAccessPage, roleCanAccessResource, getRolesDefaultPageFrom} =
    useContext(AuthContext);

  const socket = useSocket();

  const [activeStates, setActiveStates] = useState([]);

  const facilityStateOn = useCallback(
    payload => {
      if (payload.facility && payload.facility === slug && !activeStates.includes(payload.state))
        setActiveStates(prev => [...prev, payload.state]);
    },
    [slug, activeStates]
  );

  const facilityStateOff = useCallback(
    payload => {
      if (payload.facility && payload.facility === slug)
        setActiveStates(prev => prev.filter(state => state !== payload.state));
    },
    [slug]
  );

  // Socket Management
  useEffect(() => {
    socket.on(STATE_ON, facilityStateOn);
    socket.on(STATE_OFF, facilityStateOff);

    return () => {
      // unbind all event handlers used in this component
      socket.off(STATE_ON, facilityStateOn);
      socket.off(STATE_OFF, facilityStateOff);
    };
  }, [socket, facilityStateOn, facilityStateOff]);

  useEffect(() => {
    if (isMounted() && initialStates) {
      setActiveStates(initialStates);
    }
  }, [isMounted, initialStates]);

  const typeString = useMemo(() => {
    if (showArchived) return type;
    return (
      types
        ?.map((t, i) => {
          if (i === 0) return t.name.toUpperCase();
          return ` | ${t.name.toUpperCase()}`;
        })
        ?.join("") || ""
    );
  }, [showArchived, types, type]);

  const savedSlug = useMemo(() => {
    if (showArchived) return null;
    const saved = getWithExpiry(`${slugify(facility.name)}.link`);
    if (!slugs?.includes(saved)) return null;
    return saved;
  }, [facility, slugs, showArchived]);

  const link = useMemo(
    () =>
      roleCanAccessPage("facility_dashboard")
        ? `/facilities/${savedSlug || slug}`
        : getRolesDefaultPageFrom("facility_dashboard", savedSlug || slug),
    [savedSlug, slug, getRolesDefaultPageFrom, roleCanAccessPage]
  );

  const heading = useRef();

  return (
    <Card showMarquee={heading.current?.offsetWidth < heading.current?.scrollWidth}>
      <CardLink to={link} deleted={isDeleted ? 1 : 0} />

      <BannerWrapper>
        {states &&
          activeStates &&
          activeStates
            ?.filter(state => states[state])
            ?.map(state => (
              <Banner key={state} color={`#${states[state].color}`}>
                ON {state}
              </Banner>
            ))}
      </BannerWrapper>

      {heading.current?.offsetWidth < heading.current?.scrollWidth && (
        <Marquee>
          <Heading>{name}</Heading>&nbsp;
          <Heading>{name}</Heading>
        </Marquee>
      )}

      <TitleWrapper>
        <FacilityName ref={heading}>
          <FacilityAbbr title={name}>{name}</FacilityAbbr>
        </FacilityName>

        <FacilityType>
          <FacilityAbbr title={typeString}>{typeString}</FacilityAbbr>
        </FacilityType>
      </TitleWrapper>

      {!isDeleted && (
        <IconGrid>
          <GridElem>
            <div>
              {checksheetTotal === 0 && (
                <Badge offset="10px" color={colors.green}>
                  &#x2713;
                </Badge>
              )}
              <FontAwesomeIcon icon={faClipboardCheck} className="primaryIcon" />
            </div>
            <CountWrapper>
              <Count hidden={!checksheetsDue || checksheetsDue === 0}>{checksheetsDue} Due</Count>
              <Count hidden={!checksheetDrafts || checksheetDrafts === 0} warning>
                {checksheetDrafts} Incomplete
              </Count>
              <Count hidden={!checksheetsOverdue || checksheetsOverdue === 0} error>
                {checksheetsOverdue} Overdue
              </Count>
            </CountWrapper>
          </GridElem>
          {/* TODO: add back when schedule is added back */}
          {/* {eventAlerts.map(eventType => {
          const {due, draft, overdue, total, icon} = eventType;
          return (
            <GridElem key={`alert-${icon}`}>
              <div>
                {total === 0 && (
                  <Badge offset="10px" color={colors.green}>
                    &#x2713;
                  </Badge>
                )}
                <FontAwesomeIcon icon={getIcon(icon)} className="primaryIcon" />
              </div>
              <CountWrapper>
                <Count hidden={!due || due === 0}>{due} Due</Count>
                <Count hidden={!draft || draft === 0} warning>
                  {draft} Incomplete
                </Count>
                <Count hidden={!overdue || overdue === 0} error>
                  {overdue} Overdue
                </Count>
              </CountWrapper>
            </GridElem>
          );
        })} */}
        </IconGrid>
      )}
      {isDeleted && updatedBy && (
        <ArchivedBy>
          Archived by {updatedBy.firstName} {updatedBy.lastName}
          {updatedAt
            ? ` on ${prettyDateInUserTimezone(updatedAt, settings.timezone, "MMM D YYYY, h:mm A")}`
            : ""}
        </ArchivedBy>
      )}
      {(roleCanAccessResource("facility", "delete") ||
        roleCanAccessResource("facility", "archive") ||
        roleCanAccessResource("facility", "create")) && (
        <Settings
          type="button"
          onClick={() => {
            setTarget(facility);
            setShowModalSettings(true);
          }}>
          {!hasNotifications && (
            <Badge offsetX="7px" offsetY="8px" color={colors.red} diameter="16px">
              <StyledAbbr title="Notifications not configured">
                <FontAwesomeIcon icon={faExclamation} />
              </StyledAbbr>
            </Badge>
          )}
          <FontAwesomeIcon icon={faCog} className="primaryIcon">
            Settings
          </FontAwesomeIcon>
        </Settings>
      )}

      <Favorite
        type="button"
        onClick={() => {
          setTarget(facility);
          setShowModalFavorite(true);
        }}>
        <FontAwesomeIcon
          icon={favorites?.includes(facility.id) ? faStarFilled : faStar}
          className="primaryIcon">
          Favorite Facility
        </FontAwesomeIcon>
      </Favorite>
    </Card>
  );
};

FacilityCard.propTypes = {
  facility: PropTypes.objectOf(PropTypes.any).isRequired,
  setTarget: PropTypes.func,
  setShowModalSettings: PropTypes.func,
  setShowModalFavorite: PropTypes.func,
  showArchived: PropTypes.bool,
  slugs: PropTypes.arrayOf(PropTypes.string),
  favorites: PropTypes.arrayOf(PropTypes.number),
  initialStates: PropTypes.arrayOf(PropTypes.any)
};

FacilityCard.defaultProps = {
  setTarget: null,
  setShowModalSettings: null,
  setShowModalFavorite: null,
  showArchived: false,
  slugs: null,
  favorites: [],
  initialStates: []
};

// Style Overrides
const Marquee = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: none;
  animation: ${slideLeft} linear 10s infinite;

  ${Heading} {
    color: ${({theme}) => theme.tertiary};
    padding: ${pad}px;
    white-space: nowrap;
  }
`;

const CardLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${z("above")};

  ${({deleted}) =>
    deleted &&
    css`
      background: rgba(0, 0, 0, 0.5);
    `}
`;

const TitleWrapper = styled.div`
  overflow: hidden;
`;

const FacilityName = styled(Heading)`
  color: ${({theme}) => theme.secondary};
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const FacilityAbbr = styled.abbr`
  text-decoration: none;
`;

const FacilityType = styled(Small)`
  color: ${({theme}) => theme.primary};
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
`;

const IconGrid = styled.div`
  ${flex("row", "wrap", "start", "center")};
  margin-top: ${pad * 2}px;
  row-gap: ${pad * 2}px;
`;

const Count = styled(Small)`
  color: ${({theme}) => theme.success};
  color: ${({theme, warning}) => warning && theme.warning};
  color: ${({theme, error}) => error && theme.error};
`;

const CountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${pad}px;
`;

const GridElem = styled(Inline)`
  width: 150px;

  .primaryIcon {
    ${voice.medium};
    font-weight: bold;
    fill: ${({theme}) => theme.secondary};
  }
`;

const Settings = styled(Button)`
  position: absolute;
  bottom: 0;
  right: 0;
  padding: ${pad / 2}px ${pad}px;
  background: transparent;
  z-index: ${z("top")};

  .primaryIcon {
    fill: ${({theme}) => theme.secondary};
  }
`;

const BannerWrapper = styled(Inline)`
  position: absolute;
  top: 4px;
  right: 4px;
  margin-right: ${pad * 2}px;
  gap: ${pad / 2}px;
  z-index: ${z("top")};
`;

const Banner = styled.span`
  position: relative;
  border-radius: 10px;
  font-size: 10px;
  font-weight: bold;
  text-align: left;
  text-transform: uppercase;
  color: ${({theme}) => theme.tertiary};
  padding: ${pad / 4}px ${pad / 2}px;
  background-color: ${({color, theme}) => color || theme.error};
  box-shadow: ${shadow};

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${({color, theme}) => color || theme.error};
    border-radius: 10px;
    z-index: -1;

    animation: ${({color, theme}) => pulse(color || theme.error)} 2s infinite;
  }
`;

const Favorite = styled.button`
  width: inherit;
  display: inherit;
  position: absolute;
  top: 0;
  right: 0;
  padding: ${pad * 1.5}px;
  background: transparent;
  z-index: ${z("top")};

  .primaryIcon {
    ${voice.quiet};
    /* allow a larger click area */
    position: absolute;
    top: 0;
    right: 0;
    padding: ${pad / 2}px;
    fill: ${({theme}) => theme.secondary};
  }
`;

const StyledAbbr = styled(Abbr)`
  padding-left: 0.55px;
`;

const ArchivedBy = styled(Small)`
  color: ${({theme}) => theme.secondary};
  width: calc(100% - 20px);
`;

const Card = styled.div`
  ${flex("column", "nowrap", "space-between")};
  position: relative;
  padding: ${pad}px;
  height: 100%;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: 5px;
  transition: ${transition};
  min-height: 129px;
  overflow: hidden;

  &:hover {
    background-color: ${({theme}) => theme.secondary};

    ${FacilityName} {
      color: ${({theme}) => theme.tertiary};
      opacity: ${({showMarquee}) => (showMarquee ? 0 : 1)};
    }

    ${Marquee} {
      display: inline-flex;
    }

    ${Text} {
      color: ${({theme}) => theme.tertiary};
    }

    ${Small} {
      color: ${({theme}) => theme.tertiary};
    }

    .primaryIcon {
      fill: ${({theme}) => (theme.name === "light" ? theme.primary : theme.tertiary)};
    }
  }
`;

export default FacilityCard;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMap, faTableCellsLarge, faTableList} from "@fortawesome/free-solid-svg-icons";

// Styles
import {voice} from "../../style/components/typography.js";
import {pad, radius} from "../../style/components/variables.js";
import {Abbr, Button} from "../../style/components/general.js";
import {z} from "../../style/components/mixins.js";
import {bp} from "../../style/components/breakpoints.js";

const ToggleMenu = ({view, setView, setFacilities}) => (
  <Menu>
    <Selected>
      {view === "card" && (
        <Abbr title="View Facility Cards">
          <FontAwesomeIcon icon={faTableCellsLarge} />
        </Abbr>
      )}
      {view === "table" && (
        <Abbr title="View Facilities Table">
          <FontAwesomeIcon icon={faTableList} />
        </Abbr>
      )}
      {view === "map" && (
        <Abbr title="View Facility Map">
          <FontAwesomeIcon icon={faMap} />
        </Abbr>
      )}
    </Selected>
    <OptionsWrapperStatic>
      <OptionsWrapper>
        {view !== "card" && (
          <Button
            type="button"
            onClick={() => {
              localStorage.setItem("facilitiesDashboardView", "card");
              setFacilities(null);
              setView("card");
            }}>
            <Abbr title="View Facility Cards">
              <FontAwesomeIcon icon={faTableCellsLarge} />
            </Abbr>
          </Button>
        )}
        {view !== "table" && (
          <Button
            type="button"
            onClick={() => {
              localStorage.setItem("facilitiesDashboardView", "table");
              setFacilities(null);
              setView("table");
            }}>
            <Abbr title="View Facilities Table">
              <FontAwesomeIcon icon={faTableList} />
            </Abbr>
          </Button>
        )}
        {view !== "map" && (
          <Button
            type="button"
            onClick={() => {
              localStorage.setItem("facilitiesDashboardView", "map");
              setFacilities(null);
              setView("map");
            }}>
            <Abbr title="View Facilities Map">
              <FontAwesomeIcon icon={faMap} />
            </Abbr>
          </Button>
        )}
      </OptionsWrapper>

      {/* Buttons to Maintain Height */}
      <StaticButton disabled>
        <FontAwesomeIcon icon={faTableList} />
      </StaticButton>
      <StaticButton disabled>
        <FontAwesomeIcon icon={faMap} />
      </StaticButton>
    </OptionsWrapperStatic>
  </Menu>
);

ToggleMenu.propTypes = {
  view: PropTypes.string.isRequired,
  setView: PropTypes.func.isRequired,
  setFacilities: PropTypes.func.isRequired
};

// Style Overrides
const Selected = styled.div`
  ${voice.small};

  border-radius: ${radius};
  padding: ${pad / 2}px ${pad}px;
  background-color: ${props => props.theme.secondary};
  display: flex;
  align-items: center;
  line-height: 1.5em;

  ${bp(3)} {
    ${voice.normal};
  }

  svg {
    pointer-events: none;
    fill: ${props => props.theme.tertiary};
  }
`;

const OptionsWrapper = styled.div`
  position: absolute;
  top: -100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: ${pad}px;
  pointer-events: auto;
  background: ${props => props.theme.primary};
  border-bottom-left-radius: ${radius};
  border-bottom-right-radius: ${radius};
  z-index: -1;
  transition: all ease 0.5s;

  ${Button} {
    background: transparent;
  }
`;

const StaticButton = styled(Button)`
  visibility: hidden;
`;

const OptionsWrapperStatic = styled.div`
  position: absolute;
  top: 70%;
  width: 100%;
  padding-top: ${pad}px;
  overflow: hidden;
  z-index: -1;
  pointer-events: none;

  ${StaticButton} {
    background: transparent;
  }
`;

const Menu = styled.div`
  position: relative;
  z-index: ${z("base")};

  &:hover {
    ${OptionsWrapper} {
      top: 0;
    }
  }
`;

export default ToggleMenu;

import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTrash, faPlus} from "@fortawesome/free-solid-svg-icons";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";

// Contexts
import {useToast} from "../../contexts/toast.js";

// Hooks
import useMountedState from "../../hooks/useMountedState.js";

// Utils
import {fromMilitary, generateUniqueKey} from "../../utils/helpers.js";
import {replacePseudoFields} from "../../utils/builder.js";

// Components
import Modal from "../../components/Modal.js";
import ManageConditional from "./ManageConditional.js";
import {InputRadioGroup} from "../../components/form/FormInputs.js";

// Style
import {flex, z} from "../../style/components/mixins.js";
import {border, pad, radius} from "../../style/components/variables.js";
import {voice} from "../../style/components/typography.js";
import {bp} from "../../style/components/breakpoints.js";
import {
  Button,
  ButtonFull,
  Form,
  FormField,
  FormGroup,
  HeadingCenter,
  Inline,
  Label,
  Text
} from "../../style/components/general.js";

const checkMap = {
  "===": "is",
  "!==": "is not"
};

const compareMap = {
  checked: "has all checked",
  unchecked: "has all unchecked",
  one: "has one checked",
  more: "has at least one checked"
};

const ModalConditionals = ({
  visible,
  setVisible,
  targetElement,
  save,
  builder,
  hasBackButton,
  goBack,
  hasPrimaryAddress
}) => {
  const isMounted = useMountedState();
  const {addToast} = useToast();

  const [conditions, setConditions] = useState([]);
  const [targetCondition, setTargetCondition] = useState(null);
  const [targetConditionIdx, setTargetConditionIdx] = useState(null);
  const [openManage, setOpenManage] = useState(false);

  const schema = yup.object().shape({
    action: yup.string().required("Required")
  });
  const defaultValues = {
    action: targetElement?.condition ? targetElement.condition.action : "Show as a REQUIRED field",
    operator: targetElement?.condition?.operator || "or"
  };

  const form = useForm({
    defaultValues: defaultValues,
    resolver: yupResolver(schema)
  });

  const {watch, handleSubmit, setValue} = form;
  const action = watch("action");
  const operator = watch("operator");

  useEffect(() => {
    if (!visible) {
      setConditions([]);
      setValue("action", "Show as a REQUIRED field");
    } else {
      setConditions(targetElement?.condition ? targetElement.condition.list : []);
      setValue(
        "action",
        targetElement?.condition ? targetElement.condition.action : "Show as a REQUIRED field"
      );
    }
  }, [setValue, targetElement, visible]);

  useEffect(() => {
    if (isMounted() && targetElement?.condition?.list) setConditions(targetElement.condition.list);
  }, [isMounted, targetElement]);

  const handleDelete = idx => {
    const tempConditions = [...conditions];
    tempConditions.splice(idx, 1);
    setConditions(tempConditions);
  };

  const submission = values => {
    const tempConditions = [...conditions];
    const {trigger, depends: currentDepends, conditionArray: arr, operator: condOperator} = values;

    const toSave = {
      trigger,
      depends: currentDepends,
      check:
        currentDepends === "weekday" || currentDepends === "date"
          ? null
          : arr.map(cond => cond.check),
      compare:
        currentDepends === "weekday"
          ? arr[0].compare
          : arr.map(cond => {
              let {compare} = cond;
              if (cond?.frequency) {
                compare += `_${cond.frequency}`;
                if (cond?.lastDay) compare += "_last";
              }
              return compare;
            }),
      operator: condOperator
    };

    if (
      tempConditions &&
      tempConditions.length > 0 &&
      (targetConditionIdx || targetConditionIdx === 0)
    )
      tempConditions.splice(targetConditionIdx, 1, toSave);
    else tempConditions.push(toSave);

    setConditions(tempConditions);
    setTargetCondition(null);
    setTargetConditionIdx(null);
    setOpenManage(false);
  };

  const handleEdit = (condition, i) => {
    const {trigger, depends: currDepends, check, compare, operator: condOperator} = condition;
    let conditionArray = [];

    let triggerDefault = trigger;

    if (!triggerDefault)
      triggerDefault = currDepends !== "weekday" && currDepends !== "date" ? "field" : currDepends;

    if (currDepends !== "weekday" && Array.isArray(compare))
      compare.map((compareVal, compareIdx) => {
        if (check)
          conditionArray.push({check: check[compareIdx] ?? null, compare: compareVal ?? null});
        else {
          const parts = compareVal.split("_");
          conditionArray.push({
            check: null,
            compare: parts?.length >= 1 ? parts[0] : null,
            frequency: parts?.length >= 2 ? parts[1] : null,
            lastDay: parts?.length === 3
          });
        }
      });
    else if (compare) conditionArray = [{check: null, compare}];

    const target = {
      trigger: triggerDefault,
      condition: {depends: currDepends, operator: condOperator, conditionArray}
    };
    setTargetCondition(target);
    setTargetConditionIdx(i);
    setOpenManage(true);
  };

  const getOptions = () => {
    if (targetElement?.type === "confirm") return ["Show as a REQUIRED field", "Hide field"];
    return [
      "Show as a REQUIRED field",
      "Show as an OPTIONAL field",
      `Make ${targetElement?.required ? "an" : "a"} ${
        targetElement?.required ? "OPTIONAL" : "REQUIRED"
      } field`,
      "Hide field"
    ];
  };

  const saveConditionals = () => {
    if (openManage) addToast("Please create condition or cancel.", "error");
    else {
      if (!conditions || conditions.length === 0) save({condition: null});
      else save({condition: {action, operator, list: conditions}});
      if (hasBackButton && goBack) goBack();
      else setVisible(false);
    }
  };

  const getCompareVals = (compare, i, compareList, dep, condOperator) => {
    let transformed = compare;

    if (dep === "date") {
      const parts = compare.split("_");
      if (parts?.length > 1) {
        transformed = `Recurring ${parts[1].toUpperCase()}`;
        transformed +=
          parts[1] === "monthly" && parts?.length === 3
            ? " on the last day of the month"
            : ` starting ${dayjs(parts[0].slice(5)).format("MMM D")}`;
      } else transformed = dayjs(compare.slice(5)).format("MMM D");
    }

    if (dep === "weekday" || dep === "date") {
      if (i < compareList.length - 2) return `${transformed}, `;
      if (i === compareList.length - 2 && compareList.length === 2) return `${transformed} or `;
      if (i === compareList.length - 2) return `${transformed}, or `;
      return `${transformed}.`;
    }

    if (builder.byId[dep]?.type === "time") return fromMilitary(`${transformed}`);

    transformed =
      transformed in compareMap ? compareMap[transformed] : transformed.replace("Other: ", " ");

    if (i < compareList.length - 2) return `${transformed}, `;
    if (i === compareList.length - 2 && compareList.length === 2)
      return `${transformed} ${condOperator} `;
    if (i === compareList.length - 2) return `${transformed}, ${condOperator} `;
    return transformed;
  };

  const getConditionLabel = (sanitizedDepends, suffix, compare, check, condOperator, idx) => (
    <ConditionLabel data-testid="condition">
      {sanitizedDepends &&
        sanitizedDepends in builder.byId &&
        `${builder.byId[sanitizedDepends].label} ${suffix ? `${suffix} ` : ""}`}
      {sanitizedDepends === "weekday" && "Current Day is "}
      {sanitizedDepends === "rainfall" && "Yesterday's rainfall "}
      {sanitizedDepends === "cumulative" && "Cumulative rainfall "}
      {compare &&
        Array.isArray(compare) &&
        compare.map((curr, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <span key={`compare-${idx}-${i}`}>
            {/* if compare is in compare map, omit the check value as the 
                compare label includes the proper verbage */}
            {!(curr in compareMap) &&
              check &&
              check[i] &&
              `${check[i] in checkMap ? checkMap[check[i]] : check[i]} `}
            {getCompareVals(curr, i, compare, sanitizedDepends, condOperator)}
          </span>
        ))}
      {compare && !Array.isArray(compare) && (
        <span>
          {/* if compare is in compare map, omit the check value as the 
                compare label includes the proper verbage */}
          {!(compare in compareMap) && check && `${check in checkMap ? checkMap[check] : check} `}
          {getCompareVals(compare, 0, [compare], sanitizedDepends)}
        </span>
      )}
    </ConditionLabel>
  );

  return (
    <Modal visible={visible} setVisible={setVisible} hasBackButton={hasBackButton} goBack={goBack}>
      <HeadingCenter>Apply Conditional</HeadingCenter>
      <FormField>
        <Text bold>SELECTED ELEMENT: {targetElement?.label.toUpperCase()}</Text>
      </FormField>
      <LabelContainer>
        <Label bold>CONDITIONS</Label>
      </LabelContainer>

      {conditions?.map((condition, idx) => {
        const {depends: currDepends, compare, check, operator: condOperator} = condition;
        const {sanitized: sanitizedDepends, suffix} = replacePseudoFields(currDepends);
        if (idx !== targetConditionIdx)
          return (
            <WrapperInline key={`${generateUniqueKey(sanitizedDepends)}`}>
              {getConditionLabel(sanitizedDepends, suffix, compare, check, condOperator, idx)}
              <Inline>
                <Option
                  type="button"
                  title="Edit"
                  onClick={() => handleEdit(condition, idx)}
                  data-testid="condition.edit">
                  <FontAwesomeIcon icon={faEdit} />
                </Option>
                <Option
                  type="button"
                  title="Delete"
                  onClick={() => handleDelete(idx)}
                  data-testid="condition.delete">
                  <FontAwesomeIcon icon={faTrash} />
                </Option>
              </Inline>
            </WrapperInline>
          );

        if (targetCondition)
          return (
            openManage && (
              <Wrapper key={`${generateUniqueKey(sanitizedDepends)}`}>
                {getConditionLabel(sanitizedDepends, suffix, compare, check, condOperator, idx)}
                <hr />
                <ManageConditional
                  builder={builder}
                  submission={submission}
                  existing={targetCondition}
                  setExisting={setTargetCondition}
                  open={state => {
                    if (conditions && conditions.length > 0) {
                      setOpenManage(state);
                      setTargetConditionIdx(null);
                    }
                  }}
                  hasPrimaryAddress={hasPrimaryAddress}
                  targetElement={targetElement}
                />
              </Wrapper>
            )
          );

        return null;
      })}

      {((openManage && !targetCondition) || !conditions || conditions.length === 0) && (
        <Wrapper>
          <ManageConditional
            builder={builder}
            submission={submission}
            existing={targetCondition}
            setExisting={setTargetCondition}
            canCancel={conditions && conditions.length > 0}
            open={state => {
              if (conditions && conditions.length > 0) {
                setOpenManage(state);
                setTargetConditionIdx(null);
                setTargetCondition(null);
              }
            }}
            targetElement={targetElement}
            hasPrimaryAddress={hasPrimaryAddress}
          />
        </Wrapper>
      )}

      {!openManage && conditions?.length > 0 && (
        <Add data-testid="condition.add" type="button" onClick={() => setOpenManage(true)}>
          <FontAwesomeIcon icon={faPlus} />
          &nbsp;Condition
        </Add>
      )}

      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(saveConditionals)}>
          <FormGroup>
            {conditions?.length > 1 && (
              <FormField>
                <InputRadioGroup
                  name="operator"
                  label="Logical Operator"
                  options={[{option: "or"}, {option: "and"}]}
                />
              </FormField>
            )}
            <FormField>
              <InputRadioGroup
                name="action"
                label="Action"
                options={getOptions()}
                orient
                testId="action"
              />
            </FormField>
          </FormGroup>
          <ButtonFull type="submit" data-testid="conditionals.submit">
            Update Field
          </ButtonFull>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalConditionals.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  targetElement: PropTypes.objectOf(PropTypes.any),
  save: PropTypes.func.isRequired,
  builder: PropTypes.objectOf(PropTypes.any).isRequired,
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func,
  hasPrimaryAddress: PropTypes.bool
};

ModalConditionals.defaultProps = {
  targetElement: null,
  hasBackButton: false,
  goBack: () => {},
  hasPrimaryAddress: false
};

// Style Overrides
const Wrapper = styled.div`
  border: ${border} solid ${({theme}) => theme.primary};
  border-radius: ${radius};
  padding: ${pad}px;
  width: 100%;
  margin-bottom: ${pad}px;

  ${Label} {
    margin: 0;
  }
`;

const WrapperInline = styled(Wrapper)`
  ${flex("row", "nowrap", "space-between", "center")};
`;

const Option = styled(Button)`
  font-weight: bold;
  z-index: ${z("above")};
  ${voice.normal}
`;

const ConditionLabel = styled(Label)`
  max-width: calc(100% - 100px);
`;

const Add = styled(Button)`
  margin-right: ${pad}px;
  padding-left: 11.5px;
  padding-right: 11.5px;
  margin-bottom: ${pad * 2}px;
  margin-left: ${pad / 2}px;

  ${bp(2)} {
    margin-left: 0;
  }
`;

const LabelContainer = styled.div`
  margin-bottom: ${pad}px;
`;

export default ModalConditionals;

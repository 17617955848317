import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const ChartPath = ({...props}) => {
  const {chartId, line, onclick, mousemove, mouseover, mouseleave, strokeWidth, color} = props;

  return (
    <Path
      d={line}
      fill="transparent"
      color={color}
      clipPath={`url(#clip-${chartId})`}
      onClick={onclick}
      onMouseEnter={mouseover}
      onMouseLeave={mouseleave}
      onMouseMove={mousemove}
      pointerEvents={!!onclick || !!mouseover || !!mouseleave || !mousemove ? 1 : 0}
      strokeWidth={strokeWidth}
    />
  );
};

ChartPath.propTypes = {
  chartId: PropTypes.string.isRequired,
  line: PropTypes.any,
  onclick: PropTypes.func,
  mousemove: PropTypes.func,
  mouseover: PropTypes.func,
  mouseleave: PropTypes.func,
  strokeWidth: PropTypes.number,
  color: PropTypes.string
};

ChartPath.defaultProps = {
  line: null,
  onclick: null,
  mousemove: null,
  mouseover: null,
  mouseleave: null,
  strokeWidth: 1.5,
  color: "black"
};

// Style Overrides
const Path = styled.path`
  fill: transparent;
  stroke: ${({color}) => color || "black"};
  stroke-width: ${({strokeWidth}) => strokeWidth}px;
  stroke-opacity: 1;
  stroke-linecap: round;
  pointer-events: ${({pointerEvents}) => (pointerEvents === true ? "auto" : "none")};
`;

export default ChartPath;

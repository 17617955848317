import React, {useContext} from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";

// Utils
import {AuthContext} from "../contexts/auth.js";

// Components
import TableUsers from "./users/TableUsers.js";

// Style
import {border, pad, radius} from "../style/components/variables.js";
import {
  Abbr,
  Heading,
  List,
  ListItem,
  Loader,
  Page,
  Text,
  Title
} from "../style/components/general.js";

const Users = () => {
  const {currentUser, roles, roleCanAccessPage} = useContext(AuthContext);
  return (
    currentUser && (
      <Page>
        <PageTitle>User Management</PageTitle>

        <Roles>
          <RoleHeader>
            <Heading>Roles</Heading>
            {roleCanAccessPage("user_roles") && (
              <Edit to="/user-roles">
                <Abbr title="Manage Roles">
                  <FontAwesomeIcon icon={faEdit} />
                </Abbr>
              </Edit>
            )}
          </RoleHeader>
          {roles ? (
            <List column>
              {roles.length > 0 ? (
                roles.map(({id, label}) => (
                  <ListItem key={id}>
                    <Text>{label.toUpperCase()}</Text>
                  </ListItem>
                ))
              ) : (
                <Text>No roles have been created.</Text>
              )}
            </List>
          ) : (
            <Loader />
          )}
        </Roles>

        <TableUsers />
      </Page>
    )
  );
};

// Style Overrides
const PageTitle = styled(Title)`
  margin-bottom: ${pad}px;
`;

const Roles = styled.div`
  position: relative;
  width: fit-content;
  padding: ${pad}px;
  margin-bottom: ${pad}px;
  border: ${border} solid ${props => props.theme.secondary};
  border-radius: ${radius};
`;

const RoleHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Edit = styled(Link)`
  padding: ${pad}px;
`;

export default Users;

import React, {useContext, useEffect} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import styled, {css} from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

// Utils
import useMountedState from "../hooks/useMountedState.js";
import {AuthContext} from "../contexts/auth.js";
import {getWithExpiry} from "../utils/helpers.js";

// Components
import Footer from "./general/Footer.js";
import {InputPassword, InputCheck, InputError} from "../components/form/FormInputs.js";

// Style
import {border, heroTheme, pad, radius} from "../style/components/variables.js";
import {flex, z} from "../style/components/mixins.js";
import {voice} from "../style/components/typography.js";
import {bp} from "../style/components/breakpoints.js";
import {FormGroup, FormField, Form, Input} from "../style/components/general.js";

const schema = yup.object().shape({
  email: yup.string().email("Invalid email address").required("Please provide email"),
  password: yup.string().required("Please provide password"),
  remember: yup.bool()
});

const SignIn = () => {
  const isMounted = useMountedState();

  const navigate = useNavigate();

  const {pathname} = useLocation();

  const {signin, currentUser, maintenance} = useContext(AuthContext);

  const form = useForm({
    resolver: yupResolver(schema)
  });
  const {
    register,
    handleSubmit,
    formState: {errors}
  } = form;

  const handleSignIn = ({email, password, remember}) =>
    signin(email.toLowerCase(), password, remember);

  // redirect to Facilities Dashboard on Signin
  useEffect(() => {
    if (isMounted() && currentUser && pathname === "/signin") {
      if (maintenance?.status) navigate("maintenance");
      const savedPath = getWithExpiry("savedPath");
      navigate(savedPath || "/facilities");
    }
  }, [isMounted, currentUser, pathname, maintenance, navigate]);

  return (
    <SignInPage>
      <Header>
        <Logo src={`${process.env.PUBLIC_URL}/hero-services.png`} alt="Hero Services Logo" />
      </Header>
      <PageWrapper>
        <Content>
          <Welcome>Welcome Back!</Welcome>

          <FormProvider {...form}>
            <Form onSubmit={handleSubmit(handleSignIn)} noValidate>
              <FormGroup>
                <FormField>
                  <Input
                    type="email"
                    placeholder="Email"
                    autoComplete="username"
                    {...register("email", {required: true})}
                  />
                  <InputError name="email" errors={errors} />
                </FormField>
                <FormField>
                  <InputPassword name="password" />
                </FormField>
                <InputCheck name="remember">Remember Me</InputCheck>
                <SignInButton type="submit">Sign In</SignInButton>
              </FormGroup>
            </Form>
          </FormProvider>

          <ForgotPassword to="/forgot-password">Forgot password?</ForgotPassword>
        </Content>
      </PageWrapper>
      <Footer />
    </SignInPage>
  );
};

// Style Overrides
const SignInPage = styled.section`
  position: relative;
  width: 100vw;
  height: 100vh;
  ${flex("column", "nowrap", "start", "center")};
`;

const Header = styled.nav`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  align-self: start;
  display: flex;
  background: ${heroTheme.secondary};
  z-index: ${z("top")};

  ${props =>
    props.theme.name === "dark" &&
    css`
      border-bottom: ${border} solid ${heroTheme.tertiary};
    `}
`;

const Logo = styled.img`
  height: 75px;
  margin: ${pad}px auto;
`;

const PageWrapper = styled.section`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
`;

const Content = styled.div`
  width: 395px;
  max-width: 100%;
  padding: ${pad}px;
  margin: 0 auto;
  ${flex("column", "nowrap", "center", "center")};
`;

const Welcome = styled.h1`
  color: ${heroTheme.secondary};
  margin-bottom: ${pad}px;
  text-align: center;
  font-weight: bold;
  ${voice.strong};

  ${bp(5)} {
    ${voice.loud};
  }

  ${props =>
    props.theme.name === "dark" &&
    css`
      color: ${heroTheme.tertiary};
    `}
`;

const SignInButton = styled.button`
  border: 0;
  align-self: center;
  padding: ${pad}px;
  margin-top: ${pad}px;
  color: ${heroTheme.tertiary};
  border-radius: ${radius};
  background-color: ${heroTheme.secondary};

  ${props =>
    props.theme.name === "dark" &&
    css`
      background-color: ${heroTheme.primary};
    `}
`;

const ForgotPassword = styled(Link)`
  margin-top: ${pad}px;
  color: ${heroTheme.primary};
  ${voice.quiet};
`;

export default SignIn;

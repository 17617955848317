import React, {useEffect, useCallback, useContext, useState, useRef} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Utils
import useMountedState from "../../hooks/useMountedState.js";
import {AuthContext} from "../../contexts/auth.js";
import {useSocket} from "../../contexts/socket.js";
import useScrollListener from "../../hooks/useScrollListener.js";

// Components
import Modal, {ModalContainer} from "../../components/Modal.js";

// Style
import {z} from "../../style/components/mixins.js";
import {pad} from "../../style/components/variables.js";
import {voice} from "../../style/components/typography.js";
import {breakpoint} from "../../style/components/breakpoints.js";
import {HeadingCenter, TextCenter, Inline, Button} from "../../style/components/general.js";

const IDLE_TIME = 1800000;

const MANAGE_SESSION = "manage_session";

const ModalIdle = ({idle, setIdle, online, setOnline}) => {
  const isMounted = useMountedState();

  const socket = useSocket();

  const {signout, currentUser} = useContext(AuthContext);

  const scrolling = useScrollListener();

  const [wasOffline, setWasOffline] = useState(!online);

  const timerId = useRef(null);

  // Idle time for allotted time
  const checkIdle = useCallback(
    isScrolling => {
      if (isMounted()) {
        const resetTimer = () => {
          clearTimeout(timerId.current);
          timerId.current = setTimeout(() => {
            timerId.current = null;
            setIdle(true);
          }, IDLE_TIME);
        };

        window.onload = resetTimer;
        window.onmousemove = resetTimer;
        window.onmousedown = resetTimer;
        window.ontouchstart = resetTimer;
        window.onclick = resetTimer;
        window.onkeydown = resetTimer;
        if (isScrolling) resetTimer();
      }
    },
    [isMounted, setIdle]
  );

  useEffect(() => {
    if (!wasOffline && !online) setWasOffline(true);
  }, [online, wasOffline]);

  // Handle sign out on idle
  useEffect(() => {
    if (isMounted()) checkIdle(scrolling);
  }, [checkIdle, isMounted, scrolling]);

  return (
    <IdleWrapper>
      <Modal visible={idle} setVisible={setIdle} allowClose={false} maxWidth={breakpoint.width[1]}>
        <HeadingCenter>Are you still working?</HeadingCenter>
        <TextCenter>
          You may be logged out for inactivity.
          <br />
          {wasOffline && <Error>Connection was lost.</Error>}
        </TextCenter>

        <ButtonGroup>
          <Button
            type="button"
            onClick={() => {
              setOnline(true);
              setIdle(false);
              socket.emit(MANAGE_SESSION, currentUser.publicId);
              window.location.reload();
            }}>
            {wasOffline ? "Reconnect" : "Continue Working"}
          </Button>
          {!wasOffline && (
            <SignOut type="button" onClick={() => signout()}>
              Sign Out
            </SignOut>
          )}
        </ButtonGroup>
      </Modal>
    </IdleWrapper>
  );
};

ModalIdle.propTypes = {
  idle: PropTypes.bool.isRequired,
  setIdle: PropTypes.func.isRequired,
  online: PropTypes.bool.isRequired,
  setOnline: PropTypes.func.isRequired
};

// Style Overrides
const IdleWrapper = styled.div`
  position: absolute;

  ${ModalContainer} {
    z-index: ${z("peak") + 1};
  }
`;

const ButtonGroup = styled(Inline)`
  width: 100%;
  justify-content: center;
  padding-top: ${pad * 2}px;
  gap: ${pad}px;
`;

const SignOut = styled(Button)`
  background: ${props => props.theme.error};
`;

const Error = styled.span`
  ${voice.quiet};
  margin-top: ${pad}px;
  color: ${props => props.theme.error};
`;

export default ModalIdle;

import React, {useState, useEffect, useCallback, useContext, useRef} from "react";
import {FormProvider, useForm} from "react-hook-form";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faCalendarDay,
  faKeyboard,
  faArchive,
  faTrash,
  faEdit,
  faEyeSlash,
  faEye,
  faBars
} from "@fortawesome/free-solid-svg-icons";
import {useNavigate, useParams} from "react-router-dom";

// Utils
import {SettingsContext} from "../../contexts/settings.js";
import {AuthContext} from "../../contexts/auth.js";
import {prettyDateWithDayInUserTimezone} from "../../utils/helpers.js";
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Components
import BaseTable from "../../components/BaseTable.js";
import Pagination from "../../components/Pagination.js";
import ToggleSwitch from "../../components/ToggleSwitch.js";
import Dropdown from "../../components/Dropdown.js";
import ModalViewGenerated from "./ModalViewGenerated.js";
import ModalDelete from "../../components/ModalDelete.js";

// Style
import {border, pad, radius, shadow} from "../../style/components/variables.js";
import {voice} from "../../style/components/typography.js";
import {bp} from "../../style/components/breakpoints.js";
import {flex, z} from "../../style/components/mixins.js";
import {
  Abbr,
  Arrow,
  Button,
  ButtonWrapper,
  Heading,
  Inline,
  Search,
  SearchIcon,
  SearchWrapper,
  Small,
  TableEditMenu,
  TableEditMenuButton,
  TableFooter,
  TableWrapper
} from "../../style/components/general.js";

const TableReportRecords = ({index, report, showDelete, archiveReport, restoreReport}) => {
  const isMounted = useMountedState();

  const {settings} = useContext(SettingsContext);
  const {currentUser, atLeast, roleCanAccessResource} = useContext(AuthContext);
  const {slug} = useParams();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [records, setRecords] = useState(null);
  const [visible, setVisible] = useState(false);
  const [selected, setSelected] = useState({});
  const [searchType, setSearchType] = useState("text");
  const [initialLoad, setInitialLoad] = useState(true);
  const [query, setQuery] = useState("");
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageTotal, setPageTotal] = useState(1);
  const [orderBy, setOrderBy] = useState("desc");
  const [groupBy, setGroupBy] = useState("createdAt");
  const [limit, setLimit] = useState(10);
  const [limits, setLimits] = useState([]);

  const [edit, setEdit] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const [showDeleted, setShowDeleted] = useState(false);
  const [showFilters, setShowFilters] = useState(false);

  const {api, loading: loadingRecords, data} = useApi("report-records");
  const {api: apiSuppressed} = useApi("report-records", {suppress: {success: true, error: true}});

  const filterMenu = useRef(null);

  useEffect(() => {
    const handleClickOutsideFilterMenu = e =>
      filterMenu &&
      filterMenu.current !== null &&
      !filterMenu.current.contains(e.target) &&
      !filterMenu.current.contains(e.target.nextSibling) &&
      setShowFilters(false);

    if (filterMenu) document.addEventListener("mousedown", handleClickOutsideFilterMenu);

    // Cleanup
    return () => document.removeEventListener("mousedown", handleClickOutsideFilterMenu);
  }, [filterMenu]);

  const form = useForm({
    defaultValues: {
      search: ""
    }
  });

  const {reset, register} = form;

  const openModal = useCallback(
    localRecords => {
      const {hash} = window.location;
      if (hash && initialLoad) {
        const recordId = parseInt(hash.replace("#", ""), 10);
        if (recordId && !Number.isNaN(recordId)) {
          const shouldOpen = localRecords.filter(record => record.id === recordId);
          if (shouldOpen.length > 0) {
            setSelected(shouldOpen[0]);
            setVisible(true);
            setInitialLoad(false);
          }
        }
      }
    },
    [initialLoad]
  );

  const getGeneratedReports = useCallback(
    p => {
      setLoading(true);

      const payload = {
        reportId: report.id,
        page: p || 1,
        orderBy: orderBy,
        groupBy: groupBy,
        limit: limit,
        showDeleted
      };

      if (query) payload.filter = JSON.stringify({Search: query});

      api.callGet(null, payload).then(() => {
        setLoading(false);
      });
    },
    [api, report, groupBy, limit, orderBy, query, showDeleted]
  );

  // Open first table
  useEffect(() => {
    if (isMounted() && index === 1) setOpen(true);
  }, [isMounted, index]);

  // Initial Load
  useEffect(() => {
    if (isMounted() && report.id && !records && open) getGeneratedReports();
  }, [isMounted, report.id, getGeneratedReports, records, open]);

  // Update report records on sort or limit
  useEffect(() => {
    if (isMounted() && records) getGeneratedReports();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted, orderBy, groupBy, limit, query, showDeleted]);

  useEffect(() => {
    if (isMounted() && !loadingRecords && data && data.records) {
      setPageTotal(data.pages);
      setRecords(data.records);

      // Page Limit
      const {total: dataTotal} = data;
      setTotal(dataTotal);
      let count = 10;
      const temp = [];
      while (count < total + 10 && count <= 30) {
        temp.push(count);
        count += 10;
      }
      setLimits(temp);
      if (initialLoad) openModal(data.records);
    }
  }, [isMounted, data, initialLoad, loadingRecords, openModal, total]);

  const handleSearch = e => {
    setCurrentPage(1);
    setQuery(e.target.value);
  };

  const generateReport = () =>
    api.callPost({reportId: report.id, userId: currentUser.publicId}).then(({status}) => {
      if (status === 201) getGeneratedReports();
    });

  const handleDelete = () => {
    const ids = edit.map(r => r.recordId);
    api.callDelete(null, {data: {ids}}).then(response => {
      if (response) {
        setShowDeleteModal(false);
        getGeneratedReports();
        setEdit([]);
        setShowOptions(false);
      }
    });
  };

  const restore = () => {
    const [record] = edit;
    const payload = {isDeleted: false};
    apiSuppressed.callPut(record.recordId, payload).then(response => {
      if (response) {
        getGeneratedReports();
        setEdit([]);
        setShowOptions(false);
      }
    });
  };

  return (
    <TableWrapper>
      <TableMenuRow>
        <Inline>
          <ReportName>
            <abbr title={report.name}>{report.name}</abbr>
          </ReportName>
          {report?.frequency?.name && (
            <Frequency>
              <abbr title={report.frequency.name.toUpperCase()}>
                {report.frequency.name.toUpperCase()}
              </abbr>
            </Frequency>
          )}
          {report.isArchived && <Archived>ARCHIVED</Archived>}
        </Inline>
        <Inline>
          <ButtonWrapper
            type="button"
            onClick={() => {
              setOpen(prev => !prev);
              setRecords(null);
            }}>
            <Arrow rotate={!open ? "180deg" : "270deg"} />
          </ButtonWrapper>
        </Inline>
      </TableMenuRow>

      {open && (
        <>
          <TableMenuRow>
            <SearchWrapper>
              {searchType === "text" && (
                <SearchIcon>
                  <FontAwesomeIcon icon={faSearch} />
                </SearchIcon>
              )}
              <FormProvider {...form}>
                <Search
                  name="search"
                  type={searchType}
                  placeholder="Search..."
                  {...register("search")}
                  onChange={e => handleSearch(e, report.id)}
                />
              </FormProvider>
              <Spaced>
                <ToggleSwitch
                  iconLeft={faKeyboard}
                  iconRight={faCalendarDay}
                  textLeft="Text"
                  textRight="Date"
                  onChange={() => {
                    setSearchType(prev => (prev === "text" ? "date" : "text"));
                    reset({search: ""});
                  }}
                  options={["text", "date"]}
                  defaultSelected="text"
                />
              </Spaced>
            </SearchWrapper>
            <Inline>
              {atLeast("creator") && (
                <>
                  {roleCanAccessResource("report_record", "delete") &&
                    records &&
                    records.length > 0 && (
                      <Options type="button" onClick={() => setShowOptions(prev => !prev)}>
                        {showOptions ? "Cancel" : "Options"}
                      </Options>
                    )}

                  {roleCanAccessResource("report", "update") && !report.isArchived && (
                    <Button
                      type="Button"
                      onClick={() => navigate(`/facilities/${slug}/reports/${report.id}/edit`)}>
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  )}
                  {roleCanAccessResource("report", "archive") && (
                    <Archive
                      restore={report.isArchived}
                      type="button"
                      onClick={() =>
                        !report.isArchived ? archiveReport(report) : restoreReport(report)
                      }>
                      {!report.isArchived ? (
                        <Abbr title="Archive">
                          <FontAwesomeIcon icon={faArchive} />
                        </Abbr>
                      ) : (
                        "Restore"
                      )}
                    </Archive>
                  )}
                  {roleCanAccessResource("report", "delete") && (
                    <Delete type="button" onClick={() => showDelete(report)}>
                      <Abbr title="Delete">
                        <FontAwesomeIcon icon={faTrash} />
                      </Abbr>
                    </Delete>
                  )}
                  <PositionedDiv>
                    <Button
                      type="button"
                      onClick={() => {
                        setShowFilters(!showFilters);
                      }}>
                      <FontAwesomeIcon icon={faBars} />
                    </Button>
                    {showFilters && (
                      <OptionSub ref={filterMenu}>
                        <ApplyFilter
                          type="button"
                          onClick={() => {
                            setShowFilters(false);
                            setShowDeleted(prev => !prev);
                          }}>
                          <Abbr title="Show/Hide Deleted">
                            <FontAwesomeIcon icon={showDeleted ? faEyeSlash : faEye} />
                            &nbsp;Deleted
                          </Abbr>
                        </ApplyFilter>

                        {roleCanAccessResource("report_record", "create") && (
                          <ApplyFilter type="button" onClick={generateReport}>
                            Generate
                          </ApplyFilter>
                        )}
                      </OptionSub>
                    )}
                  </PositionedDiv>
                </>
              )}
            </Inline>
          </TableMenuRow>
          <BaseTable
            headings={{
              report: {header: "View", disabled: true},
              userId: {
                header: "Generated By",
                disabled: false
              },
              createdAt: {
                header: "Created",
                disabled: false
              },
              recordId: {header: "", disabled: true}
            }}
            data={
              !records
                ? []
                : records.map(record => ({
                    recordId: record.id,
                    report: roleCanAccessResource("report_record", "view") && (
                      <Inline>
                        <Button
                          type="button"
                          onClick={() => {
                            setVisible(true);
                            setSelected(record);
                          }}
                          data-testid="tableRecord.preview">
                          View
                        </Button>
                      </Inline>
                    ),
                    userId: record.user
                      ? `${record.user.firstName} ${record.user.lastName}`
                      : "GENERATED",
                    createdAt: prettyDateWithDayInUserTimezone(
                      record.createdAt,
                      settings.timezone,
                      "ddd, MMM D YYYY hh:mm a"
                    ),
                    isDeleted: record.isDeleted
                  }))
            }
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            groupBy={groupBy}
            setGroupBy={setGroupBy}
            loading={loadingRecords}
            items={edit}
            setItems={setEdit}
            showOptions={showOptions}
            editMenu={
              <TableEditMenu>
                {edit.length === 1 &&
                  edit[0].isDeleted &&
                  roleCanAccessResource("report_record", "delete") && (
                    <TableEditMenuButton type="button" onClick={() => restore()}>
                      Restore
                    </TableEditMenuButton>
                  )}
                {edit.length >= 1 &&
                  !edit.some(i => i.isDeleted) &&
                  roleCanAccessResource("report_record", "delete") && (
                    <TableEditMenuButton type="button" onClick={() => setShowDeleteModal(true)}>
                      Delete
                    </TableEditMenuButton>
                  )}
              </TableEditMenu>
            }
          />
          <TableFooter>
            {total > 0 && (
              <>
                <Inline>
                  {total > 10 && limits.length > 0 && (
                    <PageLimit>
                      <Dropdown
                        options={limits}
                        selection={limit}
                        setSelection={selection => {
                          setLimit(selection);
                          setCurrentPage(1);
                        }}
                      />
                    </PageLimit>
                  )}
                </Inline>
                {limit <= 30 && pageTotal > 1 && (
                  <Pagination
                    current={currentPage}
                    setCurrent={setCurrentPage}
                    pageTotal={pageTotal}
                    updateData={() => getGeneratedReports(currentPage)}
                    loading={loading}
                  />
                )}
              </>
            )}
          </TableFooter>
        </>
      )}

      {visible && (
        <ModalViewGenerated
          visible={visible}
          setVisible={setVisible}
          report={report}
          record={selected}
          createdAt={selected.createdAt}
          updateData={getGeneratedReports}
        />
      )}

      {showDeleteModal && (
        <ModalDelete
          visible={showDeleteModal}
          setVisible={setShowDeleteModal}
          confirmDelete={() => handleDelete()}
          title="Delete Report Record"
          loading={loadingRecords}
        />
      )}
    </TableWrapper>
  );
};

TableReportRecords.propTypes = {
  index: PropTypes.number.isRequired,
  report: PropTypes.objectOf(PropTypes.any).isRequired,
  showDelete: PropTypes.func.isRequired,
  archiveReport: PropTypes.func.isRequired,
  restoreReport: PropTypes.func.isRequired
};

// Style Overrides
const TableMenuRow = styled.div`
  ${flex("row", "nowrap", "space-between", "center")};
  width: 100%;
  padding: ${pad}px;

  ${Inline}:first-child {
    max-width: 90%;
    width: inherit;
  }
`;

const ReportName = styled(Heading)`
  text-overflow: ellipsis;
  overflow-x: hidden;
  display: block;
  white-space: nowrap;
  max-width: 65%;

  ${bp(2)} {
    max-width: inherit;
  }
`;

const Frequency = styled(Small)`
  width: fit-content;
  padding: ${pad / 4}px ${pad / 2}px;
  border-radius: 10px;
  color: ${props => props.theme.tertiary};
  background: ${props => props.theme.primary};
  margin-left: ${pad}px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: nowrap;
  ${voice.quiet};
`;

const Options = styled(Button)`
  ${props =>
    props.locked &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

const Archived = styled(Small)`
  width: fit-content;
  padding: ${pad / 4}px ${pad / 2}px;
  border-radius: 10px;
  color: ${props => props.theme.tertiary};
  background: ${props => props.theme.warning};
  margin-left: ${pad}px;
  ${voice.quiet};
`;

const Archive = styled(Button)`
  padding: ${pad / 2}px ${pad}px;
  border-radius: ${radius};
  color: ${props => props.theme.tertiary};
  background: ${props => props.theme.warning};

  ${props =>
    props.restore &&
    css`
      background: ${props.theme.success};
    `}
`;

const Delete = styled(Button)`
  padding: ${pad / 2}px ${pad}px;
  border-radius: ${radius};
  color: ${props => props.theme.tertiary};
  background: ${props => props.theme.error};
`;

const PageLimit = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${pad}px;

  select {
    padding: ${pad / 4}px;
  }
`;

const Spaced = styled.div`
  margin-right: ${pad}px;
  height: min-content;
  margin-left: ${pad / 2}px;

  ${bp(2)} {
    margin-left: ${pad}px;
  }
`;

const PositionedDiv = styled.div`
  position: relative;
`;

const ApplyFilter = styled(Button)`
  ${voice.quiet};
  width: fit-content;
  height: min-content;
  white-space: nowrap;
  padding: ${pad / 2}px;
  margin: ${pad / 2}px 0 0 0;

  > abbr > svg {
    fill: ${props => props.theme.tertiary};
    align-self: center;
  }
`;

const OptionSub = styled.div`
  ${flex("row", "nowrap", "start", "start")}
  position: absolute;
  top: 29px;
  right: 0;
  width: 168px;
  gap: ${pad / 2}px;
  border: ${border} solid ${props => props.theme.primary};
  border-radius: ${radius};
  padding: ${pad}px;
  background: ${props => props.theme.tertiary};
  margin-top: ${pad}px;
  z-index: ${z("top")};
  box-shadow: ${shadow};

  color: ${props => props.theme.secondary};

  svg {
    fill: ${props => props.theme.secondary};
  }
`;

export default TableReportRecords;

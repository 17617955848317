import React, {useState, useEffect, useCallback, useMemo} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Utils
import useApi from "../../hooks/useApi.js";

// Components
import Modal from "../../components/Modal.js";
import ModalFileView from "../facility/ModalFileView.js";

// Style
import {pad} from "../../style/components/variables.js";
import {voice} from "../../style/components/typography.js";
import {flex} from "../../style/components/mixins.js";
import {Anchor, Button, Heading, Text} from "../../style/components/general.js";
import {getAncestryName, getIndexedBuilderFields} from "../checksheet-builder/helpers.js";
import {GROUP} from "../../utils/builder.js";

const extensionMap = {
  "text/plain": "txt",
  pdf: "pdf",
  "image/jpeg": "jpg",
  "image/png": "png",
  "image/gif": "gif",
  "application/pdf": "pdf",
  "application/msword": "doc",
  "text/csv": "csv",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
  "application/vnd.ms-excel": "xls",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
  "application/vnd.ms-outlook": "msg"
};

const ModalAttachments = ({
  visible,
  setVisible,
  builder,
  files,
  pendingNotes,
  resolvedNotes,
  stageName,
  hasBackButton,
  goBack
}) => {
  const {api: fileApi} = useApi("files");

  const [consolidated, setConsolidated] = useState(null);
  const [retrieveLink, setRetrieveLink] = useState(null);
  const [links, setLinks] = useState(null);
  const [fileViewVisible, setFileViewVisible] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const indexedFields = useMemo(() => {
    const result = {};
    return builder ? getIndexedBuilderFields(builder.allIds, builder, result) : {};
  }, [builder]);

  const canPreview = file =>
    file.extension.name.includes("image") ||
    file.extension.name.includes("pdf") ||
    file.extension.name === "text/plain";

  useEffect(() => {
    if (!links && retrieveLink && retrieveLink.length > 0)
      fileApi.callGet(null, {ids: retrieveLink}).then(({status, data}) => {
        if (status === 200 && data) setLinks(data.links);
      });
  }, [fileApi, links, retrieveLink]);

  const getDisplayName = useCallback(
    element => {
      let label = stageName;
      if (element) {
        const {label: elementLabel} = element;
        label = `${label} ${elementLabel}`;
      }
      return label;
    },
    [stageName]
  );

  useEffect(() => {
    const attachments = {};
    const needLink = [];

    if (indexedFields) {
      // Field Files
      Object.keys(files).map(key => {
        files[key].files.map(file => {
          if (!canPreview(file)) needLink.push(file.id);
          if (attachments[key]) attachments[key].files.push(file);
          else
            attachments[key] = {
              name: stageName
                ? `${getDisplayName(builder.byId[key])}:`
                : `${getAncestryName(builder.byId[key], builder)}:`,
              files: [file]
            };
        });
      });

      // Note Files
      Object.keys(pendingNotes).map(key => {
        pendingNotes[key].map(note => {
          if (note.files)
            note.files.map(file => {
              if (!canPreview(file)) needLink.push(file.id);
              if (attachments[key]) attachments[key].files.push(file);
              else
                attachments[key] = {
                  name: stageName
                    ? `${getDisplayName(builder.byId[key])}:`
                    : `${getAncestryName(builder.byId[key], builder)}:`,
                  files: [file]
                };
            });
        });
      });

      Object.keys(resolvedNotes).map(key => {
        resolvedNotes[key].map(note => {
          if (note.files)
            note.files.map(file => {
              if (!canPreview(file)) needLink.push(file.id);
              if (attachments[key]) attachments[key].files.push(file);
              else
                attachments[key] = {
                  name: stageName
                    ? `${getDisplayName(builder.byId[key])}:`
                    : `${getAncestryName(builder.byId[key], builder)}:`,
                  files: [file]
                };
            });
        });
      });

      if (
        Object.keys(attachments).length === 1 &&
        Object.values(attachments)[0]?.length === 1 &&
        attachments.values()[0].files
      )
        setSelectedFile(attachments.values()[0].files[0]);

      setRetrieveLink(needLink);

      setConsolidated(
        Object.entries(attachments)
          .sort(([keyA], [keyB]) => {
            const elementA = builder.byId[keyA];
            const elementB = builder.byId[keyB];

            if (elementA.element === GROUP && elementB.element === GROUP) {
              const indexA = builder.allIds.indexOf(keyA);
              const indexB = builder.allIds.indexOf(keyB);

              return indexA - indexB;
            }

            if (elementA.element === GROUP) return 1;
            if (elementB.element === GROUP) return -1;

            const fieldIndexA = indexedFields[keyA];
            const fieldIndexB = indexedFields[keyB];

            return fieldIndexA - fieldIndexB;
          })
          .map(([, val]) => val)
      );
    }
  }, [builder, files, getDisplayName, pendingNotes, resolvedNotes, stageName, indexedFields]);

  const openFile = file => {
    fileApi.callGet(file.id, {download: true}).then(({status, data}) => {
      if (status === 200 && data)
        setSelectedFile({...file, link: data.link, download: data.download});
    });
  };

  // Open single file preview
  useEffect(() => {
    if (selectedFile) setFileViewVisible(true);
  }, [selectedFile]);

  const getExtension = file => {
    if (file?.extension?.name) {
      const abbreviated = extensionMap[file.extension.name];
      if (abbreviated) return ` (${abbreviated})`;
    }
    return "";
  };

  if (consolidated && selectedFile)
    return (
      <ModalFileView
        visible={fileViewVisible}
        setVisible={setVisible}
        hasBackButton={hasBackButton || consolidated.length > 1}
        goBack={() => setSelectedFile(null)}
        file={selectedFile}
      />
    );

  return (
    <Modal visible={visible} setVisible={setVisible} hasBackButton={hasBackButton} goBack={goBack}>
      <Heading>Attachments</Heading>
      {consolidated &&
        consolidated.map(({name, files: consolidatedFiles}) => (
          <div key={name}>
            <NotesHeader>{name}</NotesHeader>
            {consolidatedFiles.map(file => (
              <Attachment key={file.id}>
                {canPreview(file) || !links || !links[file.id] ? (
                  <FileLink onClick={() => openFile(file)}>
                    {file.label}
                    {getExtension(file)}
                  </FileLink>
                ) : (
                  <FileDownload href={links[file.id]} download>
                    {file.label}
                    {getExtension(file)}
                  </FileDownload>
                )}
              </Attachment>
            ))}
          </div>
        ))}
    </Modal>
  );
};

ModalAttachments.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  builder: PropTypes.objectOf(PropTypes.any),
  files: PropTypes.objectOf(PropTypes.any),
  pendingNotes: PropTypes.objectOf(PropTypes.any),
  resolvedNotes: PropTypes.objectOf(PropTypes.any),
  stageName: PropTypes.string,
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func
};

ModalAttachments.defaultProps = {
  builder: {allIds: [], byId: {}},
  files: null,
  pendingNotes: null,
  resolvedNotes: null,
  stageName: null,
  hasBackButton: false,
  goBack: () => {}
};

// Style Overrides
const Attachment = styled.div`
  ${flex("row", "no-wrap", "start", "center")};
  margin-right: ${pad}px;
`;

const NotesHeader = styled(Text)`
  ${voice.normal};
  font-weight: bold;
  text-transform: uppercase;
  margin-top: ${pad}px;
`;

const FileLink = styled(Button)`
  ${voice.weak};
  font-weight: bold;
  background: none;
  color: ${props => props.theme.primary};
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

const FileDownload = styled(Anchor)`
  ${voice.weak};
  font-weight: bold;
  background: none;
  color: ${props => props.theme.primary};
  cursor: pointer;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`;

export default ModalAttachments;

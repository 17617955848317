import React, {useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faBook, faTrash} from "@fortawesome/free-solid-svg-icons";

// Utils
import useApi from "../../hooks/useApi.js";
import {isMobileDevice} from "../../utils/responsive.js";
import {getMapFromCoords, openLink, hexToRgba} from "../../utils/helpers.js";

// Components
import NotesHistoryCalloutContent from "./NotesHistoryCalloutContent.js";

// Style
import {cross} from "../../style/components/shapes.js";
import {voice} from "../../style/components/typography.js";
import {flex, z} from "../../style/components/mixins.js";
import {border, pad, radius, shadow} from "../../style/components/variables.js";
import {
  CalloutContent,
  Text,
  Button,
  scrollbar,
  HeadingSmall,
  Abbr
} from "../../style/components/general.js";

const ViewNotesCalloutContent = ({
  setVisible,
  task,
  taskType,
  groupName,
  groupLabel,
  notes,
  handleEdit,
  handleDelete
}) => {
  const {api} = useApi("files");

  const [showHistory, setShowHistory] = useState(!notes || notes.length === 0);

  const renderOptions = note => (
    <Options className="renderOptions" data-testid="note.options">
      <Option type="button" onClick={() => handleEdit(note)} data-testid="note.edit">
        <abbr title="Edit">
          <FontAwesomeIcon icon={faEdit} />
        </abbr>
      </Option>
      <Option type="button" onClick={() => handleDelete(note)} data-testid="note.delete">
        <abbr title="delete">
          <FontAwesomeIcon icon={faTrash} />
        </abbr>
      </Option>
    </Options>
  );

  const openFile = fileId => {
    api.callGet(fileId).then(res => {
      if (res.status === 200) openLink(res.data.link);
    });
  };

  if (showHistory)
    return (
      <NotesHistoryCalloutContent
        setVisible={setVisible}
        taskId={task.id}
        taskType={taskType}
        groupName={groupName}
        groupLabel={groupLabel}
        hasPending={notes && notes.length > 0}
        setShowHistory={setShowHistory}
      />
    );

  return (
    <SmallCalloutContent className="calloutContent" mobile={isMobileDevice() ? 1 : 0}>
      <CalloutCloseButton onClick={() => setVisible(false)} />

      <CalloutTitle center>
        {notes.length}&nbsp;Pending Notes for <GroupLabel>{groupLabel}</GroupLabel>
      </CalloutTitle>

      <ListWrapper>
        <NoteList>
          {notes.map(note => {
            const {id, name, message, user, coords, files} = note;
            return (
              <NoteWrapper key={`${name}-${id}`}>
                <MenuContainer>
                  <Info>
                    <Message>{message}</Message>
                    <User>By: {user}</User>
                    {coords && (
                      <AddressLink
                        href={getMapFromCoords(coords.lat, coords.lon)}
                        target="_blank"
                        rel="noreferrer">
                        View in Maps
                      </AddressLink>
                    )}

                    {files?.length > 0 && (
                      <Files>
                        <hr />
                        <span>Attached Files</span>
                        {files.map(({id: fileId, label}) => (
                          <FileLink
                            type="button"
                            onClick={() => openFile(fileId)}
                            key={`file-${fileId}`}>
                            {label}
                          </FileLink>
                        ))}
                      </Files>
                    )}
                  </Info>
                  {renderOptions(note)}
                </MenuContainer>
              </NoteWrapper>
            );
          })}
        </NoteList>
      </ListWrapper>

      {task.hasNoteHistory && (
        <ButtonWrapper>
          <Button type="button" onClick={() => setShowHistory(true)}>
            <Abbr title="history">
              <FontAwesomeIcon icon={faBook} />
            </Abbr>
          </Button>
        </ButtonWrapper>
      )}
    </SmallCalloutContent>
  );
};

ViewNotesCalloutContent.propTypes = {
  setVisible: PropTypes.func.isRequired,
  task: PropTypes.objectOf(PropTypes.any).isRequired,
  taskType: PropTypes.string,
  groupName: PropTypes.string.isRequired,
  groupLabel: PropTypes.string.isRequired,
  notes: PropTypes.arrayOf(PropTypes.any),
  handleEdit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired
};

ViewNotesCalloutContent.defaultProps = {
  taskType: "checksheet",
  notes: []
};

// Style Overrides
const SmallCalloutContent = styled(CalloutContent)`
  position: relative;
  max-height: 50vh;
  box-shadow: ${shadow};
`;

const CalloutCloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  width: ${pad}px;
  padding: ${pad}px;
  margin: ${pad}px ${pad / 2}px;

  ${props => cross("100%", props.theme.secondary)};
`;

const CalloutTitle = styled(HeadingSmall)`
  margin: 0 0 ${pad}px 0;
  text-align: left;
  align-self: flex-start;
`;

const Message = styled(Text)`
  margin-bottom: ${pad / 2}px;
  max-width: calc(100% - 40px);

  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
`;

const User = styled(Text)`
  margin-bottom: ${pad / 2}px;
  ${voice.quiet};
`;

const ListWrapper = styled.div`
  position: relative;

  &:before,
  &:after {
    content: "";
    z-index: ${z("above")};
    position: absolute;
    left: 0;
    width: 100%;
    height: ${pad}px;
  }

  &:after {
    top: 0;
    background: ${props =>
      `linear-gradient(to bottom, ${`${hexToRgba(props.theme.tertiary)} 0%`}, ${
        props.theme.name === "light" ? "rgba(255, 255, 255, 0) 100%" : "rgba(0, 0, 0, 0) 100%"
      })`};
  }

  &:before {
    bottom: 0;
    background: ${props =>
      `linear-gradient(to top, ${`${hexToRgba(props.theme.tertiary)} 0%`}, ${
        props.theme.name === "light" ? "rgba(255, 255, 255, 0) 100%" : "rgba(0, 0, 0, 0) 100%"
      })`};
  }
`;

const NoteList = styled.div`
  max-height: 200px;
  overflow-y: scroll;
  ${scrollbar};
`;

const FileLink = styled.button`
  ${voice.quiet}
  font-weight: bold;
  color: ${props => props.theme.primary};
  width: fit-content;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

const NoteWrapper = styled.div`
  position: relative;
  border: ${border} solid ${props => props.theme.primary};
  border-radius: ${radius};
  padding: ${pad}px;
  width: 100%;
  margin: ${pad}px 0;
  flex: 1 auto;
`;

const MenuContainer = styled.div`
  width: 100%;
  ${flex("flex", "nowrap", "space-between", "end")};
`;

const Info = styled.div`
  width: 100%;
  height: 100%;
  width: 100%;
`;

const Files = styled.div`
  ${voice.quiet}

  span {
    font-weight: bold;
  }

  a {
    color: ${props => props.theme.primary};

    :hover {
      text-decoration: underline;
    }
  }
`;

const Options = styled.div`
  ${flex("row", "nowrap", "center", "center")};
  transition: all ease 0.2s;
  min-height: 38px;
`;

const Option = styled(Button)`
  margin: 0 0 0 ${pad}px;
  font-weight: bold;
  ${voice.normal}
`;

const AddressLink = styled.a`
  color: ${props => props.theme.primary};

  p {
    color: ${props => props.theme.primary};
  }

  &:hover {
    text-decoration: underline;
  }
`;

const GroupLabel = styled.span`
  color: ${props => props.theme.group};
  font-weight: bold;
`;

const ButtonWrapper = styled.div`
  margin-top: ${pad * 2}px;
`;

export default ViewNotesCalloutContent;

import React, {useContext, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import styled, {css} from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

// Utils
import {AuthContext} from "../contexts/auth.js";
import useMountedState from "../hooks/useMountedState.js";

// Components
import {InputPassword, InputCheck} from "../components/form/FormInputs.js";
import Footer from "./general/Footer.js";

// Style
import {border, heroTheme, pad, radius} from "../style/components/variables.js";
import {flex, z} from "../style/components/mixins.js";
import {
  Button,
  Form,
  FormField,
  FormGroup,
  Heading,
  Small,
  Text
} from "../style/components/general.js";
import voice from "../style/components/typography.js";

const schema = yup.object().shape({
  password: yup
    .string()
    .required("Please provide password")
    .min(6, "Password must be at least 6 characters"),
  confirmed: yup
    .string()
    .required("Please provide password")
    .min(6, "Password must be at least 6 characters")
    .test({
      message: "Passwords must match",
      test: (password, ctx) => password === ctx.parent.password
    }),
  certification: yup.bool().not([false], "Please certify your submission.")
});

const FirstSignin = () => {
  const navigate = useNavigate();

  const isMounted = useMountedState();

  const {pathname} = useLocation();

  const {currentUser, resetPassword, signout} = useContext(AuthContext);

  const form = useForm({
    resolver: yupResolver(schema)
  });
  const {handleSubmit} = form;

  const handleReset = ({password}) => resetPassword(password);

  // redirect to Facilities Dashboard on Signin
  useEffect(() => {
    if (isMounted() && pathname === "/first-signin" && currentUser && currentUser.isAccepted)
      navigate("/facilities");
  }, [isMounted, currentUser, pathname, navigate]);

  return (
    <ResetPasswordPage>
      <Header>
        <Logo src={`${process.env.PUBLIC_URL}/hero-services.png`} alt="Hero Services Logo" />
        <StyledButton
          type="button"
          onClick={() => {
            signout();
          }}>
          Sign Out
        </StyledButton>
      </Header>
      <FormWrapper>
        <PageHeading>Welcome to Hero Builder™!</PageHeading>
        <Text center>
          Please agree to Terms & Conditions and create a new password to get started.
        </Text>
        <FormProvider {...form}>
          <Form onSubmit={handleSubmit(handleReset)} noValidate>
            <FormGroup>
              <FormField>
                <InputPassword
                  name="password"
                  label="Password"
                  placeholder="New password"
                  required
                />
              </FormField>
              <FormField>
                <InputPassword name="confirmed" placeholder="Confirm password" required />
              </FormField>
              <FormField>
                <InputCheck name="certification" label="Certification" required {...form}>
                  The following is equivalent to your digital signature.
                  <br />
                  <Small>
                    I, {currentUser.firstName} {currentUser.lastName}, agree and understand that by
                    checking this box that all electronic signatures are the legal equivalent of my
                    manual/handwritten signature and I consent to be legally bound to this
                    agreement.
                  </Small>
                </InputCheck>
              </FormField>
              <Reset type="submit">Confirm New Password</Reset>
            </FormGroup>
          </Form>
        </FormProvider>
      </FormWrapper>
      <Footer />
    </ResetPasswordPage>
  );
};

// Style Overrides
const PageHeading = styled(Heading)`
  color: ${heroTheme.secondary};
  margin-bottom: ${pad}px;
`;

const ResetPasswordPage = styled.section`
  ${flex("column", "wrap", "center", "center")};
  min-height: 100vh;
  height: 100%;
`;

const FormWrapper = styled.section`
  ${flex("column", "wrap", "center", "center")}
  height: 100%;
  max-width: 550px;
  padding: 0 ${pad * 2}px;
`;

const Reset = styled.button`
  border: 0;
  margin-top: ${pad * 2}px;
  align-self: center;
  padding: ${pad}px ${pad * 4}px;
  background-color: ${heroTheme.secondary};
  color: ${heroTheme.tertiary};
  border-radius: ${radius};
  width: max-content;
`;

const Header = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  align-self: start;
  display: flex;
  background: ${heroTheme.secondary};
  z-index: ${z("top")};

  ${props =>
    props.theme.name === "dark" &&
    css`
      border-bottom: ${border} solid ${heroTheme.tertiary};
    `}
`;

const Logo = styled.img`
  height: 75px;
  margin: ${pad}px auto;
`;

const StyledButton = styled(Button)`
  top: 30px;
  right: 40px;
  position: absolute;
  color: ${props => props.theme.primary};
  ${voice.bold};
`;

export default FirstSignin;

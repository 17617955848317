import React from "react";
import PropTypes from "prop-types";
import * as d3 from "d3";

// Utils
import {exists} from "../../utils/helpers";
import {isMobileDevice} from "../../utils/responsive";

// Components
import ChartPath from "./ChartPath";

const ChartLimit = ({...props}) => {
  const {
    chartId,
    xValues,
    yLocation,
    label,
    value,
    units,
    width,
    setTooltipData,
    setTooltipPosition,
    tooltipPosition,
    tooltips,
    color
  } = props;

  const line = d3
    .line()
    .curve(d3.curveCatmullRom)
    .x(i => xValues[i])
    .y(() => yLocation);
  const I = d3.range(xValues.length);

  const mouseover = () => {
    setTooltipData({
      label,
      y: `${value}`,
      units,
      color
    });
  };

  const mouseleave = () => {
    setTooltipPosition({top: null, left: null, right: null});
    setTooltipData({});
  };

  const mousemove = ({nativeEvent}) => {
    if (nativeEvent && exists(nativeEvent.offsetX) && exists(nativeEvent.offsetY))
      setTooltipPosition({
        top: `${nativeEvent.offsetY - 5}px`,
        left: null,
        right: `${width - nativeEvent.offsetX}px`
      });
    else setTooltipPosition({});
  };

  const onclick = ({nativeEvent}) => {
    if (tooltips && nativeEvent && isMobileDevice()) {
      if (tooltipPosition && tooltipPosition.top) mouseleave();
      else {
        mousemove({nativeEvent});
        mouseover();
      }
    } else setTooltipPosition({});
  };

  return (
    <ChartPath
      chartId={chartId}
      line={line(I)}
      onclick={tooltips ? onclick : null}
      mousemove={tooltips ? mousemove : null}
      mouseleave={tooltips ? mouseleave : null}
      mouseover={tooltips ? mouseover : null}
      strokeWidth={2.5}
      color={color}
    />
  );
};

ChartLimit.propTypes = {
  chartId: PropTypes.string.isRequired,
  xValues: PropTypes.arrayOf(PropTypes.any).isRequired,
  yLocation: PropTypes.number.isRequired,
  value: PropTypes.number,
  label: PropTypes.string,
  units: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.number.isRequired,
  setTooltipData: PropTypes.func,
  setTooltipPosition: PropTypes.func,
  tooltipPosition: PropTypes.objectOf(PropTypes.any),
  tooltips: PropTypes.bool
};

ChartLimit.defaultProps = {
  setTooltipData: () => {},
  setTooltipPosition: () => {},
  tooltipPosition: null,
  tooltips: false,
  value: null,
  label: null,
  color: "black",
  units: ""
};

export default ChartLimit;

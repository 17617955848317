import React, {useState, useContext, useEffect, useMemo} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckDouble} from "@fortawesome/free-solid-svg-icons";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Utils
import {AuthContext} from "../../contexts/auth.js";

// Components
import InputError from "./InputError.js";
import Help from "../Help.js";

// Style
import {voice} from "../../style/components/typography.js";
import {flex} from "../../style/components/mixins.js";
import {colors, pad} from "../../style/components/variables.js";
import {cross} from "../../style/components/shapes.js";
import {
  FormFieldWrapper,
  Label,
  Text,
  Loader,
  Button,
  Anchor,
  Pill,
  Abbr,
  Inline
} from "../../style/components/general.js";

const InputFile = ({name, prompt, label, condition, tag, required}) => {
  const isMounted = useMountedState();

  const {currentUser} = useContext(AuthContext);

  const [filesToUpload, setFilesToUpload] = useState(null);
  const [files, setFiles] = useState(null);
  const [savedFiles, setSavedFiles] = useState(null);

  const images = useMemo(
    () => savedFiles?.filter(savedFile => savedFile.extension?.name.includes("image")),
    [savedFiles]
  );

  const nonImages = useMemo(
    () => savedFiles?.filter(savedFile => !savedFile.extension?.name.includes("image")),
    [savedFiles]
  );

  const {api, loading} = useApi("files");

  const {
    setValue,
    trigger,
    watch,
    formState: {errors, submitCount}
  } = useFormContext();

  const uploaded = watch(name);

  const removeFile = idx => {
    setFiles(old => (old ? old.filter((_f, i) => i !== idx) : null));
    setSavedFiles(old => (old ? old.filter((_f, i) => i !== idx) : null));
    if (uploaded) {
      const fileIds = uploaded.split(",");
      setValue(name, fileIds.filter((_f, i) => i !== idx).join(","), {shouldDirty: true});
    }
  };

  useEffect(() => {
    if (isMounted() && !files && uploaded && uploaded !== "_CONDITION_UNSATISFIED") {
      api.callGet(null, {ids: uploaded.split(","), getSerialized: true}).then(({status, data}) => {
        if (status === 200 && data) {
          const {files: fileData, links} = data;

          if (fileData) {
            setSavedFiles(
              Object.keys(fileData)
                .filter(id => {
                  const fileObj = fileData[id];
                  const {fileName, extension} = fileObj || {};
                  const link = links[id];
                  return link && fileName && extension?.name;
                })
                .map(id => {
                  const fileObj = fileData[id];
                  const link = links[id];
                  return {...fileObj, link};
                })
            );
            setFiles(Object.keys(fileData).map(() => null));
          }
        }
      });
    }
  }, [isMounted, uploaded, files, api]);

  useEffect(() => {
    if (isMounted() && currentUser?.publicId && filesToUpload) {
      const formData = new FormData();
      for (let i = 0; i < filesToUpload.length; i++) {
        formData.append(
          `file-${i}`,
          new File([filesToUpload[i]], filesToUpload[i].name, {type: filesToUpload[i].type})
        );
      }
      api.callPost(formData, {params: {userId: currentUser.publicId}}).then(({status, data}) => {
        if (status === 201 && data?.data) {
          let filesReturned = data.data;
          if (filesReturned && !Array.isArray(filesReturned)) filesReturned = [filesReturned];

          setSavedFiles(prev => (prev ? [...prev, ...filesReturned] : [...filesReturned]));

          let fileIdStr = uploaded;
          filesReturned.map(fileObj => {
            const {id} = fileObj || {};
            if (id) {
              if (fileIdStr) fileIdStr = `${fileIdStr},${id}`;
              else fileIdStr = `${id}`;
            }
          });

          setValue(name, fileIdStr, {shouldDirty: true});

          if (submitCount > 0) trigger(name);
        }
      });
      setFilesToUpload(null);
    }
  }, [isMounted, api, currentUser, filesToUpload, name, setValue, uploaded, trigger, submitCount]);

  return (
    <FormFieldWrapper>
      {label && (
        <LabelWrapper htmlFor={name} bold inline>
          {label.toUpperCase()}
          {required && <span>*</span>}
          {tag && (
            <Pill quiet>
              <Abbr title={tag}>{tag}</Abbr>
            </Pill>
          )}
          {condition && <Help icon={<FontAwesomeIcon icon={faCheckDouble} />}>{condition}</Help>}
        </LabelWrapper>
      )}
      <FileWrapper>
        {prompt && <Prompt quiet>{prompt}</Prompt>}
        <Upload type="button" data-testid={`${name}_visibleUpload`}>
          <FileInputLabel htmlFor={`${name}_hiddenUpload`}>
            <span>Upload</span>
            <HiddenInput
              id={`${name}_hiddenUpload`}
              type="file"
              data-testid="file.upload"
              multiple
              onChange={({target}) => {
                const tempFiles = target?.files ? Object.values(target.files) : null;

                if (tempFiles) {
                  setFiles(prev => {
                    const temp = prev
                      ? [
                          ...prev,
                          ...tempFiles.map(file => ({...file, link: URL.createObjectURL(file)}))
                        ]
                      : [...tempFiles.map(file => ({...file, link: URL.createObjectURL(file)}))];

                    return temp;
                  });
                  setFilesToUpload(tempFiles);
                }
              }}
            />
          </FileInputLabel>
        </Upload>
      </FileWrapper>

      {uploaded && !loading && images?.length > 0 && (
        <FileRow>
          {images.map((savedFile, i) => {
            const file = files ? files[i] : null;
            if (!savedFile) return null;
            return (
              <Preview
                key={`${name}${savedFile.fileName}`}
                background={file ? file.link : savedFile?.link}
                data-testid="preview">
                <Delete
                  onClick={() => {
                    removeFile(i);
                  }}>
                  <X />
                </Delete>
              </Preview>
            );
          })}
        </FileRow>
      )}
      {uploaded && !loading && nonImages?.length > 0 && (
        <FileRow extraMargin={images?.length > 0 ? 1 : 0}>
          {savedFiles
            .filter(savedFile => !savedFile.extension?.name.includes("image"))
            .map((savedFile, i) => {
              const file = files ? files[i] : null;
              if (!savedFile) return null;
              return (
                <Anchor
                  key={`${name}${savedFile.fileName}`}
                  href={file ? file.link : savedFile?.link}
                  target="_blank"
                  rel="noreferrer">
                  {file?.name ? file.name : savedFile?.label}
                  <Delete
                    onClick={() => {
                      removeFile(i);
                    }}
                    noborder>
                    <X />
                  </Delete>
                </Anchor>
              );
            })}
        </FileRow>
      )}
      {loading && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
      <InputError errors={errors} name={name} />
    </FormFieldWrapper>
  );
};

InputFile.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  prompt: PropTypes.string,
  condition: PropTypes.string,
  tag: PropTypes.string,
  required: PropTypes.bool
};

InputFile.defaultProps = {
  label: null,
  prompt: null,
  condition: undefined,
  tag: undefined,
  required: false
};

// Style
const FileWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

const Prompt = styled(Text)`
  margin-bottom: ${pad / 2}px;
`;

const Upload = styled(Button)`
  margin-top: ${pad / 2}px;
`;

const FileInputLabel = styled.label`
  ${voice.normal};
  position: relative;

  svg {
    pointer-events: none;
    fill: ${({theme}) => theme.tertiary};
  }

  ${({disabled}) =>
    disabled &&
    css`
      &:hover {
        cursor: not-allowed;
      }
    `}
`;

const HiddenInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const Preview = styled.div`
  ${flex("row", "nowrap", "center", "center")}
  text-align: center;
  margin-top: ${pad}px;
  border: 2px solid ${({noborder, theme}) => (noborder ? "transparent" : theme.primary)};
  position: relative;
  width: 120px;
  height: 100px;
  padding-bottom: 0px;
  background-image: url(${({background}) => background});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: ${colors.grayLight};
`;

const LoaderContainer = styled.div`
  width: 200px;
  height: 60px;
  position: relative;
`;

const Delete = styled.div`
  background-color: ${({theme}) => theme.primary};
  border-radius: 50%;
  right: -10px;
  top: -10px;
  height: 20px;
  width: 20px;
  position: absolute;
  cursor: pointer;
`;

const X = styled.div`
  ${cross()}
  :before {
    height: 70%;
    top: 15%;
    width: 1.5px;
  }

  :after {
    height: 70%;
    top: 15%;
    width: 1.5px;
  }
`;

const FileRow = styled(Inline)`
  flex-wrap: wrap;

  ${({extraMargin}) =>
    extraMargin &&
    css`
      margin-top: ${pad * 2}px;
    `}
`;

const LabelWrapper = styled(Label)`
  gap: ${pad / 2}px;
  margin-bottom: 2px;
`;

export default InputFile;

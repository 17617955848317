import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";

// Contexts
import {useToast} from "../../contexts/toast.js";
import {FacilityNavContext} from "../../contexts/facilitynav.js";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Utils
import {getFields} from "../../utils/builder.js";
import {toTitleCase} from "../../utils/helpers.js";

// Components
import Modal from "../../components/Modal.js";
import Chart from "../../components/Chart.js";
import Dropdown from "../../components/Dropdown.js";
import SearchSelect from "../../components/SearchSelect.js";
import {
  InputCheckGroup,
  InputRadioGroup,
  InputSelect,
  InputText
} from "../../components/form/FormInputs.js";

// Style
import {pad} from "../../style/components/variables.js";
import {voice} from "../../style/components/typography.js";
import {flex, z} from "../../style/components/mixins.js";
import {bp, breakpoint} from "../../style/components/breakpoints.js";
import {
  Abbr,
  Button,
  ButtonLoader,
  Form,
  FormField,
  FormGroup,
  Heading,
  Label,
  Loader,
  NotLoaded,
  Pill,
  StickyWrapper,
  Text
} from "../../style/components/general.js";

const dropdownOptions = ["None", "Last 7 Days", "Last 30 Days", "Last 90 Days", "Last Year"];

const schema = {
  name: yup.string().nullable().required("Please provide a label."),
  sourceType: yup.string().nullable().required("Please provide a source type."),
  source: yup.object().nullable().required("Please provide a source."),
  sources: yup.lazy(val =>
    Array.isArray(val)
      ? yup
          .array()
          .of(yup.string())
          .min(1, "Please select a source")
          .required("Please select a source")
      : yup.string().nullable().required("Please select a source")
  ),
  chartType: yup.string().nullable().required("Please provide chart type.")
};

const defaultValues = {
  after: null,
  name: "",
  sourceType: "Checksheet",
  source: "",
  sources: null,
  chartType: "Line"
};

const ModalNewChart = ({visible, setVisible}) => {
  const isMounted = useMountedState();

  const {addToast} = useToast();

  const prevSources = useRef([]);

  const {analytics, setAnalytics, allOrder, facility} = useContext(FacilityNavContext);

  const [sourceOptions, setSourceOptions] = useState(null);
  const [sourcesOptions, setSourcesOptions] = useState(null);
  const [chart, setChart] = useState({});
  const [snapshot, setSnapshot] = useState(null);
  const [refreshSources, setRefreshSources] = useState(null);
  const [sourceResults, setSourceResults] = useState([]);
  // Loaders
  const [previewLoading, setPreviewLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);

  const form = useForm({
    defaultValues,
    resolver: yupResolver(
      yup.object().shape(
        {
          ...schema,
          after:
            analytics && analytics.length > 0
              ? yup
                  .number()
                  .transform((v, o) => (o === "" ? null : v))
                  .typeError("Please choose where to display the new chart.")
                  .required("Please choose where to display the new chart.")
              : yup.number().nullable()
        },
        [["sources", "sources"]]
      )
    )
  });
  const {handleSubmit, watch, reset, setValue} = form;

  const sourceType = watch("sourceType");
  const stage = watch("stage");
  const source = watch("source");
  const sources = watch("sources");
  const chartType = watch("chartType");
  const axes = sourcesOptions && sourcesOptions.length >= 2 ? watch("dual") : "1";

  const {api: apiChecksheets} = useApi("checksheets");
  const {api: apiChecksheetRecords} = useApi("checksheet-records");
  const {api: apiEvents} = useApi("events");
  const {api: apiEventRecords} = useApi("event-records");
  const {api: apiAnalytics} = useApi("facility-analytics", {suppress: {error: true}});

  // Initial Load
  useEffect(() => {
    if (isMounted() && facility) {
      // Start from inital values when selected
      setSourcesOptions(null);
      setChart({});
      setValue("source", null);
      setValue("sources", null);
      setValue("stage", null);

      const api = sourceType === "Checksheet" ? apiChecksheets : apiEvents;
      api.callGet(null, {facilityId: facility.id}).then(({status, data}) => {
        if (status === 200 && data) {
          setSourceOptions(
            data.map(checksheet => {
              const {name, frequency, group} = checksheet;
              let fullName = frequency?.name
                ? `${toTitleCase(frequency.name)} ${name}`
                : `${name} (optional)`;

              if (group && facility?.builder?.byId) {
                const groupName = facility.builder.byId[group]?.label;
                fullName = groupName ? `${fullName} at ${groupName}` : fullName;
              }

              return {
                ...checksheet,
                name: fullName
              };
            })
          );
          setRefreshSources(sourceType);
        }
      });
    }
  }, [isMounted, apiChecksheets, apiEvents, sourceType, setValue, facility]);

  // Set source on dropdown select
  useEffect(() => {
    if (isMounted() && source && sourceOptions) {
      setValue("sources", null);
      setValue("type", null);
      setValue("stage", null);
    }
  }, [isMounted, source, sourceOptions, setValue]);

  const stageOptions = useMemo(() => {
    if (!source?.stages?.allIds) return null;
    const {byId, allIds} = source.stages;
    return allIds
      .filter(stageId => byId[stageId])
      .map(stageId => {
        const {name} = byId[stageId];
        return {name: stageId, label: name};
      });
  }, [source]);

  const selectedStage = useMemo(() => {
    if (!stage || !source?.stages?.byId || !source?.stages?.byId[stage]) return null;
    const {byId} = source.stages;
    return byId[stage];
  }, [source, stage]);

  // Get current Checksheet's records to check if there are enough data values
  useEffect(() => {
    if ((sourceType !== "Event" || selectedStage) && source && source.id && refreshSources) {
      const api = refreshSources === "Checksheet" ? apiChecksheetRecords : apiEventRecords;
      const typeId = refreshSources === "Checksheet" ? "facilityChecksheetId" : "eventId";
      const params = {[typeId]: source.id};

      api.callGet(null, params).then(({status}) => {
        if (status === 200) {
          const {builder} = source;
          let fields;
          if (selectedStage?.builder) {
            const {allIds, byId} = selectedStage.builder;
            fields = getFields(allIds, byId, ["number", "generated", "range"]);
          } else if (builder)
            fields = getFields(builder.allIds, builder.byId, ["number", "generated", "range"]);

          if (fields) {
            const options = fields.map(({ancestry, name}) => ({
              label: ancestry,
              name
            }));

            setSourcesOptions(options);
          } else setSourcesOptions([]);
        }
      });
    }
  }, [
    source,
    refreshSources,
    apiChecksheetRecords,
    apiEventRecords,
    setSourcesOptions,
    selectedStage,
    sourceType
  ]);

  // Allow Multiple Sources
  useEffect(() => {
    if (
      axes === "2" &&
      sources &&
      sources.length > 2 &&
      prevSources.current &&
      prevSources.current.length === 2
    ) {
      addToast("Two sources maximum for two axis graph.");
      setValue("sources", prevSources.current);
    } else if (axes === "2" && Array.isArray(sources) && sources && sources.length > 2) {
      addToast("Two sources maximum for two axis graph.");
      setValue("sources", [sources[0], sources[1]]);
      prevSources.current = [sources[0], sources[1]];
    }
    if (sources) {
      prevSources.current = sources;
    }
  }, [axes, sources, setValue, addToast]);

  // Preview Chart
  useEffect(() => {
    if (
      isMounted() &&
      sourcesOptions &&
      refreshSources &&
      source &&
      sources &&
      sources.length > 0 &&
      !(axes === "2" && sources.length > 2)
    ) {
      setPreviewLoading(true);

      const typeId = refreshSources === "Checksheet" ? "facilityChecksheetId" : "eventId";
      const params = {
        preview: true, // returns data without saving
        [typeId]: source.id,
        type: "plot",
        sources: typeof sources === "string" ? [sources] : sources,
        snapshot: snapshot || "none",
        axes: axes ? parseInt(axes, 10) : 1
      };

      if (sourceType === "Event") params.stageId = stage;

      apiAnalytics
        .callPost(params)
        .then(({status, data}) => {
          if (status === 200 && data) setChart(data);
        })
        .finally(() => setPreviewLoading(false));
    }

    if ((sources && sources.length === 0) || (axes === "2" && sources && sources.length > 2))
      setChart({});
  }, [
    isMounted,
    apiAnalytics,
    sources,
    snapshot,
    axes,
    refreshSources,
    source,
    sourcesOptions,
    stage,
    sourceType
  ]);

  const addStat = data => {
    const formatted = sourcesOptions.length === 1 || chartType === "Bar" ? [sources] : sources;
    if (formatted) {
      setSubmitLoading(true);

      let updated = 1;
      const order = data.after;
      if (order) updated = order + 1;

      const typeId = sourceType === "Checksheet" ? "facilityChecksheetId" : "eventId";
      const params = {
        type: "plot",
        [typeId]: source.id,
        label: data.name,
        sources: formatted,
        order: updated,
        snapshot: snapshot || "none",
        axes: axes ? parseInt(axes, 10) || 1 : 1,
        chartType
      };

      if (sourceType === "Event") params.stageId = stage;

      apiAnalytics.callPost(params).then(() => {
        setAnalytics(null);
        setSubmitLoading(false);
        setVisible(false);
        reset({});
      });
    }
  };

  useEffect(() => {
    if (!visible) reset({defaultValues});
  }, [reset, visible, isMounted]);

  useEffect(() => {
    setValue("axes", "1");
    setValue("sources", []);
  }, [chartType, setValue]);

  const searchSources = query => {
    if (!query) setSourceResults(sourceOptions);
    else
      setSourceResults(
        sourceOptions.filter(s => s.name.toLowerCase().includes(query.toLowerCase()))
      );
  };

  return (
    <Modal visible={visible} setVisible={setVisible} maxWidth={breakpoint.width[5]}>
      <ModalTitle>Create Chart</ModalTitle>

      <StyledText>
        Create a chart from <strong>number</strong> fields on checksheet submissions.
      </StyledText>

      <FormProvider {...form}>
        <Form data-testid="chartForm" onSubmit={handleSubmit(addStat)} noValidate>
          <Row>
            <Left>
              <FormField>
                <Label htmlFor="name" bold>
                  Provide a Label for Chart.
                </Label>
                <InputText testId="plotLabel" name="name" placeholder="Label" />
                <InputRadioGroup
                  name="chartType"
                  label="Chart Type"
                  options={["Line", "Bar", "Scatter"]}
                />
              </FormField>
              {analytics?.length > 0 && (
                <FormField>
                  <InputSelect
                    name="after"
                    label="Display After"
                    placeholder="Select..."
                    options={[{label: "Top of Page", value: "0"}, ...allOrder]}
                    fullWidth
                  />
                </FormField>
              )}
              <FormField>
                <InputRadioGroup
                  name="sourceType"
                  options={["Checksheet", "Event"]}
                  defaultValue="Checksheet"
                  label="Select a source type for plot."
                />
              </FormField>
              {!sourceOptions ? (
                <NotLoaded>
                  <Loader />
                </NotLoaded>
              ) : (
                <FormField>
                  {!source ? (
                    <SearchSelect
                      label={`Select a${
                        sourceType === "Event" ? "n" : ""
                      } ${sourceType.toLowerCase()} as source for plot.`}
                      placeholder={`Select ${sourceType}...`}
                      results={sourceResults}
                      setResults={setSourceResults}
                      search={searchSources}
                      add={val => setValue("source", val)}
                      showAll
                    />
                  ) : (
                    <>
                      <Label bold>{`SELECT A${
                        sourceType === "event" ? "N" : ""
                      } ${sourceType.toLowerCase()} AS SOURCE FOR PLOT.`}</Label>
                      <SourceContainer data-testid="addField.units-selected">
                        <Abbr title={source.name}>{source.name}</Abbr>
                        &nbsp;
                        <IconButton
                          onClick={() => {
                            setValue("source", null);
                            setValue("sources", null);
                            setValue("stage", null);
                          }}>
                          <FontAwesomeIcon icon={faClose} />
                        </IconButton>
                      </SourceContainer>
                    </>
                  )}
                </FormField>
              )}

              {sourceType === "Event" && source && (
                <FormField>
                  <InputSelect name="stage" placeholder="Stage..." options={stageOptions} />
                </FormField>
              )}

              {(sourceType !== "Event" || !!stage) && (
                <FieldOptionsWrapper>
                  {source && refreshSources && sourcesOptions === null ? (
                    <NotLoaded>
                      <Loader />
                    </NotLoaded>
                  ) : (
                    <FormGroup>
                      {(chartType === "Line" || chartType === "Scatter") &&
                        sourcesOptions &&
                        sourcesOptions.length >= 2 && (
                          <FormField>
                            <InputRadioGroup
                              name="dual"
                              options={["1", "2"]}
                              defaultValue="1"
                              label="Indicate the desired number of dependent axes."
                            />
                          </FormField>
                        )}
                      {sourcesOptions && sourcesOptions.length > 0 ? (
                        <FormField>
                          <Label htmlFor="sources" bold>
                            {axes === "1" && chartType !== "Bar" && <>Select values to graph.</>}
                            {axes === "2" && chartType !== "Bar" && (
                              <>Select 2 values to graph, one for each axis.</>
                            )}
                            {chartType === "Bar" && <>Select value to graph.</>}
                          </Label>
                          {chartType === "Line" || chartType === "Scatter" ? (
                            <InputCheckGroup
                              testId="trackedValue"
                              name="sources"
                              options={sourcesOptions}
                            />
                          ) : (
                            <InputRadioGroup
                              testId="trackedValue"
                              name="sources"
                              options={sourcesOptions}
                            />
                          )}
                        </FormField>
                      ) : (
                        <None data-testid="noDataMessage">
                          {source && sourcesOptions && (
                            <span>
                              Selected source has no graphable data points. Checksheet must have a
                              number field.
                            </span>
                          )}
                        </None>
                      )}
                    </FormGroup>
                  )}
                </FieldOptionsWrapper>
              )}
            </Left>
            <StickyDiv>
              <Chart
                testId="plot"
                loading={previewLoading ? 1 : 0}
                label={watch("name")}
                chart={chart}
                view="builder"
                allSources={sourcesOptions ? sourcesOptions.map(option => option.name) : []}
                chartType={chartType}
              />
              {!(chart && chart.sources && chart.sources.length > 0) && (
                <StyledWarning>{chart.message}</StyledWarning>
              )}
              {source && sourcesOptions && sourcesOptions.length > 0 && (
                <Dropdown
                  id="snapshot"
                  testId="snapshotDropdown"
                  name="snapshot"
                  options={dropdownOptions}
                  placeholder="Optionally, select default date range..."
                  setSelection={setSnapshot}
                  selection={snapshot}
                  fullWidth
                  spaced
                />
              )}
            </StickyDiv>
          </Row>
          <Button type="submit" loading={submitLoading ? 1 : 0}>
            {chart.message ? "Create as Template" : "Create"}
            {submitLoading && <ButtonLoader />}
          </Button>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalNewChart.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired
};

// Style Overrides
const ModalTitle = styled(Heading)`
  margin: ${pad}px 0;
  text-align: center;
  font-weight: 700;
`;

const Row = styled.div`
  position: relative;
  width: 100%;

  ${bp(2)} {
    ${flex("row", "nowrap", "start", "start")};
  }
`;

const Left = styled.section`
  padding: ${pad}px;

  ${bp(2)} {
    width: 30%;
  }
`;

const StyledText = styled(Text)`
  margin: ${pad * 2}px 0;
  padding-left: ${pad}px;
  max-width: 700px;
`;

const StyledWarning = styled(Text)`
  margin: ${pad}px 0;
  color: red;
`;

const None = styled(Text)`
  margin: ${pad}px 0 ${pad * 2}px;
  ${({theme}) => css`
    color: ${theme.error};
  `}
`;

const StickyDiv = styled(StickyWrapper)`
  width: 100%;
  padding: ${pad}px;

  ${bp(2)} {
    width: 70%;
    left: 30%;
    top: ${pad * 4}px;
    z-index: ${z("above")};
  }
`;

const FieldOptionsWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const SourceContainer = styled(Pill)`
  width: min-content;
  height: min-content;
  max-width: 100%;
  margin: ${pad}px ${pad}px 0 0;
  color: ${({theme}) => theme.tertiary};
`;

const IconButton = styled(Button)`
  ${voice.quiet};
  background-color: transparent;
  width: min-content;
  padding: 0;

  svg {
    fill: ${({theme}) => theme.tertiary};
  }
`;

export default ModalNewChart;

import React, {useEffect} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {useFieldArray, useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

// Components
import InputNumber from "./InputNumber.js";
import InputSelect from "./InputSelect.js";

// Style
import {colors, pad} from "../../style/components/variables.js";
import {Button, Label} from "../../style/components/general.js";

const InputLimit = ({name, index, option, selected, testId}) => {
  const {watch, setValue} = useFormContext();

  const label = watch(`${name}.${index}.label`);

  useEffect(() => {
    if (label === "Regulatory") setValue(`${name}.${index}.color`, colors.red);
    if (label === "Design") setValue(`${name}.${index}.color`, colors.orange);
  }, [label, setValue, index, name]);

  return (
    <>
      <InputSelect
        name={`${name}.${index}.label`}
        placeholder="Select label..."
        defaultValue={option.label || null}
        testId={`${testId}-dropdown`}
        options={["Regulatory", "Design"].filter(n => !selected.includes(n) || n === option.label)}
        minWidth="150"
      />
      <InputNumber
        name={`${name}.${index}.value`}
        placeholder="Value"
        defaultValue={option.value || null}
        testId={`${testId}-number`}
      />
    </>
  );
};

InputLimit.propTypes = {
  name: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  option: PropTypes.objectOf(PropTypes.any).isRequired,
  selected: PropTypes.arrayOf(PropTypes.any).isRequired,
  testId: PropTypes.string
};

InputLimit.defaultProps = {
  testId: "limit"
};

const InputLimitGroup = ({name, label, limit, required, testId}) => {
  const {control, watch} = useFormContext();

  const {remove, insert} = useFieldArray({control, name});

  const watchedFields = watch(name);
  const currentlySelected = watchedFields ? watchedFields.map(field => field.label) : [];

  return (
    <>
      {label && (
        <Label htmlFor={name} bold>
          {label.toUpperCase()}
          {required && <span>&nbsp;*</span>}
        </Label>
      )}
      {watchedFields &&
        watchedFields.length > 0 &&
        watchedFields.map((option, index) => (
          <InlineFormField key={option.id || index}>
            <InputLimit
              name={name}
              index={index}
              option={option}
              selected={currentlySelected}
              testId={testId}
            />

            <Edit
              disable={index === 0 && watchedFields.length === 1}
              type="button"
              data-testid={`${testId}.removeInput`}
              onClick={() => remove(index)}>
              <FontAwesomeIcon icon={faMinus} />
            </Edit>
            <Edit
              disable={watchedFields.length === limit}
              type="button"
              data-testid={`${testId}.addInput`}
              onClick={() => {
                insert(index + 1, "");
              }}>
              <FontAwesomeIcon icon={faPlus} />
            </Edit>
          </InlineFormField>
        ))}
    </>
  );
};

InputLimitGroup.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  limit: PropTypes.number
};

InputLimitGroup.defaultProps = {
  testId: "input-limit-group",
  label: null,
  required: false,
  limit: 10
};

// Style Overrides
const InlineFormField = styled.div`
  display: flex;
  width: min-content;
  gap: ${pad}px;

  div {
    margin: 0;
  }
`;

const Edit = styled(Button)`
  ${({disable}) =>
    disable &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

export default InputLimitGroup;

import React, {createContext, useCallback, useState, useContext, useMemo} from "react";
import PropTypes from "prop-types";

// Components
import Banner from "../components/Banner.js";

// Utils
import useMountedState from "../hooks/useMountedState.js";

const BannerContext = createContext();

const BannerProvider = ({children}) => {
  const isMounted = useMountedState();

  const [banner, setBanner] = useState(null);

  /**
   * @param {string} message
   * @param {string} type
   * @returns {void}
   */
  const addBanner = useCallback(
    (message, type = "info", onDismiss = null) => {
      if (isMounted())
        setBanner({
          type,
          content: message,
          onDismiss
        });
    },
    [isMounted]
  );

  const removeBanner = useCallback(() => setBanner(null), []);

  const memoedValue = useMemo(
    () => ({
      addBanner,
      removeBanner,
      banner
    }),
    [addBanner, removeBanner, banner]
  );

  return (
    <BannerContext.Provider value={memoedValue}>
      <Banner banner={banner} removeBanner={removeBanner} />
      {children}
    </BannerContext.Provider>
  );
};

BannerProvider.propTypes = {
  children: PropTypes.node.isRequired
};

const useBanner = () => useContext(BannerContext);

export {BannerContext, useBanner};

export default BannerProvider;

import React from "react";
import styled from "styled-components";

// Style
import {voice} from "../../style/components/typography.js";
import {heroTheme, pad} from "../../style/components/variables.js";

const Footer = () => (
  <FooterWrapper>
    <InfoWrapper>
      <ExternalLink href="https://www.hero-services.com" target="_blank">
        Information
      </ExternalLink>
      <ExternalLink href="https://www.hero-services.com/terms-of-service" target="_blank">
        Terms
      </ExternalLink>
      <ExternalLink href="https://www.hero-services.com/privacy-policy" target="_blank">
        Privacy
      </ExternalLink>
    </InfoWrapper>
  </FooterWrapper>
);

// Style Overrides
const FooterWrapper = styled.nav`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: ${pad * 2}px;
  margin-top: ${pad}px;
  background: ${heroTheme.primary};
`;

const InfoWrapper = styled.p`
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin: 0 auto;
`;

const ExternalLink = styled.a`
  color: ${heroTheme.secondary};
  ${voice.quiet};
`;

export default Footer;

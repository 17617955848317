import React, {useState, useEffect, useContext} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";

// Contexts
import {AuthContext} from "../../contexts/auth.js";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Components
import Modal from "../../components/Modal.js";
import {
  InputText,
  InputSelect,
  InputCheck,
  InputPhoneGroup
} from "../../components/form/FormInputs.js";
import SearchSelect from "../../components/SearchSelect.js";

// Style
import {pad} from "../../style/components/variables.js";
import {
  HeadingCenter,
  FormGroup,
  ButtonFull,
  Form,
  FormField,
  ButtonLoader,
  Loader,
  Pill,
  Button,
  Abbr,
  Label
} from "../../style/components/general.js";
import voice from "../../style/components/typography.js";

const ModalUserEdit = ({visible, setVisible, selected, updateTable, hasBackButton, goBack}) => {
  const isMounted = useMountedState();

  const {currentUser, types, roles, departments} = useContext(AuthContext);

  const [loading, setLoading] = useState(false);
  const [userTypes, setUserTypes] = useState(types);
  const [companies, setCompanies] = useState([]);
  const [companyResults, setCompanyResults] = useState([]);

  const {api: apiUser} = useApi("users");
  const {api: apiCompany} = useApi("companies");

  const schema = yup.object().shape({
    firstName: yup.string().required("Please provide first name."),
    lastName: yup.string().required("Please provide last name."),
    email: yup.string().email("Invalid email address").required("Please provide email."),
    phones: yup.array().of(
      yup.object().shape({
        id: yup.string(),
        number: yup
          .string()
          .nullable()
          .matches(/^\(\d{3}\)\s\d{3}-\d{4}/i, {
            message: "Please provide valid phone #.",
            excludeEmptyString: true
          }),
        extension: yup.string().nullable(),
        type: yup.string().required("Type is required."),
        primary: yup.bool()
      })
    ),
    department: yup
      .mixed()
      .nullable()
      .when("isActive", {
        is: val => !!val,
        then: () => yup.string().nullable().required("Please provide department."),
        else: () => yup.mixed().nullable()
      }),
    userRoleId: yup
      .mixed()
      .nullable()
      .when("isActive", {
        is: val => roles && roles.length > 0 && !!val,
        then: () => yup.string().nullable().required("Please provide user role."),
        else: () => yup.mixed().nullable()
      }),
    userTypeId: yup
      .mixed()
      .nullable()
      .when(["userRoleId", "isActive"], {
        is: (userRoleId, active) =>
          (!roles || roles.length === 0 || `${userRoleId}` === "-1") && active,
        then: () => yup.string().nullable().required("Please provide user type."),
        else: () => yup.mixed().nullable()
      }),
    company: yup.string().nullable(),
    isActive: yup.bool()
  });

  const form = useForm({
    defaultValues: {
      firstName: selected.firstName?.props?.children[0] || selected.firstName,
      lastName: selected.firstName?.props?.children[2] || selected.lastName,
      email: selected.email?.props?.children || selected.email,
      phones:
        selected.phones && selected.phones.length > 0
          ? selected.phones
          : [
              {
                id: "",
                number: "",
                extension: "",
                type: "mobile",
                primary: false
              }
            ],
      userTypeId:
        (selected.isInvited && !selected.isAccepted) || selected.isActive
          ? selected.type?.id
          : null,
      userRoleId: selected.role?.id || -1,
      department: selected.department,
      company: selected.company ? selected.company.name : null,
      isActive: (selected.isInvited && !selected.isAccepted) || selected.isActive
    },
    resolver: yupResolver(schema)
  });
  const {
    watch,
    handleSubmit,
    setValue,
    formState: {submitCount}
  } = form;

  const isActive = watch("isActive");
  const role = watch("userRoleId");
  const company = watch("company");

  useEffect(() => {
    if (isMounted() && types) {
      const filtered = types.filter(userType => userType.id <= currentUser.type.id);
      setUserTypes(filtered);
    }
  }, [isMounted, types, currentUser]);

  useEffect(() => {
    if (isMounted()) {
      apiCompany.callGet().then(({data, status}) => {
        if (status === 200 && data) setCompanies(data);
      });
    }
  }, [apiCompany, isMounted]);

  const searchCompanies = query => {
    if (query)
      setCompanyResults(
        companies
          .filter(c => c.name.toLowerCase().includes(query.toLowerCase()))
          .map(c => ({name: c.id, label: c.name}))
      );
    else setCompanyResults(companies.map(c => ({name: c.id, label: c.name})));
  };

  const editUser = values => {
    setLoading(true);
    const {firstName, lastName, phones} = values;

    const user = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      phones: null,
      ...values
    };

    if (phones?.length > 0 && phones[0].number) user["phones"] = phones;

    apiUser.callPut(selected.publicId, user).then(({status}) => {
      if (status === 200) {
        setVisible(false);
        updateTable();
        setLoading(false);
      }
    });
  };

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      maxWidth="570px"
      hasBackButton={hasBackButton}
      goBack={goBack}>
      <ModalTitle>
        Update {selected.firstName} {selected.lastName}&apos;s Account Details
      </ModalTitle>
      {departments.length > 0 && selected ? (
        <FormProvider {...form}>
          <Form onSubmit={handleSubmit(editUser)} noValidate>
            <FormGroup>
              <FormField>
                <InputText name="firstName" label="First Name" required />
              </FormField>
              <FormField>
                <InputText name="lastName" label="Last Name" required />
              </FormField>
              <FormField>
                <InputText name="email" label="Email" required />
              </FormField>
              <FormField>
                <InputPhoneGroup name="phones" label="Phone(s)" limit={3} />
              </FormField>

              <FormField>
                {company ? (
                  <>
                    <Label bold>COMPANY</Label>
                    <SelectedContainer data-testid="addField.tag-selected">
                      <Abbr>{company}</Abbr>
                      &nbsp;
                      <IconButton
                        onClick={() => setValue("company", null, {shouldValidate: !!submitCount})}>
                        <FontAwesomeIcon icon={faClose} />
                      </IconButton>
                    </SelectedContainer>
                  </>
                ) : (
                  <SearchSelect
                    label="Company"
                    results={companyResults}
                    setResults={setCompanyResults}
                    search={searchCompanies}
                    add={s => {
                      const selectedName = typeof s === "string" ? s : s.label;

                      setValue("company", selectedName, {shouldValidate: !!submitCount});
                    }}
                    showAll
                    allowNew
                  />
                )}
              </FormField>

              <FormField>
                <InputCheck name="isActive" label="Activate or Disable Account" active={isActive}>
                  <AccountStatus active={isActive}>
                    {isActive ? "Account Active" : "Account Inactive!"}
                  </AccountStatus>
                </InputCheck>
              </FormField>

              {(selected.isInvited || isActive) && (
                <>
                  <FormField>
                    <InputSelect
                      name="department"
                      label="Department"
                      options={departments.map(department => department.name)}
                    />
                  </FormField>
                  {roles?.length > 0 && (
                    <FormField>
                      <InputSelect
                        name="userRoleId"
                        label="Current Role"
                        placeholder="Role..."
                        options={[
                          {name: -1, label: "Assign Later"},
                          ...roles.map(({id, label}) => ({
                            name: id,
                            label
                          }))
                        ]}
                      />
                    </FormField>
                  )}
                  {(!roles || roles.length === 0 || `${role}` === "-1") && (
                    <FormField>
                      <InputSelect
                        name="userTypeId"
                        label={
                          `${role}` === "-1"
                            ? "User Type (must be provided when role is omitted)."
                            : "User Type"
                        }
                        placeholder="User Type..."
                        options={userTypes.map(({id, name}) => ({
                          name: id,
                          label: name.toUpperCase()
                        }))}
                      />
                    </FormField>
                  )}
                </>
              )}

              {!(selected.isInvited || isActive) && roles?.length > 0 && (
                <FormField>
                  <InputSelect
                    name="userRoleId"
                    label="Role"
                    placeholder="Role..."
                    options={[
                      {name: -1, label: "Assign Later"},
                      ...roles
                        .filter(({type: {name}}) => name === "guest")
                        .map(({id, label}) => ({
                          name: id,
                          label
                        }))
                    ]}
                  />
                </FormField>
              )}
            </FormGroup>
            <ButtonFull type="submit" loading={loading ? 1 : 0}>
              Update User{loading && <ButtonLoader />}
            </ButtonFull>
          </Form>
        </FormProvider>
      ) : (
        <Loader />
      )}
    </Modal>
  );
};

ModalUserEdit.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  updateTable: PropTypes.func.isRequired,
  selected: PropTypes.objectOf(PropTypes.any),
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func
};

ModalUserEdit.defaultProps = {
  selected: null,
  hasBackButton: false,
  goBack: null
};

// Style Overrides
const ModalTitle = styled(HeadingCenter)`
  margin: ${pad}px 0;
`;

const AccountStatus = styled.span`
  ${({active, theme}) =>
    !active &&
    css`
      color: ${theme.error};
    `}
`;

const SelectedContainer = styled(Pill)`
  width: min-content;
  height: min-content;
  max-width: 100%;
  margin: ${pad}px ${pad}px 0 0;
  color: ${({theme}) => theme.tertiary};
`;

const IconButton = styled(Button)`
  ${voice.quiet};
  background-color: transparent;
  width: min-content;
  padding: 0;

  svg {
    fill: ${({theme}) => theme.tertiary};
  }
`;

export default ModalUserEdit;

import {useState, useEffect} from "react";

// Utils
import useMountedState from "./useMountedState";

const useScrollListener = element => {
  const isMounted = useMountedState();

  const [scrolling, setScrolling] = useState(0);

  useEffect(() => {
    const scrollStart = () => setScrolling(prev => prev + 1);
    const scrollEnd = () => setScrolling(0);

    const scrollContainer = element || window;

    if (isMounted()) {
      scrollContainer.addEventListener("scroll", scrollStart);
      scrollContainer.addEventListener("scrollend", scrollEnd);
    }

    return () => {
      if (!isMounted()) scrollContainer.removeEventListener("scroll", scrollStart);
      if (!isMounted()) scrollContainer.removeEventListener("scrollend", scrollEnd);
    };
  }, [isMounted, element]);

  return scrolling;
};

export default useScrollListener;

import React, {useCallback, useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";

// Utils
import useMountedState from "../hooks/useMountedState.js";

// Components
import LineChart from "./ChartLine.js";
import BarChart from "./ChartBar.js";

// Style
import {border, pad, radius, heroTheme} from "../style/components/variables.js";

const Chart = ({
  testId,
  chart,
  label,
  allSources,
  view,
  printScale,
  hiddenLimits,
  hideTooltips,
  active,
  setActive,
  chartType
}) => {
  const isMounted = useMountedState();

  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);

  const chartWrapper = useRef(null);

  const getHeightMultiplier = useCallback(() => {
    if (view === "builder") return 0.47;
    if (view === "preview" || view === "preview-print") return 0.67;
    return 0.3;
  }, [view]);

  // Resize Chart
  useEffect(() => {
    let resize;
    if (isMounted()) {
      let resizeTimer;
      const hm = getHeightMultiplier();
      resize = new ResizeObserver(entries => {
        const rect = entries[0].contentRect;

        // current width & height
        const {width: w} = rect;

        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(() => {
          setWidth(printScale ? printScale.width ?? printScale.height : w);
          setHeight(
            printScale
              ? printScale.height ?? printScale.width
              : Math.max(window.innerHeight * hm, 200)
          );
        }, 10);
      });

      // start observing for resize
      if (chartWrapper.current) {
        resize.observe(chartWrapper.current);
        setWidth(
          printScale ? printScale.width ?? printScale.height : chartWrapper.current.clientWidth
        );
        setHeight(
          printScale
            ? printScale.height ?? printScale.width
            : Math.max(window.innerHeight * hm, 200)
        );
      }
    }

    const memoizedRef = chartWrapper.current;

    return () => {
      if (!isMounted() && resize && memoizedRef) resize.unobserve(memoizedRef);
    };
  }, [isMounted, chart.id, getHeightMultiplier, view, printScale, chartWrapper]);

  return (
    <Wrapper
      data-testid={testId}
      id={`container-${chart.id ?? "new"}-${view}`}
      view={view}
      ref={chartWrapper}
      // must add 50 to printscale value to provide room for label and padding
      print={printScale ? 1 : 0}>
      <ChartLabel title={!label || label === "" ? "Chart" : label} data-testid="chartHeader">
        <p>{!label || label === "" ? "Chart" : label}</p>
      </ChartLabel>
      {(!chartType || chartType === "Line" || chartType === "Scatter") && height && width && (
        <LineChart
          dataset={chart.values}
          sources={chart.sources}
          title={!label || label === "" ? "Chart" : label}
          xDomain={chart.domain || [undefined, undefined]}
          yDomain={
            !chart.axes || chart.axes === 1 || (chart.sources && chart.sources.length === 1)
              ? chart.range
              : undefined
          }
          ranges={
            chart.axes === 2 && chart.sources && chart.sources.length === 2
              ? chart.ranges
              : undefined
          }
          noVals={chart.noVals}
          width={width}
          height={height}
          labels={chart.labels}
          fullLabels={chart.fullLabels}
          units={chart.units}
          allSources={allSources}
          chartId={`container-${chart.id ?? "new"}-${view}`}
          allowZoom={view !== "analytics"}
          tooltips={view !== "analytics" && !hideTooltips}
          acceptable={chart.acceptable}
          limits={chart.limits ? chart.limits.filter(([l]) => !hiddenLimits.includes(l)) : []}
          limitColors={chart.limitColors}
          infinite={chart.infinite}
          infiniteSources={chart.infiniteSources}
          active={active}
          setActive={setActive}
          axes={
            !chart.axes || chart.axes === 1 || (chart.sources && chart.sources.length === 1) ? 1 : 2
          }
          isScatter={chartType === "Scatter"}
        />
      )}
      {chartType === "Bar" && height && width && (
        <BarChart
          dataset={chart.values}
          sources={chart.sources}
          title={!label || label === "" ? "Chart" : label}
          xDomain={chart.domain || [undefined, undefined]}
          yDomain={
            !chart.axes || chart.axes === 1 || (chart.sources && chart.sources.length === 1)
              ? chart.range
              : undefined
          }
          ranges={
            chart.axes === 2 && chart.sources && chart.sources.length === 2
              ? chart.ranges
              : undefined
          }
          noVals={chart.noVals}
          width={width}
          height={height}
          labels={chart.labels}
          fullLabels={chart.fullLabels}
          units={chart.units}
          allSources={allSources}
          chartId={`container-${chart.id ?? "new"}-${view}`}
          allowZoom={view !== "analytics"}
          tooltips={view !== "analytics" && !hideTooltips}
          acceptable={chart.acceptable}
          limits={chart.limits ? chart.limits.filter(([l]) => !hiddenLimits.includes(l)) : []}
          limitColors={chart.limitColors}
          infinite={chart.infinite}
          infiniteSources={chart.infiniteSources}
          active={active}
          setActive={setActive}
          axes={
            !chart.axes || chart.axes === 1 || (chart.sources && chart.sources.length === 1) ? 1 : 2
          }
        />
      )}
    </Wrapper>
  );
};

Chart.propTypes = {
  testId: PropTypes.string,
  label: PropTypes.string,
  chart: PropTypes.objectOf(PropTypes.any),
  allSources: PropTypes.arrayOf(PropTypes.string),
  view: PropTypes.string,
  printScale: PropTypes.objectOf(PropTypes.any),
  hiddenLimits: PropTypes.arrayOf(PropTypes.string),
  hideTooltips: PropTypes.bool,
  active: PropTypes.objectOf(PropTypes.any),
  setActive: PropTypes.func,
  chartType: PropTypes.string
};

Chart.defaultProps = {
  testId: null,
  label: "Chart",
  chart: {},
  allSources: [],
  view: "preview",
  printScale: null,
  hiddenLimits: [],
  hideTooltips: false,
  active: null,
  setActive: null,
  chartType: "line"
};

// Style Overrides
const Wrapper = styled.div`
  position: relative;
  padding: ${pad}px;
  width: 100%;
  height: ${props => {
    if (props.view === "builder") return 55;
    if (props.view === "preview" || props.view === "preview-print") return 75;
    return 38;
  }}vh;
  min-height: 250px;
  border: ${border} solid ${props => props.theme.secondary};
  border-radius: ${radius};
  background: ${heroTheme.tertiary};
  margin: 0;

  ${props =>
    props.view === "analytics"
      ? css`
          pointer-events: none;
        `
      : ""}

  ${props =>
    props.print
      ? css`
          height: min-content;
        `
      : ""}
`;

const ChartLabel = styled.abbr`
  text-decoration: none;
  p {
    font-size: 17px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
  }
`;

export default Chart;

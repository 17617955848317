import React from "react";
import {createPortal} from "react-dom";
import {useTransition} from "react-spring";
import styled from "styled-components";

// Components
import Toast from "./Toast.js";

const ToastList = ({toasts, pauseToastOutput, removeToast}) => {
  const transitions = useTransition(toasts, {
    from: {right: "-100%"},
    enter: {right: "0%"},
    leave: {right: "-100%"}
  });

  return createPortal(
    <Wrapper>
      {transitions(
        (styles, item, key) =>
          item && (
            <Toast
              animationKey={key}
              item={item}
              styles={styles}
              pauseToastOutput={pauseToastOutput}
              removeToast={removeToast}
            />
          )
      )}
    </Wrapper>,
    document.body
  );
};

// Style Overrides
const Wrapper = styled.div`
  position: fixed;
  right: 0;
  bottom: 5%;
  z-index: 9999;
`;

export default ToastList;

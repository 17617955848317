import {useState, createContext, useMemo, useLayoutEffect} from "react";
import PropTypes from "prop-types";
import {ThemeProvider} from "styled-components";

// Style
import {dark, light} from "../style/components/variables";

export const ThemeStateContext = createContext();

const ThemeStateProvider = ({children}) => {
  const [theme, setTheme] = useState(light);

  useLayoutEffect(() => {
    const {localStorage: stg} = window;
    const currentTheme = stg.getItem("theme") || "light";

    if (currentTheme === "dark" && theme?.name === "light") setTheme(dark);
  }, [theme, setTheme]);

  // Make the provider update only when it should.
  // We only want to force re-renders on dependencies
  const memoedValue = useMemo(
    () => ({
      theme,
      setTheme
    }),
    [theme]
  );

  return (
    <ThemeStateContext.Provider value={memoedValue}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeStateContext.Provider>
  );
};

ThemeStateProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default ThemeStateProvider;

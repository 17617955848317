// Direction Variables
const directions = {
  right: "-90deg",
  left: "90deg",
  up: "180deg",
  down: "0deg"
};

/**
 * @param {number} length
 * @param {string} color as hex
 * @param {string} direction [right, left, up, down]
 * @param {string} selector [before, after]
 * @returns
 */
export const triangle = (length, color, direction, selector = "before") => `
  &:${selector} {
    content: '';
    width: 0; 
    height: 0; 
    border-left: ${length}px solid transparent;
    border-right: ${length}px solid transparent;
    border-top: ${length}px solid ${color};
    transform: rotate(${directions[direction] || direction});
  }
`;

/**
 * @param {number} radius
 * @param {string} color as hex
 * @param {string} selector [before, after]
 * @returns
 */
export const circle = (radius, color, selector = "before") => `
  &:${selector} {
    content: '';
    display: inline-block;
    width: ${radius}px; 
    height: ${radius}px;
    background: ${color};
    border-radius: 50%;
  }
`;

/**
 * @param {number} length
 * @param {number} width
 * @param {string} color as hex
 * @param {string} direction [right, left, up, down]
 * @param {string} selector [before, after]
 * @returns
 */
export const square = (length, width, color, direction, selector = "before") => `
  &:${selector} {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: ${width}; 
    height: ${length}; 
    background: ${color};
    transform: rotate(${directions[direction] || direction});
  }
`;

/**
 * @param {number} size
 * @returns
 */
export const cross = (size = "100%", color = "#000", weight = "2px") => `
  ${square(size, weight, color, "45deg")}
  ${square(size, weight, color, "-45deg", "after")}
`;

import React, {useContext, useState, useEffect, useCallback, useRef} from "react";
import {useParams} from "react-router";
import styled from "styled-components";

// Hooks
import useApi from "../hooks/useApi.js";
import useMountedState from "../hooks/useMountedState.js";

// Contexts
import {FacilityNavContext} from "../contexts/facilitynav.js";
import {AuthContext} from "../contexts/auth.js";
import {NotificationContext} from "../contexts/notify.js";
import {useSocket} from "../contexts/socket.js";

// Components
import Room, {ROOM_JOINED} from "./general/Room.js";
import FacilityPageHeader from "./general/FacilityPageHeader.js";
import ChecksheetsDue from "./tasks/ChecksheetsDue.js";
import EventsDue from "./tasks/EventsDue.js";

// Style
import {pad, radius, shadow} from "../style/components/variables.js";
import {flex} from "../style/components/mixins.js";
import {Button, Heading, Loader, Page, Text} from "../style/components/general.js";
import {shake} from "../style/components/animations.js";

const Tasks = () => {
  const isMounted = useMountedState();

  const socket = useSocket();

  const {facility, setFacility} = useContext(FacilityNavContext);
  const {roleCanAccessResource} = useContext(AuthContext);
  const {getNotifications} = useContext(NotificationContext);

  const {slug} = useParams();

  const [active, setActive] = useState(null);
  const [canRequestLocks, setCanRequestLocks] = useState(false);
  const [checksheetHasUpdates, setChecksheetHasUpdates] = useState(false);
  const [eventHasUpdates, setEventHasUpdates] = useState(false);

  const refreshingNotificationsChecksheet = useRef(false);

  const {api, loading} = useApi("facilities");

  const getFacility = useCallback(
    () =>
      api.callGet(slug).then(({status, data}) => {
        if (status === 200 && data) setFacility(data);
      }),
    [api, setFacility, slug]
  );

  const roomJoined = useCallback(() => {
    setCanRequestLocks(true);
  }, []);

  // Initial Load
  useEffect(() => {
    if (facility === null) {
      if (canRequestLocks) setCanRequestLocks(false);
      getFacility();
    }
  }, [facility, getFacility, canRequestLocks]);

  useEffect(() => {
    socket.on(ROOM_JOINED, roomJoined);

    return () => {
      // unbind all event handlers used in this component
      socket.off(ROOM_JOINED, roomJoined);
    };
  }, [isMounted, roomJoined, socket]);

  return (
    <>
      <Page hasMenu>
        <Room name="tasks" active={active} setActive={setActive} />

        {facility?.name && (
          <FacilityPageHeader
            facility={facility}
            reloadData={() => {
              setFacility(null);
              setCanRequestLocks(false);
            }}
            path="/tasks"
          />
        )}

        {!loading && facility?.id ? (
          <Wrapper>
            {roleCanAccessResource("facility_checksheet_record", "create") ||
            roleCanAccessResource("event_record", "create") ? (
              <>
                <EventsDue
                  room={`${slug}-tasks`}
                  facilityId={facility.id}
                  canRequestLocks={canRequestLocks}
                  setHasUpdates={setEventHasUpdates}
                />
                <ChecksheetsDue
                  room={`${slug}-tasks`}
                  facilityId={facility.id}
                  canRequestLocks={canRequestLocks}
                  setHasUpdates={setChecksheetHasUpdates}
                  refreshingNotifications={refreshingNotificationsChecksheet}
                />
              </>
            ) : (
              <div>
                <Heading>Not Authorized</Heading>
                <Text>Your role is not authorized to complete tasks.</Text>
              </div>
            )}
          </Wrapper>
        ) : (
          <Loader />
        )}
      </Page>
      {(checksheetHasUpdates || eventHasUpdates) && (
        <ModificationBanner>
          <ModificationText inverted quiet>
            Tasks have changed since your last refresh.&nbsp;
            <Button
              inText
              onClick={() => {
                refreshingNotificationsChecksheet.current = true;
                getNotifications(slug);
                setChecksheetHasUpdates(false);
                setEventHasUpdates(false);
              }}>
              <ButtonText inverted quiet>
                Load New Changes!
              </ButtonText>
            </Button>
          </ModificationText>
        </ModificationBanner>
      )}
    </>
  );
};

// Style Overrides
const Wrapper = styled.div`
  ${flex("column", "nowrap", "start")};
  gap: ${pad * 3}px;
  height: 100%;
`;

const ModificationBanner = styled.div`
  ${flex("column", "nowrap", "start", "center")}
  width: 100%;
  position: sticky;
  bottom: 15px;
`;

const ModificationText = styled(Text)`
  background-color: ${({theme}) => theme.secondary};
  opacity: 0.92;
  padding: ${pad}px;
  border-radius: ${radius};
  box-shadow: ${shadow};
`;

const ButtonText = styled(Text)`
  text-decoration: underline;
  animation: ${shake()} 5s linear 2s infinite;

  &:hover {
    animation-play-state: paused;
  }
`;

export default Tasks;

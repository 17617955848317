import React, {useRef, useEffect} from "react";
import {animated} from "react-spring";
import PropTypes from "prop-types";
import styled from "styled-components";

// Style
import {voice} from "../style/components/typography.js";
import {cross} from "../style/components/shapes.js";
import {radius, pad, shadow, colors} from "../style/components/variables.js";

const Toast = ({animationKey, item, styles, pauseToastOutput, removeToast}) => {
  const toastArea = useRef();

  useEffect(() => {
    const handleHover = () => pauseToastOutput();

    if (toastArea) toastArea.current.addEventListener("mouseenter", handleHover);

    return () => document.removeEventListener("mouseenter", handleHover);
  }, [pauseToastOutput]);

  useEffect(() => {
    // reset pause immediately (0 evaluates as false)
    const handleHover = () => pauseToastOutput(0.1);

    if (toastArea) toastArea.current.addEventListener("mouseleave", handleHover);

    return () => document.removeEventListener("mouseleave", handleHover);
  }, [pauseToastOutput]);

  return (
    <ToastWrapper ref={toastArea} key={animationKey} id={item.id} style={styles} type={item.type}>
      <Close type="button" onClick={() => removeToast(item.id)} />
      {item.content}
    </ToastWrapper>
  );
};

Toast.propTypes = {
  animationKey: PropTypes.objectOf(PropTypes.any).isRequired,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    content: PropTypes.node.isRequired
  }).isRequired,
  styles: PropTypes.objectOf(PropTypes.any),
  pauseToastOutput: PropTypes.func.isRequired,
  removeToast: PropTypes.func.isRequired
};

Toast.defaultProps = {
  styles: null
};

// Style Overrides
const ToastWrapper = styled(animated.div)`
  ${voice.normal};
  margin-right: 16px;
  margin-top: 16px;
  width: inherit;
  min-width: 200px;
  max-width: 250px;
  position: relative;
  padding: ${pad}px;
  border-radius: ${radius};
  box-shadow: ${shadow};
  color: ${colors.heroWhite};
  background-color: ${props =>
    props.theme[props.type] ? props.theme[props.type] : props.theme.secondary};
`;

const Close = styled.button`
  ${cross("10px", "rgba(255,255,255,0.6)")};
  position: absolute;
  top: 0;
  right: 0;
  width: min-content;
  padding: ${pad}px;
  margin: ${pad / 2}px 0;
`;

export default Toast;

import React, {useState, useEffect} from "react";
import {useForm, FormProvider} from "react-hook-form";
import PropTypes from "prop-types";

// Hooks
import useApi from "../../hooks/useApi.js";

// Utils
import {FIELD_TYPES} from "../../utils/builder.js";

// Components
import Modal from "../../components/Modal.js";
import InputCheckGroup from "../../components/form/InputCheckGroup.js";

// Style
import {HeadingCenter, Button, Form, FormField, Inline} from "../../style/components/general.js";

const ModalFilter = ({visible, setVisible, filters, setFilters, hasBackButton, goBack}) => {
  const {api: apiFieldTags} = useApi("field-tags");

  const [fieldTags, setFieldTags] = useState();

  const form = useForm({
    defaultValues: {...filters}
  });

  const {handleSubmit} = form;

  useEffect(() => {
    if (!fieldTags)
      apiFieldTags.callGet().then(({status, data}) => {
        if (status === 200) setFieldTags(data);
      });
  });

  const applyFilters = ({types, tags: selectedTags}) => {
    const updated = {};

    if (types?.length > 0) {
      if (typeof types === "string") updated.types = [types];
      else updated.types = types;
    }

    if (selectedTags?.length > 0) {
      if (typeof selectedTags === "string") updated.tags = [selectedTags];
      else updated.tags = selectedTags;
    }

    setFilters(updated);
    setVisible(false);
  };

  return (
    <Modal visible={visible} setVisible={setVisible} hasBackButton={hasBackButton} goBack={goBack}>
      <HeadingCenter>Checksheet Filters</HeadingCenter>
      <Form onSubmit={handleSubmit(applyFilters)}>
        <FormProvider {...form}>
          {fieldTags?.length > 0 && (
            <FormField>
              <InputCheckGroup name="tags" label="Tags" options={fieldTags} />
            </FormField>
          )}
          <FormField>
            <InputCheckGroup name="types" label="Types" options={FIELD_TYPES} />
          </FormField>
          <Inline>
            <Button type="submit">Apply</Button>
            <Button
              type="button"
              onClick={() => {
                setFilters(null);
                setVisible(false);
              }}>
              Clear
            </Button>
          </Inline>
        </FormProvider>
      </Form>
    </Modal>
  );
};

ModalFilter.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  tags: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
  filters: PropTypes.objectOf(PropTypes.any),
  setFilters: PropTypes.func.isRequired,
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func
};

ModalFilter.defaultProps = {
  tags: [],
  filters: null,
  hasBackButton: false,
  goBack: null
};

export default ModalFilter;

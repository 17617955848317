import React, {useContext, useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Contexts
import {AuthContext} from "../../contexts/auth.js";
import {SettingsContext} from "../../contexts/settings.js";

// Hooks
import useMountedState from "../../hooks/useMountedState.js";

// Utils
import {formatPhone, prettyDateInUserTimezone} from "../../utils/helpers.js";

// Components
import Modal from "../../components/Modal.js";
import ModalFileView from "../facility/ModalFileView.js";

// Style
import {pad} from "../../style/components/variables.js";
import {voice} from "../../style/components/typography.js";
import {Heading, Text} from "../../style/components/general.js";

const ModalRowPreview = ({visible, setVisible, selected, setSelected, hasBackButton, goBack}) => {
  const {name, date, message, files, user: createdBy, createdAt, updatedBy, updatedAt} = selected;

  const isMounted = useMountedState();

  const {isRole} = useContext(AuthContext);

  const {settings} = useContext(SettingsContext);
  const {timezone} = settings;

  const [fileViewVisible, setFileViewVisible] = useState(!message && files?.length === 1);
  const [selectedFile, setSelectedFile] = useState(
    !message && files.length === 1 ? files[0] : null
  );

  useEffect(
    () => () => {
      if (!isMounted()) setSelected(null);
    },
    [isMounted, setSelected]
  );

  const replaceEmail = m => m.replace("mail=", "").replace(";", "");
  const replaceUrl = m => m.replace("url=", "").replace(";", "");
  const replaceName = m => m.replace("name=", "").replace(";", "");

  const formattedMessage = useMemo(() => {
    if (!message) return "";

    let formatted = message.replace(
      /(tel=\d{10};)(name=[a-zA-Z\-0-9].*?)?/g,
      (_m, p, n) => `<a href='tel:${formatPhone(p)}'>${n ? replaceName(n) : formatPhone(p)}</a>`
    );

    formatted = formatted.replace(
      /(mail=[a-zA-Z\-0-9].*?;)(name=[a-zA-Z\-0-9].*?;)?/g,
      (_m, e, n) =>
        `<a href='mailto:${replaceEmail(e)}'>${n ? replaceName(n) : replaceEmail(e)}</a>`
    );

    formatted = formatted.replace(
      /(url=[a-zA-Z\-0-9].*?;)(name=[a-zA-Z\-0-9].*?;)?/g,
      (_m, u, n) => `<a href='${replaceUrl(u)}'>${n ? replaceName(n) : replaceUrl(u)}</a>`
    );

    return formatted;
  }, [message]);

  if (files && fileViewVisible)
    return (
      <ModalFileView
        visible={fileViewVisible}
        setVisible={setVisible}
        file={selectedFile}
        hasBackButton={!!message || files.length > 1 || hasBackButton}
        goBack={
          !!message || files.length > 1
            ? () => {
                setSelectedFile(null);
                setFileViewVisible(false);
              }
            : goBack
        }
      />
    );

  return (
    <Modal visible={visible} setVisible={setVisible} hasBackButton={hasBackButton} goBack={goBack}>
      {!isRole("Lab") && <Heading>{name}</Heading>}
      <Date>
        <strong>ORIGINAL DATE:</strong>&nbsp;
        <NoBreak>{prettyDateInUserTimezone(date, timezone, "MMM D YYYY")}</NoBreak>
      </Date>
      <Date>
        <strong>CREATED:</strong> <NoBreak>{prettyDateInUserTimezone(createdAt, timezone)}</NoBreak>
        &nbsp; by <NoBreak>{createdBy}</NoBreak>
      </Date>
      {updatedAt && (
        <Date>
          <strong>UPDATED:</strong>&nbsp;
          <NoBreak>{prettyDateInUserTimezone(updatedAt, timezone)}</NoBreak> by&nbsp;
          <NoBreak>{updatedBy}</NoBreak>
        </Date>
      )}
      <br />
      {formattedMessage && <NoteMessage dangerouslySetInnerHTML={{__html: formattedMessage}} />}
      {files && Object.keys(files).length > 0 && (
        <Files>
          <hr />
          <Attached>Attached File(s)</Attached>
          {files.map(file => (
            <FileLink
              key={`file-${file.id}`}
              onClick={() => {
                setSelectedFile(file);
                setFileViewVisible(true);
              }}>
              {file.label}
            </FileLink>
          ))}
        </Files>
      )}
    </Modal>
  );
};

ModalRowPreview.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  selected: PropTypes.objectOf(PropTypes.any),
  setSelected: PropTypes.func,
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func
};

ModalRowPreview.defaultProps = {
  selected: null,
  setSelected: null,
  hasBackButton: false,
  goBack: null
};

// Style Overrides
const NoteMessage = styled(Text)`
  a {
    display: inline;
    color: ${({theme}) => theme.primary};
    font-weight: bold;
  }
`;

const Files = styled.div`
  text-align: left;
  color: ${({theme}) => theme.secondary};
  margin-bottom: ${pad / 2}px;
  ${voice.quiet};

  p {
    font-weight: bold;
  }
`;

const FileLink = styled.button`
  ${voice.quiet};
  font-weight: bold;
  color: ${({theme}) => theme.primary};
  width: fit-content;
  cursor: pointer;
  margin-bottom: ${pad / 3}px;

  :hover {
    text-decoration: underline;
  }
`;

const Attached = styled(Text)`
  margin-bottom: ${pad / 3}px;
`;

const Date = styled(Text)`
  ${voice.weak};
  margin-bottom: ${pad / 3}px;
`;

const NoBreak = styled.span`
  white-space: nowrap;
`;

export default ModalRowPreview;

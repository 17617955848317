import React, {useCallback, useState, useEffect} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import dayjs from "dayjs";

// Hooks
import useApi from "../../hooks/useApi.js";

// Components
import FilePreview from "../../components/FilePreview.js";

// Styles
import {pad} from "../../style/components/variables.js";
import {Button, Heading, Inline, Label, Section, Text} from "../../style/components/general.js";

const Review = ({checksheet, review: {title, description, limit, fields}, setRecord}) => {
  const {api: apiRecords} = useApi("checksheet-records");
  const {api: apiFiles} = useApi("files");

  const [records, setRecords] = useState();

  const handleFiles = useCallback(
    async fileId => {
      const res = await apiFiles.callGet(fileId);
      if (res.status === 200 && res.data)
        return <FilePreview selected={res.data} download={false} />;
      return "File not found.";
    },
    [apiFiles]
  );

  const formatRecords = useCallback(
    async data => {
      const formatted = {};
      data.records?.map(({id, completedAt, submission: {responses}}) => {
        formatted[completedAt] = {id, values: []};
        fields?.map(async ({name, value}) => {
          let formattedValue = responses[value];

          if (formattedValue === "_CONDITION_UNSATISFIED")
            formattedValue = "Field was hidden on this submission by a condition.";

          if (checksheet.builder.byId[value]?.type === "upload")
            formattedValue = await handleFiles(responses[value], completedAt);

          if (value in responses)
            formatted[completedAt].values.push({
              key: name,
              value: formattedValue
            });
        });
      });

      setRecords(formatted);
    },
    [checksheet, fields, handleFiles]
  );

  // Initial Load
  useEffect(() => {
    if (!records)
      apiRecords
        .callGet(null, {facilityChecksheetId: checksheet.id, limit})
        .then(({status, data}) => {
          if (status === 200 && data?.records?.length > 0) formatRecords(data);
        });
  }, [records, apiRecords, checksheet, limit, formatRecords]);

  return (
    <Section key={title}>
      <Description>{description}</Description>
      {records &&
        Object.keys(records)?.map(date => (
          <div key={records[date].id}>
            <Inline>
              <Heading smaller>{dayjs(date).format("MMM D, YYYY")}</Heading>
              <Button
                type="button"
                onClick={() =>
                  apiRecords.callGet(records[date].id).then(({status, data}) => {
                    if (status === 200 && data) setRecord(data);
                  })
                }
                quiet>
                View Full Record
              </Button>
            </Inline>
            {records[date].values?.map(pair => (
              <Row key={pair.key}>
                <Label bold>{pair.key}:</Label> {pair.value || "NO RESPONSE PROVIDED."}
              </Row>
            ))}
          </div>
        ))}

      {fields?.length > 0 && !records && <Text>No past records found for selected fields</Text>}
    </Section>
  );
};

Review.propTypes = {
  checksheet: PropTypes.objectOf(PropTypes.any).isRequired,
  review: PropTypes.objectOf(PropTypes.any).isRequired,
  setRecord: PropTypes.func
};

Review.defaultProps = {
  setRecord: undefined
};

// Style Overrides
const Description = styled(Text)`
  margin: ${pad}px 0;
`;

const Row = styled.div`
  margin-bottom: ${pad}px;
`;

export default Review;

import React, {useState, useEffect, useContext} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {faClose} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

// Contexts
import {AuthContext} from "../../contexts/auth.js";

// Hooks
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Components
import Modal from "../../components/Modal.js";
import SearchSelect from "../../components/SearchSelect.js";
import {
  InputText,
  InputCheck,
  InputSelect,
  InputPhoneGroup,
  InputError
} from "../../components/form/FormInputs.js";

// Style
import {pad} from "../../style/components/variables.js";
import {voice} from "../../style/components/typography.js";
import {
  HeadingCenter,
  Form,
  FormGroup,
  FormField,
  ButtonFull,
  ButtonLoader,
  Pill,
  Button,
  Abbr,
  Label
} from "../../style/components/general.js";

const defaultValues = {
  firstName: null,
  lastName: null,
  email: null,
  phones: [
    {
      number: null,
      extension: null,
      type: "mobile",
      primary: false
    }
  ],
  invite: false,
  userDepartmentId: null,
  userRoleId: -1,
  userTypeId: null,
  company: null
};

const ModalUserAdd = ({visible, setVisible, facilityId, updateTable, hasBackButton, goBack}) => {
  const isMounted = useMountedState();

  const {currentUser, types, roles, departments} = useContext(AuthContext);

  const {api: apiUser, loading} = useApi("users");
  const {api: apiCompany} = useApi("companies");

  const schema = yup.object().shape({
    firstName: yup.string().required("Please provide first name."),
    lastName: yup.string().required("Please provide last name."),
    email: yup.string().email("Invalid email address").required("Please provide email."),
    phones: yup.array().of(
      yup.object().shape({
        number: yup
          .string()
          .nullable()
          .matches(/^\(\d{3}\)\s\d{3}-\d{4}/i, {
            message: "Please provide valid phone #.",
            excludeEmptyString: true
          }),
        extension: yup.string().nullable(),
        type: yup.string().required("Type is required."),
        primary: yup.bool()
      })
    ),
    invite: yup.boolean(),
    userDepartmentId: yup
      .mixed()
      .nullable()
      .when("invite", {
        is: val => !!val,
        then: () =>
          yup
            .number()
            .typeError("Please provide department.")
            .required("Please provide department."),
        else: () => yup.mixed().nullable()
      }),
    userRoleId: yup
      .mixed()
      .nullable()
      .when("invite", {
        is: val => roles && roles.length > 0 && !!val,
        then: () => yup.string().nullable().required("Please provide user role."),
        else: () => yup.mixed().nullable()
      }),
    userTypeId: yup
      .mixed()
      .nullable()
      .when(["userRoleId", "invite"], {
        is: (userRoleId, invite) =>
          (!roles || roles.length === 0 || `${userRoleId}` === "-1") && invite,
        then: () => yup.string().nullable().required("Please provide user type."),
        else: () => yup.mixed().nullable()
      }),
    company: yup.string().required("Please provide company")
  });

  const form = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  });
  const {
    watch,
    handleSubmit,
    reset,
    setValue,
    formState: {submitCount, errors}
  } = form;
  const invite = watch("invite");
  const role = watch("userRoleId");
  const company = watch("company");

  const [userTypes, setUserTypes] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [companyResults, setCompanyResults] = useState([]);

  useEffect(() => {
    if (isMounted() && types) {
      const filtered = types.filter(userType => userType.id <= currentUser.type.id);
      setUserTypes(filtered);
    }
  }, [isMounted, types, currentUser]);

  useEffect(() => {
    if (isMounted()) {
      apiCompany.callGet().then(({data, status}) => {
        if (status === 200 && data) setCompanies(data);
      });
    }
  }, [apiCompany, isMounted]);

  const searchCompanies = query => {
    if (query)
      setCompanyResults(
        companies
          .filter(c => c.name.toLowerCase().includes(query.toLowerCase()))
          .map(c => ({name: c.id, label: c.name}))
      );
    else setCompanyResults(companies.map(c => ({name: c.id, label: c.name})));
  };

  const addUser = values => {
    const {firstName, lastName, phones, userDepartmentId} = values;
    const hasPhones = phones.length > 0 && phones[0].number;
    const user = {
      facilityId,
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      phones: hasPhones ? phones : null,
      userDepartmentId: userDepartmentId || null,
      ...values
    };

    apiUser.callPost(user).then(({status}) => {
      if (status === 201) {
        setVisible(false);
        updateTable();
      }
    });
  };

  useEffect(() => {
    if (!visible) reset({});
  }, [reset, visible]);

  return (
    <Modal
      visible={visible}
      setVisible={setVisible}
      maxWidth="570px"
      hasBackButton={hasBackButton}
      goBack={goBack}>
      <ModalTitle>Add User</ModalTitle>

      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(addUser)} noValidate>
          <FormGroup>
            <FormField>
              <InputText name="firstName" label="First Name" required />
            </FormField>
            <FormField>
              <InputText name="lastName" label="Last Name" required />
            </FormField>
            <FormField>
              <InputText name="email" label="Email" required />
            </FormField>
            <FormField>
              <InputPhoneGroup name="phones" label="Phone(s)" limit={3} />
            </FormField>

            <FormField>
              {company ? (
                <>
                  <Label bold>COMPANY</Label>
                  <SelectedContainer data-testid="addField.tag-selected">
                    <Abbr>{company}</Abbr>
                    &nbsp;
                    <IconButton
                      onClick={() => setValue("company", null, {shouldValidate: !!submitCount})}>
                      <FontAwesomeIcon icon={faClose} />
                    </IconButton>
                  </SelectedContainer>
                </>
              ) : (
                <SearchSelect
                  label="COMPANY"
                  results={companyResults}
                  setResults={setCompanyResults}
                  search={searchCompanies}
                  add={selected => {
                    const selectedName = typeof selected === "string" ? selected : selected.label;

                    setValue("company", selectedName, {shouldValidate: !!submitCount});
                  }}
                  showAll
                  allowNew
                />
              )}
              <InputError name="company" errors={errors} />
            </FormField>
            <FormField>
              <InputCheck name="invite">Send Invite for access?</InputCheck>
            </FormField>
            {invite && (
              <StyledFormField>
                {departments?.length > 0 && (
                  <FormField>
                    <InputSelect
                      name="userDepartmentId"
                      label="Department"
                      options={departments.map(department => ({
                        name: department.id,
                        label: department.name
                      }))}
                    />
                  </FormField>
                )}
                {roles?.length > 0 && (
                  <FormField>
                    <InputSelect
                      name="userRoleId"
                      label="Role"
                      options={[
                        {name: -1, label: "Assign Later"},
                        ...roles.map(({id, label}) => ({
                          name: id,
                          label
                        }))
                      ]}
                    />
                  </FormField>
                )}
                {userTypes?.length && (!roles || roles.length === 0 || `${role}` === "-1") && (
                  <FormField>
                    <InputSelect
                      name="userTypeId"
                      label={
                        `${role}` === "-1"
                          ? "User Type (must be provided when role is omitted)"
                          : "User Type"
                      }
                      options={userTypes.map(({id, name}) => ({
                        name: id,
                        label: name.toUpperCase()
                      }))}
                    />
                  </FormField>
                )}
              </StyledFormField>
            )}
            {!invite && roles?.length > 0 && (
              <FormField>
                <InputSelect
                  name="userRoleId"
                  label="Role"
                  options={[
                    {name: -1, label: "Assign Later"},
                    ...roles
                      .filter(({type: {name}}) => name === "guest")
                      .map(({id, label}) => ({
                        name: id,
                        label
                      }))
                  ]}
                />
              </FormField>
            )}
          </FormGroup>
          <ButtonFull type="submit" loading={loading ? 1 : 0}>
            {invite ? "Add User" : "Add as Contact"}
            {loading && <ButtonLoader />}
          </ButtonFull>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalUserAdd.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  updateTable: PropTypes.func.isRequired,
  facilityId: PropTypes.number,
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func
};

ModalUserAdd.defaultProps = {
  facilityId: null,
  hasBackButton: false,
  goBack: null
};

// Style Overrides
const ModalTitle = styled(HeadingCenter)`
  margin: ${pad}px 0;
`;

const StyledFormField = styled(FormField)`
  margin-top: -${pad}px;
  margin-bottom: ${pad * 2}px;
`;

const SelectedContainer = styled(Pill)`
  width: min-content;
  height: min-content;
  max-width: 100%;
  margin: ${pad}px ${pad}px 0 0;
  color: ${({theme}) => theme.tertiary};
`;

const IconButton = styled(Button)`
  ${voice.quiet};
  background-color: transparent;
  width: min-content;
  padding: 0;

  svg {
    fill: ${({theme}) => theme.tertiary};
  }
`;

export default ModalUserAdd;

import React, {useEffect, useState, useRef, useCallback, useContext} from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {useReactToPrint} from "react-to-print";
import styled, {css} from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
import dayjs from "dayjs";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash, faEdit, faPrint, faArrowLeft} from "@fortawesome/free-solid-svg-icons";

// Utils
import useMountedState from "../../hooks/useMountedState.js";
import useApi from "../../hooks/useApi.js";
import {exists, getReadableKey} from "../../utils/helpers.js";
import {useToast} from "../../contexts/toast.js";
import {AuthContext} from "../../contexts/auth.js";
import {FacilityNavContext} from "../../contexts/facilitynav.js";

// Components
import Chart from "../../components/Chart.js";
import Modal from "../../components/Modal.js";
import Statistic from "../../components/Statistic.js";
import MultiSelect from "../../components/MultiSelect.js";
import RenderSubmission from "../checksheets/RenderSubmission.js";
import {InputSelect, InputText} from "../../components/form/FormInputs.js";

// Style
import {z} from "../../style/components/mixins.js";
import {border, pad, radius} from "../../style/components/variables.js";
import {bp, breakpoint} from "../../style/components/breakpoints.js";
import {
  Button,
  Error,
  Form,
  FormGroup,
  FormField,
  Warning,
  ModalButton,
  Text,
  Heading,
  Label,
  ButtonFull,
  ButtonLoader,
  StickyWrapper,
  FormFieldWrapper,
  Input
} from "../../style/components/general.js";

const ModalPreviewAnalytic = ({visible, setVisible, analytic, reload, allOrder}) => {
  const isMounted = useMountedState();

  const {roleCanAccessResource} = useContext(AuthContext);
  const {facility, analytics} = useContext(FacilityNavContext);

  const {addToast} = useToast();

  const [sources, setSources] = useState(analytic.sources);
  const [rendered, setRendered] = useState(null);
  const [hiddenLimits, setHiddenLimits] = useState([]);
  const [error, setError] = useState(null);
  // Filters
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);
  // Select Point
  const [active, setActive] = useState(null);
  const [selected, setSelected] = useState(false);
  // Menu
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [edit, setEdit] = useState(false);

  const {api, loading} = useApi("facility-analytics", {suppress: {success: true, error: true}});
  const {api: recordApi} = useApi("checksheet-records");

  const dropdownOptions = ["None", "Last 7 Days", "Last 30 Days", "Last 90 Days", "Last Year"];

  const schema = yup.object().shape({
    name: yup.string().required("Please provide a name."),
    after: yup
      .number()
      .transform((v, o) => (o === "" ? null : v))
      .nullable()
      .required("Please select where to display the chart."),
    snapshot: yup.string()
  });

  let defaultAfter = 0;

  if (analytics)
    analytics.map(table => {
      if (analytic && analytic.order - 1 === table.order) defaultAfter = table.order;
    });

  const form = useForm({
    defaultValues: {
      name: analytic.label,
      snapshot: analytic.snapshot,
      after: defaultAfter
    },
    resolver: yupResolver(schema)
  });
  const {watch, handleSubmit, reset} = form;
  const name = watch("name");
  const snapshot = watch("snapshot");

  const getAnalytic = useCallback(
    (newSources = [], newStart = null, newEnd = null, newSnapshot = null) => {
      const params = {sources: newSources.toString()};
      if (newSnapshot) params.snapshot = newSnapshot.toLowerCase();
      else if (snapshot) params.snapshot = snapshot.toLowerCase();
      if (newStart) params.start = newStart;
      if (newEnd) params.end = newEnd && dayjs(newEnd).add(1, "day").format("YYYY-MM-DD");
      if (analytic?.stageId) params.stageId = analytic.stageId;

      api.callGet(analytic.id, params).then(({status, data}) => {
        if (status === 200 && data) {
          setRendered(data);

          let message;
          if (analytic.type === "plot" && data.values?.length === 0)
            message =
              newStart || newEnd || (snapshot && snapshot !== "none")
                ? "No data found for specified date range, please adjust range."
                : "No data found.";

          if (analytic.type !== "plot" && (!exists(data.values) || data.values === "No data"))
            message =
              newStart || newEnd || (snapshot && snapshot !== "none")
                ? "No data found for specified date range, please adjust range."
                : "No data found.";
          setError(message);
        }

        setSources(newSources);
        setStart(newStart);
        setEnd(newEnd);
      });
    },
    [snapshot, api, analytic]
  );

  // Initial Load
  useEffect(() => {
    if (isMounted() && !rendered) getAnalytic(analytic.sources);
  }, [isMounted, getAnalytic, rendered, analytic]);

  useEffect(() => {
    if (isMounted() && snapshot && edit) getAnalytic(sources || analytic.sources);
  }, [analytic.sources, edit, getAnalytic, isMounted, snapshot, sources]);

  const editHandler = data => {
    let order = data.after ?? 1;
    if (order && analytic.order > order) order += 1;
    if (!order) order = 1;

    const params = {
      label: data.name,
      type: "plot",
      sources: data.sources,
      order,
      snapshot: data.snapshot
    };

    api.callPut(analytic.id, params).then(() => {
      setRendered(null);
      reload();
      setEdit(false);
    });
  };

  const deleteStat = () =>
    api.callDelete(analytic.id).then(() => {
      reload();
      setVisible(false);
    });

  // Handle Print
  const printLegendRef = useRef();
  const printChartRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => {
      const printBody = document.createElement("div");
      printBody.appendChild(printLegendRef.current.cloneNode(true));
      const chart = printChartRef.current.cloneNode(true);
      chart.firstChild.style.width = "fit-content";
      chart.firstChild.style.height = "fit-content";
      chart.firstChild.style.marginLeft = "30px";
      printBody.appendChild(chart);
      return printBody;
    }
  });

  const renderModalControls = () =>
    analytic.name !== "" &&
    !selected && (
      <Controls>
        {roleCanAccessResource("analytic", "export") && analytic.type === "plot" && (
          <ModalButton type="button" title="Print" onClick={handlePrint}>
            <FontAwesomeIcon icon={faPrint} />
          </ModalButton>
        )}
        {roleCanAccessResource("analytic", "update") && !facility?.isDeleted && (
          <ModalButton
            type="button"
            data-testid="editAnalytic"
            onClick={() => {
              reset(
                rendered
                  ? {
                      name: rendered.label,
                      snapshot: rendered.snapshot,
                      after: defaultAfter
                    }
                  : undefined
              );
              setEdit(!edit);
            }}
            title="Edit Event">
            {!edit ? <FontAwesomeIcon icon={faEdit} /> : "Cancel"}
          </ModalButton>
        )}
        {roleCanAccessResource("analytic", "delete") && !facility?.isDeleted && (
          <ModalButton
            type="button"
            data-testid="event.deleteButton"
            onClick={() => setConfirmDelete(true)}
            title="Delete Event">
            <FontAwesomeIcon icon={faTrash} inverse />
          </ModalButton>
        )}
      </Controls>
    );

  if (confirmDelete)
    return (
      <Modal visible={visible} setVisible={setVisible} maxWidth={breakpoint.width[2]}>
        <Heading center>Delete {analytic.name}</Heading>
        <ConfirmDelete>
          <Text>Are you sure you want to delete this? This action cannot be undone.</Text>
          <ConfirmDeleteButton type="button" onClick={deleteStat} loading={loading ? 1 : 0}>
            Confirm Delete {loading && <ButtonLoader />}
          </ConfirmDeleteButton>
        </ConfirmDelete>
      </Modal>
    );

  return (
    <Modal
      visible={visible}
      setVisible={state => setVisible(state)}
      renderModalControls={renderModalControls()}
      maxWidth={breakpoint.width[5]}>
      {!selected ? (
        <Content>
          <Left stat={analytic.type !== "plot" ? 1 : 0}>
            <Menu>
              {edit && (
                <FormProvider {...form}>
                  <Form onSubmit={handleSubmit(editHandler)}>
                    <FormGroup>
                      <FormField>
                        <Label htmlFor="name" bold>
                          Edit {analytic.type === "plot" ? "Chart" : "Statistic"} Label
                        </Label>
                        <InputText
                          testId="editPlotLabel"
                          name="name"
                          placeholder="Label"
                          {...form}
                        />
                      </FormField>
                    </FormGroup>
                    <FormGroup>
                      <FormField>
                        <InputSelect
                          testId="editOrder"
                          name="after"
                          label="Display After"
                          placeholder="Select..."
                          showPlaceholder={false}
                          options={[
                            {label: "Top of Page", value: "0"},
                            ...allOrder.filter(({value}) => value !== analytic.order)
                          ]}
                          fullWidth
                          {...form}
                        />
                      </FormField>
                    </FormGroup>
                    <FormGroup>
                      <InputSelect
                        testId="editSnapshotDropdown"
                        name="snapshot"
                        label="Default Date Range"
                        options={dropdownOptions.map(option => ({
                          label: option,
                          value: option.toLowerCase()
                        }))}
                        fullWidth
                        {...form}
                      />
                    </FormGroup>
                    <Submit type="submit" loading={loading ? 1 : 0}>
                      Save Chart {loading && <ButtonLoader />}
                    </Submit>
                  </Form>
                </FormProvider>
              )}
              {!edit && !selected && (
                <>
                  {analytic.type === "plot" && (
                    <PrintableFormGroup ref={printLegendRef}>
                      {analytic.limits && (
                        <MultiSelect
                          testId="limits"
                          name="limits"
                          label="Show/Hide"
                          defaultSelection={analytic.limits.map(([label]) => label)}
                          options={analytic.limits.map(([label]) => ({
                            label: label,
                            name: label
                          }))}
                          setSelection={selection =>
                            setHiddenLimits(
                              analytic.limits
                                .filter(([label]) => !selection.includes(label))
                                .map(([label]) => label)
                            )
                          }
                        />
                      )}

                      <MultiSelect
                        testId="trackedValue"
                        name="sources"
                        label={analytic.limits ? "" : "Show/Hide"}
                        defaultSelection={analytic.sources.map(source => source)}
                        options={analytic.sources.map(source => ({
                          label:
                            analytic.fullLabels &&
                            analytic.fullLabels[source] &&
                            analytic.fullLabels[source] !== ""
                              ? analytic.fullLabels[source]
                              : getReadableKey(source),
                          name: source
                        }))}
                        hasLineColors
                        setSelection={selection => getAnalytic(selection, start, end)}
                      />
                    </PrintableFormGroup>
                  )}
                  <DateSelectWrapper>
                    <Label bold>From</Label>
                    <Input
                      type="date"
                      onChange={e => getAnalytic(sources, e.target.value, end)}
                      placeholder="Select Date ..."
                      hasValue={start}
                    />
                  </DateSelectWrapper>
                  <DateSelectWrapper>
                    <Label bold>To</Label>
                    <Input
                      type="date"
                      onChange={e => getAnalytic(sources, start, e.target.value)}
                      placeholder="Select Date ..."
                      hasValue={start}
                    />
                  </DateSelectWrapper>
                  {analytic.active && (
                    <Target>
                      <Label bold>Options</Label>
                      {rendered && rendered.link && (
                        <TableLink to={rendered.link}>View Record Table</TableLink>
                      )}
                      {active && (
                        <Selected
                          type="button"
                          onClick={() => {
                            recordApi
                              .callGet(null, {
                                facilityChecksheetId: rendered.checksheetId,
                                filter: JSON.stringify({
                                  Search: dayjs(active.date).format("YYYY-MM-DD")
                                }),
                                isChart: analytic.type === "plot"
                              })
                              .then(({status, data}) => {
                                if (status === 200 && data?.records && data.records[0])
                                  setSelected(data.records[0].submission);
                                else {
                                  addToast("Record not found", "error");
                                  setSelected(null);
                                }
                              });
                          }}>
                          View Record: {active.date}
                        </Selected>
                      )}
                    </Target>
                  )}
                </>
              )}
            </Menu>
            {error && <SpacedWarning>{error}</SpacedWarning>}
            {!analytic.active && !edit && (
              <SpacedError>
                Checksheet Source Deleted: no new values will be added to graph
              </SpacedError>
            )}
          </Left>
          <Right>
            {["average", "min", "max", "total"].includes(analytic.type) && (
              <StatWrapper>
                <Statistic stat={rendered || analytic} />
              </StatWrapper>
            )}

            {analytic.type === "completionrate" && (
              <StatWrapper>
                <Statistic precision={0} stat={rendered || analytic} />
              </StatWrapper>
            )}
            {analytic.type === "plot" && rendered && (
              <StickyDiv>
                <Chart
                  view="preview"
                  label={name}
                  chart={rendered}
                  allSources={analytic.sources}
                  hiddenLimits={hiddenLimits}
                  active={active}
                  setActive={setActive}
                  chartType={analytic.chartType}
                />
                <PrintableChart>
                  <div ref={printChartRef}>
                    <Chart
                      printScale={{width: 650}}
                      view="preview-print"
                      label={name}
                      chart={rendered}
                      allSources={analytic.sources}
                      hideTooltips
                      chartType={analytic.chartType}
                    />
                  </div>
                </PrintableChart>
              </StickyDiv>
            )}
          </Right>
        </Content>
      ) : (
        <>
          <Back type="button" onClick={() => setSelected(null)}>
            <FontAwesomeIcon icon={faArrowLeft} />
            &nbsp;Back
          </Back>
          <Wrapper>
            <RenderSubmission
              submission={selected}
              builder={rendered.builder}
              dateDue={active.date}
              highlight={active.source}
            />
          </Wrapper>
        </>
      )}
    </Modal>
  );
};

ModalPreviewAnalytic.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  analytic: PropTypes.objectOf(PropTypes.any).isRequired,
  reload: PropTypes.func.isRequired,
  allOrder: PropTypes.arrayOf(PropTypes.any)
};

ModalPreviewAnalytic.defaultProps = {
  allOrder: []
};

// Style Overrides
const Content = styled.section`
  position: relative;
  width: 100%;
  gap: ${pad}px;
  display: flex;
  flex-direction: column;

  ${bp(3)} {
    flex-direction: row;
  }
`;

const Left = styled.div`
  padding: ${pad}px;
  width: 100%;

  ${bp(3)} {
    width: 34%;
  }

  ${({stat}) =>
    stat &&
    css`
      padding-top: 0;
    `}

  /* Mobile Landscape */
  @media (max-width: ${breakpoint.width[3]}) and (orientation: landscape) {
    display: none;
  }
`;

const Right = styled.div`
  padding: ${pad}px;
  width: 100%;

  ${bp(3)} {
    width: 75%;
  }

  /* Mobile Landscape */
  @media (max-width: ${breakpoint.width[3]}) and (orientation: landscape) {
    width: calc(${breakpoint.width[3]} - 200px);
    padding: 0;
  }
`;

const StickyDiv = styled(StickyWrapper)`
  width: 100%;
  padding: ${pad}px;

  ${bp(2)} {
    top: ${pad * 5}px;
    z-index: ${z("above")};
  }

  /* Mobile Landscape */
  @media (max-width: ${breakpoint.width[3]}) and (orientation: landscape) {
    padding: 0;
    margin: 0;
  }
`;

const Controls = styled.div`
  display: flex;
  margin-right: ${pad}px;
`;

const ConfirmDelete = styled.div`
  margin-top: ${pad}px;
`;

const ConfirmDeleteButton = styled(Button)`
  margin-top: ${pad / 2}px;
  background: ${({theme}) => theme.error};
`;

const Menu = styled.div`
  width: 100%;
  min-width: 200px;
  padding-right: ${pad * 1.5}px;
`;

const SpacedWarning = styled(Warning)`
  margin-top: ${pad}px;
`;

const SpacedError = styled(Error)`
  margin-top: ${pad}px;
`;

const PrintableFormGroup = styled(FormGroup)`
  max-width: 100%;
  overflow-x: hidden;

  div {
    max-width: 100%;
  }

  ${Label} {
    font-weight: bold;

    @media print {
      visibility: hidden;
    }
  }

  div label div {
    @media print {
      visibility: hidden;
    }
  }
`;

const PrintableChart = styled.div`
  display: none;
`;

const StatWrapper = styled.div`
  margin-top: ${pad}px;
  width: 100%;
`;

const DateSelectWrapper = styled(FormFieldWrapper)`
  max-width: 200px;
  margin-bottom: ${pad}px;
`;

const Target = styled.div`
  padding-top: ${pad}px;
`;

const TableLink = styled(Link)`
  text-decoration: underline;
  margin: ${pad / 2}px 0;
`;

const Selected = styled.button`
  width: auto;
  text-align: left;
  text-decoration: underline;
  margin: ${pad / 2}px 0;
`;

const Back = styled.button`
  width: auto;
`;

const Wrapper = styled.div`
  border: ${border} solid ${({theme}) => theme.primary};
  border-radius: ${radius};
  padding: ${pad}px;
`;

const Submit = styled(ButtonFull)`
  margin-top: ${pad}px;
`;

export default ModalPreviewAnalytic;

import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/free-solid-svg-icons";

// Style
import {z} from "../style/components/mixins.js";
import {border, pad, radius} from "../style/components/variables.js";
import {
  List,
  ListItem,
  Label,
  scrollbar,
  Search,
  SearchIcon,
  SearchWrapper,
  Button
} from "../style/components/general.js";

const SearchSelect = ({
  testId,
  label,
  search,
  results,
  setResults,
  showMore,
  add,
  allowNew,
  placeholder,
  large,
  showAll,
  keepOpen
}) => {
  const [focus, setFocus] = useState(false);

  const searchInput = useRef(null);
  const resultElement = useRef(null);
  const outside = useRef(null);

  useEffect(() => {
    const handleClickOutside = e => {
      if (
        outside?.current !== null &&
        !outside.current.contains(e.target) &&
        !outside.current.contains(e.target.nextSibling)
      ) {
        setResults(null);
        setFocus(false);
      }
    };

    if (outside && keepOpen) document.addEventListener("mousedown", handleClickOutside);

    // Cleanup
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [outside, setResults, keepOpen]);

  const handleSearch = e => {
    const query = e.target.value;
    search(query);
  };

  return (
    <div>
      {label && <Label bold>{label.toUpperCase()}</Label>}
      <SearchWrapper ref={outside} data-testid={testId}>
        <SearchIcon>
          <FontAwesomeIcon icon={faSearch} />
        </SearchIcon>
        <Search
          ref={searchInput}
          name="search"
          key="fields"
          type="text"
          data-testid={`${testId}-search`}
          placeholder={placeholder ?? "Find field..."}
          onChange={e => handleSearch(e)}
          onBlur={() => {
            if (!keepOpen)
              setTimeout(() => {
                setFocus(false);
                setResults([]);
              }, 150);
          }}
          onFocus={() => {
            setFocus(true);
            search("");
          }}
          autoComplete="off"
        />
        {(results?.length > 0 || allowNew) &&
        (searchInput.current?.value.length || (showAll && focus)) ? (
          <Results
            ref={resultElement}
            hideScroll={results?.length === 1}
            large={large}
            showMore={showMore}>
            {results?.map(result => (
              <Result
                data-testid={`${testId}-result-${
                  result.value ||
                  result.name ||
                  result.id ||
                  result.publicId ||
                  result.email ||
                  result
                }`}
                key={`result-${
                  result.value ||
                  result.name ||
                  result.id ||
                  result.publicId ||
                  result.email ||
                  result
                }`}
                onClick={() => {
                  add(result);
                  searchInput.current.value = "";
                  setFocus(false);
                  if (!keepOpen) setResults(null);
                  else if (results) {
                    const selected =
                      result.value || result.name || result.id || result.email || result;
                    setResults(
                      results.filter(r => {
                        const rValue = r.value || r.name || r.id || r.email || r;
                        return rValue !== selected;
                      })
                    );
                  }
                }}>
                {result.firstName && result.lastName
                  ? `${result.firstName} ${result.lastName}`
                  : result.label || result.name || result}
                {result.icon && (
                  <>
                    &nbsp;&nbsp;
                    <IconWrapper color={`#${result.color}`}>
                      <FontAwesomeIcon icon={result.icon} />
                    </IconWrapper>
                    &nbsp;&nbsp;
                  </>
                )}
              </Result>
            ))}
            {allowNew && results?.length === 0 && (
              <Result>
                <Button
                  type="button"
                  onClick={() => {
                    if (searchInput.current && searchInput.current.value.length > 0) {
                      add(searchInput.current.value);
                      searchInput.current.value = "";
                    }
                  }}>
                  Add New...
                </Button>
              </Result>
            )}
          </Results>
        ) : (
          ""
        )}
      </SearchWrapper>
    </div>
  );
};

SearchSelect.propTypes = {
  testId: PropTypes.string,
  label: PropTypes.string,
  search: PropTypes.func.isRequired,
  results: PropTypes.array,
  setResults: PropTypes.func.isRequired,
  add: PropTypes.func.isRequired,
  allowNew: PropTypes.bool,
  placeholder: PropTypes.string,
  showMore: PropTypes.bool,
  large: PropTypes.bool,
  showAll: PropTypes.bool,
  keepOpen: PropTypes.bool
};

SearchSelect.defaultProps = {
  testId: "searchSelect",
  label: null,
  results: [],
  allowNew: false,
  placeholder: null,
  showMore: false,
  large: false,
  showAll: false,
  keepOpen: false
};

// Style Overrides
const Results = styled(List)`
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  width: min-content;
  min-width: ${({large}) => (large ? "100%" : "155px")};
  max-height: ${({showMore}) => (showMore ? 125 : 90)}px;
  margin-top: ${pad / 4}px;
  background: ${({theme}) => theme.tertiary};
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  box-shadow: 0 3px 5px rgb(0, 0, 0, 0.5);
  z-index: ${z("top")};

  ${({hideScroll}) =>
    hideScroll
      ? css`
          overflow-y: hidden;
        `
      : css`
          overflow-y: auto;
          ${scrollbar};
        `}
`;

const Result = styled(ListItem)`
  display: inline-block;
  width: 100%;
  padding: 0 ${pad}px;
  white-space: nowrap;
  border: ${border} solid ${({theme}) => theme.tertiary};

  &:hover {
    color: ${({theme}) => theme.tertiary};
    background: ${({theme}) => theme.secondary};
    border: ${border} solid ${({theme}) => theme.secondary};

    ${Button} {
      color: ${({theme}) => theme.tertiary};
    }
  }

  ${Button} {
    width: 100%;
    text-align: left;
    padding: 0;
    background: 0;
    color: ${({theme}) => theme.secondary};
  }
`;

const IconWrapper = styled.span`
  ${({color}) =>
    color &&
    css`
      svg {
        fill: ${color};

        path {
          stroke: #000000;
          stroke-width: 10;
        }
      }
    `}
`;

export default SearchSelect;

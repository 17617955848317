import React, {useContext, useState} from "react";
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faEdit, faList, faPlus} from "@fortawesome/free-solid-svg-icons";

// Utils
import {AuthContext} from "../../contexts/auth.js";
import useApi from "../../hooks/useApi.js";
import {getIcon} from "./helpers.js";

// Components
import Modal from "../../components/Modal.js";
import ModalEventType from "./ModalEventType.js";

// Style
import {pad, radius} from "../../style/components/variables.js";
import {Button, HeadingCenter, Inline, Label, Loader} from "../../style/components/general.js";

const ModalSettings = ({
  visible,
  setVisible,
  eventTypes,
  setEventTypes,
  units,
  setUnits,
  view,
  setView
}) => {
  const {atLeast, roleCanAccessResource} = useContext(AuthContext);

  const [showManage, setShowManage] = useState(false);
  const [selected, setSelected] = useState(null);

  const {api} = useApi("event-types");

  const handleView = () => {
    const current = localStorage.getItem("schedule_view");
    const updated = current === "list" ? "calendar" : "list";
    localStorage.setItem("schedule_view", updated);
    setView(updated);
  };

  if (showManage)
    return (
      <ModalEventType
        visible={visible}
        setVisible={setVisible}
        goBack={() => {
          setShowManage(false);
          setSelected(null);
        }}
        types={eventTypes}
        existingType={selected}
        updateTemplates={() =>
          api.callGet().then(({status, data}) => {
            if (status === 200) setEventTypes(data);
          })
        }
        units={units}
        setUnits={setUnits}
      />
    );

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <HeadingCenter>Schedule Settings</HeadingCenter>

      <ButtonWrapper>
        <Label bold>Default View:</Label>
        <Button
          type="button"
          title={view === "list" ? "Save List View as Default" : "Save Calendar View as Default"}
          onClick={handleView}>
          <FontAwesomeIcon icon={view === "list" ? faList : faCalendar} />
          &nbsp;
          {view === "list" ? "List View" : "Calendar View"}
        </Button>
      </ButtonWrapper>

      {/* <ButtonWrapper>
        <Label bold>Show Weekends:</Label>
        <Switch state={showWeekends} callback={handleWeekend} />
      </ButtonWrapper> */}

      <Existing>
        <Label bold>Event Types:</Label>
        {eventTypes ? (
          <Inline>
            {eventTypes.map(template => {
              const {id, name, color, icon} = template;
              return (
                <EventType
                  key={id}
                  type="button"
                  title="Edit"
                  color={`#${color}`}
                  onClick={() => {
                    if (
                      atLeast("creator") &&
                      roleCanAccessResource("event_type_template", "update")
                    ) {
                      setSelected(template);
                      setShowManage(true);
                    }
                  }}>
                  <FontAwesomeIcon icon={icon ? getIcon(icon) : faCalendar} />
                  {name.toUpperCase()}
                  {atLeast("creator") && roleCanAccessResource("event_type_template", "update") && (
                    <FontAwesomeIcon icon={faEdit} />
                  )}
                </EventType>
              );
            })}
            {atLeast("creator") && roleCanAccessResource("event_type_template", "create") && (
              <EventType type="button" title="add" onClick={() => setShowManage(true)}>
                <FontAwesomeIcon icon={faPlus} />
              </EventType>
            )}
          </Inline>
        ) : (
          <Loader />
        )}
      </Existing>

      {atLeast("creator") && roleCanAccessResource("event_type_template", "create") && (
        <ButtonWrapper>
          <Label bold>Event Templates:</Label>
          <PageLink to="/event-templates">Manage Event Templates</PageLink>
        </ButtonWrapper>
      )}
    </Modal>
  );
};

ModalSettings.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  eventTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  setEventTypes: PropTypes.func.isRequired,
  units: PropTypes.arrayOf(PropTypes.string).isRequired,
  setUnits: PropTypes.func.isRequired,
  view: PropTypes.string.isRequired,
  setView: PropTypes.func.isRequired
  // showWeekends: PropTypes.bool.isRequired,
  // setShowWeekends: PropTypes.func.isRequired
};

// Style Overrides
const ButtonWrapper = styled.div`
  padding-bottom: ${pad}px;
`;

const PageLink = styled(Link)`
  width: fit-content;
  padding: ${pad / 2}px ${pad}px;
  margin-top: ${pad}px;
  border-radius: ${radius};
  background-color: ${props => props.theme.secondary};
  color: ${props => props.theme.tertiary};
`;

const Existing = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: ${pad}px;

  ${Inline} {
    margin-top: ${pad}px;
    flex-wrap: wrap;
  }
`;

const EventType = styled(Button)`
  padding: ${pad}px;
  color: ${props => props.theme.tertiary};
  background: ${props => props.color || props.theme.secondary};

  svg {
    fill: ${props => props.theme.tertiary};

    &:first-child {
      margin-right: ${pad / 2}px;
    }

    &:last-child {
      margin-left: ${pad / 2}px;
    }
  }
`;

export default ModalSettings;

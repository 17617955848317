import React, {useEffect} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {useFieldArray, useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinus, faPlus} from "@fortawesome/free-solid-svg-icons";

// Components
import InputPhone from "./InputPhone.js";
import InputSelect from "./InputSelect.js";
import InputCheck from "./InputCheck.js";

// Style
import {border, pad} from "../../style/components/variables.js";
import {voice} from "../../style/components/typography.js";
import {breakpoint} from "../../style/components/breakpoints.js";
import {Button, FormFieldWrapper, Inline, Label} from "../../style/components/general.js";

const InputPhoneGroup = ({name, label, required, limit, triggerAppend, testId}) => {
  const {control, watch, setValue} = useFormContext();

  const {fields, append, remove, insert} = useFieldArray({control, name});

  useEffect(() => {
    if (triggerAppend) append(triggerAppend);
  }, [triggerAppend, append]);

  const setPrimary = index => {
    for (let i = 0; i < fields.length; i++) setValue(`${name}.${i}.primary`, false);
    setValue(`${name}.${index}.primary`, true);
  };

  return (
    <FormFieldWrapper data-testid={testId} standard>
      {label && (
        <PhoneLabel htmlFor={name} bold>
          {label.toUpperCase()}
          {required && <span>&nbsp;*</span>}
        </PhoneLabel>
      )}
      {fields &&
        fields.length > 0 &&
        fields.map((option, index) => (
          <Wrapper key={option.id || index}>
            <input type="text" name={`${name}.${index}.id`} hidden />

            <InputPhone
              testId={`${testId}-phone`}
              name={`${name}.${index}`}
              defaultValue={option.value || null}
            />

            <Inline>
              <SelectWrapper>
                <InputSelect
                  testId={`${testId}-type`}
                  name={`${name}.${index}.type`}
                  options={["mobile", "home", "office", "24/7"]}
                  minWidth={100}
                />
              </SelectWrapper>
              <FormFieldWrapper inline>
                <InputCheck
                  name={`${name}.${index}.primary`}
                  defaultChecked={fields.length === 1 && index === 0}
                  hidden
                />
                <Primary
                  type="button"
                  active={watch(`${name}.${index}.primary`)}
                  onClick={() => setPrimary(index)}
                  testId={`${testId}-primary`}>
                  Primary
                </Primary>

                <Edit
                  disable={index === 0 && fields.length === 1}
                  type="button"
                  data-testid={`${testId}.removeInput`}
                  onClick={() => remove(index)}>
                  <FontAwesomeIcon icon={faMinus} />
                </Edit>
                <Edit
                  disable={fields.length === limit}
                  type="button"
                  data-testid={`${testId}.addInput`}
                  onClick={() => {
                    insert(index + 1, "");
                  }}>
                  <FontAwesomeIcon icon={faPlus} />
                </Edit>
              </FormFieldWrapper>
            </Inline>
          </Wrapper>
        ))}
    </FormFieldWrapper>
  );
};

InputPhoneGroup.propTypes = {
  testId: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  required: PropTypes.bool,
  triggerAppend: PropTypes.objectOf(PropTypes.any),
  limit: PropTypes.number
};

InputPhoneGroup.defaultProps = {
  testId: "input-phone-group",
  label: null,
  required: false,
  triggerAppend: null,
  limit: 10
};

// Style Overrides
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${pad}px;
  margin-bottom: ${pad}px;

  @media (min-width: ${breakpoint.width[4]}) {
    flex-direction: row;
    align-items: space-between;

    div {
      margin: 0;
    }
  }

  :last-child {
    margin-bottom: 0;
  }
`;

const PhoneLabel = styled(Label)`
  margin-bottom: ${pad}px;
`;

const Edit = styled(Button)`
  height: 100%;
  min-height: 33px;
  padding: 0 ${pad}px;
  margin-left: ${pad}px;

  ${props =>
    props.disable &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `}
`;

const Primary = styled(Button)`
  height: 100%;
  min-height: 33px;
  padding: 0 ${pad / 2}px;
  background: transparent;
  border: ${border} solid ${props => props.theme.secondary};
  color: ${props => props.theme.secondary};
  ${voice.quiet};

  @media (min-width: ${breakpoint.width[4]}) {
    margin-left: ${pad}px;
  }

  ${props =>
    props.active &&
    css`
      background: ${props.theme.secondary};
      color: ${props.theme.tertiary};
    `}
`;

const SelectWrapper = styled.div`
  max-width: 100px;
  margin-right: ${pad}px;
`;

export default InputPhoneGroup;

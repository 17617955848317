import React from "react";
import {useForm, FormProvider} from "react-hook-form";
import PropTypes from "prop-types";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import styled from "styled-components";

// Components
import Modal from "../../components/Modal.js";
import {InputText} from "../../components/form/FormInputs.js";

// Style
import {ButtonFull, Form, FormFieldWrapper, Heading} from "../../style/components/general.js";
import {pad} from "../../style/components/variables.js";

const ModalDuplicateTemplate = ({visible, setVisible, saveTemplate, templateName}) => {
  const form = useForm({
    defaultValues: {name: `${templateName} Copy`},
    resolver: yupResolver(
      yup.object().shape({
        name: yup.string().required("Please provide name for new template")
      })
    )
  });

  const {handleSubmit} = form;

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <Heading>Clone Template</Heading>
      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(saveTemplate)}>
          <Spacer>
            <FormFieldWrapper>
              <InputText name="name" label="New Template Name" />
            </FormFieldWrapper>
          </Spacer>
          <ButtonFull title="Save">Add Template</ButtonFull>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalDuplicateTemplate.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  saveTemplate: PropTypes.func.isRequired,
  templateName: PropTypes.string.isRequired
};

// Style Overrides

const Spacer = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: ${pad * 2}px;
`;

export default ModalDuplicateTemplate;

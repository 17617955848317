import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";

// Utils
import {AuthContext} from "../../contexts/auth";

// Components
import SearchSelect from "../../components/SearchSelect";
import CheckBox from "../../components/CheckBox";

// Style
import {voice} from "../../style/components/typography";
import {flex} from "../../style/components/mixins";
import {border, pad, radius} from "../../style/components/variables";
import {Abbr, Button, Error, Inline, Label, Small} from "../../style/components/general";

const RoleRestrict = ({restrictTo, setRestrictTo, external, setExternal}) => {
  const {roles} = useContext(AuthContext) || {};

  const available = roles?.filter(
    curr =>
      curr?.permissions?.resource?.byId?.facility_checksheet_record?.view === "enabled" &&
      curr?.permissions?.resource?.byId?.facility_checksheet_record?.create === "enabled"
  );

  const [searchResults, setSearchResults] = useState([]);

  const search = query => {
    if (query) {
      const lower = query.toLowerCase();
      const tempResults = available.filter(
        option =>
          option.label.toLowerCase().includes(lower) &&
          !restrictTo?.map(role => role.name).includes(option.name)
      );
      tempResults.sort(
        (a, b) => a.label.toLowerCase().indexOf(lower) - b.label.toLowerCase().indexOf(lower)
      );
      setSearchResults(tempResults);
    } else
      setSearchResults(available.filter(role => !restrictTo?.map(r => r.name).includes(role.name)));
  };

  useEffect(() => {
    if ((!restrictTo || restrictTo?.length === 0) && setExternal) setExternal(false);
  }, [restrictTo, setExternal]);

  return (
    <Menu>
      <Label bold>Restrict Checksheet visibility to Role(s)</Label>
      {available?.length > 0 ? (
        <Wrapper>
          <SearchSelect
            placeholder="Find role(s)..."
            results={searchResults}
            setResults={setSearchResults}
            search={search}
            add={({id, name, label}) => {
              setRestrictTo(prev => {
                if (prev && !prev.map(curr => curr.id).includes(id))
                  return [...prev, {id, name, label}];
                return prev ?? [{id, name, label}];
              });
            }}
            showAll
          />
          <Inline>
            {restrictTo?.map(({id}) => {
              const label = roles.filter(({id: roleId}) => roleId === id)[0]?.label;
              return (
                <SelectedRole key={label}>
                  <Abbr title={label.toUpperCase()}>{label.toUpperCase()}</Abbr>
                  <IconButton
                    onClick={() => {
                      setRestrictTo(prev => {
                        let toRemove;
                        prev.map((role, index) => {
                          if (role.id === id) toRemove = index;
                        });
                        const newRoles = [...prev];
                        newRoles.splice(toRemove, 1);
                        return newRoles;
                      });
                    }}>
                    <FontAwesomeIcon icon={faClose} />
                  </IconButton>
                </SelectedRole>
              );
            })}
          </Inline>
          {setExternal && restrictTo?.length > 0 && (
            <External>
              <CheckBox
                initial={external}
                handleCheck={state => setExternal(state)}
                triggerCheck={external}
              />
              <Small>External (access via email only)?</Small>
            </External>
          )}
        </Wrapper>
      ) : (
        <Error>Roles need to be configured to update this stage.</Error>
      )}
    </Menu>
  );
};

RoleRestrict.propTypes = {
  restrictTo: PropTypes.arrayOf(PropTypes.any),
  setRestrictTo: PropTypes.func.isRequired,
  external: PropTypes.bool,
  setExternal: PropTypes.func
};

RoleRestrict.defaultProps = {
  restrictTo: [],
  external: false,
  setExternal: null
};

// Style Overrides
const Menu = styled.div`
  position: relative;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  width: 100%;
  padding: ${pad}px;
  margin-bottom: ${pad}px;

  ${Label} {
    ${voice.quiet};
    margin-bottom: ${pad / 2}px;
  }

  ${Inline} {
    flex-wrap: wrap;
    margin-top: ${pad / 2}px;
    gap: ${pad / 2}px;
  }
`;

const Wrapper = styled.div`
  margin-top: ${pad}px;
`;

const SelectedRole = styled.div`
  ${flex("row", "nowrap", "space-between", "center")};
  width: min-content;
  border-radius: ${radius};
  color: ${({theme}) => theme.tertiary};
  background-color: ${({theme}) => theme.secondary};
  padding: ${pad / 2}px ${pad}px;

  ${Abbr} {
    ${voice.quiet};
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const IconButton = styled(Button)`
  ${voice.small};
  background-color: transparent;
  width: min-content;
  padding: 0;
  margin-left: ${pad / 2}px;

  svg {
    fill: ${({theme}) => theme.tertiary};
  }
`;

const External = styled(Inline)`
  span {
    color: ${({theme}) => theme.secondary};
  }
`;

export default RoleRestrict;

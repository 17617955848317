import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react";
import {useParams} from "react-router";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faLock, faSearch} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";

// Contexts
import {FacilityNavContext} from "../contexts/facilitynav.js";
import {AuthContext} from "../contexts/auth.js";
import {useSocket} from "../contexts/socket.js";

// Hooks
import useApi from "../hooks/useApi.js";
import useMountedState from "../hooks/useMountedState.js";

// Utils
import {COMPONENT, FIELD_TYPES, getAncestors, GROUP} from "../utils/builder.js";
import {toTitleCase} from "../utils/helpers.js";

// Components
import Badge from "../components/Badge.js";
import RenderChecksheet from "./checksheet-builder/RenderChecksheet.js";
import FacilityPageHeader from "./general/FacilityPageHeader.js";
import Modal from "../components/Modal.js";

// Style
import {flex, z} from "../style/components/mixins.js";
import {voice} from "../style/components/typography.js";
import {shake} from "../style/components/animations.js";
import {pad, border, radius, navHeight, shadow, colors} from "../style/components/variables.js";
import {
  Page,
  Title,
  Inline,
  Abbr,
  NotLoaded,
  Loader,
  Pill,
  Button,
  Text,
  HeadingMedium,
  Input,
  Label,
  StickyWrapper,
  SearchWrapper,
  SearchIcon,
  Search,
  Small,
  scrollbar
} from "../style/components/general.js";

// Socket Constants
import Room, {getFacilityRooms, LIST_LOCKS, REQUEST_LOCKS} from "./general/Room.js";

const LISTEN_NOTIFY_UPDATE_CHECKSHEET_BUILDER = "notify:update_checksheet_builder";

const ChecksheetPreview = () => {
  const isMounted = useMountedState();

  const {slug} = useParams();

  const socket = useSocket();

  const {currentUser} = useContext(AuthContext);

  const {edit} = useParams();

  const {facility, setFacility} = useContext(FacilityNavContext);

  const {api: apiUser} = useApi("users");
  const {api: apiFacility} = useApi("facilities");
  const {api: apiChecksheet, loading} = useApi("checksheets");
  const {api: apiFieldTags} = useApi("field-tags", {suppress: {success: true, error: true}});

  const [active, setActive] = useState(null);
  const [locks, setLocks] = useState(null);
  const [userLock, setUserLock] = useState();
  const [interactive, setInteractive] = useState(false);
  const [checksheet, setChecksheet] = useState();
  const [hasChanges, setHasChanges] = useState(false);
  const [simulatedDate, setSimulatedDate] = useState(null);
  // Filter
  const [open, setOpen] = useState(false);
  const [fieldTags, setFieldTags] = useState([]);
  const [targetTypes, setTargetTypes] = useState([]);
  const [targetTags, setTargetTags] = useState([]);
  const [targetElements, setTargetElements] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filterResults, setFilterResults] = useState();
  // Modals
  const [showModalModified, setShowModalModified] = useState(false);

  const search = useRef();
  const suggestion = useRef();

  const readOnly = useMemo(() => !interactive, [interactive]);

  const getFacility = useCallback(
    id =>
      apiFacility.callGet(id).then(({status, data}) => {
        if (status === 200 && data) setFacility(data);
      }),
    [apiFacility, setFacility]
  );

  const getChecksheet = useCallback(
    id =>
      apiChecksheet.callGet(id).then(({status, data}) => {
        if (status === 200 && data) {
          setChecksheet(data);

          if (facility === null) getFacility(data.facilityId);
        }
      }),
    [apiChecksheet, facility, getFacility]
  );

  useEffect(() => {
    if (isMounted() && !checksheet && edit) getChecksheet(edit);
  }, [isMounted, checksheet, edit, getChecksheet]);

  useEffect(() => {
    if (isMounted())
      apiFieldTags.callGet().then(({status, data}) => {
        if (status === 200 && data) setFieldTags(data.map(({name}) => name.toLowerCase()));
      });
  }, [isMounted, apiFieldTags]);

  // Socket Management
  const notifyUpdate = useCallback(() => setHasChanges(true), []);

  const listLocks = useCallback(({lockList}) => setLocks(lockList), []);

  useEffect(() => {
    if (isMounted() && currentUser && !locks)
      socket.emit(REQUEST_LOCKS, {
        rooms: getFacilityRooms(slug, "checksheet"),
        user: currentUser.publicId,
        type: "checksheet",
        to_sender: true
      });

    socket.on(LIST_LOCKS, listLocks);

    socket.on(LISTEN_NOTIFY_UPDATE_CHECKSHEET_BUILDER, notifyUpdate);

    return () => {
      // unbind all event handlers used in this component
      socket.off(LIST_LOCKS, listLocks);
      socket.off(LISTEN_NOTIFY_UPDATE_CHECKSHEET_BUILDER, notifyUpdate);
    };
  }, [isMounted, currentUser, socket, slug, locks, listLocks, notifyUpdate]);

  useEffect(() => {
    if (locks && `checksheet_${edit}` in locks && locks[`checksheet_${edit}`]?.user)
      apiUser.callGet(locks[`checksheet_${edit}`]?.user).then(({status, data}) => {
        if (status === 200 && data) setUserLock(`${data.firstName} ${data.lastName}`);
      });
  }, [locks, apiUser, edit]);

  const getElementMatches = (target, ids, byId, result = []) => {
    ids.map(id => {
      if (byId[id]) {
        const {element, type, name, label, parentName} = byId[id];

        const ancestors = getAncestors(parentName, byId);
        const value = ancestors ? `${ancestors} ${label}` : label;

        if (name?.includes(target)) {
          // case for rainfall
          if (element === "field" && type === "weather")
            result.push({
              element,
              name: `${name}_rainfall`,
              label: `${toTitleCase(value)} Rainfall`
            });

          result.push({
            element,
            name,
            label: toTitleCase(value)
          });
        }
      }

      if (byId[id] && byId[id].children.length > 0)
        getElementMatches(target, byId[id].children, byId, result);
    });

    return result;
  };

  const handleSearch = ({target}) => {
    if (target?.value) {
      const types = FIELD_TYPES.filter(type =>
        type.label.replace(/ /g, "").toLowerCase().includes(target.value.toLowerCase())
      );
      setTargetTypes(types);

      const filtered = fieldTags.filter(name =>
        name.toLowerCase().includes(target.value.toLowerCase())
      );
      setTargetTags(filtered);

      const elementMatches = getElementMatches(
        target.value.toLowerCase(),
        checksheet.builder.allIds,
        checksheet.builder.byId
      );
      setTargetElements(elementMatches.sort((a, b) => a.label - b.label));
    }
  };

  useEffect(() => {
    const handleOutsideClick = e =>
      open &&
      suggestion &&
      suggestion.current !== null &&
      !suggestion.current.contains(e.target) &&
      !suggestion.current.contains(e.target.nextSibling) &&
      setOpen(false);

    if (suggestion) document.addEventListener("mousedown", handleOutsideClick);

    return () => document.removeEventListener("mousedown", handleOutsideClick);
  }, [open]);

  return (
    <Page hasMenu>
      <Room name="checksheet-preview" active={active} setActive={setActive} />

      <Inline fullWidth>
        {facility?.name && <FacilityPageHeader facility={facility} path="/schedule" disabled />}
        {hasChanges && (
          <Changes
            type="button"
            title="Events have changed since your last page load"
            onClick={() => setShowModalModified(true)}>
            Load new changes!
          </Changes>
        )}
        <Button type="button" onClick={() => setInteractive(prev => !prev)}>
          {interactive ? "Preview" : "Interactive"}
        </Button>
      </Inline>

      {checksheet && !loading ? (
        <Wrapper>
          <Menu>
            <Inline>
              <ChecksheetName>
                <Abbr title={checksheet.name}>{checksheet.name}</Abbr>
              </ChecksheetName>
              {checksheet.frequency && <Pill>{checksheet.frequency.name}</Pill>}
            </Inline>

            <Options>
              {userLock && (
                <Text>
                  <FontAwesomeIcon icon={faLock} />
                  &nbsp;{userLock} is making updates
                </Text>
              )}
              {interactive && (
                <>
                  <SearchWrapper>
                    <SearchIcon>
                      <FontAwesomeIcon icon={faSearch} />
                    </SearchIcon>
                    <Search
                      ref={search}
                      name="search"
                      type="text"
                      placeholder="Find..."
                      onChange={handleSearch}
                      onFocus={() => setOpen(true)}
                    />
                    {filters.length > 0 && (
                      <Badge
                        count={filters.length}
                        offsetY="25px"
                        offsetX="-10px"
                        color={colors.heroGreen}
                      />
                    )}
                    <Suggestions
                      ref={suggestion}
                      open={
                        (filterResults?.length > 0 || search.current?.value?.length > 0) && open
                      }>
                      <Filters style={{flexWrap: "wrap"}}>
                        {filters?.map(filter => (
                          <Filter
                            key={filter}
                            type="button"
                            onClick={() => {
                              const [fType, fName] = filter.split(": ");
                              setFilterResults(prev => {
                                const newResults = prev?.filter(id => {
                                  if (fType === "TYPE")
                                    return checksheet.builder.byId[id].type !== fName.toLowerCase();
                                  if (fType === "TAG")
                                    return (
                                      checksheet.builder.byId[id]?.tag?.toUpperCase() !==
                                      fName.toUpperCase()
                                    );
                                  return true;
                                });
                                if (filters.length > 1) return newResults;
                                return undefined;
                              });
                              setFilters(prev => prev.filter(f => f !== filter));
                            }}>
                            <Pill quiet>
                              {filter}&nbsp;
                              <Icon icon={faClose} />
                            </Pill>
                          </Filter>
                        ))}
                      </Filters>
                      {(targetTypes?.length > 0 || targetTags?.length > 0) && (
                        <>
                          <Small>Filter</Small>
                          <hr />
                          {targetTypes?.length > 0 && (
                            <>
                              <Text quiet>Types:</Text>
                              {targetTypes.map(({label, value}) => (
                                <Suggestion
                                  key={label}
                                  onClick={() => {
                                    search.current.value = label.toUpperCase();
                                    handleSearch({target: {value: label}});
                                    const typeMatches = Object.fromEntries(
                                      Object.entries(checksheet.builder.byId).filter(
                                        ([, {type: t}]) => t && value?.includes(t.toLowerCase())
                                      )
                                    );
                                    setFilterResults(prev =>
                                      prev
                                        ? [...prev, ...Object.keys(typeMatches)]
                                        : Object.keys(typeMatches)
                                    );

                                    // reset
                                    setTargetTypes(undefined);
                                    setTargetTags(undefined);
                                    setTargetElements(undefined);
                                    setFilters(prev => [...prev, `TYPE: ${label.toUpperCase()}`]);
                                    setOpen(false);
                                    search.current.value = "";
                                  }}>
                                  {label.toUpperCase()}
                                </Suggestion>
                              ))}
                            </>
                          )}
                          {targetTags?.length > 0 && (
                            <>
                              <Text quiet>Tags:</Text>
                              {targetTags.map(tag => (
                                <Suggestion
                                  key={tag}
                                  onClick={() => {
                                    search.current.value = tag.toUpperCase();
                                    handleSearch({target: {value: tag}});
                                    const tagMatches = Object.fromEntries(
                                      Object.entries(checksheet.builder.byId).filter(
                                        ([, {tag: t}]) => t && targetTags?.includes(t.toLowerCase())
                                      )
                                    );
                                    setFilterResults(prev =>
                                      prev
                                        ? [...prev, ...Object.keys(tagMatches)]
                                        : Object.keys(tagMatches)
                                    );

                                    // reset
                                    setTargetTypes(undefined);
                                    setTargetTags(undefined);
                                    setTargetElements(undefined);
                                    setFilters(prev => [...prev, `TAG: ${tag.toUpperCase()}`]);
                                    setOpen(false);
                                    search.current.value = "";
                                  }}>
                                  {tag.toUpperCase()}
                                </Suggestion>
                              ))}
                            </>
                          )}
                        </>
                      )}
                      {targetElements?.length > 0 && (
                        <>
                          <hr />
                          <Small>Find Element</Small>
                          <hr />
                          {targetElements.map(({element, name, label}) => (
                            <Suggestion
                              key={name}
                              element={element}
                              onClick={() => {
                                handleSearch({target: {value: label}});
                                document
                                  .querySelectorAll(`[data-testid='${element}-${name}']`)[0]
                                  .scrollIntoView({
                                    behavior: "smooth",
                                    block: "center"
                                  });

                                // reset
                                setTargetTypes(undefined);
                                setTargetTags(undefined);
                                setTargetElements(undefined);
                                setOpen(false);
                                search.current.value = "";
                              }}>
                              {label.toUpperCase()}
                            </Suggestion>
                          ))}
                        </>
                      )}
                      {targetTypes?.length === 0 &&
                        targetTags?.length === 0 &&
                        targetElements?.length === 0 && <Text>No matches found.</Text>}
                    </Suggestions>
                  </SearchWrapper>
                  <DateInputLabel bold htmlFor="simulatedDate">
                    Simulated Date
                    <Input
                      type="date"
                      onChange={e => setSimulatedDate(e.target.value)}
                      name="simulatedDate"
                      defaultValue={dayjs().format("YYYY-MM-DD")}
                    />
                  </DateInputLabel>
                </>
              )}
            </Options>
          </Menu>
          <RenderChecksheet
            task={{...checksheet, name: null, frequency: null}}
            hideMeta
            hideCompleted
            readOnly={readOnly}
            simulatedDate={simulatedDate}
            filterResults={filterResults}
          />
        </Wrapper>
      ) : (
        <NotLoaded>
          <Loader />
        </NotLoaded>
      )}

      {showModalModified && (
        <Modal visible={showModalModified} setVisible={setShowModalModified}>
          <HeadingMedium>Missing Changes</HeadingMedium>
          <Text>This checksheet has been changed since you last viewed it.</Text>
          <br />
          <Button
            type="button"
            onClick={() => {
              setShowModalModified(false);
              setHasChanges(false);
              getChecksheet(edit);
            }}>
            Update
          </Button>
        </Modal>
      )}
    </Page>
  );
};

// Style Overrides
const Wrapper = styled.article`
  ${flex("row", "wrap", "start", "start")};
  margin: ${pad * 2}px 0;
  width: 100%;
  padding: ${pad}px;
  border-radius: ${radius};
  border: ${border} solid ${({theme}) => theme.secondary};
`;

const Menu = styled(StickyWrapper)`
  ${flex("row", "wrap", "space-between", "end")};
  width: 100%;
  top: ${navHeight}px;
  background: ${({theme}) => theme.tertiary};
  box-shadow: 0 5px 15px ${({theme}) => theme.tertiary};
  z-index: ${z("top")};
`;

const ChecksheetName = styled(Title)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Changes = styled.button`
  ${voice.quiet};
  padding: ${pad}px;
  position: absolute;
  top: 33px;
  bottom: 0;
  animation: ${shake()} 5s linear 2s infinite;

  &:hover {
    text-decoration: underline;
    animation-play-state: paused;
  }
`;

const Icon = styled(FontAwesomeIcon)`
  fill: ${({theme}) => theme.tertiary};
`;

const Filters = styled(Inline)`
  flex-wrap: nowrap;
  margin-bottom: ${pad}px;
`;

const Filter = styled.button``;

const Options = styled(Inline)`
  align-items: end;
`;

const Suggestions = styled.div`
  ${scrollbar};
  position: absolute;
  top: 100%;
  left: 0;
  padding: ${pad}px;
  margin-top: ${pad / 2}px;
  width: 300px;
  max-height: 300px;
  border-radius: ${radius};
  border: ${border} solid ${({theme}) => theme.secondary};
  background: ${({theme}) => theme.tertiary};
  box-shadow: ${shadow};
  overflow-y: auto;

  display: ${({open}) => (open ? "block" : "none")};
`;

const Suggestion = styled.button`
  ${voice.small};
  text-align: left;
  margin-top: ${pad / 2}px;
  color: ${({element, theme}) => {
    if (element && element === GROUP) return theme.group;
    if (element && element === COMPONENT) return theme.component;
    return theme.secondary;
  }};

  &:hover {
    font-weight: bold;
  }
`;

const DateInputLabel = styled(Label)`
  ${voice.quiet};
  display: flex;
  flex-direction: column;
  width: min-content;
`;

export default ChecksheetPreview;

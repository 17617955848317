import React, {useCallback, useContext, useEffect, useMemo} from "react";
import {useNavigate} from "react-router-dom";
import styled, {css} from "styled-components";
import dayjs from "dayjs";

// Utils
import {AuthContext} from "../contexts/auth.js";
import {SettingsContext} from "../contexts/settings.js";
import {convertToUserTimezone} from "../utils/helpers.js";
import {useSocket} from "../contexts/socket.js";
import useMountedState from "../hooks/useMountedState.js";

// Style
import {flex, z} from "../style/components/mixins.js";
import {border, heroTheme, pad} from "../style/components/variables.js";
import {Title, Heading} from "../style/components/general.js";

const MAINTENANCE = "maintenance";

const Maintenance = () => {
  const navigate = useNavigate();

  const isMounted = useMountedState();

  const socket = useSocket();

  const {maintenance, setMaintenance} = useContext(AuthContext);
  const {settings} = useContext(SettingsContext);

  useEffect(() => {
    if (maintenance?.status === "off") navigate("/");
  }, [maintenance, navigate]);

  const handleMaintenance = useCallback(scheduled => setMaintenance(scheduled), [setMaintenance]);

  // Socket Events
  useEffect(() => {
    socket.on(MAINTENANCE, handleMaintenance);

    return () => {
      // unbind all event handlers used in this component
      socket.off(MAINTENANCE, handleMaintenance);
    };
  }, [socket, isMounted, handleMaintenance]);

  const time = useMemo(() => {
    if (maintenance?.end && maintenance.end !== "N/A") {
      const {end} = maintenance;
      const provided = end.split(":");
      const hours = provided[0];
      const minutes = provided[1];
      const formatted = convertToUserTimezone(
        dayjs().hour(hours).minute(minutes),
        settings.timezone
      ).format("h:mm a");
      return formatted;
    }
    return null;
  }, [maintenance, settings.timezone]);

  return (
    <MaintenancePage>
      <Header>
        <Logo src={`${process.env.PUBLIC_URL}/hero-services.png`} alt="Hero Services Logo" />
      </Header>
      <Title>Hero Builder™ is down for Maintenance</Title>
      <Heading>Sorry for the inconvenience{time && `, check back at ${time}`}.</Heading>
    </MaintenancePage>
  );
};

// Style Overrides
const MaintenancePage = styled.section`
  ${flex("column", "wrap", "center", "center")};
  min-height: 100vh;
  height: 100%;
`;

const Header = styled.nav`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  align-self: start;
  display: flex;
  background: ${heroTheme.secondary};
  z-index: ${z("top")};

  ${({theme}) =>
    theme.name === "dark" &&
    css`
      border-bottom: ${border} solid ${heroTheme.tertiary};
    `}
`;

const Logo = styled.img`
  height: 75px;
  margin: ${pad}px auto;
`;

export default Maintenance;

import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {get, useFormContext} from "react-hook-form";
import {useParams} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHistory, faCheckDouble} from "@fortawesome/free-solid-svg-icons";

// Contexts
import {useSocket} from "../../contexts/socket.js";
import {AuthContext} from "../../contexts/auth.js";
import {SettingsContext} from "../../contexts/settings.js";
import {useToast} from "../../contexts/toast.js";

// Hookes
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";
import useScrollListener from "../../hooks/useScrollListener.js";

// Utils
import {
  addCommas,
  exists,
  getCompletionRate,
  inViewport,
  prettyDateInUserTimezone
} from "../../utils/helpers.js";
import {isMobile} from "../../utils/responsive.js";
import {defaultUnacceptableParameterPrompt} from "../../utils/builder.js";
import {buildFormulaString, evaluateRange} from "../../pages/checksheet-builder/helpers.js";

// Components
import InputError from "./InputError.js";
import InputTextArea from "./InputTextArea.js";
import InputSelect from "./InputSelect.js";
import Help from "../Help.js";

// Style
import {voice} from "../../style/components/typography.js";
import {pad, radius} from "../../style/components/variables.js";
import {
  Abbr,
  Button,
  CloseButton,
  Error,
  FormFieldWrapper,
  Inline,
  Input,
  Label,
  Pill,
  Small,
  Text
} from "../../style/components/general.js";

const PERCENT_COMPLETE = "percent_complete";

const InputGenerated = ({
  room,
  task,
  taskRecord,
  taskType,
  stage,
  name,
  label,
  tag,
  testId,
  prompt,
  defaultValue,
  placeholder,
  required,
  readOnly,
  hidden,
  disabled,
  units,
  depends,
  trueDepends,
  formula,
  formulaString,
  condition,
  absoluteValue,
  trueFormula,
  innerRef,
  globalUnacceptable,
  globalUnacceptableTitles,
  precision,
  min,
  max,
  aMin,
  aMax,
  strictMin,
  strictMax,
  hasARange,
  hasSetPoint,
  setLow,
  setHigh,
  rangeException,
  restrictedRangeException,
  byId,
  previousRead,
  qualifier,
  errorDefault,
  weather,
  setFormulasEvaluating
}) => {
  const isMounted = useMountedState();

  const {slug} = useParams();
  const {addToast} = useToast();

  const {currentUser} = useContext(AuthContext);
  const {settings} = useContext(SettingsContext);

  const generatedRef = useRef();
  const typing = useRef();

  const scrolling = useScrollListener(document.getElementById("modal") || window);

  const [inWindow, setInWindow] = useState(false);
  const [message, setMessage] = useState(null);
  const [dependsVisible, setDependsVisible] = useState(false);
  const [prevVisible, setPrevVisible] = useState(false);
  // const [mobile, setMobile] = useState(false);
  const [formatted, setFormatted] = useState(null);
  const [backupFormulaString, setBackupFormulaString] = useState(null);
  const [hasNoBackup, setHasNoBackup] = useState(false);
  const [initializing, setInitializing] = useState(true);

  const {api} = useApi("formulas", {suppress: {error: true}});
  const socket = useSocket();

  const form = useFormContext();
  const {
    setValue,
    register,
    formState: {errors, submitCount},
    watch
  } = form;

  const value = watch(name);
  const qualifierEnabled = watch(`${name}_qualifierEnabled`);
  const qualifierSelected = watch(`${name}_qualifier`);

  const hasYupErrors = useMemo(() => {
    const {message: yupMessage} = get(errors, name) || {};
    return !!yupMessage;
  }, [errors, name]);

  // follow dependencies of formula to find any potential default values
  const recurseFindDefaults = useCallback(
    (dep, fieldsWithDefault) => {
      const dependencies = dep ? dep.filter(d => d in byId).map(d => [d, byId[d]]) : [];

      for (let i = 0; i < dependencies.length; i++) {
        const [n, f] = dependencies[i];
        if (f && f.hasDivideByZeroDefault && exists(f.divideByZeroDefault))
          fieldsWithDefault.push(n);
        recurseFindDefaults(f.depends, fieldsWithDefault);
      }
    },
    [byId]
  );

  // useEffect(() => {
  //   if (isMounted()) setMobile(isMobile());
  // }, [isMounted]);

  useEffect(() => {
    if (dependsVisible) setPrevVisible(false);
  }, [dependsVisible]);

  useEffect(() => {
    if (prevVisible) setDependsVisible(false);
  }, [prevVisible]);

  useEffect(() => {
    setFormatted(exists(value) ? addCommas(value, exists(precision) ? precision : 2) : "");
  }, [value, precision]);

  // watch linked fields dynamically
  const trueLinkedFields = {};
  trueDepends
    .filter(val => !!val)
    .forEach(d => {
      trueLinkedFields[d] = watch(d);
    });

  const linkedFields = {};
  depends
    .filter(val => !!val)
    .forEach(d => {
      linkedFields[d] = watch(d);
      linkedFields[`${d}_undefined`] = watch(`${d}_undefined`);
    });

  const updatePercentage = useCallback(() => {
    const {completedAt: _ca, note: _note, ...rest} = watch();
    if (isMounted() && room && socket && rest && taskType === "checksheet")
      socket.emit(
        PERCENT_COMPLETE,
        `${slug}-${room}`,
        `checksheet_${task.id}`,
        currentUser.publicId,
        getCompletionRate({...rest})
      );
  }, [task, taskType, watch, isMounted, room, socket, slug, currentUser]);

  useEffect(() => {
    if (!initializing) updatePercentage();
    setInitializing(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (isMounted() && (hidden || (generatedRef.current && inViewport(generatedRef.current))))
      setInWindow(true);
  }, [isMounted, hidden]);

  useEffect(() => {
    if (isMounted() && scrolling && !inWindow)
      setInWindow(hidden || inViewport(generatedRef.current));
  }, [isMounted, scrolling, inWindow, hidden]);

  const evaluate = useCallback(
    (fString, formulaObject) => {
      if (!readOnly) {
        if (setFormulasEvaluating) setFormulasEvaluating(prev => ({...prev, [name]: true}));
        api
          .callPost({
            [`${taskType}Id`]: task.id,
            [`${taskType}RecordId`]: taskRecord?.id,
            stage,
            formula: fString,
            ...formulaObject
          })
          .then(response => {
            const {status, data} = response;
            if (status === 200 && data) {
              // successful response
              setValue(name, data.computed, {shouldValidate: !!submitCount});
              setMessage(null);
              setValue(`${name}_undefined`, false, {shouldValidate: !!submitCount});
            } else if (exists(errorDefault) && response?.includes("division by zero")) {
              // division by zero, this field has a default
              setValue(name, `${errorDefault}`, {shouldValidate: !!submitCount});
              setValue(`${name}_undefined`, true, {shouldValidate: !!submitCount});
            } else if (response?.includes("division by zero")) {
              // division by zero, no default, need to search dependencies for defaults
              if (!backupFormulaString && !hasNoBackup) {
                const fieldsWithDefault = [];
                recurseFindDefaults(depends, fieldsWithDefault);

                if (fieldsWithDefault.length) {
                  // if defaults found, build iterative formula
                  const str = buildFormulaString(formula, absoluteValue ?? false);
                  setBackupFormulaString(str);
                } else {
                  // will not try to find defaults again
                  setHasNoBackup(true);
                  setMessage(response);
                }
              } else setMessage(response);

              setValue(`${name}_undefined`, true, {shouldValidate: !!submitCount});
              setValue(name, null, {shouldValidate: !!submitCount});
            } else if (response?.includes("previous")) {
              setValue(name, "_NO_PREVIOUS", {shouldValidate: !!submitCount});
              setMessage(response);
            } else if (response?.includes("infinite")) {
              setValue(name, "_DEP_IS_INF", {shouldValidate: !!submitCount});
              setMessage(response);
            } else if (response !== "canceled") {
              addToast(response, "error");
            }
            if (response !== "canceled") updatePercentage();
            if (setFormulasEvaluating) setFormulasEvaluating(prev => ({...prev, [name]: false}));
          });
      }
    },
    [
      task,
      taskRecord,
      taskType,
      absoluteValue,
      addToast,
      api,
      backupFormulaString,
      depends,
      errorDefault,
      name,
      updatePercentage,
      setValue,
      submitCount,
      hasNoBackup,
      recurseFindDefaults,
      formula,
      readOnly,
      setFormulasEvaluating,
      stage
    ]
  );

  const handleInputRateLimit = useCallback(
    (fString, formulaObject) => {
      if (typing.current) clearTimeout(typing.current);
      const timer = setTimeout(() => {
        evaluate(fString, formulaObject);
        typing.current = null;
      }, 800);
      typing.current = timer;
    },
    [evaluate]
  );

  // normal, substituted evaluation
  useEffect(() => {
    const operands = {};
    let hasAllOperands = true;
    const hasPrevious = [];
    let sanitizedFormula = formulaString;
    const nameMap = {};
    let errorMessage = null;
    let fallbackVal = null;
    let prevSymbol = null;
    let prevOperand = null;
    let prevTimeVal = null;

    if (trueFormula && trueLinkedFields && !backupFormulaString) {
      for (let i = 0; i < trueFormula.length; i++) {
        const f = trueFormula[i];
        const fieldName = f.value;

        if (["rainfall", "cumulative"].includes(fieldName)) {
          const fieldValue = weather ? weather[fieldName] || 0 : 0;
          operands[fieldName] = `${fieldValue}`;
        } else if (fieldName in byId && !f.previous) {
          const fieldType = byId[fieldName]?.type;
          // formula part is a field that is not referencing a previous value
          const fieldValue = trueLinkedFields[fieldName];
          if (!exists(fieldValue)) {
            hasAllOperands = false;
            break;
          }
          if (fieldValue === "∞") {
            errorMessage = "Cannot evaluate - one or more operands are infinite";
            fallbackVal = "_DEP_IS_INF";
            hasAllOperands = false;
            break;
          }

          // replace any characters that are invalid in a python variable name
          let sanitized = fieldName.replace(/[^A-Za-z0-9_]+/g, "_");
          if (sanitized.match(/^[0-9].*/)) {
            sanitized = `id_${sanitized}`;
          }

          // replace invalid names with sanitized version if necessary
          if (sanitized !== fieldName) {
            const re = new RegExp(fieldName, "g");
            sanitizedFormula = sanitizedFormula.replace(re, sanitized);
            nameMap[sanitized] = fieldName;
          }

          // add to operands array using sanitized name
          if (fieldType === "time") {
            let numericalTime = null;

            if (fieldValue?.includes(":")) {
              // convert field value from timestamp to numerical hours value
              const [hour, minute] = fieldValue.split(":");
              const hourParsed = parseInt(hour, 10);
              const minParsed = parseInt(minute, 10);

              numericalTime =
                !Number.isNaN(hourParsed) && !Number.isNaN(minParsed)
                  ? hourParsed + minParsed / 60
                  : null;

              if (
                prevSymbol === "-" &&
                exists(numericalTime) &&
                exists(prevTimeVal) &&
                prevTimeVal < numericalTime
              ) {
                // this is the manipulation necessary to yield the same result
                // as would be returned when considering the end time to be
                // falling on the following day
                // numericalTime -= 24;
                operands[prevOperand] = `${numericalTime}`;
                operands[sanitized] = `${prevTimeVal}`;
                prevTimeVal = null;
              } else {
                prevOperand = sanitized;
                prevTimeVal = numericalTime;
                operands[sanitized] = `${numericalTime}`;
              }
            }

            if (!exists(numericalTime)) {
              hasAllOperands = false;
              break;
            }
          } else operands[sanitized] = fieldValue;
        } else if (f.previous) {
          // formula part is a field that is referencing a previous value
          let sanitized = fieldName.replace(/[^A-Za-z0-9_]+/g, "_");
          if (sanitized.match(/^[0-9].*/)) {
            sanitized = `id_${sanitized}`;
          }

          // replace any characters that are invalid in a python variable name
          if (sanitized !== fieldName) {
            const re = new RegExp(fieldName, "g");
            sanitizedFormula = sanitizedFormula.replace(re, sanitized);
            nameMap[sanitized] = fieldName;
          }

          // add original name to previous value array for response lookup
          hasPrevious.push(sanitized);
        }

        prevSymbol = fieldName;
      }

      if (inWindow && hasAllOperands)
        handleInputRateLimit(sanitizedFormula, {operands, hasPrevious, name, nameMap});
      else if (!hasAllOperands) {
        setValue(name, fallbackVal, {shouldValidate: !!submitCount});
        setMessage(errorMessage);
        clearTimeout(typing.current);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...Object.keys(trueLinkedFields).map(f => trueLinkedFields[f]), trueFormula, inWindow]);

  // backup, iterative evaluation
  // only fires when a division by zero has occurred
  useEffect(() => {
    const operands = {};
    let hasAllOperands = true;
    const hasPrevious = [];
    let sanitizedFormula = backupFormulaString;
    const nameMap = {};
    let errorMessage = null;
    let fallbackVal = null;
    let prevSymbol = null;
    let prevTimeVal = null;

    if (formula && linkedFields && backupFormulaString) {
      for (let i = 0; i < formula.length; i++) {
        const f = formula[i];
        const fieldName = f.value;
        if (["rainfall", "cumulative"].includes(fieldName)) {
          const fieldValue = weather ? weather[fieldName] || 0 : 0;
          operands[fieldName] = `${fieldValue}`;
        } else if (fieldName in byId && !f.previous) {
          const fieldType = byId[fieldName]?.type;

          const {hasDivideByZeroDefault: fieldHasDefault, divideByZeroDefault: fieldDefault} =
            byId[fieldName];
          let fieldValue = linkedFields[fieldName];
          const undef = linkedFields[`${fieldName}_undefined`];
          // if the field in question is undefined (divided by zero) and has a default, replace
          if (undef && !fieldValue) fieldValue = fieldHasDefault ? `${fieldDefault}` : "";

          if (!exists(fieldValue)) {
            hasAllOperands = false;
            break;
          }

          if (fieldValue === "∞") {
            errorMessage = "Cannot evaluate - one or more operands are infinite";
            fallbackVal = "_DEP_IS_INF";
            hasAllOperands = false;
            break;
          }

          // replace any characters that are invalid in a python variable name
          let sanitized = fieldName.replace(/[^A-Za-z0-9_]+/g, "_");
          if (sanitized.match(/^[0-9].*/)) {
            sanitized = `id_${sanitized}`;
          }

          // replace invalid names with sanitized version if necessary
          if (sanitized !== fieldName) {
            const re = new RegExp(fieldName, "g");
            sanitizedFormula = sanitizedFormula.replace(re, sanitized);
            nameMap[sanitized] = fieldName;
          }

          if (fieldType === "time") {
            let numericalTime = null;
            if (fieldValue?.includes(":")) {
              // convert field value from timestamp to numerical hours value
              const [hour, minute] = fieldValue.split(":");
              const hourParsed = parseInt(hour, 10);
              const minParsed = parseInt(minute, 10);

              numericalTime =
                !Number.isNaN(hourParsed) && !Number.isNaN(minParsed)
                  ? hourParsed + minParsed / 60
                  : null;

              if (
                prevSymbol === "-" &&
                exists(numericalTime) &&
                exists(prevTimeVal) &&
                prevTimeVal < numericalTime
              ) {
                // this is the manipulation necessary to yield the same result
                // as would be returned when considering the end time to be
                // falling on the following day
                numericalTime -= 24;
                prevTimeVal = null;
              } else {
                prevTimeVal = numericalTime;
              }
            }

            if (exists(numericalTime)) operands[sanitized] = `${numericalTime}`;
            else {
              hasAllOperands = false;
              break;
            }
          } else operands[sanitized] = fieldValue;
        } else if (f.previous) {
          // formula part is a field that is referencing a previous value
          let sanitized = fieldName.replace(/[^A-Za-z0-9_]+/g, "_");
          if (sanitized.match(/^[0-9].*/)) {
            sanitized = `id_${sanitized}`;
          }

          // replace any characters that are invalid in a python variable name
          if (sanitized !== fieldName) {
            const re = new RegExp(fieldName, "g");
            sanitizedFormula = sanitizedFormula.replace(re, sanitized);
            nameMap[sanitized] = fieldName;
          }

          hasPrevious.push(sanitized);
        }

        prevSymbol = fieldName;
      }

      if (inWindow && hasAllOperands)
        handleInputRateLimit(sanitizedFormula, {operands, hasPrevious, name, nameMap});
      else {
        setValue(name, fallbackVal, {shouldValidate: !!submitCount});
        setMessage(errorMessage);
        clearTimeout(typing.current);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...Object.keys(linkedFields).map(f => linkedFields[f]),
    formula,
    backupFormulaString,
    inWindow
  ]);

  const readableFormula = useMemo(
    () => (
      <>
        {formula.map((part, idx) => {
          const space = idx < formula.length - 1 ? <>&nbsp;</> : null;
          if (byId && part.value in byId)
            return part.previous ? (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={idx}>
                {byId[part.value].label} <InlineIcon icon={faHistory} />
                {space}
              </Fragment>
            ) : (
              // eslint-disable-next-line react/no-array-index-key
              <Fragment key={idx}>
                {byId[part.value].label}
                {space}
              </Fragment>
            );
          return (
            // eslint-disable-next-line react/no-array-index-key
            <Fragment key={idx}>
              {part.label}
              {space}
            </Fragment>
          );
        })}
      </>
    ),
    [formula, byId]
  );

  const readableFormulaString = useMemo(() => {
    let f = "";
    formula.map(part => {
      if (byId && part.value in byId)
        f += ` ${byId[part.value].label} ${part?.previous ? " PREVIOUS" : ""}`;
      else f += ` ${part.label}`;
    });
    return f;
  }, [byId, formula]);

  const refUnits = useRef();

  return (
    <>
      <FormFieldWrapper ref={generatedRef} data-testid={testId}>
        {label && (
          <LabelWrapper bold inline onClick={e => e.preventDefault()}>
            {globalUnacceptable}
            {label.toUpperCase()}
            {required && <span>*</span>}
            {tag && (
              <Pill quiet>
                <Abbr title={tag}>{tag}</Abbr>
              </Pill>
            )}
            <Help
              title={readableFormulaString}
              icon={<Formula>f(x)</Formula>}
              showModal={isMobile()}>
              {readableFormula}
            </Help>
            {condition && <Help icon={<FontAwesomeIcon icon={faCheckDouble} />}>{condition}</Help>}
            {previousRead && exists(previousRead.value) && (
              <Help icon={<FontAwesomeIcon icon={faHistory} />}>
                {previousRead?.value !== "_NO_PREVIOUS" ? (
                  <>
                    Previous&nbsp;Read:&nbsp;
                    {addCommas(previousRead.value, exists(precision) ? precision : 2)}&nbsp;
                    {units}
                    &nbsp;on&nbsp;
                    {prettyDateInUserTimezone(previousRead.date, settings.timezone, "MMM D YYYY")}
                  </>
                ) : (
                  "No previous record, data will be available on next submission."
                )}
              </Help>
            )}
          </LabelWrapper>
        )}

        {prompt && <Prompt>{prompt}</Prompt>}

        <Inline>
          {qualifier && !qualifierEnabled && (
            <StyledButton
              type="button"
              onClick={() =>
                setValue(`${name}_qualifierEnabled`, true, {shouldValidate: !!submitCount})
              }>
              Qualifier
            </StyledButton>
          )}

          {qualifier && qualifierEnabled && (
            <>
              <StyledCloseButton
                data-testid="close-button"
                type="button"
                onClick={() => {
                  setValue(`${name}_qualifier`, null, {shouldValidate: !!submitCount});
                  setValue(`${name}_qualifierEnabled`, false, {shouldValidate: !!submitCount});
                }}
              />
              <SelectWrapper>
                <InputSelect
                  name={`${name}_qualifier`}
                  placeholder="Select..."
                  options={["<", ">", "≤", "≥", "ND", "DNQ", "EST"]}
                />
              </SelectWrapper>
            </>
          )}

          <InputWrapper>
            {units && (
              <Units data-testid={`${testId}-units`} ref={refUnits}>
                {units}
              </Units>
            )}
            <NumberInput
              id={name}
              ref={innerRef}
              type="number"
              data-testid={`${testId}-input`}
              placeholder={placeholder}
              defaultValue={defaultValue}
              disabled={disabled}
              step="any"
              size="10"
              hidden
              {...register(name, {required})}
            />
            <NumberInput
              type="text"
              placeholder={placeholder}
              disabled={disabled}
              unitPad={refUnits.current?.clientWidth}
              defaultValue={value !== "_NO_PREVIOUS" && value !== "_DEP_IS_INF" ? formatted : null}
            />
          </InputWrapper>
          <Info>{hasSetPoint && `(Set Point: ${setLow} - ${setHigh})`}</Info>
        </Inline>
        {message && (
          <Notice error={message && message.includes("Cannot evaluate - ") ? 1 : 0}>
            {message}
          </Notice>
        )}
        <InputError errors={errors} name={name} />
        {!message && !hasYupErrors && globalUnacceptableTitles?.length && (
          <Error>
            Parameter violation{globalUnacceptableTitles.length > 1 ? "s" : ""}:{" "}
            {globalUnacceptableTitles.join(", ")}
          </Error>
        )}
      </FormFieldWrapper>
      {hasARange && evaluateRange(value, aMin, aMax, strictMin, strictMax, qualifierSelected) && (
        <InputTextArea
          name={`${name}_comment`}
          label={rangeException || defaultUnacceptableParameterPrompt}
          required
          maxLength={1000}
        />
      )}
      {restrictedRangeException &&
        exists(value) &&
        (exists(min) || exists(max)) &&
        submitCount === 0 &&
        evaluateRange(value, min, max, false, false, qualifierSelected) && (
          <Error>{restrictedRangeException}</Error>
        )}
    </>
  );
};

InputGenerated.propTypes = {
  room: PropTypes.string,
  testId: PropTypes.string,
  task: PropTypes.objectOf(PropTypes.any).isRequired,
  taskRecord: PropTypes.objectOf(PropTypes.any),
  taskType: PropTypes.string,
  stage: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  tag: PropTypes.string,
  prompt: PropTypes.string,
  precision: PropTypes.number,
  defaultValue: PropTypes.number,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  hidden: PropTypes.bool,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  units: PropTypes.string,
  depends: PropTypes.arrayOf(PropTypes.string).isRequired,
  trueDepends: PropTypes.arrayOf(PropTypes.string).isRequired,
  formula: PropTypes.arrayOf(PropTypes.any).isRequired,
  trueFormula: PropTypes.arrayOf(PropTypes.any).isRequired,
  formulaString: PropTypes.string.isRequired,
  condition: PropTypes.string,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.instanceOf(Element)})
  ]),
  maxWidth: PropTypes.bool,
  globalUnacceptable: PropTypes.node,
  globalUnacceptableTitles: PropTypes.arrayOf(PropTypes.string),
  aMin: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  aMax: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  min: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  max: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  strictMin: PropTypes.bool,
  strictMax: PropTypes.bool,
  hasARange: PropTypes.bool,
  rangeException: PropTypes.string,
  restrictedRangeException: PropTypes.string,
  hasSetPoint: PropTypes.bool,
  setLow: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setHigh: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  byId: PropTypes.objectOf(PropTypes.any).isRequired,
  previousRead: PropTypes.objectOf(PropTypes.any),
  qualifier: PropTypes.bool,
  errorDefault: PropTypes.string,
  absoluteValue: PropTypes.bool,
  weather: PropTypes.objectOf(PropTypes.any),
  setFormulasEvaluating: PropTypes.func
};

InputGenerated.defaultProps = {
  room: null,
  taskRecord: null,
  taskType: "checksheet",
  testId: "input-generated",
  stage: null,
  label: null,
  tag: null,
  prompt: null,
  placeholder: null,
  defaultValue: null,
  precision: 2,
  required: true,
  readOnly: true,
  hidden: false,
  disabled: false,
  condition: null,
  innerRef: null,
  units: "",
  maxWidth: false,
  globalUnacceptable: null,
  globalUnacceptableTitles: null,
  aMin: null,
  aMax: null,
  min: null,
  max: null,
  strictMin: false,
  strictMax: false,
  hasARange: false,
  rangeException: "",
  restrictedRangeException: "",
  hasSetPoint: false,
  setLow: null,
  setHigh: null,
  previousRead: null,
  qualifier: false,
  errorDefault: null,
  absoluteValue: false,
  weather: null,
  setFormulasEvaluating: null
};

// Style Overrides
const Prompt = styled(Text)`
  margin-top: 2px;
  margin-bottom: ${pad / 2}px;
`;

const InputWrapper = styled.div`
  position: relative;
`;

const Units = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: ${pad / 2}px;
  line-height: initial;
  color: ${({theme}) => theme.tertiary};
  background: ${({theme}) => theme.secondary};
  border-top-right-radius: ${radius};
  border-bottom-right-radius: ${radius};
`;

const NumberInput = styled(Input)`
  &[type="text"],
  &[type="number"] {
    width: ${({unitPad}) => (unitPad ? `${150 + unitPad / 2}px` : "150px")};
    max-width: 200px;
  }

  ${({unitPad}) =>
    unitPad &&
    css`
      padding-right: ${unitPad}px;
    `}
`;

const Notice = styled(Small)`
  padding: ${pad}px 0;

  ${({error}) =>
    error &&
    css`
      color: ${error};
    `}
`;

const Info = styled.span`
  color: ${({theme}) => theme.secondary};
  margin-left: ${pad}px;
`;

const SelectWrapper = styled.div`
  width: 100px;
  margin-right: ${pad}px;
  margin-top: -${pad}px;
`;

const StyledCloseButton = styled(CloseButton)`
  margin-right: ${pad}px;
  margin-left: 0;
`;

const StyledButton = styled(Button)`
  margin-right: ${pad}px;
  padding: ${pad}px;
  ${voice.quiet}
`;

const LabelWrapper = styled(Label)`
  gap: 6px;
  margin-bottom: 2px;
`;

const InlineIcon = styled(FontAwesomeIcon)`
  fill: ${({theme}) => theme.tertiary};
`;

const Formula = styled.span`
  ${voice.quiet};
  font-weight: bold;
  padding: ${pad / 2}px;
  color: ${({theme}) => theme.secondary};
`;

export default InputGenerated;

// Utils
import {generateUniqueKey} from "../../utils/helpers";

// Classes
import Row from "./Row";

export default class Column {
  rows;
  id;

  constructor({region, column} = {}) {
    if (column) {
      this.rows = column.rows.map(row => new Row({row}));
      this.id = column.id;
    } else {
      this.rows = region ? [new Row({region})] : [];
      this.id = generateUniqueKey("column");
    }
  }

  getMaxHeight() {
    return Math.max(...this.rows.map(row => row.height || 0)) || 0;
  }

  setRows(rows) {
    this.rows = rows || [];
  }

  // removes idx at split
  splitColumn(idx) {
    if (idx > this.rows.length - 1) return [this.rows, null];

    const top = this.rows.slice(0, idx);
    const bottom = idx < this.rows.length - 1 ? this.rows.slice(idx + 1, this.rows.length) : null;
    return [top && top.length ? top : null, bottom && bottom.length ? bottom : null];
  }

  getRegionTop(idx) {
    let height = 0;
    for (let i = 0; i < idx; i++) {
      if (this.rows[i]) height += this.rows[i].height || 0;
      else break;
    }
    return height;
  }

  // keeps idx at split
  splitColumnAtHeight(height) {
    const top = [];
    let bottom = null;
    let currHeight = 0;
    let i = 0;
    while (currHeight < height) {
      if (!this.rows[i]) break;
      top.push(this.rows[i]);
      currHeight += this.rows[i].height;
      i += 1;
    }
    if (i < this.rows.length) {
      bottom = this.rows.slice(i, this.rows.length);
    }

    return [top && top.length ? top : null, bottom && bottom.length ? bottom : null];
  }

  addToColumn(region) {
    const row = new Row({region});
    this.rows.push(row);
  }

  insertToColumn(row, idx) {
    if (this.rows.length > idx) this.rows.splice(idx, 0, row);
    else this.rows.push(row);
  }

  getHeight() {
    let height = 0;
    this.rows.map(row => {
      height += row.height || 0;
    });
    return height;
  }

  removeFromColumn(idx) {
    const row = this.rows[idx];
    if (row) {
      return this.rows.splice(idx, 1)[0];
    }
    return null;
  }

  getFromColumn(idx) {
    const row = this.rows[idx];
    if (row) return row;

    return null;
  }

  updateColumn(rowIdx, update) {
    const row = this.rows[rowIdx];

    if (row) {
      row.updateRegion(update, true);
    }
  }
}

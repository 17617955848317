import React, {useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

// Utils
import useApi from "../../hooks/useApi.js";
import {dateHasEvent} from "./helpers.js";
import {SettingsContext} from "../../contexts/settings.js";

// Components
import Modal from "../../components/Modal.js";
import InputDate from "../../components/form/InputDate.js";
import InputTextArea from "../../components/form/InputTextArea.js";

// Style
import {
  HeadingCenter,
  Button,
  Form,
  ButtonLoader,
  Text,
  FormField
} from "../../style/components/general.js";

const ModalEventAddition = ({visible, setVisible, event, date, reload, hasBackButton, goBack}) => {
  const {api: apiEvents} = useApi("events");

  const {settings} = useContext(SettingsContext);
  const [loading, setLoading] = useState(false);

  const schema = yup.object().shape({
    due: yup
      .string()
      .nullable()
      .required()
      .test({
        test: val => !dateHasEvent(event, settings.timezone, val),
        message: "This event is already due on selected day"
      }),
    message: yup.string().nullable().required("Please provide explanation")
  });

  const form = useForm({
    defaultValues: {due: date},
    resolver: yupResolver(schema)
  });
  const {handleSubmit, reset} = form;

  useEffect(() => {
    if (!visible) reset({});
  }, [reset, visible]);

  const create = ({due, message}) => {
    const addition = event.addition ? [...event.addition] : [];

    // Remove existing
    if (addition?.map(({date: d}) => d).includes(date)) {
      const target = addition.indexOf(date);
      addition.splice(target);
    }

    addition.push({date: due, message});

    apiEvents
      .callPut(event.id, {addition})
      .then(({status}) => {
        if (status === 200) {
          reset({});
          reload();
          setVisible(false);
        }
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal visible={visible} setVisible={setVisible} hasBackButton={hasBackButton} goBack={goBack}>
      <HeadingCenter>
        {event?.addition?.includes(date) ? "Edit" : "Add"} Additional&nbsp;
        {event.type.name.toUpperCase()}
      </HeadingCenter>
      <Text>
        This will add an additional {event.type.name.toUpperCase()} to the existing event. It will
        only be required on the date specified below once.
      </Text>
      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(create)}>
          <FormField>
            <InputDate name="due" label="Due Date" />
          </FormField>
          <FormField>
            <InputTextArea name="message" label="Explanation" />
          </FormField>
          <Button type="submit" loading={loading ? 1 : 0}>
            CREATE {event.type.name.toUpperCase()} {loading && <ButtonLoader />}
          </Button>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalEventAddition.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  event: PropTypes.objectOf(PropTypes.any).isRequired,
  date: PropTypes.string.isRequired,
  reload: PropTypes.func.isRequired,
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func
};

ModalEventAddition.defaultProps = {
  hasBackButton: false,
  goBack: null
};

export default ModalEventAddition;

import React, {useContext, useState, useEffect, useCallback, useRef, useMemo} from "react";
import PropTypes from "prop-types";
import {Link} from "react-router-dom";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog, faExclamation, faFileExport} from "@fortawesome/free-solid-svg-icons";

// Contexts
import {AuthContext} from "../../contexts/auth.js";
import {useSocket} from "../../contexts/socket.js";

// Hooks
import useMountedState from "../../hooks/useMountedState.js";
import useApi from "../../hooks/useApi.js";

// Utils
import {prettyDateInUserTimezone} from "../../utils/helpers.js";

// Components
import FacilityName from "./TableFacilitiesFacilityName.js";
import BaseTable from "../../components/BaseTable.js";
import Pagination from "../../components/Pagination.js";
import Dropdown from "../../components/Dropdown.js";
import Badge from "../../components/Badge.js";
import ModalExport from "../../components/ModalExport.js";
import ModalViewAllFacilities from "../general/ModalViewAllFacilities.js";

// Style
import {z} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {colors, pad} from "../../style/components/variables.js";
import {
  Heading,
  Inline,
  Text,
  TableWrapper,
  TableHeaderWrap,
  TableFooter,
  Button,
  ListItem,
  List,
  TableMenu,
  Warning,
  Error,
  Small,
  Abbr
} from "../../style/components/general.js";

const TableFacilities = ({
  facilities,
  setFacilities,
  query,
  filters,
  orderBy,
  showArchived,
  currentPage,
  setCurrentPage,
  setTarget,
  setShowModalSettings,
  setShowModalFavorite
}) => {
  const isMounted = useMountedState();

  const {currentUser, roleCanAccessResource} = useContext(AuthContext);
  const socket = useSocket();

  const [visible, setVisible] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModalExport, setShowModalExport] = useState(false);
  const [showModalViewAll, setShowModalViewAll] = useState(false);
  // Pagination
  const [total, setTotal] = useState(0);
  const [pageTotal, setPageTotal] = useState(1);
  const [limits, setLimits] = useState([]);
  const [limit, setLimit] = useState(5);
  const [trueLimit, setTrueLimit] = useState(5);

  const retrievingFacilities = useRef(false);

  const {api} = useApi("facilities");

  const favorites = useMemo(() => currentUser?.favorites || [], [currentUser]);

  const formatFacilities = useCallback(
    prevFacilities =>
      prevFacilities?.map(facility => {
        const {isDeleted, id, slug, type, details, checksheets, events} = facility;
        return {
          isDeleted,
          id: id,
          name: (
            <FacilityName
              facility={facility}
              socket={socket}
              favorites={favorites}
              setTarget={setTarget}
              setShowModalFavorite={setShowModalFavorite}
            />
          ),
          type: <List column>{type}</List>,
          jobs: details?.jobs?.length > 0 && (
            <List column>
              {details.jobs.map(job => (
                <ListItem key={job}>{job}</ListItem>
              ))}
            </List>
          ),
          checksheets: checksheets?.map(
            ({id: cId, name: cName, frequency, dateDue, draftCount, overdueCount}) => (
              <NotifyList key={cId} column>
                <TableLink to={`/facilities/${slug}/tasks`} title={cName}>
                  {cName}
                  {frequency?.name && <Frequency>({frequency.name})</Frequency>}
                </TableLink>
                <NotifyListItem>
                  <Small>Due by {prettyDateInUserTimezone(dateDue)}</Small>
                </NotifyListItem>
                {draftCount > 0 && (
                  <NotifyListItem>
                    <Warning>{draftCount} incomplete record(s)</Warning>
                  </NotifyListItem>
                )}
                {overdueCount > 0 && (
                  <NotifyListItem>
                    <Error>{overdueCount} overdue record(s)</Error>
                  </NotifyListItem>
                )}
              </NotifyList>
            )
          ),
          events: events?.map(
            ({id: eId, name: eName, available, dateDue, draftCount, overdueCount}) => (
              <NotifyList key={eId} column>
                <TableLink to={`/facilities/${slug}/schedule`} title={eName}>
                  {eName}
                </TableLink>
                {available && (
                  <NotifyListItem>
                    <Small>Due by {prettyDateInUserTimezone(dateDue)}</Small>
                  </NotifyListItem>
                )}
                {draftCount > 0 && (
                  <NotifyListItem>
                    <Warning>{draftCount} incomplete record(s)</Warning>
                  </NotifyListItem>
                )}
                {overdueCount > 0 && (
                  <NotifyListItem>
                    <Error>{overdueCount} overdue record(s)</Error>
                  </NotifyListItem>
                )}
              </NotifyList>
            )
          ),
          settings: (roleCanAccessResource("facility", "delete") ||
            roleCanAccessResource("facility", "archive") ||
            roleCanAccessResource("facility", "create")) && (
            <Settings
              type="button"
              onClick={() => {
                setTarget(facility);
                setShowModalSettings(true);
              }}>
              {!facility.hasNotifications && (
                <Badge offsetX="7px" offsetY="8px" color={colors.red} diameter="16px">
                  <StyledAbbr title="Notifications not configured">
                    <FontAwesomeIcon icon={faExclamation} />
                  </StyledAbbr>
                </Badge>
              )}
              <FontAwesomeIcon icon={faCog} className="primaryIcon">
                Settings
              </FontAwesomeIcon>
            </Settings>
          )
        };
      }),
    [
      favorites,
      roleCanAccessResource,
      setShowModalFavorite,
      setShowModalSettings,
      setTarget,
      socket
    ]
  );

  const getFacilities = useCallback(
    p => {
      setLoading(true);

      const filter = {};

      const payload = {
        page: p || 1,
        orderBy: orderBy,
        groupBy: "name",
        limit: trueLimit,
        withChecksheets: true,
        withEvents: true,
        withDeleted: showArchived
      };

      if (query) filter.Search = query;
      if (filters?.types) filter.Type = filters.types;
      if (filters?.checksheets) filter.Checksheet = filters.checksheets;
      if (filters?.states) filter.State = filters.states;

      if (filter && Object.keys(filter).length > 0) payload.filter = JSON.stringify(filter);

      api.callGet(null, payload).then(({status, data}) => {
        if (status === 200 && data) {
          const {facilities: facilityData, page, pages, total: cTotal} = data;
          const formatted = formatFacilities(facilityData);

          // Page Limit
          const option = value => ({
            label: value,
            value
          });

          let count = 10;
          const temp = [option(5)];
          while (count < cTotal + 10 && count <= 30) {
            temp.push(option(count));
            count += 10;
          }

          if (cTotal > temp[temp.length - 1].value) temp.push({label: "All", value: cTotal});

          setTotal(cTotal);
          setCurrentPage(page);
          setPageTotal(pages);
          setVisible(prev => (limit <= 30 || page === 1 ? formatted : [...prev, ...formatted]));
          setFacilities(facilityData);
          setLimits(temp);
          setLoading(false);
          retrievingFacilities.current = false;
        }
      });
    },
    [
      api,
      filters,
      formatFacilities,
      trueLimit,
      limit,
      orderBy,
      query,
      setCurrentPage,
      setFacilities,
      showArchived
    ]
  );

  // Initial Load
  useEffect(() => {
    if (isMounted() && facilities === null && !retrievingFacilities.current) {
      retrievingFacilities.current = true;
      getFacilities();
    }
  }, [facilities, getFacilities, isMounted]);

  // Load more on scroll
  const handleScroll = useCallback(() => {
    const {scrollHeight, clientHeight, scrollTop} = document.documentElement;
    const maxScrollTop = scrollHeight - clientHeight;

    if (
      limit > 30 &&
      scrollTop + 1 >= maxScrollTop &&
      currentPage < pageTotal &&
      !loading &&
      !retrievingFacilities?.current
    ) {
      retrievingFacilities.current = true;
      getFacilities(currentPage + 1);
    }
  }, [currentPage, limit, loading, pageTotal, getFacilities]);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  // Update facilities on query, sort, limit or delete
  useEffect(() => {
    if (isMounted()) setFacilities(null);
  }, [isMounted, query, orderBy, limit, showArchived, setFacilities, filters]);

  return (
    <>
      <TableWrapper>
        <TableHeaderWrap>
          <TableHeading>Facilities</TableHeading>
          <TableMenu>
            {roleCanAccessResource("facility", "export") && facilities && facilities.length > 0 && (
              <Button type="button" onClick={() => setShowModalExport(true)}>
                <FontAwesomeIcon icon={faFileExport} />
              </Button>
            )}
          </TableMenu>
        </TableHeaderWrap>
        <BaseTable
          headings={{
            settings: {header: " ", disabled: true},
            id: {header: "", disabled: true},
            name: {header: "Name", disabled: true},
            type: {header: "Type(s)", disabled: true},
            jobs: {header: "Job Number(s)", disabled: true},
            checksheets: {header: "Checksheets", disabled: true},
            events: {header: "Events", disabled: true}
          }}
          data={visible}
          updateData={() => getFacilities(currentPage)}
          orderBy={orderBy}
          groupBy="name"
          loading={loading}
          alignRows="top"
          cellOverflow
        />
        <TableFooter>
          {total > 0 && (
            <>
              <Inline>
                {total > 10 && limits.length > 0 && (
                  <PageLimit>
                    <Dropdown
                      options={limits}
                      selection={limit}
                      setSelection={selection => {
                        setLimit(selection);
                        setTrueLimit(selection <= 30 ? selection : 10);
                        setCurrentPage(1);
                      }}
                    />
                  </PageLimit>
                )}
                <Text quiet>
                  {limit <= 30 && pageTotal > 1 && limits.length > 0 && "per page, "}
                  <ModalViewLink
                    disabled={!total || total <= 10}
                    onClick={!total || total <= 10 ? undefined : () => setShowModalViewAll(true)}>
                    {total} total
                  </ModalViewLink>
                </Text>
              </Inline>
              {limit <= 30 && pageTotal > 1 && (
                <Pagination
                  current={currentPage}
                  setCurrent={setCurrentPage}
                  pageTotal={pageTotal}
                  updateData={() => getFacilities(currentPage)}
                  loading={loading}
                />
              )}
            </>
          )}
        </TableFooter>
      </TableWrapper>

      {showModalExport && (
        <ModalExport
          visible={showModalExport}
          setVisible={setShowModalExport}
          getExport={exportParams =>
            api.callGet(null, {
              ...exportParams
            })
          }
        />
      )}

      {showModalViewAll && (
        <ModalViewAllFacilities visible={showModalViewAll} setVisible={setShowModalViewAll} />
      )}
    </>
  );
};

TableFacilities.propTypes = {
  facilities: PropTypes.arrayOf(PropTypes.any),
  setFacilities: PropTypes.func.isRequired,
  query: PropTypes.string,
  filters: PropTypes.objectOf(PropTypes.any),
  orderBy: PropTypes.string,
  showArchived: PropTypes.bool,
  currentPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setTarget: PropTypes.func.isRequired,
  setShowModalSettings: PropTypes.func.isRequired,
  setShowModalFavorite: PropTypes.func.isRequired
};

TableFacilities.defaultProps = {
  facilities: null,
  query: null,
  filters: null,
  orderBy: "asc",
  showArchived: false
};

// Style Overrides
const TableHeading = styled(Heading)`
  white-space: nowrap;
`;

const PageLimit = styled.div`
  margin-right: ${pad}px;

  select {
    padding: ${pad / 2}px;
  }
`;

const NotifyList = styled(List)`
  list-style: circle;
`;

const NotifyListItem = styled(ListItem)`
  padding: ${pad / 2}px 0;
  margin-left: ${pad * 1.5}px;
`;

const TableLink = styled(Link)`
  font-weight: bold;
  align-self: flex-start;
  vertical-align: top;

  &:hover {
    color: ${({theme}) => theme.primary};
    transition: all ease 0.5s;
  }
`;

const Frequency = styled.span`
  text-transform: uppercase;
  padding: 0 ${pad / 2}px;
  color: ${({theme}) => theme.secondary};
  ${voice.quiet};
`;

const ModalViewLink = styled.span`
  ${({disabled}) =>
    !disabled &&
    css`
      text-decoration: underline;
      cursor: pointer;
    `}
`;

const StyledAbbr = styled(Abbr)`
  padding-left: 0.55px;
`;

const Settings = styled(Button)`
  position: absolute;
  top: 0;
  left: 0;
  padding: ${pad}px ${pad}px ${pad / 2}px;
  background: transparent;
  z-index: ${z("top")};

  .primaryIcon {
    fill: ${({theme}) => theme.secondary};
  }
`;

export default TableFacilities;

import {useState, createContext, useCallback, useMemo, useEffect} from "react";
import PropTypes from "prop-types";
import {useLocation} from "react-router-dom";

export const FacilityNavContext = createContext();

const FacilityNavProvider = ({children}) => {
  const [options, setOptions] = useState({});
  const [facility, setFacility] = useState(null);
  const [analytics, setAnalytics] = useState(null);
  const [allOrder, setAllOrder] = useState(null);

  const available = useMemo(() => Object.keys(options).filter(key => options[key].rule), [options]);

  const location = useLocation();

  useEffect(() => {
    const facilitySlug = location.pathname.replace("/facilities").split("/")[1];
    if (facility && facilitySlug !== facility.slug) setFacility(null);
  }, [facility, location]);

  const addOptions = useCallback(newOptions => setOptions(prev => ({...prev, ...newOptions})), []);

  const removeOptions = useCallback(
    names => {
      const temp = {...options};
      names.map(name => delete temp[name]);
      setOptions(temp);
    },
    [options]
  );

  // Make the provider update only when it should.
  // We only want to force re-renders on dependencies
  const memoedValue = useMemo(
    () => ({
      facility,
      setFacility,
      options,
      addOptions,
      removeOptions,
      available,
      analytics,
      setAnalytics,
      allOrder,
      setAllOrder
    }),
    [facility, options, addOptions, removeOptions, available, analytics, allOrder]
  );

  return <FacilityNavContext.Provider value={memoedValue}>{children}</FacilityNavContext.Provider>;
};

FacilityNavProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default FacilityNavProvider;

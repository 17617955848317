import React, {useState, useCallback, useContext, useMemo, useEffect} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArchive, faExclamationCircle, faRedo} from "@fortawesome/free-solid-svg-icons";
import dayjs from "dayjs";

// Utils
import usePrevious from "../../hooks/usePrevious.js";
import {formatDate} from "../../utils/helpers.js";
import {getIcon, handleFilters} from "./helpers.js";
import {isMobile} from "../../utils/responsive.js";
import {AuthContext} from "../../contexts/auth.js";
import {CalendarContext} from "../../contexts/calendar.js";
import {DEFAULT_MARKER} from "../../utils/google/maps.js";

// Components
import ButtonMultiClick from "../../components/ButtonMultiClick.js";

// Style
import {bp} from "../../style/components/breakpoints.js";
import {voice} from "../../style/components/typography.js";
import {flex, z} from "../../style/components/mixins.js";
import {animateBackground, fadeIn} from "../../style/components/animations.js";
import {pad, border, radius, colors} from "../../style/components/variables.js";
import {Text, Small, Loader, NotLoaded, Abbr} from "../../style/components/general.js";

const now = new Date();
now.setHours(0, 0, 0, 0);
const today = now;

const Calendar = ({
  events,
  showAll,
  addEvent,
  currentDate,
  currentEvent,
  setCurrentEvent,
  handleClickEvent,
  handleDoubleClickEvent,
  showWeekends,
  filters,
  loading,
  markerIconMap,
  isGlobal
}) => {
  const {roleCanAccessResource} = useContext(AuthContext);

  const {calendar, records, getView, getActive} = useContext(CalendarContext);

  const [count, setCount] = useState(null);

  const formattedEvents = useMemo(() => {
    if (!calendar || !events) return null;

    const virtual = events;

    if (currentDate && currentEvent) {
      const eventsOnDay = events[currentDate];
      const updated = eventsOnDay ? eventsOnDay.filter(e => e.id === currentEvent.id)[0] : null;
      if (updated) setCurrentEvent(updated);
    }

    return virtual;
  }, [calendar, events, currentDate, currentEvent, setCurrentEvent]);

  // Events per cell
  const getCountBasedOnScreenSize = useCallback(() => {
    const height = window.innerHeight;
    const view = getView();
    if (view === "month" && (isMobile() || height < 725)) return 1;
    if (isMobile() && view !== "month") return 10;
    if (height < 725 && view !== "month") return 5;
    if (height < 885 && view === "month") return 2;
    if (height < 885 && view !== "month") return 15;
    if (height > 885 && view !== "month") return 20;
    if (height > 918 && view !== "month") return 25;
    return 3;
  }, [getView]);

  const prevView = usePrevious(getView());
  useEffect(() => {
    const view = getView();
    if (view !== prevView) setCount(getCountBasedOnScreenSize());

    const handleResize = () => setCount(getCountBasedOnScreenSize());

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => document.removeEventListener("resize", handleResize);
  }, [getView, prevView, getCountBasedOnScreenSize]);

  const getRecordAttr = (dateStr, id) => {
    if (records[dateStr] && id in records[dateStr])
      return {
        completedAt: records[dateStr][id]?.completedAt ?? null,
        createdAt: records[dateStr][id].createdAt,
        draft: records[dateStr][id].draft,
        record: records[dateStr][id]
      };
    return {};
  };

  const filterCount = useMemo(() => {
    let c = 0;
    if (filters)
      Object.keys(filters).map(filter => {
        c += filters[filter]?.length || 0;
      });
    return c;
  }, [filters]);

  const renderEventBadges = (eventsOnDay, date, rowIdx) => {
    const dateStr = formatDate(date);
    const ct = getCountBasedOnScreenSize();
    const adjusted = (date.getTime() === today.getTime() || rowIdx === 0) && ct > 1 ? ct - 1 : ct;
    if (count === null) setCount(ct);

    const filtered =
      filterCount > 0
        ? eventsOnDay.filter(event => handleFilters(filters, event, records))
        : eventsOnDay;

    const handleBadgeSingleClick = event => {
      if (isMobile())
        showAll(
          filtered.map(e => ({
            ...e,
            ...getRecordAttr(dateStr, e.id)
          })),
          dateStr
        );

      if (handleClickEvent && !isMobile()) handleClickEvent(event, dateStr, eventsOnDay);
    };

    return (
      <>
        {filtered.map((event, index) => {
          const {id, name, stages, group, availableBase, availableIndex, frequency} = event;
          const {byId} = event.facility?.builder || {};

          const record =
            records && records[dateStr] && id in records[dateStr] ? records[dateStr][id] : null;

          let stagesComplete = !stages || stages.allIds?.length === 0;
          let progress = "";
          if (record?.stage && stages?.allIds?.length) {
            const offset = record?.draft ? 0 : 1;
            const currentIndex = stages.allIds.indexOf(record?.stage) + offset;
            const numStages = stages.allIds?.length;

            stagesComplete = stages.allIds[numStages - 1] === record?.stage;

            const show = record?.stage && numStages > 1 && currentIndex < numStages;
            progress = show ? `${currentIndex + 1}/${numStages}` : "";
          }

          const sampleIcon = getIcon(event.type.icon);

          const targetGroup = group && byId && group in byId && byId[group] ? byId[group] : null;

          let groupMarker = null;

          if (targetGroup)
            groupMarker =
              Object.keys(markerIconMap)?.length > 0 &&
              targetGroup?.hasAddress &&
              targetGroup?.markerId
                ? markerIconMap[targetGroup?.markerId]
                : DEFAULT_MARKER;

          const day = dayjs(dateStr);
          let isPastDue = day.isBefore(dayjs(), "days");
          if (availableBase && availableIndex) {
            let offset = null;

            // offset is the number of months the due date must be shifted
            // backwards to make it so that the due date checked falls on the end
            // of the availability window

            const {base, interval} = frequency || {};

            if (base && interval) {
              if (availableBase === "months") {
                let totalLength;

                if (base === "months") totalLength = interval;
                else totalLength = 12;

                offset = totalLength - availableIndex;
              } else {
                offset = interval - availableIndex;
              }

              const intervalEnd = day.subtract(offset, availableBase);

              isPastDue = intervalEnd.isBefore(dayjs(), "days");
            }
          }

          return (
            <Event
              key={`${id}-${name}`}
              aria-label={`event-${id}`}
              completedAt={(record?.completedAt && !record?.draft && stagesComplete) || isPastDue}
              isArchived={event?.isArchived}
              disabled={!handleClickEvent}
              hidden={index > adjusted - 1}
              handleSingleClick={() => handleBadgeSingleClick(event)}
              handleDoubleClick={() => handleDoubleClickEvent(dateStr)}>
              <Small inverted>
                <Abbr title={name}>
                  {event?.isArchived && (
                    <Abbr title="Archived">
                      <Icon icon={faArchive} color={colors.yellow} />
                    </Abbr>
                  )}
                  {event?.frequency && <Icon icon={faRedo} />}
                  {sampleIcon && <Icon icon={sampleIcon} color={`#${event.type.color}`} />}
                  {targetGroup && groupMarker && (
                    <Icon icon={groupMarker.icon} color={`#${groupMarker.color}`} />
                  )}
                  {isPastDue && !record && <Overdue icon={faExclamationCircle} />}
                  {record?.draft && <Warning icon={faExclamationCircle} />}
                  {record && stages?.allIds?.length > 0 && progress}&nbsp;
                  {name}
                </Abbr>
              </Small>
            </Event>
          );
        })}

        {isGlobal && filtered?.length > 1 && filtered.length <= adjusted && (
          <More
            onClick={() => {
              showAll(
                filtered.map(e => ({
                  ...e,
                  ...getRecordAttr(dateStr, e.id)
                })),
                dateStr
              );
            }}>
            View All
          </More>
        )}

        {filtered.length > adjusted && (
          <More
            onClick={() =>
              showAll(
                filtered.map(e => ({
                  ...e,
                  ...getRecordAttr(dateStr, e.id)
                })),
                dateStr
              )
            }>
            {!isMobile() ? `Show ${filtered.length - adjusted}` : ""} More
          </More>
        )}
      </>
    );
  };

  // calendar cell component w/ click logic
  const handleCellSingleClick = (eventsOnDay, dateStr) => {
    const filtered =
      filters?.length > 0
        ? eventsOnDay.filter(e => filters.includes(e.type.toUpperCase()))
        : eventsOnDay;

    if ((isMobile() && filtered?.length > 0) || (!isMobile() && filtered?.length > (count ?? 3)))
      showAll(
        filtered.map(e => ({
          ...e,
          ...getRecordAttr(dateStr, e.id)
        })),
        dateStr
      );
  };

  return (
    <CalendarWrapper>
      {calendar?.map((week, x) => (
        <Week key={week[x].name} notfirst={x}>
          {week.map((day, y) =>
            !showWeekends && (y === 0 || y === 6) ? null : (
              <Day
                key={day.date}
                day={y}
                week={x}
                month={day.date.getMonth() === dayjs(getActive()).month()}
                view={getView()}
                notfirst={x}
                showWeekends={showWeekends}>
                {x === 0 && <Text center>{day.name}</Text>}
                <DateNumber today={day.date.getTime() === today.getTime()}>
                  {day.date.getDate()}
                </DateNumber>
                {formattedEvents &&
                  formattedEvents[formatDate(day.date)] &&
                  renderEventBadges(formattedEvents[formatDate(day.date)], day.date, x)}
                {roleCanAccessResource("event", "create") && addEvent && (
                  <DayButton
                    handleSingleClick={() =>
                      handleCellSingleClick(
                        formattedEvents[formatDate(day.date)],
                        formatDate(day.date)
                      )
                    }
                    handleDoubleClick={() => handleDoubleClickEvent(formatDate(day.date))}
                  />
                )}
              </Day>
            )
          )}
        </Week>
      ))}
      {loading && (
        <CalendarNotLoaded>
          <CalendarLoader />
        </CalendarNotLoaded>
      )}
    </CalendarWrapper>
  );
};

Calendar.propTypes = {
  events: PropTypes.objectOf(PropTypes.any),
  filters: PropTypes.objectOf(PropTypes.any),
  showAll: PropTypes.func,
  addEvent: PropTypes.func,
  currentDate: PropTypes.string,
  currentEvent: PropTypes.objectOf(PropTypes.any),
  setCurrentEvent: PropTypes.func,
  handleClickEvent: PropTypes.func,
  handleDoubleClickEvent: PropTypes.func,
  showWeekends: PropTypes.bool,
  loading: PropTypes.bool,
  markerIconMap: PropTypes.objectOf(PropTypes.any).isRequired,
  isGlobal: PropTypes.bool
};

Calendar.defaultProps = {
  events: null,
  filters: null,
  showAll: null,
  addEvent: null,
  currentDate: null,
  currentEvent: null,
  setCurrentEvent: null,
  handleClickEvent: () => null,
  handleDoubleClickEvent: () => null,
  showWeekends: true,
  loading: true,
  isGlobal: false
};

// Style Overrides
const CalendarWrapper = styled.div`
  border-top: ${border} solid ${({theme}) => theme.secondary};
`;

const Week = styled.div`
  width: 100%;
  min-height: ${({notfirst}) => (notfirst ? "80px" : "100px")};
  ${flex("row", "wrap", "center", "start")};
`;

const Day = styled.div`
  position: relative;
  padding: ${pad}px;
  width: calc(100% / 7);
  max-width: calc(100% / 7);
  min-height: ${({notfirst}) => (notfirst ? "80px" : "100px")};
  height: 80px;
  color: ${({theme}) => theme.secondary};
  overflow: hidden;

  ${({week, theme}) =>
    week > 0 &&
    css`
      border-top: ${border} solid ${theme.secondary};
    `}

  ${({day, showWeekends, theme}) =>
    ((day > 0 && showWeekends) || day > 1) &&
    css`
      border-left: ${border} solid ${theme.secondary};
    `}

  ${({month, theme}) =>
    !month
      ? css`
          background-color: ${theme.name === "light" ? colors.grayLight : colors.grayDarker};
        `
      : ""}

  ${({view}) =>
    view === "week"
      ? css`
          min-height: 65vh;
        `
      : ""}

  ${({showWeekends}) =>
    !showWeekends
      ? css`
          width: calc(100% / 5);
          max-width: calc(100% / 5);
        `
      : ""}

  ${bp(3)} {
    height: calc(70vh / 5);
    max-height: 20%;
  }
`;

const DateNumber = styled.div`
  text-align: center;
  margin: 0 auto ${pad / 2}px;

  ${({today: t, theme}) =>
    t &&
    css`
      background: ${theme.primary};
      color: ${theme.tertiary};
      border-radius: 50%;
      width: 27px;
      line-height: 27px;
    `}
`;

const Event = styled(ButtonMultiClick)`
  cursor: pointer;
  position: relative;
  width: 100%;
  max-width: 100%;
  padding: 0 ${pad / 2}px;
  margin-bottom: ${pad / 2}px;
  border-radius: ${radius};
  color: ${({theme}) => theme.tertiary};
  background-color: ${({theme}) => theme.secondary};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1.3;
  z-index: ${z("above")};

  ${({completedAt, isArchived}) =>
    (completedAt || isArchived) &&
    css`
      opacity: 0.5;
    `}

  ${({disabled}) =>
    disabled &&
    css`
      cursor: default;
    `}

  ${({hidden}) =>
    hidden &&
    css`
      display: none;
    `}
`;

const DayButton = styled(ButtonMultiClick)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${z("base")};
`;

const More = styled.button`
  ${voice.quiet};
  position: relative;
  padding: 0;
  line-height: 0.7em;
  background: none;
  z-index: ${z("above")};
`;

const CalendarNotLoaded = styled(NotLoaded)`
  z-index: ${z("above")};
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  margin: 0;
  animation: ${animateBackground(0, 0.2)} 150ms linear 0.2s 1 forwards;
`;

const CalendarLoader = styled(Loader)`
  margin: 0;
  animation: ${fadeIn} 150ms linear 0.2s 1 forwards;

  &:after {
    top: calc(50% - 12.5px);
  }
`;

const Icon = styled(FontAwesomeIcon)`
  ${voice.quiet};
  margin-right: ${pad / 2}px;
  font-weight: bold;
  fill: ${({color, theme}) => color || theme.tertiary};
`;

const Overdue = styled(Icon)`
  fill: ${({theme}) => theme.error};
`;

const Warning = styled(Icon)`
  fill: ${({theme}) => theme.warning};
`;

export default Calendar;

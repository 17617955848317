import React from "react";
import {useForm, FormProvider} from "react-hook-form";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import styled, {css} from "styled-components";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

// Components
import {InputText} from "../../components/form/FormInputs.js";

// Style
import {Button, Form, FormField} from "../../style/components/general.js";
import {flex} from "../../style/components/mixins.js";
import {pad} from "../../style/components/variables.js";

const defaultValues = {label: ""};

const AddElement = ({builder, addElement, linkedGroup}) => {
  const {allIds, byId} = builder;

  const schema = yup.object().shape({
    label: yup
      .string()
      .required("Please provide an Element Name")
      .test({
        test: val => !val.match(/.*[.,'"].*/),
        message: "Element name may not contain periods, commas or quotation marks."
      })
      .test({
        test: val => {
          if (linkedGroup)
            return (
              byId[linkedGroup]?.children?.filter(
                key => byId[key]?.label?.toLowerCase() === val.toLowerCase()
              )?.length === 0
            );

          return (
            allIds.filter(key => byId[key]?.label?.toLowerCase() === val.toLowerCase())?.length ===
            0
          );
        },
        message: linkedGroup
          ? `Component must be unique within ${byId[linkedGroup].label}`
          : "Group must be unique within builder"
      })
  });

  const form = useForm({
    defaultValues,
    resolver: yupResolver(schema)
  });
  const {handleSubmit, reset} = form;

  const handleAddElement = values => {
    addElement({
      ...values,
      children: [],
      linkedGroup: linkedGroup
    });
    reset();
  };

  return (
    <FormProvider {...form}>
      <Form onSubmit={handleSubmit(handleAddElement)} noValidate>
        <FormFieldWrapper data-testid="addField" linkedGroup={linkedGroup}>
          <InputText
            name="label"
            testId="label-input"
            placeholder={linkedGroup ? "Add Component..." : "Add Group..."}
            required
          />
          <Button type="submit">
            <FontAwesomeIcon icon={faPlus} cursor="pointer" />
          </Button>
        </FormFieldWrapper>
      </Form>
    </FormProvider>
  );
};

AddElement.propTypes = {
  builder: PropTypes.objectOf(PropTypes.any).isRequired,
  addElement: PropTypes.func.isRequired,
  linkedGroup: PropTypes.string
};

AddElement.defaultProps = {
  linkedGroup: null
};

const FormFieldWrapper = styled(FormField)`
  ${flex("row", "nowrap", "space-between", "center")};
  width: 100%;
  margin-bottom: ${pad}px;

  ${({linkedGroup}) =>
    linkedGroup &&
    css`
      margin-top: ${pad * 2}px;
    `}

  div {
    margin-top: 0;
  }

  ${Button} {
    margin-left: ${pad}px;
  }
`;

export default AddElement;

import React, {useCallback} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

// Components
import {InputError, InputText} from "../../components/form/FormInputs.js";

// Style
import {pad, radius} from "../../style/components/variables.js";
import {FormFieldWrapper, Button, Inline, Text, Label} from "../../style/components/general.js";

const ElementFieldHelp = ({name, label}) => {
  const {
    watch,
    setValue,
    trigger,
    formState: {errors, submitCount}
  } = useFormContext();

  const fields = watch(name);

  const triggerValidation = useCallback(() => {
    if (submitCount > 0) trigger();
  }, [submitCount, trigger]);

  const getHelpItem = (key, value, idx) => {
    const enabledSetting = watch(`${name}.${idx}.enabled`);

    const enabled = !!enabledSetting || !!value;
    return (
      <FieldHelpItem enabled={enabled} key={`${name}.${idx}`}>
        <StyledInline id={name}>
          <LabelWrapper>
            <Text>{key}</Text>
          </LabelWrapper>
          {enabled && (
            <InputText
              name={`${name}.${idx}.value`}
              placeholder="Value"
              callback={triggerValidation}
            />
          )}
        </StyledInline>
        <Inline>
          <Button
            type="button"
            onClick={() => {
              setValue(`${name}.${idx}.value`, null);
              setValue(`${name}.${idx}.enabled`, !enabled);
              triggerValidation();
            }}>
            <FontAwesomeIcon icon={enabled ? faEye : faEyeSlash} />
          </Button>
        </Inline>
      </FieldHelpItem>
    );
  };

  const renderHelp = () => fields?.map(({key, value}, index) => getHelpItem(key, value, index));

  return (
    <StyledFormFieldWrapper standard>
      {label && (
        <Label bold htmlFor={name}>
          {label}
        </Label>
      )}
      {renderHelp()}
      <InputError name={name} errors={errors} atBottom={false} />
      <br />
    </StyledFormFieldWrapper>
  );
};

ElementFieldHelp.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string
};

ElementFieldHelp.defaultProps = {
  label: null
};

// Style Overrides
const StyledFormFieldWrapper = styled(FormFieldWrapper)`
  margin-top: 0;
`;

const FieldHelpItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${pad / 2}px ${pad}px;
  background: ${props => props.theme.secondary};
  border-radius: ${radius};
  margin-top: ${pad}px;
  height: 45px;

  ${props =>
    !props.enabled &&
    css`
      opacity: 0.5;
    `}

  ${FormFieldWrapper} {
    margin: 0;
  }

  svg {
    fill: ${props => props.theme.tertiary};
  }

  span {
    padding: ${pad}px;
    color: ${props => props.theme.tertiary};
  }

  input {
    background: ${props => props.theme.tertiary};
    max-width: 150px;
    margin: 0 ${pad}px;
  }

  ${Button} {
    padding: 0 0 0 ${pad}px;
    background: transparent;
  }
`;

const StyledInline = styled(Inline)`
  width: calc(100% - 35px);
  input {
    background: ${props => props.theme.tertiary};
  }
`;

const LabelWrapper = styled.div`
  width: 30%;
  max-width: 200px;
  text-overflow: ellipsis;
  white-space: nowrap;

  p {
    color: ${props => props.theme.tertiary};
  }
`;

export default ElementFieldHelp;

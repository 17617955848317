import React, {useCallback, useEffect, useState, useMemo} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {useForm, FormProvider} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

// Utils
import useMountedState from "../../hooks/useMountedState.js";
import {exists, generateUniqueKey, objectGuard} from "../../utils/helpers.js";
import {COMPONENT, initialFieldValues, updateElement} from "../../utils/builder.js";
import {getAncestryName, resolveDependencyMismatches} from "./helpers.js";

// Components
import Modal from "../../components/Modal.js";
import RenderChecksheet from "./RenderChecksheet.js";
import ModalExternalTemplateFields from "./ModalExternalTemplateFields.js";
import {InputSelectGroup} from "../../components/form/FormInputs.js";

// Style
import {voice} from "../../style/components/typography.js";
import {border, pad, radius} from "../../style/components/variables.js";
import {
  FormGroup,
  FormField,
  HeadingCenter,
  ButtonFull,
  Form,
  Label,
  Error
} from "../../style/components/general.js";

const ModalApplyTemplate = ({
  visible,
  setShowModal,
  builder,
  saveBuilder,
  template,
  recursePreview,
  units,
  setUnits
}) => {
  const isMounted = useMountedState();

  const defaultComponent = useMemo(() => ({componentToApply: [{}]}), []);

  const schema = yup.object().shape({
    componentToApply: yup.array().of(
      yup.object().shape({
        value: yup.string().required("Please select a component to apply template.")
      })
    )
  });

  const form = useForm({
    defaultValues: defaultComponent,
    resolver: yupResolver(schema)
  });
  const {handleSubmit, reset, watch} = form;

  const [externalIdx, setExternalIdx] = useState(null);
  const [associations, setAssociations] = useState({});
  const [associationComplete, setAssociationComplete] = useState(false);
  const [hasFields, setHasFields] = useState(false);

  const componentToApply = watch("componentToApply");

  const formatOptions = useCallback(
    targetFields =>
      targetFields.map(field => {
        if (field.options && field.options.length > 0)
          return {
            ...field,
            options: field.options.map(option => ({
              option: option.option || option.value || objectGuard(option)
            })),
            toggle: true
          };

        return {...field, toggle: true};
      }),
    []
  );

  const formatSelected = useCallback(() => {
    const templateFields =
      template.fields && template.fields.length > 0
        ? formatOptions(template.fields)
        : [initialFieldValues];

    return {
      id: template.id,
      base: false,
      label: template.label || "",
      grid: template.grid || false,
      hasHelp: template.hasHelp || false,
      help: template.help || [{}],
      fields: templateFields,
      instances: template.instances
    };
  }, [formatOptions, template]);

  const templateBuilder = useMemo(() => {
    const formatted = formatSelected(template);
    const fields = [...formatted.fields];
    delete formatted.fields;
    const children = [];
    const nameMap = {};
    const fieldObjs = Object.fromEntries(
      fields.map(field => {
        const name = generateUniqueKey(field.label);
        nameMap[field.name] = name;
        children.push(name);
        return [
          name,
          {
            ...field,
            parentName: "component",
            name,
            element: "field"
          }
        ];
      })
    );
    const updated = {
      byId: {
        ...fieldObjs,
        component: {
          ...formatted,
          element: "component",
          children,
          label: formatted.label,
          name: "component",
          toggle: true
        }
      },
      allIds: ["component"]
    };
    resolveDependencyMismatches(updated, nameMap);
    return updated;
  }, [formatSelected, template]);

  // Handle components with fields
  useEffect(() => {
    if (isMounted() && componentToApply && componentToApply.length > 0) {
      componentToApply.map(component => {
        if (builder.byId[component.value] && builder.byId[component.value].children.length > 0)
          setHasFields(true);
      });
    }
  }, [isMounted, componentToApply, builder]);

  const handleApplyTemplate = useCallback(() => {
    if (template.external && Object.keys(template.external).length > 0 && !associationComplete) {
      setExternalIdx(0);
      return;
    }

    const components = [...componentToApply];
    let updated = {...builder};
    const previousById = {...builder.byId};
    const newUnits = [];

    const appliedTemplates = {
      [template.id]: components.map(component => component.value)
    };

    let formattedAssociations = {};
    if (Object.keys(associations).length > 0)
      formattedAssociations = Object.fromEntries(
        Object.entries(associations).map(([key, {value}]) => [key, value])
      );

    components.forEach(({value: target}, idx) => {
      if (idx === 0)
        template.fields.map(field => {
          const temp = field.units ? field.units.replace("Other: ", "") : "";
          if (field.units && !units.includes(temp)) {
            setUnits(prev => [...prev, temp].sort());
            newUnits.push(temp);
          }
        });

      const nameMap = {...formattedAssociations};
      const update = {
        element: COMPONENT,
        toggle: true,
        name: target,
        label: builder.byId[target].label,
        parentName: builder.byId[target].parentName,
        hasHelp: template.hasHelp,
        grid: template.grid,
        help: template.hasHelp && template.help ? template.help : null,
        fields: template.fields
          .filter(({type}) => type !== "weather")
          .map(field => ({
            ...field,
            generates: [],
            formula: field.formula ? [...field.formula.map(part => ({...part}))] : null,
            units: field.units ? field.units.replace("Other: ", "") : null,
            condition: field.condition
              ? {...field.condition, list: field.condition.list.map(item => ({...item}))}
              : null
          })),
        templateId: template.id
      };
      ({builder: updated} = updateElement(updated, update, true, nameMap));
      resolveDependencyMismatches(updated, nameMap, true);

      updated.byId = Object.fromEntries(
        Object.entries(updated.byId).filter(([name]) => !name.match("^ext[0-9]*$"))
      );

      if (update.help)
        update.help = update.help.map(item => ({
          ...item,
          id: item.id in nameMap ? nameMap[item.id] : ""
        }));

      if (updated.byId[update.parentName].preview)
        updated.byId = recursePreview(update.name, updated.byId);
    });

    saveBuilder(updated, newUnits, appliedTemplates, previousById);
    setShowModal(false);
    reset({...defaultComponent});
  }, [
    associationComplete,
    associations,
    builder,
    componentToApply,
    defaultComponent,
    recursePreview,
    reset,
    saveBuilder,
    setShowModal,
    setUnits,
    template,
    units
  ]);

  const renderComponentDropdown = () => {
    if (!builder) return null;

    const linkedComponents = Object.values(builder.byId)
      .filter(({element, toggle}) => element === COMPONENT && toggle)
      .map(({name}) => ({value: name, label: getAncestryName(builder.byId[name], builder)}));

    return (
      <FormField>
        <InputSelectGroup
          name="componentToApply"
          placeholder="Apply to Component..."
          options={linkedComponents}
          testId="applyTemplate.componentList"
        />
        {hasFields && (
          <ErrorText>
            There are existing fields associated with selected component(s). Please remove existing
            fields before applying component templates.
          </ErrorText>
        )}
        {linkedComponents.length === 0 && (
          <ErrorText>Please add at least one component to checksheet.</ErrorText>
        )}
      </FormField>
    );
  };

  // triggered when final page is passed on the field replacement process
  useEffect(() => {
    if (associationComplete) handleApplyTemplate();
  }, [handleApplyTemplate, associationComplete]);

  if (exists(externalIdx) && externalIdx < Object.keys(template.external).length)
    return (
      <ModalExternalTemplateFields
        external={template.external}
        externalIdx={externalIdx}
        setExternalIdx={setExternalIdx}
        associations={associations}
        setAssociations={setAssociations}
        templateFields={template.fields}
        byId={builder.byId}
        componentNames={componentToApply.map(({value}) => value)}
        templateName={template.label}
        setComplete={setAssociationComplete}
        setVisible={setShowModal}
      />
    );

  return (
    <Modal testId="facility.addComponent" visible={visible} setVisible={setShowModal}>
      <ModalTitle>Apply Component Template</ModalTitle>
      <FormProvider {...form}>
        <Form>
          <FormGroup>
            <Label>
              <strong>Template:</strong> {template.label}
            </Label>
          </FormGroup>

          <ApplyLabel>Select existing component to apply {template.label}</ApplyLabel>
          {renderComponentDropdown()}
        </Form>
      </FormProvider>

      <ComponentPreview>
        <Label bold>Preview</Label>

        <RenderChecksheet
          task={{
            builder: templateBuilder
          }}
          readOnly
          hideMeta
        />
      </ComponentPreview>
      {template.external && Object.keys(template.external).length > 0 && (
        <Error>
          This template requires relinking of fields that were originally located outside the
          component.
        </Error>
      )}
      <Submit type="button" onClick={handleSubmit(handleApplyTemplate)} disabled={hasFields}>
        {template.external && Object.keys(template.external).length > 0
          ? "Continue"
          : "Apply Template"}
      </Submit>
    </Modal>
  );
};

ModalApplyTemplate.propTypes = {
  visible: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  builder: PropTypes.objectOf(PropTypes.any).isRequired,
  template: PropTypes.objectOf(PropTypes.any),
  saveBuilder: PropTypes.func.isRequired,
  recursePreview: PropTypes.func.isRequired,
  units: PropTypes.arrayOf(PropTypes.string).isRequired,
  setUnits: PropTypes.func.isRequired
};

ModalApplyTemplate.defaultProps = {
  template: null
};

// Style Overrides
const ModalTitle = styled(HeadingCenter)`
  margin: ${pad}px 0;
  color: ${({theme}) => theme.component};
`;

const ComponentPreview = styled(FormGroup)`
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  padding: ${pad}px;
`;

const Submit = styled(ButtonFull)`
  margin-top: ${pad}px;
  margin-bottom: ${pad}px;
`;

const ErrorText = styled.p`
  ${voice.quiet};
  padding: 0 ${pad / 2}px;
  color: ${({theme}) => theme.error};
  margin-top: ${pad}px;
`;

const ApplyLabel = styled(Label)`
  font-weight: bold;
  margin-bottom: -${pad * 1.5}px;
`;

export default ModalApplyTemplate;

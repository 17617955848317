import React, {useState} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";

// Hooks
import useApi from "../../hooks/useApi.js";

// Utils
import {GROUP} from "../../utils/builder.js";
import {
  formatAddressAsString,
  getMapFromAddress,
  getMapFromCoords,
  openLink
} from "../../utils/helpers.js";

// Components
import Modal from "../../components/Modal.js";

// Style
import {pad} from "../../style/components/variables.js";
import {
  Heading,
  AnchorInline,
  Text,
  Button,
  ButtonLoader,
  Abbr,
  Label
} from "../../style/components/general.js";

const ModalHelp = ({visible, setVisible, target, conditionsSatisfied, byId}) => {
  const {api} = useApi("files");

  const [loadingFiles, setLoadingFiles] = useState({});

  const getNoteValue = value => {
    if (value && value.includes("http"))
      return (
        <AnchorInline href={value} target="_blank" rel="noreferrer" data-testid="help.fileLink">
          View
        </AnchorInline>
      );
    return value;
  };

  const hasCoordinates = () =>
    target.coords &&
    target.coords.lat &&
    target.coords.lon &&
    target.coords.lat !== "" &&
    target.coords.lon !== "";

  const getMapFromLocation = () => {
    if (target.address.line1) return getMapFromAddress(formatAddressAsString(target.address));

    return getMapFromCoords(target.address.lat, target.address.lon);
  };

  const openFile = fileId => {
    api.callGet(fileId).then(res => {
      if (res.status === 200) openLink(res.data.link);
      setLoadingFiles(prev => ({...prev, [fileId]: false}));
    });
  };

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <ModalHeading element={target.element}>{target.label}: Support Information</ModalHeading>
      {target.help &&
        target.help
          .filter(
            ({key, value, id}) =>
              key &&
              value &&
              (!id || !byId || !(id in byId) || byId[id]?.type !== "weather") &&
              (!id || !conditionsSatisfied?.current || !!conditionsSatisfied.current[id])
          )
          .map(({key, value, id, fileId}) => (
            <HelpItem
              key={key}
              previewHidden={
                !!conditionsSatisfied?.current && conditionsSatisfied.current[id] === false
              }>
              <Label bold>{key.toUpperCase()}</Label>
              {fileId ? (
                <Button
                  type="button"
                  onClick={() => {
                    setLoadingFiles(prev => ({...prev, [fileId]: true}));
                    openFile(fileId);
                  }}
                  loading={loadingFiles[fileId]}>
                  Open File
                  {loadingFiles[fileId] && <ButtonLoader />}
                </Button>
              ) : (
                <Text>
                  <Abbr title={value}>{getNoteValue(value)}</Abbr>
                </Text>
              )}
            </HelpItem>
          ))}
      {hasCoordinates() && (
        <Text>
          View In Maps&nbsp;
          <AnchorInline
            rel="noreferrer"
            target="_blank"
            data-testid="help.mapLink"
            href={getMapFromCoords(target.coords.lat, target.coords.lon)}>
            Location
          </AnchorInline>
        </Text>
      )}
      {target.hasAddress && (
        <Text>
          View In Maps&nbsp;
          <AnchorInline
            rel="noreferrer"
            target="_blank"
            data-testid="help.mapLink"
            href={getMapFromLocation()}>
            Location
          </AnchorInline>
        </Text>
      )}
      {/* <HelpItem>
        If the information you require is not here submit a{" "}
        <AnchorInline href="/support">Ticket</AnchorInline>
      </HelpItem> */}
    </Modal>
  );
};

ModalHelp.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  target: PropTypes.objectOf(PropTypes.any).isRequired,
  conditionsSatisfied: PropTypes.objectOf(PropTypes.any),
  byId: PropTypes.objectOf(PropTypes.any).isRequired
};

ModalHelp.defaultProps = {
  conditionsSatisfied: null
};

// Style Overrides
const ModalHeading = styled(Heading)`
  color: ${({element, theme}) => (element === GROUP ? theme.group : theme.component)};
`;

const HelpItem = styled.div`
  padding: ${pad}px 0;
  display: block;

  ${({previewHidden}) =>
    previewHidden &&
    css`
      opacity: 0.5;
    `}

  ${Label} {
    color: ${({theme}) => theme.field};
  }
`;

export default ModalHelp;

import React from "react";
import PropTypes from "prop-types";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  Outlet
} from "react-router-dom";
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/tracing";
import {useLoadScript} from "@react-google-maps/api";
import {faMapMarkerAlt} from "@fortawesome/free-solid-svg-icons";
import {library} from "@fortawesome/fontawesome-svg-core";

// Pages
import FacilitiesDashboard from "./pages/FacilitiesDashboard.js";
import FacilityDashboard from "./pages/FacilityDashboard.js";
import FacilityBuilder from "./pages/FacilityBuilder.js";
import ChecksheetBuilder from "./pages/ChecksheetBuilder.js";
import ChecksheetEditor from "./pages/ChecksheetEditor.js";
import ChecksheetPreview from "./pages/ChecksheetPreview.js";
import Checksheet from "./pages/tasks/Checksheet.js";
import ComponentTemplates from "./pages/ComponentTemplates.js";
import ReportBuilder from "./pages/ReportBuilder.js";
import ReportEditor from "./pages/ReportEditor.js";
import Tasks from "./pages/Tasks.js";
import Schedule from "./pages/Schedule.js";
import EventTemplates from "./pages/EventTemplates.js";
import GlobalTables from "./pages/GlobalTables.js";
import Account from "./pages/Account.js";
import UserRoles from "./pages/UserRoles";
import Users from "./pages/Users.js";
import Support from "./pages/Support.js";
import Signin from "./pages/SignIn.js";
import FirstSignin from "./pages/FirstSignin.js";
import ForgotPassword from "./pages/ForgotPassword.js";
import ResetPassword from "./pages/ResetPassword.js";
import Unauthorized from "./pages/Unauthorized.js";
import NotFound from "./pages/NotFound.js";
import Maintenance from "./pages/Maintenance.js";
import FacilityNotifications from "./pages/FacilityNotifications.js";

// Utils
import SettingsProvider from "./contexts/settings.js";
import ToastProvider from "./contexts/toast.js";
import AuthProvider from "./contexts/auth.js";
import NotifyProvider from "./contexts/notify.js";
import SocketProvider from "./contexts/socket.js";
import NavProvider from "./contexts/nav.js";
import FacilityNavProvider from "./contexts/facilitynav.js";
import BannerProvider from "./contexts/banner.js";
import ThemeStateProvider from "./contexts/theme.js";
import CalendarProvider from "./contexts/calendar.js";

// Theming
import {GlobalStyle} from "./style/components/general.js";

// Components
import Nav from "./pages/general/Nav.js";
import Protected from "./components/Protected.js";
import FallbackComponent from "./pages/general/FallbackComponent.js";

library.add(faMapMarkerAlt);

const GOOGLE_CONFIG = {
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
  libraries: ["places"]
};

if (process.env.REACT_APP_SENTRY_DSN)
  Sentry.init({
    environment: process.env.REACT_APP_SENTRY_ENV,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      })
    ],
    tracesSampleRate: parseInt(process.env.REACT_APP_SENTRY_TRACE_SAMPLE_RATE, 10),
    tunnel: `${process.env.REACT_APP_API}/tunnel`
  });

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

const GeneralProtected = ({element}) => (
  <>
    <Nav />
    <Protected element={element} />
  </>
);

GeneralProtected.propTypes = {element: PropTypes.node.isRequired};

const FacilityRoutes = () => {
  const {pathname} = useLocation();
  const isFacilityRoute = pathname !== "/facilities/" && pathname.includes("/facilities/");

  return (
    <FacilityNavProvider>
      <Nav />
      <Protected
        element={isFacilityRoute ? <Outlet /> : <FacilitiesDashboard key="facilities_dashboard" />}
      />
    </FacilityNavProvider>
  );
};

const AppRoutes = () => (
  <Sentry.ErrorBoundary fallback={FallbackComponent}>
    <SentryRoutes>
      {/* Authentication Routes */}
      <Route path="/signin" element={<Signin />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/first-signin" element={<Protected element={<FirstSignin />} />} />

      {/* General Routes */}
      <Route
        path="/global-tables"
        element={<GeneralProtected element={<GlobalTables key="global_custom_tables" />} />}
      />
      <Route
        path="/schedule"
        element={
          <GeneralProtected
            element={
              <CalendarProvider>
                <Schedule key="global_schedule" />
              </CalendarProvider>
            }
          />
        }
      />
      <Route
        path="/event-templates"
        element={<GeneralProtected element={<EventTemplates key="event_templates" />} />}
      />
      <Route
        path="/components"
        element={<GeneralProtected element={<ComponentTemplates key="component_templates" />} />}
      />
      <Route path="/account" element={<GeneralProtected element={<Account key="account" />} />} />
      <Route
        path="/users"
        element={<GeneralProtected element={<Users key="user_management" />} />}
      />
      <Route
        path="/user-roles"
        element={<GeneralProtected element={<UserRoles key="user_roles" />} />}
      />
      <Route path="/support" element={<GeneralProtected element={<Support key="support" />} />} />

      {/* General Unauthorized Fallback */}
      <Route path="/unauthorized" element={<GeneralProtected element={<Unauthorized />} />} />

      {/* Facility Routes */}
      <Route path="/" element={<Navigate to="/facilities" />} />
      <Route path="/facilities" element={<FacilityRoutes />}>
        <Route
          path=":slug"
          element={<Protected element={<FacilityDashboard key="facility_dashboard" />} />}>
          <Route path="checksheets" element={<Outlet />} />
          <Route path="reports" element={<Outlet />} />
          <Route path="tables" element={<Outlet />} />
          <Route path="users" element={<Outlet />} />
        </Route>
        <Route
          path=":slug/builder"
          element={<Protected element={<FacilityBuilder key="facility_builder" />} />}
        />
        <Route
          path=":slug/checksheet-builder"
          element={<Protected element={<ChecksheetBuilder key="checksheet_builder" />} />}
        />
        <Route
          path=":slug/checksheets/:edit/edit"
          element={<Protected element={<ChecksheetEditor key="checksheet_editor" />} />}
        />
        <Route
          path=":slug/checksheets/:edit/preview"
          element={<Protected element={<ChecksheetPreview key="checksheet_preview" />} />}
        />
        <Route path=":slug/tasks" element={<Protected element={<Tasks key="tasks" />} />} />
        <Route
          path=":slug/tasks/:checksheetSlug"
          element={<Protected element={<Checksheet key="checksheet_id" />} />}
        />
        <Route
          path=":slug/schedule"
          element={
            <Protected
              element={
                <CalendarProvider>
                  <Schedule key="schedule" />
                </CalendarProvider>
              }
            />
          }
        />
        <Route
          path=":slug/report-builder"
          element={<Protected element={<ReportBuilder key="report_builder" />} />}
        />
        <Route
          path=":slug/reports/:edit/edit"
          element={<Protected element={<ReportEditor key="report_editor" />} />}
        />
        <Route
          path=":slug/notifications"
          element={<Protected element={<FacilityNotifications key="facility_notifications" />} />}
        />
      </Route>

      {/* General Not Found Fallback */}
      <Route path="/*" element={<Protected element={<NotFound />} />} />

      {/* Maintenance Page */}
      <Route path="/maintenance" element={<Protected element={<Maintenance />} />} />
    </SentryRoutes>
  </Sentry.ErrorBoundary>
);

const App = () => {
  const {loadError: googleError} = useLoadScript(GOOGLE_CONFIG);

  return (
    <ThemeStateProvider>
      <GlobalStyle />

      <SocketProvider>
        <ToastProvider>
          <SettingsProvider>
            <AuthProvider>
              <NotifyProvider>
                <BrowserRouter>
                  <BannerProvider>
                    <NavProvider>
                      {googleError && <FallbackComponent />}
                      <AppRoutes />
                    </NavProvider>
                  </BannerProvider>
                </BrowserRouter>
              </NotifyProvider>
            </AuthProvider>
          </SettingsProvider>
        </ToastProvider>
      </SocketProvider>
    </ThemeStateProvider>
  );
};

export default App;

import React, {Fragment, useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Utils
import {getReadableKey} from "../../utils/helpers.js";
import useMountedState from "../../hooks/useMountedState.js";

// Components
import Modal from "../../components/Modal.js";

// Style
import {flex} from "../../style/components/mixins.js";
import {pad} from "../../style/components/variables.js";
import {
  HeadingCenter,
  Form,
  Text,
  Button,
  FormGroup,
  Span
} from "../../style/components/general.js";

const ModalDeleteElement = ({
  visible,
  setVisible,
  targetElement,
  builder,
  notifications,
  persistDelete,
  hasBackButton,
  goBack
}) => {
  const {element, name, label} = targetElement;

  const isMounted = useMountedState();

  const [deleteNotifications, setDeleteNotifications] = useState([]);

  const itemsDependentOnTarget = useMemo(() => {
    const matches = Object.entries(builder.byId)
      .filter(([, value]) => {
        let itemMatches = [];
        if (value.formula)
          itemMatches = [
            ...itemMatches,
            ...value.formula.filter(
              item => item.type === "variable" && item.value === targetElement.name
            )
          ];

        if (value.condition?.list)
          itemMatches = [
            ...itemMatches,
            ...value.condition.list.filter(item => item.depends === targetElement.name)
          ];

        return itemMatches.length > 0;
      })
      .map(([key]) => key);

    return matches;
  }, [builder, targetElement]);

  useEffect(() => {
    if (isMounted() && notifications && notifications.allIds && notifications.allIds.length > 0) {
      const {allIds, byId} = notifications;
      const matches = allIds.filter(
        id =>
          byId[id].depends === name ||
          (byId[id].trigger === "Unacceptable Parameter" &&
            byId[id].conditionArray.some(({depends}) => depends === name))
      );
      setDeleteNotifications(matches);
    }
  }, [isMounted, name, notifications]);

  const handleSubmit = e => {
    e.preventDefault();
    persistDelete(targetElement, deleteNotifications);
  };

  let canDelete = true;

  const renderMessage = () => {
    if (itemsDependentOnTarget?.length > 0) {
      canDelete = false;
      return (
        <Text>
          Unable to delete. This field is being used to generate the following fields:&nbsp;
          <Span>
            {itemsDependentOnTarget.map(id => {
              if (id in builder.byId) {
                const {label: linkedFieldLabel, parentName} = builder.byId[id];

                return (
                  <Fragment key={id}>
                    {builder.byId[parentName]
                      ? `${builder.byId[parentName].label} ${linkedFieldLabel}`
                      : linkedFieldLabel}
                    <br />
                  </Fragment>
                );
              }
              return (
                <Fragment key={id}>
                  {`Element is malformed, contact admin to delete all occurences of ${getReadableKey(
                    id
                  )}?`}
                  <br />
                </Fragment>
              );
            })}
          </Span>
        </Text>
      );
    }

    if (deleteNotifications && deleteNotifications.length > 0)
      return (
        <Text>
          {label} is used in one or more field notifications. Are you sure you want to delete this{" "}
          {element}, its notifications <Span error>WILL BE DELETED</Span>? This action cannot be
          undone.
        </Text>
      );

    return (
      <Text>Are you sure you want to delete this {element}? This action cannot be undone.</Text>
    );
  };

  return (
    <Modal visible={visible} setVisible={setVisible} hasBackButton={hasBackButton} goBack={goBack}>
      <ModalTitle>Delete Element</ModalTitle>

      <Form onSubmit={handleSubmit} noValidate>
        <FormGroup>{renderMessage()}</FormGroup>
        <Options>
          <Option
            type="button"
            onClick={() => (hasBackButton && goBack ? goBack() : setVisible(false))}>
            Cancel
          </Option>
          {canDelete && <Delete type="submit">Delete</Delete>}
        </Options>
      </Form>
    </Modal>
  );
};

ModalDeleteElement.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  persistDelete: PropTypes.func.isRequired,
  targetElement: PropTypes.objectOf(PropTypes.any).isRequired,
  builder: PropTypes.objectOf(PropTypes.any).isRequired,
  notifications: PropTypes.objectOf(PropTypes.any),
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func
};

ModalDeleteElement.defaultProps = {
  notifications: null,
  hasBackButton: false,
  goBack: () => {}
};

// Style Overrides
const ModalTitle = styled(HeadingCenter)`
  margin-bottom: ${pad * 2}px;
`;

const Options = styled.div`
  ${flex("row", "nowrap", "start", "center")};
  width: 100%;
`;

const Option = styled(Button)`
  margin: 0 ${pad / 2}px;
`;

const Delete = styled(Option)`
  background: ${props => props.theme.error};
  color: ${props => props.theme.tertiary};

  &:disabled {
    opacity: 0.6;
  }
`;

export default ModalDeleteElement;

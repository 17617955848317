import React, {useMemo} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";

// Utils
import {exists} from "../../utils/helpers";
import {isMobileDevice} from "../../utils/responsive";

// Styles
import {heroTheme} from "../../style/components/variables";

const ChartBar = ({...props}) => {
  const {
    chartId,
    id,
    x,
    xDisplay,
    y,
    sourceIdx,
    numSources,
    barWidth,
    zero,
    isNegative,
    yDisplay,
    qualifier,
    missing,
    source,
    color,
    units,
    label,
    width,
    setTooltipData,
    setTooltipPosition,
    tooltipPosition,
    tooltips,
    setActive,
    active
  } = props;

  const mouseover = () => {
    setTooltipData({
      x: xDisplay,
      y: yDisplay,
      units,
      qualifier,
      label: label,
      color,
      missing
    });
  };

  const mouseleave = () => {
    setTooltipPosition({top: null, left: null, right: null});
    setTooltipData({});
  };

  const mousemove = ({nativeEvent}) => {
    if (
      nativeEvent &&
      exists(nativeEvent.offsetX) &&
      exists(nativeEvent.offsetY) &&
      nativeEvent.offsetX > width / 2
    )
      setTooltipPosition({
        top: `${nativeEvent.offsetY}px`,
        left: null,
        right: `${width - nativeEvent.offsetX + 20}px`
      });
    else if (nativeEvent && exists(nativeEvent.offsetX) && exists(nativeEvent.offsetY))
      setTooltipPosition({
        top: `${nativeEvent.offsetY}px`,
        left: `${nativeEvent.offsetX + 20}px`,
        right: null
      });
    else setTooltipPosition({});
  };

  const onclick = ({nativeEvent}) => {
    if (setActive)
      setActive({
        source,
        date: xDisplay
      });

    if (tooltips && nativeEvent && isMobileDevice()) {
      if (tooltipPosition && tooltipPosition.top) mouseleave();
      else {
        mousemove({nativeEvent});
        mouseover();
      }
    } else setTooltipPosition({});
  };

  const xPos = useMemo(() => {
    let temp = x;
    if (numSources % 2 === 1) temp -= barWidth / 2;
    const half = Math.floor(numSources / 2);
    const multiplier = sourceIdx - half;
    temp += barWidth * multiplier;
    return temp;
  }, [numSources, x, sourceIdx, barWidth]);

  const height = useMemo(() => Math.abs(zero - y), [zero, y]);

  const yPos = useMemo(() => {
    if (height < 1) {
      if (isNegative) return zero + 1;
      return y - 1;
    }
    if (isNegative) return zero;
    return y;
  }, [isNegative, zero, y, height]);

  return (
    <Bar
      id={id}
      className="bars"
      x={xPos}
      y={yPos}
      height={Math.max(height, 1)}
      // need to subtract 1 from width because of stroke causing bars to overlap
      width={barWidth - 1}
      fill={color}
      color={color}
      clipPath={`url(#clip-sp-${chartId})`}
      onMouseEnter={tooltips ? mouseover : null}
      onMouseMove={tooltips ? mousemove : null}
      onMouseLeave={tooltips ? mouseleave : null}
      onClick={tooltips ? onclick : null}
      active={active}
      source={source}
      xDisplay={xDisplay}
      missing={missing}
    />
  );
};

ChartBar.propTypes = {
  chartId: PropTypes.string.isRequired,
  id: PropTypes.string,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired,
  zero: PropTypes.number.isRequired,
  sourceIdx: PropTypes.number,
  numSources: PropTypes.number,
  barWidth: PropTypes.number,
  datasetLength: PropTypes.number,
  isNegative: PropTypes.bool,
  xDisplay: PropTypes.string,
  yDisplay: PropTypes.string,
  missing: PropTypes.bool,
  color: PropTypes.string,
  qualifier: PropTypes.string,
  source: PropTypes.string,
  units: PropTypes.string,
  label: PropTypes.string,
  width: PropTypes.number.isRequired,
  setActive: PropTypes.func,
  active: PropTypes.objectOf(PropTypes.any),
  setTooltipData: PropTypes.func,
  setTooltipPosition: PropTypes.func,
  tooltipPosition: PropTypes.objectOf(PropTypes.any),
  tooltips: PropTypes.bool
};

ChartBar.defaultProps = {
  xDisplay: null,
  yDisplay: null,
  sourceIdx: 0,
  barWidth: 15,
  numSources: 1,
  datasetLength: 1,
  isNegative: false,
  id: null,
  setActive: () => {},
  setTooltipData: () => {},
  setTooltipPosition: () => {},
  tooltipPosition: null,
  missing: false,
  active: {},
  qualifier: "",
  label: "",
  units: "",
  source: null,
  color: "black",
  tooltips: false
};

// Style Overrides
const Bar = styled.rect`
  fill: ${({color}) => color || "black"};

  ${({active, source, xDisplay, missing}) => {
    if (
      active &&
      active.source &&
      active.source === source &&
      active.date &&
      active.date === xDisplay
    )
      return css`
        stroke: ${heroTheme.primary};
        stroke-width: 2px;
      `;

    if (missing)
      return css`
        stroke: black;
        stroke-width: 0.2px;
        opacity: 0.6;
        /* stroke: red; */
        /* stroke-dasharray: 4; */
        /* stroke-width: 1px; */
      `;

    return css`
      stroke: black;
      stroke-width: 0.2px;
    `;
  }};
`;

export default ChartBar;

import React, {useState, useEffect} from "react";
import {createPortal} from "react-dom";
import {useLocation} from "react-router-dom";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faExclamationCircle,
  faInfoCircle,
  faTimes,
  faWarning
} from "@fortawesome/free-solid-svg-icons";

// Utils
import useMountedState from "../hooks/useMountedState";

// Style
import {bp, breakpoint} from "../style/components/breakpoints";
import {voice} from "../style/components/typography";
import {flex} from "../style/components/mixins";
import {colors, navHeight, pad, radius, shadow} from "../style/components/variables";

const Banner = ({banner, removeBanner}) => {
  const isMounted = useMountedState();

  const {pathname} = useLocation();

  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    if (isMounted()) {
      if (banner?.content) setAnimate(true);
      else setAnimate(false);
    }
  }, [isMounted, banner]);

  const handleRemove = () => {
    setAnimate(false);
    if (banner.onDismiss) banner.onDismiss();
    else setTimeout(() => removeBanner(), 500);
  };

  const renderIcon = () => {
    if (banner.type === "success" || banner.type === "error")
      return <FontAwesomeIcon icon={faExclamationCircle} />;
    if (banner.type === "warning") return <FontAwesomeIcon icon={faWarning} />;
    return <FontAwesomeIcon icon={faInfoCircle} />;
  };

  if (banner?.content)
    return createPortal(
      <Wrapper type={banner.type} animate={animate} visible={pathname !== "/maintenance"}>
        <BannerWrapper>
          <IconWrapper>{renderIcon()}</IconWrapper>
          &nbsp;
          <span>{banner.content}</span>
          <Close onClick={handleRemove}>
            <FontAwesomeIcon icon={faTimes} />
          </Close>
        </BannerWrapper>
      </Wrapper>,
      document.body
    );
  return null;
};

Banner.propTypes = {
  banner: PropTypes.objectOf(PropTypes.any),
  removeBanner: PropTypes.func.isRequired
};

Banner.defaultProps = {
  banner: null
};

// Style Overrides
const Wrapper = styled.div`
  ${flex("row", "nowrap", "center", "center")};
  position: fixed;
  top: ${({animate}) => (animate ? `${navHeight}px` : `calc(${navHeight}px - 100%)`)};
  right: 0;
  width: 100%;

  ${({visible}) =>
    !visible &&
    css`
      display: none;
    `}
`;

const BannerWrapper = styled.div`
  ${flex("row", "nowrap", "center", "center")};
  ${voice.quiet};
  position: relative;
  border-bottom-right-radius: ${radius};
  border-bottom-left-radius: ${radius};
  padding: ${pad / 2}px;
  color: ${colors.heroWhite};
  opacity: 0.92;
  box-shadow: ${shadow};
  transition: top ease-in-out 0.4s;
  width: 100%;

  ${bp(1)} {
    width: ${breakpoint.width[2]};
  }

  background-color: ${({type, theme}) => {
    if (type === "success") return theme.success;
    if (type === "warning") return theme.warning;
    if (type === "error") return theme.error;
    return theme.secondary;
  }};
`;

const IconWrapper = styled.div`
  svg {
    fill: ${({theme}) => theme.tertiary};
  }
`;

const Close = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: min-content;
  padding-right: ${pad}px;

  svg {
    fill: ${({theme}) => theme.tertiary};
  }
`;

export default Banner;

import React, {useEffect, useState} from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faRedo, faTrash} from "@fortawesome/free-solid-svg-icons";

// Utils
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Components
import Switch from "../../components/Switch.js";
import ToggleButton from "./ToggleButton.js";
import Modal from "../../components/Modal.js";
import ModalDelete from "../../components/ModalDelete.js";
import ModalRoleEdit from "./ModalRoleEdit.js";

// Style
import {flex} from "../../style/components/mixins.js";
import {border, colors, pad, radius} from "../../style/components/variables.js";
import {Button, Inline, ListItem, Abbr, Heading, Text} from "../../style/components/general.js";
import {bp} from "../../style/components/breakpoints.js";

const UserRole = ({role: existing, update}) => {
  const isMounted = useMountedState();

  const {api} = useApi("user-roles");

  const [role, setRole] = useState(null);
  const [target, setTarget] = useState(null);
  const [showReset, setShowReset] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  useEffect(() => {
    if (isMounted() && role === null) setRole(existing);
  }, [isMounted, role, existing]);

  const handleUpdate = (type, id, key, value) =>
    api
      .callPut(role.id, {permissions: [{type, id, key, value: value.toString()}]})
      .then(({status, data: res}) => {
        if (status === 200) setRole(res.data);
      });

  const reset = () =>
    api.callPut(role.id, {reset: true}).then(({status, data: res}) => {
      if (status === 200) setRole(res.data);
    });

  const handleDelete = targetRole => {
    setTarget(targetRole);
    setShowDelete(true);
  };

  const confirmDelete = () => {
    setDeleteLoading(true);
    api
      .callDelete(role.id)
      .then(({status, data: res}) => {
        if (status === 200) setRole(res.data);
      })
      .then(() => {
        setDeleteLoading(false);
        update();
      });
  };

  const handleEdit = targetRole => {
    setTarget(targetRole);
    setShowEdit(true);
  };

  const renderPagePermissions = ({allIds, byId}) =>
    allIds.map(
      visId =>
        byId[visId] && (
          <ListItem key={visId}>
            <Row>
              {byId[visId].name}
              <Switch
                state={byId[visId].visible}
                on="Visible"
                off="Hidden"
                showValue={false}
                callback={newStatus => handleUpdate("page", visId, "visible", newStatus)}
              />
            </Row>
            {byId[visId].children && (
              <ChildList>{renderPagePermissions({allIds: byId[visId].children, byId})}</ChildList>
            )}
          </ListItem>
        )
    );

  const renderResourcePermissions = ({allIds, byId}) =>
    allIds.map(
      perId =>
        byId[perId] && (
          <ListItem key={perId}>
            <Row>
              {byId[perId].name}
              <ButtonGroup>
                <ToggleButton
                  type="button"
                  label="View"
                  state={byId[perId].view}
                  callback={newStatus => handleUpdate("resource", perId, "view", newStatus)}
                />
                <ToggleButton
                  type="button"
                  label="Export"
                  state={byId[perId].export}
                  callback={newStatus => handleUpdate("resource", perId, "export", newStatus)}
                />
                <ToggleButton
                  type="button"
                  label="Create"
                  state={byId[perId].create}
                  callback={newStatus => handleUpdate("resource", perId, "create", newStatus)}
                />
                <ToggleButton
                  type="button"
                  label="Edit"
                  state={byId[perId].update}
                  callback={newStatus => handleUpdate("resource", perId, "update", newStatus)}
                />
                <ToggleButton
                  type="button"
                  label="Archive"
                  state={byId[perId].archive}
                  callback={newStatus => handleUpdate("resource", perId, "archive", newStatus)}
                />
                <ToggleButton
                  type="button"
                  label="Delete"
                  state={byId[perId].delete}
                  callback={newStatus => handleUpdate("resource", perId, "delete", newStatus)}
                />
              </ButtonGroup>
            </Row>
            {byId[perId].children && (
              <ChildList>
                {renderResourcePermissions({allIds: byId[perId].children, byId})}
              </ChildList>
            )}
          </ListItem>
        )
    );

  return (
    role && (
      <>
        <Options>
          <Delete type="button" onClick={() => handleDelete(role)}>
            <Abbr title="Delete Role">
              <FontAwesomeIcon icon={faTrash} />
            </Abbr>
          </Delete>
          <Button type="button" onClick={() => handleEdit(role)}>
            <Abbr title="Edit Role">
              <FontAwesomeIcon icon={faEdit} />
            </Abbr>
          </Button>
          <Button type="button" onClick={() => setShowReset(true)}>
            <Abbr title="Reset to Default">
              <FontAwesomeIcon icon={faRedo} />
            </Abbr>
          </Button>
        </Options>
        <Role>
          <Col>
            <ColHeaders>
              <Text>Page</Text>
              <Text>Visibility</Text>
            </ColHeaders>
            {renderPagePermissions(role.permissions.page)}
          </Col>
          <Col>
            <ColHeaders>
              <Text>Resource</Text>
              <Text>Permissions</Text>
            </ColHeaders>
            {renderResourcePermissions(role.permissions.resource)}
          </Col>
        </Role>

        {showReset && (
          <Modal visible={showReset} setVisible={setShowReset}>
            <Heading>Reset Role Permissions</Heading>
            <Text>
              Are you sure you want to reset to Type&apos;s default permissions? This action cannot
              be undone.
            </Text>
            <br />
            <Button
              type="button"
              onClick={() => {
                reset();
                setShowReset(false);
              }}>
              Confirm
            </Button>
          </Modal>
        )}

        {showDelete && (
          <ModalDelete
            visible={showDelete}
            setVisible={setShowDelete}
            confirmDelete={confirmDelete}
            title={`Delete ${target.label.toUpperCase()} Role`}
            prompt={
              <span>
                Are you sure you want to delete the <strong>{target.label.toUpperCase()}</strong>
                &nbsp;role? This role will be deleted from users that currently hold it. As a result
                these user&apos;s permissions will come from their User Type. This action cannot be
                undone.
              </span>
            }
            loading={deleteLoading}
          />
        )}

        {showEdit && target && (
          <ModalRoleEdit
            visible={showEdit}
            setVisible={setShowEdit}
            target={target}
            update={update}
          />
        )}
      </>
    )
  );
};

UserRole.propTypes = {
  role: PropTypes.objectOf(PropTypes.any).isRequired,
  update: PropTypes.func.isRequired
};

// Style Overrides
const Options = styled(Inline)`
  width: 100%;
  gap: ${pad}px;
  justify-content: end;
  padding-bottom: ${pad}px;
`;

const Delete = styled(Button)`
  background: ${props => props.theme.error};
`;

const Role = styled.div`
  display: block;

  ${ListItem} {
    width: 100%;
    padding: 0 0 ${pad}px;
  }

  ${bp(4)} {
    ${flex("row", "nowrap")};
    gap: ${pad}px;
  }
`;

const Col = styled.div`
  margin-bottom: ${pad}px;
  width: 100%;
  padding: ${pad}px;
  border: ${border} solid ${props => props.theme.secondary};
  border-radius: ${radius};

  ${bp(4)} {
    margin-bottom: 0;
    width: 50%;
  }
`;

const ColHeaders = styled.div`
  display: flex;
  justify-content: space-between;

  ${Text} {
    color: ${colors.gray};
    font-weight: bold;
  }
`;

const Row = styled(Inline)`
  justify-content: space-between;
  width: 100%;
  margin-top: ${pad}px;
`;

const ChildList = styled.ul`
  padding-left: ${pad * 2}px;
  border-left: ${border} dashed ${props => props.theme.primary};
`;

const ButtonGroup = styled.div`
  ${flex("row", "nowrap", "space-between", "center")};
  gap: ${pad}px;
  padding-left: ${pad}px;
`;

export default UserRole;

import React from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {useFieldArray, useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";

// Components
import InputError from "./InputError.js";

// Style
import {pad} from "../../style/components/variables.js";
import {voice} from "../../style/components/typography.js";
import {Abbr, Button, Inline, Text, Pill, HeadingSmall} from "../../style/components/general.js";

const InputFileGroup = ({name, setFiles, callback}) => {
  const {
    control,
    formState: {errors},
    trigger
  } = useFormContext();
  const {fields: uploaded, append, remove} = useFieldArray({control, name});

  const removeFile = idx => {
    remove(idx);
    setFiles(old => old.filter((_f, i) => i !== idx));
  };

  return (
    <>
      <HeadingSmall htmlFor="file_name">FILE UPLOAD</HeadingSmall>
      <Upload type="button">
        <FileInputLabel htmlFor="file_upload">
          <span>Attach File(s)</span>
          <HiddenInput
            id="file_upload"
            name="file_upload"
            type="file"
            data-testid="file.upload"
            onChange={({target}) => {
              const targetFile = target.files[0];
              append({filename: targetFile.name});
              trigger(name);

              if (setFiles) setFiles(prev => [...prev, targetFile]);
              if (callback) callback(targetFile);
            }}
          />
        </FileInputLabel>
      </Upload>
      {setFiles && uploaded.length > 0 && (
        <FileInline>
          {uploaded.map(({id, filename}, index) => (
            <FileTagContainer key={id} data-testid="fileTag">
              <FileName>
                <Abbr title={filename}>{filename}</Abbr>
              </FileName>
              <IconButton onClick={() => removeFile(index)}>
                <FontAwesomeIcon icon={faClose} />
              </IconButton>
            </FileTagContainer>
          ))}
        </FileInline>
      )}
      <InputError name={name} errors={errors} />
    </>
  );
};

InputFileGroup.propTypes = {
  name: PropTypes.string.isRequired,
  setFiles: PropTypes.func,
  callback: PropTypes.func
};

InputFileGroup.defaultProps = {
  setFiles: null,
  callback: null
};

// Style Overrides
const Upload = styled(Button)`
  margin-top: ${pad}px;
`;

const FileInputLabel = styled.label`
  ${voice.normal};
  position: relative;

  svg {
    pointer-events: none;
    fill: ${props => props.theme.tertiary};
  }

  ${props =>
    props.disabled &&
    css`
      &:hover {
        cursor: not-allowed;
      }
    `}
`;

const HiddenInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;
`;

const FileInline = styled(Inline)`
  flex-wrap: wrap;
  margin-top: ${pad}px;
`;

const FileTagContainer = styled(Pill)`
  width: min-content;
  height: min-content;
  max-width: 100%;
  margin: 0 ${pad}px ${pad}px 0;
`;

const FileName = styled(Text)`
  ${voice.normal};
  width: min-content;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  margin-right: ${pad / 2}px;
`;

const IconButton = styled(Button)`
  ${voice.normal};
  background-color: transparent;
  width: min-content;
  padding: 0;

  svg {
    fill: ${props => props.theme.secondary};
  }
`;

export default InputFileGroup;

import React from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";

// Style
import {z} from "../../style/components/mixins.js";
import {bp} from "../../style/components/breakpoints.js";
import {voice} from "../../style/components/typography.js";
import {border, pad, radius} from "../../style/components/variables.js";
import {Text, AnchorInline, HeadingSmall, Abbr} from "../../style/components/general.js";
import Marquee from "../general/Marquee.js";

const Details = ({details, control, fullWidth}) => {
  const {notes, jobs} = details;

  const getNoteValue = value => {
    if (value?.includes("http"))
      return (
        <AnchorInline href={value} target="_blank" rel="noreferrer">
          View
        </AnchorInline>
      );
    return value;
  };

  return (
    details && (
      <Wrapper>
        {control && control}

        <CardHeading>Facility Information</CardHeading>

        {jobs?.length > 0 && (
          <Detail fullWidth={fullWidth}>
            <DetailLabel fullWidth={fullWidth}>Job #</DetailLabel>
            <Notes fullWidth={fullWidth}>
              {jobs.map(job => job && job !== "" && <Marquee key={job} text={job} />)}
            </Notes>
          </Detail>
        )}

        {notes?.length > 0 &&
          notes.map(
            ({key, value}) =>
              key &&
              value && (
                <Detail key={key} fullWidth={fullWidth}>
                  <DetailLabel fullWidth={fullWidth}>{key}</DetailLabel>
                  <Notes fullWidth={fullWidth}>
                    <NoteText bold wrap={fullWidth ? 1 : 0}>
                      <Abbr title={value}>{getNoteValue(value)}</Abbr>
                    </NoteText>
                  </Notes>
                </Detail>
              )
          )}
      </Wrapper>
    )
  );
};

Details.propTypes = {
  details: PropTypes.objectOf(PropTypes.any).isRequired,
  control: PropTypes.node,
  fullWidth: PropTypes.bool
};

Details.defaultProps = {
  control: null,
  fullWidth: false
};

// Style Overrides
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-width: 330px;
  padding: ${pad}px;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  margin: 0 0 ${pad}px 0;

  ${bp(3)} {
    min-width: 100%;
  }
`;

const CardHeading = styled(HeadingSmall)`
  ${voice.weak};
`;

const Detail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: ${pad}px 0;

  &:last-child {
    margin-bottom: 0;
  }

  p {
    color: ${({theme}) => theme.secondary};
  }

  ${({fullWidth}) =>
    fullWidth &&
    css`
      flex-direction: column;
    `}
`;

const DetailLabel = styled(Text)`
  width: 40%;
  padding-right: ${pad}px;
  text-overflow: ellipsis;
  overflow: hidden;

  ${({fullWidth}) =>
    fullWidth &&
    css`
      width: 100%;
      padding: 0;
      overflow: inherit;
    `}
`;

const Notes = styled.div`
  display: block;
  width: 60%;
  height: 100%;

  ${({fullWidth}) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;

const NoteText = styled(Text)`
  display: block;
  color: ${({theme}) => theme.secondary};
  word-wrap: ${({wrap}) => (wrap ? "break-word" : "normal")};
  text-overflow: ${({wrap}) => (wrap ? "unset" : "ellipsis")};
  white-space: ${({wrap}) => (wrap ? "unset" : "nowrap")};
  overflow: hidden;

  ${AnchorInline} {
    position: relative;
    z-index: ${z("above")};
  }
`;

export default Details;

import React from "react";
import styled, {css} from "styled-components";
import PropTypes from "prop-types";

// Style
import {pad, shadow} from "../style/components/variables.js";
import {z} from "../style/components/mixins.js";
import {isMobileDevice} from "../utils/responsive.js";

const Callout = ({button, visible, setVisible, index, buttonType, children}) => (
  <>
    {button && (
      <StickyButton index={index} buttonType={buttonType} onClick={() => setVisible(!visible)}>
        {button}
      </StickyButton>
    )}
    {visible && (
      <CalloutWrapper index={index} buttonType={buttonType} mobile={isMobileDevice() ? 1 : 0}>
        {children}
      </CalloutWrapper>
    )}
  </>
);

Callout.propTypes = {
  button: PropTypes.node,
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  index: PropTypes.number,
  buttonType: PropTypes.string,
  children: PropTypes.node
};

Callout.defaultProps = {
  button: null,
  index: 0,
  buttonType: null,
  children: null
};

// style overrides
const StickyButton = styled.div`
  margin-top: 0;
  margin-bottom: 0;
  margin-left: ${pad * 2}px;
  position: relative;
  z-index: ${z("above")};
  pointer-events: auto;

  ${props =>
    props.buttonType === "circle"
      ? css`
          width: 40px;
          height: 40px;
        `
      : css`
          width: min-content;
          height: min-content;
          margin-top: ${pad / 2}px;
        `}

  button {
    box-shadow: ${shadow};
  }
`;

const CalloutWrapper = styled.div`
  position: absolute;
  right: ${props => (props.mobile ? "-3vw" : "48px")};
  bottom: ${props => (props.mobile ? "0" : "40px")};
  height: min-content;
  z-index: ${z("top")};
`;

export default Callout;

import React, {useCallback, useContext} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCheckDouble,
  faClockRotateLeft,
  faClone,
  faEdit,
  faMinus,
  faPlus,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import {Draggable} from "react-beautiful-dnd";
import {FormProvider, useForm} from "react-hook-form";

// Utils
import {FIELD, COMPONENT} from "../../utils/builder.js";
import {AuthContext} from "../../contexts/auth.js";

// Components
import ElementOptions from "../../components/ElementOptions.js";
import Element from "../../components/Element.js";

// Style
import {flex, z} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {border, pad, radius} from "../../style/components/variables.js";
import {
  Form,
  Button,
  Section,
  Text,
  SubText,
  RelativeWrapper
} from "../../style/components/general.js";

const RenderChecksheetBuilder = ({
  builder,
  setBuilder,
  filterResult,
  activeDroppableId,
  setTarget,
  setShowEditModal,
  setShowAddModal,
  setShowDeleteModal,
  setShowFormulaModal,
  setShowConditionalModal,
  setShowElapsedModal,
  setShowSaveTemplateModal,
  cloneElement,
  setShowHelpModal,
  children,
  togglePreview,
  setHasToggledOff,
  error,
  editing,
  templates,
  inTemplateBuilder,
  inEventBuilder
}) => {
  const form = useForm();
  const {atLeast} = useContext(AuthContext);

  const getTemplateLabel = id => {
    const filtered = templates ? templates.filter(template => template.id === id) : null;
    if (filtered?.length) return filtered[0].label;
    return "Template";
  };

  const recurseToggle = (element, byId, setTo) => {
    byId[element.name].toggle = setTo;
    if (!byId[element.name].toggle) byId[element.name].preview = false;
    if (byId[element.name].children.length > 0)
      byId[element.name].children
        .filter(child => child in byId)
        .map(child => {
          byId = recurseToggle(byId[child], byId, setTo);
        });
    return byId;
  };

  const updatedToggle = element => {
    const temp = {...builder};
    const {allIds} = temp;
    let {byId} = temp;
    let hasToggledOff = false;
    byId = recurseToggle(element, byId, !element.toggle || false);

    if (byId[element.name].parentName !== "" && element.element === COMPONENT) {
      byId[byId[element.name].parentName].toggle = true;
    }

    for (let i = 0; i < allIds.length; i++) {
      if (!byId[allIds[i]].toggle) {
        hasToggledOff = true;
        break;
      }
    }

    if (setHasToggledOff) setHasToggledOff(hasToggledOff);

    setBuilder({...temp, byId});
  };

  const handleEdit = element => {
    setShowEditModal(true);
    setTarget(element);
  };

  const handleAdd = element => {
    setShowAddModal(true);
    setTarget(element);
  };

  const handleDelete = element => {
    setShowDeleteModal(true);
    setTarget(element);
  };

  const handleConditional = element => {
    setShowConditionalModal(true);
    setTarget(element);
  };

  const handleFormula = element => {
    setShowFormulaModal(element);
    setTarget(element);
  };

  const handleElapsed = element => {
    setShowElapsedModal(element);
    setTarget(element);
  };

  const isTimeFormula = element => {
    if (!element?.formula?.length) return false;
    if (!element.formula[0].value) return false;
    if (builder.byId[element.formula[0].value]?.type === "time") return true;
    return false;
  };

  const showElapsed = useCallback(
    ({type, depends}) =>
      type === "time" || depends?.filter(key => builder.byId[key]?.type === "time")?.length > 0,
    [builder]
  );

  const renderOptions = target =>
    target.toggle && atLeast("creator") ? (
      <Options className="renderOptions" data-testid="element.options">
        {target.element === "field" && showElapsed(target) && (
          <Option
            data-testid="checksheetBuilder.elapsed"
            type="button"
            title="Elapsed Time"
            onClick={() => handleElapsed(target)}>
            <FontAwesomeIcon icon={faClockRotateLeft} />
          </Option>
        )}
        {target.element === "field" &&
          !isTimeFormula(target) &&
          ["number", "range", "generated"].includes(target.type) && (
            <Option
              data-testid="checksheetBuilder.formula"
              type="button"
              title="Add/Edit Formula"
              onClick={() => handleFormula(target)}>
              f<SubText>(x)</SubText>
            </Option>
          )}
        {target.element === "field" && (
          <Option
            data-testid="checksheetBuilder.condition"
            type="button"
            title="Add Condition"
            onClick={() => handleConditional(target)}>
            <FontAwesomeIcon icon={faCheckDouble} />
          </Option>
        )}
        {target.element === "field" && target.type !== "generated" && (
          <Option
            data-testid="checksheetBuilder.clone"
            type="button"
            title="Clone"
            onClick={() => cloneElement(target)}>
            <FontAwesomeIcon icon={faClone} />
          </Option>
        )}
        {target.type !== "generated" && (
          <Option
            data-testid="element.edit"
            type="button"
            title="Edit"
            onClick={() => handleEdit(target)}>
            <FontAwesomeIcon icon={faEdit} />
          </Option>
        )}
        {target.element === FIELD || editing ? (
          <Option
            data-testid="element.edit"
            type="button"
            title="delete"
            onClick={() => handleDelete(target)}>
            <FontAwesomeIcon icon={faTrash} />
          </Option>
        ) : (
          <RelativeWrapper>
            {!inTemplateBuilder && (
              <Option type="button" title="toggle off" onClick={() => updatedToggle(target)}>
                <FontAwesomeIcon icon={faMinus} />
              </Option>
            )}
          </RelativeWrapper>
        )}
      </Options>
    ) : (
      <RelativeWrapper>
        {target.element !== FIELD && (!editing || !target.toggle) && (
          <Options className="renderOptions">
            <Option
              type="button"
              title="toggle on"
              onClick={() => updatedToggle(target)}
              data-testid="element.toggle">
              <FontAwesomeIcon icon={faPlus} />
            </Option>
          </Options>
        )}
      </RelativeWrapper>
    );

  const numOptions = target => {
    if (target.toggle && atLeast("creator")) {
      if (target.element !== "field" && inTemplateBuilder) return 1;
      if (target.element !== "field" && !inTemplateBuilder) return 2;
      if (target.type === "number" || target.type === "range") return 5;
      if (target.type === "generated") return 3;
      return 4;
    }

    return 1;
  };

  const getRenderedElements = ids => {
    if (ids && ids.length > 0) {
      return ids.map((id, index) => {
        const item = builder.byId[id];

        if (
          !item ||
          item.type === "weather" ||
          (item.element === FIELD && filterResult && !filterResult[id])
        )
          return null;

        const renderedChildren = item.children ? getRenderedElements(item.children) : null;

        if (!item.preview)
          return (
            (item.element === FIELD ||
              !filterResult ||
              (renderedChildren && renderedChildren.some(child => !!child))) && (
              <Draggable
                draggableId={id}
                index={index}
                key={item.name}
                isDragDisabled={
                  !item.toggle ||
                  (inTemplateBuilder && item.element === "component") ||
                  !!filterResult
                }>
                {provided => (
                  <Wrapper
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                    key={id}
                    id={id}>
                    <ElementOptions
                      builder={builder}
                      element={item}
                      key={id}
                      renderOptions={renderOptions}
                      activeDroppableId={activeDroppableId}
                      handleAdd={handleAdd}
                      togglePreview={togglePreview}
                      setTarget={setTarget}
                      setShowSaveTemplateModal={setShowSaveTemplateModal}
                      error={error}
                      numOptions={numOptions(item)}
                      templateLabel={item.templateId ? getTemplateLabel(item.templateId) : null}
                      disableDrag={!!filterResult}>
                      {renderedChildren || null}
                    </ElementOptions>
                    {provided.placeholder}
                  </Wrapper>
                )}
              </Draggable>
            )
          );

        return (
          (!filterResult ||
            item.element === FIELD ||
            (renderedChildren && renderedChildren.some(child => !!child))) && (
            <Element
              key={id}
              byId={builder.byId}
              attributes={item}
              readOnly
              renderOptions={renderOptions}
              activeDroppableId={activeDroppableId}
              togglePreview={togglePreview}
              setShowHelpModal={setShowHelpModal}
              setTarget={setTarget}
              preview>
              {renderedChildren || null}
            </Element>
          )
        );
      });
    }
    return null;
  };

  const renderElements = ids => {
    const view = getRenderedElements(ids);
    if (view && view.some(element => !!element)) return view;
    return (
      <Wrapper data-testid="checksheetBuilder.body">
        <Builder empty>
          <Message>No Elements Found</Message>
        </Builder>
      </Wrapper>
    );
  };

  return (
    <ChecksheetBuilderSection data-testid="checksheetBuilder.body">
      {builder?.allIds?.length > 0 ? (
        <Wrapper>
          <FormProvider {...form}>
            <Form>{renderElements(builder.allIds)}</Form>
          </FormProvider>
        </Wrapper>
      ) : (
        <RelativeWrapper>
          {!inEventBuilder && (
            <Wrapper>
              <Builder empty>
                <Message>Checksheet Elements...</Message>
              </Builder>
            </Wrapper>
          )}
        </RelativeWrapper>
      )}
      {children}
      {!inTemplateBuilder && (
        <Button type="button" onClick={handleAdd} data-testid="checksheet.addField">
          <FontAwesomeIcon icon={faPlus} /> Field
        </Button>
      )}
    </ChecksheetBuilderSection>
  );
};

RenderChecksheetBuilder.propTypes = {
  builder: PropTypes.objectOf(PropTypes.any),
  setBuilder: PropTypes.func.isRequired,
  activeDroppableId: PropTypes.string.isRequired,
  setTarget: PropTypes.func.isRequired,
  setShowEditModal: PropTypes.func.isRequired,
  setShowAddModal: PropTypes.func.isRequired,
  setShowDeleteModal: PropTypes.func.isRequired,
  setShowFormulaModal: PropTypes.func.isRequired,
  setShowConditionalModal: PropTypes.func.isRequired,
  setShowElapsedModal: PropTypes.func.isRequired,
  setShowHelpModal: PropTypes.func,
  cloneElement: PropTypes.func.isRequired,
  children: PropTypes.node,
  setShowSaveTemplateModal: PropTypes.func,
  togglePreview: PropTypes.func,
  setHasToggledOff: PropTypes.func,
  error: PropTypes.arrayOf(PropTypes.string),
  editing: PropTypes.bool,
  templates: PropTypes.arrayOf(PropTypes.any),
  inTemplateBuilder: PropTypes.bool,
  inEventBuilder: PropTypes.bool,
  filterResult: PropTypes.objectOf(PropTypes.any)
};

RenderChecksheetBuilder.defaultProps = {
  builder: null,
  children: null,
  setHasToggledOff: null,
  setShowSaveTemplateModal: null,
  error: null,
  editing: false,
  templates: null,
  inTemplateBuilder: false,
  inEventBuilder: false,
  togglePreview: null,
  setShowHelpModal: () => {},
  filterResult: null
};

// Style Overrides
const ChecksheetBuilderSection = styled(Section)`
  padding: 0;
`;

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: max-content;
  margin-bottom: ${pad * 2}px;
`;

const Builder = styled.div`
  min-height: 200px;
  padding: ${pad}px;

  ${({empty, theme}) =>
    empty &&
    css`
      border: ${border} dashed ${theme.primary};
      border-radius: ${radius};
      ${flex("row", "wrap", "center", "center")};
    `}
`;

const Message = styled(Text)`
  color: ${({theme}) => theme.primary};
  ${voice.medium};
`;

const Options = styled.div`
  ${flex("row", "nowrap", "center", "center")};
  z-index: ${z("base")};
  visibility: hidden;
  opacity: 0;
  transition: all ease 0.2s;
  min-height: 38px;
`;

const Option = styled(Button)`
  margin: 0 0 0 ${pad}px;
  font-weight: bold;
  ${voice.normal}
`;

export default RenderChecksheetBuilder;

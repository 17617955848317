// Unaccepted types
const types = ["confirm", "switch"];

/**
 * @param {Object} field
 * @returns {Array}
 */
export const getStatOptions = field => {
  const options = [];
  if (!field.required && !types.includes(field.type))
    options.push({name: "completionrate", label: "Completion Rate"});
  if (field.type === "number" || field.type === "range" || field.type === "generated") {
    options.push({name: "average", label: "Mean"});
    options.push({name: "median", label: "Median"});
    options.push({name: "min", label: "Minimum"});
    options.push({name: "max", label: "Maximum"});
    options.push({name: "total", label: "Total"});
  }
  return options;
};

export default getStatOptions;

import {keyframes} from "styled-components";

const hexToRgba = (hex, opacity = 1) => {
  if (!hex) return null;

  if (hex.length < 8 && !/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) throw new Error("Bad Hex");

  const parsedArray = hex.substring(1).split("");

  const base16 = `0x${parsedArray.join("")}`;
  // eslint-disable-next-line no-bitwise
  return `rgba(${[(base16 >> 16) & 255, (base16 >> 8) & 255, base16 & 255].join(",")},${opacity})`;
};

export const slideLeft = () => keyframes`
    0%   {
      transform: translateX(0);
    }
    100%  {
      transform: translateX(-50%);
    }
  `;

export const pulse = color => keyframes`
    0% {
      box-shadow: 0 0 0 0 ${hexToRgba(color, 0.7)};
    }
    
    70% {
      box-shadow: 0 0 0 5px ${hexToRgba(color, 0)};
    }
    
    100% {
      box-shadow: 0 0 0 0 ${hexToRgba(color, 0)};
    }
  `;

export const pulseDelay = color => keyframes`
    0% {
      box-shadow: 0 0 0 0 ${hexToRgba(color, 0.7)};
    }
    
    20% {
      box-shadow: 0 0 0 10px ${hexToRgba(color, 0)};
    }
    
    30% {
      box-shadow: 0 0 0 0 ${hexToRgba(color, 0)};
    }

    100% {
      box-shadow: 0 0 0 0 ${hexToRgba(color, 0)};
    }
  `;

/**
 * Change overlay background color
 * @param {number} startOpacity
 * @param {number} endOpacity
 * @returns css animation
 */
export const animateBackground = (startOpacity = 1, endOpacity = 0.5) => keyframes`
    0%   {
        background-color: rgba(0, 0, 0, ${startOpacity});
    }
    100%  {
        background-color: rgba(0, 0, 0, ${endOpacity});
    }
`;

export const fadeIn = keyframes`
    0%   {
        opacity: 0;
    }
    100%  {
        opacity: 1;
    }
`;

export const appear = keyframes`
    0%   {
        visibility: hidden;
    }
    100%  {
        visibility: visible;
    }
`;

export const rotate = keyframes`
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
`;

export const shake = () => keyframes`
  0%    { transform: translateX(2px);  }
  1%    { transform: translateX(-2px); }
  2%    { transform: translateX(2px);  }
  3%    { transform: translateX(-2px); }
  4%    { transform: translateX(0);    }
  95%   { transform: translateX(0);    }
  96%   { transform: translateX(2px);  }
  97%   { transform: translateX(-2px); }
  98%   { transform: translateX(2px);  }
  99%   { transform: translateX(-2px); }
  100%  { transform: translateX(0);    }
`;

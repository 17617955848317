import React, {useContext} from "react";
import {Navigate, useLocation} from "react-router-dom";
import PropTypes from "prop-types";

// Utils
import {AuthContext} from "../contexts/auth.js";
import {setWithExpiry} from "../utils/helpers.js";

// Unauthorized Page
import Unauthorized from "../pages/Unauthorized.js";

const Protected = ({element}) => {
  const {pathname} = useLocation();

  const {isAuthenticated, currentUser, roleCanAccessPage, maintenance} = useContext(AuthContext);

  if (isAuthenticated === null) return null;

  if (!isAuthenticated && !currentUser) {
    // Store intended path for after sign in
    setWithExpiry("savedPath", pathname, 60);
    return <Navigate to="/signin" />;
  }

  if (pathname !== "/first-signin" && currentUser && !currentUser.isAccepted)
    return <Navigate to="/first-signin" />;

  if (
    pathname !== "/maintenance" &&
    maintenance?.status === "on" &&
    currentUser?.type?.name !== "super"
  )
    return <Navigate to="/maintenance" />;

  // Role based Home Page
  if (
    currentUser &&
    currentUser.role &&
    pathname !== "/first-signin" &&
    pathname !== "/account" &&
    element.key &&
    !roleCanAccessPage(element.key)
  ) {
    if (
      element.key &&
      element.key !== "facilities_dashboard" &&
      roleCanAccessPage("facilities_dashboard")
    )
      return <Navigate to="/facilities" />;

    if (
      element.key &&
      element.key !== "global_custom_tables" &&
      roleCanAccessPage("global_custom_tables")
    )
      return <Navigate to="/global-tables" />;

    if (element.key && element.key !== "global_schedule" && roleCanAccessPage("global_schedule"))
      return <Navigate to="/schedule" />;

    return <Unauthorized />;
  }

  if (currentUser) return element;

  return null;
};

Protected.propTypes = {
  element: PropTypes.node.isRequired
};

export default Protected;

import React, {useEffect, useMemo} from "react";
import PropTypes from "prop-types";
import {useFormContext} from "react-hook-form";
import styled, {css} from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faClose,
  faGreaterThan,
  faGreaterThanEqual,
  faLessThan,
  faLessThanEqual,
  faTrash
} from "@fortawesome/free-solid-svg-icons";

import {
  Abbr,
  Button,
  ButtonFull,
  FormField,
  Inline,
  Label,
  ModalButton
} from "../../style/components/general";
import SearchSelect from "../../components/SearchSelect";
import {getAncestors} from "../../utils/builder";
import {flex} from "../../style/components/mixins";
import {border, pad, radius} from "../../style/components/variables";
import {
  InputCheckGroup,
  InputError,
  InputNumber,
  InputSelect
} from "../../components/form/FormInputs";
import {exists} from "../../utils/helpers";

const UnacceptableParameter = ({field, index, remove, results, setResults, search, byId}) => {
  const {
    watch,
    setValue,
    trigger,
    formState: {errors, submitCount}
  } = useFormContext();

  const strictMin = watch(`condition.conditionArray[${index}].strictMin`);
  const strictMax = watch(`condition.conditionArray[${index}].strictMax`);
  const watchDepends = watch(`condition.conditionArray[${index}].depends`);
  const aMin = watch(`condition.conditionArray[${index}].aMin`);
  const aMax = watch(`condition.conditionArray[${index}].aMax`);

  useEffect(() => {
    if (submitCount > 0) trigger(`condition.conditionArray[${index}].aMin`);
  }, [trigger, index, aMax, submitCount]);

  const dependsObject = useMemo(() => {
    if (watchDepends && byId[watchDepends]) return byId[watchDepends];
    return null;
  }, [watchDepends, byId]);

  useEffect(() => {
    if (dependsObject && ["number", "generated"].includes(dependsObject.type))
      setValue(`condition.conditionArray[${index}].alertCondition`, null);
    else if (dependsObject) {
      setValue(`condition.conditionArray[${index}].aMin`, null);
      setValue(`condition.conditionArray[${index}].aMax`, null);
      setValue(`condition.conditionArray[${index}].strictMin`, false);
      setValue(`condition.conditionArray[${index}].strictMax`, false);
    }
  }, [setValue, index, dependsObject]);

  const errorName = useMemo(() => {
    if (dependsObject)
      return ["number", "generated"].includes(dependsObject.type)
        ? `condition.conditionArray[${index}].aMin`
        : `condition.conditionArray[${index}].alertCondition`;
    return `condition.conditionArray[${index}].depends`;
  }, [dependsObject, index]);

  return (
    <Wrapper key={field.id}>
      {remove && (
        <DeleteButton type="button" onClick={() => remove()}>
          <FontAwesomeIcon icon={faTrash} inverse />
        </DeleteButton>
      )}
      <SearchWrapper>
        {!watchDepends || watchDepends === "date" || watchDepends === "weekday" ? (
          <SearchSelect
            results={results}
            setResults={setResults}
            search={search}
            add={item => setValue(`condition.conditionArray[${index}].depends`, item.name)}
            showAll
          />
        ) : (
          <Depends type="button">
            {byId[watchDepends].parentName ? (
              <>{getAncestors(byId[watchDepends].parentName, byId)}&nbsp;</>
            ) : (
              ""
            )}
            {byId[watchDepends].label}
            <FontAwesomeIcon
              icon={faClose}
              onClick={() => setValue(`condition.conditionArray[${index}].depends`, null)}
            />
          </Depends>
        )}
      </SearchWrapper>

      {(dependsObject?.type === "number" || dependsObject?.type === "generated") && (
        <>
          <Label bold>ACCEPTABLE RANGE</Label>
          <FieldWrapper>
            <ParamWrapper>
              <StrictToggle
                type="button"
                onClick={() => setValue(`condition.conditionArray[${index}].strictMin`, !strictMin)}
                isStrict={strictMin ? 1 : 0}
                disabled={!exists(aMin)}>
                <Abbr title={`Toggle ${strictMin ? "Greater Than or Equal" : "Greater Than"}`}>
                  <FontAwesomeIcon icon={strictMin ? faGreaterThan : faGreaterThanEqual} />
                </Abbr>
              </StrictToggle>
              <InputNumber
                name={`condition.conditionArray[${index}].aMin`}
                placeholder="Lower Bound"
                hideError
              />
            </ParamWrapper>
            <ParamWrapper>
              <StrictToggle
                type="button"
                onClick={() => setValue(`condition.conditionArray[${index}].strictMax`, !strictMax)}
                isStrict={strictMax ? 1 : 0}
                disabled={!exists(aMax)}>
                <Abbr title={`Toggle ${strictMax ? "Less Than or Equal" : "Less Than"}`}>
                  <FontAwesomeIcon icon={strictMax ? faLessThan : faLessThanEqual} />
                </Abbr>
              </StrictToggle>
              <InputNumber
                name={`condition.conditionArray[${index}].aMax`}
                placeholder="Upper Bound"
                hideError
              />
            </ParamWrapper>
          </FieldWrapper>
        </>
      )}

      {(dependsObject?.type === "dropdown" || dependsObject?.type === "radio") && (
        <FieldWrapper>
          <InputCheckGroup
            name={`condition.conditionArray[${index}].alertCondition`}
            options={dependsObject.options}
            label="Unacceptable Value"
          />
        </FieldWrapper>
      )}

      {(dependsObject?.type === "switch" || dependsObject?.type === "checkbox") && (
        <FieldWrapper>
          <InputSelect
            name={`condition.conditionArray[${index}].alertCondition`}
            placeholder="Select..."
            options={
              dependsObject?.type === "switch"
                ? [dependsObject.on, dependsObject.off]
                : ["checked", "unchecked"]
            }
            label="Unacceptable Value"
          />
        </FieldWrapper>
      )}

      <InputError errors={errors} name={errorName} atBottom={false} />
    </Wrapper>
  );
};

UnacceptableParameter.propTypes = {
  field: PropTypes.objectOf(PropTypes.any).isRequired,
  index: PropTypes.number.isRequired,
  remove: PropTypes.func,
  results: PropTypes.arrayOf(PropTypes.any),
  setResults: PropTypes.func.isRequired,
  search: PropTypes.func.isRequired,
  byId: PropTypes.objectOf(PropTypes.any).isRequired
};

UnacceptableParameter.defaultProps = {
  results: [],
  remove: null
};

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  padding: ${pad}px;
  padding-bottom: ${pad / 2}px;
`;

const FieldWrapper = styled(FormField)`
  margin-bottom: ${pad / 2}px;
  ${flex("row", "wrap", "start", "center")}
  gap: ${pad}px;
`;

const SearchWrapper = styled.div`
  width: max-content;
  margin-bottom: ${pad}px;
`;

const ParamWrapper = styled(Inline)`
  max-width: 194px;
  width: max-content;
`;

const Depends = styled(ButtonFull)`
  ${flex("row", "nowrap", "space-between", "center")};
  position: relative;
  text-align: left;
  padding: ${pad / 4}px ${pad / 2}px;

  svg {
    padding-left: ${pad}px;
  }
`;

const StrictToggle = styled(Button)`
  margin-top: ${pad}px;
  ${({isStrict}) =>
    isStrict
      ? css`
          padding-right: 11px;
          padding-left: 11px;
        `
      : ""}

  ${({disabled}) =>
    disabled
      ? css`
          opacity: 0.5;
        `
      : ""}
`;

const DeleteButton = styled(ModalButton)`
  position: absolute;
  top: 14px;
  right: 14px;
`;

export default UnacceptableParameter;

import React, {useState} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";

// Utils
import {formatPhone, toTitleCase} from "../utils/helpers.js";

// Components
import Modal from "./Modal.js";

// Style
import {border, pad, radius} from "../style/components/variables.js";
import {voice} from "../style/components/typography.js";
import {
  AnchorInline,
  HeadingSmall,
  List,
  ListItem,
  Small,
  Inline,
  Button
} from "../style/components/general.js";

const PhoneNumbers = ({primary, phones}) => {
  const [visible, setVisible] = useState(false);

  const getPhoneLink = ({number, extension}) => (extension ? `${number},${extension}` : number);

  const getPhoneFormatted = ({number, extension}) => {
    const formatted = formatPhone(number);
    return extension ? `${formatted} ext. ${extension}` : formatted;
  };

  return (
    <>
      <Inline data-testid="phones">
        <PhoneLink href={`tel:${getPhoneLink(primary)}`} voice={voice}>
          {getPhoneFormatted(primary)}
        </PhoneLink>
        {phones?.length > 1 && (
          <More
            data-testid="phones-more"
            type="button"
            onClick={() => {
              setVisible(true);
            }}>
            {phones.length - 1}&nbsp;More
          </More>
        )}
      </Inline>
      {visible && (
        <Modal visible={visible} setVisible={setVisible}>
          <HeadingSmall>Alternate Phone Number(s)</HeadingSmall>
          <List column data-testid="phones-modal">
            <Phone inline>
              <AnchorInline href={`tel:${getPhoneLink(primary)}`}>
                {getPhoneFormatted(primary)}
              </AnchorInline>
              &nbsp;<Bubble primary>Primary</Bubble>
              &nbsp;<Bubble>{toTitleCase(primary.type)}</Bubble>
            </Phone>
            {phones
              .filter(phone => !phone.primary)
              .map(phone => (
                <Phone key={phone.number} inline>
                  <AnchorInline href={`tel:${getPhoneLink(phone)}`}>
                    {getPhoneFormatted(phone)}
                  </AnchorInline>
                  &nbsp;<Bubble>{toTitleCase(phone.type)}</Bubble>
                </Phone>
              ))}
          </List>
        </Modal>
      )}
    </>
  );
};

PhoneNumbers.propTypes = {
  primary: PropTypes.objectOf(PropTypes.any).isRequired,
  phones: PropTypes.arrayOf(PropTypes.any).isRequired
};

// Style Overrides
const PhoneLink = styled(AnchorInline)`
  ${voice.quiet};
  text-decoration: none;

  &:hover {
    color: ${props => props.theme.primary};
  }
`;

const More = styled(Button)`
  width: min-content;
  padding: 0 ${pad / 2}px;
  margin-left: ${pad / 2}px;
  ${voice.quiet};
`;

const Phone = styled(ListItem)`
  margin-top: ${pad}px;

  ${AnchorInline} {
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.primary};
    }
  }
`;

const Bubble = styled(Small)`
  color: ${props => props.theme.tertiary};
  background: ${props => props.theme.secondary};
  border: ${border} solid ${props => props.theme.tertiary};
  border-radius: ${radius};
  padding: 0 ${pad / 2}px;

  ${props =>
    props.primary &&
    css`
      background: ${props.theme.primary};
    `}
`;

export default PhoneNumbers;

import React, {useEffect, useRef, useState} from "react";
import {createPortal} from "react-dom";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";

// Style
import {voice} from "../style/components/typography";
import {flex, z} from "../style/components/mixins";
import {pad, radius, shadow, transition} from "../style/components/variables";
import {Text} from "../style/components/general";

const Help = ({children, title, icon, color, inverted, showModal}) => {
  const [visible, setVisible] = useState(false);

  const outside = useRef(null);

  useEffect(() => {
    const handleClickOutside = ({target}) =>
      outside &&
      outside.current !== null &&
      !outside.current.contains(target) &&
      !outside.current.contains(target.nextSibling) &&
      setVisible(false);

    if (outside) document.addEventListener("mousedown", handleClickOutside);

    // Cleanup
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [outside, setVisible]);

  const renderHelp = () =>
    showModal && visible ? (
      createPortal(
        <InfoModal>
          <Text>{children}</Text>
        </InfoModal>,
        document.body
      )
    ) : (
      <Info visible={visible} onClick={e => e.preventDefault()}>
        {children}
      </Info>
    );

  return (
    <HelpWrapper ref={outside}>
      <Icon
        type="button"
        title={title}
        onClick={e => {
          e.stopPropagation();
          setVisible(prev => !prev);
        }}
        inverted={inverted}
        color={color}>
        {icon || <FontAwesomeIcon icon={faQuestionCircle} />}
      </Icon>
      {renderHelp()}
    </HelpWrapper>
  );
};

Help.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  icon: PropTypes.any,
  color: PropTypes.string,
  inverted: PropTypes.bool,
  showModal: PropTypes.bool
};

Help.defaultProps = {
  title: "",
  icon: undefined,
  color: undefined,
  inverted: false,
  showModal: false
};

// Style Overrides
const InfoModal = styled.div`
  ${flex("row", "nowrap", "center", "center")};
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  z-index: ${z("peak")};
  pointer-events: none;

  ${Text} {
    padding: ${pad}px;
    background: ${({theme}) => theme.tertiary};
    border-radius: ${radius};
  }
`;

const HelpWrapper = styled.span`
  position: relative;
  display: inline-block;
  width: min-content;
  z-index: ${z("above")};
`;

const Icon = styled.button`
  svg {
    fill: ${({inverted, theme}) => (inverted ? theme.tertiary : theme.secondary)};
    ${voice.quiet};
  }
`;

const Info = styled.span`
  ${voice.quiet};
  position: absolute;
  bottom: calc(100% - ${pad / 2}px);
  left: calc(100% + ${pad / 2}px);
  padding: ${pad / 2}px;
  color: ${({theme}) => theme.tertiary};
  background: ${({theme}) => theme.secondary};
  border-radius: ${radius};
  width: max-content;
  max-width: 300px;
  white-space: break-spaces;
  box-shadow: ${shadow};
  transition: ${transition};
  visibility: ${({visible}) => (visible ? "visible" : "hidden")};
`;

export default Help;

import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {useFormContext} from "react-hook-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash} from "@fortawesome/free-solid-svg-icons";

// Components
import InputError from "./InputError.js";

// Style
import {voice} from "../../style/components/typography.js";
import {FormFieldWrapper, Input, Label, Text} from "../../style/components/general.js";

const InputPassword = ({name, label, prompt, placeholder, required, disabled}) => {
  const {
    watch,
    register,
    formState: {errors}
  } = useFormContext();

  const [focused, setFocused] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const value = watch(name);

  useEffect(() => {
    setFocused(value && value.length > 0);
  }, [value]);

  const toggleVisibility = () => setShowPassword(!showPassword);

  return (
    <>
      {label && (
        <Label htmlFor={name} bold>
          {label.toUpperCase()}
          {required && <span>&nbsp;*</span>}
        </Label>
      )}
      <StyledFormFieldWrapper>
        <Input
          id={name}
          type={showPassword ? "text" : "password"}
          name={name}
          placeholder={!focused && prompt ? prompt : placeholder}
          disabled={disabled}
          autoComplete="current-password"
          {...register(name, {required: required})}
        />
        {value && value.length > 0 && (
          <TogglePassword data-testid="toggle-password" type="button" onClick={toggleVisibility}>
            <FontAwesomeIcon icon={showPassword ? faEye : faEyeSlash} />
          </TogglePassword>
        )}
        {focused && !errors[name] && <Prompt>{prompt}</Prompt>}
        <InputError errors={errors} name={name} />
      </StyledFormFieldWrapper>
    </>
  );
};

InputPassword.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  prompt: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  innerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.instanceOf(Element)})
  ])
};

InputPassword.defaultProps = {
  label: null,
  prompt: null,
  placeholder: "Password",
  required: false,
  disabled: false,
  innerRef: null
};

// Style Overrides
const Prompt = styled(Text)`
  ${voice.quiet};
  margin-top: 2px;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  top: 100%;
  left: 0;
`;

const TogglePassword = styled.button`
  width: fit-content;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 10px;
  z-index: 1;

  svg {
    fill: ${props => props.theme.secondary};
  }
`;

const StyledFormFieldWrapper = styled(FormFieldWrapper)`
  margin-top: 0;
`;

export default InputPassword;

import React, {useEffect, useRef, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import dayjs from "dayjs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose, faSearch} from "@fortawesome/free-solid-svg-icons";

// Hooks
import useMountedState from "../../hooks/useMountedState.js";
import useApi from "../../hooks/useApi.js";

// Utils
import {COMPONENT, FIELD_TYPES, getAncestors, getFields, GROUP} from "../../utils/builder.js";
import {toTitleCase} from "../../utils/helpers.js";

// Components
import Badge from "../../components/Badge.js";
import RenderChecksheet from "./RenderChecksheet.js";
import Modal from "../../components/Modal.js";

// Style
import {border, colors, pad, radius, shadow} from "../../style/components/variables.js";
import {flex, z} from "../../style/components/mixins.js";
import {voice} from "../../style/components/typography.js";
import {breakpoint} from "../../style/components/breakpoints.js";
import {
  Abbr,
  Inline,
  Input,
  Label,
  Pill,
  scrollbar,
  Search,
  SearchIcon,
  SearchWrapper,
  Small,
  StickyWrapper,
  Text,
  Title
} from "../../style/components/general.js";

const ModalChecksheetPreview = ({visible, setVisible, checksheet}) => {
  const {name, frequency, builder} = checksheet;
  const fields = getFields(builder.allIds, builder.byId);
  const hasFields = fields.length > 0;

  const isMounted = useMountedState();

  const [open, setOpen] = useState(false);
  const [fieldTags, setFieldTags] = useState([]);
  const [targetTypes, setTargetTypes] = useState([]);
  const [targetTags, setTargetTags] = useState([]);
  const [targetElements, setTargetElements] = useState([]);
  const [filters, setFilters] = useState([]);
  const [filterResults, setFilterResults] = useState();
  const [simulatedDate, setSimulatedDate] = useState();

  const {api: apiFieldTags} = useApi("field-tags", {suppress: {success: true, error: true}});

  const search = useRef();

  useEffect(() => {
    if (isMounted())
      apiFieldTags.callGet().then(({status, data}) => {
        if (status === 200 && data)
          setFieldTags(data.map(({name: fieldTag}) => fieldTag.toLowerCase()));
      });
  }, [isMounted, apiFieldTags]);

  const getElementMatches = (target, ids, byId, result = []) => {
    ids.map(id => {
      if (byId[id]) {
        const {element, type, name: fieldName, label, parentName} = byId[id];

        const ancestors = getAncestors(parentName, byId);
        const value = ancestors ? `${ancestors} ${label}` : label;

        if (fieldName?.includes(target)) {
          // case for rainfall
          if (element === "field" && type === "weather")
            result.push({
              element,
              name: `${fieldName}_rainfall`,
              label: `${toTitleCase(value)} Rainfall`
            });

          result.push({
            element,
            name: fieldName,
            label: toTitleCase(value)
          });
        }
      }

      if (byId[id] && byId[id].children.length > 0)
        getElementMatches(target, byId[id].children, byId, result);
    });

    return result;
  };

  const handleSearch = ({target}) => {
    if (target?.value) {
      const types = FIELD_TYPES.filter(type =>
        type.label.replace(/ /g, "").toLowerCase().includes(target.value.toLowerCase())
      );
      setTargetTypes(types);

      const filtered = fieldTags.filter(tag =>
        tag.toLowerCase().includes(target.value.toLowerCase())
      );
      setTargetTags(filtered);

      const elementMatches = getElementMatches(
        target.value.toLowerCase(),
        checksheet.builder.allIds,
        checksheet.builder.byId
      );
      setTargetElements(elementMatches.sort((a, b) => a.label - b.label));
    } else setFilterResults([]);
  };

  return (
    <Modal visible={visible} setVisible={setVisible} maxWidth={breakpoint.width[4]}>
      <Menu>
        <Inline>
          <ChecksheetName>
            <Abbr title={name}>{name}</Abbr>
          </ChecksheetName>
          {frequency && <Frequency>{frequency}</Frequency>}
        </Inline>

        <Options>
          <SearchWrapper>
            <SearchIcon>
              <FontAwesomeIcon icon={faSearch} />
            </SearchIcon>
            <Search
              ref={search}
              name="search"
              type="text"
              placeholder="Find..."
              onChange={handleSearch}
              onFocus={() => setOpen(true)}
            />
            {filters.length > 0 && (
              <Badge
                count={filters.length}
                offsetY="25px"
                offsetX="-10px"
                color={colors.heroGreen}
              />
            )}
            <Suggestions
              open={(filterResults?.length > 0 || search.current?.value?.length > 0) && open}>
              <Filters style={{flexWrap: "wrap"}}>
                {filters?.map(filter => (
                  <Filter
                    key={filter}
                    type="button"
                    onClick={() => {
                      const [fType, fName] = filter.split(": ");
                      setFilters(prev => prev.filter(f => f !== filter));
                      setFilterResults(prev =>
                        prev.filter(id => {
                          if (fType === "TYPE")
                            return checksheet.builder.byId[id].type !== fName.toLowerCase();
                          if (fType === "TAG")
                            return (
                              checksheet.builder.byId[id]?.tag?.toUpperCase() !==
                              fName.toUpperCase()
                            );
                          return true;
                        })
                      );
                    }}>
                    <Pill quiet>
                      {filter}&nbsp;
                      <Icon icon={faClose} />
                    </Pill>
                  </Filter>
                ))}
              </Filters>
              {(targetTypes?.length > 0 || targetTags?.length > 0) && (
                <>
                  <Small>Filter</Small>
                  <hr />
                  {targetTypes?.length > 0 && (
                    <>
                      <Text quiet>Types:</Text>
                      {targetTypes.map(({label, value}) => (
                        <Suggestion
                          key={label}
                          onClick={() => {
                            search.current.value = label.toUpperCase();
                            handleSearch({target: {value: label}});
                            const typeMatches = Object.fromEntries(
                              Object.entries(checksheet.builder.byId).filter(
                                ([, {type: t}]) => t && value?.includes(t.toLowerCase())
                              )
                            );
                            setFilterResults(prev =>
                              prev
                                ? [...prev, ...Object.keys(typeMatches)]
                                : Object.keys(typeMatches)
                            );

                            // reset
                            setTargetTypes(undefined);
                            setTargetTags(undefined);
                            setTargetElements(undefined);
                            setFilters(prev => [...prev, `TYPE: ${label.toUpperCase()}`]);
                            setOpen(false);
                            search.current.value = "";
                          }}>
                          {label.toUpperCase()}
                        </Suggestion>
                      ))}
                    </>
                  )}
                  {targetTags?.length > 0 && (
                    <>
                      <Text quiet>Tags:</Text>
                      {targetTags.map(tag => (
                        <Suggestion
                          key={tag}
                          onClick={() => {
                            search.current.value = tag.toUpperCase();
                            handleSearch({target: {value: tag}});
                            const tagMatches = Object.fromEntries(
                              Object.entries(checksheet.builder.byId).filter(
                                ([, {tag: t}]) => t && targetTags?.includes(t.toLowerCase())
                              )
                            );
                            setFilterResults(prev =>
                              prev ? [...prev, ...Object.keys(tagMatches)] : Object.keys(tagMatches)
                            );

                            // reset
                            setTargetTypes(undefined);
                            setTargetTags(undefined);
                            setTargetElements(undefined);
                            setFilters(prev => [...prev, `TAG: ${tag.toUpperCase()}`]);
                            setOpen(false);
                            search.current.value = "";
                          }}>
                          {tag.toUpperCase()}
                        </Suggestion>
                      ))}
                    </>
                  )}
                </>
              )}
              {targetElements?.length > 0 && (
                <>
                  <hr />
                  <Small>Find Element</Small>
                  <hr />
                  {targetElements.map(({element, name: elementName, label}) => (
                    <Suggestion
                      key={elementName}
                      element={element}
                      onClick={() => {
                        handleSearch({target: {value: label}});
                        document
                          .querySelectorAll(`[data-testid='${element}-${elementName}']`)[0]
                          .scrollIntoView({
                            behavior: "smooth",
                            block: "center"
                          });

                        // reset
                        setTargetTypes(undefined);
                        setTargetTags(undefined);
                        setTargetElements(undefined);
                        setOpen(false);
                        search.current.value = "";
                      }}>
                      {label.toUpperCase()}
                    </Suggestion>
                  ))}
                </>
              )}
              {targetTypes?.length === 0 &&
                targetTags?.length === 0 &&
                targetElements?.length === 0 && <Text>No matches found.</Text>}
            </Suggestions>
          </SearchWrapper>
          <DateInputLabel bold htmlFor="simulatedDate">
            Simulated Date
            <Input
              type="date"
              onChange={e => setSimulatedDate(e.target.value)}
              name="simulatedDate"
              defaultValue={dayjs().format("YYYY-MM-DD")}
            />
          </DateInputLabel>
        </Options>
      </Menu>

      {hasFields ? (
        <RenderChecksheet
          task={{...checksheet, name: null, frequency: null}}
          hideMeta
          hideCompleted
          simulatedDate={simulatedDate}
          filterResults={filterResults}
        />
      ) : (
        <Text>No Fields have been added.</Text>
      )}
    </Modal>
  );
};

ModalChecksheetPreview.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  checksheet: PropTypes.objectOf(PropTypes.any).isRequired
};

// Style Overrides
const Menu = styled(StickyWrapper)`
  ${flex("row", "wrap", "space-between", "end")};
  width: 100%;
  top: 0;
  padding-top: ${pad * 2}px;
  background: ${({theme}) => theme.tertiary};
  box-shadow: 0 5px 15px ${({theme}) => theme.tertiary};
  z-index: ${z("top")};

  padding-top: ${({showPadding}) => showPadding}px;
`;

const ChecksheetName = styled(Title)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const Frequency = styled(Pill)`
  ${voice.quiet};
`;

const Icon = styled(FontAwesomeIcon)`
  fill: ${({theme}) => theme.tertiary};
`;

const Filters = styled(Inline)`
  flex-wrap: nowrap;
  margin-bottom: ${pad}px;
`;

const Filter = styled.button``;

const Options = styled(Inline)`
  align-items: end;
`;

const Suggestions = styled.div`
  ${scrollbar};
  position: absolute;
  top: 100%;
  left: 0;
  padding: ${pad}px;
  margin-top: ${pad / 2}px;
  width: 300px;
  max-height: 300px;
  border-radius: ${radius};
  border: ${border} solid ${({theme}) => theme.secondary};
  background: ${({theme}) => theme.tertiary};
  box-shadow: ${shadow};
  overflow-y: auto;

  display: ${({open}) => (open ? "block" : "none")};
`;

const Suggestion = styled.button`
  ${voice.small};
  text-align: left;
  margin-top: ${pad / 2}px;
  color: ${({element, theme}) => {
    if (element && element === GROUP) return theme.group;
    if (element && element === COMPONENT) return theme.component;
    return theme.secondary;
  }};

  &:hover {
    font-weight: bold;
  }
`;

const DateInputLabel = styled(Label)`
  ${voice.quiet}
  width: min-content;
`;

export default ModalChecksheetPreview;

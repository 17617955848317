import React, {Fragment, useContext, useEffect, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import dayjs from "dayjs";

// Utils
import useApi from "../../hooks/useApi.js";
import {getMapFromCoords, openLink} from "../../utils/helpers.js";
import {AuthContext} from "../../contexts/auth.js";

// Components
import Modal from "../../components/Modal.js";
import ModalFileView from "../facility/ModalFileView.js";

// Style
import {voice} from "../../style/components/typography.js";
import {breakpoint} from "../../style/components/breakpoints.js";
import {border, pad, radius} from "../../style/components/variables.js";
import {Button, Heading, Label, Small, Text} from "../../style/components/general.js";

const NotesPreview = ({
  visible,
  setVisible,
  pendingNotes,
  resolvedNotes,
  archivedNotes,
  checksheetName,
  dateDue,
  updateData,
  builder,
  isEvent,
  hasBackButton,
  goBack
}) => {
  const {currentUser} = useContext(AuthContext);

  const {api: apiNotes} = useApi(isEvent ? "event-record-notes" : "record-notes");
  const {api: apiFiles} = useApi("files");

  const [currentPendingNotes, setCurrentPendingNotes] = useState(null);
  const [currentResolvedNotes, setCurrentResolvedNotes] = useState(null);
  const [link, setLink] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleResolve = (noteId, group) => {
    apiNotes
      .callPut(noteId, null, {
        params: {userId: currentUser.publicId, status: "resolved"},
        headers: {"Content-Type": "multipart/form-data"}
      })
      .then(({status}) => {
        if (status === 200) {
          let temp = currentPendingNotes[group].filter(note => note.id === noteId);

          if (temp.length > 0) {
            if (group in currentResolvedNotes)
              setCurrentResolvedNotes({
                ...currentResolvedNotes,
                [group]: [...currentResolvedNotes[group], temp[0]]
              });
            else
              setCurrentResolvedNotes({
                ...currentResolvedNotes,
                [group]: [temp[0]]
              });
          }

          temp = currentPendingNotes[group].filter(note => note.id !== noteId);

          if (temp.length === 0) {
            const tempPendingNotes = {...currentPendingNotes};
            delete tempPendingNotes[group];
            setCurrentPendingNotes(tempPendingNotes);
          } else setCurrentPendingNotes({...currentPendingNotes, [group]: temp});

          updateData();
        }
      });
  };

  const openFile = fileId => {
    apiFiles.callGet(fileId).then(({status, data}) => {
      if (status === 200) openLink(data.link);
    });
  };

  const buildDisplay = (notes, canResolve, type = "note", groups = true) => {
    let keys = Object.keys(notes);

    if (groups) {
      keys = keys.filter(key => key in builder.byId);
      keys.sort((a, b) => builder.allIds.indexOf(a) - builder.allIds.indexOf(b));
    }

    return keys
      .filter(group =>
        notes[group].some(note => type === "note" || (note.files && note.files.length > 0))
      )
      .map(group => (
        <Fragment key={group}>
          <Label>{builder.byId[group].label}</Label>
          {notes[group].map(note =>
            type === "note" ? (
              <NoteWrapper
                key={`${note.name}-${note.id}`}
                resolve={canResolve ? 1 : 0}
                data-testid="preview.noteWrapper">
                <Message>{note.message}</Message>
                {note.coords && (
                  <AddressLink
                    href={getMapFromCoords(note.coords.lat, note.coords.lon)}
                    target="_blank"
                    rel="noreferrer">
                    View in Maps
                  </AddressLink>
                )}
                {note.files && note.files.length > 0 && (
                  <Files>
                    <hr />
                    <p>Attached Files</p>
                    {note.files.map(file => (
                      <FileLink onClick={() => openFile(file.id)} key={`file-${file.id}`}>
                        {file.label}
                      </FileLink>
                    ))}
                  </Files>
                )}
                {note.user && <Author>By: {note.user}</Author>}
                {canResolve && (
                  <ResolveButton
                    data-testid="resolveButton"
                    onClick={() => handleResolve(note.id, group)}>
                    Resolve
                  </ResolveButton>
                )}
              </NoteWrapper>
            ) : (
              note.files &&
              note.files.length > 0 &&
              note.files.map(file => (
                <FileLink onClick={() => openFile(file.id)} key={`file-${file.id}`}>
                  {file.label}
                </FileLink>
              ))
            )
          )}
        </Fragment>
      ));
  };

  useEffect(() => {
    if (!currentPendingNotes) setCurrentPendingNotes(pendingNotes || {});
    if (!currentResolvedNotes) setCurrentResolvedNotes(resolvedNotes || {});
  }, [currentPendingNotes, currentResolvedNotes, pendingNotes, resolvedNotes]);

  if (link && selectedFile)
    return (
      <ModalFileView
        visible={visible}
        setVisible={setVisible}
        hasBackButton
        goBack={() => {
          setLink(null);
          setSelectedFile(null);
        }}
        file={{...selectedFile, link}}
      />
    );

  return (
    <Modal visible={visible} setVisible={setVisible} hasBackButton={hasBackButton} goBack={goBack}>
      <Heading>Notes</Heading>

      <Detail>
        <Text>
          <strong>Checksheet:</strong> {checksheetName}
        </Text>
        <Text>
          <strong>Due:</strong> {dayjs(dateDue).format("MMM DD, YYYY")}
        </Text>
      </Detail>

      <NotesHeader>Pending Notes</NotesHeader>
      <hr />
      {currentPendingNotes && Object.keys(currentPendingNotes).length > 0 ? (
        buildDisplay(currentPendingNotes, true)
      ) : (
        <NoNotes>No Pending Notes</NoNotes>
      )}

      <NotesHeader>Resolved Notes</NotesHeader>
      <hr />
      {currentResolvedNotes && Object.keys(currentResolvedNotes).length > 0 ? (
        buildDisplay(currentResolvedNotes, false)
      ) : (
        <NoNotes>No Resolved Notes</NoNotes>
      )}

      {archivedNotes && archivedNotes.length > 0 && (
        <>
          <NotesHeader>{isEvent ? "Event" : "Checksheet"} Archive Note</NotesHeader>
          <hr />
          {archivedNotes.map(note => (
            <NoteWrapper key={note.name} data-testid="preview.noteWrapper">
              {note.name.includes("error") && <Label bold>{note.name}</Label>}
              <Message>
                {note.name === "Hero was not implemented at this time" ? note.name : note.message}
              </Message>
              {note.files && Object.keys(note.files).length > 0 && (
                <Files>
                  <hr />
                  <p>Attached Files</p>
                  {note.files.map(file => (
                    <FileLink onClick={() => openFile(file.id)} key={`file-${file.id}`}>
                      {file.label}
                    </FileLink>
                  ))}
                </Files>
              )}
              {note.user && (
                <>
                  <hr />
                  <Author>By: {note.user}</Author>
                </>
              )}
            </NoteWrapper>
          ))}
        </>
      )}
    </Modal>
  );
};

NotesPreview.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  checksheetName: PropTypes.string.isRequired,
  dateDue: PropTypes.string.isRequired,
  updateData: PropTypes.func.isRequired,
  builder: PropTypes.objectOf(PropTypes.any),
  pendingNotes: PropTypes.objectOf(PropTypes.any),
  resolvedNotes: PropTypes.objectOf(PropTypes.any),
  archivedNotes: PropTypes.arrayOf(PropTypes.any),
  isEvent: PropTypes.bool,
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func
};

NotesPreview.defaultProps = {
  builder: {allIds: [], byId: {}},
  pendingNotes: null,
  resolvedNotes: null,
  archivedNotes: null,
  isEvent: false,
  hasBackButton: false,
  goBack: () => {}
};

// Style Overrides

const Message = styled(Text)`
  margin-bottom: ${pad / 2}px;
`;

const FileLink = styled(Text)`
  ${voice.weak}
  font-weight: bold;
  color: ${props => props.theme.primary};
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

const Files = styled.div`
  text-align: left;
  color: ${props => props.theme.secondary};
  margin-bottom: ${pad / 2}px;
  p {
    font-weight: bold;
  }
  a {
    color: ${props => props.theme.primary};

    :hover {
      text-decoration: underline;
    }
  }

  ${voice.quiet}
`;

const NoteWrapper = styled.div`
  position: relative;
  border: ${border} solid ${props => props.theme.primary};
  border-radius: ${radius};
  padding: ${pad}px;
  width: 100%;
  margin-bottom: ${pad}px;
  padding-bottom: ${props => (props.resolve ? pad * 4.5 : pad)}px;
  flex: 1 auto;
`;

const ResolveButton = styled(Button)`
  position: absolute;
  right: ${pad}px;
  margin: 0;
  bottom: 8px;
`;

const Author = styled.p`
  ${voice.quiet}
`;

const AddressLink = styled.a`
  color: ${props => props.theme.primary};

  p {
    color: ${props => props.theme.primary};
  }

  &:hover {
    text-decoration: underline;
  }
`;

const Detail = styled.div`
  margin-right: ${pad}px;

  @media (min-width: ${breakpoint.width[3]}) {
    margin-bottom: ${pad}px;
  }
`;

const NotesHeader = styled.div`
  ${voice.normal}
  font-weight: bold;
  margin: ${pad * 2}px ${pad}px 0 0;
`;

const NoNotes = styled(Small)`
  margin-left: ${pad / 2}px;
`;

export default NotesPreview;

import React, {useContext, useEffect, useMemo, useState} from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

// Contexts
import {AuthContext} from "../../contexts/auth.js";
import {SettingsContext} from "../../contexts/settings.js";

// Hooks
import useApi from "../../hooks/useApi.js";

// Components
import Modal from "../../components/Modal.js";
import {InputFileGroup} from "../../components/form/FormInputs.js";

// Style
import {pad} from "../../style/components/variables.js";
import {flex} from "../../style/components/mixins.js";
import {
  Form,
  FormGroup,
  FormField,
  ButtonFull,
  HeadingCenter,
  ButtonLoader,
  Text,
  Label,
  Error
} from "../../style/components/general.js";

const schema = yup.object().shape({
  files: yup
    .array()
    .of(yup.object())
    .test({
      test: val => Array.isArray(val) && val.length > 0,
      message: "Please provide at least one file."
    })
});

const ModalFailedUpload = ({
  visible,
  setVisible,
  facilityId,
  updateTable,
  tableId,
  tags,
  failed,
  successful
}) => {
  const {currentUser} = useContext(AuthContext);
  const {settings} = useContext(SettingsContext);

  const maxFileSize = useMemo(() => {
    let {maxFileSize: current} = settings;
    if (!current) current = 5;
    else {
      current = parseInt(current, 10);
      if (!current || Number.isNaN(current)) current = 5;
      else if (current > 8) current = 8;
    }
    return current;
  }, [settings]);

  const [files, setFiles] = useState(successful);

  const {api, loading} = useApi("custom-table-rows");

  const form = useForm({
    defaultValues:
      successful && successful.length > 0
        ? {
            files: successful.map(file => ({filename: file.name}))
          }
        : {
            files: []
          },
    resolver: yupResolver(schema)
  });
  const {handleSubmit, watch, clearErrors} = form;

  const selectedTags = watch("tags");

  // Reset tag error
  useEffect(() => {
    if (selectedTags && selectedTags.length > 0) clearErrors("tags");
  }, [clearErrors, selectedTags]);

  const handleSave = () => {
    const params = {
      userId: currentUser.publicId,
      tableId,
      facilityId,
      tags: tags.map(t => t.label),
      filesOnly: true
    };

    const fileData = new FormData();

    for (let i = 0; i < files.length; i++) {
      fileData.append(`file-${i}`, new File([files[i]], files[i].name, {type: files[i].type}));
    }

    api.callPost(fileData, {params, headers: {"Content-Type": "multipart/form-data"}}).then(() => {
      setVisible(false);
      updateTable();
    });
  };

  return (
    <Modal visible={visible} setVisible={setVisible}>
      <ModalTitle>Upload Failed</ModalTitle>

      <Text>
        Upload failed because one or more files exceed the maximum file size limit of {maxFileSize}
        MB. Try compressing and reuploading the files listed below.
      </Text>
      <br />
      <br />
      <Label bold>Failed</Label>
      <Failed>
        {failed.map(file => (
          <Error key={`failed-${file.name}`}>{file.name}</Error>
        ))}
      </Failed>
      <br />
      <br />

      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(handleSave)} noValidate>
          <FormGroup>
            <FormField>
              <InputFileGroup name="files" setFiles={setFiles} />
            </FormField>
          </FormGroup>
          <ButtonFull type="submit" loading={loading ? 1 : 0}>
            Save{loading && <ButtonLoader />}
          </ButtonFull>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalFailedUpload.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  updateTable: PropTypes.func.isRequired,
  selected: PropTypes.objectOf(PropTypes.any),
  setSelected: PropTypes.func,
  facilityId: PropTypes.number,
  tags: PropTypes.arrayOf(PropTypes.any),
  tableId: PropTypes.number,
  failed: PropTypes.arrayOf(PropTypes.any).isRequired,
  successful: PropTypes.arrayOf(PropTypes.any)
};

ModalFailedUpload.defaultProps = {
  selected: null,
  setSelected: null,
  facilityId: null,
  tags: [],
  tableId: null,
  successful: []
};

// Style Overrides
const ModalTitle = styled(HeadingCenter)`
  margin: ${pad}px 0;
`;

const Failed = styled.div`
  width: 100%;
  position: relative;
  ${flex("column", "nowrap", "center", "start")}

  gap: ${pad / 2}px;
`;

export default ModalFailedUpload;

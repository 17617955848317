import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import {useForm, FormProvider} from "react-hook-form";
// eslint-disable-next-line import/extensions
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

import Modal from "../../components/Modal.js";
import {InputText} from "../../components/form/FormInputs.js";

// Style
import {pad} from "../../style/components/variables.js";
import {
  FormGroup,
  FormField,
  HeadingCenter,
  ButtonFull,
  Form
} from "../../style/components/general.js";

const ModalSaveTemplate = ({visible, setShowModal, targetName, targetLabel, saveTemplate}) => {
  const schema = yup.object().shape({
    label: yup.string().required("Please provide a Template Name")
  });

  const form = useForm({
    defaultValues: {label: targetLabel},
    resolver: yupResolver(schema)
  });
  const {watch, handleSubmit} = form;
  const label = watch("label");

  const handleSave = () => {
    saveTemplate(targetName, label);
    setShowModal(false);
  };

  return (
    <Modal testId="facility.addComponent" visible={visible} setVisible={setShowModal}>
      <ModalTitle>Component Template</ModalTitle>
      <FormProvider {...form}>
        <Form onSubmit={handleSubmit(handleSave)}>
          <FormGroup>
            <FormField>
              <InputText
                name="label"
                label="Template Name"
                required
                testId="saveTemplate.label"
                defaultValue={targetLabel}
                {...form}
              />
            </FormField>
          </FormGroup>
          <Submit type="submit" data-testid="saveTemplate.save">
            Save Template
          </Submit>
        </Form>
      </FormProvider>
    </Modal>
  );
};

ModalSaveTemplate.propTypes = {
  visible: PropTypes.bool.isRequired,
  setShowModal: PropTypes.func.isRequired,
  targetName: PropTypes.string,
  targetLabel: PropTypes.string,
  saveTemplate: PropTypes.func.isRequired
};

ModalSaveTemplate.defaultProps = {
  targetName: "",
  targetLabel: ""
};

// Style Overrides
const ModalTitle = styled(HeadingCenter)`
  margin: ${pad}px 0;
  color: ${props => props.theme.component};
`;

const Submit = styled(ButtonFull)`
  margin-bottom: ${pad}px;
`;

export default ModalSaveTemplate;

import React, {useEffect, useContext, useRef, useState, useMemo} from "react";
import PropTypes from "prop-types";
import {useForm, FormProvider} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faClose} from "@fortawesome/free-solid-svg-icons";

// Utils
import useApi from "../../hooks/useApi.js";
import {useSocket} from "../../contexts/socket.js";
import {AuthContext} from "../../contexts/auth.js";
import {NotificationContext} from "../../contexts/notify.js";
import {exists} from "../../utils/helpers.js";

// Components
import Modal from "../../components/Modal.js";
import SearchSelect from "../../components/SearchSelect.js";
import InputError from "../../components/form/InputError.js";
import BulkApplyUnit from "./BulkApplyUnit.js";
import {calendarValidation} from "../../components/form/InputCalendar.js";

// Style
import {pad} from "../../style/components/variables.js";
import {
  Button,
  ButtonFull,
  Form,
  Heading,
  Inline,
  Label,
  SearchWrapper
} from "../../style/components/general.js";

// Socket Constants
import {EVENTS_ADDED} from "../general/Room.js";
import Help from "../../components/Help.js";

const DEFAULT_EVENT = {
  type: "",
  name: "",
  startDate: null,
  frequency: "",
  group: null
};

const eventsEqual = (e1, e2) =>
  e1?.type &&
  e2?.type &&
  e1?.name &&
  e2?.name &&
  e1?.frequency &&
  e2?.frequency &&
  e1?.group &&
  e2?.group &&
  e1.type === e2.type &&
  e1.group === e2.group &&
  e1.frequency === e2.frequency &&
  e1.name?.trim() === e2.name?.trim();

const defaultSchema = {
  templates: yup.array().of(
    yup.object().shape({
      type: yup.string(),
      name: yup
        .string()
        .required("Please provide name.")
        .test({
          test: (_v, ctx) => {
            if (!ctx) return true;

            const {from, path} = ctx;

            const [, part2] = path?.split("[") || [];
            let [idx] = part2?.split("]") || [];
            idx = parseInt(idx, 10);

            if (!exists(idx) || !from || from.length <= 1 || !from[1]?.value?.templates)
              return true;

            const {templates} = from[1].value;

            for (let i = 0; i < templates.length; i++) {
              if (i !== idx && eventsEqual(templates[i], templates[idx])) return false;
            }

            return true;
          },
          message: "Combination of name, frequency and location must be unique."
        }),
      location: yup.string().nullable(),
      frequency: yup.string().nullable(),
      startDate: calendarValidation,
      group: yup.object().nullable()
    })
  )
};

const ModalBulkApply = ({
  visible,
  setVisible,
  isGlobal,
  getEvents,
  frequencies,
  eventTypes,
  allTemplates,
  facility
}) => {
  const socket = useSocket();

  const {currentUser} = useContext(AuthContext);
  const {getNotifications} = useContext(NotificationContext);

  const [selectedFacility, setSelectedFacility] = useState(facility);
  const [facilityResults, setFacilityResults] = useState([]);
  const [allFacilities, setAllFacilities] = useState([]);

  const hasSubmitted = useRef(false);

  const form = useForm({
    defaultValues: {facility: facility?.name || null, templates: [DEFAULT_EVENT]},
    resolver: yupResolver(
      yup.object().shape({
        facility: yup.string().required("Facility is required."),
        ...defaultSchema
      })
    ),
    criteriaMode: "all"
  });
  const {
    watch,
    setValue,
    reset,
    handleSubmit,
    formState: {errors}
  } = form;
  const watchFacility = watch("facility");
  const watchTemplates = watch("templates");

  const {api: apiEvents} = useApi("events");
  const {api: apiFacilities} = useApi("facilities");

  const availableGroups = useMemo(() => {
    if (selectedFacility) {
      const {allIds, byId} = selectedFacility.builder;
      const available = [];
      allIds.map(id => {
        const {element, name, label} = byId[id];
        if (element === "group") {
          available.push({
            name,
            label
          });
        }
      });
      return available;
    }

    return [];
  }, [selectedFacility]);

  // Reset on close
  useEffect(() => {
    if (!visible) reset({templates: []});
  }, [reset, visible]);

  useEffect(() => {
    if (errors && errors.templates) hasSubmitted.current = true;
  }, [errors]);

  useEffect(() => {
    apiFacilities.callGet().then(({status, data}) => {
      if (status === 200 && data) {
        const facilities = data.map(({id, name, type, builder}) => ({
          id: id,
          name: `${name} ${type}`,
          label: `${name.toUpperCase()} ${type.toUpperCase()}`,
          builder
        }));
        setAllFacilities(facilities);
        setFacilityResults(facilities);
      }
    });
  }, [apiFacilities]);

  const saveEvent = () => {
    const eventsByType = {};
    const eventList = watchTemplates.map(
      ({id, type, name, label, startDate, frequency, responses, stages, group}) => {
        const {date, lastDay} = startDate;

        if (type in eventsByType) eventsByType[type].push(id);
        else eventsByType[type] = [id];

        const newEvent = {
          id,
          type,
          name: label || name,
          startDate: date,
          lastDay,
          responses,
          stages
        };

        if (frequency) newEvent.frequency = frequency;

        if (group) newEvent.group = group.name;

        return newEvent;
      }
    );

    apiEvents
      .callPost({
        events: eventList,
        userId: currentUser.publicId,
        facilityId: selectedFacility.id
      })
      .then(({status}) => {
        if (status === 201) {
          setVisible(false);
          getEvents();
          getNotifications(selectedFacility.slug);
          socket.emit(EVENTS_ADDED, selectedFacility.slug, eventsByType);
        }
      });
  };

  const searchFacilities = query => {
    if (query)
      setFacilityResults(
        allFacilities
          ? allFacilities.filter(f => f.name.toLowerCase().includes(query.toLowerCase()))
          : []
      );
    else setFacilityResults(allFacilities);
  };

  return (
    <Modal visible={visible} setVisible={setVisible} width="80%" maxWidth="800px" minWidth="732px">
      <FormProvider {...form}>
        <Form noValidate>
          <Inline>
            <Heading center>Bulk Apply Templates </Heading>
            <HelpWrapper>
              <Help position="center">
                Custom frequencies are not supported while using Bulk Apply
              </Help>
            </HelpWrapper>
          </Inline>
          {isGlobal && (
            <FacilitySelect>
              <Label bold>FACILITY *</Label>
              <SearchWrapper>
                {!watchFacility && (
                  <>
                    <SearchSelect
                      results={facilityResults}
                      setResults={setFacilityResults}
                      search={searchFacilities}
                      add={value => {
                        const selected = allFacilities.filter(f => f.name === value.name)[0];
                        setValue("facility", selected.label);
                        setSelectedFacility(selected);
                      }}
                      placeholder="Search..."
                      showAll
                    />
                    <InputError name="facility" errors={errors} />
                  </>
                )}
                {watchFacility && (
                  <Button
                    type="button"
                    title="Unapply Template"
                    onClick={() => setValue("facility", null)}>
                    <span>Applied: {watchFacility}</span>
                    &nbsp;
                    <FontAwesomeIcon icon={faClose} />
                  </Button>
                )}
              </SearchWrapper>
            </FacilitySelect>
          )}
          <br />
          {watchTemplates.map((template, idx) => (
            // eslint-disable-next-line react/no-array-index-key
            <UnitWrapper key={`template${idx}`}>
              <BulkApplyUnit
                defaultEvent={DEFAULT_EVENT}
                template={template}
                index={idx}
                groups={availableGroups}
                frequencies={frequencies}
                eventTypes={eventTypes}
                allTemplates={allTemplates}
                hasSubmitted={hasSubmitted}
              />
            </UnitWrapper>
          ))}
        </Form>
      </FormProvider>

      <Submit type="button" onClick={handleSubmit(saveEvent)}>
        Create Events
      </Submit>
    </Modal>
  );
};

ModalBulkApply.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  isGlobal: PropTypes.bool.isRequired,
  getEvents: PropTypes.func,
  frequencies: PropTypes.arrayOf(PropTypes.any).isRequired,
  eventTypes: PropTypes.arrayOf(PropTypes.any).isRequired,
  allTemplates: PropTypes.arrayOf(PropTypes.any).isRequired,
  facility: PropTypes.objectOf(PropTypes.any)
};

ModalBulkApply.defaultProps = {
  getEvents: null,
  facility: null
};

// Style Overrides
const FacilitySelect = styled.div`
  margin-bottom: ${pad / 2}px;
`;

const Submit = styled(ButtonFull)`
  margin-bottom: ${pad}px;
  margin-top: ${pad}px;
`;

const UnitWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: ${pad * 1.5}px;
`;

const HelpWrapper = styled.span`
  margin-top: 4px;
`;

export default ModalBulkApply;

import React, {useEffect} from "react";
import PropTypes from "prop-types";
import {FormProvider, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

// Utils
import useApi from "../../hooks/useApi.js";
import useMountedState from "../../hooks/useMountedState.js";

// Components
import Modal from "../../components/Modal.js";
import {InputSelect, InputText} from "../../components/form/FormInputs.js";

// Style
import {breakpoint} from "../../style/components/breakpoints.js";
import {
  Button,
  ButtonLoader,
  Form,
  FormField,
  FormGroup,
  HeadingCenter,
  Error,
  Text,
  Loader
} from "../../style/components/general.js";

const schema = yup.object().shape({
  name: yup.string().required("Please provide name."),
  userType: yup
    .number()
    .typeError("Please provide user type.")
    .required("Please provide user type.")
});

const ModalRowEdit = ({visible, setVisible, target, update}) => {
  const isMounted = useMountedState();

  const {api, loading} = useApi("user-roles");
  const {data: types, api: apiTypes} = useApi("user-types");

  const form = useForm({
    defaultValues: {
      name: target.label,
      userType: target.type.id
    },
    resolver: yupResolver(schema)
  });
  const {handleSubmit} = form;

  // Load user types
  useEffect(() => {
    if (isMounted() && visible) apiTypes.callGet(null, {orderBy: "desc"});
  }, [isMounted, visible, apiTypes]);

  const updateRole = ({name, userType}) => {
    const params = {label: name};
    if (target.type.id !== userType) params["userType"] = userType;
    api.callPut(target.id, params).then(response => {
      if (response.status === 200) {
        update();
        setVisible(false);
      }
    });
  };

  return (
    <Modal visible={visible} setVisible={setVisible} width={breakpoint.width[1]}>
      <HeadingCenter>Edit Role</HeadingCenter>
      <FormProvider {...form}>
        {types ? (
          <Form onSubmit={handleSubmit(updateRole)} noValidate>
            <FormGroup>
              <FormField>
                <InputText name="name" label="Role" required />
              </FormField>
              <FormField>
                <InputSelect
                  name="userType"
                  label="Type"
                  placeholder="Select..."
                  options={types
                    .filter(({name}) => name !== "super")
                    .map(({id, name}) => ({label: name.toUpperCase(), value: id}))}
                  required
                />
              </FormField>
            </FormGroup>
            <FormGroup>
              <Error>WARNING!</Error>
              <Text>
                Updating a role&apos;s type will reset its permissions to type&apos;s default.
              </Text>
            </FormGroup>
            <Button type="submit" loading={loading ? 1 : 0}>
              {loading && <ButtonLoader />}
              Save
            </Button>
          </Form>
        ) : (
          <Loader />
        )}
      </FormProvider>
    </Modal>
  );
};

ModalRowEdit.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  target: PropTypes.objectOf(PropTypes.any).isRequired,
  update: PropTypes.func.isRequired
};

export default ModalRowEdit;

import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import styled, {css} from "styled-components";

// Components
import PhoneNumbers from "../../components/PhoneNumbers.js";
import Marquee from "../general/Marquee.js";

// Style
import {z} from "../../style/components/mixins.js";
import {bp} from "../../style/components/breakpoints.js";
import {voice} from "../../style/components/typography";
import {border, pad, radius} from "../../style/components/variables";
import {Abbr, AnchorInline, Text} from "../../style/components/general.js";

const Contacts = ({details, control, fullWidth}) => {
  const {contactsExtra} = details;

  const [contacts, setContacts] = useState({});

  useEffect(() => {
    const temp = {};
    contactsExtra?.map(contact => {
      const formatted = {
        name: `${contact.firstName} ${contact.lastName}`,
        company: contact.company,
        primary: contact.primary,
        phones: contact.phones,
        email: contact.email
      };

      const company = contact.company?.name || "No Company Provided";

      const role = contact?.role?.label || "Contact";
      if (!temp[role]) temp[role] = {[company]: [formatted]};
      else if (!temp[role][company]) temp[role][company] = [formatted];
      else temp[role][company].push(formatted);
    });
    setContacts(temp);
  }, [contactsExtra]);

  return contacts && Object.keys(contacts).length > 0 ? (
    <Wrapper>
      {control && control}
      {Object.keys(contacts).map(role => (
        <Detail key={role} fullWidth={fullWidth}>
          <DetailLabel fullWidth={fullWidth}>
            <Abbr title={role}>{role}</Abbr>
          </DetailLabel>
          <Notes fullWidth={fullWidth}>
            {Object.keys(contacts[role]).map(company => {
              const contactList = contacts[role][company];
              return (
                <div key={company}>
                  <Marquee text={company} />

                  {/* <NoteText bold>
                    <Abbr title={company}>{company}</Abbr>
                  </NoteText> */}
                  {contactList.map(contact => (
                    <div key={contact.email}>
                      <NoteText quiet>
                        <Abbr title={contact.name}>{contact.name}</Abbr>
                      </NoteText>
                      {contact.primary && contact.phones && contact.phones.length > 0 && (
                        <PhoneNumbers primary={contact.primary} phones={contact.phones} />
                      )}
                      <NoteLink href={`mailto:${contact.email}`}>
                        <Abbr title={contact.email}>{contact.email}</Abbr>
                      </NoteLink>
                    </div>
                  ))}
                </div>
              );
            })}
          </Notes>
        </Detail>
      ))}
    </Wrapper>
  ) : (
    <Wrapper>
      {control && control}
      <NoContacts>
        <Text>No Contacts</Text>
      </NoContacts>
    </Wrapper>
  );
};

Contacts.propTypes = {
  details: PropTypes.objectOf(PropTypes.any).isRequired,
  control: PropTypes.node,
  fullWidth: PropTypes.bool
};

Contacts.defaultProps = {
  control: null,
  fullWidth: false
};

// Style Overrides
const Wrapper = styled.div`
  position: relative;
  width: 100%;
  min-width: 330px;
  padding: ${pad}px;
  border: ${border} solid ${({theme}) => theme.secondary};
  border-radius: ${radius};
  margin: 0 0 ${pad}px 0;

  ${bp(3)} {
    min-width: 100%;
  }
`;

const Detail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: ${pad}px 0;

  &:last-child {
    margin-bottom: 0;
  }

  p {
    color: ${({theme}) => theme.secondary};
  }

  ${({fullWidth}) =>
    fullWidth &&
    css`
      flex-direction: column;
    `}
`;

const NoContacts = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: ${pad}px 0;
`;

const DetailLabel = styled(Text)`
  width: 40%;
  padding-right: ${pad}px;
  text-overflow: ellipsis;
  overflow: hidden;

  ${({fullWidth}) =>
    fullWidth &&
    css`
      width: 100%;
      padding: 0;
      overflow: inherit;
    `}
`;

const Notes = styled.div`
  display: block;
  width: 60%;
  height: 100%;

  ${({fullWidth}) =>
    fullWidth &&
    css`
      width: 100%;
    `}
`;

const NoteText = styled(Text)`
  display: block;
  color: ${({theme}) => theme.secondary};
  word-wrap: ${({wrap}) => (wrap ? "break-word" : "normal")};
  text-overflow: ${({wrap}) => (wrap ? "unset" : "ellipsis")};
  white-space: ${({wrap}) => (wrap ? "unset" : "nowrap")};
  overflow: hidden;
`;

const NoteLink = styled(AnchorInline)`
  ${voice.quiet};
  display: block;
  color: ${({theme}) => theme.primary};
  word-wrap: ${({wrap}) => (wrap ? "break-word" : "normal")};
  text-overflow: ${({wrap}) => (wrap ? "unset" : "ellipsis")};
  white-space: ${({wrap}) => (wrap ? "unset" : "nowrap")};
  overflow: hidden;
  margin-bottom: ${pad / 2}px;
  position: relative;
  z-index: ${z("above")};
`;

export default Contacts;

import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

// Components
import Modal from "../../components/Modal.js";

// Style
import {
  HeadingCenter,
  TextCenter,
  Form,
  FormGroup,
  Button,
  ButtonLoader
} from "../../style/components/general.js";

const ModalArchive = ({visible, setVisible, archive, loading, hasBackButton, goBack}) => {
  const handleSubmit = e => {
    e.preventDefault();
    archive();
  };

  return (
    <Modal visible={visible} setVisible={setVisible} hasBackButton={hasBackButton} goBack={goBack}>
      <HeadingCenter>Archive Report</HeadingCenter>
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <TextCenter>
            Are you sure you want to Archive this report? No new reports will be generated.
          </TextCenter>
        </FormGroup>

        <Archive type="submit" loading={loading ? 1 : 0}>
          {loading && <ButtonLoader />}
          Archive
        </Archive>
      </Form>
    </Modal>
  );
};

ModalArchive.propTypes = {
  visible: PropTypes.bool.isRequired,
  setVisible: PropTypes.func.isRequired,
  archive: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  hasBackButton: PropTypes.bool,
  goBack: PropTypes.func
};

ModalArchive.defaultProps = {
  loading: false,
  hasBackButton: false,
  goBack: () => {}
};

// Style Overrides
const Archive = styled(Button)`
  background: ${props => props.theme.error};
  color: ${props => props.theme.tertiary};
`;

export default ModalArchive;

export const DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

export const MONTHS_ABBR = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
];

const VIEWS = ["month", "week"];

class Gregorian {
  // private variables
  $start;
  $end;
  $active;
  $matrix;
  $view;
  $overrideHeight;

  constructor(date, overrideHeight = null) {
    this.$start = null;
    this.$end = null;
    this.$active = date || new Date();
    this.$matrix = null;
    [this.$view] = VIEWS;
    this.$overrideHeight = overrideHeight;
  }

  getWeekHeaders() {
    return DAYS;
  }

  getMonthHeaders() {
    return MONTHS;
  }

  getStart() {
    return this.$start;
  }

  setStart(date) {
    this.$start = date;
  }

  getEnd() {
    return this.$end;
  }

  setEnd(date) {
    this.$end = date;
  }

  getActive() {
    return this.$active;
  }

  setActive(date) {
    this.$active = date;
  }

  getActiveWeek() {
    return DAYS[this.$active.getDay()];
  }

  getActiveMonth() {
    return MONTHS[this.$active.getMonth()];
  }

  getActiveYear() {
    return this.$active.getFullYear();
  }

  getMatrix() {
    return this.$matrix;
  }

  setMatrix(matrix) {
    this.$matrix = matrix;
  }

  getView() {
    return this.$view;
  }

  setView(view) {
    if (VIEWS.includes(view)) {
      this.$view = view;
      this.renderCalendar();
    }
  }

  /** @returns {Boolean} */
  monthView() {
    return this.getView() === "month";
  }

  /** @returns {Boolean} */
  weekView() {
    return this.getView() === "week";
  }

  /**
   * Reset matrix based on provided date
   * @param {Date} date
   */
  setMonth(date) {
    this.setActive(new Date(date.getFullYear(), date.getMonth(), date.getDate()));
    this.renderCalendar();
  }

  /** Increment active date by one month */
  setNextMonth() {
    const date = this.getActive();
    const first = new Date(date.setDate(1));
    const nextMonth = new Date(first.setMonth(first.getMonth() + 1));
    this.setActive(new Date(nextMonth.getFullYear(), nextMonth.getMonth()));
    this.renderCalendar();
  }

  /** Decrement active date by one month */
  setPrevMonth() {
    const date = this.getActive();
    const first = new Date(date.setDate(1));
    const prevMonth = new Date(first.setMonth(first.getMonth() - 1));
    this.setActive(new Date(prevMonth.getFullYear(), prevMonth.getMonth()));
    this.renderCalendar();
  }

  /** Increment active date by one week */
  setNextWeek() {
    const date = this.getActive();
    this.setActive(new Date(date.getFullYear(), date.getMonth(), date.getDate() + 7));
    this.renderCalendar();
  }

  /** Decrement active date by one week */
  setPrevWeek() {
    const date = this.getActive();
    this.setActive(new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7));
    this.renderCalendar();
  }

  /**
   * Get number of days in a given month
   * @param {String} month
   * @param {String} year
   * @returns {Integer}
   */
  numMonthDays(month, year) {
    // Month is Sept, Apr, Jun, Nov return 30 days
    if (/8|3|5|10/.test(month)) return 30;
    // Month is not Feb return 31 days
    if (month !== 1) return 31;
    // Month is Feb - leap year then Feb has 29 days
    if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) return 29;
    // Not a leap year. Feb has 28 days.
    return 28;
  }

  /**
   * Get object to build calendar from
   * @param {Datetime} active date, defaults to today
   * @returns {Object}
   */
  renderCalendar() {
    const active = this.getActive() || new Date();
    const size = this.numMonthDays(active.getMonth(), active.getFullYear());
    const rowLength = 7;
    const lastDay = new Date(active.getFullYear(), active.getMonth() + 1, 0);
    const rowMod = lastDay.getDay() < 2 ? 2 : 1;
    const numRows = this.monthView() ? this.$overrideHeight || Math.floor(size / 7) + rowMod : 1;

    // // get month start ~ first day of calendar month
    const first = new Date(active.getFullYear(), active.getMonth(), 1);
    // get start date based on the interval (1 month or 7 days)
    const target = this.monthView() ? first : active;
    const offset = this.monthView() ? 1 : active.getDate();
    let start = new Date(target.getFullYear(), target.getMonth(), offset - target.getDay());

    this.setStart(start);

    const calendar = new Array(numRows);
    for (let x = 0; x < calendar.length; x++) {
      const row = new Array(rowLength);
      for (let y = 0; y < row.length; y++) {
        const current = new Date(start.getFullYear(), start.getMonth(), start.getDate() + y);
        row[y] = {
          date: current,
          name: DAYS[current.getDay()]
        };
      }

      start = new Date(start.getFullYear(), start.getMonth(), start.getDate() + 7);
      calendar[x] = row;
    }

    this.setEnd(calendar[numRows - 1][rowLength - 1].date);
    this.setMatrix(calendar);
  }
}

export default Gregorian;

import React, {useState, useEffect, useContext, useRef} from "react";
import PropTypes from "prop-types";
import {FormProvider, useForm} from "react-hook-form";
import styled from "styled-components";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";

// Utils
import {AuthContext} from "../../contexts/auth.js";
import {SettingsContext} from "../../contexts/settings.js";
import useApi from "../../hooks/useApi.js";

// Components
import {AccountRow, InfoText, Save} from "./components.js";
import {
  InputText,
  InputNumber,
  InputSelect,
  InputCheck,
  InputColor
} from "../../components/form/FormInputs.js";

// Style
import {bp} from "../../style/components/breakpoints.js";
import {pad, border, radius, colors} from "../../style/components/variables.js";
import {Form, Inline, SettingsRowWrapper} from "../../style/components/general.js";

const SUPPORTED_TIMEZONES = [
  {
    name: "US/Pacific",
    label: "Pacific Time"
  },
  {
    name: "US/Mountain",
    label: "Mountain Time"
  },
  {
    name: "US/Central",
    label: "Central Time"
  },
  {
    name: "US/Eastern",
    label: "Eastern Time"
  },
  {
    name: "US/Hawaii",
    label: "Hawaii Time"
  },
  {
    name: "US/Alaska",
    label: "Alaska Time"
  },
  {
    name: "US/Arizona",
    label: "Arizona Time"
  }
];

const EnterpriseSettings = ({edit, setEdit}) => {
  const {currentUser, roles} = useContext(AuthContext);
  const {settings, saveSettings} = useContext(SettingsContext);

  const {api} = useApi("files");

  const enterpriseSchema = yup.object().shape({
    siteTitle: yup.string(),
    logo: yup.string(),
    timezone: yup.string(),
    expInterval: yup.number().typeError("Interval is required."),
    expBase: yup.string(),
    longExpInterval: yup.number().typeError("Interval is required."),
    longExpBase: yup.string(),
    support: yup.string().email("Invalid email address").nullable(),
    roleRecipients: roles?.length > 0 ? yup.boolean() : yup.mixed().nullable(),
    primary: yup.string(),
    primaryDark: yup.string(),
    secondary: yup.string(),
    secondaryDark: yup.string(),
    tertiary: yup.string(),
    tertiaryDark: yup.string(),
    background: yup.string()
  });

  const [logo, setLogo] = useState({});
  const [logoPreview, setLogoPreview] = useState("/logo.png");
  const [triggerHideColorPicker, setTriggerHideColorPicker] = useState({
    primary: false,
    primaryDark: false,
    secondary: false,
    secondaryDark: false,
    tertiary: false,
    tertiaryDark: false,
    background: false
  });

  useEffect(() => {
    if (settings?.logo) setLogoPreview(settings.logo);
  }, [settings]);

  const enterpriseForm = useForm({
    defaultValues: {
      ...settings,
      roleRecipients: settings.roleRecipients === "true",
      primary: settings.primary?.slice(1),
      primaryDark: settings.primaryDark?.slice(1),
      secondary: settings.secondary?.slice(1),
      secondaryDark: settings.secondaryDark?.slice(1),
      tertiary: settings.tertiary?.slice(1),
      tertiaryDark: settings.tertiaryDark?.slice(1)
    },
    resolver: yupResolver(enterpriseSchema)
  });
  const {watch, reset} = enterpriseForm;

  const shouldReset = useRef(true);

  useEffect(() => {
    if (!edit) reset({});
    else
      reset({
        ...settings,
        roleRecipients: settings.roleRecipients === "true",
        primary: settings.primary?.slice(1),
        primaryDark: settings.primaryDark?.slice(1),
        secondary: settings.secondary?.slice(1),
        secondaryDark: settings.secondaryDark?.slice(1),
        tertiary: settings.tertiary?.slice(1),
        tertiaryDark: settings.tertiaryDark?.slice(1)
      });
  }, [edit, reset, settings]);

  // Theme
  const primary = watch("primary");
  const primaryDark = watch("primaryDark");
  const secondary = watch("secondary");
  const secondaryDark = watch("secondaryDark");
  const tertiary = watch("tertiary");
  const tertiaryDark = watch("tertiaryDark");

  const saveEnterpriseSettings = values => {
    shouldReset.current = false;
    const {support, expirationBase, expirationInterval} = values;

    const updated = {
      ...values,
      primary: primary ? `#${primary}` : null,
      primaryDark: primaryDark ? `#${primaryDark}` : null,
      secondary: secondary ? `#${secondary}` : null,
      secondaryDark: secondaryDark ? `#${secondaryDark}` : null,
      tertiary: tertiary ? `#${tertiary}` : null,
      tertiaryDark: tertiaryDark ? `#${tertiaryDark}` : null
    };

    if (support) updated["support"] = support;
    if (expirationBase) updated["expirationBase"] = expirationBase;
    if (expirationInterval) updated["expirationInterval"] = expirationInterval.toString();

    if (logo && logo.name) {
      const formData = new FormData();
      formData.append("file", logo);
      api
        .callPost(formData, {params: {userId: currentUser.publicId, visibility: "public-read"}})
        .then(({status, data: res}) => {
          if (status === 201) {
            const {link} = res.data;
            updated.logo = link;
            saveSettings(updated);
          }
        });
    } else saveSettings(updated);

    setEdit(false);
    shouldReset.current = true;
  };

  const hideAllExcept = name => {
    setTriggerHideColorPicker({
      ...Object.fromEntries(Object.entries(triggerHideColorPicker).map(([n]) => [n, true])),
      [name]: false
    });
  };

  return (
    settings && (
      <FormProvider {...enterpriseForm}>
        <Form onSubmit={enterpriseForm.handleSubmit(saveEnterpriseSettings)} noValidate>
          <SettingsRowWrapper>
            <AccountRow label="Name">
              {edit ? (
                <NameWrapper>
                  <InputText
                    name="siteTitle"
                    placeholder="Enterprise Name"
                    defaultValue={settings.siteTitle}
                  />
                </NameWrapper>
              ) : (
                <InfoText>{settings.siteTitle}</InfoText>
              )}
            </AccountRow>
            <AccountRow label="Logo">
              <ImagePreview background={settings.secondary}>
                <img
                  width="100"
                  height="100"
                  src={logo && logo.name ? URL.createObjectURL(logo) : logoPreview}
                  alt={settings.siteTitle}
                />
              </ImagePreview>
              {edit && (
                <LogoInput label="Logo" type="file" onChange={e => setLogo(e.target.files[0])} />
              )}
            </AccountRow>
            <AccountRow
              label="Timezone"
              help={
                edit
                  ? "All dates and times will be represented based on the following timezone."
                  : null
              }>
              {edit ? (
                <InputSelect
                  name="timezone"
                  placeholder="Timezone"
                  options={SUPPORTED_TIMEZONES}
                  defaultValue={settings.timezone}
                />
              ) : (
                <InfoText>{settings.timezone}</InfoText>
              )}
            </AccountRow>
            <AccountRow
              label="Sign In Expiration"
              help={
                edit ? "User's will be logged out after based on the following interval." : null
              }>
              {edit ? (
                <StyledInline>
                  <NumberWrapper>
                    <InputNumber
                      name="expInterval"
                      defaultValue={
                        settings.expInterval ? Number.parseInt(settings.expInterval, 10) : 8
                      }
                      overrideWidth="75px"
                    />
                  </NumberWrapper>
                  <InputSelect
                    name="expBase"
                    options={["hours", "days", "weeks", "months"]}
                    defaultValue={settings.expBase || "hours"}
                  />
                </StyledInline>
              ) : (
                <InfoText>
                  {settings.expInterval || 10} {settings.expBase || "days"}
                </InfoText>
              )}
            </AccountRow>
            <AccountRow
              label="Remember Sign In Expiration"
              help={
                edit
                  ? "User's will be logged out when 'Remember Me' is selected based on the following interval."
                  : null
              }>
              {edit ? (
                <StyledInline>
                  <NumberWrapper>
                    <InputNumber
                      name="longExpInterval"
                      defaultValue={
                        settings.longExpInterval
                          ? Number.parseInt(settings.longExpInterval, 10)
                          : 10
                      }
                      overrideWidth="75px"
                    />
                  </NumberWrapper>
                  <InputSelect
                    name="longExpBase"
                    options={["hours", "days", "weeks", "months"]}
                    defaultValue={settings.longExpBase || "days"}
                  />
                </StyledInline>
              ) : (
                <InfoText>
                  {settings.longExpInterval || 10} {settings.longExpBase || "days"}
                </InfoText>
              )}
            </AccountRow>
            {roles?.length > 0 && (
              <AccountRow
                label="Role-Based Notifications"
                help={
                  edit
                    ? "Notifications will be configured based on roles. Existing user-based notifications will not be affected."
                    : null
                }>
                {edit ? (
                  <InputCheck name="roleRecipients">Enable</InputCheck>
                ) : (
                  <InfoText>{settings.roleRecipients === "true" ? "Enabled" : "Disabled"}</InfoText>
                )}
              </AccountRow>
            )}
            <AccountRow label="Theme">
              {edit ? (
                <div>
                  <ThemeRow>
                    <ThemeSetting>
                      <InputColor
                        triggerHide={triggerHideColorPicker?.primary}
                        hideAllExcept={hideAllExcept}
                        name="primary"
                        label="Primary Light"
                      />
                    </ThemeSetting>
                    <ThemeSetting>
                      <InputColor
                        triggerHide={triggerHideColorPicker?.primaryDark}
                        hideAllExcept={hideAllExcept}
                        name="primaryDark"
                        label="Primary Dark"
                      />
                    </ThemeSetting>
                  </ThemeRow>
                  <ThemeRow>
                    <ThemeSetting>
                      <InputColor
                        triggerHide={triggerHideColorPicker?.secondary}
                        hideAllExcept={hideAllExcept}
                        name="secondary"
                        label="Secondary Light"
                      />
                    </ThemeSetting>
                    <ThemeSetting>
                      <InputColor
                        triggerHide={triggerHideColorPicker?.secondaryDark}
                        hideAllExcept={hideAllExcept}
                        name="secondaryDark"
                        label="Secondary Dark"
                      />
                    </ThemeSetting>
                  </ThemeRow>
                  <ThemeRow>
                    <ThemeSetting>
                      <InputColor
                        triggerHide={triggerHideColorPicker.tertiary}
                        hideAllExcept={hideAllExcept}
                        name="tertiary"
                        label="Tertiary Light"
                      />
                    </ThemeSetting>
                    <ThemeSetting>
                      <InputColor
                        triggerHide={triggerHideColorPicker.tertiaryDark}
                        hideAllExcept={hideAllExcept}
                        name="tertiaryDark"
                        label="Tertiary Dark"
                      />
                    </ThemeSetting>
                  </ThemeRow>
                </div>
              ) : (
                <InfoText>{settings.primary ? "Custom" : "Default"}</InfoText>
              )}
            </AccountRow>
          </SettingsRowWrapper>
          {edit && <Save type="submit">Save</Save>}
        </Form>
      </FormProvider>
    )
  );
};

EnterpriseSettings.propTypes = {
  edit: PropTypes.bool.isRequired,
  setEdit: PropTypes.func.isRequired
};

// Style Overrides
const LogoInput = styled.input`
  margin-top: ${pad}px;
`;

const ImagePreview = styled.div`
  width: fit-content;
  padding: ${pad}px;
  border-radius: ${radius};
  border: ${border} solid ${({theme}) => theme.secondary};

  background: ${({background}) => background || colors.grayLight};
`;

const StyledInline = styled(Inline)`
  gap: ${pad / 2}px;
  align-items: flex-start;
`;

const NumberWrapper = styled.div`
  max-width: 75px;
`;

const NameWrapper = styled.div`
  max-width: 300px;
`;

export const ThemeRow = styled.div`
  width: 500px;
  padding-top: ${pad}px;
  display: block;

  ${bp(4)} {
    display: inline-flex;
    gap: ${pad * 3}px;
  }
`;

export const ThemeSetting = styled.div`
  position: relative;
  width: 50%;
  min-width: 200px;

  :first-child {
    margin-right: ${pad}px;
  }
`;

export default EnterpriseSettings;

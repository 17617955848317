import dayjs from "dayjs";

/**
 * @param {Number} val
 * @returns {Boolean}
 */
export const exists = val => val || val === 0;

/**
 * @param {Object | Array} item1
 * @param {Object | Array} item2
 * @returns {Boolean}
 */
export const itemsEqual = (item1, item2) => {
  if (Array.isArray(item1) !== Array.isArray(item2) || typeof item1 !== typeof item2) return false;

  const item1Keys = Array.isArray(item1) ? item1.map((_v, i) => i) : Object.keys(item1);
  const item2Keys = Array.isArray(item2) ? item2.map((_v, i) => i) : Object.keys(item2);

  if (item1Keys.length !== item2Keys.length) return false;

  for (let i = 0; i < item1Keys.length; i++) {
    const itemKey = item1Keys[i];
    const item1Val = item1[itemKey];
    const item2Val = item2[itemKey];

    if (typeof item1Val !== typeof item2Val) return false;
    if (typeof item1Val === "string" || typeof item1Val === "number") {
      if (item1Val !== item2Val) return false;
    } else if (typeof item1Val === "object") {
      const result = itemsEqual(item1Val, item2Val);
      if (!result) return false;
    }
  }

  return true;
};

/**
 * @param {String} word
 * @returns {String}
 */
export const toTitleCase = words => {
  if (typeof words !== "string") return "";

  return words
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.substring(1, word.length).toLowerCase())
    .join(" ");
};

/**
 * @param {Any} item
 * @returns {String}
 */
export const objectGuard = item => {
  if (typeof item === "object") return "";
  return item;
};

/**
 * @param {String} key
 * @returns {String}
 */
export const getReadableKey = key => {
  if (!key) return "";

  const words = key.split("_");
  if (words.length > 1) words.pop(words.length - 1); // remove unique component
  const readable = words.map(word => toTitleCase(word));

  return readable.join(" ");
};

/**
 * @param {String} name || null
 * @returns {String}
 */
export const generateUniqueKey = (name = null, numDigits = 4) => {
  if (name) {
    // replace
    let sanitized = name.replace(/[^A-Za-z0-9_]+/g, "_");
    if (sanitized.match(/^[0-9].*/)) {
      sanitized = `id_${sanitized}`;
    }
    const words = sanitized.split(" ");
    const key = words.map(word => word.toLowerCase());
    const magnitude = numDigits - 1;
    const lower = 1 * 10 ** magnitude;
    const interval = 9 * 10 ** magnitude;
    return `${key.join("_")}_${Math.floor(lower + Math.random() * interval)}`;
  }

  return `id_${Math.floor(1000 + Math.random() * 9000)}`;
};

/**
 * @param {String} name
 * @returns {String}
 */
export const getSnakeCase = name => {
  if (!name) return "";
  const words = name.split(" ");
  const key = words.map(word => word.toLowerCase());

  return key.join("_");
};

/**
 * @param {String} name
 * @returns {String}
 */
export const getKebabCase = name => {
  if (!name) return "";
  const words = name.split(" ");
  const key = words.map(word => word.toLowerCase());

  return key.join("-");
};

const DAYS = ["Sunday", "Monday", "Tueday", "Wednesday", "Thursday", "Friday", "Saterday"];

const MONTHS = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

/**
 * Return formatted date string
 * @param {Date} date
 * @returns
 */
export const formatDate = date => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [year, month, day].join("-");
};

/**
 * Return formatted date string with time
 * @param {Date} date
 * @returns
 */
export const formatDateTime = date => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return `${[year, month, day].join("-")} ${date.toISOString().slice(-13, -5)}`;
};

/**
 * Return formatted date string with time
 * @param {Date} date
 * @returns
 */
export const getStandardDate = date =>
  `${
    MONTHS[date.getMonth()]
  } ${date.getDate()} ${date.getFullYear()} ${date.getHours()}:${date.getMinutes()} PST`;

/**
 * @param {Date} date
 * @returns {String}
 */
export const getReadableDate = date =>
  `${DAYS[date.getDay()]}, ${MONTHS[date.getMonth()]} ${date.getDate()}`;

/**
 * @param {Number} phone
 * @returns {String}
 */
export const formatPhone = phone => {
  // Filter only numbers from the input
  const sanitized = `${phone}`.replace(/\D/g, "");

  // Check length
  const match = sanitized.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) return `(${match[1]}) ${match[2]}-${match[3]}`;

  return phone;
};

/**
 * @param {array} list
 * @param {object} target
 * @returns {boolean}
 */
export const findAndRemove = (list, target) => {
  const match = list.findIndex(element => element.key === target.key);
  if (match >= 0) list.splice(match, 1);
  return match >= 0;
};

/**
 * @param {object} response
 * @returns {object}
 */
export const getWeatherObject = response => ({
  temp: response.temp
});

/**
 * @param {String | Datetime} date dayjs
 * @param {String} provided timezone
 * @returns {Object} dayjs
 */
export const convertToUserTimezone = (date, setTimezone = "US/Pacific") => {
  if (!setTimezone) setTimezone = "US/Pacific";
  const localizedDateTime = dayjs.utc(date).tz(setTimezone);
  return localizedDateTime;
};

/**
 * @param {String, Datetime} date
 * @returns {String}
 */
export const prettyDateInUserTimezone = (
  date = new Date(),
  setTimezone = "US/Pacific",
  format = "MMM D YYYY, h:mm A z"
) => date && convertToUserTimezone(date, setTimezone).format(format);

/**
 * @param {Datetime} date
 * @returns {String}
 */
export const prettyDateWithDayInUserTimezone = (
  date = new Date(),
  setTimezone = "US/Pacific",
  format = "ddd, MMM D YYYY, h:mm A z"
) => date && convertToUserTimezone(date, setTimezone).format(format);

/**
 * @param {Datetime} date
 * @returns {String}
 */
export const prettyDate = (date = new Date(), format = "ddd, MMM D YYYY, h:mm A z") =>
  date.format(format);

/**
 * @param {object} options
 * @returns {object}
 */
export const getLocation = async options => {
  const error = {
    error: "Cannot populate coordinates without location services enabled",
    lat: 0,
    lon: 0
  };

  if (navigator.geolocation) {
    try {
      const location = await new Promise((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(
          resolve,
          e => {
            const {name} = e.constructor;
            const {code, message} = e;
            reject(Object.assign(new Error(message), {name: name, code}));
          },
          {...options, enableHighAccuracy: true}
        );
      });

      return {
        lat: location.coords.latitude.toFixed(7),
        lon: location.coords.longitude.toFixed(7)
      };
    } catch (e) {
      if (e.name === "GeolocationPositionError") return error;
    }
  }

  return error;
};

/**
 * Return apple maps link
 * @param {number} lat
 * @param {number} lon
 * @returns
 */
export const getMapFromCoords = (lat, lon) => `https://maps.apple.com/?q=${lat},${lon}`;

/**
 * Return apple maps link
 * @param {string} address
 * @returns
 */
export const getMapFromAddress = address => `https://maps.apple.com/?q=${address}`;

/**
 * Format Inputted Address as String, for use with getMapFromAddress
 * @param {object} address
 * @returns {string}
 */
export const formatAddressAsString = address => {
  let addressFormatted = "";
  if (address.line2)
    addressFormatted = `${address.line1}, ${address.line2}, ${address.city}, ${address.state} ${address.zipCode}`;
  else if (address.line1)
    addressFormatted = `${address.line1}, ${address.city}, ${address.state} ${address.zipCode}`;

  return addressFormatted;
};

/**
 * Store localStorage item with expiration time
 * @param {string} key
 * @param {string} value
 * @param {number} ttl Time to live in seconds (Expiration)
 * @returns {string}
 */
export const setWithExpiry = (key, value, ttl = 10 * 60, expiry = null) => {
  const now = new Date();
  const formatted = {value};
  if (ttl) formatted.expiry = now.getTime() + ttl * 1000; // convert to milliseconds for expiry
  else if (expiry) formatted.expiry = expiry;

  const item = value !== null ? formatted : null;
  if (item) localStorage.setItem(key, JSON.stringify(item));
  else localStorage.removeItem(key);
};

/**
 * Get localStorage item with expiration time
 * @param {string} key
 * @returns {string}
 */
export const getWithExpiry = key => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) return null;
  const item = JSON.parse(itemStr);
  const now = new Date();

  // compare the expiry time of the item with the current time
  if (item?.expiry && now.getTime() > item.expiry) {
    // If the item is expired, delete the item from storage
    localStorage.removeItem(key);
    return null;
  }

  return item.value;
};

/**
 * Convert #ffffff to {r:255, g:255, b:255)
 * @param {string} hex
 * @returns {object}
 */
const hexToRgbObject = hex => {
  if (!hex) return null;

  if (hex.length < 8 && !/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) throw new Error("Bad Hex");

  const parsedArray = hex.substring(1).split("");

  const base16 = parseInt(parsedArray.join(""), 16);
  return {
    // eslint-disable-next-line no-bitwise
    r: (base16 >> 16) & 255,
    // eslint-disable-next-line no-bitwise
    g: (base16 >> 8) & 255,
    // eslint-disable-next-line no-bitwise
    b: base16 & 255
  };
};

/**
 * Convert #ffffff to rgba(255,255,255)
 * @param {string} hex
 * @returns {string}
 */
export const hexToRgb = hex => {
  if (!hex) return null;

  if (hex.length < 8 && !/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) throw new Error("Bad Hex");

  const {r, g, b} = hexToRgbObject(hex);

  return `rgb(${r}, ${g}, ${b})`;
};

/**
 * Convert #ffffff to rgba(255,255,255,1)
 * @param {string} hex
 * @param {integer} opacity
 * @returns {string}
 */
export const hexToRgba = (hex, opacity = 1) => {
  if (!hex) return null;

  if (hex.length < 8 && !/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) throw new Error("Bad Hex");

  const {r, g, b} = hexToRgbObject(hex);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

/**
 * Convert #ffffff to {h:255, s:100%, l: 100%)
 * Source: https://css-tricks.com/converting-color-spaces-in-javascript/
 * @param {string} hex
 * @returns {object}
 */
const hexToHslObject = hex => {
  if (!hex) return null;

  if (hex.length < 8 && !/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) throw new Error("Bad Hex");

  const {r, g, b} = hexToRgbObject(hex);

  const cmin = Math.min(r / 255, g / 255, b / 255);
  const cmax = Math.max(r / 255, g / 255, b / 255);
  const delta = cmax - cmin;
  let h = 0;
  let s = 0;
  let l = 0;

  if (delta === 0) h = 0;
  else if (cmax === r) h = ((g - b) / delta) % 6;
  else if (cmax === g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return {h, s, l};
};

/**
 * Conver #ffffff to hsl(255,100%,100%)
 * @param {string} hex
 * @param {number} percentage
 * @returns
 */
export const adjustHexBrightness = (hex, percentage) => {
  if (!hex) return null;

  if (hex.length < 8 && !/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) throw new Error("Bad Hex");

  const {h, s, l} = hexToHslObject(hex);

  return `hsl(${h}, ${s}%, ${percentage || l}%)`;
};

/**
 * Random Color Generator
 * @returns {string}
 */
export const getRandomColor = () => Math.floor(Math.random() * 16777215).toString(16);

/**
 * Programmatically open link
 * @param {string} link
 */
export const openLink = link => {
  Object.assign(document.createElement("a"), {
    target: "_blank",
    href: link
  }).click();
};

/**
 * Convert miltary time to standard - i.e. 13:05 -> 01:05 PM
 * @param {string} time
 * @returns {string}
 */
export const toStandard = time => {
  if (!time) return null;
  const temp = time.split(":");
  if (temp.length === 1) {
    const hour = parseInt(temp, 10);

    if (Number.isNaN(hour)) return null;
    if (hour === 0) return "12:00 AM";
    if (hour <= 11) return `${hour}:00 AM`;
    if (hour === 12) return "12:00 PM";
    return `${hour - 12}:00 PM`;
  }

  const hour = parseInt(temp[0], 10);
  const min = temp[1];
  if (Number.isNaN(hour)) return null;
  if (hour === 0) return `12:${min} AM`;
  if (hour <= 11) return `${hour}:${min} AM`;
  if (hour === 12) return `12:${min} PM`;

  return `${hour - 12}:${min} PM`;
};

/**
 * Convert standard time to miltary time - i.e. 01:05 PM -> 13:05
 * @param {string} time
 * @returns {string}
 */
export const toMilitary = time => {
  if (!time) return null;
  const temp = time.split(":");

  const hour = parseInt(temp[0], 10);
  const inter = temp[1].split(" ");
  const min = parseInt(inter[0], 10);
  const meridian = inter[1];
  let mHour = hour;
  if (meridian === "PM" && mHour !== 12) mHour += 12;
  else if (meridian === "AM" && mHour === 12) mHour = 0;

  const padHour = mHour < 10 ? `0${mHour}` : mHour;
  const padMin = min < 10 ? `0${min}` : min;

  return `${padHour}:${padMin}`;
};

/**
 * Convert standard time to miltary time - i.e. 01:05 PM -> 13:05
 * @param {string} time
 * @returns {string}
 */
export const fromMilitary = time => {
  if (!time) return null;
  const temp = time.split(":");

  const hour24 = parseInt(temp[0], 10);
  const hour = ((hour24 + 11) % 12) + 1;
  const min = parseInt(temp[1], 10);

  const padMin = min < 10 ? `0${min}` : min;
  const meridian = hour24 < 12 ? "AM" : "PM";

  return `${hour}:${padMin} ${meridian}`;
};

/**
 * Provide standard title (ex: "Example Title") outputs example-title
 * @param {string} text
 * @returns {string}
 */
export const slugify = text => {
  if (!text) return "";
  const split = text.split(/[^a-zA-Z0-9]+/);
  return split.join("-").toLowerCase();
};

/**
 * Format number with commas to string
 * @param {string} num
 * @returns {string}
 */
export const addCommas = (num, precision = 0) => {
  if (!exists(num) || num === "NO RESPONSE PROVIDED" || num === "NO PREVIOUS RECORD") return null;

  [num] = `${num}`.split(" ");
  num = num?.replace(/,/g, "");

  if (!exists(num)) return null;

  const temp = Number.parseFloat(num);
  let rounded = temp;
  if (!Number.isNaN(temp) && exists(precision)) rounded = temp.toFixed(precision);
  else rounded = `${rounded}`;

  if (!exists(rounded)) return null;

  const formatter = new Intl.NumberFormat("en-US");

  if (!rounded.includes(".")) return formatter.format(Number.parseFloat(rounded.replace(/,/g, "")));

  const parts = rounded.split(".");
  const whole = formatter.format(Number.parseFloat(parts[0].replace(/,/g, "")));
  const decimal = parts[1];

  return `${whole}.${decimal}`;
};

/**
 * Round to two decimal places
 * @param {number} num
 * @returns {number}
 */
export const round = num => Math.round((num + Number.EPSILON) * 100) / 100;

/**
 * Round number to selected decimal place
 * @param {number} num
 * @param {number} place Decimal Place (Default: 10^2 == 100)
 * @returns {number}
 */
export const roundTo = (num, place = 2) =>
  Math.round((num + Number.EPSILON) * 10 ** place) / 10 ** place;

/**
 * Get Percent Completion Rate (%) from Object
 * @param {object} formObject
 * @returns {number} Percentage
 */
export const getCompletionRate = formObject => {
  const keyList = formObject ? Object.keys(formObject) : [];

  if (keyList.length > 0) {
    let count = 0;
    const filtered = keyList
      .filter(
        key =>
          !key.match(/(_undefined|_qualifier|_qualifierEnabled|_infinite)$/g) &&
          formObject[key] !== "_CONDITION_UNSATISFIED" &&
          (!key.match(/_comment$/g) || formObject[key] !== "")
      )
      .map(key => {
        if (formObject[key] && formObject[key] !== "" && formObject[key] !== "_NEEDS_COMMENT")
          count += 1;
      });

    return Math.round((count / filtered.length) * 100);
  }

  return 0;
};

/**
 * Check whether provided element is visible in on the window
 * @param {documentElement} element
 * @returns {boolean}
 */
export const inViewport = element => {
  const el = element?.getBoundingClientRect();

  const scrollContainer = document.getElementById("modal") || window;

  return (
    el &&
    element.offsetParent !== null &&
    el.top >= 0 &&
    el.left >= 0 &&
    el.top <= (scrollContainer?.innerHeight || document.documentElement.clientHeight) &&
    el.left <= (scrollContainer?.innerWidth || document.documentElement.clientWidth)
  );
};

// Conversions

/**
 * Convert mm to in
 * @param {number} val
 * @returns
 */
export const milimetersToInches = val => round(val / 25.4);

import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";

const ButtonMultiClick = ({className, children, handleSingleClick, handleDoubleClick, delay}) => {
  const [clickCount, setClickCount] = useState(0);

  useEffect(() => {
    const timer = setTimeout(() => {
      // simple click
      if (clickCount === 1) handleSingleClick();
      setClickCount(0);
    }, delay);

    // the duration between this click and the previous one
    // is less than the value of delay = double-click
    if (clickCount === 2) handleDoubleClick();

    return () => {
      clearTimeout(timer);
    };
  }, [clickCount, delay, handleDoubleClick, handleSingleClick]);

  return (
    <button
      type="button"
      data-testid="button-multiclick"
      className={className}
      onClick={({detail}) => setClickCount(detail)}>
      {children}
    </button>
  );
};

ButtonMultiClick.propTypes = {
  className: PropTypes.string.isRequired, // needed to style jsx component
  children: PropTypes.node,
  handleSingleClick: PropTypes.func.isRequired,
  handleDoubleClick: PropTypes.func.isRequired,
  delay: PropTypes.number
};

ButtonMultiClick.defaultProps = {
  children: null,
  delay: 200
};

export default ButtonMultiClick;
